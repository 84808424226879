import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Modal,Row,Col } from 'react-bootstrap';

// Components
import StatusBox from '../../../elements/StatusBox';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import GenericList from '../../../components/GenericList/GenericList';
import AttachmentsWidget from '../../../components/GenericForm/Widgets/AttachmentsWidget';
import withLocalization from '../../../hoc/withLocalization';
import ClientListFilter from './ClientListFilter/ClientListFilter';
import DatePicker from '../../../components/DatePicker/DatePicker';
import configRoots from '~/library/config';
import { serverTimeFormat } from '~/library/core';

@inject('clientStore', 'commonStore')
@withRouter
@withLocalization
@observer
class ClientsList extends Component {
    state = {        
        showAttachmentModal: false,  
        currentClientId : 0,
        clientAttachmentIds:"",
        showRenewModal:false,
        showRemoveAttachmentModal:false,        
        renew_date:new Date(),
        attachmentList:[],
        expiry_date:new Date(),
    };

    handleTableButtonAction = (id, type, data,expiry_date) => {
        if(type === 'upload'){       
            this.handleAttachmentUploads(id,data,expiry_date);
            return;
        }        
        if(type === 'remove-expiry'){
            this.removeExpiryAttachments(id,data);
            this.handleRenewModalCloseButton();
            this.props.clientStore.resetLastListLoadTime();
            return;
        }
        this.props.onTableAction(id, type);
    };    

    handleAttachmentModalCloseButton = () => {
        this.setState({ showAttachmentModal: false,
            currentClientId:0,
            clientAttachmentIds:"",
            attachmentList:[],
            expiry_date:new Date()});
    };

    handleRenewModalCloseButton = () => {
        this.setState({ showRenewModal: false,
            currentClientId:0,
            renew_date:new Date() });
    };

    handleRemoveAttachmentModalCloseButton = () => {
        this.setState({ showRemoveAttachmentModal: false,
            currentClientId:0});
    };

    handleAttachmentUploads = async (id,data,expiry_date) =>{        
        let attachment_ids = "";
        let attachmentList = [];
        if(data && data.attachment_id != ""){
            attachment_ids = data.attachment_id;
            await this.props.clientStore.getMultipleAttachments({ attachmentIds:attachment_ids}).then((response=>{
                attachmentList = response.attachments;
            }))
        }                            
        this.setState({ clientAttachmentIds: attachment_ids,
            showAttachmentModal: true,
            currentClientId: id,
            attachmentList: attachmentList,
            expiry_date: expiry_date ? new Date(expiry_date) : new Date()});  
    }

    saveClientAttachments = async () =>{
        const { clientStore, t, commonStore} = this.props;
        const { currentClientId,clientAttachmentIds,expiry_date} = this.state;           
        if(clientAttachmentIds == "" || clientAttachmentIds == undefined){
            commonStore.addNotification(t('Please Add Attachments'), null, 'error');
            return;
        }
        let data = {
            id: currentClientId,
            attachment_id: clientAttachmentIds,
            expiry_date : moment(expiry_date).format("YYYY-MM-DD")
        }    
        await clientStore.addAttachments(data).then(
            (response) => {
                if (response) {                    
                    commonStore.addNotification(t('Saved'), null, 'success');
                    this.handleAttachmentModalCloseButton();
                    this.props.clientStore.resetLastListLoadTime();
                    return;
                }
            })
            .catch(
                (error) => {
                    commonStore.addNotification(error.message || t('Error'), null, 'error');
            }
        )
    }

    removeExpiryAttachments = async () =>{
        const { currentClientId} = this.state;           
        const { clientStore, t, commonStore} = this.props;
        let newData = {
            id: currentClientId,
            attachment_id: "",
            expiry_date : null
        }          
        await clientStore.addAttachments(newData).then(
            (response) => {
                if (response) {                    
                    commonStore.addNotification(t('Deleted'), null, 'success');                    
                    this.props.clientStore.resetLastListLoadTime();                    
                }
            })
            .catch(
                (error) => {
                    commonStore.addNotification(error.message || t('Error'), null, 'error');
            }
        )
        this.handleRemoveAttachmentModalCloseButton();
    }

    renewClientDate = async () =>{
        const { clientStore, t, commonStore, history} = this.props;
        const { currentClientId,renew_date} = this.state;              
        let data = {
            id:currentClientId,
            renew_date:moment(renew_date).format("YYYY-MM-DD")
        }    
        await clientStore.renewClient(data).then(
            (response) => {
                if (response) {                    
                    commonStore.addNotification(t('Saved'), null, 'success');                    
                    return;
                }
            })
            .catch(
                (error) => {
                    console.log(error);
                    commonStore.addNotification(error.message || t('Error'), null, 'error');
            }
        )
    }

    renderTableActionButtons = (id, user_id, status,data,expiry_date) => (        
        <div className="actions-center" style={{justifyContent:"center"}}>
            <Button
                onClick={() => this.handleTableButtonAction(user_id, 'loginas')}
                bsStyle="info"
                simple
                title={this.props.t('Login as')}
                icon
            >
                <i className="fa fa-user" />
            </Button>  
            {/* <Button onClick={() => this.handleTableButtonAction(id, 'upload',data,expiry_date)} bsStyle="warning" simple icon>
                <i className="fa fa-upload" />
            </Button>            */}
            <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                <i className="fa fa-edit" />
            </Button>
            {status != "active" && <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                <i className="fa fa-times" />
            </Button>}                      
        </div>
    );
    
    render() {
        const { config } = this.props.commonStore;
        const { filters, appliedFilters, loading } = this.props.clientStore;
        if (!config.biztypes) return null;

        return (
            <div className="table-list">
                <div className="table-list__header">
                    {config.biztypes.map(biztype => (
                        <Button round wd icon key={biztype.id} onClick={() => this.props.onAdd(biztype.id)}>
                            Add {biztype.name} <i className="fa fa-plus" />
                        </Button>
                    ))}
                </div>
                <GenericList
                    columns={[
                        {
                            Header: 'ID',
                            id: 'id',
                            accessor: 'id',
                            maxWidth:50
                        },
                        {
                            Header: 'Name',
                            id: 'name',
                            accessor: 'name',
                        },
                        {
                            Header: 'Type',
                            id: 'biztype_name',
                            accessor: 'biztype_name',
                            sortable: false,
                        },
                        {
                            Header: 'Phone',
                            id: 'phone',
                            accessor: 'phone',
                        },
                        {
                            Header: 'Email',
                            id: 'email',
                            accessor: 'email',
                        },
                        {
                            Header: 'Users',
                            id: 'users_amount',
                            accessor: 'users_amount',                            
                        },
                        // {
                        //     Header: 'Renew',
                        //     id: 'renew_date',
                        //     accessor: ({ renew_date, renew_manual,id }) => (
                        //         <span>
                        //             {(renew_date && new Date(renew_date) > new Date())?
                        //             moment(renew_date).format(serverTimeFormat()):
                        //             <Button wd fill round onClick={() => this.setState({showRenewModal:true,currentClientId:id})}>
                        //                 {this.props.t('Renew')}
                        //             </Button>}
                        //             {/* <StatusBox status={renew_manual ? 'manual' : 'auto'} /> */}
                        //         </span>
                        //     ),
                        // },
                        {
                            Header: 'Expiry date',
                            id: 'expiry_date',
                            accessor: ({ id,data,expiry_date }) => (
                                <span>
                                    {expiry_date != null? (
                                        <span >
                                            <Button wd round onClick={() => this.handleTableButtonAction(id, 'upload',data,expiry_date)}>
                                                {moment(expiry_date).format(serverTimeFormat())}
                                            </Button>                                            
                                            <i className="fa fa-times" 
                                            style={{cursor:'pointer',color:'#cc6d6b'}}
                                            onClick={() => this.setState({currentClientId:id,showRemoveAttachmentModal:true})}/>
                                        </span> 
                                    )                                                                                                                                              
                                    :(
                                        <Button wd fill round onClick={() => this.handleTableButtonAction(id, 'upload',data,expiry_date)}>
                                            {this.props.t('Set Expiry')}
                                        </Button>
                                    )
                                    }
                                </span>                                
                            ),
                            minWidth:150                        
                        },
                        {
                            Header: 'Status',
                            id: 'status',
                            accessor: ({ status }) => <StatusBox status={status || ''} />,
                            sortable: false,
                        },
                        {
                            Header: 'Operation',
                            id: 'operation',
                            sortable: false,
                            accessor: ({ id, user_id,status,data,expiry_date }) => this.renderTableActionButtons(id, user_id,status,data,expiry_date),                            
                        },
                    ]}
                    filters={appliedFilters}                    
                    lastListLoadTime={this.props.clientStore.lastListLoadTime}
                    header={(
                        <ClientListFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.clientStore.setFilter(name, value)}
                            onFilter={() => this.props.clientStore.onFilter()}
                            loading={loading}
                        />
                    )}
                    requestData={params => this.props.clientStore.loadList(params)}
                />
                <Modal                    
                    show={this.state.showAttachmentModal}
                    onHide={this.handleAttachmentModalCloseButton}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Client')} {this.props.t('Attachments')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>             
                        <Row>
                            <Col sm={6}>
                                <AttachmentsWidget
                                    value={this.state.clientAttachmentIds}
                                    onChange={attachIds => this.setState({clientAttachmentIds:attachIds})}
                                    options={{
                                        imageContext: {
                                            existingAttachment:[],
                                            fileType: 'docs',
                                            id: 0,
                                            model: 'Client',
                                        },
                                    }}
                                    // error={this.state.element.photo_required && !this.state.element.attachments_by_user}
                                />
                                <DatePicker
                                    selected={this.state.expiry_date}
                                    onChange={(date) => this.setState({expiry_date:date})}
                                    dateFormat="EEEE MMMM dd, yyyy"
                                    label={this.props.t('Expiry date')}
                                />
                            </Col>
                            {this.state.attachmentList && this.state.attachmentList != [] && 
                            <Col sm={6}>
                                <h4>{this.props.t('Prev')} {this.props.t('Attachments')}</h4>
                                {this.state.attachmentList.map((attachment,index)=>(
                                    <div key={index}>
                                        <a href={`${configRoots.API_ROOT}/attachments/${attachment.id}`} target="_blank">
                                            {attachment.filename}
                                        </a>
                                    </div>)
                                )}
                            </Col>
                        } 
                        </Row>                                                                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.saveClientAttachments} className="btn-wd btn-fill">
                            {this.props.t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleAttachmentModalCloseButton} className="btn-wd">
                            {this.props.t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal                    
                    show={this.state.showRenewModal}
                    onHide={this.handleRenewModalCloseButton}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Renew')} {this.props.t('Clients')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>                        
                        <DatePicker
                            selected={this.state.renew_date}
                            onChange={(date) => this.setState({renew_date:date})}
                            dateFormat="EEEE MMMM dd, yyyy"
                            label={this.props.t('Renew date')}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.renewClientDate} className="btn-wd btn-fill">
                            {this.props.t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleRenewModalCloseButton} className="btn-wd">
                            {this.props.t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal                    
                    show={this.state.showRemoveAttachmentModal}
                    onHide={this.handleRemoveAttachmentModalCloseButton}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Remove')} {this.props.t('Expiry date')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>                        
                        {this.props.t('Are you sure to perform this action?')}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.removeExpiryAttachments} className="btn-wd btn-fill">
                            {this.props.t('Yes')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleRemoveAttachmentModalCloseButton} className="btn-wd">
                            {this.props.t('No')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default ClientsList;
