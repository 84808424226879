import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import '../../../../styles/css/styles.css';

// Components
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import { Input } from '../../../../elements/Input';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import ChecklistTemplateTreeView from './ChecklistTemplateTreeView';
import { ProjectsWidget } from '../../../../components/GenericForm/Widgets/ProjectsWidget';

@inject('checklistStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ChecklistTemplateAddEdit extends Component {
    constructor(props) {
        super(props);
        this.timeout = 0;
    }

    loadData = async () => {
        const { checklistStore, id, add } = this.props;
        if (id && id > 0) {
            checklistStore.loadChecklistTemplate(id, add).then((elementsResult) => {
            });
        } else {
            checklistStore.returnDefaultNewChecklistTemplate();
        }
        this.props.checklistStore.checklistTemplateDoSearch("");
    }

    componentDidMount() {
        this.loadData();
    }

    doSearch(evt) {
        this.props.checklistStore.checklistTemplateDoSearch(evt.target.value);
    }

    onassignedchecklist = async (item) => {
        await this.props.checklistStore.onassignedchecklist(item)
    };

    // handleUpdateElement = async (item) => {
    //     let currentassignedarray = this.state.assignedelements;
    //     this.state.assignedelements.map((item, index) => {
    //         item["newkey"] = "aaaaaaaaaaaaaaaa";
    //     });
    // }

    unassignedelement = async (item) => {
        await this.props.checklistStore.unassignedelement(item)
    };

    handleChange = (name, value) => {
        this.props.checklistStore.checklistTemplateHandleChange(name, value)
    };

    render() {
        const {
            config,
            t,
            isUnionMode,
            userStore: { currentUser },
            checklistStore
        } = this.props;
        const { loading, filters, appliedFilters, currentChecklistTemplate } = this.props.checklistStore;

        // let reportedUsername = `${currentUser.first_name} ${currentUser.last_name}`;
        return (
            <div className="page" style={{ padding: 0, margin: 0 }}>
                <div className="page__header">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="page__title">{t('Create checklist')}</div>
                    </div>
                </div>
                <div className="page__content">
                    <form className="custom-form">
                        {/* <div className="custom-form__row">
                            <div className="custom-form__col">
                                <div className="read-area">
                                    <span className="read-area__label">{t('Reported by')}</span>
                                    <div className="read-area__content">{reportedUsername}</div>
                                </div>
                            </div>
                        </div> */}
                        <div className="custom-form__row">
                            <div className="custom-form__col">
                                <ProjectsWidget
                                    label={`${t('Project')} *`}
                                    value={currentChecklistTemplate.project_id}
                                    name="project_id"
                                    allowAll={false}
                                    onChange={value => {
                                        this.handleChange('project_id', value);
                                    }}
                                    error={!currentChecklistTemplate.project_id}
                                />
                            </div>
                        </div>
                        <div className="custom-form__row">
                            <Input
                                className="custom-form__col"
                                label={`${t('Checklist Name')} *`}
                                placeholder={t('Checklist Name')}
                                type="text"
                                name="checklistname"
                                value={currentChecklistTemplate.checklistname}
                                onChange={evt => {
                                    this.handleChange('checklistname', evt.target.value);
                                }}
                                error={!currentChecklistTemplate.checklistname}
                            />
                        </div>
                        <div>
                            <div className="form-group has-feedback">
                                <Input
                                    className="custom-form__col"
                                    label={`${t('Add Elements')} *`}
                                    placeholder={t('Search Elements here')}
                                    type="text"
                                    ref="searchInput"
                                    name="Element_search"
                                    value={currentChecklistTemplate.searchText}
                                    onChange={(evt) => this.doSearch(evt)}
                                />
                            </div>
                        </div>
                        <div className="custom-form__row"> <label>{t('Click on element to add to your list.')}</label></div>
                        <div>
                            {currentChecklistTemplate.elementlist.length > 0
                                ? currentChecklistTemplate.elementlist.map((item, index) => (
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-3 elementgridc">
                                        <div className="checkelistementc"
                                         onClick={() => this.onassignedchecklist(item)}>
                                            <i
                                                className="fa fa-plus elementiconc"
                                                style={{ cursor: 'cell' }}
                                                onDoubleClick={this.ondoubleclickoperation}
                                                
                                            ></i>
                                            <label
                                                style={{ cursor: 'cell' }}
                                               className="control-label elementformcontrolc"
                                            > {item.name}</label>
                                        </div>
                                    </div>
                                ))
                                : null}
                        </div>
                        <div>
                            <label>{t('Assigned element list')}</label>
                            <div>
                                {currentChecklistTemplate.assignedelements.length > 0 ? (
                                    currentChecklistTemplate.assignedelements.map((item, index) => (
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xs-12  elementgridc">
                                            <div className="checkelistementcrow">
                                                <i
                                                    className="fa fa-close elementiconc"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => this.unassignedelement(item)}
                                                ></i>
                                                {/* <input
                                                    className="form-control checklistformcontrolc"
                                                    type="text"
                                                    value={item.name}
                                                    readOnly={true}
                                                /> */}
                                                <label
                                                    className="control-label elementtreerowlabel"
                                                > Category: {item.name}</label>
                                                <div>
                                                    <ChecklistTemplateTreeView
                                                        data={item}
                                                        headingcolor="#F6F6F6"
                                                        key={item.id}
                                                        paddingLeft={0}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                        <h4>{t('No elements assigned...')}</h4>
                                    )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default ChecklistTemplateAddEdit;
