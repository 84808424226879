import { observable, action, computed } from 'mobx';

import agent from '../agent';

const currentEntityInit = {
    status: 'new',
    doc_type: 'internal',
    data: { disableAvailableForEverybody: true },
};
class ExtraDocStore {
    @observable currentList = [];

    @observable currentEntity = currentEntityInit;

    @observable updatingErrors = null;

    @observable updating = false;

    @observable loading = false;

    @observable requestParams = null;

    @observable deleteSuccess = false;

    @observable lastListLoadTime = null;

    @observable filters = {
        name: '',
    };

    @observable appliedFilters = {
        name: '',
    };

    @computed get getPreparedData() {
        const {
            id, name, attachments, description, doc_type
        } = this.currentEntity;
        const data = this.currentEntity.data || {};

        const { history, ...preparedData } = data;
        return {
            id,
            name,
            attachments,
            description,
            doc_type,
            data: preparedData,
        };
    }

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    @action loadList(params, mode) {
        params.mode = mode;
        return agent.ExtraDoc.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    @action load(id) {
        this.loading = true;
        return agent.ExtraDoc.load(id)
            .then(
                action(({ extradoc }) => {
                    this.currentEntity = extradoc;
                    this.loading = false;
                })
            )
            .catch(
                action(err => {
                    this.returnDefaultNew();
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action save(values, isAdd) {
        this.updating = true;
        if (!values.data) values.data = {};

        return agent.ExtraDoc.save(values, isAdd)
            .then(
                action(({ extradoc }) => {
                    this.currentEntity = extradoc;
                    this.updating = false;
                    return extradoc;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    throw err.response && err.response.body && err.response.body.error;
                })
            );
    }

    @action async remove(id) {
        await agent.ExtraDoc.remove(id);
        this.deleteSuccess = true;
        return 1;
    }

    @action resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    @action handleChange = (name, value) => {
        // const extradoc = { ...this.currentEntity };
        const extradoc = JSON.parse(JSON.stringify(this.currentEntity));
        if (name.split('.').length === 1) {
            extradoc[name] = value;
        }
        if (name.split('.').length === 2) {
            const firstName = name.split('.')[0];
            const secondName = name.split('.')[1];
            if (!(extradoc[firstName])) extradoc[firstName] = {};
            extradoc[firstName][secondName] = value;
        }
        this.currentEntity = JSON.parse(JSON.stringify(extradoc));
    };

    @action handleChangeFull(obj = {}) {
        this.currentEntity = { ...this.currentEntity, ...obj };
    }

    @action returnDefaultNew(params) {
        if (params) {
            this.currentEntity = {
                ...JSON.parse(JSON.stringify(currentEntityInit)),
                ...JSON.parse(JSON.stringify(params)),
            };
        } else {
            this.currentEntity = {
                ...JSON.parse(JSON.stringify(currentEntityInit)),
                // ...JSON.parse(JSON.stringify(params)),
            };
        }
        this.loading = false;

        return this.currentEntity;
    }
}

export default new ExtraDocStore();
