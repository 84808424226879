import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import v4 from 'uuid/v4';

const layout = [
    [7, 8, 9, { name: 'del', double: true }],
    [4, 5, 6, { name: 'switcher', double: true }],
    [1, 2, 3, '', ''],
    [0, { name: 'ok', double: true }, '', ''],
];

class NumKeypad extends Component {
    state = {
        target: 'mobile',
    };

    renderCol = (key, double) => {
        if (key === '') {
            return <div className="num-keypad__col" key={v4()} />;
        }
        let str = `${key}`;
        if (str === 'del') str = <i className="fa fa-arrow-left" />;
        if (str === 'ok') str = <i className="fa fa-check" />;
        if (str === 'switcher') {
            const { t, focusedInput: target, simple } = this.props;
            if (simple) {
                return <div className="num-keypad__col" key={v4()} />;
            }
            return (
                <div key={v4()} className={`num-keypad__col ${double ? 'num-keypad__col_double' : ''}`}>
                    <div className="num-keypad__targets">
                        <button
                            type="button"
                            className={`num-keypad__target ${target == 'mobile' ? 'num-keypad__target_active' : ''}`}
                            onClick={() => this.props.onFocusChange('mobile')}
                        >
                            {t('ID')}
                        </button>
                        <button
                            type="button"
                            className={`num-keypad__target ${target == 'pin' ? 'num-keypad__target_active' : ''}`}
                            onClick={() => this.props.onFocusChange('pin')}
                        >
                            {t('PIN')}
                        </button>
                    </div>
                </div>
            );
        }
        return (
            <div key={v4()} className={`num-keypad__col ${double ? 'num-keypad__col_double' : ''}`}>
                <Button onClick={() => this.onAction(key)}>{str}</Button>
            </div>
        );
    };

    onAction(key) {
        const {
            onChangeValue, onSubmit, mobile, pin, password, focusedInput: target, simple
        } = this.props;
        let value = '';
        if (target == 'mobile') {
            value = mobile;
        } else if (target == 'pin') {
            value = pin;
        } else {
            value = password;
        }
        if (!value) value = '';
        if (key === 'del') {
            if (value.length > 0) {
                value = value.substring(0, value.length - 1);
                onChangeValue(target, value);
            }
        }
        if (typeof key === 'number') {
            value += `${key}`;
            onChangeValue(target, value);
        }
        if (key === 'ok') {
            if (target == 'mobile') {
                this.props.onFocusChange('pin');
            } else if (target == 'pin') {
                this.props.onFocusChange('mobile');
                onSubmit();
            } else {
                onSubmit();
            }
        }
    }

    render() {
        return (
            <div className="num-keypad">
                <div className="num-keypad__table">
                    {layout.map(row => (
                        <div className="num-keypad__row" key={v4()}>
                            {row.map(col => this.renderCol(col.name || col, col.double))}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default NumKeypad;
