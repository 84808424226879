import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ReactQuill from 'react-quill';
import MultiSelect from '@khanacademy/react-multi-select';

//components
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

//styles
import '../../../../styles/css/styles.css';

//this component displays all the documents with checkbox// 
//and the ones in the current template are checked//

@inject('documentStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class DocumentMultipleSelector extends Component {

    state = {
        template_doc_ids: null,//for Ids of documents in document template
        documentList: [],//to load all documents
        selected: [],//for selected documents
        options: [],//for document list
        template_documents: [],//used for rendering documents via quill
        modules: {
            toolbar: false,
        },
    }

    componentDidMount() {
        //setting the template_doc_ids and calls loadData//
        this.setState({
            template_doc_ids: this.props.documents
        }, () => {
            this.loadData();
        });
    }

    //maps and parse ids from template_doc_ids//
    parseTemplateIds = () => {
        let idsArray = []
        if (this.state.template_doc_ids != null && this.state.template_doc_ids != "") {
            idsArray = this.state.template_doc_ids.split(",").map(function (item) {
                return parseInt(item, 10);
            });
        }
        return idsArray;
    }

    //loads documentList and assigns checked values based on template_doc_ids//
    async loadData() {
        let idsArray = this.parseTemplateIds();

        //fetches all documents and assigns checked values based on idsArray//
        //and sets documentList//
        await this.props.documentStore.getDocumentsList().then((data) => {
            if (idsArray.length > 0) {                
                data.rows = data.rows.map(function (item) {
                    if (idsArray.includes(item.id)) {
                        item.checked = true;
                        return item;
                    } else {
                        item.checked = false;
                        return item;
                    }
                })
            };
            this.setState({ documentList: data.rows });
        });

        let optionArray = [];

        this.state.documentList.forEach(item => {
            optionArray.push({
                label: item.document_name,
                value: item.id
            })
        })      

        this.setState({
            options: optionArray,
            selected: idsArray
        }, () => {
            this.updateListInSelectedOrder(this.state.documentList, this.state.selected);
        }
        )

    }

    //handlesSelectedOptions
    handleSelectedOptions = (selected) => {
        this.setState({ selected }, () => {
            this.updateDocumentList()
        })
    }

    //update DocumentList with selected values//
    updateDocumentList = () => {
        let idsArray = this.state.selected;      
        let updatedData = this.state.documentList;
        updatedData = this.state.documentList.map(function (item) {
            if (idsArray.includes(item.id)) {
                item.checked = true;
                return item;
            } else {
                item.checked = false;
                return item;
            }
        })


        this.setState({
            documentList: updatedData,
            template_doc_ids: idsArray.toString()
        }, () => {            
            this.props.documentStore.handleDocumentTemplateChange("template_documents", this.state.template_doc_ids);
            this.updateListInSelectedOrder(this.state.documentList, this.state.selected);
        })
    }

    //renders checked docuements//
    renderDocuments = (item, index) => {
        const { t } = this.props;
        if (item.checked) {
            return (
                <div key={index} style={{ marginBottom: '20px' }}>
                    <div style={{ marginBottom: '5px', color: 'blue', fontSize: '20px' }}>{t('Document Name') + " : " + item.document_name}</div>
                    <div>
                        <ReactQuill
                            value={item.document_description}
                            readOnly={true}
                            modules={this.state.modules}
                            style={{ border: '0px', width: '210mm', padding: '10px', color: 'black' }}
                        />
                    </div>
                </div>
            )
        }
    }

    //render documents on the basis of selection//
    updateListInSelectedOrder = (documentList, selected) => {
        let result = []
        selected.forEach(i => {
            result.push(documentList.filter(item => item.id == i)[0])
        })
        this.setState({ template_documents: result })
    }

    render() {
        //renders documents with checkbox and checked documents//
        const { selected } = this.state;

        return (
            <div>
                <MultiSelect
                    options={this.state.options}
                    selected={selected}
                    onSelectedChanged={selected => this.handleSelectedOptions(selected)}
                    overrideStrings={{
                        selectSomeItems: "Select Documents",
                        allItemsAreSelected: "All Documents Are Selected",
                        selectAll: "Select All",
                        search: "Search By Document Name"
                      }} 
                />
            </div>
        )
    }
}

export default DocumentMultipleSelector;

                {/* <div className="custom-form__row">

                    {this.state.template_documents.length > 0 && (
                        <div className="" style={{ border: '10px groove #ccc', borderStyle: 'inset', width: '220mm', padding: '10px' }}>
                            { this.state.template_documents.map((item, index) => (
                                this.renderDocuments(item, index)
                            ))}
                            
                        </div>
                    )}
                </div> */}