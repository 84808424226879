import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormControl } from 'react-bootstrap';
import { find } from 'lodash';

import Button from '../../../../components/CustomButton/CustomButton.jsx';
import Select from '../../../../elements/Select';
import withLocalization from '../../../../hoc/withLocalization';

@inject('commonStore')
@withLocalization
@observer
class ClientsListFilter extends Component {
    render() {
        const {
            filters, setFilter, onFilter, loading, t
        } = this.props;
        const { config } = this.props.commonStore;
        const { status, name } = filters;
        const projectStatuses = config.client.data.project_statuses || [];
        const options = projectStatuses.map(status => {
            return { value: `${status.code}`, label: status.name };
        });
        options.unshift({ value: '', label: t('All') });
        const currentSelect = find(options, o => {
            return o.value === status;
        });
        return (
            <div className="list-filters">
                <div className="list-filters__item">
                    <Select
                        options={options}
                        round
                        value={currentSelect}
                        onChange={id => setFilter('status', id.value)}
                        style={{ width: 150 }}
                    />
                </div>
                <div className="list-filters__item list-filters__item_grow">
                    <FormControl
                        placeholder={t('Search')}
                        type="text"
                        onChange={e => setFilter('name', e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onFilter();
                            }
                        }}
                        value={name}
                    />
                </div>
                <div className="list-filters__item list-filters__item_to-right">
                    <Button fill wd onClick={() => onFilter()} disabled={loading}>
                        {t('Filter')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default ClientsListFilter;
