import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Modal } from 'react-bootstrap';

import Button from '../../../components/CustomButton/CustomButton.jsx';
import ModalEditor from '../Timelogs/ModalAccept';

import AbsencesTotals from './AbsencesTotals';
import AbsenceListFilter from './AbsencesFilter';
import AbsenceReportDownloadModal from './AbsenceReportDownloadModal';

import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import { statusMap } from '~/utils/normalStatuses';
import { shortDateFormat } from '~/utils/dateFormat'

import config from '~/library/config';
import agent from '~/library/agent';

// Elements

import customerFeature from '~/utils/customerFeature';
import { serverTimeFormat } from '~/library/core';

@inject('absenceStore', 'userStore','commonStore')
@withRouter
@withLocalization
@observer
class AbsencesList extends Component {
    state = {
        accepting: null,
        acceptanceChanged: false,
        selected: null,
        start:'',
        end:'',
        dateRange: { start: moment(new Date()).startOf('month'), end: moment(new Date()) },
        all_members:'',
        users:'',
        type:''
    };

    componentDidMount() {
        this.setState({
            start: this.state.dateRange.start.format('YYYY-MM-DD'),
            end: this.state.dateRange.end.format('YYYY-MM-DD')
        })
    }

    handleTableButtonAction = (id, type) => {
        this.props.onTableAction(id, type);
    };

    renderTableActionButtons = (id, full) => {
        const { t } = this.props;
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const showStatus = (user_type === 'pm' || user_type === 'admin') && customerFeature('allow_accepting');
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');
        let statusText = full.status;
        if (!statusText) statusText = 'active';
        statusText = statusMap[statusText] || '';
        return (
            <div className="actions-center">
                {customerFeature('allow_accepting') && (
                    <div
                        className={`status-block status-${full.status || 'active'}${
                            user_type === 'member' ? ' status-no-click-for-member' : ''
                        }`}
                        onClick={() => (showStatus ? this.handleAccept(id, full) : null)}
                        style={{ marginTop: '-4px', lineHeight: '10px' }}
                    >
                        {t(statusText)}
                        <br />
                        <small>
                            <small>
                                {this.props.commonStore.getUsername(full.status_changed_by)}
                                <br />
                                {full.status_changed_by && moment(new Date(full.status_changed_on)).format(serverTimeFormat())}
                            </small>
                        </small>
                    </div>
                )}
                <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                    <i className="fa fa-edit" />
                </Button>
                {allow && (
                    <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                        <i className="fa fa-times" />
                    </Button>
                )}
            </div>
        );
    };

    handleAccept(id, full) {
        if (full.status == 'approved') return;
        this.setState({
            accepting: {
                ids: [id],
                count: 1,
                entity: 'absence',
            },
        });
    }

    onSelectionChanged(ids) {
        this.setState({ selected: ids });
    }

    onMassAccept() {
        const ids = [];
        if (!this.state.selected) return;
        Object.keys(this.state.selected).forEach(id => {
            if (this.state.selected[id]) ids.push(id);
        });
        this.setState({
            acceptanceChanged: false,
            accepting: {
                ids,
                count: ids.length,
                entity: 'absence',
            },
        });
    }

    afterMassAccept() {
        this.props.absenceStore.resetLastListLoadTime();
        this.setState({ accepting: null, acceptanceChanged: true });
    }

    handleAbsenceReportDownload = () => {
        const { t } = this.props;
        this.setState({
            showDownloadReport: true
        });
    }

    handleClosesDownloadReportModal = () => {
        this.setState({
            showDownloadReport: false,
            all_members: '',
            users: '',
            type: ''
        });
    };
    
    applyDownloadReportForm = () => {
        const { t } = this.props;
        if (this.state.users.length <= 0 && !this.state.all_members) {
            this.props.commonStore.addNotification(t('Choose users'), null, 'error');
            return;
        }

        if (!this.state.type) {
            let text = t('Select') + " " + t('Absence types');
            this.props.commonStore.addNotification(text, null, 'error');
            return;
        }

        let hydratedPayload= {
            start:this.state.start,
            end:this.state.end,
            all_members:this.state.all_members,
            users: this.state.users,
            type: this.state.type,
            authorization: this.props.commonStore.token
        }

        window.open(`${config.API_ROOT}/absences/report/main${agent.convertToGetParams(hydratedPayload)}`);
        this.handleClosesDownloadReportModal();
    };

    handleDownloadFormInputChange = (key, value) => {
        this.setState({[key] : value})
    }    

    //handles generic list page change//
    handlePageChange = (page,isEdit) => {
        this.props.handlePageChange(page,isEdit);
    }

    render() {
        const { loading, filters, appliedFilters } = this.props.absenceStore;
        const { user_type } = this.props.userStore.currentUser;
        const { t } = this.props;
        const { accepting, acceptanceChanged } = this.state;
        const showStatus = user_type === 'pm' && customerFeature('allow_accepting');
        const {config} = this.props.commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;

        return (
            <Fragment>
                <Modal size="lg" show={!!accepting} onHide={() => this.setState({ accepting: null })}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Accept/reject')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <ModalEditor params={accepting} note={"fdhjdsgjhds"} afterSave={() => this.afterMassAccept()} />
                    </Modal.Body>
                </Modal>
                {(user_type === 'member' || user_type === 'pm') && <AbsencesTotals />}
                <div className="table-list">
                    <div className="table-list__header">
                        <Button fill wd icon onClick={() => this.handleAbsenceReportDownload()}>
                            {this.props.t('Download Excel')} <i className="fa fa-plus" />
                        </Button>
                        <Button fill wd icon onClick={() => this.props.onAdd()}>
                            {this.props.t('Add new')} <i className="fa fa-plus" />
                        </Button>
                    </div>
                    <GenericList
                        columns={[
                            {
                                Header: 'From date',
                                id: 'from',
                                sortable: true,
                                Cell: ({ original }) => shortDateFormat(original.from, dateTimeRules),
                            },
                            {
                                Header: 'To date',
                                id: 'to',
                                sortable: true,
                                Cell: ({ original }) => shortDateFormat(original.to, dateTimeRules),
                            },
                            user_type === 'member'
                                ? null
                                : {
                                    Header: 'Member',
                                    id: 'last_name',
                                    accessor: ({ first_name, last_name }) => (
                                        <span>{`${first_name} ${last_name}`}</span>
                                    ),
                                },
                            {
                                Header: 'Total days',
                                id: 'total_days',
                                accessor: ({ total_days }) => <span>{total_days}</span>,
                            },
                            {
                                Header: 'Type',
                                id: 'absence_type',
                                accessor: ({ absence_type, leave_type }) => <span>{t(absence_type)} / {leave_type =='paid'?t('Paid'):t('Not paid')}</span>,
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                sortable: false,
                                accessor: full => this.renderTableActionButtons(full.id, full),
                            },
                        ]}
                        onSelectionChanged={selection => this.onSelectionChanged(selection)}
                        allowCheckboxes={showStatus}
                        lastListLoadTime={this.props.absenceStore.lastListLoadTime}
                        header={(
                            <AbsenceListFilter
                                filters={filters}
                                setFilter={(name, value) => this.props.absenceStore.setFilter(name, value)}
                                onFilter={() => this.props.absenceStore.onFilter()}
                                loading={loading}
                                onChangeStatus={() => this.onMassAccept()}
                                showStatus={showStatus}
                            />
                        )}
                        forceReload={this.props.absenceStore.deleteSuccess || this.state.acceptanceChanged}
                        filters={appliedFilters}
                        requestData={params => this.props.absenceStore.loadList(params)}
                        handlePageChange = {(page,isEdit)=>this.handlePageChange(page,isEdit)}
                        page = {this.props.page}//new page props to maintain page from parent//
                        isPageAddedOrEdited = {this.props.isPageAddedOrEdited}//to load same page after edit//
                    />
                </div>
                <Modal
                    className="modal fade"
                    show={this.state.showDownloadReport}
                    onHide={this.handleClosesDownloadReportModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Download Absence Report')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AbsenceReportDownloadModal 
                            handleDownloadFormInputChange = {this.handleDownloadFormInputChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.applyDownloadReportForm} className="btn-wd btn-fill">
                            {t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleClosesDownloadReportModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}
export default AbsencesList;
