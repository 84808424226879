import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import UsersList from './UsersList';
import UsersForm from './UsersForm';
import ImportUsers from './ImportUsers';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

@inject('authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class UsersPage extends Component {
    //added state to maintain the genericList and genericForm states in parent component//
    state={
        mode:{
            id:0,
            add:false,            
        },
        page:0,
        isPageAddedOrEdited:false
    }

    //removed URL pushing//
    onAdd() {
        // const { history } = this.props;
        // history.push('/admin/members/add');
        const mode={id:0,add:true};
        this.setState({mode:mode,isPageAddedOrEdited:true});   
    }

    onTableAction(id, type) {
        const { history, authStore } = this.props;
        if (type === 'loginas') {
            authStore
                .loginAs(id)
                .then(() => {
                    window.location.href = `${window.location.href.split('/admin')[0]}/admin/?reload`;
                    // history.push(`/admin`);
                })
                .catch(e => {
                    this.props.commonStore.addNotification(e.message || this.props.t('Error'), null, 'error');
                });
            return;
        }
        if (type === 'remove') {
            return this.props.handleDelete(id, id => {
                return this.props.userStore.remove(id).then(res => {
                    this.props.userStore.resetLastListLoadTime();
                    return res;
                });
            });
        }
        // history.push(`/admin/members/${id}`);
        const mode={id:id,add:false};
        this.setState({mode:mode,isPageAddedOrEdited:true});
    }

    // editingId = () => {
    //     let id = null;
    //     if (this.props.location.pathname !== this.props.match.path) {
    //         id = this.props.location.pathname.replace(this.props.match.path, '');
    //         id = id.replace('/', '');
    //     }
    //     return {
    //         editing: !!id,
    //         add: id === 'add',
    //         importing: id === 'import',
    //         id,
    //     };
    // };

    //this method is replacing the URL pushing part while add/edit//
    goBack = () =>{        
        const mode = {id:0,add:false};
        this.setState({mode:mode});
    }

    //method to maintain react-table page in parent component//
    handlePageChange = (page,isEdit) => {
        this.setState({page:page,isPageAddedOrEdited:isEdit});        
    }

    render() {
        // const mode = this.editingId();
        const {mode,page,isPageAddedOrEdited}= this.state;
        // console.log("This is members");
        return (
            <div className="main-content">
                 {(mode.id == 0 && mode.add == false) ? 
                    <UsersList
                        onTableAction={(id, type) => this.onTableAction(id, type)}
                        mode="members"
                        onAdd={() => this.onAdd()}
                        handlePageChange = {(page,isEdit)=>this.handlePageChange(page,isEdit)}
                        page = {page}
                        isPageAddedOrEdited = {isPageAddedOrEdited}
                    />
                :
                    <UsersForm 
                        id={mode.id} add={mode.add} mode="members" 
                        goBack={()=>this.goBack()}
                    />
                }
                
                {/* {!mode.editing && (
                    <UsersList
                        onTableAction={(id, type) => this.onTableAction(id, type)}
                        mode="members"
                        onAdd={() => this.onAdd()}
                    />
                )}
                {mode.editing && !mode.importing && <UsersForm id={mode.id} add={mode.add} mode="members" />}
                {mode.importing && <ImportUsers />} */}
            </div>
        );
    }
}
export default UsersPage;
