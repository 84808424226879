import React from 'react';
import { getTimeFormat, timeFormat } from '../../../utils/dateFormat';


function resolver({ params }) {
    const {
        schedulerData,
        event,
        bgColor,
        isStart,
        isEnd,
        mustAddCssClass,
        mustBeHeight,
        agendaMaxEventWidth,
        t,
        dateTimeRules
    } = params;

    const borderWidth = isStart ? '4' : '0';
    let borderColor = 'rgba(0,139,236,1)';
    let backgroundColor = '#80C5F6';
    let titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, event);
    //let taskName = schedulerData.behaviors.getEventTaskName(schedulerData,event).substring(0,10) + "..."; 
    let taskName = schedulerData.behaviors.getEventTaskName(schedulerData,event);
    if (event.source === 'vacation' || event.source === 'absence') {
        borderColor = 'rgba(245,60,43,1)'; // 'rgba(0,139,236,1)' : (event.type == 3 ? 'rgba(245,60,43,1)' : '#999');
        backgroundColor = '#FA9E95 !important'; // event.type == 1 ? '#80C5F6' : (event.type == 3 ? '#FA9E95' : '#D9D9D9')
    }
    if (event.source === 'vacation') {
        titleText = t('Vacation');
    }
    if (event.source === 'absence') {
        titleText = t('Absent');
    }
    if (event.availability_type === 'available') {
        titleText = t('Available');
    }
    if (event.availability_type === 'not available') {
        titleText = t('Not Available');
    }
     let divStyle = { borderLeft: `${borderWidth}px solid ${borderColor}`, backgroundColor, height: mustBeHeight };
     //let divStyle = { borderLeft: `${borderWidth}px solid ${borderColor}`, backgroundColor, height };
    
    if (agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
    let clockText = ''
    if(event.from == '12:00 am' && event.to =='12:00 am'){
        clockText = ''
    }
    else{
        clockText = event.from + ' -' + event.to
    }

    return (
        <div key={event.id} className={event.resourceId===0?'shift_box':(event.source === 'vacation' || event.source === 'absence'?'change_bg': event.availability_type == 'available'  ? 'change_bg_available' : event.availability_type == 'not available' ? 'change_bg_notAvailable' :mustAddCssClass)} style={divStyle}>
            {event.addBonus ? 
                <>
                {
                  event.workLogStatus!=='none' &&
                   <span style={{fontSize:'11px',display:'block',marginLeft:'4px'}}>(t{event.workLogStatus})</span>
                } 
                <span style={{ marginLeft: '4px', fontSize:'11px' }}>{titleText}</span>
                <span> </span>
                <span style={{ marginLeft: '10px',  background: '#F39C12', borderRadius: '50%', color: 'black', fontWeight: 'bold', padding: '0 4px 0 3px' }}>+</span>
                </>
                : 
                <>
                {/* <span style={{ marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}>{taskName}</span> */}
              {
                  event.workLogStatus!=='none' &&
                   <span style={{fontSize:'11px',display:'block',marginLeft:'4px'}}>{event.workLogStatus}</span>
              } 
              {
                  event.workLogStatus==='none' &&
                  <span style={{ marginLeft: '4px', display:'block',fontSize:'11px' }}>{taskName}</span>
              } 
                
                <span style={{ marginLeft: '4px',fontSize:'11px' }}>{titleText}</span>
                {(event.availability_type == 'available' || event.availability_type == 'not available') &&
                    <span style={{ marginLeft: '4px', fontSize: '11px', marginTop: '-2px' }}>{`${clockText}`}</span>
                }
                
                </>}
        </div>
    );
}

export default resolver;
