import React, { Component } from 'react';
import Switch from 'react-switch';

class TrueFalceWidget extends Component {
    render() {
        const { value, onChange } = this.props;
        return (
            <div>
                <Switch checked={!!value} onChange={checked => onChange(!!checked)} />
            </div>
        );
    }
}

export default TrueFalceWidget;
