import React, { Component, Fragment } from 'react';
import { Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import withLocalization from '~/hoc/withLocalization';
import { defaultLanguage } from '~/library/core';

@inject('commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class HeaderLinks extends Component {
    handleLangChange = (lang) => {
        this.props.commonStore.setLocale(lang);
        this.props.commonStore.locale = lang;
    };

    handleProfile = () => {
        this.props.history.push('/admin/profile');
    };

    handleReport = () => {
        this.props.history.push('/admin/send_report');
    };

    langName = (lang) => {
        if (lang == 'ru') return 'Russian';
        if (lang == 'no') return 'Norsk';
        if (lang == 'en') return 'English';
        if (lang == 'lt') return 'Lithuanian';
        if (lang == 'es') return 'Spanish';
        if (lang == 'gr') return 'Greek';
        if (lang == 'pl') return 'Polish';
        return defaultLanguage();
    };

    render() {
        const { handleLogOut, t, commonStore, userStore } = this.props;
        const { biztype } = commonStore.config;
        const { currentUser, newNotificationsCount } = userStore;
        //console.log('rerender', newNotificationsCount);
        const biztypeData = biztype ? biztype.data : { hide_send_report: true };
    
        // const { data:biztypeData } = commonStore.config.biztype;
        return (
            <div>
                {/* <Navbar.Form pullLeft className="navbar-search-form"> */}
                {/* <FormGroup> */}
                {/* <InputGroup> */}
                {/* <InputGroup.Addon> */}
                {/* <i className="fa fa-search" /> */}
                {/* </InputGroup.Addon> */}
                {/* <FormControl type="text" placeholder="Search..." /> */}
                {/* </InputGroup> */}
                {/* </FormGroup> */}
                {/* </Navbar.Form> */}
                <Nav pullRight activeKey={0}>
                    <Fragment>
                        {!biztypeData.hide_send_report && currentUser.user_type === 'member' && (
                            <li className="dropdown">
                                <a
                                    id="basic-nav-dropdown-0"
                                    role="button"
                                    className="dropdown-toggle"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    onClick={() => this.handleReport()}
                                >
                                    <div>
                                        <i className="fa fa-address-book" />
                                        <p className="">{t('Send report')}</p>
                                    </div>
                                </a>
                            </li>
                        )}
                        <li className="dropdown nav-item">
                            <a
                                href="#"
                                className={`dropdown-toggle nav-link notification-button ${
                                    newNotificationsCount > 0 ? 'notification-button-active' : ''
                                }`}
                                onClick={() => userStore.showNotificationDock()}
                            >
                                <i className="pe-7s-bell" style={{ fontWeight: 900 }} />
                                {newNotificationsCount > 0 && <span>{newNotificationsCount}</span>}
                                <p>
                                    <span className="d-lg-none">{t('Notifications')}</span>
                                </p>
                            </a>
                        </li>
                        <NavDropdown
                            title={
                                <div>
                                    <i className="fa fa-globe" />
                                    <p className="">
                                        {this.langName(this.props.commonStore.locale)}
                                        <b className="caret" />
                                    </p>
                                </div>
                            }
                            noCaret
                            id="basic-nav-dropdown-1"
                        >
                            <MenuItem onClick={() => this.handleLangChange('en')}>English</MenuItem>
                            <MenuItem onClick={() => this.handleLangChange('no')}>Norsk</MenuItem>
                            <MenuItem onClick={() => this.handleLangChange('pl')}>Polish</MenuItem>
                            <MenuItem onClick={() => this.handleLangChange('lt')}>Lithuanian</MenuItem>
                            <MenuItem onClick={() => this.handleLangChange('ru')}>Russian</MenuItem>
                            <MenuItem onClick={() => this.handleLangChange('es')}>Spanish</MenuItem>
                            <MenuItem onClick={() => this.handleLangChange('gr')}>Greek</MenuItem>
                        </NavDropdown>
                        {/* <NavDropdown */}
                        {/* eventKey={3} */}
                        {/* title={ */}
                        {/* <div> */}
                        {/* <i className="fa fa-bell-o" /> */}
                        {/* <span className="notification">5</span> */}
                        {/* <p className="hidden-md hidden-lg"> */}
                        {/* Notifications */}
                        {/* <b className="caret" /> */}
                        {/* </p> */}
                        {/* </div> */}
                        {/* } */}
                        {/* noCaret */}
                        {/* id="basic-nav-dropdown-2" */}
                        {/* > */}
                        {/* <MenuItem eventKey={3.1}>Notification 1</MenuItem> */}
                        {/* <MenuItem eventKey={3.2}>Notification 2</MenuItem> */}
                        {/* <MenuItem eventKey={3.3}>Notification 3</MenuItem> */}
                        {/* <MenuItem eventKey={3.4}>Notification 4</MenuItem> */}
                        {/* <MenuItem eventKey={3.5}>Another notifications</MenuItem> */}
                        {/* </NavDropdown> */}
                        <li className="dropdown nav-item">
                            <a href="#" className="dropdown-toggle nav-link text-danger" onClick={handleLogOut}>
                                <i className="pe-7s-close-circle" />
                                <p className="d-lg-none">{t('Log out')}</p>
                            </a>
                        </li>
                    </Fragment>
                </Nav>
            </div>
        );
    }
}
export default HeaderLinks;
