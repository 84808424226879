import React from 'react';

import NO from './../assets/img/NO.svg';
import US from './../assets/img/US.svg';
import RU from './../assets/img/RU.svg';
import PL from './../assets/img/PL.svg';
import ES from './../assets/img/ES.svg';
import LT from './../assets/img/LT.svg';
import SE from './../assets/img/SE.svg';
import GR from './../assets/img/GR.svg';


export const switchFlag = (flag) => {
    switch (flag) {        
        case 'US':
            return US;
        case 'RU':
            return RU;
        case 'PL':
            return PL;
        case 'ES':
            return ES;
        case 'SE':
            return SE;
        case 'LT':
            return LT;
        case 'GR':
            return GR;
        default:
            return NO;
    }
};

const Flags = ({ width, height, flag }) => (
    <div>
        <img src={switchFlag(flag)} style={{ width: width, height: height }} />
    </div>
);

export default Flags;
