import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import LeftArrow from './../../../assets/img/left-arrow.svg'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Button from '../../../components/CustomButton/CustomButton.jsx';
import contractStore from '../../../library/stores/contractStore';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/ck-build/ckeditor';



const editorConfiguration = {
  title: '',
  toolbar: ['heading', '|',
    'alignment', '|',
    'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|', 'PageBreak',
    'link', '|',
    'bulletedList', 'numberedList', 'todoList',
    '-', // break point
    'fontfamily', 'fontColor', 'fontBackgroundColor', '|',
    'code', 'codeBlock', '|',
    'insertTable', '|',
    'outdent', 'indent', '|',
    'uploadImage', 'blockQuote', '|',
    'undo', 'redo'],

};


@inject('documentStore', 'authStore', 'userStore', 'commonStore', 'contractStore')
@withLocalization
@withRouter
@observer
class ContractsEditor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editorContent: '',
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' },
          { 'indent': '-1' }, { 'indent': '+1' }],
          ['link', 'image', 'video'],
          ['clean']
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        }
      },
      formats: [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
      ],

      placeholder: 'Write Something',
    }
    this.handleSave = this.handleSave.bind(this);
    this.getListUrl = this.getListUrl.bind(this);
  }

  componentDidMount() {

  }

  loadData() {
    const { contract_id, contractStore } = this.props;
    if (contract_id) {
      if (contractStore.contractEditorDetails && contractStore.contractEditorDetails.body)
        this.setState({ editorContent: contractStore.contractEditorDetails.body });
    }
  }



  getListUrl() {
    const { history } = this.props;
    history.push("/admin/contract_templates");
  }

  handleTextChange(value) {

    this.setState({ editorContent: value });
  }
  handleSave() {
    const { contractStore, commonStore, contract_id, t } = this.props;
    const { editorContent } = this.state;
    if (editorContent) {
      // let getKeys = editorContent.match(/[{{]+[\s&nbsp;a-zA-Z0-9_$\s&nbsp;]+[}}]+/g);
      let getKeys = editorContent.match(/\[[^\]]*]/g);
      // getKeys = (getKeys && getKeys.length) ? getKeys.toString().split(' ').join('_').split(', ') : [];

      // if (getKeys && getKeys.length) {
      //   let checkDuplicateKeys = getKeys.filter((e, i, a) => a.indexOf(e) !== i)
      //   if (checkDuplicateKeys && checkDuplicateKeys.length)
      //     return commonStore.addNotification('Document Keys Cannot be Duplicate', null, 'error');
      // }

      let payload = {
        id: contractStore.contractEditorDetails ? contractStore.contractEditorDetails.id : null,
        editorBody: editorContent,
        editorKeys: getKeys && getKeys.length ? getKeys : [],
        contract_id: contractStore.currentContractDetails.id
      }
      contractStore.saveEditorData(payload).then(response => {
        if (response.status) {
          commonStore.addNotification(t('Saved'), null, 'success');
          this.setState({ editorContent: '' });
          this.getListUrl();
        }
      })
    }
    else {
      commonStore.addNotification(t('Please Add Data'), null, 'error');
    }
  }

  handleCancelClick() {
    const { history } = this.props;
    history.push("/admin/contract_templates");
  }

  render() {
    const { contract_type_selected, t } = this.props;
    return (
      <>
        <div style={{ padding: '20px' }}>
          <p style={{ color: 'grey', cursor: 'pointer' }} ><img onClick={this.props.handleGoBack} src={LeftArrow} alt="left arrow" /> &nbsp;{t('Go Back')}</p>
          <div className="table-list__header_contract" >
            <Button fill wd icon onClick={this.handleSave}>{t('Save Now')}</Button>
            <Button onClick={this.getListUrl}>{t('Cancel')}</Button>
          </div>
          <div className='main-table' style={{ width: '62%' }}>
            <div style={{ color: 'grey' }} className='row'>
              <p className='col-md-3'>{t('Country')}</p>
              <p className='col-md-3'>{t('Contract Type')}</p>
              <p className='col-md-3'>{t('Module')}</p>
              <p className='col-md-3'>{t('Document Name')}</p>
            </div>

            <div className='row'>
              <h6 className='col-md-3'>{contractStore.currentContractDetails.country || " "}</h6>
              <h6 className='col-md-3 doc-name' title={contract_type_selected.label}>{contract_type_selected ? contract_type_selected.label : " "}</h6>
              <h6 className='col-md-3'>{contractStore.currentContractDetails.module || ""}</h6>
              <h6 className='col-md-3 doc-name' title={contractStore.currentContractDetails.name}>{contractStore.currentContractDetails.name || " "}</h6>
            </div>
          </div>

          <h6 style={{ fontWeight: 'bolder', paddingTop: '20px' }}>{t('Create New Contract')}</h6>
          <hr style={{ border: '0.5px solid grey' }} />
          <div style={{ margin: "15px 0px",fontWeight: 'bolder' }}>
            <h6 style={{display:'inline-block'}}>{t('Note :')}</h6> <span style={{fontWeight:'lighter !important',color: 'grey'}}>{t('Anything between [ ] will be considered as keys.')}</span>
          </div>
          <div className='ckContracts' style={{marginTop:"10px"}}>
            {/* <DocumentEditor
              name="document_description"
              value={this.state.editorContent}
              onChange={value => { this.handleTextChange(value) }}
              isContracts = {true}
           
            /> */}
            {/* <ReactQuill
                    theme = {this.state.theme}
                    onChange={}
                    value=
                    modules={this.state.modules}
                    formats={this.state.formats}
                    // readOnly={this.props.isEmployee}
                    placeholder={this.state.placeholder}                    
                    style={{ border: '0px',width:'210mm', minHeight:'200px', padding:'10px', color:'black'}}
                /> */}



            <CKEditor
              editor={Editor}
              data={this.state.editorContent}
              config={editorConfiguration}
              onReady={editor => {
                this.loadData();
              }}

              onChange={(event, editor) => {
                const data = editor.getData();
                this.handleTextChange(data)
              }}
            />

          </div>
        </div>
      </>
    )
  }
}
export default ContractsEditor;
