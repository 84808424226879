// import { Link } from 'react-router-dom';
import React from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
    Grid,
    Row,
    Col,
    FormGroup,
    //  ControlLabel,
    FormControl,
    Media,
} from 'react-bootstrap';

import Card from '../../components/Card/Card.jsx';
import PrimaryLayout from '../../layouts/PrimaryLayout.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';

// Components
import ListErrors from '../../components/ListErrors';
import withLocalization from '../../hoc/withLocalization';

@inject('authStore')
@withLocalization
@observer
class Register extends React.Component {
    componentWillUnmount() {
        const { authStore } = this.props;
        authStore.reset();
    }

    handleInputChange = e => {
        this.props.authStore.formChange(e.target.name, e.target.value);
    };

    handleSubmitForm = e => {
        e.preventDefault();
        const { authStore, history, t } = this.props;
        const { values } = authStore;
        const errors = [];
        if (values.username.length < 5) {
            errors.push('Username is too short');
        }
        if (values.password.length < 5) {
            errors.push('Passwords is too short');
        }
        if (values.password !== values.confirmPassword) {
            errors.push('Passwords do not match');
        }
        if (!Object.keys(errors).length) {
            authStore.register().then(() => {
                // const lastPage = localStorage.getItem('lastPage');
                const lastPage = sessionStorage.getItem('lastPage');
                if (lastPage) {
                    // localStorage.removeItem('lastPage');
                    sessionStorage.removeItem('lastPage');
                    history.replace(lastPage);
                } else {
                    history.replace('/login');
                }
            });
        } else {
            this.props.authStore.setError(errors);
        }
    };

    render() {
        const { authStore, t } = this.props;
        const { values, errors, frontErrors } = authStore;
        return (
            <PrimaryLayout>
                <div className="register-page">
                    <Grid>
                        <Row>
                            <Col md={8} mdOffset={2}>
                                <div className="header-text">
                                    <h2>Norsk Timeregister</h2>
                                    <h4>{t('Register for free and experience the timetracking today')}</h4>
                                    <hr />
                                </div>
                            </Col>
                            <Col md={4} mdOffset={2}>
                                <Media>
                                    <Media.Left>
                                        <div className="icon">
                                            <i className="pe-7s-user" />
                                        </div>
                                    </Media.Left>
                                    <Media.Body>
                                        <Media.Heading>{t('Free Account')}</Media.Heading>
                                    </Media.Body>
                                </Media>
                                <Media>
                                    <Media.Left>
                                        <div className="icon">
                                            <i className="pe-7s-graph1" />
                                        </div>
                                    </Media.Left>
                                    <Media.Body>
                                        <Media.Heading>{t('Awesome Performance')}</Media.Heading>
                                    </Media.Body>
                                </Media>
                                <Media>
                                    <Media.Left>
                                        <div className="icon">
                                            <i className="pe-7s-headphones" />
                                        </div>
                                    </Media.Left>
                                    <Media.Body>
                                        <Media.Heading>{t('Global Support')}</Media.Heading>
                                    </Media.Body>
                                </Media>
                            </Col>
                            <Col md={4}>
                                <Card
                                    plain
                                    content={(
                                        <form onSubmit={this.handleSubmitForm}>
                                            <div>
                                                <FormGroup>
                                                    <FormControl
                                                        type="text"
                                                        placeholder="Username"
                                                        onChange={this.handleInputChange}
                                                        name="username"
                                                        value={values.username}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControl
                                                        type="email"
                                                        placeholder="Enter Email"
                                                        onChange={this.handleInputChange}
                                                        name="email"
                                                        value={values.email}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControl
                                                        type="password"
                                                        placeholder="Password"
                                                        autoComplete="off"
                                                        onChange={this.handleInputChange}
                                                        name="password"
                                                        value={values.password}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormControl
                                                        type="password"
                                                        autoComplete="off"
                                                        placeholder="Password Confirmation"
                                                        onChange={this.handleInputChange}
                                                        name="confirmPassword"
                                                        value={values.confirmPassword}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <ListErrors errors={toJS(errors)} />
                                            <ListErrors errors={toJS(frontErrors)} />
                                        </form>
                                    )}
                                    ftTextCenter
                                    legend={(
                                        <Button wd fill neutral onClick={this.handleSubmitForm}>
                                            {t('Create Free Account')}
                                        </Button>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </PrimaryLayout>
        );
    }
}
export default Register;
