import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';

 class ExistingShiftsModal extends Component {

    constructor(props){
        super(props);
    }
   
    closeUserModal(){
        this.props.closeUserModal();
    }
    

    render() {
        
        return (
            <div>
                  <Modal dialogClassName="lg" className="lg" size="lg" show  onHide={()=>this.closeUserModal()} >
                    <Modal.Header closeButton className="set_ModalHeader">
                        <Modal.Title>
                            {this.props.t('User with Existing Shifts')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <table className="table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>{this.props.t('SR. No')}</th>
                        <th>{this.props.t('Name')}</th>
                        <th>{this.props.t('For Date')}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.userWithFutureShifts && this.props.userWithFutureShifts.map((user,index) =>
                        <tr key={index}>
                            <td>{index}</td>
                            <td>{user.name}</td>
                            <td>{user.forDate}</td>
                        </tr>
                    )}
                </tbody>
                    </table>
                    </Modal.Body>
                    <Modal.Footer style={{textAlign:'left'}}>
                        <span style={{color:'red'}}>{this.props.t("Could'nt copy shifts for these users, as they already have previously created shifts")}</span>
                    </Modal.Footer>
                    </Modal>
            </div>
        )
    }
}

export default ExistingShiftsModal
