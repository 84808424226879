import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Import from '~/components/Import/CampaignStepper';
import getSchema from '~/library/schemas/user';

@inject('authStore', 'userStore', 'commonStore')
@withRouter
@observer
class ImportUsers extends Component {
    render() {
        const schema = getSchema({ config: this.props.commonStore.config });
        const schemaArray = [];
        Object.keys(schema.properties).forEach(key => {
            schemaArray.push({ id: key, field: schema.properties[key] });
        });
        return (
            <div>
                <Import
                    schema={schemaArray}
                    validations={null}
                    saveSingle={row => this.props.userStore.save(row, true)}
                />
            </div>
        );
    }
}
export default ImportUsers;
