import React, { Component } from 'react';
import { Rnd } from 'react-rnd';
import signWidget from '../../../../assets/img/signature-widget.svg';
import signWidgetNO from '../../../../assets/img/no/signature-widget-no.svg';
import signWidgetEs from '../../../../assets/img/es/signature-widget-es.svg';
import signWidgetGr from '../../../../assets/img/gr/signature-widget-gr.svg';
import LoadingSpinner from '~/elements/LoadingSpinner';
import signWidgetLt from '../../../../assets/img/lt/signature-widget-lt.svg';
import signWidgetPl from '../../../../assets/img/pl/signature-widget-pl.svg';
import signWidgetRu from '../../../../assets/img/ru/signature-widget-ru.svg';
import signWidgetSe from '../../../../assets/img/se/signature-widget-se.svg';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../../hoc/withLocalization';
import SignatureModal from './../Recipients/public/signatureModal';
import signatureStore from '../../../../library/stores/signatureStore';

@inject('propertyStore', 'recipientStore', 'signatureStore', 'propertyStore')
@withLocalization
@observer
export class SignWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      x: this.props.info.widgetX || 0,
      y: this.props.info.widgetY || 0,
      width: this.props.info.width || 100,
      height: this.props.info.width || 70,
      showSignatureModal: false,
      signature_img: this.props.info.data || null
    }
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSignatureSave = this.handleSignatureSave.bind(this);
  }
  setActiveWidget(widget) {
    const { propertyStore } = this.props;
    propertyStore.setActiveWidget(widget)
    if(this.props.edit && !widget.data && !this.props.view){
      this.setState({ showSignatureModal: true });
    }
  }

  hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',.4)';
    }
    throw new Error('Bad Hex');
  }

  onDragStop = (evt, position, at) => {
    const { propertyStore, info } = this.props;
    const DEFAULT_WIDTH = 150;
    const DEFAULT_HEIGHT = 70;

    const updatedWidth = (propertyStore.activeWidget && propertyStore.activeWidget.width) ? propertyStore.activeWidget.width : ( this.state.width ? this.state.width : DEFAULT_WIDTH);
    const updatedHeight = (propertyStore.activeWidget && propertyStore.activeWidget.height) ? propertyStore.activeWidget.height : ( this.state.height ? this.state.height : DEFAULT_HEIGHT);

    const widInfo = { x: position.x, y: position.y , width:updatedWidth, height:updatedHeight};  	
    propertyStore.updateWidgetRnd(info.widgetId, widInfo)
  }
  onResizeStop = (e, direction, ref, delta, position) => {
    const widInfo = { x: position.x, y: position.y, width: ref.style.width, height: ref.style.height }
    const { propertyStore, info } = this.props;
    propertyStore.updateWidgetRnd(info.widgetId, widInfo)
  }
  handleCloseModal() {
    this.setState({ showSignatureModal: false });
  }
  handleSignatureSave(type, image) {
    const { signatureStore, recipientDetail, caseId, propertyStore } = this.props;
    let data = {
      case_id: caseId,
      sign_type: type,
      sign_data: image,
      recipient_id: recipientDetail.id
    }
    let widgetsData = {
      caseId: signatureStore.caseId,
      widgets: propertyStore.widgets
    }

    signatureStore.storeSignature(data).then(res => {
      propertyStore.updateWidgetData(this.props.info, 'data', res.result.sign_data);
    //   propertyStore.saveWidget(widgetsData);
      this.setState({
        showSignatureModal: false,
        signature_img: res.result.sign_data

      });
    });
  }
  render() {
    const { recipientDetail, caseId } = this.props;
    const { x, y, width, height, showSignatureModal, signature_img } = this.state;
    const widgetColor = this.hexToRgbA(this.props.info ? this.props.info.colorCode : "#ffd65b");
    let loc = sessionStorage.getItem("locale");
    const{signatureLoading} = this.props.signatureStore;
    let getLocaleWidget;
    switch(loc){
      case 'en' : getLocaleWidget = signWidget;break;
      case 'no' : getLocaleWidget = signWidgetNO;break;
      case 'es' : getLocaleWidget = signWidgetEs;break;
      case 'gr' : getLocaleWidget = signWidgetGr;break;
      case 'lt' : getLocaleWidget = signWidgetLt;break;
      case 'pl' : getLocaleWidget = signWidgetPl;break;
      case 'ru' : getLocaleWidget = signWidgetRu;break;
      case 'se' : getLocaleWidget = signWidgetSe;break;
      default : getLocaleWidget = signWidgetNO;
    }
    if (signatureLoading) return <LoadingSpinner />;

    // signatureStore.setUserLocale(sessionStorage.getItem("locale"));
    return (
      <>
        {
          this.props.locked && (
            <SignatureModal showModal={showSignatureModal} handleSignatureSave={this.handleSignatureSave} recipientDetail={recipientDetail} caseId={caseId} handleCloseModal={this.handleCloseModal} />
          )
        }
        <Rnd
          default={{
            x,
            y,
            width,
            height
          }}
          onDragStop={this.onDragStop}
          onResizeStop={this.onResizeStop}
          lockAspectRatio="true"
          bounds="parent"
          disableDragging={this.props.locked}
          enableResizing={!this.props.locked}
        >
          <div className="signWidgetCon" onClick={() => { this.setActiveWidget(this.props.info) }}>
            <img src={signature_img ? signature_img : getLocaleWidget} alt="singature widget" draggable="false" 
           // style={ this.props.edit ? { resize: 'none' }  : this.props.view ?  { resize: 'none',border: 'none',overflow: 'hidden', background: 'transparent' } :{backgroundColor: widgetColor, resize: 'none'}}
            style={this.props.view ? {backgroundColor: 'none'}:this.props.edit?{backgroundColor: 'none'}:{backgroundColor:widgetColor} }></img>
          </div>
        </Rnd>
      </>
    )
  }
}

export default SignWidget