import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';

import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import getSchema from '~/library/schemas/project';

const fieldsToShow = [
    {
        title: 'Project Info',
        items: [
            { id: 'phone', name: 'Phone' },
            { id: 'email', name: 'Email' },
            { id: 'contact_person', name: 'Owner' },
            { id: 'Manager', name: 'Manager' },
        ],
    },
    {
        title: 'Details',
        items: [
            { id: 'address', name: 'Address' },
            { id: 'My_est_hours', name: 'Agreement hours' },
            { id: 'My_est_work', name: 'Agreement work' },
            { id: 'My_employment_grade', name: 'My employment grade' },
            { id: 'status', name: 'Status' },
        ],
    },
];

@inject('projectStore', 'commonStore')
@withRouter
@withLocalization
@observer
class ProjectsForm extends Component {
    getListUrl() {
        return '/admin/projects';
    }

    async loadData() {
        const {
            projectStore, id, add, mode
        } = this.props;
        const project = await projectStore.load(id, add);
    }

    componentWillMount() {
        this.props.projectStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    render() {
        const { projectStore, add, t } = this.props;
        const { loading, currentEntity } = projectStore;

        if (loading) return <LoadingSpinner />;

        const { project } = currentEntity;

        return (
            <div className="primary-page">
                <h3>{project.name}</h3>
                {fieldsToShow.map((group, index) => (
                    <div className="panel view-group" key={`${index}`}>
                        <h4>{t(group.title)}</h4>
                        {group.items.map((item, index2) => (
                            <div className="view-row" key={`${index2}`}>
                                <span className="view-title"> {t(item.name)}</span>
                                <span className="view-value"> {project[item.id]}</span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    }
}

export default ProjectsForm;
