import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
// import clientStore from 'stores/clientStore';

import queryString from 'query-string';

import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import getSchema from '~/library/schemas/client';

const schema = getSchema(true);
const listUrl = '/admin/clients';

const uiSchema = {
    'ui:field': 'layout',
    'ui:layout': [
        {
            image: { md: 4 },
            name: { md: 4 },
        },
        {
            max_users: { md: 4 },
            renew_date: { md: 4 },
            tax_number: { md: 4 },
        },
        {
            address: { md: 4 },
            post_number: { md: 4 },
            post_place: { md: 4 },
        },
        /* {
      description: {
        md: 12,
        render: (props) => {
          const { formData } = props;
          const {   tax_number } = formData;

          return (
            <div>
              <b>Active users</b>: {tax_number}
            </div>
          )
        }
      }
    }, */
    ],
    image: {
        'ui:widget': 'ImageUpload',
        'ui:imageContext': {
            model: 'Client',
            fileType: 'logo',
            id: 0,
        },
    },
    renew_manual: {
        'ui:widget': 'Radio',
    },
};

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class CompanyProfile extends Component {
    loadData() {
        const { clientStore, userStore } = this.props;
        clientStore.load(userStore.currentUser.client_id, false);
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
        this.props.clientStore.setLoading(true);
    }

    onSave(values) {
        const {
            clientStore, history, add, t, commonStore
        } = this.props;
        return clientStore.save(values, false).then(result => {
            if (!result.client || !result.client.id) {
                // some error
                // console.log(result);
                commonStore.addNotification(t('Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');
            // history.push(listUrl);
            return true;
        });
    }

    prepareSchema(_schema) {
        _schema.properties.tax_number.readOnly = true;
        _schema.properties.max_users.readOnly = true;
        return _schema;
    }

    render() {
        const { clientStore, add } = this.props;
        const { loading, currentEntity } = clientStore;
        if (loading) return <LoadingSpinner />;

        return (
            <div className="main-content">
                <GenericForm
                    entity={currentEntity.client}
                    uiSchema={uiSchema}
                    schema={this.prepareSchema(schema)}
                    translationScope="forms.client"
                    onSave={values => this.onSave(values)}
                    listUrl={listUrl}
                    isAdding={add}
                />
            </div>
        );
    }
}

export default CompanyProfile;
