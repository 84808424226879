import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import { NumberInput } from '../../../../elements/Input';
import Select from '../../../../elements/Select';
import SwitchWidget from '../SwitchWidget';

// const autoTimelogOptions = [{ value: 'enabled', label: 'ON auto start', title: 'ON auto start' },
// { value: 'disabled', label: 'OFF auto start', title: 'OFF auto start' },
// { value: 'gps', label: 'GPS check current position, Auto login', title: 'GPS check current position, Auto login' },
// { value: 'gpsmanual', label: 'GPS check current position, Approve login manually', title: 'GPS check current position, Approve login manually' },
// { value: 'autoschedule', label: 'Set Auto Schedule Plan On', title: 'Set Auto Schedule Plan On' }]

const autoTimelogOptionsV2 = [{ value: 'gps', label: 'Auto Clock in/out', title: 'Auto Clock in/out' },
{ value: 'enabled', label: 'Allow clock in/out both manually and with start and finish buttons', title: 'Allow clock in/out both manually and with start and finish buttons' },
{ value: 'autoschedule', label: 'Auto log working hours according to schedule', title: 'Auto log working hours according to schedule' },
{ value: 'gpsmanual', label: 'Allow members to log hours with start and finish buttons only', title: 'Allow members to log hours with start and finish buttons only' },
]

// const autoLogoutOptions = [{ value: 'current', label: 'Save current time', title: 'Save current time' },
// { value: 'old', label: 'Save time when left the workplace', title: 'Save time when left the workplace' },]

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class LoginRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginRules: {
                // autoLogout: 'current', //commented these rules 2022/09/30 as there is no implementation nor any proper usage//
                autoTimelogs: 'enabled',
                gpsAllowedDistance: 0,
                // gpsAllowedTime: 0,
                startBufferTimeForClockIn: 5,
                endBufferTimeForClockOut: 5,
                pmManagesOvertime: false,
            },
        }
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handleUserDataChange = this.handleUserDataChange.bind(this);
        this.translateLabels = this.translateLabels.bind(this);
        this.handleInputRange = this.handleInputRange.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const { loginRules } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.loginRules) {
            const _loginRules = Object.assign({}, currentEntity.client.data.loginRules);
            // loginRules.autoLogout = _loginRules.autoLogout ? _loginRules.autoLogout : 'current';
            loginRules.autoTimelogs = _loginRules.autoTimelogs && _loginRules.autoTimelogs != 'disabled' ? _loginRules.autoTimelogs : 'enabled';
            loginRules.gpsAllowedDistance = _loginRules.gpsAllowedDistance ? _loginRules.gpsAllowedDistance : 0;
            // loginRules.gpsAllowedTime = _loginRules.gpsAllowedTime ? _loginRules.gpsAllowedTime : 0;
            loginRules.startBufferTimeForClockIn = _loginRules.startBufferTimeForClockIn ? _loginRules.startBufferTimeForClockIn : 5;
            loginRules.endBufferTimeForClockOut = _loginRules.endBufferTimeForClockOut ? _loginRules.endBufferTimeForClockOut : 5;
            loginRules.pmManagesOvertime = _loginRules.pmManagesOvertime ? _loginRules.pmManagesOvertime : false;
            this.setState({ loginRules })
        }
    }

    handleUserDataChange = (name, value) => {
        // console.log(name,value);
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data.loginRules) {
            currentEntity.client.data.loginRules[name] = value;
        } else {
            // console.log("No login rules, creating new.");
            let loginRules = {};
            loginRules[name] = value;
            currentEntity.client.data.loginRules = loginRules;
        }
    }

    handleChange = (name, value) => {
        // console.log("SOme : ",name,value);
        const { loginRules } = this.state;
        loginRules[name] = value;
        this.handleUserDataChange(name, value)
        this.setState({ loginRules });
    }

    translateLabels() {
        const { t } = this.props;
        autoTimelogOptionsV2.map(item => {
            item.label = t(item.title)
        })
        // autoTimelogOptionsV2.map(item => {
        //     item.label = t(item.title)
        // })
    }

    handleInputRange = (name, e) => {
        const { loginRules } = this.state;
        const value = Math.ceil(e.target.value / 5) * 5;
        loginRules[name] = value;
        this.handleUserDataChange(name, value)
        this.setState({ loginRules })
    }

    render() {
        const { loginRules } = this.state;
        const { t } = this.props;
        this.translateLabels()
        return (
            <div>
                <Row>
                    <Col sm={6}>
                        {/* <label className="control-label">{t('Automatic timelogs enabled')}</label> */}
                        <label className="control-label">{t('Clocking options')}</label>
                        <Select
                            options={autoTimelogOptionsV2}
                            value={autoTimelogOptionsV2.find(item => item.value === loginRules.autoTimelogs)}
                            onChange={selectedItem => {
                                if(selectedItem.value != "gps" && loginRules.pmManagesOvertime) this.handleChange('pmManagesOvertime', false)
                                this.handleChange('autoTimelogs', selectedItem.value)
                            }}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col sm={6}>
                        <NumberInput
                            label={`${t('GPS max allowed distance (m)')}`}
                            name="gpsAllowedDistance"
                            value={loginRules.gpsAllowedDistance}
                            onChange={evt => {
                                this.handleChange('gpsAllowedDistance', parseInt(evt.target.value, 10));
                            }}
                        />
                    </Col>
                    {/* <Col sm={6}>
                        <NumberInput
                            label={`${t('GPS max allowed time (min)')}`}
                            name="gpsAllowedTime"
                            value={loginRules.gpsAllowedTime}
                            onChange={evt => {
                                this.handleChange('gpsAllowedTime', parseInt(evt.target.value,10));
                            }}
                        />
                    </Col> */}                    
                </Row>
                {loginRules.autoTimelogs == 'gps' && (
                    <>
                        <Row className='mt-10'>
                            {/* <Col sm={6}>
                        <label className="control-label">{t('Automatic timelogs enabled')}</label>
                        <Select
                            options={autoTimelogOptionsV2}
                            value={autoTimelogOptionsV2.find(item=> item.value === loginRules.autoTimelogs)}                        
                            onChange={selectedItem => this.handleChange('autoTimelogs',selectedItem.value )}                            
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col sm={6}>
                        <label className="control-label">{t('Automatic Logout')}</label>
                        <Select
                            options={autoLogoutOptions}
                            value={autoLogoutOptions.find(item=> item.value === loginRules.autoLogout)}                       
                            onChange={selectedItem => this.handleChange('autoLogout',selectedItem.value )}                            
                            style={{ width: '100%' }}
                        />
                    </Col> */}
                            <Col sm={6}>
                                <label className="control-label">{t('Clock in buffer time (In Minutes)')}</label>
                                <div className="slider-parent" style={{ position: "relative" }}>
                                    <input type="range" min="5" max="60"
                                        onChange={event => this.handleInputRange('startBufferTimeForClockIn', event)}
                                        value={loginRules.startBufferTimeForClockIn}
                                    />
                                    <div className="buble">
                                        {loginRules.startBufferTimeForClockIn} {t("Minute(s)")}
                                    </div>
                                </div>
                            </Col>

                            <Col sm={6}>
                                <label className="control-label">{t('Clock out buffer time (In Minutes)')}</label>
                                <div className="slider-parent" style={{ position: "relative" }}>
                                    <input type="range" min="5" max="60"
                                        onChange={event => this.handleInputRange('endBufferTimeForClockOut', event)}
                                        value={loginRules.endBufferTimeForClockOut}
                                    />
                                    <div className="buble">
                                        {loginRules.endBufferTimeForClockOut} {t("Minute(s)")}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-10'>
                            <Col sm={1}>
                                <SwitchWidget
                                    value={loginRules.pmManagesOvertime}
                                    onChange={checked => this.handleChange('pmManagesOvertime', checked)} />
                            </Col>
                            <Col sm={11}>
                                <label className="control-label">{t('Allow PM to manage overtime')}</label>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            {t("Allow PM to manage overtime")}
                                        </Tooltip>
                                    }
                                >
                                    <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </>)}                  
            </div>
        );
    }
}
export default LoginRules;
