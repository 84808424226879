export const schema = {
    title: 'Client',
    type: 'object',
    required: [],
    properties: {
        project_statuses: {
            type: 'array',
            title: 'Project statuses',
            default: [],
            items: {
                type: 'object',
                required: ['code', 'name'],
                properties: {
                    code: { type: 'string' },
                    name: { type: 'string', title: 'Label' },
                },
            },
        },
        task_statuses: {
            type: 'array',
            title: 'Task statuses',
            default: [],
            items: {
                type: 'object',
                required: ['code', 'name'],
                properties: {
                    code: { type: 'string' },
                    name: { type: 'string', title: 'Label' },
                },
            },
        },
        basicRules: {
            type: 'object',
            title: 'Basic Rules',
            properties: {
                selfClarificationPeriod: { type: 'integer', title: 'Self clarification period days', default: 16 },
                absencePeriodForTwoKids: { type: 'integer', title: 'Absence care days for single or two children', default: 20 },
                absencePeriodForMoreThanTwoKids: { type: 'integer', title: 'Absence care days for more than two children', default: 30 },
                allowSingleParentCare: {
                    type: 'boolean',
                    title: 'Allow extra care days for single parent',
                    defaut: false,
                },
                allowQucikChecklist: {
                    type: 'boolean',
                    title: 'Allow quick checklist for users',
                    defaut: false,
                },
                quickCheckListText: { type: 'string', title: 'Quick Checklist text', default: 'Did you get any injury at site today?' },
                // gpsAllowedDistance: { type: 'integer', title: 'GPS max allowed distance (m)', default: 500 },
                // gpsAllowedTime: { type: 'integer', title: 'GPS max allowed time (min)', default: 30 },
                // rulesLink: { type: 'string', title: 'Rules link', default: 'http://google.com' },
                defaultHolidays: { type: 'string', title: 'Default holidays', default: '5,6' },
                // // gpsEnabledForCompany: { type: 'boolean', title: 'GPS enabled for company', defaut: false },
                // autoTimelogs: { type: 'string', title: 'Automatic timelogs enabled',
                //     anyOf: [
                //         {
                //             title: 'OFF auto start',
                //             enum: ['disabled'],
                //         },
                //         {
                //             title: 'Set GPS on for scheduling',
                //             enum: ['gps'],
                //         },
                //         {
                //             title: 'Set GPS off for scheduling',
                //             enum: ['enabled'],
                //         },
                //     ],
                //     default: 'gps' },
                // autoLogout: { type: 'string', title: 'Automatic Logout',
                //     anyOf: [
                //         {
                //             title: 'Save current time',
                //             enum: ['current'],
                //         },
                //         {
                //             title: 'Save time when left the work place',
                //             enum: ['old'],
                //         }
                //     ],
                //     default: 'current' },
                
                allowManualTimelogChange: {
                    type: 'boolean',
                    title: 'Allow change timelog without switching to manual mode',
                    defaut: false,
                },
                allow_overtime_limit: {
                    type: 'boolean',
                    title: 'Allow more then 9 hours without confirmation',
                    defaut: false,
                },
                displayCommentAfterFinish: {
                    type: 'boolean',
                    title: 'Allow to display comment dialog after finish work',
                    defaut: false,
                }, 
                signatureModalAfterWorklog: {
                    type: 'boolean',
                    title: 'Show Signature Modal after finishing work in mobile app',  
                    defaut: false,
                }, 
                workinghourUserphoto: {
                    type: 'boolean',
                    title: 'Allow Capture live image of user when start working hours',
                    defaut: false,
                },   
                workinghourGPSObligatory: {
                    type: 'boolean',
                    title: 'Make GPS obligatory for start/end time in mobile app, without GPS on workslogs cant be registered.',
                    defaut: false,
                }, 
                pmManagesOvertime:{
                    type:'boolean',
                    title:'Allow pm to manage overtime',
                    default:false
                },                       
                maxAllowedTimePeriodToLogOutWithGPSObligatory:{
                    type: 'integer', 
                    title: 'Allowed time for the member to log out before scheduled end time(In Minutes)', 
                    minimum: 5,
                    maximum: 60,
                    default:5,
                    multipleOf:5                
                },   
                // validateTimetrackOption: {
                    //     type: 'boolean',
                //     title: 'Validate timetrack option',
                //     defaut: false,
                // },
                // criticalVarianceThreshold: {
                //     type: 'string',
                //     title: 'Critical variance threshold (hh:mm)',
                //     default: '00:30',
                // },
                // overtimeThreshold: {
                //     type: 'string',
                //     title: 'Overtime threshold (hh:mm)',
                //     default: '00:30',
                // },
                // sendEmailNotificationForCriticalThresholdToAdmin: {
                //     type: 'boolean',
                //     title: 'Send email notification for critical threshold to admin',
                //     defaut: false,
                // },
                // sendEmailNotificationForCriticalThresholdToPM: {
                //     type: 'boolean',
                //     title: 'Send email notification for critical threshold to PM',
                //     defaut: false,
                // },
                // sendEmailNotificationForMediumThresholdToAdmin: {
                //     type: 'boolean',
                //     title: 'Send email notification for medium threshold to admin',
                //     defaut: false,
                // },
                // sendEmailNotificationForMediumThresholdToPM: {
                //     type: 'boolean',
                //     title: 'Send email notification for medium threshold to PM',
                //     defaut: false,
                // },
                // sendEmailNotificationForOvertimeThresholdToAdmin: {
                //     type: 'boolean',
                //     title: 'Send email notification for overtime threshold to admin',
                //     defaut: false,
                // },
                // sendEmailNotificationForOvertimeThresholdToPM: {
                //     type: 'boolean',
                //     title: 'Send email notification for overtime threshold to PM',
                //     defaut: false,
                // },
                // sendEmailNotificationAt: {
                //     type: 'string',
                //     title: 'Send email notification at',
                //     default: '21:00',
                // },
                approvalTimelogs: {
                    title: 'Time log approval',
                    type: 'string',
                    anyOf: [
                        {
                            title: 'Automatic',
                            enum: ['auto'],
                        },
                        {
                            title: 'Automatic if no overtimes',
                            enum: ['auto-no-overtime'],
                        },
                        {
                            title: 'Manual',
                            enum: ['manual'],
                        },
                    ],
                    default: 'manual',
                },
                approvalVacations: {
                    title: 'Vacations approval',
                    type: 'string',
                    anyOf: [
                        {
                            title: 'Automatic',
                            enum: ['auto'],
                        },
                        {
                            title: 'Manual',
                            enum: ['manual'],
                        },
                    ],
                    default: 'manual',
                },
                approvalAbsents: {
                    title: 'Absents approval',
                    type: 'string',
                    anyOf: [
                        {
                            title: 'Automatic',
                            enum: ['auto'],
                        },
                        {
                            title: 'Manual',
                            enum: ['manual'],
                        },
                    ],
                    default: 'manual',
                },
                overtime_types: {
                    type: 'array',
                    title: 'Manual Overtime Calculation',
                    default: [],
                    items: {
                        type: 'object',
                        required: ['code', 'multiplier', 'name'],
                        properties: {
                            code: { type: 'string', title: 'Unique id' },
                            multiplier: { type: 'integer', title: 'Enter the amount' },
                            name: { type: 'string', title: 'Label' },
                        },
                    },
                },
                overtimeCalcRules: {
                    title: 'Automatic Overtime Calculation(To Enable this rule, please delete all manual overtime rule)',
                    type: 'object',
                    properties: {
                        calc_period: {
                            title: 'Period',
                            type: 'string',
                            anyOf: [
                                {
                                    title: 'None',
                                    enum: ['none'],
                                },
                                {
                                    title: 'Day',
                                    enum: ['day'],
                                },
                                {
                                    title: 'Week',
                                    enum: ['week'],
                                },
                                {
                                    title: 'Month',
                                    enum: ['month'],
                                },
                                {
                                    title: 'Hours in excess of nine hours in the course of 24 hours and 40 hours in the course of seven days',
                                    enum: ['government'],
                                },
                            ],
                            default: 'none',
                        },
                        max_hours: {
                            type: 'integer',
                            title: 'Maximum hours',
                        },
                        max_minutes: {
                            type: 'integer',
                            title: ': minutes',
                        },
                        calc_percent: {
                            type: 'integer',
                            title: 'Overtime %',
                        },
                        weekend_percent: {
                            type: 'integer',
                            title: 'Additional pay for weekend',
                        },
                        weekend_days: {
                            type: 'string',
                            title: 'Weekend days',
                        },
                    },
                },
                startTimeRules: {
                    title: 'Rules for Start Time',
                    type: 'object',
                    properties: {
                        start: {
                            type: 'string',
                            title: 'Start time',
                        },
                        end: {
                            type: 'string',
                            title: 'End time',
                        },
                        total: {
                            type: 'string',
                            title: 'Total Hours',
                        },
                    },
                },
                breakTimeRules: {
                    title: 'Rules for Break Time',
                    type: 'object',
                    properties: {
                        minutes: {
                            title: 'Set the time',
                            type: 'string',
                            anyOf: [
                                {
                                    title: '0m',
                                    enum: ['0'],
                                },
                                {
                                    title: '30m',
                                    enum: ['30'],
                                },
                                {
                                    title: '60m',
                                    enum: ['60'],
                                },
                                {
                                    title: '90m',
                                    enum: ['90'],
                                },
                            ],
                            default: '30',
                        },
                    },
                },
                worklogValidation: {
                    title: 'Worklog Validation',
                    type: 'object',
                    properties: {
                        validateTimetrackOption: {
                            type: 'boolean',
                            title: 'Validate timetrack option',
                            default: false,
                        },
                        criticalVarianceThreshold: {
                            type: 'string',
                            title: 'Critical variance threshold (hh:mm)',
                            default: '00:30',
                        },
                        overtimeThreshold: {
                            type: 'string',
                            title: 'Overtime threshold (hh:mm)',
                            default: '00:30',
                        },
                        sendEmailNotificationForCriticalThresholdToPM: {
                            type: 'boolean',
                            title: 'Send email notification for critical threshold to PM',
                            default: false,
                        },
                        sendEmailNotificationForCriticalThresholdToAdmin: {
                            type: 'boolean',
                            title: 'Send email notification for critical threshold to admin',
                            default: false,
                        },
                        sendEmailNotificationForMediumThresholdToPM: {
                            type: 'boolean',
                            title: 'Send email notification for medium threshold to PM',
                            default: false,
                        },
                        sendEmailNotificationForMediumThresholdToAdmin: {
                            type: 'boolean',
                            title: 'Send email notification for medium threshold to admin',
                            default: false,
                        },
                        sendEmailNotificationForOvertimeThresholdToPM: {
                            type: 'boolean',
                            title: 'Send email notification for overtime threshold to PM',
                            default: false,
                        },
                        sendEmailNotificationForOvertimeThresholdToAdmin: {
                            type: 'boolean',
                            title: 'Send email notification for overtime threshold to admin',
                            default: false,
                        },
                        sendEmailNotificationAt: {
                            type: 'string',
                            title: 'Send email notification at',
                            default: '18:00',
                        },
                    }
                }
            },
        },
        extendedRules: {
            type: 'object',
            title: 'Extended rules',
            properties: {
                kilometersRules: {
                    title: 'Rules for Kilometers',
                    type: 'array',
                    default: [],
                    items: {
                        type: 'object',
                        required: ['distance'],
                        properties: {
                            distance: {
                                title: 'Distance / Zone',
                                type: 'string',
                            },
                            max_km: {
                                title: 'Max KM',
                                type: 'string',
                            },
                        },
                    },
                },
                vacation_types: {
                    type: 'array',
                    title: 'Leave types',
                    default: [],
                    items: {
                        type: 'object',
                        required: ['code', 'name'],
                        properties: {
                            code: { type: 'string' },
                            name: { type: 'string', title: 'Label' },
                        },
                    },
                },
                absent_types: {
                    type: 'array',
                    title: 'Absence types',
                    default: [],
                    items: {
                        type: 'object',
                        required: ['code', 'name'],
                        properties: {
                            code: { type: 'string' },
                            name: { type: 'string', title: 'Label' },
                        },
                    },
                },
                expense_types: {
                    type: 'array',
                    title: 'Expense types',
                    default: [],
                    items: {
                        type: 'object',
                        required: ['code', 'name'],
                        properties: {
                            code: { type: 'string' },
                            name: { type: 'string', title: 'Label' },
                        },
                    },
                },
                extrawork_types: {
                    type: 'array',
                    title: 'Extra work types',
                    default: [],
                    items: {
                        type: 'object',
                        required: ['code', 'name'],
                        properties: {
                            code: { type: 'string' },
                            name: { type: 'string', title: 'Label' },
                        },
                    },
                },
            },
        },
        deviations: {
            type: 'object',
            title: 'Deviations',
            properties: {
                dueDateEmailToBeSentBeforeDays: {
                    type: 'string',
                    title:
                        'Due date email to be sent days, comma-separated (0 - on due date, -1 - day before, 1 - day after etc)',
                },
                strings: { type: 'string', title: 'Deviations messages' },
            },
        },
        Vacation_setup: {
            type: 'object',
            title: 'Vacation setup',
            properties: {
                standared_vacation_days: { type: 'integer', title: 'Standard vacation days', default:  10 }
            }
        },
        general_settings: {
            type: 'object',
            title: 'General Settings',
            properties: {

                pMsAdmins: {
                    title: 'PMs/Admins',
                    type: 'object',
                    properties: {
                        pm_allow_editing_all: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow Edit Everything' },
                        pm_no_shifts: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Not manage others shifts' }
                    }
                },

                pM: {
                    title: 'PM',
                    type: 'object',
                    properties: {
                        pm_hide_tasks: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Tasks Hide' },
                        pm_hide_vacations: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Vacations Hide' },
                        pm_hide_absences: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Absents Hide' },
                        pm_no_register: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'No Register Time' }
                    },
                },

                AbsentsVacations: {
                    title: 'Absents/Vacations',
                    type: 'object',
                    properties: {
                        allow_vacations: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Vacations Allow', default: true },
                        allow_absents: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Absents Allow' },
                        // allow_project_users: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow project users' },
                        allow_accepting: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Time/Vacations/Absents Accepting Accepting' },
                        allow_vacation_not_me: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow Absents/Vacations made by Admins for Members' },
                        admin_hide_absences: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Hide Absents/Vacations from Admins' },
                        disallow_absences_vacations_not_self: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Disallow seeing not my absences/vacations', },
                    },
                },

                deviations: {
                    title: 'Deviations',
                    type: 'object',
                    properties: {
                        enable_deviations: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Enable deviations' }
                    },
                },

                projects: {
                    title: 'Projects',
                    type: 'object',
                    properties: {
                        member_allow_see_projects: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Show Projects to Members' },
                        show_times_on_project: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Show agreement times on project form' }
                    },
                },

                timelog: {
                    title: 'Timelog',
                    type: 'object',
                    properties: {
                        timelog_hide_kilometers: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Hide kilometers' },
                        timelog_hide_other: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Hide other expenses' },
                        timelog_hide_extra: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Hide extra work' },
                        timelog_hide_names: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Disallow member names' },
                        timelog_no_edit_for_approved: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Disable edit when approved' },
                        timelog_no_edit_for_done: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Disable edit when not draft' }
                    },
                },

                reports: {
                    title: 'Reports',
                    type: 'object',
                    properties: {
                        reports_weekly: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Weekly report' },
                        rest_report: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'User report' },
                        hide_send_report: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Hide send report' },
                        reports_not_for_me: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Not Allow my reports' }
                    },
                },

                mix: {
                    title: '',
                    type: 'object',
                    properties: {
                        presence_view: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Show Employee board' },
                        login_as_allowed: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow Login As without confirmation' },
                        locked_mode: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Locked mode' },
                        union_group: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Union group' },
                        gps_enabled: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'GPS enabled' }
                    },
                },


                // allow_vacations: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow vacations', default: true, },
                // allow_absents: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow absents' },
                // // allow_project_users: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow project users' },
                // allow_accepting: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow accepting time / vacations / absents', },
                // allow_vacation_not_me: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow absents/vacations made by admins for members', },

                // enable_deviations: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Enable deviations' },
                // disallow_absences_vacations_not_self: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Disallow seeing not my absences/vacations', },

                // pm_hide_tasks: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'PM hide tasks' },
                // pm_hide_vacations: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'PM hide vacations' },
                // pm_hide_absences: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'PM hide absents' },
                // pm_no_register: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'PM no register time' },


                // member_allow_see_projects: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Members see projects' },
                // timelog_hide_kilometers: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Hide timelog kilometers', },
                // timelog_hide_other: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Hide timelog other expenses' },
                // timelog_hide_extra: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Hide timelog extra work' },
                // show_times_on_project: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Show agreement times on project form', },
                // hide_send_report: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Hide send report' },
                // presence_view: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Show users presence' },
                // admin_hide_absences: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Hide absents/vacations from admins', },

                // reports_not_for_me: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow reports not for me' },
                // reports_weekly: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Weekly report enabled' },
                // locked_mode: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Locked mode' },
                // rest_report: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Restaurant report enabled' },
                // pm_allow_editing_all: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow everything edited for PMs/Admins', },
                // login_as_allowed: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Allow Login As without confirmation', },

                // timelog_hide_names: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Disallow member names in timelogs', },
                // gps_enabled: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'GPS enabled' },
                // timelog_no_edit_for_approved: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Disable edit timelog when approved', },
                // timelog_no_edit_for_done: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Disable edit timelog when not draft', },
                // pm_no_shifts: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'PMs/Admins cant manage others shifts', },

                // union_group: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Union group' },

            },
        },
        loginRules: {
            type: 'object',
            title: 'Login Rules',
            properties: {
                gpsAllowedDistance: { type: 'integer', title: 'GPS max allowed distance (m)', default: 500 },
                gpsAllowedTime: { type: 'integer', title: 'GPS max allowed time (min)', default: 30 },
                autoTimelogs: { type: 'string', title: 'Automatic timelogs enabled',
                    anyOf: [
                        {
                            title: 'ON auto start',
                            enum: ['enabled'],
                        },
                        {
                            title: 'OFF auto start',
                            enum: ['disabled'],
                        },
                        {
                            title: 'GPS check current position, Auto login',
                            enum: ['gps'],
                        },
                        {
                            title: 'GPS check current position, Approve login manually',
                            enum: ['gpsmanual'],
                        },
                        {
                            title: 'Set Auto Schedule Plan On',
                            enum: ['autoschedule'],
                        }
                    ],
                    default: 'gps' },
                autoLogout: { type: 'string', title: 'Automatic Logout',
                    anyOf: [
                        {
                            title: 'Save current time',
                            enum: ['current'],
                        },
                        {
                            title: 'Save time when left the work place',
                            enum: ['old'],
                        }
                    ],
                    default: 'current' },
            },
        },
    },
};

export const getSchema = () => schema;

export default getSchema;
