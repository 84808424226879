import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import MultiSelect from '@khanacademy/react-multi-select';
//components
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

//styles
import '../../../../styles/css/styles.css';
//this component displays all the members with checkbox// 
//and the ones in the current template are checked//

@inject('userStore','commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class DocumentMembers extends Component {

    state = {               
        selected: [],//for selected members
        options: [],//for members list        
    }

    componentDidMount() {
        this.loadData();
    }
    

    //loads memberList and assigns checked members to document template//
    async loadData() {    
        const {userStore,value} = this.props;
        await userStore.loadLookup('notadmin','').then((data) => {
            let multiSelectOptions=[];
            data.map(item=>{
                multiSelectOptions.push({
                    label: item.fullname,
                    value: item.id
                })
            })
            this.setState({ options:multiSelectOptions,selected:value });
        });        

    }

    //handlesSelectedOptions
    handleSelectedOptions = (selected) => {
        this.setState({ selected })
        this.props.onChange(selected)
    }   
        
    render() {
        //renders members with checkbox and checked members//
        const { selected } = this.state;
        const { t }=this.props;
        return (
            <div>
                <MultiSelect
                    options={this.state.options}
                    selected={selected}
                    onSelectedChanged={selected => this.handleSelectedOptions(selected)}
                    overrideStrings={{
                        selectSomeItems: t('Select people...'),
                        allItemsAreSelected: t('All members are selected'),
                        selectAll: t('Select All'),
                        search: t('Search'),
                    }} 
                />
            </div>
        )
    }
}

export default DocumentMembers;