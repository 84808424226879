import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import GenericList from '~/components/GenericList/GenericList';
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import LeftArrow from './../../../assets/img/left-arrow.svg';
import moment from 'moment';
import config from '../../../library/config';
import agent from '../../../library/agent';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { shortDateFormat, timeFormat, totalTimeFormat, dateToTimeFormat } from '~/utils/dateFormat';
import './signdoc.css';

@inject('signatureStore', 'commonStore')
@withRouter
@withLocalization
@observer
class SignDocHistory extends Component {
    state = {
        caseId: null,
        caseName: null,
        caseDate: null,
        buttonDisabled: false,
    }

    componentDidMount() {
        const { history } = this.props;
        if (history && history.location && history.location.state && history.location.state.caseId)
            this.setState({ caseId: history.location.state.caseId })
        if (history && history.location && history.location.state && history.location.state.caseName)
            this.setState({ caseName: history.location.state.caseName })
        if (history && history.location && history.location.state && history.location.state.caseDate)
            this.setState({ caseDate: history.location.state.caseDate })
    }
    backToList = () => {
        const { history } = this.props;
        history.push(history.location.state.parent === 'UserContract' ? '/admin/members' : '/admin/cases');
    }

    downloadCertificate = async () => {
        const { caseId } = this.state;
        let hydrated = {
            id: encodeURIComponent(caseId),
            authorization: this.props.commonStore.token
        }
        window.open(`${config.API_ROOT}/sign_doc_history/${encodeURIComponent(caseId)}${agent.convertToGetParams(hydrated)}`);
        this.setState({ buttonDisabled: false });
    }

    setUserLocation(location_info) {
        let locationData = "";
        if (location_info.city)
        locationData = locationData.concat(location_info.city)
        if (location_info.regionName)
        locationData = locationData.concat(", ", location_info.regionName)
        if (location_info.country)
        locationData = locationData.concat(", ", location_info.country)
        if (location_info.zip)
        locationData =  locationData.concat(", ",location_info.zip)

        return (
            <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id="tooltip-top">
                    {/* {t("Full Report")} */}
                    {locationData}
                </Tooltip>
            }
        >
        <span>{locationData}</span>
        </OverlayTrigger>
        );
    }

    render() {

        const { caseId, caseName, caseDate, buttonDisabled } = this.state;
        const { t, commonStore } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        if (!caseId) {
            return (<div>{t('No data found')}</div>);
        }
        return (
            <div className="main-content">
                <div className="historyDoc">
                    <div className="same">
                        <h4>{t('Document History')}</h4>
                        <p style={{ fontSize: '1.1em', color: 'black', paddingTop: '25px' }} onClick={this.backToList}>
                            <img src={LeftArrow} alt="left arrow" style={{ cursor: 'pointer' }} /> &nbsp;<span className="hover-underline">{t("Back to Document Listing")}</span>
                        </p>
                    </div>
                    <p>{t("Document Name")}</p>
                    <h6>{caseName}</h6>
                    <p>{t("Sent Date")}</p>
                    {/* <h6>{moment(caseDate).format(dateTimeRules.short_date_format)}</h6> */}
                    <h6>{caseDate && shortDateFormat(caseDate, dateTimeRules)}</h6>

                    {caseId != null &&
                        <GenericList
                            columns={[
                                {
                                    Header: 'Date & Time',
                                    id: 'created_at',
                                    sortable: false,
                                    accessor: ({ created_at }) => (
                                        <span>{`${shortDateFormat(created_at, dateTimeRules)} ${dateToTimeFormat(created_at, dateTimeRules)}`}</span>
                                    )
                                },
                                {
                                    Header: 'User',
                                    id: 'user_id',
                                    sortable: false,
                                    accessor: ({ username, recipient_name, user_id }) => (
                                        <span>
                                            {user_id == '0'
                                                ? recipient_name
                                                : username}
                                        </span>
                                    )
                                },
                                {
                                    Header: 'Device',
                                    id: 'device_info.os.name',
                                    sortable: false,
                                    accessor: 'device_info.os.name',
                                },
                                {
                                    Header: 'Location',
                                    id: 'location_info',
                                    sortable: false,
                                    accessor: ({ location_info }) => {
                                        if (location_info && location_info.status === "success") {
                                            return this.setUserLocation(location_info)   
                                        }
                                        else {
                                            return t("Couldn't detect location")
                                        }
                                    }
                                },
                                {
                                    Header: 'Action',
                                    id: 'action',
                                    sortable: false,
                                    accessor: 'action',
                                },

                            ]}
                            lastListLoadTime={this.props.signatureStore.lastListLoadTime}
                            requestData={() => this.props.signatureStore.getSignDocHistory(caseId)}
                        />
                    }
                    <button className="btn btn-primary"
                        onClick={() => this.setState({ buttonDisabled: true }, () => this.downloadCertificate())}
                        disabled={buttonDisabled}
                    >
                        {t('Download Certificate')}
                    </button>
                </div>
            </div>

        );
    }
}
export default SignDocHistory;
