import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { confirmAlert } from 'react-confirm-alert';
import { Modal } from 'react-bootstrap';

import LoadingSpinner from '../../../elements/LoadingSpinner';
import withLocalization from '../../../hoc/withLocalization';
import { TasksWidget } from '../../../components/GenericForm/Widgets/TasksWidget';
import { Accordion, AccordionItem } from '../../../components/Accordion/Accordion';
import DidNotWork from '../../../components/DidNotWork/DidNotWork';
import TimeSelect from '../../../components/TimeSelect/TimeSelect';
import InputTime from '../../../components/GenericForm/Widgets/InputTime';
import TimelogArray from '../../../components/TimelogArray/TimelogArray';
import DynamicList from '../../../components/DynamicList/DynamicList';
import AttachmentsWidget from '../../../components/GenericForm/Widgets/AttachmentsWidget';
import Signature from '../../../components/Signature/Signature';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Input, Textarea } from '../../../elements/Input';
import playIcon from '../../../assets/img/play.svg';
import stopIcon from '../../../assets/img/stop.svg';
import clockIcon from '../../../assets/img/clock.svg';
import distanceIcon from '../../../assets/img/distance.svg';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import { longDateFormat, distanceUnitType } from '../../../utils/dateFormat'
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import AcceptFormBlock from './AcceptFormBlock';
import TimePickerInput from '../../../components/TimePicker';

import 'react-confirm-alert/src/react-confirm-alert.css';
import GPSInfo from './GPSInfo';

import customerFeature from '~/utils/customerFeature';
import { conforms } from 'lodash';

@inject('timelogStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class TimelogsForm extends Component {

    state = {
        isqucikChecklist: false,
    }

    componentWillMount() {
        const { timelogStore } = this.props;
        timelogStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    handleQuickChecklistModalClose = (isInjury) => {
        if (isInjury === true) {
            this.handleChange('data.isInjury', isInjury);
            this.reportInjuryDuringWork();
        }
        this.setState({ isqucikChecklist: false });
    };

    reportInjuryDuringWork = async () => {
        const { timelogStore, commonStore, t } = this.props;
        const { currentEntity } = timelogStore;
        await timelogStore.reportInjury(currentEntity, currentEntity).then(result => {
            if (!result || !result.id) {
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
            } else {
                commonStore.addNotification(t('Very sorry to know about your injury, PM and management has been informed'), null, 'success');
            }
        });
    }


    visualState() {
        const { timelogStore, add, commonStore, t, userStore, id } = this.props;
        const { user_type, id: userId, data: userData } = userStore.currentUser;
        const manualDisabled = !!userData.disable_manual_log;
        const pauseButtonEnabled = !!userData.enable_pause_button;
        const gpsEnabled = customerFeature('gps_enabled');
        const { currentEntity } = timelogStore;
        const allowEditing =
            (user_type === 'pm' || user_type === 'admin') &&
            customerFeature('pm_allow_editing_all') &&
            !customerFeature('timelog_hide_names');
        const showStatus =
            !add &&
            user_type === 'pm' &&
            customerFeature('allow_accepting') &&
            currentEntity.task_id &&
            currentEntity.status != 'draft';
        const allowSave = currentEntity.status === 'draft' || currentEntity.user_id === userId || allowEditing;
        const normalEditing = currentEntity.task_id && currentEntity.tracker_status != 'na';
        const showStartButtons =
            currentEntity.status === 'draft' && currentEntity.task_id && currentEntity.tracker_status == 'na';
        const isManualMode = normalEditing && currentEntity.tracker_status == 'manual';
        const isTrackingMode =
            normalEditing && currentEntity.status == 'draft' && currentEntity.tracker_status != 'manual';

        //adding condition for autoShiftplan//
        const isSystemEntry = (currentEntity.gps_data && currentEntity.gps_data.systemEntry) ? currentEntity.gps_data.systemEntry : false;
        const trackerIsRunning =
            currentEntity.status === 'draft' &&
            (currentEntity.tracker_status == 'started' || currentEntity.tracker_status == 'break' || isSystemEntry);
        const allowTimeEditing = !isTrackingMode;
        const showFinishButtons = trackerIsRunning;
        return {
            gpsEnabled,
            allowEditing,
            allowSave,
            showStatus,
            normalEditing,
            showStartButtons,
            showFinishButtons,
            isManualMode,
            isTrackingMode,
            trackerIsRunning,
            allowTimeEditing,
            manualDisabled,
            pauseButtonEnabled,
        };
    }

    // onSave(values) {
    //   const { timelogStore, history, add, t, commonStore } = this.props;
    //   const {userStore} = this.props;
    //   const {currentUser} = userStore;
    //
    //   if (currentUser.user_type === 'member') {
    //     values.assignee_id = currentUser.id;
    //   }
    //   return timelogStore.save(values, add).then(result => {
    //     if (!result.task || !result.task.id) {
    //       // some error
    //       commonStore.addNotification(t(result.message || 'Error'), null, 'error');
    //       return false;
    //     } else {
    //       commonStore.addNotification(t('Saved'), null, 'success');
    //       history.push(this.getListUrl());
    //       return true;
    //     }
    //   });
    // }

    timeAction = async (act) => {
        const { timelogStore, add, commonStore, t, history } = this.props;
        const { currentEntity } = timelogStore;
        const { client } = commonStore.config;

        if (act === 'start') {
            timelogStore.startByButton(currentEntity.id, {});
        }
        if (act === 'finish') {
            // timelogStore.finishByButton(currentEntity, {});
            await timelogStore.finishByButton(currentEntity, {}).then(result => {
                // console.log("result : ", result);
                if (!result || !result.id) {
                    commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                } else {
                    if (client && client.data && client.data.basicRules && client.data.basicRules.allowQucikChecklist)
                        this.setState({ isqucikChecklist: true });
                }
            });
        }
        if (act === 'break') {
            const isPaused = currentEntity.tracker_status === 'break';
            await timelogStore.breakByButton(currentEntity.id, isPaused ? 'stop' : 'start', false);
            this.loadData();
        }
        if (act === 'manual') {
            timelogStore.setManualDefaults();
            // timelogStore.changeEntity('tracker_status', 'manual');
            this.intermediateSave();
        }
    };

    afterSave = (result) => {
        const { timelogStore, add, commonStore, t, history } = this.props;
        if (!result.timelog || !result.timelog.id) {
            // some error
            // commonStore.addNotification(t(result.message || 'Error'), null, 'error');
            return false;
        }
        commonStore.addNotification(t('Saved'), null, 'success');
        history.push('/admin/timelogs');
        return true;
    };

    fullSave = async (doPublish) => {
        const { timelogStore } = this.props;
        const { commonStore } = this.props;
        const { data: clientData } = commonStore.config.client;
        const { currentEntity, originalEntity } = timelogStore;
        const { t } = this.props;

        if (currentEntity.total_hours_overall < currentEntity.total_hours_overtime) {
            commonStore.addNotification(t('Work time is less than overtime'), null, 'error');
            return;
        }

        const internalSave = () => {
            const { commonStore } = this.props;
            const overTimeMaxHours = commonStore.config
                && commonStore.config.client
                && commonStore.config.client.data.basicRules
                && commonStore.config.client.data.basicRules.overtimeCalcRules
                && commonStore.config.client.data.basicRules.overtimeCalcRules.max_hours ? Number(commonStore.config.client.data.basicRules.overtimeCalcRules.max_hours): 0;

            const isAutomaticOvertime = commonStore.config
                && commonStore.config.client
                && commonStore.config.client.data.basicRules
                && commonStore.config.client.data.basicRules.overtimeAutomaticMode ? commonStore.config.client.data.basicRules.overtimeAutomaticMode : false;

            const memberOvertime = commonStore.config
            && commonStore.config.client
            && commonStore.config.client.data.basicRules
            && commonStore.config.client.data.basicRules.overtimeCalcRules
            && commonStore.config.client.data.basicRules.overtimeCalcRules.calc_percent ? Number(commonStore.config.client.data.basicRules.overtimeCalcRules.calc_percent): 0;

            // console.log("isAutomaticOvertime",isAutomaticOvertime,commonStore.config.client.data.basicRules.overtimeAutomaticMode,overTimeMaxHours);

            const maxDailyHoursInMins = isAutomaticOvertime ? overTimeMaxHours * 60 : 9 * 60; 
            if (getMinutesFromTimeInput(currentEntity.total_hours_normal) > maxDailyHoursInMins &&
                getMinutesFromTimeInput(currentEntity.total_hours_overtime) === 0 &&
                !clientData.basicRules.allow_overtime_limit) {
                setTimeout(() => {
                    if(isAutomaticOvertime){
                        confirmAlert({
                            title: t('Confirm overtime'),
                            message: 
                            t('Total registered hours are ') +minutesToTimeInput(getMinutesFromTimeInput(currentEntity.total_hours_normal))+ t(' for the day. ')
                                +  minutesToTimeInput((getMinutesFromTimeInput(currentEntity.total_hours_normal) - (overTimeMaxHours * 60))) + t(' Hours/min. of these are automatically added as overtime of ')
                                + memberOvertime +'%. '+ t('Please cancel and update if this is not correct or click continue.'),
                            buttons: [
                                {
                                    label: t('Cancel'),
                                    onClick: () => { },
                                },
                                {
                                    label: t('Continue'),
                                    onClick: () => {
                                        this.fullSaveInternal(doPublish);
                                    },
                                },
                            ],
                        });
                    }else{
                        confirmAlert({
                            title: t('Confirm overtime'),
                            message: t(
                                'You have registered more time than your standard working day, do you want to register overtime?'
                            ),
                            buttons: [
                                {
                                    label: t('Yes'),
                                    onClick: () => { },
                                },
                                {
                                    label: t('No'),
                                    onClick: () => {
                                        this.fullSaveInternal(doPublish);
                                    },
                                },
                            ],
                        });
                    }
                }, 250);

            } else {
                this.fullSaveInternal(doPublish);
            }
        };

        if (currentEntity.status !== 'draft') {
            const enableCheck = clientData.basicRules && !clientData.basicRules.allowManualTimelogChange;
            if (enableCheck && (currentEntity.from !== originalEntity.from || currentEntity.to !== originalEntity.to)) {
                confirmAlert({
                    title: t('Confirm time change'),
                    message: t('You have changed from/to times, this will switch timelog to manual'),
                    buttons: [
                        {
                            label: t('Yes, proceed'),
                            onClick: () => {
                                timelogStore.switchToManual();
                                internalSave();
                            },
                        },
                        {
                            label: t('Cancel'),
                            onClick: () => { },
                        },
                    ],
                });
                return;
            }
        }
        internalSave();
        // allow_overtime_limit
    };

    fullSaveInternal = async (doPublish) => {
        const { timelogStore, commonStore, t } = this.props;
        let result = null;
        if (doPublish) {
            this.handleChange('status', 'active');
        }

        try {
            result = await timelogStore.save();
        } catch (e) {
            if (e && e.name === 'ValidationError') {
                commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
            return;
        }
        this.afterSave(result);
    };

    handleChange = (name, value) => {
        // console.log("name : ",name,value);
        const { timelogStore } = this.props;
        timelogStore.changeEntity(name, value);
    };

    handleChangeOvertimes = (overtime, value) => {
        const { timelogStore } = this.props;
        const { code } = overtime;
        const { currentEntity } = timelogStore;
        const data = currentEntity.data ? Object.assign({}, currentEntity.data) : {};
        data.overtimes = data.overtimes || {};
        data.overtimes[code] = {
            ...overtime,
            value,
        };
        var overtimes = JSON.parse(JSON.stringify(data.overtimes));
        let newOvertimes = overtimes;

        for (let i = 0; i < Object.keys(overtimes).length; i++) {
            const consideredData = overtimes[Object.keys(overtimes)[i]];
            if (consideredData.value == "00:00") {
                delete newOvertimes[Object.keys(overtimes)[i]];
            }
        }
        data.overtimes = newOvertimes;
        timelogStore.changeEntity('data', data, 'overtime');
    };

    handleTaskChange = async (value, full) => {
        const { timelogStore } = this.props;
        const { currentEntity } = timelogStore;
        this.handleChange('data.workplace', full.address);
        this.handleChange('project_id', full.project_id);
        this.handleChange('task_id', value);
        if (full.start_time && !currentEntity.from) {
            this.handleChange('from', minutesToTimeInput(full.start_time, false));
        }
        if (full.end_time && !currentEntity.to) {
            this.handleChange('to', minutesToTimeInput(full.end_time, false));
        }
        if (full.description) {
            this.handleChange('description', full.description);
        }
        const gpsData = currentEntity.gps_data || {};
        if (full.gps_data) {
            gpsData.task = full.gps_data;
        }
        this.handleChange('gps_data', gpsData);
        this.intermediateSave();
    };

    intermediateSave = async () => {
        const { timelogStore, commonStore, t } = this.props;
        const { currentEntity } = timelogStore;
        try {
            await timelogStore.save(currentEntity, false, true);
            commonStore.addNotification(t('Saved'), null, 'success');
        } catch (e) { }
    };

    handleUserChange = (value, full) => {
        this.handleChange('user_id', value);
        this.handleChange('PM_CHANGE', true);
        this.intermediateSave();
    };

    applyHoliday = (from, to) => {
        const { commonStore, t, timelogStore } = this.props;
        timelogStore
            .saveAsHoliday(from, to)
            .then((result) => this.afterSave(result))
            .catch((e) => {
                // commonStore.addNotification(t(e.message || 'Error'), null, 'error');
                return false;
            });
    };

    async loadData() {
        const { timelogStore, id, add, mode, history } = this.props;
        if (add) {
            const draftId = await timelogStore.getPossibleDraftId();
            history.push(`/admin/timelogs/${draftId}`);
            return;
        }
        timelogStore.load(id, add).then((timelog) => {
            this.handleChange('for_date', timelog.timelog.for_date);
        });
        return null;
    }

    async handleChangeStatus(action) {
        this.loadData();
    }

    render() {
        const { timelogStore, add, commonStore, t, userStore, id } = this.props;
        const { data: clientData } = commonStore.config.client;
        const { data: biztypeData } = commonStore.config.biztype;
        const { timelog_last_filled, id: userId } = userStore.currentUser;
        const { user_type } = userStore.currentUser;
        const gpsEnabled = customerFeature('gps_enabled');
        const { loading, currentEntity, ui: storeUI } = timelogStore;
        const {
            task_id,
            workplace,
            User,
            user_id,
            from,
            to,
            total_hours_normal,
            total_hours_overtime,
            total_hours_overall,
            data,
            for_date,
            Attachments,
            description,
            status,
        } = currentEntity;
        const { signature } = data;
        
        const tipAmount = data.tipInfo && data.tipInfo.amount ? data.tipInfo.amount : '';
        // console.log(tipAmount,'.fffffffffffffffffffffff');
        const tipEditor =  clientData.tipRules && clientData.tipRules.tipEditor;
        const overtimes = data.overtimes ? data.overtimes : {};
        const configOvertimes = clientData.basicRules.overtime_types || [];
        const configExtraExpenses = clientData.extendedRules.expense_types || [];
        const configExtraWorks = clientData.extendedRules.extrawork_types || [];
        const quickCheckListText = clientData.basicRules.quickCheckListText || 'Were you injured today at work site?';
        const configStartTime = clientData.basicRules.startTimeRules &&
                                clientData.basicRules.startTimeRules.start
                                ? clientData.basicRules.startTimeRules.start : '08:00';
        const {config} = this.props.commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false; 
        const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';
        const alloWManualAdd = clientData.loginRules && clientData.loginRules.autoTimelogs 
                                && clientData.loginRules.autoTimelogs == 'gpsmanual' && user_type == 'member'
                                  ? false : true;
        const isAutomaticOvertime = config && config.client.data.basicRules
            && config.client.data.basicRules.overtimeAutomaticMode ? 
                                    config.client.data.basicRules.overtimeAutomaticMode : false;
        if (loading) {
            return <LoadingSpinner />;
        }
        return (
            <div className="primary-page" style={{ padding: 0, margin: 0 }}>
                <div className="primary-page__header" style={{ justifyContent: 'space-between' }}>
                    <h3 className="primary-page__title">
                        {t('Register working hours')}
                        {status === 'draft' && (
                            <div className="primary-page__title__draft">
                                <b>{t('This is a draft work log')}</b>
                            </div>
                        )}
                    </h3>
                    <div className='customize-datepicker'>
                    <DatePicker
                        selected={for_date}
                        onChange={(date) => this.handleChange('for_date', date)}
                        dateFormat= {longDateFormat(dateTimeRules)}
                        maxDate={new Date()}
                    />
                    </div>
                </div>
                <DidNotWork
                    allow={this.visualState().allowEditing}
                    start={for_date}
                    applyHoliday={(from, to) => this.applyHoliday(from, to)}
                />
                {storeUI.forDateWarning && (
                    <div className="panel panel-danger">
                        <div className="panel-heading">{t('Warning')}</div>
                        <div className="panel-body">{t('This date will be set as your first entry')}</div>
                    </div>
                )}
                <Accordion preExpanded={['time', 'km', 'extrapass', 'otherexp', 'extraw', 'signature', 'final']}>
                    <AccordionItem title={t('Register Time')} uuid="time">
                        {user_type != 'member' && (
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group field field-string">
                                        <label className="control-label">{t('Select Member')} *</label>
                                        {this.visualState().allowEditing && (
                                            <MembersWidget
                                                className="form-control"
                                                onChange={(value, full) => this.handleUserChange(value, full)}
                                                value={user_id}
                                                defaultIfOne
                                            />
                                        )}
                                        {!this.visualState().allowEditing && User && (
                                            <div>
                                                {User.first_name} {User.last_name}
                                            </div>
                                        )}
                                        {!this.visualState().allowEditing && !User && (
                                            <div>
                                                {userStore.currentUser.first_name} {userStore.currentUser.last_name}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group field field-string">
                                    <label className="control-label">{t('Select Task')} *</label>
                                    <TasksWidget
                                        className="form-control"
                                        onChange={(value, full) => this.handleTaskChange(value, full)}
                                        value={task_id}
                                        defaultIfOne
                                        filterByUser={user_id}
                                        shouldFilterByUser
                                    />
                                </div>
                                <div className="form-group field field-string">
                                    <label className="control-label">{t('Workplace')}</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={data.workplace ? (data.workplace === 'null' ? '' : data.workplace) : ''}
                                        placeholder={t('Workplace address')}
                                        onChange={(e) => this.handleChange('data.workplace', e.target.value)}
                                    />
                                </div>
                            </div>
                            {currentEntity.user_image_attachment_id &&
                                <div className="col-md-6">
                                    <div style={{ display: 'flex', justifyContent: 'right', height: '160px' }}>
                                        <img
                                            src={`${config.UPLOADS_API_ENDPOINT}/${currentEntity.user_image_attachment_id}`}
                                            style={{ maxHeight: '140px', maxWidth: '200px', marginTop: '0.4rem' }}>
                                        </img>
                                    </div>
                                </div>
                            }
                        </div>
                        {!!this.visualState().showStartButtons && !!this.visualState().manualDisabled && (
                            <div className="timelog-action-block">
                                <span className="timelog-action-block__label">{t('Press "Start" to run timer')}</span>
                                <div className="timelog-action-block__buttons">
                                    <Button fill wd onClick={() => this.timeAction('start')}>
                                        {t('Start')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {!!this.visualState().showStartButtons && !this.visualState().manualDisabled && (
                            <div className="timelog-action-block">
                                <span className="timelog-action-block__label">
                                    {t('You can create hours manually or just press "Start" to run timer')}
                                </span>
                                <div className="timelog-action-block__buttons">
                                    <Button fill wd onClick={() => this.timeAction('start')}>
                                        {t('Start')}
                                    </Button>
                                    {alloWManualAdd &&                                     
                                        <Button onClick={() => this.timeAction('manual')}>{t('Manual')}</Button>
                                    }
                                </div>
                            </div>
                        )}
                        {!!this.visualState().showFinishButtons && !this.visualState().pauseButtonEnabled && (
                            <div className="timelog-action-block">
                                <span className="timelog-action-block__label">{t('Finish the work')}</span>
                                <div className="timelog-action-block__buttons">
                                    <Button fill wd onClick={() => this.timeAction('finish')}>
                                        {t('Finish')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {!!this.visualState().showFinishButtons && !!this.visualState().pauseButtonEnabled && (
                            <div className="timelog-action-block">
                                <span className="timelog-action-block__label">
                                    {t('You can finish the work or take/end the break')}
                                </span>
                                <div className="timelog-action-block__buttons">
                                    <Button fill wd onClick={() => this.timeAction('finish')}>
                                        {t('Finish')}
                                    </Button>
                                    <Button wd onClick={() => this.timeAction('break')}>
                                        {currentEntity.tracker_status === 'break'
                                            ? t('Finish break')
                                            : t('Start break')}
                                    </Button>
                                </div>
                            </div>
                        )}

                        <Modal
                            show={this.state.isqucikChecklist}
                            onHide={this.handleQuickChecklistModalClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('Quick Checklist')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {t(quickCheckListText)}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.handleQuickChecklistModalClose(true)}>
                                    {t('Yes')}
                                </Button>
                                <Button variant="secondary" onClick={this.handleQuickChecklistModalClose}>
                                    {t('No')}
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {currentEntity.task_id && (
                            <>
                                {!this.visualState().showStartButtons && (
                                    <div className="timelog-grid">
                                        <div className="timelog-grid__item timelog-grid__item_purple justify-content-space-between">
                                            <div className="d-flex">
                                                <i className="timelog-grid__icon">
                                                    <img src={playIcon} alt="Start" />
                                                </i>
                                                <span className="timelog-grid__text">{t('Start')}</span>
                                            </div>
                                            <div className="d-flex">
                                                {this.visualState().allowTimeEditing && (
                                                    // <InputTime
                                                    //     value={from}
                                                    //     onChange={(e) => this.handleChange('from', e)}
                                                    //     className="big-input"
                                                    // />  
                                                    <TimePickerInput        
                                                        style={{width:150}}        
                                                        value={from}
                                                        format={format=="hh:mm"?"HH:mm":format}
                                                        onChange={value => this.handleChange('from', value)}
                                                        className="worklog-input"
                                                    />                                              
                                                )}
                                                {!this.visualState().allowTimeEditing && (
                                                    <span className="timelog-grid__input-text">{from}</span>
                                                )}
                                                {from < configStartTime && (
                                                    <i
                                                        className="fa fa-warning"
                                                        style={{ color: '#FF1E50' }}
                                                        title={t('Starting before') + ' ' + configStartTime}
                                                    />
                                                )}
                                                <span className="timelog-grid__info">
                                                    {t('The start time should belong to the time interval')} (
                                                    {clientData.basicRules.startTimeRules.start} -{' '}
                                                    {clientData.basicRules.startTimeRules.end})
                                                </span>
                                            </div>
                                        </div>
                                        <div className="timelog-grid__item timelog-grid__item_red justify-content-space-between" >
                                            <div className='d-flex'>
                                                <i className="timelog-grid__icon">
                                                    <img src={stopIcon} alt="Stop" />
                                                </i>
                                                <span className="timelog-grid__text">{t('End')}</span>
                                            </div>
                                            <div className='d-flex'>
                                                {this.visualState().allowTimeEditing && (
                                                    // <InputTime
                                                    //     value={to}
                                                    //     onChange={(e) => this.handleChange('to', e)}
                                                    //     className="big-input"
                                                    // />
                                                    <TimePickerInput        
                                                        style={{width:150}}        
                                                        value={to}
                                                        format={format=="hh:mm"?"HH:mm":format}
                                                        onChange={value => this.handleChange('to', value)}
                                                        className="worklog-input"
                                                    />
                                                )}
                                                {!this.visualState().allowTimeEditing && (
                                                    <span className="timelog-grid__input-text">
                                                        {this.visualState().trackerIsRunning ? t('Still working') : to}
                                                    </span>
                                                )}
                                                <span className="timelog-grid__info">
                                                    {t('The start time should belong to the time interval')} ({from} -{' '}
                                                    {clientData.basicRules.startTimeRules.end})
                                                </span>
                                            </div>
                                        </div>
                                        <div className="timelog-grid__item">
                                            <span className="timelog-grid__text timelog-grid__text-capitalize">
                                                {t('Breaktime')}
                                            </span>
                                            {add && (
                                                <TimeSelect
                                                    value={currentEntity.break}
                                                    onChange={(option) => this.handleChange('break', option.value)}
                                                    max={120}
                                                />
                                            )}
                                            {!add && (currentEntity.break || currentEntity.break === 0) && (
                                                <InputTime
                                                    value={minutesToTimeInput(currentEntity.break, false)}
                                                    onChange={(e) =>
                                                        this.handleChange('break', getMinutesFromTimeInput(e))
                                                    }
                                                    className="big-input"
                                                />
                                            )}
                                            {currentEntity.tracker_status === 'break' &&
                                                currentEntity.data.breakStarted && (
                                                    <span style={{ paddingLeft: 10 }}>
                                                        {`${t('Break started at')} ${minutesToTimeInput(
                                                            currentEntity.data.breakStarted,
                                                            true
                                                        )}`}
                                                    </span>
                                                )}
                                        </div>
                                        <div className="timelog-grid__item timelog-grid__item_black">
                                            <i className="timelog-grid__icon">
                                                <img src={clockIcon} alt="Total Time" />
                                            </i>
                                            <span className="timelog-grid__text">{t('Total')}</span>
                                            <InputTime disabled value={total_hours_overall} className="big-input" />
                                        </div>
                                    </div>
                                )}
                                {!!currentEntity.task_id && (
                                    <GPSInfo data={currentEntity.gps_data} mode="form" full={currentEntity} />
                                )}
                                {this.visualState().normalEditing && !isAutomaticOvertime &&(
                                    <>
                                        <h3 className="timelog-grid__title">{t('Overtimes')}</h3>
                                        <div className="timelog-grid">
                                            {configOvertimes &&
                                                configOvertimes.map((overtime, index) => (
                                                    <div className="timelog-grid__item" key={`${index}_${overtime.code}`}>
                                                        <span className="timelog-grid__text timelog-grid__text-capitalize">
                                                            {overtime.name}
                                                        </span>
                                                        <InputTime
                                                            value={
                                                                overtimes[overtime.code]
                                                                    ? overtimes[overtime.code].value
                                                                    : '00:00'
                                                            }
                                                            className="big-input"
                                                            onChange={(e) => this.handleChangeOvertimes(overtime, e)}
                                                        />
                                                    </div>
                                                ))}
                                        </div>
                                    </>
                                )}
                                {this.visualState().normalEditing && (tipEditor === "member") && (
                                    <>
                                        <h3 className="timelog-grid__title">{t('Tip Entry')}</h3>
                                        <div className="timelog-grid">
                                                    <div className="timelog-grid__item">
                                                        <span className="timelog-grid__text timelog-grid__text-capitalize" style={{flex: 1}}>
                                                             { t("Tip Amount")}
                                                        </span>
                                                        <Input
                                                            type="number"
                                                            value={tipAmount}
                                                            className="big-input"
                                                            onChange={(evt)=>{ this.handleChange('data.tipInfo.amount',evt.target.value) }}
                                                            
                                                        />
                                                    </div>
                                             
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </AccordionItem>
                    {!biztypeData.timelog_hide_kilometers && this.visualState().normalEditing && (
                        <AccordionItem title={t('Register Distance')} uuid="km">
                            <div className="register-dist">
                                <div className="register-dist__main-input">
                                    <img src={distanceIcon} alt="Distance" />
                                    <div>
                                        <input
                                            className="form-control"
                                            value={data.distance}
                                            type="number"
                                            onChange={(e) => this.handleChange('data.distance', e.target.value)}
                                        />
                                        <span>{distanceUnitType(dateTimeRules)}</span>
                                    </div>
                                </div>
                                <div className="register-dist__inputs">
                                    <Input
                                        label={t('Workplace 1')}
                                        className="register-dist__input"
                                        value={data.address}
                                        onChange={(e) => this.handleChange('data.address', e.target.value)}
                                        placeholder={t('Address')}
                                    />
                                    <Input
                                        label={t('Workplace 2')}
                                        className="register-dist__input"
                                        value={data.workplace}
                                        onChange={(e) => this.handleChange('data.workplace', e.target.value)}
                                        placeholder={t('Address')}
                                    />
                                </div>
                            </div>
                        </AccordionItem>
                    )}

                    {!biztypeData.timelog_hide_kilometers && this.visualState().normalEditing && (
                        <AccordionItem title={t('Extra Passangers')} uuid="extrapass">
                            <DynamicList
                                placeholder={t('Enter passanger name')}
                                label={t('Passanger Name')}
                                onItemsChange={(passangers) => this.handleChange('data.passangers', passangers)}
                                initData={data.passangers}
                            />
                        </AccordionItem>
                    )}
                    {!biztypeData.timelog_hide_other && this.visualState().normalEditing && (
                        <AccordionItem title={t('Other Expenses')} uuid="otherexp">
                            <TimelogArray
                                mode="expenses"
                                options={configExtraExpenses}
                                onSave={(expenses) => this.handleChange('data.expenses', expenses)}
                                initData={data.expenses}
                            />
                        </AccordionItem>
                    )}
                    {!biztypeData.timelog_hide_extra && this.visualState().normalEditing && (
                        <AccordionItem title={t('Extra Work')} uuid="extraw">
                            <TimelogArray
                                mode="work"
                                options={configExtraWorks}
                                onSave={(extraWork) => this.handleChange('data.extraWork', extraWork)}
                                initData={data.extraWork}
                            />
                        </AccordionItem>
                    )}
                    {this.visualState().normalEditing && (
                        <AccordionItem title={t('Signature')}>
                            <Signature
                                onChange={(signature) => this.handleChange('data.signature', signature)}
                                initialSignature={signature}
                                required
                                width="100%"
                                height={250}
                                error={false && !signature}
                            />
                        </AccordionItem>
                    )}
                    {this.visualState().normalEditing && (
                        <AccordionItem title={t('Final Step')} uuid="final">
                            <div className="timelog-final">
                                <div className="timelog-final__item">
                                    <h4 className="timelog-final__item-title">{t('Other Documents')}</h4>
                                    <div className="attachment-box" style={{ width: '100%' }}>
                                        <span className="attachment-box__label">{t('Upload Files')}</span>
                                        <AttachmentsWidget
                                            value={data.attachments}
                                            onChange={(attachIds) => this.handleChange('data.attachments', attachIds)}
                                            options={{
                                                checkDate: true,
                                                imageContext: {
                                                    existingAttachment: Attachments,
                                                    fileType: 'docs',
                                                    id: 0,
                                                    model: 'Timelog',
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="timelog-final__item">
                                    <h4 className="timelog-final__item-title">{t('Comments')}</h4>
                                    <Textarea
                                        label={t('Comment')}
                                        value={description}
                                        style={{ width: '100%' }}
                                        onChange={(e) => this.handleChange('description', e.target.value)}
                                        placeholder={t('Enter comments here')}
                                    />
                                </div>
                                <div className="timelog-final__item">
                                    <div className="timelog-final__item-box" style={{ background: '#744098' }}>
                                        <img src={clockIcon} alt="Total Time" width="40" height="40" />{' '}
                                        {t('Working hours')}: <br />
                                        <b>{total_hours_normal}</b>
                                    </div>
                                    <div className="timelog-final__item-box" style={{ background: '#FF1E50' }}>
                                        <img src={clockIcon} alt="Total Time" width="40" height="40" />
                                        {t('Overtime hours')}: <br />
                                        <b>{total_hours_overtime}</b>
                                    </div>
                                    {this.visualState().allowSave && currentEntity.status == 'draft' && (
                                        <>
                                            <Button
                                                fill
                                                round
                                                wd
                                                onClick={() => this.intermediateSave()}
                                                style={{ margin: '0 auto' }}
                                            >
                                                {t('Save as draft')}
                                            </Button>
                                            {!this.visualState().trackerIsRunning && (
                                                <Button
                                                    fill
                                                    round
                                                    wd
                                                    onClick={() => this.fullSave(true)}
                                                    style={{ margin: '0 auto' }}
                                                >
                                                    {t('Save and publish')}
                                                </Button>
                                            )}
                                        </>
                                    )}
                                    {this.visualState().allowSave && currentEntity.status != 'draft' && (
                                        <>
                                            <Button
                                                fill
                                                round
                                                wd
                                                onClick={() => this.fullSave(false)}
                                                style={{ margin: '0 auto' }}
                                            >
                                                {t('Save')}
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </AccordionItem>
                    )}
                </Accordion>
                {this.visualState().showStatus && (
                    <AcceptFormBlock
                        params={{ id, status, entity: 'timelog' }}
                        afterSave={() => this.handleChangeStatus()}
                    />
                )}
            </div>
        );
    }
}

export default TimelogsForm;
