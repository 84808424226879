import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Modal, Col, Tabs, Tab } from 'react-bootstrap';
import Input from '../../../../../elements/Input'
import LoadingSpinner from '~/elements/LoadingSpinner';
import Button from '../../../../../components/CustomButton/CustomButton.jsx'
import withLocalization from '../../../../../hoc/withLocalization';
import SignatureInitialWrapper from '../../../../../components/SignatureInitials/signatureInitialWrapper';
import Signature from '../../../../../components/Signature/Signature';
import ImageUploadWidgetGeneral from '../../../../../components/GenericForm/Widgets/ImageUploadWidgetGeneral';
import exportAsImage from '../../../../../utils/exportAsImage';
import SignatureWrapper from '../../SignatureWrapper';

@inject('commonStore', 'signatureStore')
@withLocalization
@observer
export class SignatureModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //showSignatureModal: this.props.showModal?this.props.showModal:false,
            username: this.props.recipientDetail? this.props.recipientDetail.user_name : 'Demo Sign',
            initials: this.props.recipientDetail? this.props.recipientDetail.user_name.split(' ').map(obj => obj[0].toUpperCase()).join('') : 'DS',
            image: null,
            type: 'signatureCanvas'
        }
        this.myRef = React.createRef();
    }

    componentDidMount(){
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {

    }

    handleSignatureModal = () => {
        this.setState({ showSignatureModal: !this.state.showSignatureModal });
    };

    updateSignature = (key) => {
        this.setState({ image: null, type: key });
    }

    handleSignatureChange = (_image) => {
        this.setState({ image: _image })
    }

    handleSave = async () => {
        const { type,image } = this.state;
        const {commonStore,t} = this.props;
        let img = await exportAsImage(this.myRef.current);
       
        if(image === null) {
            commonStore.addNotification(t('Please Add Signature'), null, 'error');
            return;
        }
        if(img){
            this.props.handleSignatureSave(type,img);
        }
    }


    render() {
        const { t,showModal,handleCloseModal,recipientDetail} = this.props;
        const { type, image } = this.state;
        return (
            <div>
                <Modal className='signatureWidgetModal' show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Signature')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div>
                            <div className='row'>
                                <Col xs={8}>
                                    <Input
                                        label={this.props.t('Full name')}
                                        value={this.state.username}
                                        disabled={true}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <Input
                                        label={this.props.t('Initials')}
                                        value={this.state.initials}
                                        disabled={true}
                                    />
                                </Col>
                            </div>
                            <Tabs defaultActiveKey="signatureCanvas" id="MainTabContainer"
                                onSelect={(key) => this.updateSignature(key)} style={{zIndex : 0}}>
                                
                                <Tab eventKey="signatureCanvas" title={t("Choose")}>
                                    <SignatureInitialWrapper
                                        username={this.state.username}
                                        initials={this.state.initials}
                                        is_initials = {this.props.initials?this.props.initials:false}
                                        onChange={this.handleSignatureChange}
                                    />
                                </Tab>
                                <Tab eventKey="signaturePad" title={t("Draw")}>
                                    {/* <Signature
                                        // onChange={(signature) => {
                                        // }
                                        //}
                                        onChange={this.handleSignatureChange}
                                        // required
                                        width={"100%"}
                                    // height={250}
                                    // error={this.state.template.is_signature_required && !this.state.signature}
                                    /> */}
                                    <Signature
                                        // required
                                        width={"100%"}   
                                        title={this.props.initials?"Initials":""}   
                                        onChange={this.handleSignatureChange}                                  
                                    // height={250}
                                    // error={this.state.template.is_signature_required && !this.state.signature}
                                    />
                                </Tab>
                                    <div ref={this.myRef} style = {{position : "absolute" , zIndex : -1 , top : "-63px"}}>
                                        <SignatureWrapper imgSrc={this.state.image != null ? this.state.image : null}/>
                                    </div>                                   
                                <Tab eventKey="signatureUpload" title={t("Upload")}>
                              {this.state.image === null ? <i className='signModal_Note'><span style={{fontWeight:'bolder',color:'black'}}>{t('Note :')}</span> {t('Only Image Files Supported (jpg, png, jpeg, webp, svg)')}</i> :
                              ''}
                                    <ImageUploadWidgetGeneral
                                        onChange={this.handleSignatureChange}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleSave}>
                            {t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default SignatureModal;