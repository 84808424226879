import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormControl } from 'react-bootstrap';
import { find } from 'lodash';
import moment from 'moment/min/moment-with-locales';

import Button from '../../../components/CustomButton/CustomButton.jsx';
import Select from '../../../elements/Select';
import withLocalization from '../../../hoc/withLocalization';
import statuses from '../../../utils/normalStatuses';
import { longDateFormat } from '../../../utils/dateFormat.js';
import DatePicker from '../../../components/DatePicker/DatePicker';

@inject('commonStore')
@withLocalization
@observer
class SubscriptionsFilter extends Component {
    setFilterDate(name, name1, value) {
        this.props.setFilter(name1, value);
        this.props.setFilter(name);
    }

    render() {
        const {
            filters, setFilter, onFilter, t } = this.props;
        const { name } = filters;
        return (
            <div className="list-filters">
                <div className="list-filters__item list-filters__item_grow ">
                    <FormControl
                        placeholder={t('Search')}
                        type="text"
                        onChange={(e) => setFilter('name', e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onFilter();
                            }
                        }}
                        value={name}
                    />
                </div>
                <div className="list-filters__item list-filters__item_to-right">
                    <Button fill wd onClick={() => onFilter()}>
                        {t('Filter')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default SubscriptionsFilter;
