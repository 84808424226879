import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import 'react-quill/dist/quill.snow.css';


//this component uses react-quill and serves as an editor for documents//

@inject('documentStore')
@withLocalization
@withRouter
@observer
class DocumentEditor extends Component {
    state = {
        editorHTML: '',
        theme : 'snow',
        modules: {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image', 'video'],
                ['clean']
            ],
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            }
        },
        formats: [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video'
        ],

        placeholder: 'Write Something',

    }

    componentDidMount(){
            
    }

    componentWillReceiveProps(){
        const {currentDocument} = this.props.documentStore;
        this.setState({editorHTML:currentDocument.document_description})
    }

    handleTextChange = (html) => {
        //handles text change in editor and passes it to parent//
        this.setState({ editorHTML: html })
        this.props.onChange(this.state.editorHTML)
    }

    render() {    
        //imports and uses Reactquill editor//
        return (
            <div>
                <ReactQuill
                    theme = {this.state.theme}
                    onChange={this.handleTextChange}
                    value={this.state.editorHTML}
                    modules={this.state.modules}
                    formats={this.state.formats}
                    readOnly={this.props.isEmployee}
                    placeholder={this.state.placeholder}                    
                    style={{ border: '0px',width:'210mm', minHeight:'200px', padding:'10px', color:'black'}}
                />
            </div>
        )
    }
}
export default DocumentEditor;