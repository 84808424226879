import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { find, filter } from 'lodash';

import Select from '../../../elements/Select';
import withLocalization from '../../../hoc/withLocalization';

@inject('taskStore')
@withLocalization
@observer
class TasksWidgetMobx extends Component {
    notLoadedYet = true;

    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
        };
    }

    componentWillReceiveProps(nextProps) {
        const cache = `${this.props.filterByUser} ${this.props.filterByProject}`;
        const ncache = `${nextProps.filterByUser} ${nextProps.filterByProject}`;
        if (cache != ncache || this.notLoadedYet) {
            this.notLoadedYet = false;
            nextProps.taskStore.loadLookup(nextProps.mode, '').then(list => {
                const { value } = nextProps;
                const {
                    onChange, mode, defaultIfOne, defaultByGeneric
                } = nextProps;
                const { loadingLookup, currentTasksLookup } = nextProps.taskStore;
                const options = this.prepareOptions(list);
                if (!value && defaultIfOne && options.length === 1) {
                    nextProps.onChange(options[0].value, options[0]);
                }
                if (defaultByGeneric && nextProps.filterByProject && !value) {
                    const o = find(options, o => (o.is_default_for_project = true));
                    if (o) nextProps.onChange(o.value, o);
                }
            });
        }
    }

    loadOptions = (inputValue, callback) => {
        const { mode, taskStore } = this.props;
        const { currentTasksLookup } = taskStore;
        this.props.taskStore.loadLookup(this.props.mode, inputValue).then(() => {
            callback(this.prepareOptions(currentTasksLookup[mode]));
        });
    };

    handleInputChange = newValue => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    prepareOptions = array => {
        const { filterByUser, filterByProject } = this.props;
        let _array = filterByUser ? filter(array, a => a.assigned_users.indexOf(parseInt(filterByUser)) >= 0) : array;
        _array = filterByProject ? filter(_array, a => a.project_id == parseInt(filterByProject)) : _array;
        return _array
            ? _array.map(pm => {
                return {
                    value: `${pm.id}`,
                    label: `${pm.name}`,
                    address: `${pm.address}`,
                    project_id: pm.project_id,
                    start_time: pm.start_time,
                    end_time: pm.end_time,
                    is_default_for_project: pm.is_default_for_project,
                    description: pm.description,
                    gps_data: pm.gps_data,
                };
            })
            : [];
    };

    render() {
        const { loadingLookup, currentTasksLookup } = this.props.taskStore;
        const {
            value,
            onChange,
            mode,
            defaultIfOne,
            filterByUser,
            shouldFilterByUser,
            t,
            disabled,
            emptyOption,
            emptyOptionLabel,
            label = '',
        } = this.props;
        const options = this.prepareOptions(currentTasksLookup);
        const currentSelect = find(options, o => {
            return String(o.value) === String(value);
        });
        if (shouldFilterByUser && !filterByUser) {
            return <div className="managers-widget">{t('Select user first')}</div>;
        }
        const inputView = !!label;
        return (
            <div className={`managers-widget ${inputView ? 'input-block' : ''}`}>
                {inputView && <span className="input-block__label">{label}</span>}
                <Select
                    options={options}
                    emptyOption={emptyOption}
                    inputView={inputView}
                    emptyOptionLabel={emptyOptionLabel}
                    value={currentSelect}
                    isDisabled={disabled}
                    placeholder="Select Task"
                    onChange={id => onChange(id.value, id)}
                    style={{ width: '100%' }}
                />
            </div>
        );
    }
}

@observer
export class TasksWidget extends Component {
    render() {
        return <TasksWidgetMobx {...this.props} mode="managers" />;
    }
}
