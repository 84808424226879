import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Tabs, Tab } from 'react-bootstrap';
import withLocalization from '~/hoc/withLocalization';
import BasicRules from './BasicRules/BasicRules';
import ExtendedRules from './ExtendedRules/ExtendedRules';
import VacationRules from './VacationRules/VacationRules';
import LoginRules from './LoginRules/LoginRules';
import ProjectRules from './ProjectRules/ProjectRules';
import TaskRules from './TaskRules/TaskRules';
import DeviationRules from './DeviationRules/DeviationRules';
import TipRules from './TipRules/TipRules';
import DateTimeRules from './DateTimeRules/DateTimeRules';
// import RolesAndPermission from './RolesAndPermissions/RolesAndPermissions';

const listUrl = '/admin';

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class ControlPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
        };
    }

    componentDidMount() {
        this.loadData();
        this.completeSettings();
    }

    componentWillMount() {
        this.props.clientStore.setLoading(true);
    }

    loadData = async () => {
        const { clientStore, userStore } = this.props;
        await clientStore.load(userStore.currentUser.client_id, false).then(
            (res) => {
                // console.log('Running', res)
                this.setState(prevState => ({ isLoad: !prevState.isLoad }))
            });
    }    

    submitForm() {
        const { history, clientStore, commonStore, t } = this.props;
        const currentClient = Object.assign({}, clientStore.currentEntity.client);
        // console.log("Updated data : ", currentClient)
        currentClient.data.isFirstTimeLogin = false;
        //  history.push('/admin')
        return clientStore.save(currentClient, false).then((result) => {
            if (!result.client || !result.client.id) {
                // console.log(result);
                commonStore.addNotification(t('Error'), null, 'error');
                return false;
            }
            commonStore.setAppLoaded(false).then((res)=>{
                commonStore.addNotification(t('Saved'), null, 'success');
                // console.log({ result });
                //history.push('/admin');
                // history.push(listUrl);
                return true;
            })
        });
    }
    completeSettings() {
        const { commonStore, t, history } = this.props;
        if (
            commonStore.config.client.data.isFirstTimeLogin &&
            commonStore.config.client.data.isFirstTimeLogin == true
        ) {
            return commonStore.addNotification(t('Please complete settings'), null, 'success');
        }
    }
    onCancel() {
        const { history } = this.props;
        if (!listUrl) return;
        history.push(listUrl);
    }

    render() {
        const { t, clientStore } = this.props;
        const allowTip = clientStore.currentEntity.client ? clientStore.currentEntity.client.allow_tip : '' ;
        const { isLoad} = this.state;
        return (
            <div className="main-content control-panel">
                <Tabs defaultActiveKey="dateTimeRules" id="MainTabContainer">
                    {/* <Tab eventKey="rolesAndPermission" title={t("Roles & Permissions")}>
                        {isLoad && <RolesAndPermission />}
                    </Tab> */}
                    <Tab eventKey="dateTimeRules" title={t('Regional Settings')}>
                        <h5>{t('Regional Settings')}</h5>
                        {isLoad && <DateTimeRules />}
                    </Tab>
                    <Tab eventKey="basicRules" title={t('Basic Rules')}>
                        <h5>{t('Basic Rules')}</h5>
                        {isLoad && <BasicRules />}
                    </Tab>
                    <Tab eventKey="extendedRules" title={t('Extended rules')}>
                        <h5>{t('Extended rules')}</h5>
                        {isLoad && <ExtendedRules />}
                    </Tab>
                    <Tab eventKey="projectRules" title={t('Project Rules')}>
                        <h5>{t('Project Rules')}</h5>
                        {isLoad && <ProjectRules />}
                    </Tab>
                    <Tab eventKey="Task Rules" title={t('Task Rules')}>
                        <h5>{t('Task Rules')}</h5>
                        {isLoad && <TaskRules />}
                    </Tab>
                    <Tab eventKey="deviationRules" title={t('Deviation Rules')}>
                        {isLoad && <DeviationRules />}
                    </Tab>
                    <Tab eventKey="vacationRules" title={t('Vacation Rules')}>
                        {isLoad && <VacationRules />}
                    </Tab>
                    <Tab eventKey="loginRules" title={t('Login Rules')}>
                        {isLoad && <LoginRules />}
                    </Tab>
                    {
                    allowTip &&
                    <Tab eventKey="tipRules" title={t("Tip Rules")}>
                        <h5>{t('Tip Rules')}</h5>
                        {isLoad && allowTip && <TipRules />}
                    </Tab>
                    }
                </Tabs>

                <button className="btn btn-fill" onClick={() => this.submitForm()}>
                    {t('Submit')}
                </button>

                <button className="btn" style={{ marginLeft: '2rem' }} onClick={() => this.onCancel()}>
                    {t('Cancel')}
                </button>
            </div>
        );
    }
}

export default ControlPanel;
