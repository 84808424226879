import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import { TimeInput } from '../../../../elements/Input';
import InputTime from '../../../../components/GenericForm/Widgets/InputTime';
import SwitchWidget from '../SwitchWidget';
import TimePickerInput from '../../../../components/TimePicker';


@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class WorklogValidation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            worklogValidation: {
                criticalVarianceThreshold: "00:00",
                overtimeThreshold: "00:00",
                sendEmailNotificationAt: "00:00",
                sendEmailNotificationForCriticalThresholdToAdmin: false,
                sendEmailNotificationForCriticalThresholdToPM: false,
                sendEmailNotificationForMediumThresholdToAdmin: false,
                sendEmailNotificationForMediumThresholdToPM: false,
                sendEmailNotificationForOvertimeThresholdToAdmin: false,
                sendEmailNotificationForOvertimeThresholdToPM: false,
                validateTimetrackOption: false,
            },
        }
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const { worklogValidation } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            const basicRules = Object.assign({}, currentEntity.client.data.basicRules);
            if (basicRules.worklogValidation) {
                worklogValidation.validateTimetrackOption = basicRules.worklogValidation.validateTimetrackOption;
                worklogValidation.criticalVarianceThreshold = basicRules.worklogValidation.criticalVarianceThreshold;
                worklogValidation.overtimeThreshold = basicRules.worklogValidation.overtimeThreshold;
                worklogValidation.sendEmailNotificationAt = basicRules.worklogValidation.sendEmailNotificationAt;
                worklogValidation.sendEmailNotificationForCriticalThresholdToAdmin = basicRules.worklogValidation.sendEmailNotificationForCriticalThresholdToAdmin;
                worklogValidation.sendEmailNotificationForCriticalThresholdToPM = basicRules.worklogValidation.sendEmailNotificationForCriticalThresholdToPM;
                worklogValidation.sendEmailNotificationForMediumThresholdToAdmin = basicRules.worklogValidation.sendEmailNotificationForMediumThresholdToAdmin;
                worklogValidation.sendEmailNotificationForMediumThresholdToPM = basicRules.worklogValidation.sendEmailNotificationForMediumThresholdToPM;
                worklogValidation.sendEmailNotificationForOvertimeThresholdToAdmin = basicRules.worklogValidation.sendEmailNotificationForOvertimeThresholdToAdmin;
                worklogValidation.sendEmailNotificationForOvertimeThresholdToPM = basicRules.worklogValidation.sendEmailNotificationForOvertimeThresholdToPM;
                this.setState({ worklogValidation })
            }
        }
    }

    handleTextChange = (name, value) => {
        const { worklogValidation } = this.state;
        worklogValidation[name] = value;
        this.props.handleUserDataChange('worklogValidation', worklogValidation);
        this.setState({ worklogValidation });
    }

    handleCheck = (name, evt) => {
        const { worklogValidation } = this.state;
        worklogValidation[name] = evt;
        this.props.handleUserDataChange('worklogValidation', worklogValidation);
        this.setState({ worklogValidation });
    }

    render() {
        const { worklogValidation } = this.state;
        const { t } = this.props;
        const { currentEntity } = this.props.clientStore;
        const { dateTimeRules } = currentEntity.client.data;       
        const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';
        return (
            <div>
                <Row>
                    <Col sm={1}>
                        <SwitchWidget
                            value={worklogValidation.validateTimetrackOption}
                            onChange={checked => this.handleCheck('validateTimetrackOption', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Validate timetrack option')}</label>
                        <OverlayTrigger
                            key="top"
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Some Text")}
                                </Tooltip>
                            }
                        ><i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i></OverlayTrigger>
                    </Col>
                </Row>
                {worklogValidation.validateTimetrackOption &&
                    <div style={{ marginTop: '20px' }}>
                        <Row>
                            <Col sm={6}>
                                {/* <TimeInput
                                    label={`${t('Critical variance threshold (hh:mm)')}`}
                                    name="criticalVarianceThreshold"
                                    value={worklogValidation.criticalVarianceThreshold}
                                    onChange={evt => {
                                        this.handleTextChange('criticalVarianceThreshold', evt.target.value);
                                    }}
                                /> */}
                                <label className="control-label">{('Critical variance threshold')}
                                    <span className="text-muted "><em> (hh:mm)</em></span>
                                </label>
                                <InputTime
                                    width={"auto"}
                                    value={worklogValidation.overtimeThreshold}
                                    onChange={value => {
                                        this.handleTextChange('overtimeThreshold', value);
                                    }}
                                />
                            </Col>
                            <Col sm={6}>
                                {/* <TimeInput
                                    label={`${t('Overtime threshold (hh:mm)')}`}
                                    name="overtimeThreshold"
                                    value={worklogValidation.overtimeThreshold}
                                    onChange={evt => {
                                        this.handleTextChange('overtimeThreshold', evt.target.value);
                                    }}
                                /> */}
                                <label className="control-label">{('Overtime threshold')}
                                    <span className="text-muted "><em> (hh:mm)</em></span>
                                </label>
                                <InputTime
                                    width={"auto"}
                                    value={worklogValidation.overtimeThreshold}
                                    onChange={value => {
                                        this.handleTextChange('overtimeThreshold', value);
                                    }}
                                />
                            </Col>
                        </Row>
                        <label className="control-label">{t('Send email notification for')}</label>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={6}>
                                <Row>
                                    <Col sm={2}>
                                        <SwitchWidget
                                            value={worklogValidation.sendEmailNotificationForCriticalThresholdToPM}
                                            onChange={checked => this.handleCheck('sendEmailNotificationForCriticalThresholdToPM', checked)} />
                                    </Col>
                                    <Col sm={10}>
                                        <label className="control-label">{t('Critical threshold to PM')}</label>
                                        <OverlayTrigger
                                            key="top"
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    {t("Some Text")}
                                                </Tooltip>
                                            }
                                        ><i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i></OverlayTrigger>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={6}>
                                <Row>
                                    <Col sm={2}>
                                        <SwitchWidget
                                            value={worklogValidation.sendEmailNotificationForCriticalThresholdToAdmin}
                                            onChange={checked => this.handleCheck('sendEmailNotificationForCriticalThresholdToAdmin', checked)} />
                                    </Col>
                                    <Col sm={10}>
                                        <label className="control-label">{t('Critical threshold to admin')}</label>
                                        <OverlayTrigger
                                            key="top"
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    {t("Some Text")}
                                                </Tooltip>
                                            }
                                        ><i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i></OverlayTrigger>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={6}>
                                <Row>
                                    <Col sm={2}>
                                        <SwitchWidget
                                            value={worklogValidation.sendEmailNotificationForMediumThresholdToPM}
                                            onChange={checked => this.handleCheck('sendEmailNotificationForMediumThresholdToPM', checked)} />
                                    </Col>
                                    <Col sm={10}>
                                        <label className="control-label">{t('Medium threshold to PM')}</label>
                                        <OverlayTrigger
                                            key="top"
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    {t("Some Text")}
                                                </Tooltip>
                                            }
                                        ><i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i></OverlayTrigger>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={6}>
                                <Row>
                                    <Col sm={2}>
                                        <SwitchWidget
                                            value={worklogValidation.sendEmailNotificationForMediumThresholdToAdmin}
                                            onChange={checked => this.handleCheck('sendEmailNotificationForMediumThresholdToAdmin', checked)} />
                                    </Col>
                                    <Col sm={10}>
                                        <label className="control-label">{t('Medium threshold to admin')}</label>
                                        <OverlayTrigger
                                            key="top"
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    {t("Some Text")}
                                                </Tooltip>
                                            }
                                        ><i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i></OverlayTrigger>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={6}>
                                <Row>
                                    <Col sm={2}>
                                        <SwitchWidget
                                            value={worklogValidation.sendEmailNotificationForOvertimeThresholdToPM}
                                            onChange={checked => this.handleCheck('sendEmailNotificationForOvertimeThresholdToPM', checked)} />
                                    </Col>
                                    <Col sm={10}>
                                        <label className="control-label">{t('Overtime threshold to PM')}</label>
                                        <OverlayTrigger
                                            key="top"
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    {t("Some Text")}
                                                </Tooltip>
                                            }
                                        ><i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i></OverlayTrigger>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={6}>
                                <Row>
                                    <Col sm={2}>
                                        <SwitchWidget
                                            value={worklogValidation.sendEmailNotificationForOvertimeThresholdToAdmin}
                                            onChange={checked => this.handleCheck('sendEmailNotificationForOvertimeThresholdToAdmin', checked)} />
                                    </Col>
                                    <Col sm={10}>
                                        <label className="control-label">{t('Overtime threshold to admin')}</label>
                                        <OverlayTrigger
                                            key="top"
                                            placement="right"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    {t("Some Text")}
                                                </Tooltip>
                                            }
                                        ><i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} style={{ opacity: '0.5' }} aria-hidden="true"></i></OverlayTrigger>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={6}>
                                {/* <TimeInput
                                    label={`${t('Send email notification at')} (hh:mm)`}
                                    name="sendEmailNotificationAt"
                                    value={worklogValidation.sendEmailNotificationAt}
                                    onChange={evt => {
                                        this.handleTextChange('sendEmailNotificationAt', evt.target.value);
                                    }}
                                /> */}
                                <label className="control-label">{('Send email notification at')}
                                    <span className="text-muted "><em> (hh:mm)</em></span>
                                </label>                                
                                <TimePickerInput        
                                    style={{width:150}}        
                                    value={worklogValidation.sendEmailNotificationAt}
                                    format={format=="hh:mm"?"HH:mm":format}
                                    onChange={value => {
                                        this.handleTextChange('sendEmailNotificationAt', value);
                                    }}
                                />
                                {/* <InputTime
                                    width={"auto"}
                                    value={worklogValidation.sendEmailNotificationAt}
                                    onChange={value => {
                                        this.handleTextChange('sendEmailNotificationAt', value);
                                    }}
                                /> */}
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}
export default WorklogValidation;
