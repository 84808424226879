import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {Col} from 'react-bootstrap';
import withLocalization from '../../hoc/withLocalization';
import SignatureInitial from './';
import Select from '../../elements/Select';
import './signatureInitials.css';
import FontPicker from "font-picker-react";

// const options = [{value:'Pacifico',label:'Pacifico'},{value:'Hurricane',label:'Hurricane'},{value:'Dancing Script',label:'Dancing Script'},{value:'cursive',label:'Cursive'}];

@inject('commonStore')
@withLocalization
@observer
export class SignatureInitialWrapper extends Component {
    
    constructor(props) {
        super(props);        
        this.state={
         //   font:'cursive',
            username:this.props.username,
            initials:this.props.initials,
            activeFontFamily: "Brush Script MT",
        }
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
       
    }

    handleImage = (image) =>{
        this.props.onChange(image);
    }

    handleFontChange = (value) =>{
        this.setState({activeFontFamily:value});
    }
    
    render() {
        return (
            <div>                     
                <div className={'wrapper-row fontpickerSignature'}>                    
                    {/* <Select
                        options={options}  
                        style={{'width':'100%'}}                  
                        value={options.find(obj=>obj.value == this.state.font)}                    
                        onChange={obj => this.handleFontChange(obj.value)}                    
                    />                     */}
                    <FontPicker
                   
                       families={['Pacifico','Hurricane','Dancing Script','Brush Script MT']}
                    // className={'fontPicker'}
                    apiKey="AIzaSyD3pmNTCQHmrGCROGgRlfVkANoAJiq8L6Q"
                    previews={true}
                   activeFontFamily={this.state.activeFontFamily}     
                    onChange={(nextFont) =>
                        this.setState({
                            activeFontFamily: nextFont.family,
                        })
                    }
                />
                {/* <p className="apply-font">gg</p> */}
                </div>
                <div className={'wrapper-row'} >
                    {/* <Col xs={8} className={'scrollX'}> */}
                    <Col xs={12} className={'scrollX'}>
                        <SignatureInitial
                        className={'apply-font'}
                        initials = {this.props.is_initials ? this.state.initials:this.state.username}
                        font = {this.state.activeFontFamily}
                        onChange = {this.handleImage} />
                    </Col>                    
                    {/* <Col xs={3} className={'scrollX'}>
                        <SignatureInitial
                        initials = {this.state.initials}
                        font = {this.state.font}
                        onChange = {this.handleImage} />
                    </Col> */}
                </div>
            </div>
        )
    }
}

export default SignatureInitialWrapper;