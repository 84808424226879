export const schema = {
    title: 'Task',
    type: 'object',
    required: ['project_id', 'name', 'description'],
    properties: {
        id: { type: 'integer' },
        task_number: { type: ['integer', 'null'], title: 'Task Number' },
        project_id: { type: ['integer'], title: 'Project' },
        name: { type: ['string'], title: 'Name', isRequired: true },
        status: { type: ['string', 'null'], title: 'Status', default: 'active' },
        assignee_id: { type: ['string'], title: 'Assignee' },
        parent_id: { type: ['integer', 'null'], title: 'Parent project' },
        project_type: { type: ['integer', 'null'], title: 'Project type' },
        contact_person: { type: ['string', 'null'], title: 'Contact name' },
        // title: { type: ['string', 'null'], title: 'Contact title' },
        post_place: { type: ['string', 'null'], title: 'Post place' },
        post_number: { type: ['string', 'null'], title: 'Post number', format: 'postnumber' },
        address: { type: ['string', 'null'], title: 'Address' },
        g_nr: { type: ['string', 'null'], title: 'G.Nr' },
        b_nr: { type: ['string', 'null'], title: 'B.Nr' },
        email: { type: ['string', 'null'], title: 'Email', format: 'email' },
        phone: { type: ['string', 'null'], title: 'Phone', format: 'phone-no' },
        description: { type: ['string'], title: 'Description', isRequired: true },
        est_hours: { type: ['integer', 'null'], title: 'Estimated hours' },
        start_time: { type: ['integer', 'null'], title: 'Start time' },
        end_time: { type: ['integer', 'null'], title: 'Finish time' },
        auto_log_on_days: { type: ['string', 'null'], title: 'Automatically log on days' },
        scheduled_days: { type: ['string', 'null'], title: 'Scheduled days' },
        gps_data: { type: ['string', 'null'], title: 'GPS location', format: 'gps' },
        est_work: {
            title: 'Allowed work',
            type: 'string',
            anyOf: [
                {
                    title: 'Daily',
                    enum: ['daily'],
                },
                {
                    title: 'Weekly',
                    enum: ['weekly'],
                },
                {
                    title: 'Monthly',
                    enum: ['monthly'],
                },
            ],
            default: 'weekly',
        },
        members: {
            type: 'array',
            title: 'Members',
            default: [],
            items: {
                type: 'object',
                required: ['user_id'],
                properties: {
                    user_id: {
                        title: 'Member',
                        type: 'integer',
                    },
                    hours: {
                        title: 'Estimated hours',
                        type: 'number',
                    },
                    timeframe: {
                        title: 'Allowed work',
                        type: 'string',
                        anyOf: [
                            {
                                title: 'Daily',
                                enum: ['daily'],
                            },
                            {
                                title: 'Weekly',
                                enum: ['weekly'],
                            },
                            {
                                title: 'Monthly',
                                enum: ['monthly'],
                            },
                        ],
                        default: 'weekly',
                    },
                },
            },
        },
        data: {
            type: 'object',
            properties: {
                require_hms: { type: 'boolean', title: 'Require HMS', default: true },
                security_analyze: { type: 'boolean', title: 'Security analyze', default: false },
                start_date: { type: 'string', format: 'date', title: 'Start date' },
                finish_date: { type: 'string', format: 'date', title: 'Finish date' },
            },
        },
        attachments: { type: ['string', 'null'], title: 'Attachments' },
        Shifts: { type: 'string', title: 'Shifts' },
    },
};

export const getSchema = () => schema;

export default getSchema;
