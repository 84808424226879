import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';

import '../../../styles/css/styles.css';
import {shortDateFormat, timeFormat, getShortDateFormat, getTimeFormat} from '../../../utils/dateFormat'

// Components
import Button from '../../../components/CustomButton/CustomButton.jsx';

import { Modal } from 'react-bootstrap';
import ChecklistElementCheck from './ChecklistElementCheck';
import config from '~/library/config';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import ChecklistDeviation from './ChecklistDeviation';

@inject('checklistStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ChecklistTreeElement extends Component {
    state={
        isThumpsUp : false,
        isNO: false,
        isNotApplicable: false
    }


    componentDidMount() {
        if(this.props.checklist_status == 'Approved'){
            if(this.props.element.status == 'Done'){
                this.setState({ isNO: true, isNotApplicable: true })
            }
            if(this.props.element.status == 'NO'){
                this.setState({ isThumpsUp: true, isNotApplicable: true })
            }
            if(this.props.element.status == 'Not Applicable'){
                this.setState({ isThumpsUp: true, isNO: true })
            }
        }
    }

    setStatusColor = (status) => {
        let color = "";
        switch (status) {
            case 'Done':
                color = 'green';
                break;

            case 'Approved':
                color = 'green';
                break;            

            case 'Deviation':
                color = 'red';
                break;

            case 'Under work':
                color = '#ff8c00';
                break;

            default:
                color = 'blue';            
        }
        return color;
    }

    render() {
        const { t } = this.props;
        const { commonStore } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        return (
            <div className="flex-box checklist-element" key={this.props.element.id} style={{ borderColor: this.setStatusColor(this.props.element.status)}}>                 
                <div className="col-md-8" >
                    <div className="checklist-element-name" onClick={() => { this.props.checkElement(this.props.element) }}>{this.props.element.name}</div>
                    {this.props.element.comment &&
                        <div className="checklist-comment">
                            {this.props.element.comment}
                        </div>
                    }
                    <div className="checklist-element-bottombar" style={{ display: '-webkit-inline-box' }}>
                        {this.props.element.status != undefined && (this.props.element.status == 'Done' || this.props.element.status === 'NO' || this.props.element.status === "Not Applicable")
                         ? (
                            <>
                                <div className="checklist-element-bottombar-div">{t('Status')} : &nbsp;
                                    <span className='checklist-element-done' style={{ color: this.setStatusColor(this.props.element.status)}}>
                                        {this.props.element.status ? t(this.props.element.status) : t('New')}
                                    </span>
                                </div>
                                <div className="checklist-element-bottombar-div">
                                    {t('By')} : {this.props.element.updated_by_name}
                                </div>
                                <div className="checklist-element-bottombar-div">
                                    {t('Date')} : {`${moment(this.props.element.updated_at).format(dateTimeRules.short_date_format)} ${moment(this.props.element.updated_at).format(dateTimeRules.time_format == "hh:mm" ? "HH:mm" : "hh:mm A" )}`}
                                </div>
                            </>
                        ) : (
                            <div className="checklist-element-bottombar-div">{t('Status')} : &nbsp;
                                <span className='checklist-element-new' style={{ color: this.setStatusColor(this.props.element.status)}}>
                                    {this.props.element.status ? t(this.props.element.status) : t('New')}
                                </span>
                            </div>
                        )
                        }
                        {this.props.element.attachments &&
                            <div className="checklist-attachment-link" onClick={() => this.props.attachmentsModal(this.props.element)}>
                                {t("Click To See All Files")}
                            </div>
                        }
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="actions-center">
                        {/* {this.props.element.status != undefined && this.props.element.status == 'Done' ? (
                            <Button bsStyle="warning" simple icon disabled >
                                <i className="fa fa-thumbs-up" />
                            </Button>
                        ) : ( */}

                        <Button disabled={this.state.isThumpsUp} className={this.props.element.status == 'Deviation' ? 'disabled btn-simple' : 'btn-simple'} bsStyle="warning" simple icon style={{ backgroundColor: this.props.element.status != undefined && this.props.element.status == 'Done' ? 'green': ''}} onClick={() => { this.props.checkElement(this.props.element) }}>
                            <i className="fa fa-thumbs-up" />
                        </Button>
                        {/* )} */}
                        <Button disabled={this.state.isNO} className={this.props.element.status != undefined && this.props.element.status === 'NO' ? "no-na-btn btn-green":"no-na-btn"} onClick={() => { this.props.checkElement2(this.props.element,"NO") }}
                        style={{ backgroundColor: this.props.element.status != undefined && this.props.element.status == 'NO' ? 'green': ''}} >NO</Button>
                        <Button disabled={this.state.isNotApplicable} className={this.props.element.status != undefined && this.props.element.status === 'Not Applicable' ? "no-na-btn btn-green":"no-na-btn"} onClick={() => { this.props.checkElement(this.props.element,"NA") }}
                        >N/A</Button>
                        <Button
                            className={this.props.element.status == 'Deviation' ? 'disabled btn-simple' : 'btn-simple'}
                            simple icon onClick={() => this.props.doDeviation(this.props.element)} >
                            {t('Deviation')}
                        </Button>
                    </div>
                </div>
            </div>

        );
    }
}

export default ChecklistTreeElement;
