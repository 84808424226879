import { observable, action, computed } from 'mobx'
import agent from '../agent';

//all API calls are defined in agent.js under documents for documents and document_templates//
//file contains all the observable values and actions to handle documents//
//import this file in index.js and add it to stores list before using//

const currentDocumentInit = {
    //default values to initiate currentDocument//
    id: 0,
    document_name: '',
    document_description: '',
    created_by_id: null,
    ispdf:false,
    pdf_attachment:'',
}

const currentDocumentTemplateInit = {
    //default values to initiate currentDocumentTemplate//
    id: 0,
    template_name: '',
    template_documents: '',
    created_by_id: null,
    client_id: null,
    is_signature_required: false,
    isobligatory:false,
    document_read:null,
    assigendMembers:'',
}

class documentStore {
    //observable values for documents//

    @observable currentList = [];

    @observable currentDocument = currentDocumentInit;

    @observable lastListLoadTime = null;

    @observable loading = false;

    @observable filters = {
        name: '',
    };

    @observable appliedFilters = {
        name: '',
    };



    //actions for documents//

    @action setFilter(name, value) {
        //handles entries in search column//
        //takes 2 arguments, type - string, name, value
        const filter = Object.assign({}, this.filters);
        filter[name] = value;
        this.filters = filter;
    }

    @action onFilter() {
        //sets appliedFilter to filter valuse from search column//
        this.appliedFilters = Object.assign({}, this.filters);
    }

    @action resetLastListLoadTime() {
        //resets the LoadTime to refresh list//
        this.lastListLoadTime = new Date();
    }

    @action loadDocumentById = async (id) => {
        //sets editable document values from list to currentDocument//
        //takes 1 argument, type - integer, id//
        if (id && id > 0) {
            await this.getDocumentById(id).then((data) => {
                this.currentDocument.id = data.document.id;
                this.currentDocument.document_name = data.document.document_name;
                this.currentDocument.document_description = data.document.document_description;
                this.currentDocument.created_by_id = data.document.created_by_id;
                this.currentDocument.ispdf = data.document.ispdf;
                this.currentDocument.pdf_attachment = data.document.pdf_attachment;
                return true;
            })
        }
    }

    @action loadDefaultDocument() {
        //initiates currentDocument for new document//
        this.currentDocument = currentDocumentInit;
    }

    @action handleDocumentChange = async (name, value) => {
        //handles changes made in the document and sets it to currentDocument//
        //takes 2 arguments, type - string, name, value//
        this.currentDocument[name] = value;
    }


    @action getDocuments(params) {
        //calls get method from documents API//
        //takes 1 arguments, type - object, params//
        //returns document list//
        return agent.documents
            .getDocuments(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.currentList = list;
                    this.lastListLoadTime = list.time;
                    return list;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    @action getDocumentsList() {
        //calls get method from documents API//
        //returns document list//
        return agent.documents
            .getDocumentsList()
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    @action getAdminDocuments() {
        //calls get method from documents API//
        //returns document list made by admin//
        return agent.documents
            .getAdminDocuments()
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    @action getDocumentById(id) {
        //calls get method from documents API//
        //takes 1 argument, type - integer, id//
        //returns API response//
        return agent.documents.getDocumentById(id)
            .then(
                action((response) => {
                    if (!response.document) response.document = {};
                    //this.loading = false;
                    return response;
                })
            )
            .catch(
                action((error) => {
                    //this.loading = false;
                    throw error;
                })
            )
    }

    @action getPdfFormAttachmentById =(id)=>{
        //calls get method from attachments API//
        //takes 1 argument, type - integer, id//
        //returns API response//
        return agent.documents.getPDFDataByAttachmentId(id)
            .then(
                action((response) => {
                    if (!response) response = {};
                    return response;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            )
    }

    @action saveDocumentData(data) {
        //calls post method from documents API//
        //takes 1 arguments, type - object, document//
        //returns API response//
        return agent.documents.addDocument(data).then(
            action((response) => {
                return response;
            })
        )
            .catch(
                action((error) => {
                    throw error;
                })
            )
    }

    @action copyDocument(data) {
        //calls post method from documents API//
        //takes 1 arguments, type - object, document//
        //returns API response//
        return agent.documents.copyDocument(data).then(
            action((response) => {
                return response;
            })
        )
            .catch(
                action((error) => {
                    throw error;
                })
            )
    }

    @action async deleteDocumentById(id) {

        //calls delete method from documents API//
        //takes 1 argument, type - integer, id//
        await agent.documents.deleteDocumentById(id).then(
            action((response) => {
                return response;
            })
        ).catch(
                action((error) => {
                    throw error;
                })
            )
    }

    //observable values for document_templates//

    @observable currentTemplateList = [];

    @observable currentDocumentTemplate = currentDocumentTemplateInit;

    @observable lastTemplateListLoadTime = null;

    @observable templateFilters = {
        name: '',
    };

    @observable appliedTemplateFilters = {
        name: '',
    };

    //actions for document_template//

    @action setTemplateFilter(name, value) {
        //handles entries in search column//
        //takes 2 arguments, type - string, name, value
        const filter = Object.assign({}, this.templateFilters);
        filter[name] = value;
        this.templateFilters = filter;
    }

    @action onTemplateFilter() {
        //sets appliedFilter to filter valuse from search column//
        this.appliedTemplateFilters = Object.assign({}, this.templateFilters);
    }

    @action resetLastTemplateListLoadTime() {
        //resets the LoadTime to refresh list//
        this.lastTemplateListLoadTime = new Date();
    }


    @action loadDocumentTemplateById = async (id) => {
        //sets editable document values from list to currentDocument//
        //takes 1 argument, type - integer, id//
        if (id && id > 0) {
            await this.getDocumentTemplateById(id).then((data) => {
                this.currentDocumentTemplate.id = data.document_template_data.id;
                this.currentDocumentTemplate.template_name = data.document_template_data.template_name;
                this.currentDocumentTemplate.template_documents = data.document_template_data.template_documents;
                this.currentDocumentTemplate.created_by_id = data.document_template_data.created_by_id;
                this.currentDocumentTemplate.client_id = data.document_template_data.client_id;
                this.currentDocumentTemplate.is_signature_required = data.document_template_data.is_signature_required;
                this.currentDocumentTemplate.isobligatory = data.document_template_data.isobligatory;
                this.currentDocumentTemplate.document_read = data.document_read;
                this.currentDocumentTemplate.assigendMembers = data.assigendMembers;
                return this.currentDocumentTemplate;
            })
        }
    }

    @action handleDocumentTemplateChange = async (name, value) => {
        //handles changes made in the document and sets it to currentDocument//
        //takes 2 arguments, type - string, name, value//
        this.currentDocumentTemplate[name] = value;
    }

    @action loadDefaultDocumentTemplate() {
        //initiates currentDocumentTemplate for new documentTemplate//
        this.currentDocumentTemplate = currentDocumentTemplateInit;
    }


    @action getDocumentTemplates(params) {
        //calls get method from document_templates API//
        //takes 1 arguments, type - object, params//
        //returns document_template list//
        return agent.documents
            .getDocumentTemplates(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastTemplateListLoadTime = list.time;//this can change
                    this.currentTemplateList = list;
                    return this.currentTemplateList;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    @action getDocumentTemplateById(id) {
        //calls get method from document_templates API//
        //takes 1 argument, type - integer, id//
        //returns API response//

        //this.loading = true;
        return agent.documents.getDocumentTemplateById(id)
            .then(
                action((response) => {
                    if (!response.document_template_data) response.document_template_data = {};
                    //this.loading = false;
                    return response;
                })
            )
            .catch(
                action((error) => {
                    //this.loading = false;
                    throw error;
                })
            )
    }


    @action saveDocumentTemplateData(template) {
        //calls post method from documents API//
        //takes 1 arguments, type - object, document//
        //returns API response//
        return agent.documents.addDocumentTemplate(template).then(
            action((response) => {
                return response;
            })
        )
            .catch(
                action((error) => {
                    throw error;
                })
            )
    }

    @action async deleteDocumentTemplateById(id) {
        //calls delete method from document_templates API//
        //takes 1 argument, type - integer, id//
        let result = await agent.documents.deleteDocumentTemplateById(id);
        return result;
    }

    @action async getDoumentsInTemplateById(id) {
        //calls get method from document_template API//
        //takes 1 arguments, type - integer, id//
        //returns list of documents in the template//
        return agent.documents
            .getDocumentsInTemplate(id)
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    @action async getDoumentsInTemplateByIdInSelectedOrder(id) {
        //calls get method from document_template API//
        //takes 1 arguments, type - integer, id//
        //returns list of documents in the template in the order as selected//
        return agent.documents
            .getDocumentsInTemplateInSelectedOrder(id)
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    @action async getMultipleDoumentsByIds(paylaod) {
        //calls get method from document_template API//
        //takes 1 arguments, type - integer, id//
        //returns list of documents in the template in the order as selected//
        return agent.documents
            .getMultipleDoumentsByIds(paylaod)
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    @action async generateDocumentReport(id) {
        //calls get method from document_template API//
        //takes 1 arguments, type - integer, id//
        //generates reports of documents in template//
        return agent.documents.getDocumentReportByTemplateId(id)
            .then(
                action((response) => {
                    return true;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    @action async generateDocumentReportPreview(id) {
        //calls get method from document_template API//
        //takes 1 arguments, type - integer, id//
        //generates reports of documents in template//
        return agent.documents.getDocumentReportPreviewByTemplateId(id)
            .then(
                action((response) => {
                    return response.path;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }
    
    @action async generateMultipleDocumentsPdfPreviewUrl(params) {
        //calls get method from document_template API//
        //takes 1 arguments, type - integer, id//
        //generates reports of documents in template//
        return agent.documents.getmultipleDocumentsPdfPreviewUrl(params)
            .then(
                action((response) => {
                    return response.path;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    @action async getDocumentTemplateAssignList(id) {
        //calls get method from document_template API//
        //takes 1 arguments, type - integer, id//
        //return of document templates and assigned document_templates for user//
        return agent.documents.getDocumentTemplateAssignList(id)
            .then(
                action((response) => {
                    return response;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }
    

    //actions for document_read//

    @action addDocumentRead(data) {
        //calls post method from documents API//
        //takes 1 arguments, type - object, document//
        //returns API response//
        return agent.documents.addDocumentRead(data).then(
            action((response) => {
                return response;
            })
        )
            .catch(
                action((error) => {
                    throw error;
                })
            )
    }

    @action sendDocumentReadMailToMembers(data) {
        //calls post method from documents API//
        //takes 1 arguments, type - object, document//
        //returns API response//
        return agent.documents.sendDocumentReadMailToMembers(data).then(
            action((response) => {                
                return response;
            })
        )
            .catch(
                action((error) => {
                    throw error;
                })
            )
    }

    @action updateDocumentReadStatus(data) {
        //calls post method from documents API//
        //takes 1 arguments, type - object, document//
        //returns API response//
        return agent.documents.updateDocumentReadStatus(data).then(
            action((response) => {
                // console.log(response);
                return response;
            })
        )
            .catch(
                action((error) => {
                    throw error;
                })
            )
    }

    @action assignDocumentTemplatesToUser(data) {
        //calls post method from documents API//
        //takes 1 arguments, type - object, document//
        //returns API response//
        return agent.documents.assignDocumentTemplatesToUser(data).then(
            action((response) => {
                // console.log("Documents assigned ::: ",response);
                return response;
            })
        )
            .catch(
                action((error) => {
                    throw error;
                })
            )
    }
}

export default new documentStore();
