import React from 'react';
import DateRangePicker from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css';
import moment from 'moment/min/moment-with-locales';

import PrimaryDatePicker from '../DatePicker/DatePicker';

const DateRange = ({
    onChange, numberOfCalendars, value, ...rest
}) => {
    const _numberOfCalendars = numberOfCalendars || 1;
    return (
        <DateRangePicker
            locale={
                // window.localStorage.getItem('locale') ? window.localStorage.getItem('locale').replace('no', 'nb') : 'en'
                window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale').replace('no', 'nb') : 'en'
            }
            firstOfWeek={1}
            singleDateRange
            numberOfCalendars={_numberOfCalendars}
            value={value}
            onSelect={onChange}
            {...rest}
        />
    );
};
export default DateRange;
