import React, { Component } from 'react';
import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField';
import { Tabs, Tab } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';

import MultiSelect from './ShiftForm';
@inject('dashboardStore', 'userStore', 'commonStore', 'scheduleStore')
@observer
export default class ShiftHistory extends Component {
    resolveUsername(resources, id) {
        const user = _.find(resources, r => r.id === id);
        if (user) return user.name;
        return '???';
    }

    getInvited() {
        const { entity, t, resources } = this.props;
        if (!entity.data.invited) return null;
        const { invited } = entity.data;
        return (
            <table className="schedule__invites-table">
                {invited.map((row, index) => (
                    <tr key={`r__${index}`}>
                        <td>{this.resolveUsername(resources, row.user_id)}</td>
                        <td>{moment(new Date(row.date)).format('DD.MM HH:mm')}</td>
                    </tr>
                ))}
            </table>
        );
    }

    getProposed() {
        const {
            entity, t, resources, onAcceptProposed
        } = this.props;
        if (!entity.data.proposed) return null;
        const { proposed } = entity.data;
        return (
            <table className="schedule__invites-table">
                {proposed.map((row, index) => (
                    <tr key={`r__${index}`}>
                        <td>{this.resolveUsername(resources, row)}</td>
                        <td>
                            <a onClick={() => onAcceptProposed(row)}>{t('accept')}</a>
                        </td>
                    </tr>
                ))}
            </table>
        );
    }

    getHistory() {
        const { entity, t, resources, commonStore } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        if (!entity.data.history) return null;
        const { history } = entity.data;
        return (
            <div className="schedule__history-table">
                {history.map((row, index) => (
                    <div className="history-item" key={`r__${index}`}>
                        <div className="history-time">
                            {/* {row.user_name}, {moment(new Date(row.date)).format('DD.MM HH:mm')} */}
                            {`${moment(new Date(row.date)).format(dateTimeRules.short_date_format)} ${moment(new Date(row.date)).format(dateTimeRules.time_format == "hh:mm" ? "HH:mm" : "hh:mm A" )}`}
                        </div>
                        <div className="history-comment">{row.comment}</div>
                    </div>
                ))}
            </div>
        );
    }

    render() {
        const { entity, t } = this.props;

        return (
            <div  className="setCustomTab">
            <Tabs defaultActiveKey="history" id="tabs"  >
                <Tab eventKey="history" title={t('History')} key="history" >
                   {this.getHistory()}
                </Tab>
                {entity.assignee_id == 0 && (
                    <Tab eventKey="invites" title={t('Invites')} key="invites">
                        <div className="form-group field field-string">
                            <label className="control-label">{t('Invited')}</label>
                            {this.getInvited()}
                        </div>
                    </Tab>
                )}
                {entity.data.proposed && entity.assignee_id == 0 && (
                    <Tab eventKey="proposes" title={t('Proposed')} key="proposes">
                        <div className="form-group field field-string">
                            <label className="control-label">{t('Proposed')}</label>
                            {this.getProposed()}
                        </div>
                    </Tab>
                )}
            </Tabs>
            </div>
        );
    }
}
