import React, {Component} from 'react';
import { inject, observer } from 'mobx-react';
import { FormControl } from 'react-bootstrap';

//components
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '../../../../hoc/withLocalization';

//this component handles the search and is document filter// 

@inject('commonStore')
@withLocalization
@observer
class DocumentFilter extends Component{
    render(){
        const { filters, setFilter, onFilter, loading, t } = this.props;
        //const { config } = this.props.commonStore;
        const {name} = filters;
        return(
            <div className='list-filters'>
                <div className='list-filters__item list-filters__item_grow'>
                    <FormControl
                        placeholder = {t('Search Document By Name')}
                        type = "text"
                        onChange = { (e) => setFilter('name',e.target.value)}
                        onKeyPress = { (e) => {
                            if(e.key === 'Enter'){
                                onFilter();
                            }
                        }}
                        value={name}
                    />
                </div>
                <div className='list-filters__item list-filters__item_to-right'>
                    <Button fill wd onClick={()=> onFilter()} disabled={loading}>
                        {t('Filter')}
                    </Button>
                </div>
            </div>
        )
    }
}

export default DocumentFilter;