import React, { Component } from 'react';
import trimCanvas from 'trim-canvas';
import withLocalization from '../../hoc/withLocalization';

import './signatureInitials.css';

@withLocalization
class SignatureInitial extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();   
    }

    componentDidMount() {    
        const {initials, font} = this.props;  
        this.prepareSignature(initials,font);
        this.prepareSignatureImage();
    }

    shouldComponentUpdate(nextProps, nextState) {           
        const prevProps = this.props;  
        if(nextProps.font != prevProps.font){            
            this.clearCanvas();
            const {initials,font} = nextProps;
            this.prepareSignature(initials,font);
            this.prepareSignatureImage();
        }
        return true;
    }

    clearCanvas = () => {
        let context = this.canvasRef.current.getContext('2d');
        context.fillStyle = "#fff";
        context.fillRect(0,0,1000,100);
    }

    prepareSignature = (initials,font) => {              
        const font_family = font ? font : 'cursive';

        let context = this.canvasRef.current.getContext("2d");
        
        context.font = `30px ${font_family}`;
        //console.log("Text width : ",context.measureText(initials).width)
        const canvas_width = context.measureText(initials).width + 100;
        
        this.canvasRef.current.setAttribute('width',`${canvas_width}`);
        context.font = `30px ${font_family}`;
        context.fillStyle = "black";
        context.fillText(initials, 50, 50);
        //console.log("Image : ", this.canvasRef.current.toDataURL());
    }
   
    prepareSignatureImage = () => {
        // let srcCanvas = trimCanvas(this.canvasRef.current);
        let srcCanvas = this.canvasRef.current;
        let _canvas = document.createElement('canvas');
        _canvas.setAttribute('width',`${srcCanvas.width}`);
        let context = _canvas.getContext('2d');
        context.fillStyle = "#fff";
        //console.log("srcCanvas.width :",srcCanvas.width);
        context.fillRect(0, 0, srcCanvas.width + 10, srcCanvas.height + 10);
        context.drawImage(srcCanvas, 2.5, 2.5);        
        this.handleImage(trimCanvas(_canvas).toDataURL('image/png',1));
    }

    handleImage = (image) =>{
        //console.log("Trimmed Image : ", image);
        this.props.onChange(image);        
    }
    
    render() {
        return (
            <div>
                <canvas ref={this.canvasRef} className={'signature-initial'} height={70}  />                
            </div>
        );
    }
}
export default SignatureInitial;
