import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { shortDateFormat } from '~/utils/dateFormat'

import '../../../../styles/css/styles.css';

// Components
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import GenericList from '~/components/GenericList/GenericList';

import customerFeature from '~/utils/customerFeature';
import { serverTimeFormat } from '~/library/core';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import { Modal } from 'react-bootstrap';
import ChecklistTemplateFilter from './ChecklistTemplateFilter';
import ChecklistTemplateAddEdit from './ChecklistTemplateAddEdit';

@inject('checklistStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ChecklistTemplateList extends Component {
    state = {
        checklistTemplateArray: [],
        showChecklistTemplateAddEdit: false,
        checklistdata: null,
        id:0
    };

    handleTableButtonAction = (id, type) => {
        const { history } = this.props;
        if (type == 'edit') {
            this.viewChecklistTemplatesEdit(id);
        }
        if (type == 'remove') {
            this.removeChecklist(id);
        }
    };

    removeChecklist(id) {
        return this.props.handleDelete(id, (id) => {
            return this.props.checklistStore.checklistTemplateDelete(id).then((res) => {
                this.props.checklistStore.resetLastListLoadTimeCheckTemplate();
                return res;
            });
        });
    }

    renderTableActionButtons = (id, full) => {
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');

        return (
            <div className="actions-center">
                <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                    <i className="fa fa-edit" />
                </Button>
                {(user_type == 'member' || (user_type == 'member' && full.assignee_id == userId) || allow) &&
                    !full.is_default_for_project && (
                        <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                            <i className="fa fa-trash" />
                        </Button>
                    )}
            </div>
        );
    };

    componentWillMount() {
        const params = new URLSearchParams(this.props.location.search);
        const filterByProject = params.get('project');
        this.props.checklistStore.setFilter('project', filterByProject || null);
        this.props.checklistStore.setFilter('name', '');
        this.props.checklistStore.onFilter();
    }

    handleClosesChecklistTemplateAddEditModal = () => {
        this.setState({ showChecklistTemplateAddEdit: false, id:0 });
        this.props.checklistStore.returnDefaultNewChecklistTemplate();
    };

    viewChecklistTemplatesAdd = () => {
        this.setState({
            add: true,
            showChecklistTemplateAddEdit: true
        });
    };

    viewChecklistTemplatesEdit = (id) => {
        this.setState({
            add: false,
            id: id,
            showChecklistTemplateAddEdit: true
        });
    };

    savechecklist = async () => {
        const { t, checklistStore, commonStore } = this.props;
        let checklistTemplateData = checklistStore.checklistTemplateData;
        if(checklistTemplateData == null || checklistTemplateData.name == undefined || checklistTemplateData.name == "" || checklistTemplateData.name == null ){
            commonStore.addNotification(t('Please Enter checklist name.'), null, 'error');
            return;
        }
        else if(checklistTemplateData.project_id == undefined || (checklistTemplateData.project_id == "")){
            commonStore.addNotification(t('Please Select Project.'), null, 'error');
            return;
        } 
        else if(checklistTemplateData.element_data == undefined || (checklistTemplateData.element_data && checklistTemplateData.element_data.length == 0 )){
            commonStore.addNotification(t('Please add Elements.'), null, 'error');
            return;
        } 

        await checklistStore.addChecklistTempalteNew().then((data) => {
                if(data){
                    this.props.commonStore.addNotification(t('Checklist template Saved'), null, 'success');
                    this.props.checklistStore.resetLastListLoadTimeCheckTemplate();
                    this.handleClosesChecklistTemplateAddEditModal();
                    // commonStore.addNotification(t('Checklist Template save successfully.'), null, 'success');
                }
        })
        .catch((err) => {
            commonStore.addNotification(err.message || t('Error'), null, 'error');
        });;
    };

    render() {
        const { t, isUnionMode } = this.props;
        const { loading, filters, appliedFilters } = this.props.checklistStore;
        const {config} = this.props.commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        return (
            <div className="table-list">
                <div className="table-list__header">
                    <Button fill wd icon onClick={() => this.viewChecklistTemplatesAdd()}>
                        {this.props.t('Add')} <i className="fa fa-plus" />
                    </Button>
                </div>
                <GenericList
                    columns={[
                        // {
                        //     Header: 'Id',
                        //     id: 'id',
                        //     sortable: true,
                        //     accessor: 'id',
                        // },
                        {
                            Header: 'Name',
                            id: 'name',
                            accessor: ({ id, name }) => (
                                <a
                                    className="list-main-column"
                                    onClick={() => this.handleTableButtonAction(id, 'edit')}
                                >
                                    {`${name}`}
                                </a>
                            ),
                        },
                        {
                            Header: 'Project',
                            id: 'project_name',
                            sortable: false,
                            accessor: 'project_name',
                        },
                        // {
                        //     Header: 'Client',
                        //     id: 'client_name',
                        //     sortable: false,
                        //     accessor: 'client_name',
                        // },
                        {
                            Header: 'Last Updated',
                            id: 'updated_at',
                            accessor: ({ updated_at }) => (
                                <span>{updated_at && shortDateFormat(updated_at, dateTimeRules)}</span>
                            ),
                        },
                        {
                            Header: 'Actions',
                            id: 'actions',
                            sortable: false,
                            accessor: (full) => this.renderTableActionButtons(full.id, full),
                        },
                    ]}
                    forceReload={this.props.checklistStore.deleteSuccess || this.props.checklistStore.addOrUpdatedTemplate}
                    filters={appliedFilters}
                    lastListLoadTime={this.props.checklistStore.lastListLoadTimeChckTemplate}
                    header={
                        <ChecklistTemplateFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.checklistStore.setFilter(name, value)}
                            onFilter={() => this.props.checklistStore.onFilter()}
                            loading={loading}
                        />
                    }
                    requestData={(params) => this.props.checklistStore.getChecklistTemplateByClientId(params)}
                />
                <Modal
                     className="modal right fade"
                    show={this.state.showChecklistTemplateAddEdit}
                    onHide={this.handleClosesChecklistTemplateAddEditModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Checklists')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 130px)', minHeight: '500px', overflowY: 'auto' }}>
                        <ChecklistTemplateAddEdit add={this.add} id={this.state.id}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClosesChecklistTemplateAddEditModal}>
                            {t('Close')}
                        </Button>
                        <Button variant="primary" onClick={this.savechecklist}>
                            {t('Save')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ChecklistTemplateList;
