import React from 'react';
import styled from 'styled-components';
import Stepper from 'react-stepper-horizontal';
import { inject, observer } from 'mobx-react';

import importStore from '../../library/stores/importStore';
import Button from '../CustomButton/CustomButton.jsx';

import UploadCSV from './UploadCSV';
import ColumnsChooser from './ColumnsChooser';

import './import.css';
import ConfirmLaunch from './ConfirmLaunch';

function getSteps() {
    return [{ title: 'Upload CSV' }, { title: 'Map fields' }, { title: 'Launch' }];
}

const Content = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
`;

@inject('importStore')
@observer
class HorizontalLinearStepper extends React.Component {
    handleNext = index => {
        const { importStore } = this.props;
        importStore.nextStep(index);
    };

    saveStatus = (name, value) => {
        const { importStore } = this.props;
        importStore.newImport[name] = value;
    };

    setMapping = (value, index) => {
        const { importStore } = this.props;
        importStore.setMapping(value, index);
    };

    render() {
        const { importStore, schema } = this.props;
        const steps = getSteps();
        steps.forEach((step, index) => {
            // step.onClick = this.handleNext(index);
        });
        const { step, error } = importStore;
        const { newImport } = importStore;
        return (
            <div>
                <Stepper steps={steps} activeStep={newImport.step} />
                <div>
                    {error && <div className="error-messages">{error}</div>}
                    {newImport.step === 0 && (
                        <UploadCSV campaign={importStore.newImport} saveStatus={(n, v) => this.saveStatus(n, v)} />
                    )}
                    {newImport.step === 1 && (
                        <ColumnsChooser
                            campaign={importStore.newImport}
                            schema={schema}
                            setMapping={(v, i) => this.setMapping(v, i)}
                            filteredRows={importStore.newImport.csvData.rows}
                        />
                    )}
                    {newImport.step === 2 && (
                        <ConfirmLaunch
                            campaign={importStore.newImport}
                            filteredRows={importStore.newImport.csvData.rows}
                            additionalOptions={this.props.additionalOptions}
                        />
                    )}
                    <div className="import-bottom-buttons">
                        {newImport.step < 2 && (
                            <Button onClick={() => this.handleNext(step + 1)} className="btn btn-primary">
                                Next
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const CampaignStepper = HorizontalLinearStepper;
export default CampaignStepper;
