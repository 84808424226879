import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GenericList from "../../../components/GenericList/GenericList";
import { inject, observer } from "mobx-react";
import withLocalization from "../../../hoc/withLocalization";
import clockIcon from "../../../assets/img/clockIcon.svg";
import editIcon from "../../../assets/img/editIcon.svg";
import downloadIcon from "../../../assets/img/downloadIcon.svg";
import RecipientsList from "../SignDocs/Recipients/recipientList";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../SignDocs/Widgets/CaseList.css";
import config from '~/library/config';
import moment from 'moment';

let nameTimeOut = null;
@inject('signatureStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@observer
class UserContractList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showrecipientList: false,
            caseId: null,
            currentStatus: '',
            nameFilter: '',
            recipients_status: undefined,
        }
    }

    navigateToHistory = (data) => {
        const { history } = this.props;
        history.push({
            pathname: '/admin/signdochistory',
            state: {
                caseId: data.id,
                caseName : data.name,
                caseDate : data.send_date,
                parent : 'UserContract'
            }
        });

    }

    handleDownload = (data, id) => {
        const { signatureStore, propertyStore, commonStore } = this.props;
        window.open(`${config.API_ROOT}/sign_documents/completed_signing_document/${encodeURIComponent(id)}`);
    }

    handleDoSignature = (data, id) => {
        const { signatureStore, propertyStore, commonStore, t } = this.props;
        signatureStore.generateSignatureLink(id).then((res) => {
            window.open(`${res.Link}`);
        }).catch((err) => {
            commonStore.addNotification(err.message, null, 'error');
        });
    }

    renderTableActionButtons = (id, data) => (
        <div className="actions-center-signdoc">
            <button onClick={() => this.navigateToHistory(data)} className="compose-btn"
                title={this.props.t('Check History')}
                style={{ maxWidth: '50px' }}
            >
                <img src={clockIcon}
                    className="img1"
                />
            </button>

            {
                data.status === "pending" && this.props.userStore.currentUser.id !== data.created_by && (

                    <button className="compose-btn" onClick={() => this.handleDoSignature(data, id)}
                        title={this.props.t('Edit')}

                    >
                        <img src={editIcon}
                            className="img1"
                        />
                    </button>
                )
            }

            {
                data.status === 'completed' && (

                    <button className="compose-btn" onClick={() => this.handleDownload(data, id)}
                        title={this.props.t('Download Signature')}>
                        <img src={downloadIcon}
                            className="img1"
                        />
                    </button>
                )
            }

        </div>
    );

    handlerecipientList = (recipients, id) => {

        if (recipients == 0) {
            return 0;
        }
        else {
            this.setState({ showrecipientList: !this.state.showrecipientList, caseId: id });
        }

    };

    onStatusChange = (value) => {
        const { signatureStore } = this.props;
        signatureStore.onFilter('status', value.value);
        this.setState({ currentStatus: value });
    }
    onNameChange = (e) => {
        const { signatureStore } = this.props;
        this.setState({ nameFilter: e.target.value })
        if (nameTimeOut) {
            clearTimeout(nameTimeOut);
        }
        nameTimeOut = setTimeout(() => {
            signatureStore.onFilter('name', this.state.nameFilter);
        }, 1000);
    }
    sendToSignature(data) {
        const { signatureStore, history } = this.props;

        if (data.status === "draft") {
            signatureStore.setCaseId(data.id);
            sessionStorage.setItem("CaseId", data.id);
            history.push('/admin/signupload');
        }
    }

    componentDidMount() {
        const { signatureStore } = this.props;
        
        
                    
    }
    getColorCode(status) {
        if (status === 'pending') {
            return '#ffb300'
        } else if (status === 'draft') {
            return '#c9c9c9'
        } else if (status === 'completed') {
            return '#33c85b'
        } else if (status === 'expired') {
            return '#ff0000'
        } else {
            return '#ffb300'
        }
    }

    render() {
        const { t,user_id } = this.props;
        const { commonStore} = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        return (
            <div className="caseListContainer" style={{marginTop:"20px"}}>
                <label>{t('Status Activity')}</label>
                {
                    
                    <GenericList
                        columns={[
                            {
                                Header: 'Document Name',
                                id: 'name',
                                sortable: true,
                                accessor: (data) => (<span onClick={() => { this.sendToSignature(data) }}>{data.name}</span>),
                            },
                            {
                                Header: 'Created By',
                                id: 'created_by_full_name',
                                sortable: false,
                                accessor: 'created_by_full_name',
                                // accessor: ({ id, name }) => (
                                //     <a
                                //         className="list-main-column"
                                //         onClick={() => this.handleTableButtonAction(id, 'edit')}
                                //     >
                                //         {`${name}`}
                                //     </a>
                                // ),
                            },
                            {
                                Header: 'Status',
                                id: 'status',
                                sortable: true,

                                accessor: 'status',
                                accessor: ({ recipients_status, recipients }) =>
                                    // <span
                                    //     style={{ backgroundColor: this.getColorCode(status), color: "white", padding: '5px 10px', width: '40px', height:"20px", borderRadius: "20px", textTransform: 'capitalize' }}
                                    // >{status}</span> 

                                    <div className={recipients_status == 0 && recipients == 0 ? "d-none" : "progress"} 
                                    style={{ height: "20px", color: "white", borderRadius: "10px", verticalAlign: "middle", lineHeight: "20px" }}>
                                        <div className={recipients_status == recipients && recipients_status != 0 ? "progress-bar"
                                            : recipients_status == 0 && recipients > 0 ? "pending"
                                            : recipients_status == (recipients >= 1 && recipients_status >= 1) || (recipients_status / recipients) ? "progress-bar-primary"
                                                : ""} role="progressbar"
                                            style={{
                                                height: "20px", width: recipients_status == recipients && recipients_status != 0 ? "100%"
                                                    : recipients_status == 0 && recipients > 0 ? "100%"
                                                    : recipients_status == 0 && recipients == 0 ? "0%"
                                                        : recipients_status == (recipients >= 1 && recipients_status >= 1) || (recipients_status / recipients) ? recipients_status / recipients * 100 + "%"
                                                            : ""
                                            }}
                                        >
                                            {recipients_status == recipients && recipients_status != 0 ? "Completed"
                                                : recipients_status == 0 && recipients > 0 ? "Pending"
                                                : recipients_status == (recipients >= 1 && recipients_status >= 1) || (recipients_status / recipients) ? recipients_status / recipients * 100 + "%" 
                                                : ""}
                                        </div>
                                    </div>
                            },
                            {
                                Header: 'Recipients',
                                id: 'recipients',
                                accessor: ({ recipients, id }) =>
                                    
                                    <span
                                        className="chat-badge" style={{ marginRight: '60px', cursor: "pointer" }}
                                        // onClick={() => this.showrecipientList()}
                                        
                                        onClick={() => { this.handlerecipientList(recipients,id) }}
                                        
                                    >{recipients}
                                    </span>
                                    ,
                                // accessor: ({ first_name, last_name }) => {`${first_name}` + `${last_name}`},
                                // accessor: ({ first_name, last_name, to_project }) => (
                                //     to_project == false ? <span>{`${first_name}` + ' ' + `${last_name}`}</span> : "To Project"
                                // ),
                                sortable: true,
                            },
                            {
                                Header: 'Sent Date',
                                id: 'send_date',
                                sortable: true,
                                accessor: ({ send_date }) => (
                                    <span>{send_date ? moment(send_date).format(dateTimeRules.short_date_format) : ''}</span>
                                    ),
                                // accessor: ({ created_by_first_name, created_by_last_name }) => (
                                //     <span>{`${created_by_first_name}` + ' ' + `${created_by_last_name}`}</span>
                                // ),
                            },
                            {
                                Header: 'Expiring On',
                                id: 'expiry_date',
                                sortable: true,
                                accessor:  ({ expiry_date }) => (
                                    <span>{expiry_date ? moment(expiry_date).format(dateTimeRules.short_date_format) : ''}</span>
                                    ),
                                // accessor: ({ updated_at }) => (
                                //     <span>{updated_at && moment(updated_at).format(serverTimeFormat())}</span>
                                // ),
                            },
                            {
                                Header: 'Action',
                                id: 'action',
                                sortable: false,
                                // accessor:'action'
                                accessor: (data) => this.renderTableActionButtons(data.id, data),
                            },
                            // {
                            //     Header: '',
                            //     id: 'actions',
                            //     sortable: false,
                            //     // accessor: (full) => this.renderTableActionButtons(full.id, full),
                            // },
                        ]}
                        lastListloadTime={this.props.signatureStore.lastListloadTime}
                        requestData={(params) => this.props.signatureStore.getSignDocument(params,true,user_id)}
                        // requestData={(params) => this.props.contractStore.get(params)}
                    />
                }
                <RecipientsList caseId={this.state.caseId} show={this.state.showrecipientList} handlerecipientList={this.handlerecipientList}></RecipientsList>
            </div>


        )
    }

}

export default UserContractList;
