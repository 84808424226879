import React, { Component } from 'react';
import moment from 'moment/min/moment-with-locales';

class Clock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeString: '',
        };
    }

    componentDidMount() {
        this.timerId = setInterval(this.startClock, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    startClock = () => {
        const time = moment().format('HH:mm:ss');
        this.setState({
            timeString: time,
        });
    };

    render() {
        return <span className="simple-clock">{this.state.timeString}</span>;
    }
}

export default Clock;
