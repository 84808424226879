import Chart from 'react-chartist';
import React, { Component } from 'react';

export default class ChartPie extends Component {
    render() {
        const { data, ...rest } = this.props;
        const series = data.map(piece => ({
            ...piece,
            label: piece.value != 0 ? piece.label : '',
            className: `pie-peace pie-peace_${piece.view}`,
        }));
        const seriesSum = series.reduce((sum, current) => {
            return +sum + parseFloat(current.value);
        }, 0);

        const labels = data.map(piece => piece.value != 0 ? `${Math.round((piece.value / seriesSum) * 100)}%` : ' '
        );
        return (
            <>
                <Chart type="Pie" data={{ series, labels }} {...rest} />
                <div className="chart-legend">
                    {data.map(legend => (
                        <span
                            className={`chart-legend__item chart-legend__item_${legend.view}`}
                            key={`${legend.value}_${legend.label}`}
                        >
                            {legend.label}
                        </span>
                    ))}
                </div>
            </>
        );
    }
}
