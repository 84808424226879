import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Icon from 'antd/lib/icon';

class ResourceView extends Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
        contentScrollbarHeight: PropTypes.number.isRequired,
        slotClickedFunc: PropTypes.func,
        slotItemTemplateResolver: PropTypes.func,
        toggleExpandFunc: PropTypes.func,
    };

    cropText = (str = '', size = 16) => {
        
        str = `${str}`;
        if (str.length > size) {
            return `${str.slice(0, size)}...`;
        }

        if(str === 'Pending')
            return this.props.t(str)

        return str;
    };

    textFirstLetr=(str)=>{
        var matches = str.match(/\b(\w)/g);
        var first =matches[0];
        var finalword=first;
        if(matches[1]){
            finalword=finalword+matches[1];
        }
        finalword=finalword.toUpperCase()  
        return finalword;
    }

    render() {
        const {
            schedulerData,
            contentScrollbarHeight,
            slotClickedFunc,
            slotItemTemplateResolver,
            toggleExpandFunc,
            allowResourceSelection,
            filteredRenderData,
            project_id
        } = this.props;
        const { renderData } = schedulerData;

        const width = schedulerData.getResourceTableWidth() - 2;
        const paddingBottom = contentScrollbarHeight;
        let displayRenderData = renderData.filter((o) => o.render);
        if(filteredRenderData.length && project_id!=''){
            displayRenderData = filteredRenderData;
        }
        const resourceList = displayRenderData.map((item,index) => {
           // console.log('item******',item);
            // let shiftDataCount = 0;
            // item.headerItems.map((data,index)=>{
                
            //     if(data.count > 0){
            //         shiftDataCount=shiftDataCount + data.count;
            //     }
                
            // })
            const indents = [];
            for (let i = 0; i < item.indent; i++) {
                indents.push(<span key={`es${i}`} className="expander-space" />);
            }
            let indent = <span key={`es${item.indent}`} className="expander-space" />;
            if (item.hasChildren) {
                indent = item.expanded ? (
                    <Icon
                        type="minus-square"
                        key={`es${item.indent}`}
                        style={{}}
                        className=""
                        onClick={() => {
                            if (toggleExpandFunc) toggleExpandFunc(schedulerData, item.slotId);
                        }}
                    />
                ) : (
                    <Icon
                        type="plus-square"
                        key={`es${item.indent}`}
                        style={{}}
                        className=""
                        onClick={() => {
                            if (toggleExpandFunc) toggleExpandFunc(schedulerData, item.slotId);
                        }}
                    />
                );
            }
            indents.push(indent);

            const a =
                slotClickedFunc != undefined ? (
                    <span className="slot-cell">
                        <a
                            className="slot-text"
                            title={item.slotName}
                            alt={item.slotName}
                            onClick={() => {
                                slotClickedFunc(schedulerData, item);
                            }}
                        >
                            {this.cropText(item.slotName)}
                        </a>
                    </span>
                ) : (
                    <span className="slot-cell" >
                        {/* {allowResourceSelection && item.slotId > 0 && this.props.copyingStarted && (
                            <input
                                type="checkbox"
                                onClick={() => this.props.onResourceSelectionChanged(item.slotId)}
                                checked={this.props.resourceSelection.indexOf(item.slotId) >= 0}
                            />
                        )} */}
                        {!allowResourceSelection && indents}
                        
                      
                        
                        <span className="" style={{display:'flex',alignItems:'center'}}>
                            <span>    
                                { item.slotName!=='Pending' ?<span className={index % 2 == 0 ? 'circle':'circle_new'}>{this.textFirstLetr(item.slotName)}</span>:'' }
                            </span>
                            <span>
                            {/* <span className={item.slotName!=='Pending' ?"slot-text":"slot-text-p"}>{this.cropText(item.slotName)} <span className="shiftDetail">1h 0m/ 1 shift</span>  </span> */}
                            {/* <span className={item.slotName === 'Pending'?'slot-text-pending':'slot-text'}>{this.cropText(item.slotName)} <span className={item.slotName === 'Pending'?'shiftDetail_pending':'shiftDetail'}>{shiftDataCount} {this.props.t('shifts')}</span>  </span> */}
                            <span className={item.slotName === 'Pending'?'slot-text-pending':'slot-text'}>{this.cropText(item.slotName)}</span>
                                
                            </span>
                        </span>
                    </span>
                );
            let slotItem = (
                <div title={item.slotName} className="overflow-text header2-text" style={{ textAlign: 'left' }}>
                    {a}
                </div>
            );
            if (slotItemTemplateResolver) {
                const temp = slotItemTemplateResolver(
                    schedulerData,
                    item,
                    slotClickedFunc,
                    width,
                    'overflow-text header2-text'
                );
                if (temp) slotItem = temp;
            }


            // strange bug :(
            let tdStyle = { height: item.slotId !== 0 ? item.rowHeight + 1 : item.rowHeight }; // === 34 ? 35: item.rowHeight
            //let tdStyle = { height: 20 }; // === 34 ? 35: item.rowHeight
            // console.log("IRH", item.rowHeight);
            if (item.groupOnly) {
                tdStyle = {
                    ...tdStyle,
                    backgroundColor: schedulerData.config.groupOnlySlotColor,
                };
            }

            let classname = `resource-row ${item.slotId === 0 ? 'pending-resource' : ''}`;
            // let setStyle = `${item.slotId === 0?backgroundColor}`;
            if (item.slotId === schedulerData.config.currentUserId) {
                classname += ' current-resource';
            }
            return (
                // <tr key={item.slotId} className={classname} style={{backgroundColor: `${item.slotId === 0? 'rgb(150,224,255,0.4)':'#fbfbfb'}` }}>
                <tr key={item.slotId} className={classname} style={{backgroundColor: `${item.slotId === 0? '#F2F1ED':'#fbfbfb'}` }}>
                    <td data-resource-id={item.slotId} style={tdStyle}>
                        {slotItem}
                    </td>
                </tr>
            );
        });

        return (
            <div style={{ paddingBottom }}>
                <table className="resource-table">
                    <tbody>{resourceList}</tbody>
                </table>
            </div>
        );
    }
}

export default ResourceView;
