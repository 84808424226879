import React, { Component } from 'react';

import CustomRadio from '../../CustomRadio/CustomRadio';
import withLocalization from '../../../hoc/withLocalization';

@withLocalization
class RadioWidgetInternal extends Component {
    render() {
        const {
            options, value, onChange, t, vertical
        } = this.props;
        const { enumOptions } = options;
        return (
            <div className={`radio-widget ${vertical ? 'vertical' : ''}`}>
                {!!enumOptions
                    && enumOptions.map(radio => (
                        <CustomRadio
                            key={radio.value}
                            onChange={() => onChange(radio.value)}
                            checked={value === radio.value}
                            option={radio.value}
                            label={t(radio.label)}
                        />
                    ))}
            </div>
        );
    }
}

export default class RadioWidget extends Component {
    render() {
        return <RadioWidgetInternal {...this.props} />;
    }
}
