import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import customerFeature from '../../../utils/customerFeature';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { serverTimeFormat } from '~/library/core';
// Components
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Modal } from 'react-bootstrap';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

// import ElementList1 from './ElementList1';

import ElementList from './Element/ElementList';

import ChecklistTemplateList from './CheckListTemplate/ChecklistTemplateList';
import ChecklistAssign from './ChecklistAssign';
import GenericList from '~/components/GenericList/GenericList';
import ChecklistTable from './ChecklistTable';


import '../../../styles/css/styles.css';
import ChecklistsFilter from './ChecklistsFilter';
import ChecklistCheck from './ChecklistCheck';

@inject('checklistStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class Checklist extends Component {
    state = {
        checklist_data: [],
        element_data: [],
        showAddElement: false,

        elementarray: [],
        showaddelement: false,
        showChecklistTemplate: false,
        checklistTemplateArray: [],
        showChecklistAssign: false,
        addChecklist: false,
        add: false,
        currentChecklistId: 0,
        showChecklistTodo: false,
        checklistChanged: false,
        currenttab: 1,
    };

    componentDidMount() {

    }

    renderAddElementModal = () => {
        const { t } = this.props;
        this.setState({
            showAddElement: true,
            showChecklistAssign: true,
        });
    };

    renderChecklistTodoModal = () => {
        const { t } = this.props;
        this.setState({
            showChecklistTodo: true,
        });
    };

    viewChecklistTemplates = () => {
        this.setState({
            addChecklist: true,
        });
        this.renderChecklistTemplateModal();
    };

    // onAddelements = () => {
    //     this.loadelements();
    //     const { history } = this.props;
    //     this.renderAddElementModal();
    // };

    loadelements = async () => {
        let data = {
            id: this.props.userStore.currentUser.client_id,
        };
        await this.props.checklistStore.listelementbyclientid().then((list) => {
            this.setState({ checklistTemplateArray: list.elements });
        });
    };

    loadChecklistTemplates = async () => {
        await this.props.checklistStore.getChecklistTemplateByClientId().then((list) => {
            this.setState({ elementarray: list.rows });
        });
    };
    handleClosesAddElementModal = () => {
        this.setState({ showAddElement: false });
        // this.loadelements();
    };
    onAddelements = () => {
        // this.loadelements();

        const { history } = this.props;
        this.renderAddElementModal();
    };

    handleClosesChecklisAssignModal = () => {
        this.setState({ showChecklistAssign: false, add: false });
        this.props.checklistStore.returnDefaultNew();
    };

    handleClosesChecklisTodoModal = () => {
        this.setState({ showChecklistTodo: false });
    };

    handleTableButtonAction = (id, type) => {
        // this.props.onTableAction(id, type);
        if (type === 'edit') {
            const { history } = this.props;
            this.setState({ currentChecklistId: id, add: false });
            this.renderChecklistAssignModal();
        } else if (type === 'todo') {
            const { history } = this.props;
            this.setState({ currentChecklistId: id, add: false });
            this.renderChecklistTodoModal();
        }
    };

    onTableAction = (id, type) => {
        const { history } = this.props;
        if (type === 'remove') {
            return this.props.handleDelete(id, (id) => {
                return this.props.absenceStore.remove(id).then((res) => {
                    this.props.absenceStore.resetLastListLoadTime();
                    return res;
                });
            });
        }
        history.push(`/admin/absences/${id}`);
    };

    renderTableActionButtons = (id, full) => {
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');

        return (
            <div className="actions-center">
                <Button bsStyle="warning" onClick={() => this.handleTableButtonAction(id, 'todo')} simple icon>
                    <i className="fa fa-thumbs-up" />
                </Button>
                <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                    <i className="fa fa-edit" />
                </Button>
                {/* {(user_type == 'member' || (user_type == 'member' && full.assignee_id == userId) || allow) &&
                    !full.is_default_for_project && (
                        <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                            <i className="fa fa-times" />
                        </Button>
                    )}
               */}
            </div>
        );
    };

    applyForm = (params = {}) => {
        const { loading, currentChecklist: checklist } = this.props.checklistStore;
        const { t, commonStore, checklistStore } = this.props;
        let hydrated = {
            user_id: checklist.user_id,
            project_id: checklist.project_id,
            checklist_templates_id: checklist.checklist_templates_id,
            //to_project: checklist.to_project,
            isSendEmail : checklist.isSendEmail,
        };
        if (checklist.id && checklist.id > 0) {
            hydrated['id'] = checklist.id;
        }
        //if (hydrated.checklist_templates_id && (hydrated.user_id || hydrated.to_project )) {
        if (hydrated.checklist_templates_id) {
            checklistStore
                .assignChecklist(hydrated)
                .then((res) => {
                    this.setState({ checklistChanged: true });
                    this.props.checklistStore.resetLastListLoadTime();
                    commonStore.addNotification(t('Checklist assign sucessfully'), null, 'success');
                    this.handleClosesChecklisAssignModal();
                })
                .catch((err) => {
                    commonStore.addNotification(err.message || t('Error'), null, 'error');
                });
        } else {
            commonStore.addNotification(t('Error'), null, 'error');
        }
    };

    Opentab = (tabid) => {
        this.setState({ currenttab: tabid });
    };

    getComponent = (tab) => {

        switch (tab) {
            case 1:
                return <ChecklistTable />;
            case 2:
                return <ChecklistTemplateList />;
            case 3:
                return <ElementList />;
        }
    };
    render() {
        const { config, t, isUnionMode } = this.props;
        const { loading, filters, appliedFilters } = this.props.checklistStore;
        return (
            <div className={`main-content`}>
                <div>
                    <button className={(this.state.currenttab == 1 ? "tablink-active" : "tablink")} onClick={() => this.Opentab(1)}>
                        {t('My Checklist')}
                    </button>
                    <button className={(this.state.currenttab == 2 ? "tablink-active" : "tablink")} onClick={() => this.Opentab(2)}>
                        {t('Create checklist')}
                    </button>
                    <button className={(this.state.currenttab == 3 ? "tablink-active" : "tablink")} onClick={() => this.Opentab(3)}>
                        {t('Elements')}
                    </button>
                </div>
                <div className="page"> {this.getComponent(this.state.currenttab)}</div>
            </div>
        );
    }
}
export default Checklist;
