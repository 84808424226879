import React, { Component } from 'react'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import CustomRadio from '../../../../components/CustomRadio/CustomRadio';
import _ from 'lodash';
import TableWidget from '../TableWidget.js';
import SwitchWidget from '../SwitchWidget';

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
export class TipRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tip_allocation: [
                {
                    label: "Allow tip only to selected members under members profile.",
                    value: "selected",

                },
                {
                    label: "Allow tip to all members and divide equally.",
                    value: "equally",
                },
                {
                    label: "Allow tips to all members according to given percent(%) under members profile",
                    value: "percentage",
                }
            ],
        }
    }
    handleUserDataChange = (name, value) => {
        // console.log(name,value);
        const { currentEntity } = this.props.clientStore;
        const tipRules = currentEntity.client.data.tipRules ? currentEntity.client.data.tipRules : {};
        currentEntity.client.data.tipRules = { ...tipRules, profiles: value }
    }

    
    onChange(field, value) {
        const { clientStore } = this.props
        let tipRules = clientStore.currentEntity.client.data && clientStore.currentEntity.client.data.tipRules ? clientStore.currentEntity.client.data.tipRules : {};
        clientStore.currentEntity.client.data.tipRules = { ...tipRules, [field]: value }
    }
    handleSwitch(name) {
        const { clientStore } = this.props
        let tipRules = clientStore.currentEntity.client.data && clientStore.currentEntity.client.data.tipRules ? clientStore.currentEntity.client.data.tipRules : {};
        clientStore.currentEntity.client.data.tipRules = { ...tipRules,tipEditor: name }
    }
    render() {
        const { t, clientStore } = this.props;
        const tipRules = clientStore.currentEntity.client && clientStore.currentEntity.client.data && clientStore.currentEntity.client.data.tipRules ? clientStore.currentEntity.client.data.tipRules : {};
        const profiles = tipRules.profiles ? tipRules.profiles : [];
        const _value = tipRules.tipAllocation ? tipRules.tipAllocation : 'selected';
        const editor_value = tipRules.tipEditor ? tipRules.tipEditor : 'pm';
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <div id="editor_radio">

                            <Row style={{ marginTop: '2rem' }}>
                                <Col sm={1}>
                                    <SwitchWidget
                                        value={editor_value === "pm" ? true : false} 
                                        onChange={checked => checked ? this.handleSwitch('pm', checked) : ''} />
                                </Col>
                                <Col sm={10}>
                                    <label className="control-label">{t('Allow only PM to enter Tip amount into the system')}</label>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {t("Allow only PM to enter Tip amount into the system")}
                                            </Tooltip>
                                        }
                                    >
                                        <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '2rem', marginBottom: '3rem' }}>
                                <Col sm={1}>
                                    <SwitchWidget
                                        value={editor_value === "member" ? true : false}
                                        onChange={checked => checked ? this.handleSwitch('member', checked) : ''} />
                                </Col>
                                <Col sm={10}>
                                    <label className="control-label">{t('Allow members to enter tip amount into the system')}</label>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {t("Allow members to enter tip amount into the system")}
                                            </Tooltip>
                                        }
                                    >
                                        <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </Col>
                            </Row>

                        </div>


                        <div id="allocation_radio">
                            <label className="control-label">{t('Tip allocation rule')}{":"}</label>
                            {
                                this.state.tip_allocation.map(({ label, value }, index) => {
                                    return (
                                        <CustomRadio
                                            name={"allocation"}
                                            key={label}
                                            onChange={() => this.onChange("tipAllocation", value)}
                                            checked={value === _value}
                                            option={label}
                                            label={t(label)}
                                        >
                                        </CustomRadio>
                                    )
                                })
                            }
                        </div>
                        {
                            <div className="mt-2 tip-custom-card">
                                <h6>{t('Profile Status')}</h6>
                                <TableWidget
                                    headers={['Employee Type', 'share(%)']}
                                    metaData={[{ field: 'employee_type', type: 'text', placeholder: 'type', required: true },
                                    { field: 'percentage', type: 'number', placeholder: '%', required: true }]}
                                    data={profiles}
                                    page={"tip"}
                                    onChange={data => this.handleUserDataChange('taskRules', data)}
                                    minRemoveIndex={2}
                                />
                            </div>
                        }

                    </Col>
                </Row>
            </div>
        )
    }
}

export default TipRules