import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';

import agent from '~/library/agent';
import DatePicker from '../../../components/DatePicker/DatePicker';
import config from '~/library/config';
import withLocalization from '~/hoc/withLocalization';
import { longDateFormat } from '../../../utils/dateFormat';
import MonitoringHistoryTable from './MonitoringHistoryTable';
import MonitorGridFilter from './MonitorGridFilter'
import TempPage from './TempPage';
// import customerFeature from '~/utils/customerFeature';

@inject('monitorStore', 'userStore', 'commonStore')
@withLocalization
@withRouter
@observer
class MonitortingHistory extends Component {

    state = {
        from: moment(new Date()).startOf('month').toDate(),
        to: moment(new Date()).toDate(),
        monitor : ''
    };

    async loadData() {
        const { t, monitorStore, userStore, history, id } = this.props;
        const monitorData = await monitorStore.loadGrid(id, this.appliedFilters);
        
        this.setState({
            monitor: [monitorData]
        })
    }

    componentDidMount() {
        this.loadData();
    }

    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    
    handleSearch = () => {
        const { from, to } = this.state;
    }

    printMonitorReport(id, user) {
        //generates monitor report//
        const { commonStore, monitorStore } = this.props;
     
        let hydrated = {
            authorization: commonStore.token,
            id: id,
            fromDate: monitorStore.filters.fromDate || '',
            toDate:  monitorStore.filters.toDate || ''
        }
        window.open(`${config.API_ROOT}/monitor/report/${id}${agent.convertToGetParams(hydrated)}`);
    }

    render() {
        const { t, monitorStore, userStore, id } = this.props;
        const { loading, filters, appliedFilters } = this.props.monitorStore;
        let date = '';
        let Mname = '';
        let projectName = "";
        // date = this.state.monitor.createdAt
        Mname = monitorStore.name
        projectName = monitorStore.project_id
        const { config } = this.props.commonStore
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        const { from, to } = this.state;
        return (
            <div className='monitoring-history-container'>
                <div className='history-title'>
                    <h5>{t('HISTORY')}</h5>
                    <div>
                        {/* <button className="btn" onClick={() => this.onCancel()}>{t('Preview')}</button> */}
                        <button className="btn btn-fill" onClick={() => this.printMonitorReport(id)} style={{ marginLeft: "10px" }}>{t('Export as PDF')}</button>
                    </div>
                </div>
                <div className='sub-history-container'>
                    <MonitorGridFilter
                        filters={filters}
                        setFilter={(name, value) => this.props.monitorStore.setFilter(name, value)}
                        onFilter={() => this.props.monitorStore.onFilter()} 
                        id={this.props.id}
                        />
                </div>
                <MonitoringHistoryTable styling='auto'/>
            </div>
        );
    }
}

export default MonitortingHistory;
