import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import CustomCheckBox from '../../../../components/CustomCheckbox/CustomCheckbox';
import { Viewer } from '@react-pdf-viewer/core';
//import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
//import config from '~/library/config';
import { Worker } from '@react-pdf-viewer/core';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

//styles
import '../../../../styles/css/styles.css';

//components
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import { Input } from '../../../../elements/Input';

import withLocalization from '~/hoc/withLocalization';
// import DocumentCheckbox from './DocumentCheckbox';
import DocumentMultipleSelector from './DocumentMultipleSelector';
import DocumentMembers from './DocumentMembers'
import Signature from '../../../../components/Signature/Signature';
import { MembersWidget, MembersWidgetMultiple, MembersWidgetMultipleSelect } from '../../../../components/GenericForm/Widgets/UsersWidget';
//this component is used as both add and edit page for documents//

@inject('documentStore', 'authStore', 'userStore', 'commonStore')
@withLocalization
@withRouter
@observer
class DocumentTemplateAddEdit extends Component {

    state = {
        isLoad: false,//holds page while document_book initial vlaues are loading
        template: null,//used for passing props to DocumentMultiselector
        //isEmployee: true,//helps to restrict view and actions
        isSaved: false,//true if document_book is saved
        isReadable: false,//true if document_book mail is send and employee need to read
        documentPath: null,//keeps document_book report path
        isSaveAndSend: false,// true if send mail check_box checked
        isPreview: false,//true if document_book pdf is loaded
        signature: null,
        isSignatureDone: false,//if document is read and signature is done
        isPreviewing: false,
        isSelectAll: false,//for selecting or de-selecting all the document-options
    };


    loadData = async () => {
        //calls action based on props to initiate currentDocumentTemplate values//
        const { documentStore, id, add } = this.props;

        if (id && id > 0 && !add) {
            documentStore.loadDocumentTemplateById(id).then((data) => {
                if (documentStore.currentDocumentTemplate.document_read) {
                    this.setState({
                        template: documentStore.currentDocumentTemplate,
                        signature: documentStore.currentDocumentTemplate.document_read.signature,
                        isReadable: documentStore.currentDocumentTemplate.document_read.status ? false : true,
                        isSaved: true,
                        isSignatureDone: documentStore.currentDocumentTemplate.document_read.signature ? true : false,
                    }, () => {
                        //loading last document_book pdf for preview
                        this.previewDocument();
                        this.setState({
                            isLoad: true
                        })
                    })
                }
                else {
                    this.setState({
                        template: documentStore.currentDocumentTemplate,
                        //isSent: true,
                        isSaved: true,
                    }, () => {
                        //loading last document_book pdf for preview
                        this.previewDocument();
                        this.setState({
                            isLoad: true
                        })
                    })
                }
                this.checkSelectAll();
            });        
        }
        else {
            documentStore.loadDefaultDocumentTemplate()
            this.setState({
                template: documentStore.currentDocumentTemplate,
            }, () => {
                this.setState({
                    isLoad: true,
                })
            })
        }
    }

    componentDidMount() {
        //calls loaddata and sets user type//
        // const { user_type } = this.props.userStore.currentUser;
        // if (user_type === "admin" || user_type === "super-admin" || user_type === "pm") {
        //     this.setState({ isEmployee: false })
        // }
        this.loadData();
    }

    handleTextChange = (name, value) => {
        //calls action to handle text changes//
        this.props.documentStore.handleDocumentTemplateChange(name, value);
        if (name === 'is_signature_required' || name === 'isobligatory') {
            this.checkSelectAll()
        }
    }

    handleButtonClick = (type) => {
        //handles action button clicks//
        if (type === 'save') {
            //saves document book//
            this.addDocumentTemplateData();
        }
        else if (type === 'send') {
            //sends document book created notification//
            //alert("Commented!")
            this.sendDocumentReadMailToMembers();
        }
        else if (type === 'read') {
            //sends document book read notification//
            this.updateDocumentReadStatus();
        }
        else if (type === 'preview') {
            //sends document book read notification//
            this.previewDocumentWithoutSave();
        }
        else {
            this.cancelButton()
        }
    }

    //Send document book created notification to all the members and pm//
    //who are yet to read the document//
    sendDocumentReadMailToMembers = async () => {
        const { documentStore, commonStore, t } = this.props;
        let data = {};
        data = {
            document_id: documentStore.currentDocumentTemplate.id,
        }

        await documentStore.sendDocumentReadMailToMembers(data).then(
            (response) => {
                if (response) {                    
                    commonStore.addNotification(t('Document Book Notification Mail Sent'), null, 'success');
                    return true;
                }
            })
            .catch(
                (error) => {
                    commonStore.addNotification(error.message || t('Error'), null, 'error');
                }
            )
    }

    //Update read status and sends mails to pm and admins//
    updateDocumentReadStatus = async () => {
        const { documentStore, commonStore, t, history } = this.props;
        let document_book_id = documentStore.currentDocumentTemplate.id;
        if (this.state.template.is_signature_required && (this.state.signature == undefined || this.state.signature == '')) {
            commonStore.addNotification(t('Please Do Signature'), null, 'error');
            return;
        }
        let data = {
            document_id: document_book_id,
            status: "read",
            signature: this.state.signature,
        }
        await documentStore.updateDocumentReadStatus(data).then(
            (response) => {
                commonStore.addNotification(t('Document Book Status Updated'), null, 'success');
                history.push(this.getListUrl());
                return true;
            })
            .catch(
                (error) => {
                    commonStore.addNotification(error.message || t('Error'), null, 'error');
                }
            )
    }

    //add new document read data ******added this call in back-end//
    addDocumentRead = async () => {
        const { documentStore, commonStore, t } = this.props;
        let data = {};

        data = {
            id: 0,
            document_id: documentStore.currentDocumentTemplate.id,
            isobligatory: documentStore.currentDocumentTemplate.isobligatory,
        }

        await documentStore.addDocumentRead(data).then(
            (response) => {
                if (response) {
                    commonStore.addNotification(t('Document Read Data Added'), null, 'success');
                    return true;
                }
            })
            .catch(
                (error) => {
                    commonStore.addNotification(error.message || t('Error'), null, 'error');
                }
            )
    }

    //previews document, works only after document_book is added//
    //shows the document in tab//
    previewDocument = async () => {
        const { commonStore, t, documentStore } = this.props;
        let id = documentStore.currentDocumentTemplate.id;
        commonStore.addNotification(t('Please Wait... Document Book Is Loading'), null, 'success');
        await documentStore.generateDocumentReportPreview(id).then(
            (data) => {
                this.setState({ isPreview: true, documentPath: data })
            }
        )
    }

    previewDocumentWithoutSave = async () => {
        const { commonStore, t, documentStore } = this.props;

        let documentTemplate = {
            template_id: documentStore.currentDocumentTemplate.id || 0,
            document_ids: documentStore.currentDocumentTemplate.template_documents,
            template_name: documentStore.currentDocumentTemplate.template_name.replaceAll(" ", "_-_")
        }

        if (documentTemplate.template_name === "" || documentTemplate.template_name == null || documentTemplate.template_name == undefined) {
            commonStore.addNotification(t('Please Enter Document Book Name'), null, 'error');
            return;
        }

        if (documentTemplate.document_ids === "" || documentTemplate.document_ids == null || documentTemplate.document_ids == undefined) {
            commonStore.addNotification(t("Please Select Documents"), null, 'error');
            return;
        }

        commonStore.addNotification(t('Please Wait... Document Book Is Loading'), null, 'success');
        this.setState({
            isPreviewing: true
        })
        await documentStore.generateMultipleDocumentsPdfPreviewUrl(documentTemplate).then(
            (data) => {
                this.setState({ isPreview: true, documentPath: data })
            }
        )
    }

    addDocumentTemplateData = async () => {
        //calls action to save document//        
        const { documentStore, commonStore, t, history, add } = this.props;
        let documentTemplate = {
            id: documentStore.currentDocumentTemplate.id,
            template_name: documentStore.currentDocumentTemplate.template_name,
            template_documents: documentStore.currentDocumentTemplate.template_documents,
            is_signature_required: documentStore.currentDocumentTemplate.is_signature_required,
            isobligatory: documentStore.currentDocumentTemplate.isobligatory,
            assigendMembers: documentStore.currentDocumentTemplate.assigendMembers,
        };

        if (documentTemplate.template_name === "" || documentTemplate.template_name == null || documentTemplate.template_name == undefined) {
            commonStore.addNotification(t('Please Enter Document Book Name'), null, 'error');
            return;
        }

        if (documentTemplate.template_documents === "" || documentTemplate.template_documents == null || documentTemplate.template_documents == undefined) {
            commonStore.addNotification(t("Please Select Documents"), null, 'error');
            return;
        }

        if (documentTemplate.assigendMembers === "" || documentTemplate.assigendMembers == null || documentTemplate.assigendMembers == undefined) {
            commonStore.addNotification(t("Please Select Members"), null, 'error');
            return;
        }

        await documentStore.saveDocumentTemplateData(documentTemplate).then((data) => {
            const { documentStore } = this.props;

            if (data) {
                documentStore.currentDocumentTemplate.id = data.id;
                commonStore.addNotification(t('Document Book Saved'), null, 'success');
                if (this.state.isSaveAndSend && add) {
                    this.sendDocumentReadMailToMembers();
                }
                this.setState({ isSaved: true, })
                history.push(this.getListUrl());
            }
        }).catch((error) => {
            commonStore.addNotification(error.message || t('Error'), null, 'error');
        })
    }

    getListUrl() {
        //returns template list URL//
        return '/admin/documents/templates';
    }

    cancelButton() {
        //redirects pages to list URL//
        const { history } = this.props;
        history.push(this.getListUrl());
    }

    checkSelectAll = () => {
        const { currentDocumentTemplate } = this.props.documentStore;
        const { isSaveAndSend } = this.state;
        const { add } = this.props;        
        if (currentDocumentTemplate.is_signature_required === false ||
            currentDocumentTemplate.isobligatory === false) {
            this.setState({ isSelectAll: false })
        }
        else if (add === true && isSaveAndSend === false) {
            this.setState({ isSelectAll: false })
        }
        else {
            this.setState({ isSelectAll: true })
        }
    }

    handleSelectAll = (value) => {
        this.setState({ isSelectAll: value, isSaveAndSend: value });
        this.props.documentStore.handleDocumentTemplateChange('is_signature_required', value)
        this.props.documentStore.handleDocumentTemplateChange('isobligatory', value)
    }
    
    render() {
        const { t, add } = this.props;
        const { user_type } = this.props.userStore.currentUser;
        const { currentDocumentTemplate } = this.props.documentStore;
        return (
            <div className="page" style={{ padding: 0, margin: 0 }}>
                <div className="page_content">
                    <form className="custom-form">
                        <div className="custom-form__row">
                            <Input
                                className="custom-form__col"
                                disabled={user_type === 'member'}
                                label={`${t('Document Book')} *`}
                                placeholder={t('Document Book Name')}
                                type="text"
                                name="template_name"
                                value={currentDocumentTemplate.template_name}
                                onChange={evt => {
                                    this.handleTextChange('template_name', evt.target.value);
                                }}
                            />
                        </div>

                        {(this.state.isLoad && user_type != 'member') &&
                            //    <DocumentCheckbox 
                            //    changeDocumentHandle={this.changeDocumentHandle}
                            //    documents = {this.state.template.template_documents}
                            //      />
                            <>
                                <div className="custom-form__row">
                                    <div className="custom-form__col">
                                        <DocumentMultipleSelector
                                            changeDocumentHandle={this.changeDocumentHandle}
                                            documents={this.state.template.template_documents}
                                        />
                                    </div>
                                    <div className="custom-form__col">
                                        <DocumentMembers
                                            onChange={selected=>this.handleTextChange('assigendMembers', selected)}
                                            value={currentDocumentTemplate.assigendMembers}                                            
                                        />
                                    </div>
                                </div>
                                <div className="custom-form__row">
                                    <div className="col-md-8">
                                        <div className="col-md-3">
                                            <CustomCheckBox
                                                label={`${t('Select All')}`}
                                                isChecked={this.state.isSelectAll}
                                                onChange={evt => this.handleSelectAll(evt)}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        {t("Employees Have To Sign The Document")}
                                                    </Tooltip>
                                                }
                                            >
                                                <CustomCheckBox
                                                    label={`${t('Sign Required')}`}
                                                    isChecked={currentDocumentTemplate.is_signature_required}
                                                    onChange={evt => {
                                                        this.handleTextChange('is_signature_required', evt);
                                                    }}
                                                />
                                            </OverlayTrigger>

                                        </div>

                                        <div className="col-md-3">
                                            <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        {t("Obligatory For Employees To Read Document")}
                                                    </Tooltip>
                                                }
                                            >
                                                <CustomCheckBox
                                                    label={`${t('Obligatory')}`}
                                                    isChecked={currentDocumentTemplate.isobligatory}
                                                    onChange={evt => {
                                                        this.handleTextChange('isobligatory', evt);
                                                    }}
                                                />
                                            </OverlayTrigger>

                                        </div>

                                        <div className="col-md-3">
                                            {!this.state.isSaved &&
                                                <OverlayTrigger
                                                    key="top"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip-top">
                                                            {t("Send Email To Employee")}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <CustomCheckBox
                                                        label={`${t('Send Mail')}`}
                                                        isChecked={this.state.isSaveAndSend}
                                                        onChange={evt => {
                                                            this.setState({ isSaveAndSend: evt }, () => this.checkSelectAll())
                                                        }}
                                                    />
                                                </OverlayTrigger>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        {this.state.isSaved &&
                                            <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-top">
                                                        {t("Send Email To Employee")}
                                                    </Tooltip>
                                                }
                                            >
                                                <Button wd pullRight style={{ marginLeft: '5px' }} onClick={() => this.handleButtonClick('send')}>
                                                    {t('Send Mail')}
                                                </Button>
                                            </OverlayTrigger>
                                        }
                                        <Button fill wd pullRight onClick={() => this.handleButtonClick('preview')}>
                                            {t('Preview')}
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }

                        {this.state.isPreview ? (
                            <div>
                                {user_type != 'member' ? (
                                    <iframe style={{ width: "100%", height: "290mm" }} src={this.state.documentPath} />
                                ) : (
                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                                        <Viewer
                                            fileUrl={this.state.documentPath}
                                        // plugins={[defaultLayoutPluginInstance]} 
                                        />
                                    </Worker>)}
                            </div>
                        ) : (
                            <div className="center-block" style={{
                                minHeight: '300px',
                                paddingTop: '150px'
                            }}>
                                {!add || this.state.isPreviewing ? `${t('Fetching Document Book Please Wait')}` : `${t('Please Add Documents And Save Document Book To Preview')}`}
                            </div>
                        )}
                    </form>
                </div>
                <div className="table-list__footer">
                    {(this.state.isLoad && (user_type === 'member' || user_type === 'pm')) && (
                        <div>
                            <div className="custom-form__row">
                                {this.state.template &&
                                    <div className="custom-form__col">
                                        <h4>{t('Signature')} </h4>
                                        {!this.state.isSignatureDone ? (
                                            <Signature
                                                onChange={(signature) => {
                                                    this.setState({
                                                        signature: signature,
                                                    })
                                                }
                                                }
                                                required
                                                width="100%"
                                                height={250}
                                                error={this.state.template.is_signature_required && !this.state.signature}
                                            />
                                        ) : (
                                            <img src={this.state.signature}
                                                width="100%"
                                                height={250}
                                                style={{ border: '2px solid #F5F5F5' }}
                                                name="signature" alr="member_signature" />
                                        )}

                                    </div>
                                }
                            </div>
                            <Button fill wd pullRight
                                disabled={!this.state.isReadable}
                                onClick={() => this.handleButtonClick('read')}>
                                {t('Read')}
                            </Button>
                        </div>
                    )}

                    {(this.state.isLoad && user_type != 'member') &&
                        <Button fill wd pullRight onClick={() => this.handleButtonClick('save')}>
                            {t('Save')}
                        </Button>
                    }
                    <Button wd onClick={() => this.handleButtonClick('cancel')}>
                        {t('Cancel')}
                    </Button>
                </div>
            </div>
        )
    }
}

export default DocumentTemplateAddEdit;
