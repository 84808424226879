import React, { Component } from 'react';
import { totalTimeFormat } from '../../utils/dateFormat';
import {getMinutesFromTimeInput} from '../../utils/timeCalc'
export function OvertimeDisplay({ data ,dateTimeRulesv2}) {
    const overtimes = [];
    if (!data) {
        return null;
    }
    Object.keys(data).forEach(key => {
        overtimes.push(data[key]);
    });
    return (
        <div style={{ lineHeight: '10px', fontSize: '9px' }}>
            {overtimes.map((row,index) => (
                <div key={index}>
                    {/* {row.name}: {row.value} */}
                    {row.name}: {totalTimeFormat(getMinutesFromTimeInput(row.value), dateTimeRulesv2)}
                </div>
            ))}
        </div>
    );
}
