import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Col, Grid, Row } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { confirmAlert } from 'react-confirm-alert';

import * as times from '../../../utils/timeCalc';

import ShiftForm from './ShiftForm';

import withLocalization from '~/hoc/withLocalization';
import LoadingSpinner from '~/elements/LoadingSpinner';
import Button from '~/components/CustomButton/CustomButton.jsx';
import customerFeature from '~/utils/customerFeature';

@inject('dashboardStore', 'userStore', 'commonStore', 'scheduleStore')
@withLocalization
@withRouter
@observer
export default class ScheduleMember extends Component {
    state = {
        dates: null,
    };

    constructor(props) {
        super(props);
    }

    // componentWillMount() {
    //     // this.setState({dates:this.calcDates()});
    // }

    componentDidMount() {
        this.loadData();
    }

    calcDates() {
        const r = {
            start: moment(new Date()).startOf('week').add(1, 'day'),
            end: moment(new Date()).startOf('week').add(2, 'week'),
            days: [],
        };
        let day = moment(r.start);
        let dayStr = r.start.format('YYYY-MM-DD');
        const toStr = r.end.format('YYYY-MM-DD');
        let i = 0;
        do {
            r.days.push({ date: moment(new Date(day)) });
            dayStr = day.format('YYYY-MM-DD');
            day = day.add(1, 'days');
            i += 1;
        } while (toStr !== dayStr && i < 100);
        return r;
    }

    async loadData(params) {
        const {
            t, dashboardStore, userStore, scheduleStore
        } = this.props;
        const { sending, viewModel } = this.state;
        const dates = this.calcDates();
        const list = await scheduleStore.loadByUser({
            start: dates.start.format('YYYY-MM-DD'),
            end: dates.end.format('YYYY-MM-DD'),
        });
        dates.days.forEach(day => {
            day.rows = _.filter(list.combined, r => {
                return r.for_date === day.date.format('YYYY-MM-DD');
            });
        });
        this.setState({ dates });

        scheduleStore.setLoading(false);
    }

    requestMove(item) {}

    renderItem(item) {
        const { t } = this.props;
        if (item.source == 'vacation') {
            return <div className="vacation">{t('Vacation')}</div>;
        }
        if (item.source == 'absence') {
            return <div className="absence">{t('Absence')}</div>;
        }
        return (
            <div className="shift">
                <div className="timing">
                    {times.minutesToTimeInput(item.start_time, true)} - {times.minutesToTimeInput(item.end_time, true)}
                </div>
                <div className="title">
                    {item.project_name} : {item.task_name}
                </div>
            </div>
        );
    }

    renderItems(day) {
        const { scheduleStore } = this.props;
        const { userData } = scheduleStore;
        return (
            <Fragment>
                {day.rows.map(item => (
                    <div key={`${item.id}`} className={`schedule__item schedule__item--${item.source}`}>
                        {this.renderItem(item)}
                    </div>
                ))}
            </Fragment>
        );
    }

    renderInner() {
        const { scheduleStore } = this.props;
        const { dates } = this.state;

        return (
            <Fragment>
                {dates.days.map(day => (
                    <div
                        key={`${day.date}`}
                        className={`schedule__daywrapper ${day.rows.length > 0 ? 'filled' : ''} ${
                            day.date.day() == 0 ? 'sunday' : ''
                        }`}
                    >
                        <div className="schedule__daytitle">{day.date.format('ddd MMM D')}</div>
                        <div className="schedule__itemswrapper">{this.renderItems(day)}</div>
                    </div>
                ))}
            </Fragment>
        );
    }

    render() {
        const { scheduleStore } = this.props;
        const { userData, loading } = scheduleStore;
        const { dates } = this.state;

        return (
            <div>
                {(!userData || loading || !dates) && <LoadingSpinner />}
                {userData && !loading && dates && this.renderInner()}
            </div>
        );
    }
}
