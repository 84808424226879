import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import React from 'react';
import { timeFormat } from './dateFormat';
import * as time from './timeCalc';

export const scheduleStatuses = {
    PENDING_INVITED: 'pending-invited',
    PENDING_ACCEPTED: 'pending-accepted',
    PENDING_USER_REJECTED: 'pending-user-rejected',
    PENDING_USER_INVITED: 'pending-user-invited',
    PENDING_DRAFT: 'pending',
    ASSIGNED: 'assigned',
};

/* Output format:
* {
        id: 1,
        start: '2017-12-18 09:30:00',
        end: '2017-12-19 23:30:00',
        resourceId: 'r1',
        title: 'I am finished',
        bgColor: '#D9D9D9',
        showPopover: false
    }
* */
// TODO: 24h!
export const getTimeFromShift = (shift, isStart) => {
    return `${shift.for_date} ${time.minutesToTimeInput(isStart ? shift.start_time : shift.end_time, false)}:00`;
};

export const transformToSchedulerEvent = (shift, dateTimeRules) => {
    const event = {
        id: shift.id,
        source: shift.source,
        start: getTimeFromShift(shift, true),
        end: getTimeFromShift(shift, false),
        resourceId: shift.assignee_id || 0,
        title: `${timeFormat(shift.start_time, dateTimeRules)} - ${timeFormat(shift.end_time, dateTimeRules)}`,
        bgColor: '#D9D9D9',
        showPopover: true,
        projectName: shift.project_name,
        taskName: shift.task_name,
        addBonus: (shift && shift.data && shift.data.addBonus) || false,
        workLogStatus:shift.work_log_status,
        forDate:shift.for_date
    };
    return event;
};

export const transformToSchedulerEventList = (input, dateTimeRules) => {
    return {
        list: input.combined.map((r) => transformToSchedulerEvent(r, dateTimeRules)),
    };
};
export const transformToSchedulerAvailability = (availabilityData, dateTimeRules) => {
    const availability = {
        id: availabilityData.id,
        availability_type: availabilityData.availability_type,
        from: availabilityData.from >= 0 ? timeFormat(availabilityData.from, dateTimeRules) : '',
        to: availabilityData.to >= 0 ? timeFormat(availabilityData.to, dateTimeRules): '',
        resourceId: availabilityData.user_id || 0,
        title: `${timeFormat(availabilityData.from, dateTimeRules)} - ${timeFormat(availabilityData.to, dateTimeRules)}`,
        bgColor: '#000',
        showPopover: true,
        for_date:availabilityData.for_date
    };
    return availability;
};

export const transformToSchedulerAvailabilityList = (input, dateTimeRules) => {
    return {
        list: input.availability.map((r) => transformToSchedulerAvailability(r, dateTimeRules)),
    };
};

export const getStatusText = (entity) => {
    let statusText = 'Waiting for invites';
    if (!entity.status) entity.status = scheduleStatuses.PENDING_DRAFT;
    switch (entity.status) {
        case scheduleStatuses.PENDING_INVITED:
            statusText = 'Invitations sent';
            break;
        case scheduleStatuses.PENDING_ACCEPTED:
            statusText = 'Invitation accepted';
            break;
        case scheduleStatuses.PENDING_USER_REJECTED:
            statusText = 'Rejected by the user';
            break;
        case scheduleStatuses.PENDING_USER_INVITED:
            statusText = 'Invitation sent by the user';
            break;
        case scheduleStatuses.PENDING_DRAFT:
            statusText = 'Waiting for invites';
            break;
        case scheduleStatuses.ASSIGNED:
            statusText = 'Assigned';
            break;
        default:
            statusText = 'Waiting for invites';
    }
    return statusText;
};
