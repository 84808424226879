import { action, observable } from 'mobx';

import agent from '../agent';

class PresenceStore {
    @observable currentList = [];

    @observable requestParams = null;

    @observable filters = {
        date: new Date(),
    };

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action loadList(params) {
        return agent.Timelogs.presenceList(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }
}

export default new PresenceStore();
