import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

export class CompanyCard extends Component {
    render() {
        const {
            statsText, statsValue, bigIcon, statsIconText, withFooter = true, cutText
        } = this.props;
        return (
            <div className="card card-company">
                <div className="content">
                    <Row>
                        <Col xs={12}>
                            <div className="numbers">
                                <p>{statsText}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className="company-icon text-center icon-warning">{bigIcon}</div>
                        </Col>
                    </Row>
                    {withFooter && false && (
                        <div className="footer">
                            <hr />
                            <div className={`stats ${cutText && 'stats-cut'}`}>
                                &nbsp;
                                {/* this.props.statsIcon */} {statsIconText}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default CompanyCard;
