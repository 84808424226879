import { observable, action, reaction } from 'mobx';
import moment from 'moment/min/moment-with-locales';
import objectAssign from 'object-assign';
import agent from '../agent';

class signatureStore {
    @observable isLoading = false;

    @observable isDocUploading = false;

    @observable isDocFetching = false;

    @observable isFullScreen = false;

    @observable caseId = sessionStorage.getItem('CaseId') || 0;

    @observable documentList = null;

    @observable isDocFinished = null;

    @observable caseName = null;

    @observable documentPages = null;

    @observable accessCode = null;
     
    @observable dateFormat = null;

    @observable signatureLoading = false;

    
    @observable filters = {
        status: '',
    };

    @observable appliedFilters = {
        name: '',
        status: '',
    };


    @action setFilter(name, value) {
        const filter = Object.assign({}, this.filters);
        filter[name] = value;
        this.filters = filter;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
         this.requestParams.filters = this.appliedFilters;
    }

  
    @observable config = {};


    @observable signDocHistoryList = [];

    @observable lastListLoadTime = null;

    @observable signDocumentList = [];

    @observable recipientList = [];

    @action VerifyCode(access_code) {
        this.accessCode = access_code;
    }
    @action resetLastListLoadTime() {
        //resets the LoadTime to refresh list//
        this.lastListLoadTime = new Date();
    }

    @action getConfig() {
        return this.config;
    }

    @action getSignDocHistory(id) {
        //calls get method from signDocHistory API//
        //takes 1 arguments, type - object, params//
        //returns signdochistory list//
        //  this.isLoading = true;
        return agent.Signature.signDocHistory(id)
            .then(
                action((list) => {                    
                    //    this.isLoading = false;
                    // this.requestParams = params;
                    list.time = new Date();
                    this.signDocHistoryList = list;
                    this.lastListLoadTime = list.time;
                    return this.signDocHistoryList;
                })
            )
            .catch(
                action((error) => {
                    //   this.isLoading = false;
                    throw error;
                })
            );
    }

    @action resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    @action getSignDocument(params,contract_user,user_id) {
        params.contract_user = contract_user?contract_user:false;
        params.user_id = user_id?user_id:null;
        return agent.Signature.createDocument(params)
            .then(
                action((list) => {                    
                    this.requestParams = params;
                    list.time = new Date();
                    this.signDocumentList = list;
                    this.lastListLoadTime = list.time;
                    return this.signDocumentList;
                })
            )
            .catch(
                action((err) => {
                    // this.isDocUploading = false;
                    throw err;
                })
            );
    }

    @action uploadDoc(data) {
        this.isDocUploading = true;
        return agent.Signature.uploadDocs(data)
            .then(
                action((doc) => {
                    this.isDocUploading = false;
                    this.documentList = doc.sign_document.data;
                    this.caseName = doc.sign_document.name;
                    return doc;
                })
            )
            .catch(
                action((err) => {
                    this.isDocUploading = false;
                    throw err;
                })
            );
    }

    // @action onFilter(name, value) {
    //     let appliedFilters = Object.assign({}, this.appliedFilters);
    //     appliedFilters[name] = value;
    //     this.appliedFilters = appliedFilters;
    // }

    @action openFullScreenMode() {
        this.isFullScreen = true;
    }
    @action closeFullScreenMode() {
        this.isFullScreen = false;
    }
    @action removeDoc(data) {
        return agent.Signature.deleteDocs(data)
            .then(
                action((res) => {
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    @action deleteDocPage(id) {
        return agent.Signature.deleteDocPage(id)
            .then(
                action((res) => {
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }
    @action fetchAll(data, page = 'Upload') {
        if (page === 'Prepare Document') {
            this.isDocFetching = true;
        }
        return agent.Signature.fetchAllDocs(data)
            .then(
                action((res) => {
                    if (res.document_pages && res.document_pages != undefined) {
                        this.documentPages = res.document_pages;
                    }
                    this.documentList = res.sign_document.data;
                    this.caseName = res.sign_document.name;
                    this.isDocFetching = false;
                    return res;
                })
            )
            .catch(
                action((err) => {
                    this.isDocFetching = false;
                    throw err;
                })
            );
    }

    @action setCaseId(id) {
        this.caseId = id;
    }
    @action createCase(data) {
        return agent.Signature.createCaseID(data)
            .then(
                action((doc) => {
                    this.caseId = doc.sign_document.id;
                    sessionStorage.setItem('CaseId', this.caseId);
                    return doc;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    @action updateDocName(docInfo) {
        return agent.Signature.updateDocName(docInfo)
            .then(
                action((res) => {
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }
    @action resetDocList() {
        this.documentList = null;
        this.documentPages = null;
        this.caseId = null;
    }
    @action resetDocPages() {
        this.documentPages = null;
    }
    @action sendDocNow(data) {
        return agent.Signature.sendDocNow(data)
            .then(
                action((res) => {
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    @action getRecipientDoc(id) {
        return agent.Signature.getRecipientDoc(id)
            .then(
                action((res) => {
                    if(res && res.client)
                        this.dateFormat = res.client.data.dateTimeRules;
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    @action getByDocCaseId(id) {
        return agent.Signature.getByDocCaseId(id)
            .then(
                action((res) => {
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    @action getByDocCaseId(id){
        this.isDocFinished = true;
        return agent.Signature.getByDocCaseId(id).then(
            action((res) => {
                this.isDocFinished = false;
                return res;
            })
        )
        .catch(
            action((err) => {
                this.isDocFinished = false;
                throw err;
            })
        )
    }
    @action verifyAccessCode(data) {
        return agent.Signature.verifyAccessCode(data)
            .then(
                action((res) => {
                    this.dateFormat = res.client.data.dateTimeRules
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    @action storeSignature(data) {
        this.signatureLoading = true;
        return agent.Signature.storeSignature(data)
            .then(
                action((res) => {
                    this.signatureLoading = false
                    return res;
                })
            )
            .catch(
                action((err) => {
                    this.signatureLoading = false
                    throw err;
                })
            );
    }
    @action generateSignatureLink(id){
        return agent.Signature.generateSignatureLink(id).then(
            action((res) => {
                return res;
            })
        )
        .catch(
            action((err) => {
               
                throw err;
            })
        )
    } 

    @action downloadCompletedDocument(id){
        return agent.Signature.downloadCompletedDocument(id).then(
            action((res) => {
                return res;
            })
        )
        .catch(
            action((err) => {
               
                throw err;
            })
        )
    } 
    

    @action downloadPdf(id) {
        return agent.Signature.downloadPdf(id)
            .then(
                action((res) => {                    
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    @action async removeCase(case_id){        
        await agent.Signature.deleteSignatureDoc(case_id)
        .then(
            action((res) => {
                return res;
            })
        )
        .catch(
            action((err) => {
                throw err;
            })
        );
    }
}

export default new signatureStore();
