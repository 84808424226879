import React, { Component } from 'react';
// import widgetList from './WidgetList';
import Select from '../../../../elements/Select';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../../hoc/withLocalization';
import signWidgetIcon from '../../../../assets/img/sign-widget-icon.svg';
import dateWidgetIcon from '../../../../assets/img/date-widget-icon.svg';
import recipientWidgetIcon from '../../../../assets/img/recipient-widget-icon.svg';
import initialWidgetIcon from '../../../../assets/img/initial-widget-icon.svg';
import labelWidgetIcon from '../../../../assets/img/label-widget-icon.svg';
import textboxWidgetIcon from '../../../../assets/img/textbox-widget-icon.svg';
import checkboxWidgetIcon from '../../../../assets/img/checkbox-widget-icon.svg';
import radiobuttonWidgetIcon from '../../../../assets/img/radiobutton-widget-icon.svg';
import dropdownWidgetIcon from '../../../../assets/img/dropdown-widget-icon.svg';

//en
import recipientWidget from '../../../../assets/img/recipient-widget.svg';
import initialWidget from '../../../../assets/img/Initials-widget.svg';
import labelWidget from '../../../../assets/img/label-widget.svg';
import textboxWidget from '../../../../assets/img/textbox-widget.svg';
import checkboxWidget from '../../../../assets/img/checkbox-widget.svg';
import radiobuttonWidget from '../../../../assets/img/radiobutton-widget.svg';
import dropdownWidget from '../../../../assets/img/dropdown-widget.svg';
import signWidget from '../../../../assets/img/signature-widget.svg';
import dateWidget from '../../../../assets/img/date-widget.svg';

//no
import signWidgetNor from '../../../../assets/img/no/signature-widget-no.svg';
import dateWidgetNor from '../../../../assets/img/no/date-widget-no.svg';
import recipientWidgetNor from '../../../../assets/img/no/recipient-widget-no.svg';
import initialWidgetNor from '../../../../assets/img/no/Initials-widget-no.svg';
import labelWidgetNor from '../../../../assets/img/no/label-widget-no.svg';
import textboxWidgetNor from '../../../../assets/img/no/textbox-widget-no.svg';
import dropdownWidgetNor from '../../../../assets/img/no/dropdown-widget-no.svg';

//es
import signWidgetEs from '../../../../assets/img/es/signature-widget-es.svg';
import dateWidgetEs from '../../../../assets/img/es/date-widget-es.svg';
import recipientWidgetEs from '../../../../assets/img/es/recipient-widget-es.svg';
import initialWidgetEs from '../../../../assets/img/es/Initials-widget-es.svg';
import labelWidgetEs from '../../../../assets/img/es/label-widget-es.svg';
import textboxWidgetEs from '../../../../assets/img/es/textbox-widget-es.svg';
import dropdownWidgetEs from '../../../../assets/img/es/dropdown-widget-es.svg';

//gr
import signWidgetGr from '../../../../assets/img/gr/signature-widget-gr.svg';
import dateWidgetGr from '../../../../assets/img/gr/date-widget-gr.svg';
import recipientWidgetGr from '../../../../assets/img/gr/recipient-widget-gr.svg';
import initialWidgetGr from '../../../../assets/img/gr/Initials-widget-gr.svg';
import labelWidgetGr from '../../../../assets/img/gr/label-widget-gr.svg';
import textboxWidgetGr from '../../../../assets/img/gr/textbox-widget-gr.svg';
import dropdownWidgetGr from '../../../../assets/img/gr/dropdown-widget-gr.svg';

//lt
import signWidgetLt from '../../../../assets/img/lt/signature-widget-lt.svg';
import dateWidgetLt from '../../../../assets/img/lt/date-widget-lt.svg';
import recipientWidgetLt from '../../../../assets/img/lt/recipient-widget-lt.svg';
import initialWidgetLt from '../../../../assets/img/lt/Initials-widget-lt.svg';
import labelWidgetLt from '../../../../assets/img/lt/label-widget-lt.svg';
import textboxWidgetLt from '../../../../assets/img/lt/textbox-widget-lt.svg';
import dropdownWidgetLt from '../../../../assets/img/lt/dropdown-widget-lt.svg';

//pl
import signWidgetPl from '../../../../assets/img/pl/signature-widget-pl.svg';
import dateWidgetPl from '../../../../assets/img/pl/date-widget-pl.svg';
import recipientWidgetPl from '../../../../assets/img/pl/recipient-widget-pl.svg';
import initialWidgetPl from '../../../../assets/img/pl/Initials-widget-pl.svg';
import labelWidgetPl from '../../../../assets/img/pl/label-widget-pl.svg';
import textboxWidgetPl from '../../../../assets/img/pl/textbox-widget-pl.svg';
import dropdownWidgetPl from '../../../../assets/img/pl/dropdown-widget-pl.svg';

//ru
import signWidgetRu from '../../../../assets/img/ru/signature-widget-ru.svg';
import dateWidgetRu from '../../../../assets/img/ru/date-widget-ru.svg';
import recipientWidgetRu from '../../../../assets/img/ru/recipient-widget-ru.svg';
import initialWidgetRu from '../../../../assets/img/ru/Initials-widget-ru.svg';
import labelWidgetRu from '../../../../assets/img/ru/label-widget-ru.svg';
import textboxWidgetRu from '../../../../assets/img/ru/textbox-widget-ru.svg';
import dropdownWidgetRu from '../../../../assets/img/ru/dropdown-widget-ru.svg';

//se
import signWidgetSe from '../../../../assets/img/se/signature-widget-se.svg';
import dateWidgetSe from '../../../../assets/img/se/date-widget-se.svg';
import recipientWidgetSe from '../../../../assets/img/se/recipient-widget-se.svg';
import initialWidgetSe from '../../../../assets/img/se/Initials-widget-se.svg';
import labelWidgetSe from '../../../../assets/img/se/label-widget-se.svg';
import textboxWidgetSe from '../../../../assets/img/se/textbox-widget-se.svg';
import dropdownWidgetSe from '../../../../assets/img/se/dropdown-widget-se.svg';

@inject('signatureStore', 'recipientStore', 'propertyStore')
@withLocalization
@observer
export class WidgetPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragImg: null,
      widgetList: [
        {
          widgetName: "Signature",
          label: "Signature",
          widgetIcon: signWidgetIcon,
          dropIcon: signWidgetNor,
        },
        {
            widgetName : "Document Date",
            label: "Date",
            widgetIcon : dateWidgetIcon,
            dropIcon : dateWidgetNor
        },
        {
            widgetName : "Recipient Name",
            label: "Recipient",
            widgetIcon : recipientWidgetIcon,
            dropIcon : recipientWidgetNor
        },
        {
            widgetName : "Initials",
            label: "Initial",
            widgetIcon : initialWidgetIcon,
            dropIcon : initialWidgetNor
        },
        {
            widgetName : "Label",
            label: "Label",
            widgetIcon : labelWidgetIcon,
            dropIcon : labelWidgetNor
        },
        {
            widgetName : "Textbox",
            label: "Text",
            widgetIcon : textboxWidgetIcon,
            dropIcon : textboxWidgetNor
        },
        {
            widgetName : "Checkbox List",
            label: "Checkbox",
            widgetIcon : checkboxWidgetIcon,
            dropIcon : checkboxWidget
        },
        {
            widgetName : "Radio Button List",
            label: "Radio",
            widgetIcon : radiobuttonWidgetIcon,
            dropIcon : radiobuttonWidget
        },
        {
            widgetName : "Select Box",
            label: "Select",
            widgetIcon : dropdownWidgetIcon,
            dropIcon : dropdownWidgetNor
        }
      ]
    }
  }

  onDragStart = (evt, label) => {

    evt.dataTransfer.setData('text/plain', label);
    const img = document.querySelector(`#${label}`);

    evt.dataTransfer.setDragImage(img, 0, 0);
    evt.dataTransfer.effectAllowed = "move";
  }

  static getDerivedStateFromProps(props, state) {
    const { widgetList } = state;
    const widgetListCopy = [...widgetList];
    let locale = props.commonStore.locale;
    let widgetData =[];
    
    switch(locale){
      case 'en' : widgetData = [signWidget,dateWidget,recipientWidget,initialWidget,labelWidget,textboxWidget,checkboxWidget,radiobuttonWidget,dropdownWidget];break;
      case 'no' : widgetData = [signWidgetNor,dateWidgetNor,recipientWidgetNor,initialWidgetNor,labelWidgetNor,textboxWidgetNor,checkboxWidget,radiobuttonWidget,dropdownWidgetNor];break;
      case 'es': widgetData = [signWidgetEs,dateWidgetEs,recipientWidgetEs,initialWidgetEs,labelWidgetEs,textboxWidgetEs,checkboxWidget,radiobuttonWidget,dropdownWidgetEs];break;  
      case 'gr': widgetData = [signWidgetGr,dateWidgetGr,recipientWidgetGr,initialWidgetGr,labelWidgetGr,textboxWidgetGr,checkboxWidget,radiobuttonWidget,dropdownWidgetGr];break; 
      case 'lt': widgetData = [signWidgetLt,dateWidgetLt,recipientWidgetLt,initialWidgetLt,labelWidgetLt,textboxWidgetLt,checkboxWidget,radiobuttonWidget,dropdownWidgetLt];break;
      case 'pl': widgetData = [signWidgetPl,dateWidgetPl,recipientWidgetPl,initialWidgetPl,labelWidgetPl,textboxWidgetPl,checkboxWidget,radiobuttonWidget,dropdownWidgetPl];break;
      case 'ru':  widgetData = [signWidgetRu,dateWidgetRu,recipientWidgetRu,initialWidgetRu,labelWidgetRu,textboxWidgetRu,checkboxWidget,radiobuttonWidget,dropdownWidgetRu];break; 
      case 'se': widgetData = [signWidgetSe,dateWidgetSe,recipientWidgetSe,initialWidgetSe,labelWidgetSe,textboxWidgetSe,checkboxWidget,radiobuttonWidget,dropdownWidgetSe];break;  
      case 'default' : widgetData = [signWidgetNor,dateWidgetNor,recipientWidgetNor,initialWidgetNor,labelWidgetNor,textboxWidgetNor,checkboxWidget,radiobuttonWidget,dropdownWidgetNor];break;
    }
    widgetListCopy.map((wid,i)=>{
      wid.dropIcon = widgetData[i];
    })
    return {
      widgetList: widgetListCopy
    }
  }

  prepareOptions = array => {
    const { emptyOption, emptyOptionLabel } = this.props;

    const result = array
      ? array.filter((rec)=> rec.signing_type !== "Send Copy").map(rec => {
          return { value: `${rec.id}`, label:(<div style={{display: "flex",alignItems: 'center'}}><div className="dot" style={{backgroundColor: rec.color_code}}></div>{rec.user_name}</div>)};
      })
      : [];

    return result;
  };
  hexToRgbA(hex) {
    hex = hex.length === 6 ? hex.concat('0') : hex;
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',.4)';
    }
    throw new Error('Bad Hex');
  }
  onChange = (rec) => {

    const { recipientStore, propertyStore } = this.props;

    if (rec.value) {
      const recipient = recipientStore.recipientsList.find((recipient) => recipient.id === parseInt(rec.value))
      propertyStore.setActiveRecipient(recipient);
    }

  }
  render() {
    const { recipientStore, propertyStore,t } = this.props;
    const activeRec = propertyStore.activeRecipient ? propertyStore.activeRecipient : '';
    const recipients = recipientStore.recipientsList ? recipientStore.recipientsList : [];
    const options = this.prepareOptions(recipients);
    const firstSignRec = recipients.filter((rec)=> rec.signing_type !== "Send Copy");
    !(propertyStore.activeRecipient) && propertyStore.setActiveRecipient(firstSignRec[0]);
    const currentSelect = propertyStore.activeRecipient ? { value: propertyStore.activeRecipient.id, label: (<div style={{display: "flex",alignItems: 'center'}}><div className="dot" style={{backgroundColor: propertyStore.activeRecipient.color_code}}></div>{propertyStore.activeRecipient.user_name}</div>)} : ''
    const widgetColor = this.hexToRgbA(activeRec.color_code ? activeRec.color_code : '#ffd65b');
    return (
      <div className="widget-panel">
        <div className="widget-content">
          <div className="userList">
            <Select
              value={currentSelect}
              options={options}
              onChange={this.onChange}
            >

            </Select>
          </div>
          <div className="widgetHead">{t('Standard Fields')}</div>
          <div className="widgetList">

            {
              this.state.widgetList.map((widget) => {
                return (
                  <div className="widget" key={widget.widgetName} draggable={true}  onDragStart={(evt) => { this.onDragStart(evt, widget.label, widget.dropIcon) }}>
                    <div className="widgetIcon">
                      <img src={widget.widgetIcon} alt="widget-icon" />
                    </div>
                    <div className="widgetTitle" > 
                      {t(widget.widgetName)}
                    </div>
                   
                    <img id={widget.label} className="widgetDropIcon" src={widget.dropIcon} style={{ backgroundColor:  widgetColor}}></img>
                  </div>
                );
              })

            }


          </div>
        </div>
      </div>
    )
  }
}

export default WidgetPanel