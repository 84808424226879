import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';

import config from '~/library/config';

// import LanguageDetector from 'i18next-browser-languagedetector';
// const defaultLocale = window.localStorage.getItem('locale') || 'no';
const defaultLocale = window.sessionStorage.getItem('locale') || 'no';

moment.locale(defaultLocale === 'no' ? 'nb' : 'en');

i18n.use(XHR)
    // .use(LanguageDetector)
    .init({
        backend: {
            loadPath: `${config.LOCALES_ROOT}/{{lng}}/{{ns}}.json`,
        },
        fallbackLng: defaultLocale,
        load: 'languageOnly',
        debug: false,
        keySeparator: '....',
        nsSeparator: '::::',
    });
export default i18n;
