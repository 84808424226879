const API_ROOT =
    window.location.hostname === 'localhost' && window.location.port ? 'http://localhost:3000/api' : '/api';

const SOCKET_ROOT = window.location.hostname === 'localhost' && window.location.port ? 'http://localhost:3000/' : '/';

const LOCALES_ROOT =
    window.location.hostname === 'localhost' && window.location.port ? 'http://localhost:3000/locales' : '/locales';

const UPLOADS_ROOT =
    window.location.hostname === 'localhost' && window.location.port ? 'http://localhost:3000/uploads' : '/uploads';

const UPLOADS_API_ENDPOINT =
    window.location.hostname === 'localhost' && window.location.port
        ? 'http://localhost:3000/api/attachments'
        : '/api/attachments';

const ADMIN_EMAIL = 'test@test.test';

const SERVER_IS_NORWAY = true;

export default {
    API_ROOT,
    UPLOADS_ROOT,
    UPLOADS_API_ENDPOINT,
    ADMIN_EMAIL,
    LOCALES_ROOT,
    SOCKET_ROOT,
    SERVER_IS_NORWAY,
};
