import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { FormControl } from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '~/hoc/withLocalization';
import LeftArrow from './../../../assets/img/left-arrow.svg'
import {v4 as uuid4} from 'uuid';

@inject('userStore', 'timelogStore', 'contractStore', 'commonStore', 'documentStore')
@withRouter
@withLocalization
@observer
class EmployeeContract extends Component {
    state = {
        listItems: [],
        selectedItem: {},
    };


    componentDidMount() {
        this.getData();
    }

    handleContractChange(evt, item) {
          
        this.setState({ selectedItem: item });
    }

    navigateToEdit(){
        const { history, location } = this.props;
        history.push({
            pathname: '/admin/docedit',
            state: {  selected: this.state.selectedItem, recId: (location.state.detail || location.state.recId)}
        })
    }

    getData = async (values) => {
        const { currentUser } = this.props.userStore;
        const { dashboardStore, history,location } = this.props;

        const {config} = this.props.commonStore;
        const countryName = config && config.client && config.client.data.dateTimeRules ? config.client.data.dateTimeRules.country : false;
        let list = [];
   
        await this.props.contractStore.getContracts({}).then((res) => {
            list = res.rows;
        });
       
        list = list.filter(item => item && item.country === countryName)
        this.setState({
            listItems: list,
            selectedItem: list.length===1 && list[0] || location.state.selected ||{} 
        });
    };


    goBack(){
        const {history} = this.props;
        history.push({
             pathname: '/admin/members'
    })
    }
    render() {
         const { t } = this.props;
        const { selectedItem } = this.state;
      
        return (
            <div className="main-content employeeContractMain" style={{ padding: '20px' }}>
            <p onClick={()=>{ this.goBack() }}><img src={LeftArrow} alt="left arrow"/> &nbsp;{t('Go Back to Member List')}</p>
            
            {this.state.listItems != 0 ?
            <div>
                <h5>{t('Please select contract template to use:')}</h5>
                <ul style={{ listStyle: 'none' }}>
                    {this.state.listItems && this.state.listItems.map((item) => {
                        return <li key={uuid4()}><input type="radio" onChange={(evt) => { this.handleContractChange(evt, item) }} checked={this.state.listItems.length === 1 || selectedItem.id === item.id  } style={{ margin: '10px', fontSize: '120px' }} />{item.name}</li>

                    })}
                </ul>
                <Button disabled={Object.keys(this.state.selectedItem).length ? false : true} fill wd icon style={{ marginLeft: "50px" }} onClick={()=>{ this.navigateToEdit() }}>{t('Next')}</Button>
        </div>
             : <h4 style={{color:"grey"}}>{t('No Contract Template Available. Please Try Again Later.')}</h4>}
            </div>
        )
    }
}
export default EmployeeContract;