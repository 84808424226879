import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { getTimeFormat, timeFormat } from '../../../utils/dateFormat';

function resolver({ params }) {
    const {
        schedulerData, eventItem, title, start, end, statusColor, t, com, dateTimeRules
    } = params;
    // const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
    let bigText = `${start.format(getTimeFormat(dateTimeRules, true))} - ${end.format(getTimeFormat(dateTimeRules, true))}${eventItem.addBonus ? ` ${t('with bonus')}` : ''}`;
    let titleText = `${eventItem.projectName} / ${eventItem.taskName}`;
    if (eventItem.source === 'vacation') {
        bigText = t('Vacation');
        titleText = '';
    }
    if (eventItem.source === 'absence') {
        bigText = t('Absence');
        titleText = '';
    }
    if (eventItem.availability_type === 'available') {
        titleText = t('Available');
        bigText = `${eventItem.from ? eventItem.from + ' -' + eventItem.to : ''} `;
    }
    if (eventItem.availability_type === 'not available') {
        titleText = t('Not Available');
        bigText = `${eventItem.from ? eventItem.from + ' -' + eventItem.to : ''} `;
    }
    if(eventItem.from == '12:00 am' && eventItem.to =='12:00 am'){
        bigText = ''
    }
    return (
        // <React.Fragment>
        //     <h3>{title}</h3>
        //     <h5>{start.format("HH:mm")} - {end.format("HH:mm")}</h5>
        //     <img src="./icons8-ticket-96.png" />
        // </React.Fragment>
        <div className="scheduler-popup">
            <div className="scheduler-popup__header">
                <div className="status-dot" style={{ backgroundColor: statusColor }} />
                <span title={titleText}>{titleText}</span>
            </div>
            <div className="scheduler-popup__content">{bigText}</div>
        </div>
    );
}

export default resolver;
