import React, { Component, Fragment } from 'react';
import { MenuItem, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import scrollToComponent from 'react-scroll-to-component';

import logo from '../../assets/img/logo_white.svg';
import withLocalization from '../../hoc/withLocalization';

@withLocalization
@inject('commonStore', 'userStore')
@observer
@withRouter
class PagesHeader extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
        this.state = {
            width: window.innerWidth,
        };
    }

    // function that sets the class to active of the active page
    activeRoute(routeName) {
        return window.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
    }

    // function that shows/hides sidebar on responsive
    mobileSidebarToggle(e) {
        document.documentElement.classList.toggle('nav-open');
    }

    updateWidth() {
        this.setState({ width: window.innerWidth });
    }

    componentDidMount() {
        this._isMounted = true;
        window.addEventListener('resize', this.updateWidth.bind(this));
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.updateWidth.bind(this));
    }

    handleLangChange = lang => {
        this.props.commonStore.setAppLang(lang);
        // Force Render
        this.setState({ state: this.state });
    };

    langName = lang => {
        if (lang === 'ru') return 'Русский';
        if (lang === 'en') return 'English';
        if (lang === 'lt') return 'Lithuanian';
        return 'Norsk';
    };

    scrollTo = section => {
        const { refsForScroll } = this.props;
        if (refsForScroll && refsForScroll[section]) {
            scrollToComponent(refsForScroll[section], {
                offset: -20, align: 'top', duration: 500, ease: 'inCirc'
            });
        }
    };

    handleLinks = ()=>{
        const {history} = this.props;
        history.push('/visitorClientRegister')
    }

    render() {
        const {
            currentUser, handleLogOut, t, match, refsForScroll
        } = this.props;
        const routePath = match.path;
        return (
            <Navbar
                collapseOnSelect
                inverse
                className="navbar-primary navbar-transparent navbar-absolute navbar-unauth lp-header"
            >
                <Navbar.Header>
                    <Navbar.Brand>
                        <NavLink to="/" className="nav-link">
                            <img className="logo-img" src={logo} alt="react-logo" />
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={this.mobileSidebarToggle} />
                </Navbar.Header>
                <Navbar.Collapse>
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <NavLink to="/" className="lp-text-btn">
                                {t('Home')}
                            </NavLink>
                        </li>
                        {refsForScroll && (
                            <Fragment>
                                <button className="lp-text-btn" onClick={() => this.scrollTo('features')}>
                                    {t('Features')}
                                </button>
                                <button className="lp-text-btn" onClick={() => this.scrollTo('about')}>
                                    {t('About Us')}
                                </button>
                                <button className="lp-text-btn" onClick={() => this.handleLinks()}>
                                    {t('Visitor Register')}
                                </button>
                                <button className="lp-text-btn" onClick={() => this.scrollTo('contacts')}>
                                    {t('Contact Us')}
                                </button>
                            </Fragment>
                        )}
                        <NavDropdown
                            title={(
                                <div className="lp-text-btn">
                                    <i className="fa fa-globe" />
                                    {this.langName(this.props.commonStore.locale)}
                                </div>
                            )}
                            noCaret
                            id="basic-nav-dropdown-1"
                            style={{
                                padding: 0,
                            }}
                        >
                            <MenuItem onClick={() => this.handleLangChange('en')}>English</MenuItem>
                            <MenuItem onClick={() => this.handleLangChange('no')}>Norsk</MenuItem>
                        </NavDropdown>
                        {currentUser && (
                            <li>
                                <NavLink to="/admin/" className="nav-link">
                                    <i className="fa fa-th-list" />
                                    <p>{t('Dashboard')}</p>
                                </NavLink>
                            </li>
                        )}
                        {!currentUser && routePath !== '/login' && (
                            <li className={this.activeRoute('login')}>
                                <NavLink to="/login/" className="nav-link lp-primary-button">
                                    {t('Login')}
                                </NavLink>
                            </li>
                        )}
                        {!currentUser && routePath !== '/register' && (
                            <li className={this.activeRoute('register')}>
                                <a
                                    href="#"
                                    className="nav-link lp-secondary-button"
                                    onClick={() => this.scrollTo('contacts')}
                                >
                                    <i className="fa fa-user-o" />
                                    {t('Sign Up')}
                                </a>
                            </li>
                        )}
                        {currentUser && (
                            <li className={this.activeRoute('register')}>
                                <a
                                    href="#"
                                    className="nav-link"
                                    onClick={e => {
                                        e.preventDefault();
                                        handleLogOut();
                                    }}
                                >
                                    <i className="fa fa-user-times" />
                                    <p>{t('Log out')}</p>
                                </a>
                            </li>
                        )}
                    </ul>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default PagesHeader;
