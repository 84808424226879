import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import '../../../styles/css/styles.css';

// Components
import Button from '../../../components/CustomButton/CustomButton.jsx';
import ChecklistTreeView from './ChecklistTreeView';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import { ChecklistWidget } from '../../../components/GenericForm/Widgets/ChecklistWidget';
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import customerFeature from '~/utils/customerFeature';
import { Modal } from 'react-bootstrap';
import ChecklistCheckAll from './ChecklistCheckAll';

@inject('checklistStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ChecklistCheck extends Component {
    state = {
        checklist: {},
        showErrors: false,
        checkAll: false,
        due_date: (new Date()),
        signature: ""
    }

    async loadData() {
        const { checklistStore, id } = this.props;
        await checklistStore.getChecklistById(id).then((data) => {
            this.setState({ checklist: data }, () => {
                // this.forceUpdate();
              
            });
        });
    }

    componentWillMount() {
        this.props.checklistStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    handleChange = (name, value) => {
        this.props.checklistStore.handleChange(name, value);
    };

    handleUserChange = (value, full) => {
        this.handleChange('user_id', value);
    };
    
    updateElement = async (id) => {
        this.props.reRenderForm(id);
    };

    updateState = (data) => {
        this.setState({ checklist: data });
    }

    handleClosesCheckAllModal = () => {
        this.setState({ checkAll: false, checkAll: false, isDeviation: false, elementData: {} });
    };

    handleSingatureChange = (sign) => {
        this.setState({ signature: sign });
    };
    handleSaveChecklistCheckAll = () => {
        const {t, checklistStore, commonStore  } = this.props;
        let hydrated = {
            id: this.props.id,
            due_date: this.state.due_date,
            signature: this.state.signature
        };

        checklistStore.checkAllChecklist(hydrated).then((res) => {
            this.props.checklistStore.resetLastListLoadTime();
            commonStore.addNotification(t('Saved'), null, 'success');
            this.handleClosesCheckAllModal();
            this.props.reRenderForm(this.props.id);
        })
            .catch((err) => {
                commonStore.addNotification(err.message || t('Please Fill all fields.'), null, 'error');
            });
        // this.setState({ elementData: element });
    };

    render() {
        const { config, t, isUnionMode, userStore: { currentUser }, userStore } = this.props;
        const { showErrors, checklist } = this.state;
        let reportedUsername = `${currentUser.first_name} ${currentUser.last_name}`;
        const user_type = currentUser.user_type;
        const user_id = currentUser.id;
        let allowEditing = (user_type === 'pm' || user_type === 'admin') ? true : false;
        return (
            <div className="page" style={{ padding: 0, margin: 0 }}>
                <div className="page__content">
                    <form className="custom-form">
                        <div className="custom-form__row">
                            <div className="custom-form__col">
                                <div className="read-area">
                                    <span className="read-area__label">{t('Project name')}</span>
                                    <div className="read-area__content">{checklist.project_name}</div>
                                </div>
                            </div>
                            <div className="custom-form__col">
                                <div className="read-area">
                                    <span className="read-area__label">{t('Checklist Name')}</span>
                                    <div className="read-area__content">{checklist.name}</div>
                                </div>
                            </div>
                        </div>
                        <div className="custom-form__row">
                            <div className="custom-form__col">
                                <div className="read-area">
                                    <span className="read-area__label">{t('Checklist')}</span>
                                </div>
                            </div>
                            {checklist.status != "Done" && checklist.allow_check_all && <div className="actions-center">
                                <Button className="btn-simple" bsStyle="warning" onClick={() => { this.setState({ checkAll: true }) }}>
                                    {t('Check All')} <i className="fa fa-thumbs-up" />
                                </Button>
                            </div>}
                        </div>
                        <div>
                           
                            {this.state.checklist.element_data && this.state.checklist.element_data.length > 0 ? (
                                    this.state.checklist.element_data.map((item, index) => (
                                        <div className="flex-box checklist-main" key={index}>
                                            <ChecklistTreeView
                                                data={item}
                                                headingcolor="#F6F6F6"
                                                key={item.id}
                                                marginLeft={0}
                                                marginTop={20}
                                                siginig_required={item.siginig_required}
                                                photo_required={item.photo_required}
                                                due_date_required={item.due_date_required}
                                                checklist_id={this.state.checklist.id}
                                                checklist_name={this.state.checklist.name}
                                                project_id={this.state.checklist.project_id}
                                                elementUpdate = {this.updateElement}
                                                checklist_status = {this.state.checklist.status}
                                            />
                                        </div>
                                    ))
                            ) : null}
                        </div>
                    </form>
                </div>
                <Modal size="lg" show={this.state.checkAll} onHide={this.handleClosesCheckAllModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Checklist')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                        <ChecklistCheckAll
                            due_date={this.state.due_date}
                            checklist_name= {checklist.name}
                            handleSingatureChange={this.handleSingatureChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleSaveChecklistCheckAll}>
                            {t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleClosesCheckAllModal}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}

export default ChecklistCheck;
