import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep, iteratee } from 'lodash';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';

import LoadingSpinner from '../../../elements/LoadingSpinner';
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Input, Textarea } from '../../../elements/Input';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import agent from '../../../library/agent';
import InputTime from '../../../components/GenericForm/Widgets/InputTime';
import GPSInfo from './GPSInfo';
import TimePickerInput from '../../../components/TimePicker';
import { timeFormat, totalTimeFormat } from '~/utils/dateFormat'

@inject('timelogStore', 'absenceStore','commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class ModalAccept extends Component {
    state = {
        notes: '',
        showTimeEditAndComment: false,
        clientInfo: null,
        fromInput: '',
        toInput: '',
        breakInput: '',
        approvalTimelogsIsManual: false,
        fromChangedBy: null,
        previousFrom: null,
        toChangedBy: null,
        previousTo: null,
        breakChangedBy: null,
        previousBreak: null,
    };

    convertUserTypeToText(userType) {
        switch(userType) {
            case 'member':
                return 'Member';
            case 'pm':
                return 'PM';
            case 'admin':
                return 'Admin';
            default:
                return userType ? `${userType}` : '';
        }
    }

    async componentDidMount() {
        const {
            absenceStore, timelogStore, commonStore, t, params,
        } = this.props;
       if( params.entity == 'absence'){
        const absence = await absenceStore.load(this.props.params.ids[0]);
        this.setState({notes : absence.absence.status_note})
       }
        const firstItem = (timelogStore && timelogStore.currentList && timelogStore.currentList.rows && timelogStore.currentList.rows.filter(row => row.id == params.ids[0])[0]) || {};
        if((params.count === 1 || params.count === '1') && firstItem.validateTimetrackOption && params.startedByWorklogButton) {
            const { client_id, data } = firstItem;
            const [ clientInfo, fromChangedBy, toChangedBy, breakChangedBy ] = await Promise.all([
                agent.Clients.load(client_id)
                    .then((response => {
                            if (!response.client.User) response.client.User = {};
                            if (!response.client.data) response.client.data = {};
                            if (!response.client.User.data) response.client.User.data = {};
                            return response;
                        })
                    ),
                data.fromChangedById ? agent.Users.load(data.fromChangedById)
                    .then((response => {
                            // if (!response.user.employee_percent) response.user.employee_percent = '100';
                            // const currentEntity = response;
                            // console.log(currentEntity);
                            // currentEntity.user.generic_pin = +this.currentEntity.user.generic_pin;
                            // currentEntity.user.timelog_start_from = this.currentEntity.user.timelog_start_from
                            //     ? moment(this.currentEntity.user.timelog_start_from).format('YYYY-MM-DD')
                            //     : null;
                            // currentEntity.user.disregard_billable_hours = this.currentEntity.user.disregard_billable_hours || false;
                            // return currentEntity
                            return response
                        })
                    ) : null,
                data.toChangedById ? agent.Users.load(data.toChangedById)
                    .then((response => {
                            // if (!response.user.employee_percent) response.user.employee_percent = '100';
                            // const currentEntity = response;
                            // console.log(currentEntity);
                            // currentEntity.user.generic_pin = +this.currentEntity.user.generic_pin;
                            // currentEntity.user.timelog_start_from = this.currentEntity.user.timelog_start_from
                            //     ? moment(this.currentEntity.user.timelog_start_from).format('YYYY-MM-DD')
                            //     : null;
                            // currentEntity.user.disregard_billable_hours = this.currentEntity.user.disregard_billable_hours || false;
                            // return currentEntity
                            return response
                        })
                    ) : null,
                data.breakChangedById ? agent.Users.load(data.breakChangedById)
                    .then((response => {
                            // if (!response.user.employee_percent) response.user.employee_percent = '100';
                            // const currentEntity = response;
                            // console.log(currentEntity);
                            // currentEntity.user.generic_pin = +this.currentEntity.user.generic_pin;
                            // currentEntity.user.timelog_start_from = this.currentEntity.user.timelog_start_from
                            //     ? moment(this.currentEntity.user.timelog_start_from).format('YYYY-MM-DD')
                            //     : null;
                            // currentEntity.user.disregard_billable_hours = this.currentEntity.user.disregard_billable_hours || false;
                            // return currentEntity
                            return response;
                        }
                    )) : null,
            ]);
            this.setState({ 
                clientInfo,
                fromChangedBy: (fromChangedBy && fromChangedBy.user) || null,
                toChangedBy: (toChangedBy && toChangedBy.user) || null,
                breakChangedBy: (breakChangedBy && breakChangedBy.user) || null,
                previousFrom: (data.previousFrom || data.previousFrom === 0) ? data.previousFrom : null,
                previousTo: (data.previousTo || data.previousTo === 0) ? data.previousTo : null,
                previousBreak: (data.previousBreak || data.previousBreak === 0) ? data.previousBreak : null,
                notes: firstItem.status_note || '',
                fromInput: minutesToTimeInput(firstItem.from),
                toInput: minutesToTimeInput(firstItem.to),
                breakInput: minutesToTimeInput(firstItem.break || 0),
                approvalTimelogsIsManual: !!(clientInfo && clientInfo.client && clientInfo.client.data && clientInfo.client.data.basicRules && clientInfo.client.data.basicRules.approvalTimelogs === 'manual')
            });
        }
    }

    async handleRun(action) {
        if (action == 'rejected' && !this.state.notes) {
            this.props.commonStore.addNotification(this.props.t('Comment is required'), null, 'error');
            return;
        }
        const {
            timelogStore, commonStore, t, params, afterSave
        } = this.props;
        for (let i = 0; i < params.ids.length; i++) {
            await timelogStore.setStatus(params.ids[i], params.entity, action, { notes: this.state.notes });
        }
        if ((action === 'rejected' || action === 'approved') && params.entity === 'timelog') {
            if (params.ids.length === 1) {
                await timelogStore.singlePushNotification(params.ids[0], this.state.notes, action);
            } else {
                await timelogStore.massPushNotification(params.ids, this.state.notes, action);
            }
        }
        afterSave();
    }

    async saveOne() {
        const {
            timelogStore, commonStore, userStore, t, params, afterSave
        } = this.props;
        const firstItem = timelogStore.currentList.rows.filter(row => row.id == params.ids[0])[0];
        const newData = JSON.parse(JSON.stringify(firstItem.data));
        const userId = userStore.currentUser.id;
        const { user_type, username, first_name, last_name } = userStore.currentUser;
        const previousFrom = firstItem.from;
        const previousTo = firstItem.to;
        const previousBreak = firstItem.break;
        firstItem.from = getMinutesFromTimeInput(this.state.fromInput);
        firstItem.to = getMinutesFromTimeInput(this.state.toInput);
        firstItem.break = getMinutesFromTimeInput(this.state.breakInput);
        firstItem.total_hours_normal = getMinutesFromTimeInput(this.state.toInput) - getMinutesFromTimeInput(this.state.fromInput) - getMinutesFromTimeInput(this.state.breakInput)
        newData.fromChangedById = userId;
        // newData.fromChangedByType = user_type;
        // newData.fromChangedByUsername = username;
        // newData.fromChangedByFirstName = first_name;
        // newData.fromChangedByLastName = last_name;
        newData.previousFrom = previousFrom;
        newData.toChangedById = userId;
        // newData.toChangedByType = user_type;
        // newData.toChangedByUsername = username;
        newData.previousTo = previousTo;
        newData.breakChangedById = userId;
        // newData.breakChangedByType = user_type;
        // newData.breakChangedByUsername = username;
        newData.previousBreak = previousBreak;
        firstItem.data = newData;
        await timelogStore.saveAndOverrideCurrentEntity(firstItem, false);
        afterSave();
    }

    async saveOneAndAccept() {
        const {
            timelogStore, commonStore, userStore, t, params, afterSave
        } = this.props;
        const firstItem = timelogStore.currentList.rows.filter(row => row.id == params.ids[0])[0];
        if (!(firstItem.description)) {
            this.props.commonStore.addNotification(this.props.t('The worklog does not have a comment, unable to save the changes. Please fill in the comment to proceed'), null, 'error');
            return;
        }
        const newData = JSON.parse(JSON.stringify(firstItem.data));
        const userId = userStore.currentUser.id;
        const { user_type, username } = userStore.currentUser;
        const previousFrom = firstItem.from;
        const previousTo = firstItem.to;
        const previousBreak = firstItem.break;
        firstItem.from = getMinutesFromTimeInput(this.state.fromInput);
        firstItem.to = getMinutesFromTimeInput(this.state.toInput);
        firstItem.break = getMinutesFromTimeInput(this.state.breakInput);
        firstItem.total_hours_normal = getMinutesFromTimeInput(this.state.toInput) - getMinutesFromTimeInput(this.state.fromInput) - getMinutesFromTimeInput(this.state.breakInput)
        newData.fromChangedById = userId;
        // newData.fromChangedByType = user_type;
        // newData.fromChangedByUsername = username;
        newData.previousFrom = previousFrom;
        newData.toChangedById = userId;
        // newData.toChangedByType = user_type;
        // newData.toChangedByUsername = username;
        newData.previousTo = previousTo;
        newData.breakChangedById = userId;
        // newData.breakChangedByType = user_type;
        // newData.breakChangedByUsername = username;
        newData.previousBreak = previousBreak;
        newData.status_notes = this.state.notes
        firstItem.data = newData;
        firstItem.status = 'approved';
        firstItem.status_note = this.state.notes;
        firstItem.status_changed_by = userId;
        firstItem.status_changed_on = new Date();
        firstItem.overrideClearStatusNote = true;
        firstItem.sendPushStatusNote = true;
        await timelogStore.saveAndOverrideCurrentEntity(firstItem, false);
        await timelogStore.singlePushNotification(params.ids[0], this.state.notes, 'changed and approved');
        afterSave();
    }

    
    render() {
        const {
            timelogStore, commonStore, t, params,
        } = this.props;
        const { 
            clientInfo, fromChangedBy, previousFrom, toChangedBy, previousTo, breakChangedBy, previousBreak,
        } = this.state;
        if (!params) return null;
        const firstItem = (timelogStore && timelogStore.currentList && timelogStore.currentList.rows && timelogStore.currentList.rows.filter && timelogStore.currentList.rows.filter(row => row.id == params.ids[0])[0]) || {};
        if((params.count === 1 || params.count === '1') && firstItem.validateTimetrackOption && params.startedByWorklogButton) {
            const tdStyleBorder = {border: '1px solid #B0B0B0'};
            const tdStyleAlign = {
                textAlign: 'center',
                verticalAlign: 'center'
            }
            const tdStyleTextSize = {
                height: '40px',
                width: '200px'
            }
            const tdStyleTextNumber = {
                height: '40px',
                width: '150px'
            }
            const tdStyleText = {
                ...tdStyleBorder,
                ...tdStyleAlign,
                ...tdStyleTextSize
            }
            const tdStyleNumber ={
                ...tdStyleBorder,
                ...tdStyleAlign,
                ...tdStyleTextNumber,
                textAlign: 'right',
                paddingRight: '25px',
            }
            const tdStyleTextInput = {
                ...tdStyleAlign,
                ...tdStyleTextSize
            }
            const tdStyleNumberInput = {
                ...tdStyleAlign,
                ...tdStyleTextNumber,
                paddingRight: '10px',
            }
            const tdMapElement = {
                ...tdStyleAlign,
            }
            const tdTotalInput = {
                ...tdStyleNumberInput,
                textAlign: 'right',
                paddingRight: '10px',
            }
            const dateTimeRules = commonStore.config.client && commonStore.config.client.data && 
                                  commonStore.config.client.data.dateTimeRules ? 
                                  commonStore.config.client.data.dateTimeRules : false; 
            const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';       
            if (clientInfo) {
                return (
                    <div className="">
                        <h3><b>{t('Registered working hours')}</b></h3>
                        <p><b>{t('Tracked time')}</b></p>
                        <table style={
                            {
                                margin: 'auto',
                                borderCollapse: 'collapse',
                                // border: '1px solid #B0B0B0',
                            }
                        }>
                            <tr>
                                <td style={tdStyleText}>{t('Start time')}</td>
                                <td style={tdStyleNumber}>{timeFormat(firstItem.from, dateTimeRules)} </td>
                                <td style={tdStyleText}>{t('End time')}</td>
                                <td style={tdStyleNumber}>{timeFormat(firstItem.to, dateTimeRules)}</td>
                                <td rowspan="2" style={tdMapElement}><GPSInfo status={firstItem.status} data={firstItem.gps_data} full={firstItem} mode='modal'/></td>
                            </tr>
                            <tr>
                                <td style={tdStyleText}>{t('Break time')}</td>
                                <td style={tdStyleNumber}>{minutesToTimeInput(firstItem.break || 0)}</td>
                                <td style={tdStyleText}><b>{t('Total')}</b></td>
                                <td style={tdStyleNumber}>{minutesToTimeInput(firstItem.to - firstItem.from - (firstItem.break || 0))}</td>
                            </tr>
                        </table>
                        <br></br>
                        <table style={{width: '100%'}}>
                            <tr>
                                <td>
                                    { fromChangedBy && (previousFrom || previousFrom === 0) && 
                                        <p style={{margin: 0}}>{t(this.convertUserTypeToText(fromChangedBy.user_type))} {fromChangedBy.first_name || ''} {fromChangedBy.last_name || ''} {t('changed start time from')} {timeFormat(previousFrom, dateTimeRules)} {t('to')} {timeFormat(firstItem.from, dateTimeRules)}</p>
                                    }
                                    { toChangedBy && (previousTo || previousTo === 0) && 
                                        <p style={{margin: 0}}>{t(this.convertUserTypeToText(toChangedBy.user_type))} {toChangedBy.first_name || ''} {toChangedBy.last_name || ''} {t('changed end time from')} {timeFormat(previousTo, dateTimeRules)} {t('to')} {timeFormat(firstItem.to || 0, dateTimeRules)}</p>
                                    }
                                    { breakChangedBy && (previousBreak || previousBreak === 0) && 
                                        <p style={{margin: 0}}>{t(this.convertUserTypeToText(breakChangedBy.user_type))} {breakChangedBy.first_name || ''} {breakChangedBy.last_name || ''} {t('changed break time from')} {minutesToTimeInput(previousBreak)} {t('to')} {minutesToTimeInput(firstItem.break)}</p>
                                    }
                                </td>
                                <td>
                                    <div style={{ textAlign: 'right' }}>
                                        <Button
                                            fill
                                            wd
                                            icon
                                            type="button"
                                            style={{ backgroundColor: 'blue', marginRight: 0, border: 0 }}
                                            onClick={() => this.setState((state) => ({ showTimeEditAndComment: !state.showTimeEditAndComment }))}
                                        >
                                            {t('Adjust')}
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        {this.state.showTimeEditAndComment && 
                            <div className="">
                                <br/>
                                <table style={
                                    {
                                        margin: 'auto',
                                        borderCollapse: 'collapse',
                                        // border: '1px solid #505050',
                                    }
                                }>
                                    <tr>
                                        <td style={tdStyleTextInput}>{t('Start time')}</td>
                                        <td style={{tdStyleNumberInput,float:'right'}} >
                                            {/* <InputTime
                                                value = {this.state.fromInput}
                                                onChange = {(e) => {
                                                    this.setState({
                                                        fromInput: e,
                                                    })
                                                }}
                                                className="big-input"
                                                // style={{marginLeft: '50px'}}
                                            /> */}
                                            <TimePickerInput        
                                                style={{width:150}}        
                                                value={this.state.fromInput}
                                                format={format=="hh:mm"?"HH:mm":format}
                                                onChange={value => this.setState({fromInput: value})}
                                                className="worklog-input"                                                
                                            /> 
                                        </td>
                                        <td style={tdStyleTextInput}>{t('End time')}</td>
                                        <td style={{tdStyleNumberInput,float:'right'}} >
                                            {/* <InputTime
                                                value = {this.state.toInput}
                                                onChange = {(e) => {
                                                    this.setState({
                                                        toInput: e,
                                                    })
                                                }}
                                                className="big-input"
                                                // style={{marginLeft: '50px'}}
                                            /> */}
                                            <TimePickerInput        
                                                style={{width:150}}        
                                                value={this.state.toInput}
                                                format={format=="hh:mm"?"HH:mm":format}
                                                onChange={value => this.setState({toInput: value})}
                                                className="worklog-input"
                                            /> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyleTextInput}>{t('Break time')}</td>
                                        <td style={tdStyleNumberInput}>
                                            <InputTime
                                                value = {this.state.breakInput}
                                                onChange = {(e) => {
                                                    this.setState({
                                                        breakInput: e,
                                                    })
                                                }}
                                                className="big-input"
                                                // style={{marginLeft: '50px'}}
                                            />
                                        </td>
                                        <td style={tdStyleTextInput}><b>{t('Total')}</b></td>
                                        <td style={tdTotalInput}>
                                            {minutesToTimeInput(getMinutesFromTimeInput(this.state.toInput) - getMinutesFromTimeInput(this.state.fromInput) - getMinutesFromTimeInput(this.state.breakInput))}
                                        </td>
                                    </tr>
                                </table>
                                <p 
                                    style = {{textAlign: 'right', color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}
                                    onClick = {() => {
                                        this.setState((state) => {
                                            const { clientInfo } = state;
                                            const fromInput = (clientInfo 
                                                && clientInfo.client
                                                && clientInfo.client.data
                                                && clientInfo.client.data.basicRules
                                                && clientInfo.client.data.basicRules
                                                && clientInfo.client.data.basicRules.startTimeRules
                                                && clientInfo.client.data.basicRules.startTimeRules.start) || state.fromInput;
                                            const toInput = (clientInfo 
                                                && clientInfo.client
                                                && clientInfo.client.data
                                                && clientInfo.client.data.basicRules
                                                && clientInfo.client.data.basicRules
                                                && clientInfo.client.data.basicRules.startTimeRules
                                                && clientInfo.client.data.basicRules.startTimeRules.end) || state.toInput;
                                            let breakMinutes;
                                            if (clientInfo 
                                                && clientInfo.client
                                                && clientInfo.client.data
                                                && clientInfo.client.data.basicRules
                                                && clientInfo.client.data.basicRules
                                                && clientInfo.client.data.basicRules.breakTimeRules
                                                && clientInfo.client.data.basicRules.breakTimeRules.minutes) {
                                                    breakMinutes = + clientInfo.client.data.basicRules.breakTimeRules.minutes
                                            }
                                            if (!breakMinutes && breakMinutes !== 0){
                                                breakMinutes = getMinutesFromTimeInput(state.breakInput)
                                            }
                                            const breakInput = minutesToTimeInput(breakMinutes)
                                            // const breakMinutes = (+(clientInfo 
                                            //     && clientInfo.client
                                            //     && clientInfo.client.data
                                            //     && clientInfo.client.data.basicRules
                                            //     && clientInfo.client.data.basicRules
                                            //     && clientInfo.client.data.basicRules.breakTimeRules
                                            //     && clientInfo.client.data.basicRules.breakTimeRules.minutes) || 0)
                                            //     || state.breakInput;
                                            return {
                                                fromInput,
                                                toInput,
                                                breakInput,
                                            }
                                        })
                                    }}
                                >
                                    {t('Standard company time')}
                                </p>
                            </div>
                        }
                        <Textarea
                            label={t('Comment')}
                            value={this.props.notes ? this.props.notes : this.state.notes}
                            onChange={e => this.setState({ notes: e.target.value })}
                            placeholder={t('Enter comments here')}
                            required
                        />
                        {this.state.approvalTimelogsIsManual ? <div style={{ textAlign: 'center' }}>
                            <Button
                                fill
                                wd
                                icon
                                type="submit"
                                style={{ backgroundColor: 'blue', marginRight: 30, border: 0 }}
                                onClick={() => this.saveOneAndAccept()}
                            >
                                {' '}
                                <i className="fa fa-check" /> {t('Save and accept')}
                            </Button>
                            <Button
                                fill
                                wd
                                icon
                                type="submit"
                                style={{ backgroundColor: 'green', marginRight: 30, border: 0 }}
                                onClick={() => this.handleRun('approved')}
                            >
                                {' '}
                                <i className="fa fa-check" /> {t('Accept original')}
                            </Button>
                            <Button
                                fill
                                wd
                                icon
                                type="submit"
                                style={{ backgroundColor: 'red', marginRight: 30, border: 0 }}
                                onClick={() => this.handleRun('rejected')}
                            >
                                {' '}
                                <i className="fa fa-times" /> {t('Reject')}
                            </Button>
                            <Button
                                fill
                                wd
                                icon
                                type="submit"
                                style={{ backgroundColor: 'darkorange', border: 0 }}
                                onClick={() => this.handleRun('reset')}
                            >
                                {' '}
                                <i className="pe-7s-refresh" /> {t('Reset approval')}
                            </Button>
                        </div> : <div style={{ textAlign: 'center' }}>
                            <Button
                                fill
                                wd
                                icon
                                type="submit"
                                style={{ backgroundColor: 'blue', marginRight: 30, border: 0 }}
                                onClick={() => this.saveOne()}
                            >
                                {' '}
                                <i className="fa fa-check" /> {t('Save')}
                            </Button>
                        </div>}
                    </div>
                )
            }
        }
        return (
            <div className="">
                <h3>
                    {t('With selected items')} <b>({params.count})</b>
                </h3>

                <Textarea
                    label={t('Comment')}
                    value={this.state.notes}
                    onChange={e => this.setState({ notes: e.target.value })}
                    placeholder={t('Enter comments here')}
                    required
                />

                <div style={{ textAlign: 'center' }}>
                    <Button
                        fill
                        wd
                        icon
                        type="submit"
                        style={{ backgroundColor: 'green', marginRight: 30, border: 0 }}
                        onClick={() => this.handleRun('approved')}
                    >
                        {' '}
                        <i className="fa fa-check" /> {t('Accept')}
                    </Button>
                    <Button
                        fill
                        wd
                        icon
                        type="submit"
                        style={{ backgroundColor: 'red', marginRight: 30, border: 0 }}
                        onClick={() => this.handleRun('rejected')}
                    >
                        {' '}
                        <i className="fa fa-times" /> {t('Reject')}
                    </Button>
                    <Button
                        fill
                        wd
                        icon
                        type="submit"
                        style={{ backgroundColor: 'darkorange', border: 0 }}
                        onClick={() => this.handleRun('reset')}
                    >
                        {' '}
                        <i className="pe-7s-refresh" /> {t('Reset approval')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default ModalAccept;
