import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';

import agent from '~/library/agent';
import DatePicker from '../../../components/DatePicker/DatePicker';
import config from '~/library/config';
import withLocalization from '~/hoc/withLocalization';
import MonitorGridFilter from './MonitorGridFilter';
import MonitoringHistoryTable from './MonitoringHistoryTable';
import queryString from 'query-string';

@inject('monitorStore', 'userStore', 'commonStore')
@withLocalization
@withRouter
@observer
class TempPage extends Component {
    state = {
        monitor: []
    }

    async loadData() {
        const { t, monitorStore, userStore, history } = this.props; 
        let params = queryString.parse(this.props.location.search)
        
        const monitorData = await monitorStore.loadGridpdf(params.id, params);
        this.setState({
            monitor: [monitorData]
        })
    }
    componentDidMount() {
        this.loadData();
    }

    render() {
        const { t, monitorStore, userStore, id, clientStore} = this.props;
        let Mname = '';
        let projectName = "";
        let client_name = "";
        let register_no = '';
        // date = this.state.monitor.createdAt
        Mname = monitorStore.name
        projectName = monitorStore.project_id
        if(monitorStore.tableListDataHistory[0] != undefined){
             client_name = monitorStore.tableListDataHistory[0].client_name
             register_no = monitorStore.tableListDataHistory[0].reg_no
        }
        return (
            <>
                <div style={{ display: 'flex', textAlign: 'center', height: 70, alignItems: 'center', borderBottom: '2px solid brown', background: 'aliceblue', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', marginLeft: 40 }}>
                        <p>Client Name:- </p>
                        <p style={{ marginLeft: 8, fontWeight: '500' }}>{client_name}</p>
                    </div>
                    <div style={{ display: 'flex', marginLeft: 40, marginRight: 40 }}>
                        <p>Register Number:- </p>
                        <p style={{ marginLeft: 8, fontWeight: '500' }}>{register_no}</p>
                    </div>
                </div>
                <div className='monitoring-history-container ' style={{ display: 'flex', justifyContent: 'center', marginTop: 10, flexDirection: 'column', transform: 'scale(0.63)', transformOrigin: 'left top' }}>
                    <MonitoringHistoryTable />
                </div>
            </>
        );
    }
}

export default TempPage