import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import customerFeature from '../../../utils/customerFeature';

import DeviationList from './DeviationList';
import DeviationForm from './DeviationForm';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

@inject('authStore', 'deviationStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class DeviationPage extends Component {
    editingId = () => {
        let id = null;
        if (this.props.location.pathname !== this.props.match.path) {
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }
        return { editing: !!id, add: id === 'add', id };
    };

    onAdd = () => {
        const { history } = this.props;
        history.push('/admin/deviation/add');
    };

    onDeleteListItem = id => {
        const { commonStore, t } = this.props;
        return this.props.handleDelete(id, id => {
            return this.props.deviationStore.remove(id).then(res => {
                commonStore.addNotification(t('Deviation') + ' ' + t('Deleted'), null, 'error');
                this.props.deviationStore.resetLastListLoadTime();
                return res;
            });
        });
    };

    onDelete = id => {
        const { history } = this.props;
        return this.props.handleDelete(id, id => {
            return this.props.deviationStore.remove(id).then(res => {
                history.push('/admin/deviation/');
                return res;
            });
        });
    };

    onDetails(id) {
        const { history } = this.props;
        history.push(`/admin/deviation/${id}`);
    }

    render() {
        const config = _.get(this.props.commonStore, 'config.deviationConfig', {});
        const mode = this.editingId();
        const isUnionMode = customerFeature('union_group');
        return (
            <div className={`main-content ${mode.editing && !mode.add ? 'main-content_fixed-height' : ''}`}>
                {!mode.editing && (
                    <DeviationList
                        config={config}
                        isUnionMode={isUnionMode}
                        onDetails={id => this.onDetails(id)}
                        onAdd={id => this.onAdd(id)}
                        onDelete={id => this.onDeleteListItem(id)}
                    />
                )}
                {mode.editing && (
                    <DeviationForm
                        isUnionMode={isUnionMode}
                        onDelete={this.onDelete}
                        config={config}
                        id={mode.id}
                        add={mode.add}
                    />
                )}
            </div>
        );
    }
}
export default DeviationPage;
