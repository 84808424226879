import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';

import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import getSchema from '~/library/schemas/task';
import customerFeature from '~/utils/customerFeature';

/* const uiSchema = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
      { 'ui:col': { md: 12, children: [
            { 'ui:group': 'Name', 'ui:row': [
                { 'ui:col': { md: 6, children: ['name'] } },
                { 'ui:col': { md: 6, children: ['status'] } },
              ] },
            { 'ui:row': [
                { 'ui:col': { md: 6, children: ['title'] } },
                { 'ui:col': { md: 6, children: [
                  { 'ui:row': [
                      { 'ui:col': { md: 6, children: ['name'] } },
                      { 'ui:col': { md: 6, children: ['status'] } },
                    ] }
                ] } },
              ] },
            { 'ui:row': [
                { 'ui:col': { md: 12, children: ['phone'] } },
              ] },
          ] } },
    ] },
} */

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:layout': [
        {
            'ui:subtitle': 'Task basic',
            project_id: { md: 4 },
            name: { md: 4 },
            task_number: { md: 4 },
            status: { md: 4 },
            task_type: { md: 4 },
            assignee_id: { md: 12 },
        },
        {
            'ui:subtitle': 'Customer info',
            contact_person: { md: 4 },
            // 'title':{md:4},
            email: { md: 4 },
            phone: { md: 4 },
        },
        {
            'ui:subtitle': 'Location',
            address: { md: 4 },
            post_place: { md: 4 },
            post_number: { md: 4 },
            g_nr: { md: 4 },
            b_nr: { md: 4 },
            gps_data: { md: 4 },
        },
        {
            'ui:subtitle': 'HMS Requirement',
            data: { md: 12 },
        },
        {
            'ui:subtitle': 'Costs and expenses',
            est_hours: { md: 6 },
            est_work: { md: 6 },
        },
        //    {
        //      'ui:subtitle': 'Schedule',
        //      start_time: { md: 3 },
        //      end_time: { md: 3 },
        //      scheduled_days: { md: 6 }
        //    },
        //    {
        //      'ui:subtitle': 'Automate',
        //      auto_log_on_days: { md: 12, 'ui:widget': 'DaysOfWeek' }
        //    },
        {
            'ui:subtitle': 'Task documents',
            description: { md: 12 },
            attachments: { md: 12 },
        },
    ],
    data: {
        'ui:field': 'layout',
        'ui:layout:hideframe': true,
        'ui:flex': true,
        'ui:layout': [
            {
                require_hms: { className: 'col-md-4' },
                security_analyze: { className: 'col-md-4' },
                start_date: { className: 'col-md-4' },
                finish_date: { className: 'col-md-4' },
            },
        ],
        require_hms: {
            'ui:widget': 'CheckBoxWidget',
        },
        security_analyze: {
            'ui:widget': 'CheckBoxWidget',
        },
    },
    est_work: {
        'ui:widget': 'Radio',
    },
    start_time: {
        'ui:widget': 'TimeSelect',
    },
    end_time: {
        'ui:widget': 'TimeSelect',
    },
    gps_data: {
        'ui:widget': 'GpsCoordinatesWidget',
    },
    attachments: {
        'ui:widget': 'ImageUpload',
        'ui:imageContext': {
            model: 'Task',
            fileType: 'docs',
            id: 0,
        },
    },
    status: {
        'ui:widget': 'TaskStatuses',
    },
    assignee_id: {
        'ui:widget': 'MembersWidgetMultipleSelect',
    },
    project_id: {
        'ui:widget': 'ProjectsWidget',
    },
    name: {
        'ui:placeholder': 'Task name',
    },
    task_number: {
        'ui:placeholder': 'Leave blank to auto-populate',
    },
    auto_log_on_days: {
        'ui:widget': 'DaysOfWeek',
    },
    scheduled_days: {
        'ui:widget': 'DaysOfWeek',
    },
    Shifts: {
        classNames: 'no-fieldset-title',
        'ui:widget': 'ShiftsWidget',
    },
};

/* const uiSchema = {
  'ui:field': 'layout',
  'ui:layout': [
    {
      name: { md: 12 },
      data: {md:12}
    },
  ],
  'data': {
    allow_vacations: {
      'ui:widget': 'TrueFalse'
    },
    allow_absents: { 'ui:widget': 'TrueFalse' },
    allow_task_users: { 'ui:widget': 'TrueFalse'},
    allow_accepting: { 'ui:widget': 'TrueFalse'},
  }
} */

@inject('taskStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class TasksForm extends Component {
    state = {
        schema: getSchema(),
        uiSchema: defaultUiSchema,
    };

    getListUrl() {
        return '/admin/tasks';
    }

    loadData() {
        const {
            taskStore, id, add, mode
        } = this.props;
        this.setState({
            schema: getSchema({
                config: this.props.commonStore.config,
            }),
        });
        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, id) });
        if (add) return taskStore.returnDefaultNew();
        taskStore.load(id, add);
    }

    async loadData() {
        const {
            taskStore, id, add, mode
        } = this.props;
        this.setState({
            schema: getSchema({
                config: this.props.commonStore.config,
            }),
        });

        if (add) {
            this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, null) });
            return taskStore.returnDefaultNew();
        }
        const task = await taskStore.load(id, add);

        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, task.task) });
    }

    prepareSchema(_schema, currentEntity) {
        const schema = cloneDeep(_schema);
        const { userStore } = this.props;
        const { currentUser } = userStore;

        schema.attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'Task',
                fileType: 'docs',
                id: currentEntity && currentEntity.id !== 'add' ? currentEntity.id : 0,
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
            },
        };
        if (currentUser.user_type === 'member') {
            schema.assignee_id.classNames = 'hidden';

            if (customerFeature('union_group')) {
                schema['ui:layout'].push({
                    'ui:subtitle': 'Shifts',
                    Shifts: { md: 12 },
                });
            }
        }

        return schema;
    }

    componentWillMount() {
        this.props.taskStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    async recalcGps(e) {
        return this.props.taskStore.recalcGpsForTask(e);
    }

    onSave(values) {
        const {
            taskStore, history, add, t, commonStore
        } = this.props;
        const clientData = commonStore.config && commonStore.config.client && commonStore.config.client.data;
        const { userStore } = this.props;
        const { currentUser } = userStore;

        if (currentUser.user_type === 'member' && add) {
            values.assignee_id = `${currentUser.id}`;
        }
        if (values.assignee_id == 'NaN' || values.assignee_id == ''){
            commonStore.addNotification(t('Please Assign Members'), null, 'error');
            return false;
        }
        values.members = values.assignee_id.split(',').map(id => {
            return { user_id: parseInt(id) };
        });
        values.assignee_id = `${values.members[0].user_id}`;
        const isGPSObligatory = clientData.basicRules.workinghourGPSObligatory;
        if(isGPSObligatory){
            if(values.gps_data  == null || values.gps_data == undefined || values.gps_data == ''){
                commonStore.addNotification(t('Please add address.'), null, 'error');
                return false;
            }
        }        

        return taskStore.save(values, add).then(result => {
            if (!result.task || !result.task.id) {
                // some error
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');
            history.push(this.getListUrl());
            return true;
        });
    }

    render() {
        const { taskStore, add } = this.props;
        const { loading, currentEntity } = taskStore;
        const { schema, uiSchema } = this.state;

        if (loading) {
            return <LoadingSpinner />;
        }

        return (
            <div className="primary-page">
                <GenericForm
                    entity={currentEntity.task}
                    uiSchema={uiSchema}
                    recomputeFields={['gps_data', 'data.addressCache']}
                    schema={schema}
                    forceRefresh={!!currentEntity.task.gps_data}
                    onChange={async data => this.recalcGps(data)}
                    translationScope="forms.task"
                    onSave={values => this.onSave(values)}
                    listUrl={this.getListUrl()}
                    isAdding={add}                    
                />
            </div>
        );
    }
}

export default TasksForm;
