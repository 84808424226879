import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { find } from 'lodash';

import Select from '../../../elements/Select';
import withLocalization from '../../../hoc/withLocalization';

@inject('userStore')
@withLocalization
@observer
class WrapperMobx extends Component {
    componentDidMount() {
        const { value, onChange, mode } = this.props;
        const options = this.getOptions(mode);
        // console.log('options', options);
        if (!value && options) {
            // onChange(options[0].value, options[0]);
        }
    }

    getOptions = mode => {
        if (mode === 'kids') {
            let options = this.props.userStore.currentUser.data.kids || [];
            options = JSON.parse(JSON.stringify(options));
            return options.map((option, index) => ({
                value: `${index}`,
                label: `${option.name}${option.chronic_disease === 'yes' ? ` [${this.props.t('chronic')}]` : ''}`,
                full: option,
            }));
        }
        return [];
    };

    render() {
        const { value, onChange, mode } = this.props;
        const options = this.getOptions(mode);
        if (!options.length) {
            return null;
        }
        const currentSelect = find(options, o => {
            return String(o.value) === String(value);
        });
        return (
            <div className="specail-select-widget">
                <Select
                    options={options}
                    value={currentSelect}
                    onChange={o => {
                        onChange(o.value);
                    }}
                    style={{ width: '100%' }}
                />
            </div>
        );
    }
}

@observer
export class KidsSelect extends Component {
    render() {
        return <WrapperMobx {...this.props} mode="kids" />;
    }
}
