import moment from "moment";
import React,{ Component } from "react"
import { withRouter } from 'react-router-dom';
import withLocalization from '~/hoc/withLocalization';
import { inject, observer } from 'mobx-react';
import { timeFormat } from '~/utils/dateFormat'
@inject('timelogStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@observer
class MemberAvailability extends Component{
    constructor(props){
        super(props);
    }
    render()
    {
        const {obj,commonStore,t} = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        return(
            <>
                <div style={{borderRadius: '5px',
                            padding: '1rem 1rem 1rem 1rem',
                            border: 'thin solid #cccccc',
                            marginTop : '15px'}}>
                    <div className='row'>
                        <table className='col-md-12'>
                            <tr>
                                <th className='col-md-3'>{t("Date")}</th>     
                                <th className='col-md-3'>{t("Member Name")}</th>
                                <th className='col-md-3'>{t("Availability")}</th>
                                <th className='col-md-3'>{t("Time")}</th>    
                            </tr>
                        </table>
                    </div>
                    <hr style={{borderTop : '1.5px solid #ccc' , marginTop : '5px', marginBottom : '5px'}}/>
                    {obj && obj.map((obj, index)=>
                        <div className='row' key={index}>
                        <table className='col-md-12'>
                            <tr>
                                {index==0 ?
                                <td className='col-md-3 text-center' style={{padding : '1.5px'}}>{moment(obj.for_date).format('YYYY-MM-DD')}</td>
                                :
                                <td className='col-md-3 text-center' style={{padding : '1.5px'}}></td>
                                }
                                <td className='col-md-3 text-center' style={{padding : '1.5px'}}>{obj.first_name+" "+obj.last_name}</td>
                                {obj.availability_type =='available' &&
                                    <td className='col-md-3 text-center' style={{padding : '1.5px', color : 'green'}}>{t("Available")}</td>
                                }
                                {obj.availability_type =='not available' &&
                                    <td className='col-md-3 text-center' style={{padding : '1.5px', color : 'red'}}>{t("Unavailable")}</td>
                                }
                                {( (obj.from==0 || obj.from == null) && (obj.to==0 || obj.to == null) ) ?
                                    <td className='col-md-3 text-center' style={{padding : '1.5px'}}>{t("Full day")}</td>
                                    :
                                    <td className='col-md-3 text-center' style={{padding : '1.5px'}}>{t("from")} {timeFormat(obj.from, dateTimeRules)} {t("to")} {timeFormat(obj.to, dateTimeRules)}</td>
                                }
                            </tr>
                        </table>
                    </div>
                    )}
                </div>  
            </>
        )
    }
}
export default MemberAvailability