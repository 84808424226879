import React, { Component } from 'react';

import withLocalization from '../../../hoc/withLocalization';
import Checkbox from '../../CustomCheckbox/CustomCheckbox.jsx';

const defaultDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

@withLocalization
class DaysOfWeekWidgetInternal extends Component {
    onCheck(index) {
        const { value, onChange, t } = this.props;
        let _v = value || '';
        _v = _v ? _v.split(',') : [];
        index = `${index}`;
        if (_v.indexOf(index) >= 0) {
            _v.splice(_v.indexOf(index), 1);
        } else {
            _v.push(index);
        }
        const res = _v.reduce((accum, cValue) => `${accum ? `${accum},` : ''}${cValue}`, '');
        onChange(res);
    }

    render() {
        const { value, t } = this.props;
        const isChecked = index => {
            return (value || '').indexOf(index) >= 0;
        };
        return (
            <div className="days-of-week">
                {defaultDays.map((day, index) => (
                    <Checkbox
                        wrapperClassName="days-of-week__item"
                        key={`${index}`}
                        isChecked={isChecked(index)}
                        onChange={() => this.onCheck(index)}
                        label={t(day)}
                    />
                ))}
            </div>
        );
    }
}

export default class DaysOfWeekWidget extends Component {
    render() {
        return <DaysOfWeekWidgetInternal {...this.props} />;
    }
}
