import React from 'react';
import PrimaryDatePicker, { registerLocale } from 'react-datepicker';
import { inject, observer } from 'mobx-react';
import enGb from 'date-fns/locale/en-GB'; // the locale you want
import nb from 'date-fns/locale/nb'; // the locale you want
import no from 'date-fns/locale/nb'; // the locale you want
import pl from 'date-fns/locale/pl'; // the locale you want
import lt from 'date-fns/locale/lt'; // the locale you want
import ru from 'date-fns/locale/ru'; // the locale you want
import es from 'date-fns/locale/es'; // the locale you want
import 'react-datepicker/dist/react-datepicker.css';
import { serverTimeFormat } from '../../library/core';

registerLocale('en', enGb); // register it with the name you want
registerLocale('nb', nb); // register it with the name you want
registerLocale('no', no); // register it with the name you want
registerLocale('pl', pl); // register it with the name you want
registerLocale('lt', lt); // register it with the name you want
registerLocale('ru', ru); // register it with the name you want
registerLocale('es', es); // register it with the name you want

const DatePicker = ({ onChange, selected, label, error, commonStore, ...rest }) => {
    if (label) {
        return (
            <label className="input-block">
                <span className="input-block__label" title={label}>
                    {label}
                </span>
                <PrimaryDatePicker
                    selected={selected}
                    onChange={onChange}
                    className={`input-block__date-picker ${error ? 'input-block__date-picker_error' : ''}`}
                    dateFormat={serverTimeFormat()}
                    locale={
                        commonStore && commonStore.locale
                            ? commonStore.locale
                            : 'en'
                    }
                    {...rest}
                />
            </label>
        );
    }
    return (
        <PrimaryDatePicker
            selected={selected}
            onChange={onChange}
            dateFormat={serverTimeFormat()}
            locale={
                // window.localStorage.getItem('locale') ? window.localStorage.getItem('locale').replace('no', 'nb') : 'en'
                window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale').replace('no', 'nb') : 'en'
            }
            {...rest}
        />
    );
};
export default inject('commonStore')(observer(DatePicker));
