import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import config from '~/library/config';
import agent from '~/library/agent';
import moment from 'moment/min/moment-with-locales';
import { Modal,Row,Col,Table } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import StatusBox from '../../../elements/StatusBox';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import AttachmentsWidget from '../../../components/GenericForm/Widgets/AttachmentsWidget';
import UsersListFilter from './UsersListFilter';
import ModalEditor from './ManagesMembersForm2';

import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import GenericList from '~/components/GenericList/GenericList';
import { Input, Textarea } from '../../../elements/Input'

// Elements

@inject('userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class UsersList extends Component {
    state = {
        nowEditingId: null,
        currenttab: null,
        currentUserId : 0,
        userAttachmentIds:"",
        showRenewModal:false,
        attachmentList:[],
        isModalOn: false,
        email: "",
        message: '',
        showOnlyInactiveUser: false
    };

    startEdit = id => {
        //console.log('ID', id);
        this.setState({ nowEditingId: id });
    };

    handleTableButtonAction = (id, type, attachments) => {
        if(type === 'upload'){       
            this.handleAttachmentUploads(id, attachments);
            return;
        } 
        this.props.onTableAction(id, type);
    };

    handleAttachmentModalCloseButton = () => {
        this.setState({ showAttachmentModal: false,
            currentUserId:0,
            userAttachmentIds:"",
            attachmentList:[]           
        });
    };

    saveUserAttachments = async () =>{
        const { t, commonStore,userStore} = this.props;
        const { currentUserId,userAttachmentIds} = this.state;                   
        let data = {
            id: currentUserId,
            attachment_id: userAttachmentIds,
        }    
        await userStore.addAttachments(data).then(
            (response) => {
                if (response) {                    
                    commonStore.addNotification(t('Saved'), null, 'success');
                    this.handleAttachmentModalCloseButton();    
                    this.props.userStore.resetLastListLoadTime();                
                    return;
                }
            })
            .catch(
                (error) => {
                    commonStore.addNotification(error.message || t('Error'), null, 'error');
            }
        )
    }

    handleAttachmentUploads = (id,attachments) =>{        
        this.loadAttachments(id,attachments); 
    }

    renderTableActionButtons = (id, user_type, attachments, status, register_type) => (
        <div className="actions-center">
            {user_type !== 'admin' && (
                <Button
                    onClick={() => this.handleTableButtonAction(id, 'loginas')}
                    bsStyle="info"
                    simple
                    title={this.props.t('Login as')}
                    icon
                >
                    <i className="fa fa-user" />
                </Button>
            )}
            {user_type === 'pm' && (
                <Button onClick={() => this.startEdit(id)} bsStyle="info" simple icon>
                    <i className="fa fa-address-book" />
                </Button>
            )}            
            <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                <i className="fa fa-edit" />
            </Button>
            {/* <Button onClick={() => this.handleTableButtonAction(id, 'upload', attachments)}  simple icon
                title={this.props.t('Upload Files')}>
                <i className="fa fa-upload" />
            </Button> */}
            {register_type != 'invited' &&
                <div>
                    {status == 'active' ?
                        <Button onClick={() => this.handleActiveInactive(id, 'inactive')} bsStyle="warning" simple icon
                            title={this.props.t('Inactivate')}>
                            <i class="fa fa-ban" aria-hidden="true"></i>
                        </Button> :
                        <Button onClick={() => this.handleActiveInactive(id, 'active')} bsStyle="warning" simple icon
                            title={this.props.t('Activate')}>
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </Button>
                    }

                </div>
            }
            {status == 'inactive' &&
                <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                    <i className="fa fa-times" />
                </Button>
            }
        </div>
    );

    handleActiveInactive = (id, value) => {
        const { t, commonStore, userStore } = this.props;
        let hydrated = {
            id: id,
            status: value
        };
        confirmAlert({
            title: this.props.t('Confirm to Change User status'),
            message: 'Are You Sure you want to modify the user status',
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        return userStore.setActiveInactive(hydrated).then((res) => {
                            this.props.userStore.resetLastListLoadTime();
                            commonStore.addNotification(t('Updated user status to ') + (`${hydrated.status}`), null, 'success');
                            return res;
                        });
                    },
                },
                {
                    label: this.props.t('No'),
                    onClick: () => { },
                },
            ],
        });
    }
    loadlistbystatus = (st) => {
        if (this.state.currenttab == st) {
            return;
        }
        else {
            this.setState({ currenttab: st })
            if (st != null) {
                if(st == 'all') {
                    this.setState({showOnlyInactiveUser: false})
                }
                if(st == 'inactive') {
                    this.setState({showOnlyInactiveUser: true})
                }
                this.props.userStore.setFilter("status", st)
                this.props.userStore.onFilter();
            }
            else {
                if(st == null) {
                    this.setState({showOnlyInactiveUser: false})
                }
                this.props.userStore.setFilter("status", '')
                this.props.userStore.onFilter();
            }
            return;
        }

    }

    getUserReport = () => {
        let hydrated = {
            authorization: this.props.commonStore.token
        }
        window.open(`${config.API_ROOT}/users/report${agent.convertToGetParams(hydrated)}`);
    }

    handleInvite =() =>{
        this.setState({isModalOn: true})
    }

    handleCloseModal =() =>{
        this.setState({isModalOn: false})
    }

    handleChange = (name, value) => {
        if(name == 'email'){
            this.setState({email: value})
        }
        if(name == 'message'){
            this.setState({message: value})
        }
        
    }

    onShare = () => {
        const { userStore, t, commonStore} = this.props; 
        if(this.state.email == ''){
            return commonStore.addNotification(t('Please Fill All the Fields'), null, 'error');
        }
        const state = Object.assign({}, this.state);
        state.email = this.state.email
        state.message = this.state.message
        userStore.saveInviteUser(state).then(result => {
            if (!result || !result.result.id) {
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return true;
            }
            commonStore.addNotification(t('Mail Sent Successfully'), null, 'success');
            
        });
        this.setState({isModalOn: false, email: ''})
    }
    copyLink = () => {
        const { t, commonStore, userStore } = this.props;
        if (userStore.currentList.inviteLink && userStore.currentList.inviteLink != "") {
            navigator.clipboard.writeText(userStore.currentList.inviteLink);
            commonStore.addNotification(t('Link has been copied!'), null, 'success');
        } else {
            commonStore.addNotification(t('There are some issue in link, please refresh and try again or contact admin.'), null, 'error');
        }
    }

    renderHeader = () => {
        const {
            mode, userStore, t, onAdd, commonStore
        } = this.props;
        const { config } = this.props.commonStore;
        const { loading, filters, currentUser } = userStore;        
        return (
            <div>
                <div className="table-list__header" style={{ flexWrap: 'nowrap' }}>
                    {currentUser.user_type !== 'super-admin' && <>
                        {userStore.currentList && userStore.currentList.total && config.client && (
                            <div className="table-list__summary">
                                {/* <div className="highlighted" style={{cursor:"pointer"}} onClick={()=>this.loadlistbystatus(null)}>
                                {t('Total users')}: {userStore.currentList.total}
                            </div> */}
                                <div className="highlightedgreen" style={{ cursor: "pointer" }} onClick={() => this.loadlistbystatus('active')}>
                                    {t('Active members')}: {userStore.currentList.activeuser}
                                </div>
                                <div className="highlightedred" style={{ cursor: "pointer" }} onClick={() => this.loadlistbystatus('inactive')}>
                                    {t('Inactive members')}: {userStore.currentList.inactiveuser}
                                </div>
                                <div>
                                    {t('Allowed users')}: {config.client.max_users_requested}
                                </div>
                            </div>
                        )}
                        <Button fill icon onClick={() => this.getUserReport()}>
                            {t('Download as PDF')} <i className="fa fa-file-download" />
                        </Button>
                        {this.state.showOnlyInactiveUser &&
                            <Button fill icon onClick={() => this.loadlistbystatus()}>
                                {t('All members')}
                            </Button>
                        }
                        {!this.state.showOnlyInactiveUser &&
                            <Button fill icon onClick={() => this.loadlistbystatus('inactive')}>
                                {t('Inactive members')}
                            </Button>
                        }
                        <Button fill icon onClick={() => this.handleInvite()}>
                            {t('Invite')} <i className="fa fa-link" />
                        </Button>
                    </>
                    }
                    <Button fill wd icon onClick={() => onAdd()}>
                        {t('Add new')} <i className="fa fa-plus" />
                    </Button>
                </div>
                <UsersListFilter
                    filters={filters}
                    setFilter={(name, value) => userStore.setFilter(name, value)}
                    onFilter={() => userStore.onFilter()}
                    loading={loading}
                />
            </div>
        );
    };

    handleAttachmentActions = (type, userId, attachmentId)=>{
        if(type === 'download'){
            this.downloadAttachment(attachmentId);
            return;
        }

        if(type === 'delete'){            
            this.setState({showAttachmentModal:false});
            this.removeAttachments(userId,attachmentId);
        }

    }

    removeAttachments = async (userId,attachmentId) => {
        const { commonStore, t } = this.props;        
        
        return this.props.handleDelete(attachmentId, attachmentId => {
            return this.props.userStore.removeUserAttachment(attachmentId).then((response)=>{
                if (response.attachments){
                    commonStore.addNotification(t('Deleted'), null, 'error');
                    this.props.userStore.resetLastListLoadTime();
                    this.loadAttachments(userId,response.attachments);
                }
                return response;
            }
            );
        });        
    }

    loadAttachments = async (id,attachments) =>{
        let attachment_ids = "";
        let attachmentList = [];
        if(attachments && attachments != ""){
            attachment_ids = attachments;
            await this.props.userStore.getMultipleAttachments({ attachmentIds:attachments}).then((response=>{
                attachmentList = response.attachments;
            }))
        }                            
        this.setState({ 
            userAttachmentIds: attachment_ids,
            showAttachmentModal: true,
            currentUserId: id,
            attachmentList: attachmentList            
            });
    }

    downloadAttachment = async (attachmentId) => {        
        let hydratedPayload= {            
            authorization: this.props.commonStore.token
        }
        window.open(`${config.API_ROOT}/user/getAttachment/${attachmentId}/${agent.convertToGetParams(hydratedPayload)}`);        
    }
    
    renderAttachmentTable = (attachmentList) => (
        <div style={{margin:'10px'}}>
            <Table>
                <thead>
                    <tr>
                        <th>{this.props.t('Date')}</th>
                        <th>{this.props.t('Time')}</th>
                        <th>{this.props.t('Name')}</th>
                        <th>{this.props.t('Actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {attachmentList.map(attachment=>(
                        <tr>
                            <td>{moment(attachment.createdAt).format("YYYY-MM-DD")}</td>
                            <td>{moment(attachment.createdAt).format("HH:mm")}</td>
                            {/* <td>{moment(attachment.createdAt).format("hh:mm A")}</td> */}
                            <td><a href={`${config.API_ROOT}/attachments/${attachment.id}`} target="_blank">{attachment.filename}</a></td>
                            <td>
                                {/* <Button onClick={() => this.handleTableButtonAction(id)} simple icon>
                                    <i className="fa fa-edit" />
                                </Button> */}
                                <Button onClick={() => this.handleAttachmentActions('download',attachment.to_id, attachment.id)} simple icon>
                                    <i className="fa fa-download" />
                                </Button>
                                <Button onClick={() => this.handleAttachmentActions('delete',attachment.to_id, attachment.id)}  bsStyle="danger" simple icon>
                                    <i className="fa fa-times" />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )        
                
    //handles generic list page change//
    handlePageChange = (page,isEdit) => {
        this.props.handlePageChange(page,isEdit);
    }

    render() {
        const { mode, t} = this.props;
        const { nowEditingId } = this.state;

        const { userStore } = this.props;
        const userType = userStore.currentUser.user_type;
        if (userType == 'member') {
            return <div>Not Allowed</div>;
        }

        const { loading, filters, appliedFilters } = userStore;

        return (
            <>
            <Fragment>
                <Modal size="lg" show={!!nowEditingId} onHide={() => this.setState({ nowEditingId: null })}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Assign Members to PM')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <ModalEditor id={nowEditingId} afterSave={() => this.setState({ nowEditingId: null })} />
                    </Modal.Body>
                </Modal>
                <GenericList
                    columns={[
                        {
                            Header: 'Name',
                            id: 'first_name',
                            accessor: ({ id, first_name, last_name }) => (
                                <a
                                    className="list-main-column"
                                    onClick={() => this.handleTableButtonAction(id, 'edit')}
                                >
                                    {`${first_name} ${last_name}`}
                                </a>
                            ),
                        },
                        {
                            Header: 'Phone',
                            id: 'phone',
                            accessor: 'phone',
                        },
                        {
                            Header: 'Email',
                            id: 'email',
                            accessor: 'email',
                        },
                        {
                            Header: 'Role',
                            id: 'user_type',
                            accessor: 'user_type',
                        },
                        {
                            Header: 'Status',
                            id: 'status',
                            accessor: ({ status, register_type }) => (register_type == 'invited' ? <StatusBox status={'pending' || ''} /> : <StatusBox status={status || ''} />)
                            // {register_type == 'invited' ? <StatusBox status={register_type || ''} /> : <StatusBox status={status || ''} />},
                        },
                        {
                            Header: 'Operation',
                            id: 'operation',
                            sortable: false,
                            accessor: ({ id, user_type, attachments, status, register_type }) => this.renderTableActionButtons(id, user_type, attachments, status, register_type ),
                        },
                    ]}
                    lastListLoadTime={this.props.userStore.lastListLoadTime}
                    forceReload={this.props.userStore.deleteSuccess}
                    header={this.renderHeader()}
                    filters={appliedFilters}
                    requestData={params => this.props.userStore.loadList(params, mode)}
                    handlePageChange = {(page,isEdit)=>this.handlePageChange(page,isEdit)}//handles page change
                    page = {this.props.page}//new page props to maintain page from parent//
                    isPageAddedOrEdited = {this.props.isPageAddedOrEdited}//to load same page after edit//
                />
                 <Modal                    
                    show={this.state.showAttachmentModal}
                    onHide={this.handleAttachmentModalCloseButton}
                    dialogClassName="lg"
                    className="lg" size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('User')} {this.props.t('Attachments')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>             
                        <Row>
                            <Col sm={12}>                            
                            <div className="attachment-box">
                                <span className="attachment-box__label">{this.props.t('Upload Files')}</span>
                                <AttachmentsWidget
                                    value={this.state.userAttachmentIds}
                                    onChange={attachIds => this.setState({userAttachmentIds:attachIds})}  
                                    options={{
                                        imageContext: {
                                            model: 'User',
                                            fileType: 'docs',
                                            id: this.state.currentUserId ? this.state.currentUserId : 0,
                                            existingAttachment: this.state.attachmentList ? this.state.attachmentList : [],
                                        }
                                    }}                                                                      
                                />
                            </div>
                            </Col>
                            {this.state.attachmentList && this.state.attachmentList != [] && 
                            <Col sm={12}>                                
                                {this.renderAttachmentTable(this.state.attachmentList)}
                            </Col>
                            } 
                        </Row>                                                                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.saveUserAttachments} className="btn-wd btn-fill">
                            {this.props.t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleAttachmentModalCloseButton} className="btn-wd">
                            {this.props.t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
                <Modal
                    show={this.state.isModalOn}
                    onHide={this.handleCloseModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Invite member to Join')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Input
                            label={`${t('Enter Email')}*`}
                            name="emails"
                            placeholder={t("[Email 1], [Email 2]")}
                            value={this.state.email}
                            onChange={(e) => this.handleChange('email', e.target.value)}
                        />
                        <span>You can add multiple Email-ID Here by adding comma(,) between two Email Address</span>
                        <Textarea
                            className='mt-10'
                            label={`${t('Write Comment')}`}
                            value={this.state.message}
                            style={{ width: '100%' }}
                            name="message"
                            onChange={(e) => this.handleChange('message', e.target.value)}
                            placeholder={t('Write Comment')}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.copyLink()} className="btn-wd btn-fill">
                            {t('Copy Link')} <i className="fa fa-link" />
                        </Button>
                        <Button variant="secondary" onClick={values => this.onShare(values)} className="btn-wd btn-fill">
                            {t('Share')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleCloseModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
export default UsersList;
