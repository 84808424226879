import signWidgetIcon from '../../../../assets/img/sign-widget-icon.svg';
import dateWidgetIcon from '../../../../assets/img/date-widget-icon.svg';
import recipientWidgetIcon from '../../../../assets/img/recipient-widget-icon.svg';
import initialWidgetIcon from '../../../../assets/img/initial-widget-icon.svg';
import labelWidgetIcon from '../../../../assets/img/label-widget-icon.svg';
import textboxWidgetIcon from '../../../../assets/img/textbox-widget-icon.svg';
import checkboxWidgetIcon from '../../../../assets/img/checkbox-widget-icon.svg';
import radiobuttonWidgetIcon from '../../../../assets/img/radiobutton-widget-icon.svg';
import dropdownWidgetIcon from '../../../../assets/img/dropdown-widget-icon.svg';
import signWidget from '../../../../assets/img/signature-widget.svg';
// import signWidgetNor from '../../../../assets/img/no/signature-widget-no.svg';
import dateWidget from '../../../../assets/img/date-widget.svg';
// import dateWidgetNor from '../../../../assets/img/no/date-widget-no.svg';
import recipientWidget from '../../../../assets/img/recipient-widget.svg';
// import recipientWidgetNor from '../../../../assets/img/no/recipient-widget-no.svg';
import initialWidget from '../../../../assets/img/Initials-widget.svg';
// import initialWidgetNor from '../../../../assets/img/no/Initials-widget-no.svg';
import labelWidget from '../../../../assets/img/label-widget.svg';
// import labelWidgetNor from '../../../../assets/img/no/label-widget-no.svg';
import textboxWidget from '../../../../assets/img/textbox-widget.svg';
// import textboxWidgetNor from '../../../../assets/img/no/textbox-widget-no.svg';
import checkboxWidget from '../../../../assets/img/checkbox-widget.svg';
import radiobuttonWidget from '../../../../assets/img/radiobutton-widget.svg';

import dropdownWidget from '../../../../assets/img/dropdown-widget.svg';
// import dropdownWidgetNor from '../../../../assets/img/no/dropdown-widget-no.svg';
let locale = sessionStorage.getItem("locale");


const widgetList = [
    {
        widgetName : "Signature",
        label: "Signature",
        widgetIcon : signWidgetIcon,
        dropIcon: signWidget
    },
    {
        widgetName : "Document Date",
        label: "Date",
        widgetIcon : dateWidgetIcon,
        dropIcon : dateWidget
    },
    {
        widgetName : "Recipient Name",
        label: "Recipient",
        widgetIcon : recipientWidgetIcon,
        dropIcon : recipientWidget
    },
    {
        widgetName : "Initials",
        label: "Initial",
        widgetIcon : initialWidgetIcon,
        dropIcon : initialWidget
    },
    {
        widgetName : "Label",
        label: "Label",
        widgetIcon : labelWidgetIcon,
        dropIcon : labelWidget
    },
    {
        widgetName : "Textbox",
        label: "Text",
        widgetIcon : textboxWidgetIcon,
        dropIcon : textboxWidget
    },
    {
        widgetName : "Checkbox List",
        label: "Checkbox",
        widgetIcon : checkboxWidgetIcon,
        dropIcon : checkboxWidget
    },
    {
        widgetName : "Radio Button List",
        label: "Radio",
        widgetIcon : radiobuttonWidgetIcon,
        dropIcon : radiobuttonWidget
    },
    {
        widgetName : "Select Box",
        label: "Select",
        widgetIcon : dropdownWidgetIcon,
        dropIcon : dropdownWidget
    }
]


export default widgetList;