import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
// import {  DropdownButton, MenuItem } from 'react-bootstrap';
import { momentLocalizer,Views  } from "react-big-calendar";
// import Select from 'react-select';
import Select from '../../elements/Select';
import { ProjectsWidget } from '../GenericForm/Widgets/ProjectsWidget';
import moment from "moment";
import { Row, Col } from 'react-bootstrap';
import "react-big-calendar/lib/css/react-big-calendar.css";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);
// Col, Row and Icon do not have their own less files for styling. They use
// rules declared in antd's global css. If these styles are imported directly
// from within antd, they'll include, for instance, reset rules. These will
// affect everything on the page and in essence would leak antd's global styles
// into all projects using this library. Instead of doing that, we are using
// a hack which allows us to wrap all antd styles to target specific root. In
// this case the root id will be "RBS-Scheduler-root". This way the reset styles
// won't be applied to elements declared outside of <Scheduler /> component.
//
// You can get more context for the issue by reading:
// https://github.com/ant-design/ant-design/issues/4331
// The solution is based on:
// https://github.com/ant-design/ant-design/issues/4331#issuecomment-391066131
//
// For development
// This fix is implemented with webpack's NormalModuleReplacementPlugin in
// webpack/webpack-dev.config.js.
//
// For library builds
// This fix is implemented by the build script in scripts/build.js
//
// The next components have their own specific stylesheets which we import
// separately here to avoid importing from files which have required the global
// antd styles.
// import Col from 'antd/lib/col';
// import Row from 'antd/lib/row';
import Icon from 'antd/lib/icon';
import 'antd/lib/select/style/index.css';
import 'antd/lib/grid/style/index.css';
// import Radio from 'antd/lib/radio';
import 'antd/lib/radio/style/index.css';
import Popover from 'antd/lib/popover';
import 'antd/lib/popover/style/index.css';
import Calendar from 'antd/lib/calendar';

import 'antd/lib/calendar/style/index.css';
import EventItem from './EventItem';
import DnDSource from './DnDSource';
import DnDContext from './DnDContext';
import ResourceView from './ResourceView';
import HeaderView from './HeaderView';
import BodyView from './BodyView';
import ResourceEvents from './ResourceEvents';
import AgendaView from './AgendaView';
import AddMorePopover from './AddMorePopover';
import ViewTypes from './ViewTypes';
import CellUnits from './CellUnits';
import SummaryPos from './SummaryPos';
import SchedulerData from './SchedulerData';
// import DemoData from './DemoData';

import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import withLocalization from './../../hoc/withLocalization';
import { style } from '~/variables/Variables';

// const RadioButton = Radio.Button;
// const RadioGroup = Radio.Group;


@inject('userStore', 'commonStore', 'scheduleStore','projectStore')
@withRouter
@withLocalization
@observer
class Scheduler extends Component {
    constructor(props) {
        super(props);

        const { schedulerData, dndSources } = props;
        let sources = [];
        sources.push(
            new DnDSource((props) => {
                return props.eventItem;
            }, EventItem)
        );

        
        if (dndSources != undefined && dndSources.length > 0) {
            sources = [...sources, ...dndSources];
        }
        const dndContext = new DnDContext(sources, ResourceEvents);

        this.currentArea = -1;
        schedulerData._setDocumentWidth(document.documentElement.clientWidth);
        this.state = {
            visible: false,
            dndContext,
            contentHeight: schedulerData.getSchedulerContentDesiredHeight(),
            contentScrollbarHeight: 17,
            contentScrollbarWidth: 17,
            resourceScrollbarHeight: 17,
            resourceScrollbarWidth: 17,
            scrollLeft: 0,
            scrollTop: 0,
            documentWidth: document.documentElement.clientWidth,
            documentHeight: document.documentElement.clientHeight,
            options:[
                {
                    value:'1',
                    label: props.t('Week')
                },
                {
                    value:'2',
                    label:props.t('Month')
                }
            ],
            selectedUsers:[],
            project_id:''
        };

        if (schedulerData.isSchedulerResponsive()) window.onresize = this.onWindowResize;
    }

    onWindowResize = (e) => {
        const { schedulerData } = this.props;
        schedulerData._setDocumentWidth(document.documentElement.clientWidth);
        this.setState({
            documentWidth: document.documentElement.clientWidth,
            documentHeight: document.documentElement.clientHeight,
        });
    };

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
        prevClick: PropTypes.func.isRequired,
        nextClick: PropTypes.func.isRequired,
        onViewChange: PropTypes.func.isRequired,
        onSelectDate: PropTypes.func.isRequired,
        onSetAddMoreState: PropTypes.func,
        updateEventStart: PropTypes.func,
        updateEventEnd: PropTypes.func,
        moveEvent: PropTypes.func,
        movingEvent: PropTypes.func,
        leftCustomHeader: PropTypes.object,
        rightCustomHeader: PropTypes.object,
        newEvent: PropTypes.func,
        subtitleGetter: PropTypes.func,
        eventItemClick: PropTypes.func,
        viewEventClick: PropTypes.func,
        viewEventText: PropTypes.string,
        viewEvent2Click: PropTypes.func,
        viewEvent2Text: PropTypes.string,
        conflictOccurred: PropTypes.func,
        eventItemTemplateResolver: PropTypes.func,
        dndSources: PropTypes.array,
        slotClickedFunc: PropTypes.func,
        toggleExpandFunc: PropTypes.func,
        slotItemTemplateResolver: PropTypes.func,
        nonAgendaCellHeaderTemplateResolver: PropTypes.func,
        onScrollLeft: PropTypes.func,
        onScrollRight: PropTypes.func,
        onScrollTop: PropTypes.func,
        onScrollBottom: PropTypes.func,
    };

    componentDidMount(props, state) {
        this.resolveScrollbarSize();
    }

    componentDidUpdate(props, state) {
        this.resolveScrollbarSize();

        const { schedulerData } = this.props;
        const { localeMoment, behaviors } = schedulerData;
        if (schedulerData.getScrollToSpecialMoment() && !!behaviors.getScrollSpecialMomentFunc) {
            if (!!this.schedulerContent && this.schedulerContent.scrollWidth > this.schedulerContent.clientWidth) {
                const start = localeMoment(schedulerData.startDate).startOf('day');
                const end = localeMoment(schedulerData.endDate).endOf('day');
                const specialMoment = behaviors.getScrollSpecialMomentFunc(schedulerData, start, end);
                if (specialMoment >= start && specialMoment <= end) {
                    let index = 0;
                    schedulerData.headers.forEach((item) => {
                        const header = localeMoment(item.time);
                        if (specialMoment >= header) index++;
                    });
                    this.schedulerContent.scrollLeft = (index - 1) * schedulerData.getContentCellWidth();

                    schedulerData.setScrollToSpecialMoment(false);
                }
            }
        }
    }

    async handleChange (value) {
        const { scheduleStore } = this.props;
        const {
            projectStore
        } = this.props;
        if(value){
            await projectStore.getProjectMembersIds(value).then(
                (data) => {
                     let projectMemberIds = data.membersArray || [];
                    this.setState({
                        selectedUsers: projectMemberIds,
                        project_id:value
                    });
                }
            );
        }
        else{
            this.setState({project_id:''});
        }
        //scheduleStore.changeEntity(name, value);
    };


    // async assignMembersByProject(id) {
    //     // var ids = [1,2,3,4,5]
    //     var userOptionsOld = this.state.userOptions;

    //     const {
    //         projectStore
    //     } = this.props;
    //     await projectStore.getProjectMembersIds(id).then(
    //         (data) => {
    //             let projectMemberIds = data.membersArray || [];
    //             this.setState({
    //                 selectedUsers: projectMemberIds,
    //                 members: userOptionsOld.filter(option => projectMemberIds.indexOf(option.value) !== -1).map(option => ({ id: option.value, label: option.label }))
    //             })
    //         }
    //     );
    // }

    // handleOption(event){
    //     this.onViewChange(event);
    // }

    render() {
        
        const { schedulerData, leftCustomHeader, rightCustomHeader, allowResourceSelection, t, scheduleStore,userStore } = this.props;
        const isMember = userStore.currentUser.user_type === 'member';
       // const entity = scheduleStore.currentEntity;
        // let { resources } = scheduleStore;
        // resources = _.filter(resources, (r) => !!r.id);
        // if (entity.project_id) {
        //     resources = _.filter(resources, (r) => r.project_ids.indexOf(parseInt(entity.project_id, 10)) >= 0);
        // }

        const { renderData, viewType, showAgenda, isEventPerspective, config } = schedulerData;  
        const {options,selectedUsers,project_id}= this.state;
       // let isFounded = renderData.some( ai => selectedUsers.includes(ai.slotId) );
    //   let isFounded = renderData.some(v=> selectedUsers.indexOf(v.slotId) !== -1);
    const filteredRenderData = renderData.filter(value => selectedUsers.includes(value.slotId));
        
        const width = schedulerData.getSchedulerWidth();
        // const { calendarPopoverEnabled } = config;
        const calendarPopoverEnabled = false;

        let dateLabel = schedulerData.getDateLabel(1); 
        let getWeek = schedulerData.getDateWeek('1');
        const defaultValue = `${viewType}${showAgenda ? 1 : 0}${isEventPerspective ? 1 : 0}`;
        // const radioButtonList = config.views.map((item) => {
        //     if (item.viewType === viewType.Quarter) return null;

        //     return (
        //         <RadioButton
        //             key={`${item.viewType}${item.showAgenda ? 1 : 0}${item.isEventPerspective ? 1 : 0}`}
        //             value={`${item.viewType}${item.showAgenda ? 1 : 0}${item.isEventPerspective ? 1 : 0}`}
        //         >
        //             {/* <span style={{ margin: '0px 8px' }}>{item.viewName}</span> */}
        //             <span style={{ margin: '0px 8px' }} className="set_viewStyle">{item.viewName}</span>
        //         </RadioButton>
        //     );
        // });

        let tbodyContent = <tr />;
        if (showAgenda) {
            tbodyContent = <AgendaView {...this.props} />;
        } else {
            const resourceTableWidth = schedulerData.getResourceTableWidth();
           // const schedulerContainerWidth = width - resourceTableWidth + 1;
            const schedulerWidth = schedulerData.getContentTableWidth() - 1;
            const DndResourceEvents = this.state.dndContext.getDropTarget();
            const eventDndSource = this.state.dndContext.getDndSource();

            let displayRenderData = renderData.filter((o) => o.render);
            if(project_id === ''){
                displayRenderData = renderData.filter((o) => o.render);
            }

            if(selectedUsers.length && project_id != ''){
                 displayRenderData = filteredRenderData.filter((o) => o.render);
            }
            const resourceEventsList = displayRenderData.map((item) => {
                return (
                    <DndResourceEvents
                        {...this.props}
                        key={item.slotId}
                        resourceEvents={item}
                        dndSource={eventDndSource}
                    />
                );
            });

            const { contentScrollbarHeight } = this.state;
            const { contentScrollbarWidth } = this.state;
            const { resourceScrollbarHeight } = this.state;
            const { resourceScrollbarWidth } = this.state;
            const contentHeight = this.state.contentHeight + 135;
            const resourcePaddingBottom = resourceScrollbarHeight === 0 ? contentScrollbarHeight : 0;
            const contentPaddingBottom = contentScrollbarHeight === 0 ? resourceScrollbarHeight : 0;
            let schedulerContentStyle = {
                overflowX: 'auto',
                overflowY: 'hidden',
                margin: '0px',
                position: 'relative',
                paddingBottom: contentPaddingBottom,
            };
            let resourceContentStyle = {
                overflowX: 'auto',
                overflowY: 'hidden',
                width: resourceTableWidth + resourceScrollbarWidth - 2,
                margin: `0px -${contentScrollbarWidth}px 0px 0px`,
            };
            if (config.schedulerMaxHeight > 0) {
                schedulerContentStyle = {
                    ...schedulerContentStyle,
                    // maxHeight: config.schedulerMaxHeight - config.tableHeaderHeight,
                };
                resourceContentStyle = {
                    ...resourceContentStyle,
                    // maxHeight: config.schedulerMaxHeight - config.tableHeaderHeight,
                };
            }

            const resourceName = schedulerData.isEventPerspective ? config.taskName : config.resourceName;
            tbodyContent = (
                <tr>
                   
                    <td style={{ width: resourceTableWidth, verticalAlign: 'top' }}>
                        <div className="resource-view" style={{borderRight:'2px solid #e8e8e8'}}>
                            <div
                                style={{
                                    overflow: 'hidden',
                                    borderBottom: '1px solid #e9e9e9',
                                    height: config.tableHeaderHeight,
                                }}
                            >
                                <div
                                    style={{
                                        overflowX: 'scroll',
                                        overflowY: 'hidden',
                                        margin: `0px 0px -${contentScrollbarHeight}px`,
                                    }}
                                >
                                    <table className="resource-table">
                                        <thead>
                                            <tr style={{ height: config.tableHeaderHeight }}>
                                                <th className="header3-text schedule-resource-selection" >
                                                    {/* {allowResourceSelection && this.props.copyingStarted && (
                                                        <span
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <a
                                                                onClick={() =>
                                                                    this.props.onResourceSelectionChanged('all')
                                                                }
                                                            >
                                                                {t('Select All')}
                                                            </a>
                                                            <button
                                                                onClick={() => this.props.onCopyingStart()}
                                                                title={t('Start copy')}
                                                            >
                                                                {t('Copy')}
                                                            </button>
                                                        </span>
                                                    )} */}
                                                    <span style={{fontSize:'15px',fontWeight:'600'}}>{t('Employees')}</span>{resourceName}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                            <div
                                style={resourceContentStyle}
                                ref={this.schedulerResourceRef}
                                onMouseOver={this.onSchedulerResourceMouseOver}
                                onMouseOut={this.onSchedulerResourceMouseOut}
                                onScroll={this.onSchedulerResourceScroll}
                            >
                                <ResourceView {...this.props} project_id={project_id} filteredRenderData={filteredRenderData}  contentScrollbarHeight={resourcePaddingBottom} />
                            </div>
                        </div>
                    </td>
                  
                    <td valign="top">
                        <div
                            className="scheduler-view"
                            //style={{ width: schedulerContainerWidth, verticalAlign: 'top' }}
                            //style={{width:'100%'}}
                        >
                            <div
                                style={{
                                    overflow: 'hidden',
                                    borderBottom: '1px solid #e9e9e9',
                                    height: config.tableHeaderHeight,
                                }}
                            >
                                <div
                                    style={{
                                        overflowX: 'scroll',
                                        overflowY: 'hidden',
                                        margin: `0px 0px -${contentScrollbarHeight}px`,
                                    }}
                                    ref={this.schedulerHeadRef}
                                    onMouseOver={this.onSchedulerHeadMouseOver}
                                    onMouseOut={this.onSchedulerHeadMouseOut}
                                    onScroll={this.onSchedulerHeadScroll}
                                >
                                    <div
                                        style={{
                                            paddingRight: `${contentScrollbarWidth}px`,
                                            width: schedulerWidth + contentScrollbarWidth,
                                        }}
                                    >
                                        <table className="scheduler-bg-table">
                                            <HeaderView {...this.props} />
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={schedulerContentStyle}
                                ref={this.schedulerContentRef}
                                onMouseOver={this.onSchedulerContentMouseOver}
                                onMouseOut={this.onSchedulerContentMouseOut}
                                onScroll={this.onSchedulerContentScroll}
                            >
                                <div style={{ width: schedulerWidth, height: contentHeight }}>
                                    <div className="scheduler-content">
                                        <table className="scheduler-content-table">
                                            <tbody >{resourceEventsList}</tbody>
                                        </table>
                                    </div>
                                    <div className="scheduler-bg">
                                        <table
                                            className="scheduler-bg-table"
                                            style={{ width: schedulerWidth }}
                                            ref={this.schedulerContentBgTableRef}
                                        >
                                            <BodyView {...this.props} project_id={project_id} filteredRenderData={filteredRenderData}   />
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }

        const popover = (
            <div className="popover-calendar">
                <Calendar fullscreen={false} onSelect={this.onSelect} />
            </div>
        );

        let schedulerHeader = <div />;
        if (config.headerEnabled) {
            schedulerHeader = (
                <Row    style={{ marginBottom: '24px',marginRight:'1em' }}>
                    {leftCustomHeader}
                    <Col md={2} lg={2}  >
                         <div className="custom_dropdown">
                        <Select options={options} defaultValue={options[0]}  onChange={event=>this.onViewChange(event)} />
                        </div>
                    </Col>
                  
                    <Col md={2} lg={2}  >
                    {
                        !isMember && (
                            <div>
                        <ProjectsWidget
                                                 value={this.state.project_id || '' }
                                                onChange={(id) => this.handleChange(id)}
                                                allLabel={t('All projects')}
                                                allowAll
                                                // applyOnFirstLoad={entity.assignee_id > 0}
                                                 disabled={isMember}
                                                // filterByUser={entity.assignee_id}
                                                style={{ border: '1px solid' }}
                                            />
                        </div>
                        )
                    }
                   
                    </Col>

                    <Col md={3} lg={3} >
                    <div style={{border:'solid 1px #b4b4b4',borderRadius:'5px',display:'flex',justifyContent:'center',alignItems:'center'}} className={viewType===1?'p_0':'p_5'} >
                           
                           <span className="week_block" >
                               {getWeek}
                           </span>
                           
                       <Icon
                               type="left"
                               //style={{ marginRight: '8px' }}
                               style={{verticalAlign:'middle'}}
                               className="icon-nav"
                               onClick={this.goBack}
                           />
                          
                            {calendarPopoverEnabled ? (
                               <Popover
                                   content={popover}
                                   placement="bottom"
                                   trigger="click"
                                   visible={this.state.visible}
                                   onVisibleChange={this.handleVisibleChange}
                               >
                                   <span className="header2-text-label" style={{ cursor: 'pointer' }}>
                                       {dateLabel}
                                   </span>
                               </Popover>
                           ) : (
                               <span className="header2-text-label" style={{width:'0px',minWidth:'130px',color:'#707070',fontSize:'15px',fontFamily:'Roboto'}}>{dateLabel}</span>
                           )}
                           
                            <Icon
                               type="right"
                               //style={{ marginLeft: '8px' }}
                               style={{marginRight:'10px',verticalAlign:'middle'}}
                               className="icon-nav"
                               onClick={this.goNext}
                           />
                          
                       </div>
                    </Col>

                    <Col md={5} lg={5} >
                        <div>
                            {rightCustomHeader}
                        </div>
                    </Col>


                      

                    {/* <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div className="custom_dropdown">
                        <Select options={options} defaultValue={options[0]}  onChange={event=>this.onViewChange(event)} />
                        </div>
                        <div>
                        <ProjectsWidget
                                                 value={this.state.project_id || '' }
                                                onChange={(id) => this.handleChange(id)}
                                                allLabel={t('All projects')}
                                                allowAll
                                                // applyOnFirstLoad={entity.assignee_id > 0}
                                                 disabled={isMember}
                                                // filterByUser={entity.assignee_id}
                                                style={{ border: '1px solid' }}
                                            />
                        </div>
                        <div style={{border:'solid 1px #b4b4b4',borderRadius:'5px',display:'flex',justifyContent:'center',alignItems:'center'}} className={viewType===1?'p_0':'p_5'} >
                           
                            <span className="week_block" >
                                {getWeek}
                            </span>
                            
                        <Icon
                                type="left"
                                //style={{ marginRight: '8px' }}
                                style={{verticalAlign:'middle'}}
                                className="icon-nav"
                                onClick={this.goBack}
                            />
                           
                             {calendarPopoverEnabled ? (
                                <Popover
                                    content={popover}
                                    placement="bottom"
                                    trigger="click"
                                    visible={this.state.visible}
                                    onVisibleChange={this.handleVisibleChange}
                                >
                                    <span className="header2-text-label" style={{ cursor: 'pointer' }}>
                                        {dateLabel}
                                    </span>
                                </Popover>
                            ) : (
                                <span className="header2-text-label" style={{width:'0px',minWidth:'130px',color:'#707070',fontSize:'15px',fontFamily:'Roboto'}}>{dateLabel}</span>
                            )}
                            
                             <Icon
                                type="right"
                                //style={{ marginLeft: '8px' }}
                                style={{marginRight:'10px',verticalAlign:'middle'}}
                                className="icon-nav"
                                onClick={this.goNext}
                            />
                           
                        </div>
                        <div>
                        {rightCustomHeader}
                        </div>
                    </div> */}

                    
                </Row>
            );
        }

        
        // {
        //     this.state.showCalendar && (

        //         <NewCalendar
        //         localizer={localizer}
        //         // events={events}
        //         events={this.state.events}
        //         defaultView={Views.WEEK}
        //         startAccessor="start"
        //         endAccessor="end"
        //         style={{ height: 500 }}
        //         selectable
        //         scrollToTime={new Date(1970, 1, 1, 6)}
        //         defaultDate={new Date(2015, 3, 12)}
        //         onSelectEvent={event => alert(event.title)}
        //         onSelectSlot={this.handleSelect}
        //         onClick={()=>{alert('good')}}
        //       />
        //     )
        // }
        return (
            <table id="RBS-Scheduler-root" className="scheduler" style={{ width: `${width}px` }}>
                <thead>
                    <tr>
                        <td colSpan="2">{schedulerHeader}</td>
                    </tr>
                </thead>
                <tbody>{tbodyContent}</tbody>
            </table>
        );
    }

    resolveScrollbarSize = () => {
        const { schedulerData } = this.props;
        let contentScrollbarHeight = 17;
        let contentScrollbarWidth = 17;
        let resourceScrollbarHeight = 17;
        let resourceScrollbarWidth = 17;
        let contentHeight = schedulerData.getSchedulerContentDesiredHeight();
        if (this.schedulerContent) {
            contentScrollbarHeight = this.schedulerContent.offsetHeight - this.schedulerContent.clientHeight;
            contentScrollbarWidth = this.schedulerContent.offsetWidth - this.schedulerContent.clientWidth;
        }
        if (this.schedulerResource) {
            resourceScrollbarHeight = this.schedulerResource.offsetHeight - this.schedulerResource.clientHeight;
            resourceScrollbarWidth = this.schedulerResource.offsetWidth - this.schedulerResource.clientWidth;
        }
        if (!!this.schedulerContentBgTable && !!this.schedulerContentBgTable.offsetHeight) {
            contentHeight = this.schedulerContentBgTable.offsetHeight;
        }

        let tmpState = {};
        let needSet = false;
        if (contentScrollbarHeight != this.state.contentScrollbarHeight) {
            tmpState = { ...tmpState, contentScrollbarHeight };
            needSet = true;
        }
        if (contentScrollbarWidth != this.state.contentScrollbarWidth) {
            tmpState = { ...tmpState, contentScrollbarWidth };
            needSet = true;
        }
        if (contentHeight != this.state.contentHeight) {
            tmpState = { ...tmpState, contentHeight };
            needSet = true;
        }
        if (resourceScrollbarHeight != this.state.resourceScrollbarHeight) {
            tmpState = { ...tmpState, resourceScrollbarHeight };
            needSet = true;
        }
        if (resourceScrollbarWidth != this.state.resourceScrollbarWidth) {
            tmpState = { ...tmpState, resourceScrollbarWidth };
            needSet = true;
        }
        if (needSet) this.setState(tmpState);
    };

    schedulerHeadRef = (element) => {
        this.schedulerHead = element;
    };

    onSchedulerHeadMouseOver = () => {
        this.currentArea = 2;
    };

    onSchedulerHeadMouseOut = () => {
        this.currentArea = -1;
    };

    onSchedulerHeadScroll = (proxy, event) => {
        if (
            (this.currentArea === 2 || this.currentArea === -1) &&
            this.schedulerContent.scrollLeft != this.schedulerHead.scrollLeft
        )
            this.schedulerContent.scrollLeft = this.schedulerHead.scrollLeft;
    };

    schedulerResourceRef = (element) => {
        this.schedulerResource = element;
    };

    onSchedulerResourceMouseOver = () => {
        this.currentArea = 1;
    };

    onSchedulerResourceMouseOut = () => {
        this.currentArea = -1;
    };

    onSchedulerResourceScroll = (proxy, event) => {
        if (
            (this.currentArea === 1 || this.currentArea === -1) &&
            this.schedulerContent.scrollTop != this.schedulerResource.scrollTop
        )
            this.schedulerContent.scrollTop = this.schedulerResource.scrollTop;
    };

    schedulerContentRef = (element) => {
        this.schedulerContent = element;
    };

    schedulerContentBgTableRef = (element) => {
        this.schedulerContentBgTable = element;
    };

    onSchedulerContentMouseOver = () => {
        this.currentArea = 0;
    };

    onSchedulerContentMouseOut = () => {
        this.currentArea = -1;
    };

    onSchedulerContentScroll = (proxy, event) => {
        if (this.currentArea === 0 || this.currentArea === -1) {
            if (this.schedulerHead.scrollLeft != this.schedulerContent.scrollLeft)
                this.schedulerHead.scrollLeft = this.schedulerContent.scrollLeft;
            if (this.schedulerResource.scrollTop != this.schedulerContent.scrollTop)
                this.schedulerResource.scrollTop = this.schedulerContent.scrollTop;
        }

        const { schedulerData, onScrollLeft, onScrollRight, onScrollTop, onScrollBottom } = this.props;
        const { scrollLeft, scrollTop } = this.state;
        if (this.schedulerContent.scrollLeft !== scrollLeft) {
            if (this.schedulerContent.scrollLeft === 0 && onScrollLeft != undefined) {
                onScrollLeft(
                    schedulerData,
                    this.schedulerContent,
                    this.schedulerContent.scrollWidth - this.schedulerContent.clientWidth
                );
            }
            if (
                this.schedulerContent.scrollLeft ===
                    this.schedulerContent.scrollWidth - this.schedulerContent.clientWidth &&
                onScrollRight != undefined
            ) {
                onScrollRight(
                    schedulerData,
                    this.schedulerContent,
                    this.schedulerContent.scrollWidth - this.schedulerContent.clientWidth
                );
            }
        } else if (this.schedulerContent.scrollTop !== scrollTop) {
            if (this.schedulerContent.scrollTop === 0 && onScrollTop != undefined) {
                onScrollTop(
                    schedulerData,
                    this.schedulerContent,
                    this.schedulerContent.scrollHeight - this.schedulerContent.clientHeight
                );
            }
            if (
                this.schedulerContent.scrollTop ===
                    this.schedulerContent.scrollHeight - this.schedulerContent.clientHeight &&
                onScrollBottom != undefined
            ) {
                onScrollBottom(
                    schedulerData,
                    this.schedulerContent,
                    this.schedulerContent.scrollHeight - this.schedulerContent.clientHeight
                );
            }
        }
        this.setState({
            scrollLeft: this.schedulerContent.scrollLeft,
            scrollTop: this.schedulerContent.scrollTop,
        });
    };

    onViewChange = (e) => {
        const { onViewChange, schedulerData } = this.props;
        // const viewType = parseInt(e.target.value.charAt(0));
        // const showAgenda = e.target.value.charAt(1) === '1';
        // const isEventPerspective = e.target.value.charAt(2) === '1';
        const viewType = e.value;
        const showAgenda = e.value === 1;
        const isEventPerspective = e.value === 1;
              onViewChange(schedulerData, {
                viewType,
                showAgenda,
                isEventPerspective,
            });
        // if(viewType===1){
        //     onViewChange(schedulerData, {
        //         viewType,
        //         showAgenda,
        //         isEventPerspective,
        //     });
        // }
        // if(viewType===2){
        //     this.setState({showCalendar:true});
           
        // }
    };

    goNext = () => {
        const { nextClick, schedulerData } = this.props;
        nextClick(schedulerData);
    };

    goBack = () => {
        const { prevClick, schedulerData } = this.props;
        prevClick(schedulerData);
    };

    handleVisibleChange = (visible) => {
        this.setState({ visible });
    };

    onSelect = (date) => {
        this.setState({
            visible: false,
        });

        const { onSelectDate, schedulerData } = this.props;
        onSelectDate(schedulerData, date);
    };
}

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export { SchedulerData, ViewTypes, CellUnits, SummaryPos, DnDSource, DnDContext, AddMorePopover, };
export default Scheduler;
