import app_for_1 from './app_for_1.png';
import app_for_2 from './app_for_2.png';
import app_for_3 from './app_for_3.png';
import app_for_4 from './app_for_4.png';
import app_for_bg from './app_for_bg.svg';
import apple_store from './apple_store.png';
import cloud_service from './cloud_service.svg';
import no_setup from './no_setup.svg';
import union from './union.svg';
import more_functions from './more_functions.svg';
import google_play from './google_play.png';
import lg_bed from './lg_bed.svg';
import lg_clock from './lg_clock.svg';
import lg_contacts from './lg_contacts.svg';
import lg_icon_fb from './lg_icon_fb.svg';
import lg_icon_loc from './lg_icon_loc.svg';
import lg_icon_mail from './lg_icon_mail.svg';
import lg_icon_phone from './lg_icon_phone.svg';
import lg_icon_tw from './lg_icon_tw.svg';
import lg_icon_yt from './lg_icon_yt.svg';
import lg_logo from './lg_logo.svg';
import lg_main_bg from './lg_main_bg.svg';
import lg_main_img from './lg_main_img.svg';
import lg_preview_app from './lg_preview_app.svg';
import lg_puzle from './lg_puzle.svg';
import lg_vacation from './lg_vacation.svg';

export default {
    app_for_1,
    app_for_2,
    app_for_3,
    app_for_4,
    app_for_bg,
    apple_store,
    cloud_service,
    google_play,
    lg_bed,
    lg_clock,
    lg_contacts,
    lg_icon_fb,
    lg_icon_loc,
    lg_icon_mail,
    lg_icon_phone,
    lg_icon_tw,
    lg_icon_yt,
    lg_logo,
    lg_main_bg,
    lg_main_img,
    lg_preview_app,
    lg_puzle,
    lg_vacation,
    no_setup,
    union,
    more_functions,
};
