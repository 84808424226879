import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import '../../../styles/css/styles.css';

import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import GenericList from '~/components/GenericList/GenericList';
import Button from '~/components/CustomButton/CustomButton.jsx';
import CheckBoxWidget from '../../../components/GenericForm/Widgets/CheckBoxWidget';
import Select from '../../../elements/Select';
import DatePicker from '../../../components/DatePicker/DatePicker';
import config from '~/library/config';
import { longDateFormat } from '../../../utils/dateFormat';
import { FormControl } from 'react-bootstrap';
import TimePickerInput from '../../../components/TimePicker';
import { getDay } from 'date-fns';


@inject('checklistStore', 'authStore', 'userStore', 'commonStore', 'scheduleStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ScheduleAddAvailability extends Component{
    state = {
        from: moment(new Date()).startOf('month').toDate(),
        to: moment(new Date()).toDate(),
        from_date: new Date(),
        to_date: new Date(),
        checklist: {},
        showErrors: false,
        isProjectMembers: false,
        projectMembers: [],
        selectedMember: '',
        all:false,
        selected_date:null,
        isAddAvailability: false,
        isChecked: false,
        days : ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        dates : [],
        data : this.props.selectedRowData ? this.props.selectedRowData : {"client_id": null,"user_id": null,"availability": [],"from_date": "","to_date": "", "request_type": "weekly","isToTimePicker" : false },
    }

    onDaysSelectionChange(index, value) {
        let newData = this.state.data;
        switch (value) {
            case true:
                delete newData.availability[index].from;
                delete newData.availability[index].to;
                break;
            case false:
                newData.availability[index].from = 0;
                newData.availability[index].to = 0;
                break;
            default:
                break;
        }
        this.setState({data : newData});
        this.props.setData(newData);
    }

    onAvailabilitySelectionChange(index, value) {
        let newData = this.state.data;
        newData.availability[index].availability_type = value;
        this.setState({data : newData});
        this.props.setData(newData);
    }

    handleWeekDateChange = (name, value) => {
        let newData = this.state.data;
        // newData[name] = moment(value).format("YYYY-MM-DD");
        newData[name] = value;
        this.setState({data : newData});
        this.props.setData(newData);
        this.getDatesInRange(newData.from_date, newData.to_date);
    };

    setInputValues = (index, name, value) => {
        const {commonStore, t} = this.props;
        let newData = this.state.data;
        newData.availability[index][name] = value;
        if(newData.availability[index]['from']){
            newData.availability[index]['isToTimePicker'] = true;
        }
        // if(newData.availability[index]['from'] > newData.availability[index]['to']){
        //     commonStore.addNotification(t('Start time should be less than End time'), null, 'error');
        // }
        this.setState({data : newData});
        this.props.setData(newData);
    };

    disableWeekends = current => {
        return current.day() === 0 && current.day() === 6;
    }

    getDatesInRange = (startDate, endDate) => {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
        let newData = this.state.data;
        newData.availability = [];
        dates.map(date => {
            // let temp = moment(date).format("YYYY-MM-DD");
            let temp = date;
            newData.availability.push({for_date : temp, availability_type: 'available'});
        });
        // console.log(newData);
        this.setState({data : newData, dates : dates});
        this.props.setData(newData);

    }

    getDay = (date) => {
        const {days} = this.state;
        // console.log(date);
        // console.log(new Date(date.for_date));
        return days[new Date(date.for_date).getDay()];
    }

    handleRequestTypeChange(name, value) {
        let newData = this.state.data;
        newData[name] = value && value.value;
        this.setState({data : newData});
        this.props.setData(newData);
    };

    render() {
        const { data } = this.state;
        const { userStore, t, commonStore } = this.props;
        const { config } = commonStore ;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';
        const { currentUser } = userStore;
        const allowAvailability = config.client && config.client.data && config.client.data.basicRules 
        && config.client.data.basicRules.allowAvailability ? config.client.data.basicRules.allowAvailability : false;
        const isNotMember = currentUser.user_type !== 'member';

        const {
            value,
            onChange,
            mode,
            defaultIfOne,
            filterByUser,
            disabled,
            emptyOption,
            emptyOptionLabel,
            label = '',
            save
        } = this.props;

        const currentSelect = find([{label :'Available', value : 'available'}, {label : 'Not available', value: 'not available'}], o => {
            return String(o.value) === String(value);
        });        

        const inputView = !!label;

        return(
            <div className='availability-card'>
                <div style={{margin: '1em'}} className="row-flex">
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <div className='col-lg-6 col-sm-12 col-xs-12 col-md-6 '>
                            <p className="">{t('Availability Type')}</p>
                        </div>
                        <div className='col-lg-6 col-sm-12 col-xs-12 col-md-6'>
                            <Select
                                // value={this.state.data.request_type}
                                options={[{ label: 'Weekly', value: 'weekly' }, { label: 'Repeating', value: 'repeating' }]}
                                placeholder={this.state.data.request_type ? t(this.state.data.request_type) : t("Request Type")}
                                name="request_type"
                                style={{ width: '150px' }}
                                onChange={(evt) => { this.handleRequestTypeChange('request_type', evt) }}
                            />
                        </div>
                    </div>
                    {/* {
                        this.props.selectedRowData && this.props.selectedRowData.status === 'pending' && isNotMember ? 
                        <>
                            <div className='col-lg-1 col-md-1 col-sm-12 col-xs-12' style={{display: 'flex', alignItems: 'center'}}>
                                {this.props.selectedRowData && <Button fill wd icon type="submit" style={{ backgroundColor: 'green', marginRight: 30, border: 0 }}
                                onClick={() => {data.status = "approved"; this.setState({data: data});this.props.editData(data)}}>{t('Approve')}</Button>}
                            </div>
                            <div className='col-lg-1 col-md-1 col-sm-12 col-xs-12' style={{display: 'flex', alignItems: 'center', marginLeft: 30}}>
                                {this.props.selectedRowData && <Button fill wd icon type="submit" style={{ backgroundColor: 'red', marginRight: 30, border: 0 }}
                                onClick={() => {data.status = "rejected"; this.setState({data: data});this.props.editData(data)}}>{t('Reject')}</Button>}
                            </div>
                        </>
                        : 
                        <>
                            {this.props.selectedRowData &&
                                <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12' style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button disabled={true}>{t(this.props.selectedRowData.status)}</Button>
                                </div>
                            }
                        </>
                    } */}
                </div>
                <div>
                    <p className="dashboard-picker-title">{t('From Week')}</p>
                    <DatePicker
                        isValidDate={this.disableWeekends}
                        selected={data.from_date && new Date(data.from_date)}
                        onChange={(date) => this.handleWeekDateChange('from_date', date)}
                        dateFormat={longDateFormat(dateTimeRules)}
                        filterDate={date => getDay(date) === 1}
                    />
                </div>
                <div>
                    <p className="dashboard-picker-title">{t('To Week')}</p>
                    <DatePicker
                        isValidDate={this.disableWeekends}
                        selected={data.to_date && new Date(data.to_date)}
                        onChange={(date) => this.handleWeekDateChange('to_date', date)}
                        dateFormat={longDateFormat(dateTimeRules)}
                        filterDate={date => getDay(date) === 0}
                    />
                </div>
                {data.availability.map((date, index) => {
                    return (
                        <div className='availability-days row-flex' style={{color: '#3abda9'}} key={index}>
                            {date && <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'><span className='title'>{t(this.getDay(date))}</span></div>}
                            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                                <span className='title'>
                                    <CheckBoxWidget 
                                        value={data.availability[index].from != undefined && data.availability[index].from >= 0? false : true} 
                                        onChange={(value) => this.onDaysSelectionChange(index, value)} 
                                        label="All day" />
                                </span>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                <span className='title'>
                                    {/* <p>{data.availability[index].availability_type}</p> */}
                                <Select
                                    options={[{label :'Available', value : 'available'}, {label : 'Not available', value: 'not available'}]} 
                                    placeholder={this.state.data.availability[index].availability_type ? this.state.data.availability[index].availability_type : t("Availability type")}
                                    value={data.availability[index].availability_type && (data.availability[index].availability_type).charAt(0).toUpperCase()}
                                    // inputView={inputView}
                                    // emptyOptionLabel={emptyOptionLabel}
                                    // value={currentSelect}
                                    // isDisabled={disabled}
                                    onChange={(event) => this.onAvailabilitySelectionChange(index, event.value)}
                                    style={{ width: '100%',marginBottom: '0.5em' }}
                                />
                                    {data.availability[index] && data.availability[index].from != undefined && data.availability[index].from >= 0 &&
                                    data.availability[index].to != undefined && data.availability[index].to  >= 0 && 
                                    <span className='row-flex' style={{marginBottom: '0.5em'}}>
                                        <div style={{paddingRight : "20px"}}>
                                        <TimePickerInput       
                                            style={{width:150, marginRight: '0.5em'}}
                                            value={data.availability[index].from ? minutesToTimeInput(data.availability[index].from, false) : `00:00`}
                                            format={format=="hh:mm"?"HH:mm":format}
                                            onChange={(value) => this.setInputValues(index, 'from', getMinutesFromTimeInput(value))}
                                        />
                                        </div>

                                        {data.availability[index]['isToTimePicker'] ? 
                                        <TimePickerInput       
                                            style={{width:150}}
                                            value={data.availability[index].to ? minutesToTimeInput(data.availability[index].to, false) : `00:00`}
                                            format={format=="hh:mm"?"HH:mm":format}
                                            onChange={(value) => this.setInputValues(index, 'to', getMinutesFromTimeInput(value))}
                                        />
                                        :
                                        <TimePickerInput       
                                            style={{width:150}}
                                            value={data.availability[index].to ? minutesToTimeInput(data.availability[index].to, false) : `00:00`}
                                            format={format=="hh:mm"?"HH:mm":format}
                                            onChange={(value) => this.setInputValues(index, 'to', getMinutesFromTimeInput(value))}
                                            disabled
                                        />
                                        }
                                    </span>}
                                    <FormControl
                                        placeholder={t('Comments')}
                                        type="text"
                                        onChange={(e) => this.setInputValues(index, 'comment', e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                // onFilter();
                                            }
                                        }}
                                        value={data.availability[index].availability_type && data.availability[index].comment}
                                    />
                                </span>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default ScheduleAddAvailability;
