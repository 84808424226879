import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import Select from '../../../elements/Select';
import { Input } from '../../../elements/Input';
import { Modal } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';
import LeftArrow from './../../../assets/img/left-arrow.svg'
import { countryOptions, moduleOptions } from '../../../utils/constants';
import contractStore from '../../../library/stores/contractStore.js';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import { find, cloneDeep, sortBy, values } from 'lodash';
import ContractsEditor from './contractsEditor.js';
import { serverTimeFormat } from '../../../library/core';



const countries = [
    { value: "United States", label: "United States", title: "United States"},
    { value: "Russia", label: "Russia", title: 'Russia' },
    { value: "Greek", label: "Greek", title: 'Greek' },
    { value: "Sweden", label: "Sweden", title: 'Sweden'},
    { value: "Norway", label: "Norway", title: "Norway" },
    { value: "Poland", label: "Poland", title: 'Poland' },
    { value: "Spanish", label: "Spanish", title: 'Spanish'},
    { value: "Lithuania", label: "Lithuania", title: 'Lithuania' }
];

//const defaultButton = (props) => <button {...props}>{props.children}</button>;

@inject('userStore', 'timelogStore', 'contractStore', 'commonStore', 'documentStore')
@withRouter
@withRemoveDialog
@observer
class ContractsForm extends Component {
    constructor(){
        super();
        this.handleChildGoBack = this.handleChildGoBack.bind(this);
        this.goBack = this.goBack.bind(this);
    }
    state = {
        document_name: '',
        module_name: 'user',
        module: '',
        showChecklistAccept: false,
        listItems: [],
        userInput: '',
        listArray: [],
        name: '',
        contract_type_options:[],
        contract_type_selected : null,
        isCurrentTaskEdit: false,
        editingRowId: null,
        country_name: null,
        showFormComponent:true,
        isEdit:false,
        contract_id : null,
        error: '',
        errors: ''
    };

    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount(){
        const {contractStore} = this.props;
        contractStore.resetList();
    }

    loadData = async () => {
        const { dashboardStore, history,contractStore } = this.props;
        let getValue = parseInt(history.location.pathname.split('/').pop());

        await contractStore.getContractTypes({});
       
        if(getValue){
            let cotract_type_list = [];
            if(contractStore.contractTypesList && contractStore.contractTypesList.length){
                cotract_type_list = contractStore.contractTypesList.map(item=>{
                    return { value: item.id, label: item.name };
                })
            }
            await contractStore.getContractsById(getValue).then((response) => {
                if (response) {
                    this.setState({
                        country_name : countries.filter(e=>e.value === response.contract_record.country),
                        contract_type_selected : cotract_type_list.filter(e=>e.value === response.contract_record.contract_type_id)[0] ,
                        module_name : this.state.module_name,
                        document_name : response.contract_record.name,
                        contract_id : response.contract_record.id,
                        isEdit :true
                    });
                    // commonStore.addNotification('Edit', null, 'success');
                }
                // return data;
            });
        //    contractStore.setEditMode();
            
        }
        if(contractStore.contractTypesList && contractStore.contractTypesList.length){
            let cotract_type_list = contractStore.contractTypesList.map(item=>{
                return { value: item.id, label: item.name };
            })
            this.setState({contract_type_options:cotract_type_list});
        }

       
    };

    

    handleTableButtonAction = (type) => {
        if (type === 'open') {

            this.renderContractModal();
        }
    };
    renderContractModal = async () => {
        const { t } = this.props;
        this.setState({
            showContractModal: true,
        });
    };
    addData = async () => {
        const { t, checklistStore ,commonStore} = this.props;
        const { userInput, for_date } = this.state;
        if( !userInput || userInput.trim() === ""){
            commonStore.addNotification(t('Enter Contract Name'), null, 'error');
            this.setState({errors: t('Enter Contract Name')})
            return;
        }
        if(userInput.length >=30){
            this.setState({errors: t('contract name should not be more than 30 character')});
            return;
        }
        this.setState({errors: ''})
        let data = {
            name: userInput,
        };
        await contractStore
            .addContractType(data)
            .then((contract_type) => {
                if (contract_type) {
                    this.props.commonStore.addNotification(t('Contract Data Saved'), null, 'success');
                    this.loadData();
                    this.setState({userInput: ''});
                }
            })
            .catch((err) => {
                this.props.commonStore.addNotification(err.message || t('Error'), null, 'error');
            });
    };


    handleCloseContractModal = () => {
        this.setState({ showContractModal: false });
    };
    inputChangeHandler = ({ target: { value } }) =>
   
        this.setState({
            userInput: value,
        });



    handleSave = async (values) => {
        const { listItems } = this.state;
        const { contractStore, commonStore } = this.props;
        let data = {
            contract_type_id: values.id,
            name: values.name
        }
        await contractStore.UpdateContractTypeById(data).then((response) => {
            if (response) {
                commonStore.addNotification('Edit', null, 'success');
                this.loadData();
            }
        });
        this.setState({ editingRowId: null });
    };


    handleChangeValue(rowIndex, name, value) {
     
        const {contractStore}= this.props;
        let contract_type_list = contractStore.contractTypesList;
        contract_type_list[rowIndex][name] = value;
        return contract_type_list

    }
    
    handleEdit(li) {
        this.setState({ editingRowId: li.id });
    }

    handleRemove = async (id) => {
        const { t, commonStore, contractStore } = this.props;
        await contractStore.deleteContractTypeById(id).then((response) => {
            if (response) {
                commonStore.addNotification('Deleted', null, 'error');
            }
        });
        this.loadData()
    };

    handleContractSave = async (values) => {
        const { contractStore, commonStore,history , t} = this.props;
        const { document_name, module_name,country_name,contract_type_selected,contract_id } = this.state;
        if(!country_name || !contract_type_selected ){
            commonStore.addNotification(t('Please Enter all values'), null, 'error');
            return;
        }
        if( !document_name || document_name.trim() === ""){
            commonStore.addNotification(t('Enter Document Name'), null, 'error');
            this.setState({error: t('Enter Document Name')})
            return;
        }
        if(document_name.length >=30){
            this.setState({error: t('document name should not be more than 30 character')});
            return;
        }
        this.setState({error: ''})
        let data = {
            id : contract_id ? contract_id :null,
            country : country_name.value,
            contract_type_id : contract_type_selected.value,
            module :  this.state.module_name,
            name: document_name
        }
       
        await contractStore.addContractDataList(data).then(response=>{
           
            
       
              
            if(response.contract_details){
                commonStore.addNotification('Saved', null, 'success');
                this.loadData();
               this.setState({showFormComponent:false});
               return true;
            }
        this.setState({error: ''})
        })
        .catch(error=>{
            commonStore.addNotification(error.message || t('Error'), null, 'error');
        })
    
    };
    
    handleFieldsChange(name, value) {
        switch (name) {
            case 'country':
                this.setState({ country_name: value });
                break;
            case 'module':
                this.setState({ module_name: 'user' });
                break;
            case 'doc_name':
                this.setState({ document_name: value });
                break;
            case 'contract_type':
                this.setState({contract_type_selected:value});
                break;

        }
    }

    handleChildGoBack (){
        this.setState({showFormComponent:true});
    }

    goBack(){
        const {history} = this.props;
        history.push("/admin/contract_templates");
    }

    render() {
    
        const {error,errors} = this.state;
        const { userStore, setFilter, onFilter, t, timelogStore } = this.props;
        const { users, members,contract_id, showFormComponent, editingRowId, usersToAdd,contract_type_options,country_name,contract_type_selected,module_name } = this.state;
        const currentSelect = find(moduleOptions, o => {
            return o.value === this.state.module_name;
        });
        return (
            <div className='main-content'>
            {
                showFormComponent ? (

                <div style={{ paddingLeft: '20px', marginTop: '20px' }}>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <h5 style={{ fontWeight: 'bolder' }}>{t('Add New Contract')}</h5>
                        <p style={{ color: 'grey', paddingTop: '10px',cursor:'pointer'}}><img onClick = {this.goBack} src={LeftArrow} alt="left arrow"/> &nbsp;{t('Go Back')}</p>
                    </div>
                    <div style={{marginTop : '25px'}}>
                        <h6 style={{ fontWeight: 'bolder', paddingLeft: '5px' }}>{t('Select Country')}</h6>

                        <Select options={countries} value={this.state.country_name} onChange={(value) => this.handleFieldsChange('country', value)} placeholder={this.props.t('Select Country')} style={{ width: 350 }} required />

                        <h6 style={{ fontWeight: 'bolder', paddingLeft: '5px' }}>{t('Contract Type')}</h6>
                        <div style={{ display: 'flex' }}>
                            <Select
                                options={contract_type_options}
                                value={this.state.contract_type_selected}
                                required
                                onChange={(value)=>this.handleFieldsChange('contract_type',value)}
                                placeholder={this.props.t('Search & Select Contract Type')}
                                style={{ width: 350 }}
                            />
                            <Button
                                onClick={() => this.handleTableButtonAction('open')}
                                style={{ visibility: 'hidden' }}
                            >
                                <i className="fa fa-plus-circle fa_custom fa-2x" style={{ visibility: 'visible' }}></i>
                            </Button>
                        </div>
                        <h6 style={{ fontWeight: 'bolder', paddingLeft: '5px' }}>{t('Module')}</h6>

                        <div className='module-name-contract'>
                        <Select options={moduleOptions}    
                      // value={this.state.module_name}    
                      defaultValue = {currentSelect}                   
                        onChange={(value) => this.handleFieldsChange('module', value)} style={{ width: 350 }} placeholder={this.props.t('Select Module')}
                        isDisabled  ={true}
                        />
                        </div>
                        <h6 style={{ fontWeight: 'bolder', paddingLeft: '5px' }}>{t('Document Name')}</h6>
                        <Input
                            type="text"
                            name="Document Name"
                            placeholder={this.props.t('Document Name')}
                            style={{ width: 350 }}
                            value={this.state.document_name}
                            required
                            onChange={e=>this.handleFieldsChange('doc_name',e.target.value)}
                        />
                          {error!== '' && <span className='text-danger text-center'>{t(error)}</span>}
                    </div>
                    <Button fill wd icon onClick={this.handleContractSave} style={{ marginTop: '20px' }}>
                        {t('Next')}
                    </Button>
                </div>
                ) : (
                    <>
                        <ContractsEditor contract_type_selected = {contract_type_selected} contract_id = {contract_id} handleGoBack = {this.handleChildGoBack} />
                    </>
                )
            }

                <Modal
                    className="ContractModal"
                    show={this.state.showContractModal}
                    style={{ overflow: 'hidden' }}
                >
                    <Modal.Header
                        
                        
                        style={{ borderBottom: '2px solid #87CEEB', width: '100%' }}
                    >
                    <span  className="close-button cursor-pointer" onClick={this.handleCloseContractModal}>&times;</span>
                        <Modal.Title style={{ fontWeight: 'bolder' }}>{t('Contract Types')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ paddingTop: '30px',padding:'20px' }}>
                        <div className="example" style={{ overflowY: 'scroll', maxHeight: '300px' }}>
                            <table className="table table-hover table-striped">
                                <tbody>
                                    <tr 
                                    className='contract-form-border'>
                                        <th style={{paddingRight:'128px'}}>{t('Contract Name')}</th>
                                        <th style={{paddingRight:'271px'}}>{t('Date')}</th>
                                        <th style={{paddingRight:'30px'}}>{t('Action')}</th>
                                         
                                    </tr>
                                   
                                <tr>
                                {/* <div className='row'> */}
                                <td colSpan={2}>
                                       
                                           
                                          
                                                <input
                                                className='contractNameField'
                                                    value={this.state.userInput}
                                                    onChange={this.inputChangeHandler}
                                                    placeholder={this.props.t('Enter Contract Name')}
                                                     style={{ width:'90%'}}
                                                />
                                             
                                                 <button
                                                    type="submit"
                                                    value="Submit"
                                                    style={{ visibility: 'hidden' }}
                                                    onClick={this.addData}
                                                >
                                                    <i
                                                        className="fa fa-plus-circle fa_custom fa-2x"
                                                        style={{ visibility: 'visible' ,paddingTop:'5px'}}
                                                    ></i>
                                                </button>
                                                
                                                 {errors!== '' && <span className='text-danger text-center ml-10'>{t(errors)}</span>}
                                                 
                                                </td>
                                                
                                                <td>
                                                
                                            
                                                </td>
                                            
                                           
                                        </tr>
                                    
                                        
                                    {contractStore.contractTypesList && contractStore.contractTypesList.map((li, rowIndex) => (

                                        <tr key={rowIndex} 
                                        className="contract-form-border"
                                     
                                        >

                                            <td style={{paddingRight:'30px'}}>
                                                {li.id === editingRowId && (
                                                    <Input
                                                        value={li.name}
                                                        onChange={e => this.handleChangeValue(rowIndex, 'name', e.target.value)
                                                        }
                                                    />
                                                )}
                                                {li.id !== editingRowId && <span>{li.name}</span>}

                                            </td>
                                            <td > 
                                            {li.for_date ? moment(li.for_date).format(serverTimeFormat()) : ''}
                                             </td>

                                            <td >
                                                {li.id === editingRowId && (
                                                    <Button
                                                        onClick={() => this.handleSave(li)}
                                                        bsStyle="danger"
                                                        simple
                                                        icon
                                                    >
                                                        <i className="fa fa-check cursor-pointer" />
                                                    </Button>
                                                )}

                                                {li.id !== editingRowId && (
                                                    <>
                                                        <i
                                                            className="far fa-edit cursor-pointer"
                                                        
                                                            style={{ fontSize: '20px', color: '#007FFF' }}
                                                            key={li.key}
                                                            onClick={() => this.handleEdit(li)}
                                                        ></i>
                                                        <i
                                                            className="fas fa-trash-alt cursor-pointer"
                                                            aria-hidden="true"
                                                            style={{ fontSize: '20px', color: 'red', paddingLeft: '10px' }}
                                                            onClick={() => this.handleRemove(li)}
                                                            key={li.key}
                                                        ></i>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            fill
                            wd
                            icon
                            variant="secondary"
                            onClick={this.handleCloseContractModal}
                            style={{ float: 'left' }}
                        >
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default ContractsForm;
