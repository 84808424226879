import { observable, action } from 'mobx';
import moment from 'moment/min/moment-with-locales';

// import getBase64 from '~/utils/getBase64';
import queryString from 'query-string';
import { dataURItoBlob } from 'react-jsonschema-form/lib/utils';

import agent from '../agent';

import userStore from './userStore';

class SubscriptionStore {
    @observable currentList = [];

    @observable currentEntity = {};

    @observable updatingErrors = null;

    @observable updating = false;

    @observable loading = false;

    @observable requestParams = null;

    @observable deleteSuccess = false;

    @observable lastListLoadTime = null;

    @action resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }
    @observable filters = {
        status: '',
        name: '',
    };

    @observable appliedFilters = {
        status: '',
        name: '',
    };

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    @action loadList(params) {
        return agent.subscriptions.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    @action save(values, isAdd) {
        this.updating = true;
        return agent.subscriptions.save(values, isAdd)
            .then(
                action(data => {
                    this.currentEntity = data;
                    return data;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action load(id) {
        this.loading = true;
        return agent.subscriptions.load(id)
            .then(
                action(response => {
                    this.currentEntity = response
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action async remove(id) {
        await agent.subscriptions.remove(id);
        this.deleteSuccess = true;
        this.resetLastListLoadTime()
        return 1;
    }
}

export default new SubscriptionStore();