import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Modal } from 'react-bootstrap';

import Button from '../../../components/CustomButton/CustomButton.jsx';
import ModalEditor from '../Timelogs/ModalAccept';


import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import SubscriptionsFilter from './subscriptionsFilter'
import { shortDateFormat } from '~/utils/dateFormat'

@inject('userStore', 'subscriptionStore', 'commonStore')
@withRouter
@withLocalization
@observer
class SubscriptionsList extends Component {
    state = {
        selectedReminder: {},
        showSubscriptionModal : false
    };

    handleTableButtonAction = (id, type) => {
        this.props.onTableAction(id, type);
    };

    viewSubscriptionModal = (obj) => {
        this.setState({ selectedReminder : obj });
        this.renderSubscriptionModel();
    };

    renderSubscriptionModel = () => {
        this.setState({
            showSubscriptionModal : true
        });
    };

    handleClosesSubscriptionModal = () => {
        this.setState({
            showSubscriptionModal : false
        });
    }
    renderTableActionButtons = (id, full) => {
        const { t } = this.props;
        return (
            <div className="actions-center">
                <Button onClick={() => this.viewSubscriptionModal(full)} bsStyle="warning" simple icon>
                    <i className="fa fa-bell" />
                </Button>
                <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                    <i className="fa fa-edit" />
                </Button>
                <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                    <i className="fa fa-times" />
                </Button>
            </div>
        );
    };


    render() {
        const { t } = this.props;
        const {
            loading, filters, appliedFilters, lastListLoadTime
        } = this.props.subscriptionStore;
        const { user_type } = this.props.userStore.currentUser;
        const { config } = this.props.commonStore;
        const defaultData = [{ days : '7' }]
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        const subscriptionRules = config.client && config.client.data && config.client.data.extendedRules && config.client.data.extendedRules.subscriptionRules ? config.client.data.extendedRules.subscriptionRules : defaultData; 
        let dates = [];
        let nDates = []
        subscriptionRules.forEach(element => {
            dates.push(moment(this.state.selectedReminder.expiry_date).subtract(element.days, 'days'))
            nDates = (dates.sort(function(a, b){return a-b}))
        });

        return (
            <Fragment>
                <div className="table-list">
                    <div className="table-list__header">
                        <Button fill wd icon onClick={() => this.props.onAdd()}>
                            {this.props.t('Add new')} <i className="fa fa-plus" />
                        </Button>
                    </div>
                    <GenericList
                        columns={[
                            {
                                Header: 'ID',
                                id: 'id',
                                sortable: true,
                                accessor: 'id',
                            },
                            {
                                Header: 'Name',
                                id: 'name',
                                sortable: true,
                                accessor: 'name',
                            },
                            user_type === 'member'
                                ? null
                                : {
                                    Header: 'Member',
                                    id: 'last_name',
                                    accessor: ({ first_name, last_name }) => (
                                        <span>{`${first_name} ${last_name}`}</span>
                                    ),
                                },
                            {
                                Header: 'Expiry date',
                                id: 'expiry_date',
                                accessor: ({ expiry_date }) => shortDateFormat(expiry_date, dateTimeRules),
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                sortable: false,
                                accessor: full => this.renderTableActionButtons(full.id, full),
                            },
                        ]}
                        filters={appliedFilters}
                        lastListLoadTime={lastListLoadTime}
                        header={(
                            <SubscriptionsFilter
                                filters={filters}
                                setFilter={(name, value) => this.props.subscriptionStore.setFilter(name, value)}
                                onFilter={() => this.props.subscriptionStore.onFilter()}
                                loading={loading}
                            />
                        )}
                        requestData={params => this.props.subscriptionStore.loadList(params)}
                    />
                </div>
                <Modal
                    className="modal fade"
                    show={this.state.showSubscriptionModal}
                    onHide={this.handleClosesSubscriptionModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Reminders')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <h5>{t('Upcoming Reminders for this License/Documents')}</h5>
                        {nDates.map(date =>
                            <ul>
                                <li>{shortDateFormat(date, dateTimeRules)}</li>
                            </ul>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClosesSubscriptionModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}
export default SubscriptionsList;
