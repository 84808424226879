import React, { Component } from 'react';
import { autorun } from 'mobx';
// Components
import { inject, observer } from 'mobx-react';

import AuthNavbar from '../components/Navbars/AuthNavbar.jsx';
import NotificationSystem from 'react-notification-system';
import { style } from '~/variables/Variables';

@inject('userStore', 'authStore', 'commonStore')
@observer
class PrimaryLayout extends Component {

    mobxHook = autorun(() => {
        const { commonStore } = this.props;
        if (commonStore.PendingNotifications.length > 0) {
            commonStore.PendingNotifications.forEach(pn => {
                if (this.refs.notificationSystem) this.refs.notificationSystem.addNotification(pn);
            });
        }
    });

    componentWillMount() {
        if (document.documentElement.className.indexOf('nav-open') !== -1) {
            document.documentElement.classList.toggle('nav-open');
        }
    }

    handleLogOut = () => {
        this.props.authStore.logout();
    };

    render() {
        const { currentUser } = this.props.userStore;
        const { commonStore, refsForScroll } = this.props;
        return (
            <div className="primary-layout">
            <NotificationSystem ref="notificationSystem" style={style} />
                <AuthNavbar currentUser={currentUser} handleLogOut={this.handleLogOut} refsForScroll={refsForScroll} />
                <div className="primary-layout__content">
                    {this.props.children}
                    {commonStore && commonStore.connectionError && false && (
                        <div
                            className="primary-layout__conn-err"
                            style={{ background: 'red', padding: 20, textAlign: 'center' }}
                        >
                            ERROR IN CONNECTION
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default PrimaryLayout;
