import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormControl } from 'react-bootstrap';
import { filter, find } from 'lodash';
import config from '~/library/config';
import agent from '~/library/agent';

import Button from '../../../../components/CustomButton/CustomButton.jsx';
import Select from '../../../../elements/Select';
import withLocalization from '../../../../hoc/withLocalization';

@inject('commonStore')
@withLocalization
@observer
class ClientsListFilter extends Component {
    
    printExcelReport = async () => {
        const { filters, t } = this.props;
        let hydratedPayload= {
            filters:filters,                     
            authorization: this.props.commonStore.token
        }
        window.open(`${config.API_ROOT}/clients/report/${agent.convertToGetParams(hydratedPayload)}`);        
    }

    render() {
        const {
            filters, setFilter, onFilter, loading, t
        } = this.props;
        const { biztype, name, status } = filters;
        const { biztypes } = this.props.commonStore.config;
        const options = biztypes.map(b => {
            return { value: `${b.id}`, label: b.name };
        });
        options.unshift({ value: '', label: t('All') });
        const currentSelect = find(options, o => {
            return o.value == biztype;
        });        
        const statusOptions = [{value:'active',label:t('Active')},{value:'inactive',label:t('Inactive')}];
        statusOptions.unshift({ value: '', label: t('All statuses') });
        const currentStatus = find(statusOptions, o => {
            return o.value == status;
        })
        return (
            <div className="list-filters">
                <div className="list-filters__item">
                    <Select
                        options={options}
                        value={currentSelect}
                        disabled={loading}
                        onChange={id => {setFilter('biztype', id.value);
                                    onFilter();}}
                        style={{ width: 150 }}                        
                    />
                </div>
                <div className="list-filters__item">
                    <Select
                        options={statusOptions}
                        value={currentStatus}
                        disabled={loading}
                        onChange={id => {setFilter('status', id.value);
                                        onFilter();}}
                        style={{ width: 150 }}                        
                    />
                </div>
                <div className="list-filters__item list-filters__item_grow">
                    <FormControl
                        placeholder={t('Search')}
                        type="text"
                        onChange={e => setFilter('name', e.target.value)}                        
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onFilter();
                            }
                        }}
                        value={name}                        
                    />
                </div>            
                <div className="list-filters__item list-filters__item_to-right">
                    <Button fill wd onClick={() => onFilter()} disabled={loading}>
                        {t('Filter')}
                    </Button>
                    <Button fill onClick={() => this.printExcelReport()} disabled={loading} simple icon>
                        <i className="fa fa-print" />
                    </Button>
                </div>                
            </div>
        );
    }
}

export default ClientsListFilter;
