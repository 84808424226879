import React, { Component } from 'react';
import Button from '../../../components/CustomButton/CustomButton';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import { serverTimeFormat } from '~/library/core';

import { shortDateFormat } from '~/utils/dateFormat'
import withLocalization from '~/hoc/withLocalization';
import MonitoringTable from './MonitoringTable'
import LoadingSpinner from '../../../elements/LoadingSpinner';
// import customerFeature from '~/utils/customerFeature';

@inject('monitorStore', 'userStore', 'commonStore')
@withLocalization
@withRouter
@observer
class MonitoringGrid extends Component {

    state = {
        from: moment(new Date()).startOf('month').toDate(),
        to: moment(new Date()).toDate(),
        monitor: [],
        fetching: true
    };

    componentDidMount() {
        const { id } = this.props;
        this.onLoadData(id)
    }

    onGridSave = async (redirect) => {
        const { monitorStore } = this.props;
        const name = monitorStore.currentEntity && monitorStore.currentEntity.name;
        const unit = monitorStore.currentEntity && monitorStore.currentEntity.unit;
        const project_id = monitorStore.currentEntity && monitorStore.currentEntity.project_id;
        const monitor_id = monitorStore.currentEntity && monitorStore.currentEntity.id;
        const id = monitorStore.currentGridDataId; 
        const metadata = _.cloneDeep(monitorStore.tableMeta);
        const tableListData = _.cloneDeep(monitorStore.tableListData);


        if (monitorStore.tableListData.length) {
            const data = tableListData.map((tableData) => {
                delete tableData.meta;
                return tableData;
            });
            await monitorStore.onGridSave({
                name,
                project_id,
                unit,
                monitor_id,
                metadata,
                data,
                id
            })
            if(!redirect){   
                 this.props.changeMode("view");
            }
            
        
        }
    }

    onLoadData = (id) => {
        const { monitorStore } = this.props;
        id = id || monitorStore.currentEntity.id;
        if (id) {
            monitorStore.onGridUpdate(id).then((response) => {
                this.setState({ fetching: false });
             })
        }
    }


    render() {
        const { t, monitorStore } = this.props;
        const { config } = this.props.commonStore;
        let date = '';
        let Mname = '';
        let projectName = "";
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        date = shortDateFormat(moment(new Date), dateTimeRules)
        Mname = monitorStore.currentEntity.name
        projectName = monitorStore.currentEntity.project_name
        if (this.state.fetching){
            return <LoadingSpinner />
        }
        return (
            <div className='monitoring-grid-container'>
                <div className='grid-title'>
                    <h5>{t('CURRENT MONITORING')}</h5>
                    <div>
                        <button className="btn margin__right" onClick={values => this.props.onFormCancel()}>{t('Cancel')}</button>
                        <button className="btn btn-fill" onClick={values => this.onGridSave()}>{t('Save')}</button>
                    </div>
                </div>
                <div className='sub-history-container' style={{ marginTop: "-10px" }}>
                    <div className='table-content'>
                        <table className='filter-table'>
                            <thead>
                                <tr>
                                    <th>{t('Date')}</th>
                                    <th>{t('Name')}</th>
                                    <th>{t('Project')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{date}</td>
                                    <td>{Mname}</td>
                                    <td>{projectName}</td>
                                </tr>
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </div>
                </div>
                <MonitoringTable onGridSave={this.onGridSave} view={false} styling='auto'/>
                <button style={{ float: 'right', width: '100px', marginTop: "-30px" }} className="btn btn-fill " onClick={values => this.onGridSave()}>{t('Save')}</button>
                <button style={{ float: 'right', width: '100px', marginTop: "-30px" }} className="btn margin__right" onClick={values => this.props.onFormCancel()}>{t('Cancel')}</button>
            </div>
        );
    }
}

export default MonitoringGrid;
