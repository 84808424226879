export const countryOptions = [
    {value: '', label: 'All'},
    { value: 'United States', label: 'United States' },
    { value: 'Russia', label: 'Russia' },
    { value: 'Greek', label: 'Greek' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Poland', label: 'Poland' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'Lithuania', label: 'Lithuania' },
];
export const moduleOptions = [
    {value:'project',label:'project'},
    {value:'user',label:'user'},
    {value:'document',label:'document'}
];

export const optionStatus = [
    { value: '', label: 'All', title: 'All' },
    { value: 'draft', label: 'Draft', title: 'Draft' },
    { value: 'pending', label: 'Pending', title: 'Pending' },
    { value: 'completed', label: 'Completed', title: 'Completed' },
    { value: 'expired', label: 'Expired', title: 'Expired' }
]

