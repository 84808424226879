import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Button from '../../../components/CustomButton/CustomButton.jsx';
import SwitchWidget from '../ControlPanel/SwitchWidget.js';
import MultiSelect from '@khanacademy/react-multi-select';

import withLocalization from '~/hoc/withLocalization';
import LoadingSpinner from '~/elements/LoadingSpinner';


@inject('commonStore','taskStore')
@withRouter
@withLocalization
@observer
class ProjectTaskCloneForm extends Component {
    state = {
        taskOptions: [],
        memberOptions:[],
        selectedMemberIds:[],
        selectedTasksIds:[],
        is_copy_address: true,
        is_loading:true,
        save_btn:false
    };

    async loadData() {
        const { id, taskStore, commonStore, t} = this.props;
        await taskStore.loadTasksForCloning(id).then(
            res => {
                if(res.tasks){
                    const taskOptions = this.prepareOptionsArr(res.tasks);
                    const memberOptions = this.prepareOptionsArr(res.projectMembers);
                    // console.log("00000000-----------000000000",taskOptions,memberOptions);
                    this.setState({taskOptions,memberOptions,is_loading:false});
                }
            }
        ).catch(
            err => {
                commonStore.addNotification(t(err), null, 'error');
            }
        );
    }

    componentDidMount() {
        this.loadData();
    }

    prepareOptionsArr(arr){
        let temp = [];
        if(arr.length>0){
            temp = arr.map(obj=>{
                return { value: `${obj.id}`, label: `${obj.name}` };
            })
        }
        return temp;
    }

    async onSave() {
        const { is_copy_address, selectedMemberIds, selectedTasksIds} = this.state;
        const { commonStore, t, afterSave, taskStore} = this.props;

        if(selectedTasksIds.length == 0){
            this.setState({save_btn:false});
            return commonStore.addNotification(t('Please select tasks to assign'), null, 'error');
        }
        else{
            let data = {
                is_copy_address:is_copy_address,
                selectedMemberIds:selectedMemberIds,
                selectedTasksIds:selectedTasksIds,
                id:this.props.id
            }        

            await taskStore.cloneTasks(data).then(
                res => {
                    if(res.status =='ok') {
                        afterSave();
                        return commonStore.addNotification(t('Saved'), null, 'success');
                    }
                }
            ).catch(err=>{
                return commonStore.addNotification(t(err), null, 'error');
            })
        }
    }
    
    render() {
        const {
            memberOptions, taskOptions, selectedMemberIds, selectedTasksIds, 
            is_copy_address, is_loading, save_btn
        } = this.state;
        const { id, t } = this.props;        
        if ( !id || is_loading) {
            return <LoadingSpinner />;
        }

        return (
            <div>
                <div className='d-flex justify-content-space-between align-items-center mb-10 mt-10'>  
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                        <MultiSelect                            
                            options={taskOptions || []}
                            selected={selectedTasksIds || []}
                            onSelectedChanged={id => this.setState({selectedTasksIds:id})}
                            overrideStrings={{
                                selectSomeItems: t('Select Tasks') + '...',
                                allItemsAreSelected: t('All tasks are selected'),
                                selectAll: t('Select All'),
                                search: t('Search'),
                            }}
                         />
                    </div>
                </div>
                <div className='d-flex justify-content-space-between align-items-center mb-10 mt-10'>  
                    <div className='col-md-8 col-sm-8 col-xs-8'>                        
                        <MultiSelect                            
                            options={memberOptions || []}
                            selected={selectedMemberIds || []}
                            onSelectedChanged={id=> this.setState({selectedMemberIds:id})}
                            overrideStrings={{
                                selectSomeItems: t('Select Members') + '...',
                                allItemsAreSelected: t('All members are selected'),
                                selectAll: t('Select All'),
                                search: t('Search'),
                            }}
                        />     
                    </div>                  
                    <div className='col-md-4 col-sm-4 col-xs-4 d-flex'>
                        <SwitchWidget 
                            value={is_copy_address} 
                            onChange={checked => this.setState({is_copy_address:checked})}
                        />
                        <label className="control-label ml-10">{t('Copy Project Address')}</label>
                    </div>                                      
                </div> 
                <div className='d-flex justify-content-end'>
                    <Button onClick={() => this.setState({save_btn:true},()=> this.onSave())} disabled={save_btn}>
                        {t('Save')}
                    </Button> 
                </div>               
            </div>
        );
    }
}

export default ProjectTaskCloneForm;
