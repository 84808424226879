import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';

import customerFeature from '../../../../utils/customerFeature';
import { serverTimeFormat } from '~/library/core';
import '../../../../styles/css/styles.css';
import { shortDateFormat } from '~/utils/dateFormat'

// Components
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import { Modal } from 'react-bootstrap';
// import ElementComponent from '../elementComponent';
import GenericList from '~/components/GenericList/GenericList';
import { confirmAlert } from 'react-confirm-alert';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import ElementFilter from './ElementFilter';

import ElementTreeView from './ElementTreeView';
import { Editor } from '@tinymce/tinymce-react';
import { Input, Textarea } from '../../../../elements/Input';

import AttachmentsWidget from '../../../../components/GenericForm/Widgets/AttachmentsWidget';
import Async from 'react-select/async';
import { element } from 'prop-types';
@inject('checklistStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ElementList extends Component {
    state = {
        // elementarray: [],
        showaddelement: false,
        showElemntModal: false,
        currentmodelelement: null,
        currentmodelelementarray: [],
        mode: '',
        name: '',
        comment: '',
        attachments:'',
        sub: false,
        parentElement: null,
        isSubCategory: false,
        showElemntCopyModal: false,
        copyElement: null,
        newCopyElementname:''
    };

    // componentDidMount() {
    //     this.loadelement();
    // }

    // loadelement = async () => {
    //     await this.props.checklistStore.listelementbyclientid().then((list) => {
    //         this.setState({ elementarray: list.elements });
    //     });
    // };

    componentWillMount() {
        this.props.checklistStore.setElementFilter('name', '');
        this.props.checklistStore.onElementFilter();
    }

    Addelementbutton = () => {
        let x = !this.state.showaddelement;
        this.setState({ showaddelement: x });
    };

    closeaddelement = () => {
        this.setState({ showaddelement: false });
    };

    renderTableActionButtons = (id, full) => {
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');

        return (
            <div className="actions-center">
                <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                    <i className="fa fa-edit" />
                </Button>
                <Button onClick={() => this.handleTableButtonAction(id, 'delete')} bsStyle="warning" simple icon>
                    <i className="fa fa-trash" />
                </Button>
                <Button onClick={() => this.handleTableButtonAction(id, 'copy',full)} bsStyle="warning" simple icon>
                    <i className="fa fa-copy" />
                </Button>
            </div>
        );
    };

    handleTableButtonAction = async (id, type, full) => {
        // this.props.onTableAction(id, type);
        if (type === 'edit') {
            const { history } = this.props;
            let reqData = {
                parent_id: id,
            };

            // await this.props.checklistStore.getElementById(id).then(async (element) => {
                // if (id != 0) {
                //     reqData.isParent = true;
                // }
                await this.props.checklistStore.getElementChildByParentId(reqData).then((element) => {
                    this.setState({
                        currentmodelelement: element.elements,
                        currentmodelelementarray: element.elements.elements,
                        mode: 'Edit Main Catogary',
                        name: element.elements.name,
                        comment: element.elements.comment,
                        attachments: element.elements.attachments
                    }, () => {
                        this.renderAttachments();
                    });
                    this.renderElementModal();
                });
            // });
        } else if (type === 'add') {
            const { history } = this.props;
            // if(this.state.mode == "Add Main Catogary"){
            //     this.setState({mode: 'Add Sub Category' });
            // } else {
                this.setState({ mode: 'Add Main Catogary' });
            // }
            this.setState({
                currentmodelelement: null,
                name: '', 
                comment: '',
                attachments: '',
                showElemntModal: true,
                sub: this.state.sub
            });
        } else if (type === 'delete') {
            const str = `Are you sure , You want to delete ?`;
            confirmAlert({
                title: this.props.t('Confirm to delete'),
                message: str,
                buttons: [
                    {
                        label: this.props.t('Yes'),
                        onClick: () => {
                            return this.props.checklistStore.deleteElementById(id).then((res2) => {
                                this.props.checklistStore.resetLastListLoadTime();
                                this.props.commonStore.addNotification(this.props.t('Deleted'), null, 'success');
                                //this.loadelement();
                                return res2;
                            });
                        },
                    },
                    {
                        label: this.props.t('No'),
                        onClick: () => {},
                    },
                ],
            });
        } else if (type === 'copy') {
            this.setState({
                copyElement: full
            }, () => {
                this.renderElementCopyModal();
            } )
            // let reqData = {
            //     parent_id: id,
            // };

            // await this.props.checklistStore.copyElementById(reqData).then((element) => {
            //     this.props.checklistStore.resetLastListLoadTime();
            //     this.props.commonStore.addNotification(this.props.t('Copied'), null, 'success');
            // });
        }
    };

    renderElementModal = () => {
        // const { t } = this.props;
        this.setState({
            showElemntModal: true,
            sub: this.state.sub
        });
        if(this.state.currentmodelelement && this.state.currentmodelelement.name){
            this.setState({
                name: this.state.currentmodelelement.name,
                comment: this.state.currentmodelelement.comment,
                attachments: this.state.currentmodelelement.attachments
            });
        }
    };

    handleCloseElemntModal = () => {
        this.setState({ name: '', comment: '', sub: false,attachments: '' });
        this.setState({
            showElemntModal: false,
        });
        this.props.checklistStore.resetLastListLoadTime();
    };
    
    renderElementCopyModal = () => {
        // const { t } = this.props;
        this.setState({
            showElemntCopyModal: true,
            newCopyElementname: this.state.copyElement.name
        });
    };

    handleCloseElemntCopyModal = () => {
        this.setState({
            showElemntCopyModal: false,
        });
        this.props.checklistStore.resetLastListLoadTime();
    };

    copyElementSave = async () => {
        if(this.state.newCopyElementname == "" || this.state.newCopyElementname == null){
            this.props.commonStore.addNotification(this.props.t('Please fill Name.'), null, 'error');
            return;
        }
        let reqData = {
            parent_id: this.state.copyElement.id,
            name:this.state.newCopyElementname
        };

        await this.props.checklistStore.copyElementById(reqData).then((element) => {
            this.props.commonStore.addNotification(this.props.t('Copied Successfully'), null, 'success');
            this.handleCloseElemntCopyModal();
        });

    }

    handleChange = (name, value) => {
        if(name=="name"){
            this.setState({ name: value });
        } else if(name =="comment" ){
            this.setState({ comment: value });
        } else if(name == "newCopyElementname"){
            this.setState({ newCopyElementname: value });
        }
    };

    saveParentElement = async () => {
        if(this.state.currentmodelelement != null){
            this.setState({ mode: 'Edit Main Catogary'}, 
            () =>{
                this.addelementclick();
            });
        } else {
            this.setState({ mode: 'Add Main Catogary'}, 
            () =>{
                this.addelementclick();
            });
        }
    }

    handleAddsubelement = async () => {
        if (this.state.mode == "Add Main Catogary" || this.state.mode == "Edit Main Catogary") {
            if (this.state.name == undefined || this.state.name == '') {
                this.props.commonStore.addNotification(this.props.t('Please fill Name.'), null, 'error');
                return;
            }
            this.setState({ mode: 'Add Sub Category', isSubCategory: true });
        }
        await this.addelementclick();
        if (this.state.currentmodelelement && this.state.currentmodelelement.id > 0) {
            this.setState({
                currentmodelelement: this.state.currentmodelelement,
                currentmodelelementarray: this.state.currentmodelelement.elements,
                sub: true,
                name: '',
                comment: '',
                attachments: ''
            });
        }
    };

    addelementclick = async () => {
        if (this.state.mode.toLowerCase().includes('add')) {
            let data = {};
            data = {
                name: this.state.name,
                parent_id: 0,
                comment: this.state.comment,
                attachments: this.state.attachments
            };
            if(this.state.mode == "Add Main Catogary" && this.state.currentmodelelement && this.state.currentmodelelement.id > 0){
                data.id = this.state.currentmodelelement.id;
            }
            if (data.name == undefined || data.name == '') {
                this.props.commonStore.addNotification(this.props.t('Please fill Name.'), null, 'error');
                return;
            }
            if (this.state.currentmodelelement && this.state.currentmodelelement.id > 0 && this.state.sub == true) {
                data.parent_id = this.state.currentmodelelement.id;
            }
            await this.props.checklistStore.addelement(data).then(async (data) => {
                this.props.commonStore.addNotification(this.props.t('Saved'), null, 'success');
                if(data != undefined) { 
                var addedElement = JSON.parse(JSON.stringify(data));
                if (data.parent_id == 0) {
                    addedElement.elements = [];
                    this.setState({ currentmodelelement: addedElement });
                } else {
                    let reqData = {
                        parent_id: data.parent_id,
                    };

                    // await this.props.checklistStore.getElementById(data.parent_id).then(async (element) => {
                        // if (element.elements[0].parent_id != 0) {
                        //     reqData.isParent = true;
                        // }
                        await this.props.checklistStore.getElementChildByParentId(reqData).then((element) => {
                        this.setState({
                            currentmodelelement: element.elements,
                            currentmodelelementarray:element.elements.elements,
                            name: element.elements.name,
                            comment: element.elements.comment,
                            attachments: element.elements.attachments,
                            isSubCategory: false,
                            mode: "Edit Main Catogary"
                        }, () => {
                            this.renderAttachments();
                        });
                        // });
                    });
                }
                // this.handleCloseElemntModal();

                // this.loadelement();
            }
            });
        } else if (this.state.mode.toLowerCase().includes('edit')) {
            let data = this.state.currentmodelelement;
            data.name = this.state.name;
            data.comment = this.state.comment;
            data.attachments= this.state.attachments;
            await this.props.checklistStore.addelement(data).then((data) => {
                this.props.commonStore.addNotification(this.props.t('Updated'), null, 'success');
                // this.handleCloseElemntModal();
                // this.loadelement();
            });
        }
    };

    handleEditorChange = (e) => {
        this.setState({ comment: e.target.getContent() });
    };

    renderAttachments = (e) => {
        const { checklistStore } = this.props;
        var dataReq = {
            attachmentIds: this.state.attachments
        }
        checklistStore.getMultipleElements(dataReq).then((elementsResult) => {
            this.setState({ attachmentsList: elementsResult.attachments })
        });
    };

    refreshparentmodel = async () => {
        let reqData = {
            parent_id: this.state.currentmodelelement.id,
        };
        // await this.props.checklistStore.getElementById(this.state.currentmodelelement.id).then(async (element) => {
            await this.props.checklistStore.getElementChildByParentId(reqData).then(async (elementData) => {
                this.setState({
                    currentmodelelement: elementData.elements,
                    currentmodelelementarray: elementData.elements.elements,
                    mode: 'Edit Main Catogary',
                    name: elementData.elements.name,
                    comment: elementData.elements.comment,
                    attachments: elementData.elements.attachments
                }, () => {
                    this.renderAttachments();
                });
                this.handleCloseElemntModal();
                setTimeout(() => {
                    this.renderElementModal();
                }, 400);
            // });
        });
    };

    render() {
        const {  t, isUnionMode } = this.props;
        const { loading, elementFilters, elementAppliedFilters } = this.props.checklistStore;
        const {config} = this.props.commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        return (
            <div>
                <div>
                    <div className="table-list__header">
                        <Button fill wd icon onClick={() => this.handleTableButtonAction(0, 'add')}>
                            {this.props.t('Add')} <i className="fa fa-plus" />
                        </Button>
                    </div>
                    <GenericList
                        columns={[
                            // {
                            //     Header: 'Id',
                            //     id: 'id',
                            //     sortable: true,
                            //     accessor: 'id',
                            // },
                            {
                                Header: 'Name',
                                id: 'name',
                                sortable: true,
                                accessor: 'name',
                            },
                            {
                                Header: 'Date',
                                id: 'created_at',
                                accessor: ({ created_at }) => (
                                    <span>{created_at && shortDateFormat(created_at, dateTimeRules)}</span>
                                ),
                            },
                            {
                                Header: 'User',
                                id: 'user_name',
                                accessor: ({ first_name, last_name }) => (
                                    <span>{`${first_name}` + ' ' + `${last_name}`}</span>
                                ),
                                sortable: false,
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                sortable: false,
                                accessor: (full) => this.renderTableActionButtons(full.id, full),
                            },
                        ]}
                        forceReload={this.props.checklistStore.deleteSuccess || this.props.checklistStore.updated}
                        filters={elementAppliedFilters}
                        lastListLoadTime={this.props.checklistStore.lastListLoadTime}
                        header={
                            <ElementFilter
                                filters={elementFilters}
                                setFilter={(name, value) => this.props.checklistStore.setElementFilter(name, value)}
                                onFilter={() => this.props.checklistStore.onElementFilter()}
                                loading={loading}
                            />
                        }
                        requestData={(params) => this.props.checklistStore.getElementTemplateByClientId(params)}
                    />
                </div>

                <Modal
                    className="modal right fade"
                    show={this.state.showElemntModal}
                    onHide={this.handleCloseElemntModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                        {this.state.mode == "Add Main Catogary" ? t("Add main catagory") : ""}
                        {this.state.mode == "Edit Main Catogary" ? t("Edit main catagory") : ""}
                        {this.state.mode == "Add Sub Category" ? t("Add sub catagory") : ""}
                        {this.state.mode == "Edit Sub Catogary" ? t("Edit sub catagory") : ""}
                        {this.state.mode == "Add Checklist" ? t("Add Checklist") : ""}
                        {this.state.mode == "Edit Checklist" ? t("Edit Checklist") : ""}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 130px)', minHeight: '80vh', overflowY: 'auto' }}>
                        <div className="form-group field field-string">
                            {/* <label className="control-label">{this.state.sub == true ? 'Checklist Name' : 'Element Name'}</label> */}
                            {/* <input
                                className="form-control"
                                type="text"
                                name="name"
                                value={this.state.name}
                                placeholder={this.state.sub == true ? 'Checklist Name' : 'Element Name'}
                                onChange={(e) => this.handleChange(e)}
                            /> */}
                            <Textarea
                                label={`${t('Name')} *`}
                                value={this.state.name}
                                style={{ width: '100%' }}
                                name="name"
                                // onChange={(e) => this.handleChange(e)}
                                onChange={(e) => this.handleChange('name', e.target.value)}
                                placeholder={t('Enter Name here')}
                            />
                        </div>
                        <div className="attachment-box" style={{ width: '100%' }}>
                            <span className="attachment-box__label">{t('Upload Files')}</span>
                            <AttachmentsWidget
                                value={this.state.attachments}
                                onChange={attachIds => {
                                    this.setState({
                                        attachments: attachIds
                                    })
                                }}
                                options={{
                                    imageContext: {
                                        existingAttachment: this.state.attachmentsList,
                                        fileType: 'docs',
                                        id: 0,
                                        model: 'Image',
                                    },
                                }}
                            />
                        </div>
                        <br />
                        {/* <label className="control-label">{'Comments'}</label> */}
                        <Textarea
                            label={t('Comment')}
                            value={this.state.comment}
                            style={{ width: '100%' }}
                            name="comment"
                            // onChange={(e) => this.handleChange(e)}
                            onChange={(e) => this.handleChange('comment', e.target.value)}
                            placeholder={t('Enter comments here')}
                        />
                        <div>
                            {/* {this.state.mode.toLowerCase().includes('add') ? null : ( */}
                            <div>
                                <Button variant="primary" onClick={this.addelementclick}>
                                    {t('Save')}
                                </Button>
                            </div>
                            <br/>
                            {t('After adding a Main Category, add minimum one sub category to create checklist')}
                            
                            <br />
                            <div>
                                {(this.state.mode != "Add Sub Category") &&
                                    <Button fill wd icon onClick={this.handleAddsubelement}>
                                        {this.props.t('Add sub catagory')} <i className="fa fa-plus" />
                                    </Button>}
                                {/* <Button fill wd icon onClick={this.handleAddsubelement} style={{marginLeft: '10px'}}>
                                        {this.props.t('Add Checklist')} <i className="fa fa-plus" />
                                    </Button> */}
                            </div>
                            {/* )} */}
                            {this.state.mode.toLowerCase().includes('edit') &&
                            this.state.currentmodelelementarray.length > 0
                                ? this.state.currentmodelelementarray.map((item, index) => (
                                      <ElementTreeView
                                          data={item}
                                          key={item.id}
                                          refresh={this.reloadmodel}
                                          closeparentmodel={this.refreshparentmodel}
                                      />
                                  ))
                                : null}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button variant="primary" onClick={this.addelementclick}>
                            {'Save'}
                        </Button> */}
                        <Button variant="secondary" onClick={this.handleCloseElemntModal}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    className="modal fade"
                    show={this.state.showElemntCopyModal}
                    onHide={this.handleCloseElemntCopyModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                        {t("Copy Element")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group field field-string">
                            <Textarea
                                label={t('Name')}
                                value={this.state.newCopyElementname}
                                style={{ width: '100%' }}
                                name="newCopyElementname"
                                onChange={(e) => this.handleChange('newCopyElementname', e.target.value)}
                                placeholder={t('Enter Name here')}
                            />
                        </div>

                     </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.copyElementSave}>
                            {'Save'}
                        </Button>
                        <Button variant="secondary" onClick={this.handleCloseElemntCopyModal}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ElementList;
