import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import VacationsList from './VacationsList';
import VacationsForm from './VacationsForm';

import withRemoveDialog from '~/hoc/withRemoveDialog';

@inject('authStore', 'vacationStore', 'userStore')
@withRouter
@withRemoveDialog
@observer
class VacationsPage extends Component {
    onAdd() {
        const { history } = this.props;
        history.push('/admin/vacations/add');
    }

    onTableAction(id, type, user_id) {
        const { history } = this.props;
        if (type === 'remove') {
            return this.props.handleDelete(id, id => {
                return this.props.vacationStore.remove(id).then(res => {
                    this.props.vacationStore.resetLastListLoadTime();
                    return res;
                });
            });
        }

        history.push(`/admin/vacations/${id}`);
    }

    editingId = () => {
        let id = null;
        if (this.props.location.pathname !== this.props.match.path) {
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }
        return { editing: !!id, add: id === 'add', id };
    };

    render() {
        const mode = this.editingId();
        const { user_type } = this.props.userStore.currentUser;
        return (
            <div className="main-content">
                {!mode.editing && (
                    <VacationsList
                        onTableAction={(id, type, user_id) => this.onTableAction(id, type, user_id)}
                        onAdd={() => this.onAdd()}
                    />
                )}
                {mode.editing && <VacationsForm id={mode.id} add={mode.add} />}
            </div>
        );
    }
}
export default VacationsPage;
