import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import TimelogsList from './TimelogsList';
import TimelogsForm from './TimelogsForm';

import withRemoveDialog from '~/hoc/withRemoveDialog';

@inject('authStore', 'timelogStore')
@withRouter
@withRemoveDialog
@observer
class TimelogsPage extends Component {
    onAdd() {
        const { history } = this.props;
        history.push('/admin/timelogs/add');
    }

    componentWillMount() {
        const params = new URLSearchParams(this.props.location.search);
        const filterByStatus = params.get('status');
        this.props.timelogStore.setFilter('status', filterByStatus || null);
        this.props.timelogStore.onFilter();
    }

    onTableAction(id, type) {
        const { history } = this.props;
        if (type === 'remove') {
            return this.props.handleDelete(id, id => {
                return this.props.timelogStore.remove(id).then(res => {
                    this.props.timelogStore.resetLastListLoadTime();
                    return res;
                });
            });
        }
        history.push(`/admin/timelogs/${id}`);
    }

    editingId = () => {
        let id = null;
        if (this.props.location.pathname !== this.props.match.path) {
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }
        return { editing: !!id, add: id === 'add', id };
    };

    render() {
        const mode = this.editingId();
        return (
            <div className="main-content">
                {!mode.editing && (
                    <TimelogsList
                        onTableAction={(id, type) => this.onTableAction(id, type)}
                        onAdd={() => this.onAdd()}
                    />
                )}
                {mode.editing && <TimelogsForm id={mode.id} add={mode.add} />}
            </div>
        );
    }
}
export default TimelogsPage;
