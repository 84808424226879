import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

export class StatsCard extends Component {
    render() {
        const {
            statsText, statsValue, bigIcon, statsIconText, withFooter = true, cutText
        } = this.props;
        return (
            <div className="card card-stats">
                <div className="content">
                    <Row>
                        <Col xs={7}>
                            <div className="numbers">
                                <p>{statsText}</p>
                                {statsValue}
                            </div>
                        </Col>
                        <Col xs={5}>
                            <div className="icon-big text-center icon-warning">{bigIcon}</div>
                        </Col>
                    </Row>
                    {withFooter && (
                        <div className="footer">
                            <hr />
                            <div className={`stats ${cutText && 'stats-cut'}`}>{statsIconText || '&nbsp;'}</div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default StatsCard;
