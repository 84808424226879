import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';

import { Input } from '../../../elements/Input';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import RadioWidget from '../../../components/GenericForm/Widgets/RadioWidget';
import { validateNorwegianIdNumber } from '../../../utils/validateNorwayNo';

import withLocalization from '~/hoc/withLocalization';

@inject('userStore', 'commonStore')
@withRouter
@withLocalization
@observer
class AdminAddLimitBeforeAdd extends Component {
    state = { error: null };

    proceed = async () => {
        const { userStore, commonStore, t } = this.props;
        await userStore.increaseUserLimit();
        commonStore.addNotification(t('Request is sent to system administrator'), null, 'success');
    };

    render() {
        const { userStore, t } = this.props;
        const { addPossibility } = userStore;
        const { error } = this.state;

        return (
            <div className="add-user-form">
                <h2>{t('User limit reached')}</h2>
                {t('Currently you have')}&nbsp;{addPossibility.users}&nbsp;{t('users out of')}&nbsp;{addPossibility.max}
                <br />
                <b>{t('Please confirm you want to request 5 more users')}</b>
                <br />
                <br />
                {!!error && <div className="row error">{error}</div>}
                <Button fill wd onClick={() => this.proceed()}>
                    {t('Request and proceed')}
                </Button>
            </div>
        );
    }
}

export default AdminAddLimitBeforeAdd;
