import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../hoc/withLocalization';
import './ReviewSend.css';
import DatePicker from '../../../components/DatePicker/DatePicker';
import moment from "moment";
import { longDateFormat, currencyDecimalSize } from '../../../utils/dateFormat'

@inject('commonStore', 'signatureStore', 'recipientStore')
@withRouter
@withLocalization
@observer
export class ReviewSend extends Component {


    constructor(props) {
        super(props);
        this.state = {
            subjectLength: 100,
            msgLength: 1000,
            expiration: '',
            emailSubject: '',
            caseName: '',
            emailMsg: '',
            reminder: 1,
            maxReminderDay: 89
        }
    }

    handleCaseName = (e) => {
        this.setState({ caseName: e.target.value });
    }
    componentDidMount() {
        const { signatureStore, recipientStore } = this.props
        const caseId = signatureStore.caseId;
        if (caseId) {
            if (!(signatureStore.documentList && signatureStore.documentList.length)) {
                signatureStore.fetchAll(signatureStore.caseId);
            }
            if (!(recipientStore.recipientsList && recipientStore.recipientsList.length)) {
                recipientStore.fetchAllRecipients(signatureStore.caseId);
            }
        }
        signatureStore.openFullScreenMode();
        const defaultDate = moment(new Date()).add(90, 'days');
        const caseName = signatureStore.caseName ? signatureStore.caseName : '';
        this.setState({ expiration: defaultDate.toDate(), caseName: caseName });

    }

    componentWillUnmount() {
        const { signatureStore } = this.props;
        signatureStore.closeFullScreenMode();
    }

    handleDateChange(data) {
        let currentDate = moment(new Date());
        let selectedDate = moment(new Date(data));
        let diff = selectedDate.diff(currentDate, 'days')
        if (diff <= 0) {
            const { commonStore } = this.props;
            commonStore.addNotification(t(`can't select current or past date`), null, 'error');
            return
        }
        this.setState({ expiration: data, maxReminderDay: diff });
    }
    handleReminderChange = (evt) => {
        let value = evt.target.value
        for (let x = 0; x < value.length; x++) {
            const code = value.charCodeAt(x)
            if (!(code >= 48 && code <= 57)) {
                return
            } else {
                if (value > this.state.maxReminderDay) {
                    return
                }
            }
        }

        this.setState({ reminder: value });
    }
    handleEmailChange = (evt) => {
        if (evt.target.name === "subject") {
            const maxCharCount = 100;
            const subject = evt.target.value.trimStart();
            const remainCount = maxCharCount - subject.length;
            if (remainCount <= -1) {
                return
            }
            this.setState({ emailSubject: subject, subjectLength: remainCount })

        } else if (evt.target.name === "message") {
            const maxCharCount = 1000;
            const message = evt.target.value.trimStart();
            const remainCount = maxCharCount - message.length;
            if (remainCount <= -1) {
                return
            }
            this.setState({ emailMsg: message, msgLength: remainCount })
        }
    }
    sendDocNow = () => {
        const { signatureStore, commonStore, history } = this.props;
        if (!this.state.caseName) {
            commonStore.addNotification(`document name can't be blank`, null, 'error');
            return
        }

        const data = {
            caseId: signatureStore.caseId,
            expiry_date: this.state.expiration,
            email_subject: this.state.emailSubject,
            email_message: this.state.emailMsg,
            doc_name: this.state.caseName,
            expiry_reminder:this.state.reminder
        }
        signatureStore.sendDocNow(data).then(() => {
            history.push('/admin/docsuccess');
        })
    }
    render() {
        const { signatureStore, recipientStore, history ,t,commonStore} = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        if (!(signatureStore.documentList && signatureStore.documentList.length)) {
            return <></>;
        }
        return (
            <div className="sendContainer">
                <div className="documentCont">
                    <div className="title">{t('Document')}</div>
                    <div className="list">
                        <span>
                            <input
                                type="text"
                                value={this.state.caseName}
                                onChange={this.handleCaseName}
                            />
                        </span>
                    </div>
                </div>
                <div className="recipientCont">
                    <div className="title">{t('Recipients')}</div>
                    <div className="list">
                        {
                            recipientStore.recipientsList && recipientStore.recipientsList.map((rec) => {
                                return (<React.Fragment key={rec.id}>
                                    <div className="mailTag">{rec.email}</div>
                                </React.Fragment>)
                            })
                        }
                    </div>
                </div>
                <div className="reviewField">
                    <label className="inputLable">{t('Email subject')}</label>
                    <input type='text' name="subject" value={this.state.emailSubject} onChange={this.handleEmailChange} placeholder={this.props.t("Enter Email Subject")}></input>
                    <span className="charCount">{t('Character remaining ')}{this.state.subjectLength}</span>
                </div>
                <div className="reviewField">
                    <label className="inputLable">{t('Email message')}</label>
                    <textarea type="text" name="message" value={this.state.emailMsg} onChange={this.handleEmailChange} placeholder={this.props.t("Enter Email Message")}></textarea>
                    <span className="charCount">{t('Character remaining ')}{this.state.msgLength}</span>
                </div>
                <div className="flexCont reviewField">
                    <div className="expDate">
                        <label className="inputLable">{t('Expiration')}</label>
                        <DatePicker
                            selected={this.state.expiration}
                            onChange={(date) => this.handleDateChange(date)}
                            dateFormat= {longDateFormat(dateTimeRules)}
                        />
                    </div>
                    <div>
                        <label className="inputLable">{t('Remind signers Days before request expiration')}</label>
                        <input type="text" value={this.state.reminder} onChange={this.handleReminderChange} placeholder={this.props.t("Enter Days")}></input>
                        <span className="charCount">{t('Max reminder days')} {this.state.maxReminderDay}</span>
                    </div>
                </div>
                <div className="">
                    <button className="btn btn-fill" onClick={this.sendDocNow}>{t('Send Now')}</button>
                    {/* <button className="btn">{t('Cancel')}</button> */}
                </div>
            </div>
        );
    }
}

export default ReviewSend;
