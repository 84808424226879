import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Select from '../../../elements/Select';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import GenericList from '../../../components/GenericList/GenericList';
import {countryOptions} from '../../../utils/constants';
import ContractFilter from './ContractFilter';
import { serverTimeFormat } from '../../../library/core';
import moment from 'moment';
@inject('userStore','timelogStore','contractStore','documentStore')
@withRouter
@withRemoveDialog
@observer
class ContractsNew extends Component {
    state={
       
    };

    componentDidMount() {}

    contractForm = () => {
        const { history } = this.props;
        history.push('/admin/contractsform');
    };

    handleTableActionButtonAction = (id, type) => {
        const { history } = this.props;
        //handles action button clicks//
        if (type === 'edit') {
            //edit             
            //this.editDocument(id)
            history.push(`/admin/contractsform/${id}`)
        }
        else if (type === 'delete') {
            //delete 
            this.removeDocument(id);
        } else if (type === 'copy') {
            
            this.copyDocument(id);
        }
    };

    removeDocument(id) {
        const { history, commonStore } = this.props;
        return this.props.contractStore.deleteContracts(id).then(response => {
          
            commonStore.addNotification('contracts Status change', null, 'success');
            this.props.contractStore.resetLastListLoadTime();
            
            return response;
        });
    }

    // editDocument(id) {
    //calls parentHandler to redirect to addEditComponent with given Id//
    //     const { history } = this.props;
    //     history.push(`/admin/contractsform/${id}`);
    // }

    editDocument = async (id) => {

        const { listItems} = this.state;
        const { contractStore, commonStore, history } = this.props;
        let data = {
            id: id,
            documentname: id.name,
            modulename : id.module,
            contracttype : id.contract_type_name,
         }
       await contractStore.getContractsById(id,data).then((response) => {
            if (response) {
                commonStore.addNotification('Edit', null, 'success');
            }
            return data;
        });
        history.push(`/admin/contractsform/`);
       
    };



    renderTableActionButtons = (data) => {
        const { user_type } = this.props.userStore.currentUser;
        return (
            <div className="actions-center">
                {(user_type != "member" && user_type != "pm") ?
                    <div style={{paddingLeft:"18px"}}>
                        {((user_type == "super-admin") || (user_type != "super-admin" && !isadmin)) &&
                            <>
                                <Button onClick={() => this.handleTableActionButtonAction(data.id, 'edit')} className="contractButtons">
                                    <i className="fa fa-edit" style={{color:'grey',marginLeft:'-10px'}} />
                                </Button>
                                <Button onClick={() => this.handleTableActionButtonAction(data.id, 'delete')} className="contractButtonsDelete">
                                    <i className="fa fa-times" style={{color:'red',marginLeft:'-10px'}} />
                                </Button>
                            </>
                        }
                    </div> : null}
            </div>
        );
    };

    render() {
        const { userStore, timelogStore, onFilter ,t} = this.props;
        const { filters, appliedFilters, setFilter } = this.props.contractStore;
        const { country } = filters;
        const userType = userStore.currentUser.user_type;
        
        const currentSelect = find(countryOptions, (o) => {
            return o.value === country;
        });
        if (userType != 'super-admin') {
            return <div>{t('Not Allowed')}</div>;
        }

        return (
            
            //   <div>Random Text</div>
            <div className="main-content" style={{ padding: '20px' }}>
            
                <h6 style={{ fontWeight: 'bolder', paddingLeft: '10px' }}>{t('Select Country')}</h6>

                <div className="table-list__header_contractsnew" style={{ marginTop: '100px',position:'sticky' }}>
                    <h6 style={{ fontWeight: 'bolder', paddingLeft: '5px' }}>{t('Contracts')}</h6>
                    <Button fill wd icon onClick={this.contractForm}>
                        {t('Add New Contract')}
                    </Button>
                </div>
                <GenericList
                        columns={[
                            {
                                Header: 'Date',
                                id: 'for_date',
                                sortable: true,
                              //  accessor: 'for_date'
                                accessor: ({ for_date }) => (
                                <span>{for_date ? moment(for_date).format(serverTimeFormat()) : ''}</span>
                            ),
                            },
                            {
                                Header: 'Country',
                                id: 'country',
                                sortable: true,
                                accessor: 'country'
                            },
                            {
                                Header: 'Document Name',
                                id: 'name',
                                accessor : 'name'
                            },
                            {
                                Header: 'Contract Type',
                                id: 'contract_type_name',
                                sortable: true,
                                accessor : 'contract_type_name'
                            },
                            {
                                Header: 'Module Name',
                                id: 'module',
                                sortable: false,
                                accessor : 'module'
                            },
                            
                            {
                                Header: 'Status',
                                id: 'status',
                                sortable: true,
                                accessor : 'status'
                            },
                            {
                                Header: 'Total Used',
                                id: 'total_used',
                                accessor : 'total_used'
                              
                            },
                            {
                                Header: 'Action',
                                id: 'actions',
                                sortable: false,
                                accessor : (data) => this.renderTableActionButtons(data)
                            },
                        ]}
                        filters={appliedFilters}                    
                     lastListLoadTime={this.props.contractStore.lastListLoadTime}
                    header={(
                        <ContractFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.contractStore.setFilter(name, value)}
                            onFilter={() => {
                                this.props.contractStore.onFilter();
                                
                            }}
                        />
                    )}
                    requestData={(params) => this.props.contractStore.getContracts(params)}
                   
                />
            </div>
            
            
        );
    }
}
export default ContractsNew;