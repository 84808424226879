import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

// React Table
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import SelectTable from 'react-table';

// Components
import TablePagination from './TablePagination';

import withLocalization from '~/hoc/withLocalization';

@withLocalization
class GenericList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: {},
            selectAll: 0,
            data: [],
            pages: null,
            sort: '',
            loading: true,
            lastLoad: null,
            currentPage:props.page?props.page:0
        };
        this.fetchData = this.fetchData.bind(this);
        this.toggleRow = this.toggleRow.bind(this);
    }

    fetchData(state) {
        let _currentPage = state.page;
        if(this.props.isPageAddedOrEdited != undefined && this.props.isPageAddedOrEdited == false){                        
            if(this.props.handlePageChange)
                this.props.handlePageChange(state.page,false); 
        }
        
        if(this.props.isPageAddedOrEdited != undefined && this.props.isPageAddedOrEdited == true){
            _currentPage = this.props.page;
            if(this.props.handlePageChange)
                this.props.handlePageChange(_currentPage,false);
        }
        
        let { filters } = this.props;
        if (state.forceFilters) filters = state.forceFilters;
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        this.props
            .requestData({
                pagesize: state.pageSize,
                page: _currentPage,
                sort: state.sorted,
                // React Table default filter
                filters,
            })
            .then((res) => {
                // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
                this.setState({
                    selected: {},
                    lastListLoadTime: res.time,
                    selectAll: 0,
                    data: res.rows,                    
                    pages: res.pages,   
                    currentPage:_currentPage,                               
                    sort: state.sorted,
                    pagesize: state.pageSize,
                    loading: false,
                });
            });
    }

    toggleRow(id) {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[id] = !this.state.selected[id];
        this.setState({
            selected: newSelected,
            selectAll: 2,
        });
        if (this.props.onSelectionChanged) this.props.onSelectionChanged(newSelected);
    }

    toggleSelectAll() {
        const newSelected = {};

        if (this.state.selectAll === 0) {
            this.state.data.forEach((x) => {
                newSelected[x.id] = true;
            });
        }

        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0,
        });
        if (this.props.onSelectionChanged) this.props.onSelectionChanged(newSelected);
    }

    checkboxCol() {
        return {
            id: 'checkbox',
            accessor: '',
            Cell: ({ original }) => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={this.state.selected[original.id] === true}
                        onChange={() => this.toggleRow(original.id)}
                    />
                );
            },
            Header: (x) => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={this.state.selectAll === 1}
                        ref={(input) => {
                            if (input) {
                                input.indeterminate = this.state.selectAll === 2;
                            }
                        }}
                        onChange={() => this.toggleSelectAll()}
                    />
                );
            },
            sortable: false,
            width: 45,
        };
    }

    translateColumns() {
        const { columns, t } = this.props;
        let _columns = [];
        columns.forEach((c) => {
            if (c && !c.hidden) _columns.push(c);
        });
        _columns = _columns.map((column) => {
            const { Header, ...cols } = column;
            return { Header: t(Header), ...cols };
        });
        if (this.props.allowCheckboxes) _columns.unshift(this.checkboxCol());
        return _columns;
    }

    shouldComponentUpdate(nextProps, nextState) {           
        const prevProps = this.props;             
        // console.log("CheckCurrent Page :",this.state.currentPage,nextState,nextProps,prevProps);     
        if (
            JSON.stringify(nextProps.filters) != JSON.stringify(prevProps.filters) ||
            (this.state.lastListLoadTime &&
                !this.state.loading &&
                !nextState.loading &&
                nextProps.lastListLoadTime &&
                this.state.lastListLoadTime != nextProps.lastListLoadTime)
        ) {
            this.fetchData({
                pageSize: nextState.pagesize,                
                sorted: nextState.sort,  
                page:nextState.currentPage,                             
                forceFilters: nextProps.filters,
            });
        }
        return true;
    }

    // changePage(index){
    //     this.setState({currentPage:index});
    //     if(this.props.handlePageChange)
    //         this.props.handlePageChange(index);
    // }

    render() {
        const { data, pages, loading, currentPage} = this.state;
        const { header, filters, t, className } = this.props;

        return (
            <Fragment>
                {header}
                <ReactTable
                    keyField="id"
                    columns={this.translateColumns()}
                    manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                    data={data}
                    minRows={0}
                    page={currentPage}
                    pages={pages} // Display the total number of pages
                    loading={loading} // Display the loading overlay when we need it
                    onFetchData={this.fetchData} // Request new data when things change
                    defaultPageSize={this.props.pageSize || 10}
                    className={`-striped -highlight ${className}`}
                    noDataText={t('No data found')}
                    PaginationComponent={this.props.disablePagination ? () => null : TablePagination}
                    getTrProps={this.props.getTrProps}                    
                />
            </Fragment>
        );
    }
}

GenericList.propTypes = {
    requestData: PropTypes.func.isRequired,
    columns: PropTypes.array,
    header: PropTypes.any,
};

export default GenericList;
