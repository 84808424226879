import React, { Component, createRef, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Icon } from 'leaflet';
import {
    Map, Marker, Popup, TileLayer, FeatureGroup
} from 'react-leaflet';
import { Modal } from 'react-bootstrap';

import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import { Input, Textarea } from '../../../elements/Input';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '../../../hoc/withLocalization';
import LoadingSpinner from '../../../elements/LoadingSpinner';
import { leafletStringToLocation, locationToString, googleCoordsToLeaflet } from '../../../utils/geo';
import timelogStatus from '../../../utils/timelogStatus';
import { timeFormat, totalTimeFormat } from '~/utils/dateFormat'

import 'leaflet/dist/leaflet.css';

@inject('timelogStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class GPSInfo extends Component {
    state = {
        mapOpen: false,
        zoom: 15,
    };

    mapRef = createRef();

    groupRef = createRef();

    transformIfOk(field, showDistance) {
        const { data, t, mode } = this.props;
        const gps_data = data;
        if (!gps_data) return null;
        const fld = gps_data[field];
        const ok = fld ? !!fld.is_ok || fld.decision === 'ok' : null;
        const color = ok ? 'darkgreen' : ok === false ? 'red' : 'grey';
        return (
            <span style={{ color }}>
                {t(field == 'start' ? 'start' : 'end')}
                {showDistance && fld && fld.diff && fld.diff !== 'undefined' ? `: ${fld.diff}m ` : ' '}
                {ok && fld && mode === 'form' && fld.locationString ? fld.locationString : ''}
            </span>
        );
    }

    transformIfOkForm(field, showDistance) {
        const { data, t, mode } = this.props;
        const gps_data = data;
        if (!gps_data) return '?';
        const fld = gps_data[field] || { manual: true };
        const ok = !!fld.is_ok || fld.decision === 'ok';
        const color = ok ? '#87cb16' : ok === false && !fld.manual ? '#FF1E50' : '#979797';
        let statusText = timelogStatus.statuses.MANUAL;
        let statusIcon = 'pe-7s-edit';
        // console.log("SOme : 1234565:: ",field,gps_data.fld);
        if (fld.stamped) {
            statusText = timelogStatus.statuses.STAMPED;
            statusIcon = 'pe-7s-stopwatch';
        }
        if (fld.cron) {
            statusText = timelogStatus.statuses.AUTOMATIC;
            statusIcon = 'pe-7s-stopwatch';
            if (gps_data.systemEntry) {
                statusText = timelogStatus.statuses.SYSTEM;
                statusIcon = 'pe-7s-stopwatch';
            }
        }
        if (ok) {
            statusText = timelogStatus.statuses.VERIFIED;
            statusIcon = 'pe-7s-stopwatch';
        }

        return (
            <span className="gps-info-form__item-value" style={{ color }}>
                <i className={statusIcon} style={{ color }} />
                <span>
                    {statusText}
                    {fld && fld.diff && fld.diff !== 'undefined' ? `: ${fld.diff}m ` : ' '}
                    {fld.locationString ? fld.locationString : ''}
                </span>
            </span>
        );
    }

    openMap() {
        this.setState({ mapOpen: true });
        setTimeout(() => {
            const map = this.mapRef.current ? this.mapRef.current.leafletElement : null; // get native Map instance
            const group = this.groupRef.current ? this.groupRef.current.leafletElement : null; // get native featureGroup instance
            if (!!map && !!group) {                
                map.fitBounds(group.getBounds().pad(0.5));
            }
        }, 1000);
    }    

    renderModal() {
        const { t, data, full } = this.props;
        const {commonStore} = this.props;    
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;    
        const myIcon = new Icon({
            iconUrl: '/images/map-icon.png',
            iconSize: [38, 50],
            iconAnchor: [22, 50],
            popupAnchor: [-3, -76],
            shadowUrl: '/images/map-icon-shadow.png',
            shadowSize: [68, 95],
            shadowAnchor: [22, 94],
        });
        const greenIcon = new Icon({
            iconUrl: '/images/map-icon-green.png',
            iconSize: [38, 50],
            iconAnchor: [22, 50],
            popupAnchor: [-3, -76],
            shadowUrl: '/images/map-icon-shadow.png',
            shadowSize: [68, 95],
            shadowAnchor: [22, 94],
        });
        const redIcon = new Icon({
            iconUrl: '/images/map-icon-red.png',
            iconSize: [38, 50],
            iconAnchor: [22, 50],
            popupAnchor: [-3, -76],
            shadowUrl: '/images/map-icon-shadow.png',
            shadowSize: [68, 95],
            shadowAnchor: [22, 94],
        });        

        const isSameLocation = data.start && data.start.coords && data.start.coords!==null && 
                                data.end && data.end.coords && data.end.coords!==null? 
                                data.start.coords.latitude.toFixed(1) === data.end.coords.latitude.toFixed(1)
                                && data.start.coords.longitude.toFixed(1) === data.end.coords.longitude.toFixed(1)?
                                true:false
                                :false;   
                                
        const tMarker = data.task ? (
            <Marker position={leafletStringToLocation(data.task)} icon={myIcon}>
                <Popup>
                    {`${t('Task')} : ${full.task_name}`}<br/>                    
                </Popup>
            </Marker>
        ) : null;
        
        // console.log("INFO ::: :: ",data.start,data.end);
        const sMarker = data.start && data.start.coords ? (
            <Marker position={googleCoordsToLeaflet(data.start.coords)} icon={greenIcon}>
                {data.end!= null && data.end.coords != undefined && (JSON.stringify(data.start.coords) === JSON.stringify(data.end.coords))}
                <Popup>
                    {isSameLocation?
                    `${t('Start')} / ${t('End')}`
                    :
                    `${t('Start')}`}
                    <br />
                    {isSameLocation?
                    `${timeFormat(full.from, dateTimeRules)} / ${timeFormat(full.to, dateTimeRules)}`
                    :`${timeFormat(full.to, dateTimeRules)}`
                    }
                    <br />
                    {data.start.locationString?`${data.start.locationString}`:''}
                </Popup>
            </Marker>
        ) : null;
        
        const eMarker = data.end && data.end.coords ? (
            <Marker position={googleCoordsToLeaflet(data.end.coords)} icon={redIcon}>
                <Popup>{isSameLocation?
                    `${t('Start')} / ${t('End')}`
                    :`${t('End')}`}
                    <br />
                    {isSameLocation?
                    `${timeFormat(full.from, dateTimeRules)} / ${timeFormat(full.to, dateTimeRules)}`
                    :`${timeFormat(full.to, dateTimeRules)}` 
                    }
                    <br />
                    {data.end.locationString?`${data.end.locationString}`:''}
                </Popup>
            </Marker>
        ) : null;    

        return (
            <Fragment>
                <Modal size="lg" show={!!this.state.mapOpen} onHide={() => this.setState({ mapOpen: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Task locations')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ height: '500px' }}>
                        <div style={{ height: '400px', width: '100%' }}>
                            <Map
                                center={leafletStringToLocation(data.task)}
                                length={4}
                                zoom={this.state.zoom}
                                zoomSnap={0.1}
                                maxZoom={17}
                                ref={this.mapRef}
                            >
                                <TileLayer
                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <FeatureGroup ref={this.groupRef}>
                                    {tMarker}
                                    {sMarker}
                                    {eMarker}
                                </FeatureGroup>
                            </Map>
                            <div style={{ width: '100%', padding: '10px', display: 'flex' }}>
                                <button className="btn btn-fill" onClick={() => this.setState({ mapOpen: false })}>
                                    {t('Close')}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }

    calcIconColor() {
        const { data, t, mode } = this.props;
        const gps_data = data;
        if (!gps_data) return '#979797';
        let fld = gps_data.start;
        const okStart = fld ? !!fld.is_ok || fld.decision === 'ok' : null;
        fld = gps_data.end;
        const okEnd = fld ? !!fld.is_ok || fld.decision === 'ok' : null;
        if (okStart && okEnd) return '#87cb16';

        return '#FF1E50';
    }

    render() {
        const {
            timelogStore, commonStore, t, data, mode, status
        } = this.props;        
        const { mapOpen } = this.state;
        if (!data) return null;

        if (mode === 'modal') {
            if (data.task) {
                return <div className="gps-info-on-list">
                {mapOpen && this.renderModal()}
                <a onClick={() => this.openMap()}>
                    <i className="pe-7s-map-marker" style={{ color: this.calcIconColor()}} />
                </a>
            </div>
            } else return null
        }

        if (mode != 'form' && data && ((data.start && data.start.stamped) || (data.end && data.end.stamped))) {
            const separatorNeeded = data.start && data.start.stamped && data.end && data.end.stamped;
            return (
                <span>
                    {t(timelogStatus.statuses.STAMPED)}&nbsp;
                    {!!(data.start && data.start.stamped) && t('in')}
                    {!!separatorNeeded && '/'}
                    {!!(data.end && data.end.stamped) && t('out')}
                </span>
            );
        }
        //to show system entries//
        if(data.systemEntry && mode != 'form'){
            return <span>{t(timelogStatus.statuses.SYSTEM)}</span>;
        }

        if (!data.task && mode != 'form') {
            return <span>{t(timelogStatus.statuses.MANUAL)}</span>;
        }
        if (mode == 'form') {
            return (
                <div className="gps-info-form">
                    {mapOpen && this.renderModal()}
                    <h6 className="gps-info-form__title">{t('Verification status')}</h6>
                    <div className="gps-info-form__item">
                        <span className="gps-info-form__item-label">{t('Task')}</span>
                        {data.task && (
                            <a className="gps-info-form__item-value" onClick={() => this.openMap()}>
                                <i className="pe-7s-map-marker" style={{ color: this.calcIconColor() }} />
                                GPS
                            </a>
                        )}
                        {!data.task && (
                            <span className="gps-info-form__item-value" style={{ color: 'rgb(151, 151, 151)' }}>
                                {t('No GPS')}
                            </span>
                        )}
                    </div>
                    <div className="gps-info-form__item">
                        <span className="gps-info-form__item-label">{t('Start')}</span>
                        {this.transformIfOkForm('start', true)}
                    </div>
                    <div className="gps-info-form__item">
                        <span className="gps-info-form__item-label">{t('Finish')}</span>
                        <span className="gps-info-form__item-value">{this.transformIfOkForm('end', true)}</span>
                    </div>
                </div>
            );
        }

        return (
            <div className="gps-info-on-list">
                {mapOpen && this.renderModal()}
                <a onClick={() => this.openMap()}>
                    <i className="pe-7s-map-marker" style={{ color: this.calcIconColor() }} />
                    {mode !== 'form' && status !== 'draft' && (
                        <span>
                            &nbsp;|&nbsp;
                            {/* {this.transformIfOk('start')} | {this.transformIfOk('end')} | */}
                            {t(timelogStatus.getStatusByGps({ status, gps_data: data }))}
                        </span>
                    )}
                    {mode == 'form' && (
                        <div>
                            <b>{t('GPS info')}:</b>
                            <br />
                            {this.transformIfOk('start', true)},<br />
                            {this.transformIfOk('end', true)},<br />
                            {t(timelogStatus.getStatusByGps({ status, gps_data: data }))}
                        </div>
                    )}
                </a>
            </div>
        );
    }
}

export default GPSInfo;
