import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';

// HOC
import withLocalization from '../../../hoc/withLocalization';

// Components
import AttachmentsWidget from '../../../components/GenericForm/Widgets/AttachmentsWidget';
import Button from '../../../components/CustomButton/CustomButton.jsx';
// Elements
import { Input, Textarea } from '../../../elements/Input';
import LoadingSpinner from '../../../elements/LoadingSpinner';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';

import globalConfig from '~/library/config';

@inject('extradocStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class ExtraDocForm extends Component {
    state = {
        showErrors: false,
    };

    loadData() {
        const { extradocStore, id, add } = this.props;
        if (add) return extradocStore.returnDefaultNew();
        extradocStore.load(id, add);
    }

    componentWillMount() {
        this.props.extradocStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    onDelete = () => {
        const { id } = this.props.extradocStore.currentEntity;
        this.props.onDelete(id);
    };

    onSave() {
        const {
            extradocStore, add, t, commonStore, mode
        } = this.props;
        const { currentEntity: fullExtradoc } = extradocStore;
        const preparedData = extradocStore.getPreparedData;
        preparedData.doc_type = mode;
        this.setState({
            showErrors: true,
        });
        this.saveExtradoc(preparedData, add);
    }

    saveExtradoc = (changes, add) => {
        const {
            extradocStore, history, t, commonStore
        } = this.props;
        const { currentEntity: fullExtradoc } = extradocStore;
        extradocStore
            .save(changes, add)
            .then(({ id }) => {
                commonStore.addNotification(t('Saved'), null, 'success');
                if (add) {
                    history.push(`/admin/${fullExtradoc.doc_type == 'internal' ? 'internaldocs' : 'contracts'}/${id}`);
                }
            })
            .catch(err => {
                commonStore.addNotification(t(err), null, 'error');
            });
    };

    handleChange = (name, value) => {
        this.props.extradocStore.handleChange(name, value);
    };

    onFileUpload = name => {
        const {
            extradocStore,
            config,
            t,
            userStore: { currentUser },
        } = this.props;
        const { showErrors } = this.state;
        const { loading, currentEntity: extradoc } = extradocStore;
        if (!extradoc.name) this.props.extradocStore.handleChange('name', name);
    };

    render() {
        const {
            extradocStore,
            config,
            t,
            userStore: { currentUser },
            isUnion,
        } = this.props;
        const { showErrors } = this.state;
        const { loading, currentEntity: extradoc } = extradocStore;
        const userType = currentUser.user_type;
        let isPm = userType === 'admin' || userType === 'pm';
        if (isUnion) {
            if (!extradoc.id) isPm = true; // allow adding
            if (extradoc.created_by === currentUser.id) isPm = true;
        }

        if (loading) {
            return <LoadingSpinner />;
        }
        return (
            <div className={extradoc.id > 0 ? 'page' : 'page'}>
                <div className="page__header">
                    <h1 className="page__title">{t(extradoc.id > 0 ? 'Register Document' : 'Registered Document')}</h1>
                </div>
                <div className="page__content">
                    <form className="custom-form">
                        <div className="custom-form__row">
                            <Input
                                className="custom-form__col"
                                label={`${t('Name')} *`}
                                placeholder={t('Name')}
                                error={showErrors && !extradoc.name}
                                type="text"
                                value={extradoc.name}
                                onChange={({ target: { name, value } }) => this.handleChange(name, value)}
                                name="name"
                                disabled={!isPm}
                            />
                        </div>
                        <div className="custom-form__row" style={{paddingLeft: 10}}>
                            <Checkbox
                                isChecked={!(_.get(extradoc, 'data.disableAvailableForEverybody'))}
                                onChange={(checked) => /* this.handleChange('do_notify', checked */ this.handleChange('data.disableAvailableForEverybody', !checked)}
                                label={this.props.t('Available for all')}
                                style={{ marginRight: 30, marginLeft: 30 }}
                                disabled={!isPm}
                            />
                        </div>
                        <div className="custom-form__row">
                            <Textarea
                                className="custom-form__col"
                                label={t('Details')}
                                error={showErrors && !extradoc.description}
                                placeholder={t('description')}
                                value={extradoc.description}
                                onChange={({ target: { name, value } }) => this.handleChange(name, value)}
                                name="description"
                                disabled={!isPm}
                            />
                        </div>

                        <div className="custom-form__row">
                            <AttachmentsWidget
                                value={extradoc.attachments}
                                onUpload={name => this.onFileUpload(name)}
                                onChange={attachIds => this.handleChange('attachments', attachIds)}
                                options={{
                                    checkDate: false,
                                    imageContext: {
                                        existingAttachment: extradoc.Attachments,
                                        fileType: 'docs',
                                        id: 0,
                                        model: 'ExtraDoc',
                                    },
                                }}
                                disabled={!isPm}
                            />
                        </div>

                        {isPm && (
                            <div className="custom-form__row">
                                <div
                                    className="custom-form__col custom-form__col_grow-0 "
                                    style={{ marginLeft: 'auto' }}
                                >
                                    <Button onClick={() => this.onSave()} fill wd>
                                        {t(extradoc.id > 0 ? 'Save' : 'Create')}
                                    </Button>
                                </div>
                                {extradoc.id && (
                                    <div className="custom-form__col custom-form__col_grow-0 pull-right">
                                        <Button danger onClick={() => this.onDelete()} fill wd>
                                            {t('Delete')}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </form>
                </div>
            </div>
        );
    }
}

export default ExtraDocForm;
