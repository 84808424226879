import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import moment from 'moment/min/moment-with-locales';

import AcceptFormBlock from '../Timelogs/AcceptFormBlock';

import VacationsTotals from './VacationsTotals';

import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import getSchema from '~/library/schemas/vacation';
import customerFeature from '~/utils/customerFeature';

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:layout': [
        {
            vacation_type: { md: 4 },
            project_id: { md: 4 },
            // 'status':{md:4},
        },
        {
            from: { md: 4 },
            to: { md: 4 },
            total_days: { md: 4 },
        },
        {
            comments: { md: 12 },
        },
        {
            attachments: { md: 12 },
        },
    ],
    vacation_type: {
        'ui:widget': 'VacationStatuses',
    },
    project_id: {
        'ui:widget': 'ProjectsWidget',
    },
    attachments: {},
    user_id: {
        'ui:widget': 'AllUsersWidget',
    },
};

@inject('vacationStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class VacationsForm extends Component {
    state = {
        schema: getSchema(),
        uiSchema: defaultUiSchema,
    };

    getListUrl() {
        return '/admin/vacations';
    }

    async handleChangeStatus(action) {
        this.setState({defaultHolidays : this.props.commonStore.config.client.data.basicRules.defaultHolidays || "5,6"},()=>{
            this.loadData();
        })
    }

    async loadData() {        

        const {
            vacationStore, id, add, mode, location
        } = this.props;
        const { currentEntity } = vacationStore;
        this.setState({
            schema: getSchema({
                config: this.props.commonStore.config,
            }),
        });

        if (add) {
            this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, null) });
            return vacationStore.returnDefaultNew({ location });
        }
       
        const vacation = await vacationStore.load(id, add);
        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, vacation.vacation) });
    }

    prepareSchema(_schema, currentEntity) {
        const schema = cloneDeep(_schema);
        const { userStore } = this.props;
        const { currentUser } = userStore;

        schema.attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'Vacation',
                fileType: 'docs',
                id: currentEntity && currentEntity.id !== 'add' ? currentEntity.id : 0,
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
            },
        };
        const showUser = (currentUser.user_type == 'admin' || currentUser.user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');
        // const showUser = currentUser.user_type === 'pm' && customerFeature('allow_vacation_not_me');
        if (showUser) {
            schema['ui:layout'][0].user_id = { md: 4 };
        }
        if (currentUser.user_type === 'member') {
            if (schema.user_id) schema.user_id.classNames = 'hidden';
        }

        return schema;
    }

    componentWillMount() {
        this.props.vacationStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
     
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    onSave(values) {
        const {
            vacationStore, history, add, t, commonStore
        } = this.props;
        const { userStore } = this.props;
        const { currentUser } = userStore;
        // if (currentUser.user_type === 'member' || add) {
        if (!values.user_id) {
            values.user_id = currentUser.id;
        }
        const { currentEntity } = vacationStore;

        let allow = (currentUser.user_type == 'admin' || currentUser.user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');

        if (currentEntity && currentEntity.vacation && currentEntity.vacation.status == 'approved') allow = false;

        if (currentUser.id != values.user_id && !allow) {
            commonStore.addNotification(t('Can not save on behalf of others'), null, 'error');
            return false;
        }
        values.status = 'active';
        return vacationStore.save(values, add).then(result => {
            if (!result.vacation || !result.vacation.id) {
                // some error
                // console.log(result);
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');
            history.push(this.getListUrl());
            return true;
        });
    }

    render() {
        const { vacationStore, add } = this.props;
        const { loading, currentEntity } = vacationStore;
        const { schema, uiSchema } = this.state;
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const showStatus = (user_type === 'pm' || user_type === 'admin') && customerFeature('allow_accepting') && !add;
        let allow = (user_type == 'admin' || user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');
        allow = !add && currentEntity && currentEntity.vacation && currentEntity.vacation.user_id != userId && !allow;

        if (currentEntity && currentEntity.vacation && currentEntity.vacation.status == 'approved') allow = true;

        if (loading) return <LoadingSpinner />;

        return (
            <div className="primary-page">
                <VacationsTotals user={currentEntity.vacation.user_id} currentvacation={vacationStore.currentEntity}/>
                <GenericForm
                    entity={currentEntity.vacation}
                    uiSchema={uiSchema}
                    schema={schema}
                    translationScope="forms.vacation"
                    onChange={async data => vacationStore.recalc(data)}
                    onSave={values => this.onSave(values)}
                    listUrl={this.getListUrl()}
                    isAdding={add}
                    disallowSave={allow}
                />
                {showStatus && (
                    <AcceptFormBlock
                        params={{ id: this.props.id, status: currentEntity.vacation.status,data:vacationStore.currentEntity, entity: 'vacation' }}
                        afterSave={() => this.handleChangeStatus()}
                    />
                )}
            </div>
        );
    }
}

export default VacationsForm;
