import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import '../../../styles/css/styles.css';

// Components
import { Input, Textarea } from '../../../elements/Input';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

@inject('checklistStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ChecklistAccept extends Component {
    state = {
        checklist: {},
        showErrors: false,
        status_note: null,
        checklist_status: null
    }

    loadData() {
        this.setState({
            checklist: this.props.checklist,
            status_note: this.props.checklist.status_note,
            checklist_status : this.props.checklist.status
        })
    }

    componentDidMount() {
        this.loadData();
    }

    handleChange = (value) => {
        this.setState({
                "status_note": value
        }, () => {
            this.props.updateCurrentChecklistComment(value);
          })
    };

    render() {
        const { t } = this.props;
        return (
            <div className="page" style={{ padding: 0, margin: 0 }}>
                <div className="page__content">
                    <div className="">
                        <h3>
                            <b>({this.state.checklist.name})</b>
                        </h3>

                        <Textarea
                            label={t('Comment')}
                            value={this.state.status_note}
                            onChange={(e) => this.handleChange(e.target.value)}
                            placeholder={t('Enter comments here')}
                            required
                            disabled={this.state.checklist_status == 'Approved'}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default ChecklistAccept;
