import { observable, action } from 'mobx';

// import getBase64 from '~/utils/getBase64';
import agent from '../agent';

class BizTypeStore {
    @observable currentList = [];

    @observable currentEntity = {};

    @observable updatingErrors = null;

    @observable updating = false;

    @observable loading = false;

    @observable requestParams = null;

    @observable deleteSuccess = false;

    @observable lastListLoadTime = null;

    @action loadList(params) {
        return agent.BizTypes.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.currentList = list;
                    this.lastListLoadTime = list.time;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    @action returnDefaultNew(params) {
        this.currentEntity = { biztype: { name: '', data: {} } };
        this.loading = false;
    }

    @action load(id) {
        this.loading = true;
        return agent.BizTypes.load(id)
            .then(
                action(response => {
                    if (!response.biztype.data) response.biztype.data = {};
                    this.currentEntity = response;
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action save(values, isAdd) {
        this.updating = true;
        return agent.BizTypes.save(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action async remove(id) {
        await agent.BizTypes.remove(id);
        this.lastListLoadTime = new Date();
        this.deleteSuccess = true;
        return 1;
    }

    //for biztype_documents//
    @observable currentBizTypeDocumentList = [];

    @action getbiztypeDocuments(id) {
        //calls get method from biztype_documents API//
        //takes 1 arguments, type - integer, id//
        //returns document list//
        return agent.BizTypes
            .getBizTypeDocumentsById(id)
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    @action addBizTypeDocument(data){
        return agent.BizTypes.saveBizTypeDocuments(data).then(
            action((response)=>{
                return response;
            })
        )
        .catch(
            action((error)=>{
                throw error;
            })
        )
    }
}

export default new BizTypeStore();
