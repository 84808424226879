import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import withLocalization from '../../../hoc/withLocalization';
import GenericList from '../../../components/GenericList/GenericList';
import { minutesToTimeInput } from '../../../utils/timeCalc';
import DatePicker from '../../../components/DatePicker/DatePicker';
import config from '../../../library/config';
import userPlaceholderImg from '../../../assets/img/user-placeholder.jpg';
import Input from '../../../elements/Input';

import StatusFilter from './StatusFilter';

import Button from '~/components/CustomButton/CustomButton.jsx';

@inject('userStore', 'lockedStore', 'commonStore')
@withRouter
@withLocalization
@observer
class StatusList extends Component {
    state = {
        email: '',
        sending: false,
        project: null,
    };

    onEmailChange = e => {
        this.setState({ email: e.target.value });
    };

    componentDidMount() {
        let _project = this.props.project;
        if (_project && _project.value) _project = _project.value;
        this.setState({ project: _project });
    }

    getStatusText = status => {
        switch (status) {
            case 'started':
                return 'Working';
            case 'no_work':
                return 'Not started today';
            case 'finish':
                return 'Finished';
            case 'done':
                return 'Finished';
            case 'break':
                return 'On break';
            default:
                return 'Not working';
        }
    };

    applyForm = () => {
        const {
            dashboardStore, userStore, commonStore, lockedStore, t
        } = this.props;
        const { email, project } = this.state;
        if (!email || email.indexOf('@') < 0) {
            commonStore.addNotification(t('Fill email'), null, 'error');
            return;
        }
        this.setState({ sending: true });
        const hydrated = {
            email,
            project: project ? project.value : '',
        };
        hydrated.authorization = commonStore.token;
        lockedStore
            .sendReportByMail(hydrated)
            .then(res => {
                this.setState({ sending: false });
                commonStore.addNotification(t('Sent'), null, 'success');
            })
            .catch(err => {
                this.setState({ sending: false });
                commonStore.addNotification(err.message || t('Error'), null, 'error');
            });
    };

    render() {
        const { commonStore, lockedStore, t } = this.props;
        const { modes } = lockedStore;
        const { data: clientData } = commonStore.config.client;
        const { sending, project } = this.state;

        return (
            <div className="locked-mode-status">
                <section>
                    <GenericList
                        filters={{ project }}
                        header={(
                            <StatusFilter
                                filters={{ project }}
                                setFilter={(name, project) => this.setState({ project: project.value })}
                            />
                        )}
                        // disablePagination
                        id="employee-table"
                        className="employee-table locked-table"
                        columns={[
                            {
                                Header: 'Employee',
                                id: 'email',
                                width: 200,
                                accessor: user => (
                                    <div className={`employee-table__user employee-table__user_${user.presenceStatus}`}>
                                        <i
                                            style={{
                                                backgroundImage: `url("${
                                                    user.image
                                                        ? `${config.UPLOADS_API_ENDPOINT}/${user.image}`
                                                        : userPlaceholderImg
                                                }")`,
                                            }}
                                        />
                                        <div>
                                            <h4>
                                                {user.first_name} {user.last_name}
                                            </h4>
                                            <small>{user.social ? user.social : ''}</small>
                                        </div>
                                    </div>
                                ),
                            },
                            {
                                Header: 'Start time',
                                id: 'start_time',
                                accessor: ({
                                    start_time, end_time, lockstate, yesterday
                                }) => (
                                    <div className="employee-table__start">
                                        {yesterday && <span style={{ color: '#447df7' }}>{t('Yesterday')}</span>}
                                        {start_time && (
                                            <span style={{ color: '#447df7' }}>
                                                {minutesToTimeInput(start_time, true)}
                                            </span>
                                        )}
                                    </div>
                                ),
                            },
                            {
                                Header: 'End time',
                                id: 'end_time',
                                accessor: ({
                                    start_time, end_time, lockstate, yesterday
                                }) => (
                                    <div className="employee-table__start">
                                        {yesterday && <span style={{ color: '#447df7' }}>{t('Yesterday')}</span>}
                                        {end_time && lockstate === 'done' && (
                                            <span style={{ color: '#447df7' }}>
                                                {minutesToTimeInput(end_time, true)}
                                            </span>
                                        )}
                                    </div>
                                ),
                            },
                            {
                                Header: 'Break today',
                                sortable: false,
                                id: 'total_break',
                                accessor: ({ totalBreak }) => {
                                    const totalBreakNow = minutesToTimeInput(totalBreak || 0, true);
                                    return <span>{totalBreakNow}</span>;
                                },
                            },
                            {
                                Header: 'Project',
                                id: 'project',
                                accessor: ({ project }) => (
                                    <div className="employee-table__start">{project ? project.label : ''}</div>
                                ),
                            },
                            /* {
                Header: "Already worked",
                id: 'total_time',
                sortable: false,
                accessor: ({Timelogs}) => {
                  return (Timelogs && Timelogs.length) ?
                    (
                      <div>
                        {Timelogs.map(t=>(
                          <div>
                            {t.from} - {t.to} @ {t.project_name}
                          </div>
                        ))}
                      </div>
                    ) :
                    ' - '
                }
              }, */
                            {
                                Header: 'Status',
                                id: 'status',
                                sortable: false,
                                accessor: ({ lockstate }) => (
                                    <span className={`employee-table__status employee-table__status_${lockstate}`}>
                                        {this.props.t(this.getStatusText(lockstate))}
                                    </span>
                                ),
                            },
                        ]}
                        requestData={params => this.props.lockedStore.loadStatusList(params)}
                    />
                </section>
                <div className="locked-page__footer locked-page__footer_status">
                    <div className="locked-page__footer-nav">
                        <button
                            className="locked-page__footer-btn"
                            type="button"
                            onClick={() => lockedStore.switchMode(modes.LOCKED_MENU)}
                        >
                            <i className="fa fa-arrow-left" />
                        </button>
                    </div>
                    <FormGroup>
                        <h4>{t('Send report to')}:</h4>
                        <FormControl value={this.state.email} onChange={this.onEmailChange} placeholder="Email" />
                        <div>
                            {sending && <span>Sending</span>}
                            {!sending && (
                                <Button variant="primary" onClick={this.applyForm}>
                                    {t('Send')}
                                </Button>
                            )}
                        </div>
                    </FormGroup>
                </div>
            </div>
        );
    }
}
export default StatusList;
