import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import { PropTypes } from 'prop-types';
import Popover from 'antd/lib/popover';

import moment from "moment";
import 'antd/lib/popover/style/index.css';
import EventItemPopover from './EventItemPopover';
import { DnDTypes } from './DnDTypes';

import { CellUnits, DATETIME_FORMAT } from './index';
import { scheduleStore } from '~/library/stores/scheduleStore';

const supportTouch = 'ontouchstart' in window;



@inject('dashboardStore', 'userStore', 'commonStore', 'scheduleStore')
@withLocalization
@withRouter
@observer
class EventItem extends Component {
    constructor(props) {
        super(props);

        const { left, top, width } = props;

        // state ={

        //     showBox:false
        // }
        
        this.state = {
            left,
            top,
            width,
            showBox:false
        };
        this.startResizer = null;
        this.endResizer = null;
    }

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
        eventItem: PropTypes.object.isRequired,
        isStart: PropTypes.bool.isRequired,
        isEnd: PropTypes.bool.isRequired,
        left: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        top: PropTypes.number.isRequired,
        isInPopover: PropTypes.bool.isRequired,
        leftIndex: PropTypes.number.isRequired,
        rightIndex: PropTypes.number.isRequired,
        isDragging: PropTypes.bool.isRequired,
        connectDragSource: PropTypes.func.isRequired,
        connectDragPreview: PropTypes.func.isRequired,
        updateEventStart: PropTypes.func,
        updateEventEnd: PropTypes.func,
        moveEvent: PropTypes.func,
        subtitleGetter: PropTypes.func,
        eventItemClick: PropTypes.func,
        viewEventClick: PropTypes.func,
        viewEventText: PropTypes.string,
        viewEvent2Click: PropTypes.func,
        viewEvent2Text: PropTypes.string,
        conflictOccurred: PropTypes.func,
        eventItemTemplateResolver: PropTypes.func,
    };

    componentWillReceiveProps(np) {
        const { left, top, width } = np;
        this.setState({
            left,
            top,
            width,
        });

        this.subscribeResizeEvent(np);
    }

    componentDidMount() {
        this.subscribeResizeEvent(this.props);
    }

    initStartDrag = ev => {
        const { schedulerData, eventItem } = this.props;
        const slotId = schedulerData._getEventSlotId(eventItem);
        const slot = schedulerData.getSlotById(slotId);
        if (!!slot && !!slot.groupOnly) return;
        if (schedulerData._isResizing()) return;

        ev.stopPropagation();
        let clientX = 0;
        if (supportTouch ) {
            if (ev.changedTouches.length == 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            if (ev.buttons !== undefined && ev.buttons !== 1) return;
            clientX = ev.clientX;
        }
        this.setState({
            startX: clientX,
        });
        schedulerData._startResizing();
        if (supportTouch) {
            this.startResizer.addEventListener('touchmove', this.doStartDrag, false);
            this.startResizer.addEventListener('touchend', this.stopStartDrag, false);
            this.startResizer.addEventListener('touchcancel', this.cancelStartDrag, false);
        } else {
            document.documentElement.addEventListener('mousemove', this.doStartDrag, false);
            document.documentElement.addEventListener('mouseup', this.stopStartDrag, false);
        }
        document.onselectstart = function () {
            return false;
        };
        document.ondragstart = function () {
            return false;
        };
    };

    doStartDrag = ev => {
        ev.stopPropagation();

        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const {
            left, width, leftIndex, rightIndex, schedulerData
        } = this.props;
        const cellWidth = schedulerData.getContentCellWidth();
        const offset = leftIndex > 0 ? 5 : 6;
        const minWidth = cellWidth - offset;
        const maxWidth = rightIndex * cellWidth - offset;
        const { startX } = this.state;
        let newLeft = left + clientX - startX;
        let newWidth = width + startX - clientX;
        if (newWidth < minWidth) {
            newWidth = minWidth;
            newLeft = (rightIndex - 1) * cellWidth + (rightIndex - 1 > 0 ? 2 : 3);
        } else if (newWidth > maxWidth) {
            newWidth = maxWidth;
            newLeft = 3;
        }

        this.setState({ left: newLeft, width: newWidth });
    };

    stopStartDrag = ev => {
        ev.stopPropagation();
        if (supportTouch) {
            this.startResizer.removeEventListener('touchmove', this.doStartDrag, false);
            this.startResizer.removeEventListener('touchend', this.stopStartDrag, false);
            this.startResizer.removeEventListener('touchcancel', this.cancelStartDrag, false);
        } else {
            document.documentElement.removeEventListener('mousemove', this.doStartDrag, false);
            document.documentElement.removeEventListener('mouseup', this.stopStartDrag, false);
        }
        document.onselectstart = null;
        document.ondragstart = null;
        const {
            width,
            left,
            top,
            leftIndex,
            rightIndex,
            schedulerData,
            eventItem,
            updateEventStart,
            conflictOccurred,
        } = this.props;
        schedulerData._stopResizing();
        if (this.state.width === width) return;

        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) {
                this.setState({
                    left,
                    top,
                    width,
                });
                return;
            }
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const {
            cellUnit, events, config, localeMoment
        } = schedulerData;
        
        const cellWidth = schedulerData.getContentCellWidth();
        const offset = leftIndex > 0 ? 5 : 6;
        const minWidth = cellWidth - offset;
        const maxWidth = rightIndex * cellWidth - offset;
        const { startX } = this.state;
        const newWidth = width + startX - clientX;
        const deltaX = clientX - startX;
        const sign = deltaX < 0 ? -1 : deltaX === 0 ? 0 : 1;
        let count = (sign > 0 ? Math.floor(Math.abs(deltaX) / cellWidth) : Math.ceil(Math.abs(deltaX) / cellWidth)) * sign;
        if (newWidth < minWidth) count = rightIndex - leftIndex - 1;
        else if (newWidth > maxWidth) count = -leftIndex;
        let newStart = localeMoment(eventItem.start)
            .add(
                cellUnit === CellUnits.Hour ? count * config.minuteStep : count,
                cellUnit === CellUnits.Hour ? 'minutes' : 'days'
            )
            .format(DATETIME_FORMAT);
        if (count !== 0 && cellUnit !== CellUnits.Hour && config.displayWeekend === false) {
            if (count > 0) {
                let tempCount = 0;
                let i = 0;
                while (true) {
                    i++;
                    const tempStart = localeMoment(eventItem.start).add(i, 'days');
                    const dayOfWeek = tempStart.weekday();
                    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                        tempCount++;
                        if (tempCount === count) {
                            newStart = tempStart.format(DATETIME_FORMAT);
                            break;
                        }
                    }
                }
            } else {
                let tempCount = 0;
                let i = 0;
                while (true) {
                    i--;
                    const tempStart = localeMoment(eventItem.start).add(i, 'days');
                    const dayOfWeek = tempStart.weekday();
                    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                        tempCount--;
                        if (tempCount === count) {
                            newStart = tempStart.format(DATETIME_FORMAT);
                            break;
                        }
                    }
                }
            }
        }

        let hasConflict = false;
        const slotId = schedulerData._getEventSlotId(eventItem);
        let slotName;
        const slot = schedulerData.getSlotById(slotId);
        if (slot) slotName = slot.name;
        if (config.checkConflict) {
            const start = localeMoment(newStart);
            const end = localeMoment(eventItem.end);

            events.forEach(e => {
                if (schedulerData._getEventSlotId(e) === slotId && e.id !== eventItem.id) {
                    const eStart = localeMoment(e.start);
                    const eEnd = localeMoment(e.end);
                    if (
                        (start >= eStart && start < eEnd)
                        || (end > eStart && end <= eEnd)
                        || (eStart >= start && eStart < end)
                        || (eEnd > start && eEnd <= end)
                    ) hasConflict = true;
                }
            });
        }

        if (hasConflict) {
            this.setState({
                left,
                top,
                width,
            });

            if (conflictOccurred != undefined) {
                conflictOccurred(
                    schedulerData,
                    'StartResize',
                    eventItem,
                    DnDTypes.EVENT,
                    slotId,
                    slotName,
                    newStart,
                    eventItem.end
                );
            } else {
                console.log('Conflict occurred, set conflictOccurred func in Scheduler to handle it');
            }
            this.subscribeResizeEvent(this.props);
        } else if (updateEventStart != undefined) updateEventStart(schedulerData, eventItem, newStart);
    };

    cancelStartDrag = ev => {
        ev.stopPropagation();

        this.startResizer.removeEventListener('touchmove', this.doStartDrag, false);
        this.startResizer.removeEventListener('touchend', this.stopStartDrag, false);
        this.startResizer.removeEventListener('touchcancel', this.cancelStartDrag, false);
        document.onselectstart = null;
        document.ondragstart = null;
        const {
            schedulerData, left, top, width
        } = this.props;
        schedulerData._stopResizing();
        this.setState({
            left,
            top,
            width,
        });
    };

    initEndDrag = ev => {
        const { schedulerData, eventItem } = this.props;
        const slotId = schedulerData._getEventSlotId(eventItem);
        const slot = schedulerData.getSlotById(slotId);
        if (!!slot && !!slot.groupOnly) return;
        if (schedulerData._isResizing()) return;

        ev.stopPropagation();
        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            if (ev.buttons !== undefined && ev.buttons !== 1) return;
            clientX = ev.clientX;
        }
        this.setState({
            endX: clientX,
        });

        schedulerData._startResizing();
        if (supportTouch) {
            this.endResizer.addEventListener('touchmove', this.doEndDrag, false);
            this.endResizer.addEventListener('touchend', this.stopEndDrag, false);
            this.endResizer.addEventListener('touchcancel', this.cancelEndDrag, false);
        } else {
            document.documentElement.addEventListener('mousemove', this.doEndDrag, false);
            document.documentElement.addEventListener('mouseup', this.stopEndDrag, false);
        }
        document.onselectstart = function () {
            return false;
        };
        document.ondragstart = function () {
            return false;
        };
    };

    doEndDrag = ev => {
        ev.stopPropagation();
        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const { width, leftIndex, schedulerData } = this.props;
        const { headers } = schedulerData;
        const cellWidth = schedulerData.getContentCellWidth();
        const offset = leftIndex > 0 ? 5 : 6;
        const minWidth = cellWidth - offset;
        const maxWidth = (headers.length - leftIndex) * cellWidth - offset;
        const { endX } = this.state;

        let newWidth = width + clientX - endX;
        if (newWidth < minWidth) newWidth = minWidth;
        else if (newWidth > maxWidth) newWidth = maxWidth;

        this.setState({ width: newWidth });
    };

    stopEndDrag = ev => {
        ev.stopPropagation();

        if (supportTouch) {
            this.endResizer.removeEventListener('touchmove', this.doEndDrag, false);
            this.endResizer.removeEventListener('touchend', this.stopEndDrag, false);
            this.endResizer.removeEventListener('touchcancel', this.cancelEndDrag, false);
        } else {
            document.documentElement.removeEventListener('mousemove', this.doEndDrag, false);
            document.documentElement.removeEventListener('mouseup', this.stopEndDrag, false);
        }
        document.onselectstart = null;
        document.ondragstart = null;
        const {
            width,
            left,
            top,
            leftIndex,
            rightIndex,
            schedulerData,
            eventItem,
            updateEventEnd,
            conflictOccurred,
        } = this.props;
        schedulerData._stopResizing();
        if (this.state.width === width) return;

        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) {
                this.setState({
                    left,
                    top,
                    width,
                });
                return;
            }
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const {
            headers, cellUnit, events, config, localeMoment
        } = schedulerData;
        const cellWidth = schedulerData.getContentCellWidth();
        const offset = leftIndex > 0 ? 5 : 6;
        const minWidth = cellWidth - offset;
        const maxWidth = (headers.length - leftIndex) * cellWidth - offset;
        const { endX } = this.state;

        const newWidth = width + clientX - endX;
        const deltaX = newWidth - width;
        const sign = deltaX < 0 ? -1 : deltaX === 0 ? 0 : 1;
        let count = (sign < 0 ? Math.floor(Math.abs(deltaX) / cellWidth) : Math.ceil(Math.abs(deltaX) / cellWidth)) * sign;
        if (newWidth < minWidth) count = leftIndex - rightIndex + 1;
        else if (newWidth > maxWidth) count = headers.length - rightIndex;
        let newEnd = localeMoment(eventItem.end)
            .add(
                cellUnit === CellUnits.Hour ? count * config.minuteStep : count,
                cellUnit === CellUnits.Hour ? 'minutes' : 'days'
            )
            .format(DATETIME_FORMAT);
        if (count !== 0 && cellUnit !== CellUnits.Hour && config.displayWeekend === false) {
            if (count > 0) {
                let tempCount = 0;
                let i = 0;
                while (true) {
                    i++;
                    const tempEnd = localeMoment(eventItem.end).add(i, 'days');
                    const dayOfWeek = tempEnd.weekday();
                    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                        tempCount++;
                        if (tempCount === count) {
                            newEnd = tempEnd.format(DATETIME_FORMAT);
                            break;
                        }
                    }
                }
            } else {
                let tempCount = 0;
                let i = 0;
                while (true) {
                    i--;
                    const tempEnd = localeMoment(eventItem.end).add(i, 'days');
                    const dayOfWeek = tempEnd.weekday();
                    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                        tempCount--;
                        if (tempCount === count) {
                            newEnd = tempEnd.format(DATETIME_FORMAT);
                            break;
                        }
                    }
                }
            }
        }

        let hasConflict = false;
        const slotId = schedulerData._getEventSlotId(eventItem);
        let slotName;
        const slot = schedulerData.getSlotById(slotId);
        if (slot) slotName = slot.name;
        if (config.checkConflict) {
            const start = localeMoment(eventItem.start);
            const end = localeMoment(newEnd);

            events.forEach(e => {
                if (schedulerData._getEventSlotId(e) === slotId && e.id !== eventItem.id) {
                    const eStart = localeMoment(e.start);
                    const eEnd = localeMoment(e.end);
                    if (
                        (start >= eStart && start < eEnd)
                        || (end > eStart && end <= eEnd)
                        || (eStart >= start && eStart < end)
                        || (eEnd > start && eEnd <= end)
                    ) hasConflict = true;
                }
            });
        }

        if (hasConflict) {
            this.setState({
                left,
                top,
                width,
            });

            if (conflictOccurred != undefined) {
                conflictOccurred(
                    schedulerData,
                    'EndResize',
                    eventItem,
                    DnDTypes.EVENT,
                    slotId,
                    slotName,
                    eventItem.start,
                    newEnd
                );
            } else {
                console.log('Conflict occurred, set conflictOccurred func in Scheduler to handle it');
            }
            this.subscribeResizeEvent(this.props);
        } else if (updateEventEnd != undefined) updateEventEnd(schedulerData, eventItem, newEnd);
    };

    cancelEndDrag = ev => {
        ev.stopPropagation();

        this.endResizer.removeEventListener('touchmove', this.doEndDrag, false);
        this.endResizer.removeEventListener('touchend', this.stopEndDrag, false);
        this.endResizer.removeEventListener('touchcancel', this.cancelEndDrag, false);
        document.onselectstart = null;
        document.ondragstart = null;
        const {
            schedulerData, left, top, width
        } = this.props;
        schedulerData._stopResizing();
        this.setState({
            left,
            top,
            width,
        });
    };

    displyaBox = () =>{
        const{scheduleStore,eventItem}=this.props;
        
        scheduleStore.currentEntity.id = eventItem.id;
        //console.log("eventItem***",eventItem.id);
       // console.log("scheduleStore",scheduleStore.currentEntity.id);
         this.setState({showBox:!this.state.showBox});
     }

     moveToPending = ()=>{
       // moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
           const {
               eventItem,
               schedulerData,
           } = this.props;

        const slotId = schedulerData._getEventSlotId(eventItem);
        const slot = schedulerData.getSlotById(slotId);
        const slotName = slot.name;
       // this.props.moveEvent(schedulerData,eventItem,slotId,slotName,eventItem.start,eventItem.end);
       this.props.moveToPending(eventItem,slotId,slotName,eventItem.start,eventItem.end);
       // moveToPending={(event,slotId,slotName,start,end)=>this.moveToPending(event,slotId,slotName,start,end)}
       // this.props.decide('move',false);
          // console.log("props values***",this.props);
         //alert("move to pending called");
     }

    render() {
        const {
            eventItem,
            isStart,
            isEnd,
            isInPopover,
            eventItemClick,
            schedulerData,
            isDragging,
            connectDragSource,
            connectDragPreview,
            eventItemTemplateResolver,
            toggle_todaybtn
        } = this.props;


        let getWeekendDays = moment(eventItem.forDate, "YYYY-MM-DD HH:mm:ss");

        const { config, localeMoment } = schedulerData;
        const { left, width, top } = this.state;
        const roundCls = isStart ? (isEnd ? 'round-all' : 'round-head') : isEnd ? 'round-tail' : 'round-none';
       
        let bgColor = config.defaultEventBgColor;
        if (eventItem.bgColor) bgColor = eventItem.bgColor;
        
      
        const titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, eventItem);
        let getEventSource = eventItem.source==="vacation" || eventItem.source==="absence" ;
        let getEndDate = new Date(eventItem.end);
        let currentDate = new Date();
       // console.log("titleText",titleText);
      // console.log("checking event souurce:::::",getEventSource);
        //let checkEventSource = eventItem.source
        //const taskName = schedulerData.behaviors.getEventTaskName(schedulerData, eventItem);
        //console.log("titleText",titleText.test1,titleText.test2);
        const content = (
            <EventItemPopover
                {...this.props}
                eventItem={eventItem}
                title={eventItem.title}
                startTime={eventItem.start}
                endTime={eventItem.end}
                statusColor={bgColor}
            />
        );

        const start = localeMoment(eventItem.start);
         const eventTitle = isInPopover ? `${start.format('HH:mm')} ${titleText}` : titleText;
         
        
        let startResizeDiv = <div />;
        if (this.startResizable(this.props) ) {
            startResizeDiv = (
                <div className="event-resizer event-start-resizer" ref={ref => (this.startResizer = ref)} />
            );
        }
        let endResizeDiv = <div />;
        if (this.endResizable(this.props) ) {
            endResizeDiv = <div className="event-resizer event-end-resizer" ref={ref => (this.endResizer = ref)} />;
        }

        let eventItemTemplate = (
            <div
                className={`${roundCls} event-item`}
                key={eventItem.id}
                style={{ height: config.eventItemHeight, backgroundColor: bgColor,zIndex:1000 }}
               
            >
                <span style={{ marginLeft: '10px', lineHeight: `${config.eventItemHeight}px` }}>
                    {eventTitle}
                </span>            
            
            </div>
          
            
        );
       
        
        if (eventItemTemplateResolver != undefined) {
            
            //console.log("props eventItemTemplateResolver****",this.props);
            //const {t} = this.props;
            eventItemTemplate = eventItemTemplateResolver(
                schedulerData,
                eventItem,
                bgColor,
                isStart,
                isEnd,
                // 'event-item',
               // (getWeekendDays.format('dddd') === 'Sunday' || getWeekendDays.format('dddd') === 'Saturday')?'weekend_event_style': eventItem.workLogStatus ==='none'?'new_event_style': (eventItem.workLogStatus==='late'?'new_eventLate_style':'new_eventOnTime_style'),
                eventItem.workLogStatus ==='none'?'new_event_style':  eventItem.workLogStatus ==='NO SHOW'?'noShow_style' : (eventItem.workLogStatus==='LATE'?'new_eventLate_style':eventItem.workLogStatus==='ON TIME'?'new_eventOnTime_style':'new_event_style'),
                config.eventItemHeight,
                undefined
            );
               
        }

        const a = (
            <a  className="timeline-event"
            style={{ left, width, top}}
            onClick={() => {
                if (eventItemClick) eventItemClick(schedulerData, eventItem);
            }}
            >
                    {eventItemTemplate}  
                    {startResizeDiv}
                    {endResizeDiv} 
            </a>
        );

        return isDragging ? null : schedulerData._isResizing()
          || config.eventItemPopoverEnabled == false
          || eventItem.showPopover == false ? (
              <div>
                  {connectDragPreview(connectDragSource(a))}
              </div>
            ) : (<>

{eventItem.availability_type == 'available' || eventItem.availability_type == 'not available' ?
<a  className="timeline-event"
            style={{ left, width, top}}>
                {/*this is for drag and drop only shifts not availability or vacations or absence */}
                {eventItem.source == 'shift' &&
                    !getEventSource ? (
                        <Popover placement="bottomLeft" content={content} trigger="hover">
                        
                        {connectDragPreview(connectDragSource(
    
                    <div
                
                onClick={() => {
                    if (eventItemClick) eventItemClick(schedulerData, eventItem);
                }}
                >
                        {eventItemTemplate}  
                        {startResizeDiv}
                        {endResizeDiv}
                    
                    </div>
                        ))}
                    </Popover>
                    ) : (
                        <Popover placement="bottomLeft" content={content} trigger="hover">
                        {/* {connectDragPreview(connectDragSource( */}
                        {/* this code is to remove drag and drop events from vacation and absence shifts */}
    
                    <div
                
                onClick={() => {
                    if (eventItemClick) eventItemClick(schedulerData, eventItem);
                }}
                >
                        {eventItemTemplate}  
                        {startResizeDiv}
                        {endResizeDiv}
                    
                    </div>
                        {/* // ))} */}
                    </Popover>  
                    ) 
                }
                
                {/* <Popover placement="bottomLeft" content={content} trigger="hover">
                    {console.log("inside popover before connect drag preview ")}
                    {connectDragPreview(connectDragSource(

                <div
            
            onClick={() => {
                if (eventItemClick) eventItemClick(schedulerData, eventItem);
            }}
            >
                    {eventItemTemplate}  
                    {startResizeDiv}
                    {endResizeDiv}
                
                </div>
                    ))}
                </Popover> */}
               
                {
                    toggle_todaybtn ?(
                        <>
                        {
                             getEndDate.getDate() === currentDate.getDate() && (
                                <>
                                {

!getEventSource && (

    <div className="set_threeDots" onClick={this.displyaBox}>
     <i className="fa fa-ellipsis-v " style={{fontSize:'10px',color:'#333'}} onClick={this.displyaBox}  />
       
       {
        this.state.showBox && (

            <div className="openBox">
             <div className="mb_18 boxHeading"  onClick={() => {
    if (eventItemClick) eventItemClick(schedulerData, eventItem);
}} style={{marginTop:'12px'}}><i className="fa fa-edit set_boxIcon" ></i>{this.props.t('Edit Shift')}</div>
           {eventItem.resourceId!=0 && (
               <div className="mb_18 boxHeading" onClick={this.moveToPending}><i className="fa fa-plus set_boxIcon"></i>{this.props.t('Shift to open')}</div>
           )} 
           {/* <div className="mb_18 boxHeading" ><i className="fa fa-copy set_boxIcon" ></i>{this.props.t('Copy Shift')}</div> */}
           <div className="mb_18 boxHeading" onClick={()=>{this.props.finish('delete');}} ><i className="fa fa-trash set_boxIcon" ></i>{this.props.t('Delete Shift')}</div>
           </div>
        )
       }
</div>
        )


                                }
                                </>
                             )
                        }
                        </>
                    ):(
                        <>
                        
                        {

                    //  this is for 3dots(menu button) only shifts operations( Edit or Delete)
                    !getEventSource && ( eventItem.source == 'shift' &&

                <div className="set_threeDots" onClick={this.displyaBox}>
                 <i className="fa fa-ellipsis-v " style={{fontSize:'10px',color:'#333'}} onClick={this.displyaBox}  />
                   
                   {
                    this.state.showBox && (

                        <div className="openBox">
                         <div className="mb_18 boxHeading"  onClick={() => {
                if (eventItemClick) eventItemClick(schedulerData, eventItem);
            }} style={{marginTop:'12px'}}><i className="fa fa-edit set_boxIcon" ></i>{this.props.t('Edit Shift')}</div>
                       {eventItem.resourceId!=0 && (
                           <div className="mb_18 boxHeading" onClick={this.moveToPending}><i className="fa fa-plus set_boxIcon"></i>{this.props.t('Shift to open')}</div>
                       )} 
                       {/* <div className="mb_18 boxHeading" ><i className="fa fa-copy set_boxIcon" ></i>{this.props.t('Copy Shift')}</div> */}
                       <div className="mb_18 boxHeading" onClick={()=>{this.props.finish('delete');}} ><i className="fa fa-trash set_boxIcon" ></i>{this.props.t('Delete Shift')}</div>
                       </div>
                    )
                   }
            </div>
                    )
                        }
                        </>
                    )
                }
            </a>
            :
<a  className="timeline-event"
            style={{ left, width, top}}>
                {/*this is for drag and drop only shifts not availability or vacations or absence */}
                {eventItem.source == 'shift' &&
                    !getEventSource ? (
                        <Popover placement="bottomLeft" content={content} trigger="hover">
                        
                        {connectDragPreview(connectDragSource(
    
                    <div
                
                onClick={() => {
                    if (eventItemClick) eventItemClick(schedulerData, eventItem);
                }}
                >
                        {eventItemTemplate}  
                        {startResizeDiv}
                        {endResizeDiv}
                    
                    </div>
                        ))}
                    </Popover>
                    ) : (
                        <Popover placement="bottomLeft" content={content} trigger="hover">
                        {/* {connectDragPreview(connectDragSource( */}
                        {/* this code is to remove drag and drop events from vacation and absence shifts */}
    
                    <div
                
                onClick={() => {
                    if (eventItemClick) eventItemClick(schedulerData, eventItem);
                }}
                >
                        {eventItemTemplate}  
                        {startResizeDiv}
                        {endResizeDiv}
                    
                    </div>
                        {/* // ))} */}
                    </Popover>  
                    ) 
                }
                
                {/* <Popover placement="bottomLeft" content={content} trigger="hover">
                    {console.log("inside popover before connect drag preview ")}
                    {connectDragPreview(connectDragSource(

                <div
            
            onClick={() => {
                if (eventItemClick) eventItemClick(schedulerData, eventItem);
            }}
            >
                    {eventItemTemplate}  
                    {startResizeDiv}
                    {endResizeDiv}
                
                </div>
                    ))}
                </Popover> */}
               
                {
                    toggle_todaybtn ?(
                        <>
                        {
                             getEndDate.getDate() === currentDate.getDate() && (
                                <>
                                {

!getEventSource && (

    <div className="set_threeDots" onClick={this.displyaBox}>
     <i className="fa fa-ellipsis-v " style={{fontSize:'10px',color:'#333'}} onClick={this.displyaBox}  />
       
       {
        this.state.showBox && (

            <div className="openBox">
             <div className="mb_18 boxHeading"  onClick={() => {
    if (eventItemClick) eventItemClick(schedulerData, eventItem);
}} style={{marginTop:'12px'}}><i className="fa fa-edit set_boxIcon" ></i>{this.props.t('Edit Shift')}</div>
           {eventItem.resourceId!=0 && (
               <div className="mb_18 boxHeading" onClick={this.moveToPending}><i className="fa fa-plus set_boxIcon"></i>{this.props.t('Shift to open')}</div>
           )} 
           {/* <div className="mb_18 boxHeading" ><i className="fa fa-copy set_boxIcon" ></i>{this.props.t('Copy Shift')}</div> */}
           <div className="mb_18 boxHeading" onClick={()=>{this.props.finish('delete');}} ><i className="fa fa-trash set_boxIcon" ></i>{this.props.t('Delete Shift')}</div>
           </div>
        )
       }
</div>
        )


                                }
                                </>
                             )
                        }
                        </>
                    ):(
                        <>
                        
                        {

                    //  this is for 3dots(menu button) only shifts operations( Edit or Delete)
                    !getEventSource && ( eventItem.source == 'shift' &&

                <div className="set_threeDots" onClick={this.displyaBox}>
                 <i className="fa fa-ellipsis-v " style={{fontSize:'10px',color:'#333'}} onClick={this.displyaBox}  />
                   
                   {
                    this.state.showBox && (

                        <div className="openBox">
                         <div className="mb_18 boxHeading"  onClick={() => {
                if (eventItemClick) eventItemClick(schedulerData, eventItem);
            }} style={{marginTop:'12px'}}><i className="fa fa-edit set_boxIcon" ></i>{this.props.t('Edit Shift')}</div>
                       {eventItem.resourceId!=0 && (
                           <div className="mb_18 boxHeading" onClick={this.moveToPending}><i className="fa fa-plus set_boxIcon"></i>{this.props.t('Shift to open')}</div>
                       )} 
                       {/* <div className="mb_18 boxHeading" ><i className="fa fa-copy set_boxIcon" ></i>{this.props.t('Copy Shift')}</div> */}
                       <div className="mb_18 boxHeading" onClick={()=>{this.props.finish('delete');}} ><i className="fa fa-trash set_boxIcon" ></i>{this.props.t('Delete Shift')}</div>
                       </div>
                    )
                   }
            </div>
                    )
                        }
                        </>
                    )
                }
            </a>
}

                  
                </>
            );
    }

    startResizable = props => {
        const { eventItem, isInPopover, schedulerData } = props;
        const { config } = schedulerData;
        return (
            config.startResizable === true
            && isInPopover === false
            && (eventItem.resizable == undefined || eventItem.resizable !== false)
            && (eventItem.startResizable == undefined || eventItem.startResizable !== false)
        );
    };

    endResizable = props => {
        const { eventItem, isInPopover, schedulerData } = props;
        const { config } = schedulerData;
        return (
            config.endResizable === true
            && isInPopover === false
            && (eventItem.resizable == undefined || eventItem.resizable !== false)
            && (eventItem.endResizable == undefined || eventItem.endResizable !== false)
        );
    };

    subscribeResizeEvent = props => {
        
        if (this.startResizer != undefined) {
            if (supportTouch) {
                // this.startResizer.removeEventListener('touchstart', this.initStartDrag, false);
                // if (this.startResizable(props))
                //     this.startResizer.addEventListener('touchstart', this.initStartDrag, false);
            } else {
                this.startResizer.removeEventListener('mousedown', this.initStartDrag, false);
                if (this.startResizable(props)) this.startResizer.addEventListener('mousedown', this.initStartDrag, false);
            }
        }
        if (this.endResizer != undefined) {
            if (supportTouch) {
                // this.endResizer.removeEventListener('touchstart', this.initEndDrag, false);
                // if (this.endResizable(props))
                //     this.endResizer.addEventListener('touchstart', this.initEndDrag, false);
            } else {
                this.endResizer.removeEventListener('mousedown', this.initEndDrag, false);
                if (this.endResizable(props)) this.endResizer.addEventListener('mousedown', this.initEndDrag, false);
            }
        }
    };
}

export default EventItem;
