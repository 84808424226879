import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import Button from '~/components/CustomButton/CustomButton.jsx';
import { Modal } from 'react-bootstrap';
import { Textarea } from '../../../elements/Input';
import { longDateFormat } from '../../../utils/dateFormat';
import DatePicker from '../../../components/DatePicker/DatePicker';
import moment from 'moment/min/moment-with-locales';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import { MembersWidget, MembersWidgetMultiple, MembersWidgetMultipleSelect } from '../../../components/GenericForm/Widgets/UsersWidget';
import TimePickerInput from '../../../components/TimePicker';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';
import agent from '~/library/agent';
import { getDay } from 'date-fns';

@inject('userStore', 'commonStore', 'scheduleStore')
@withRouter
@withLocalization
@observer
class CheckAvailabilityForm extends Component {
    state = {
        start_date: moment(new Date(this.props.start_date ? this.props.start_date : this.props.viewModel.startDate)).format('YYYY-MM-DD'),
        end_date: moment(new Date(this.props.end_date ? this.props.end_date : this.props.viewModel.endDate)).format('YYYY-MM-DD'),
        // end_date: moment(this.state.start_date).add(7, 'days'),
        defaultHolidays: [0, 1, 2, 3, 4, 5, 6],
        defaultDays: [{ day: 'Mon', checked: false }, { day: 'Tue', checked: false },
        { day: 'Wed', checked: false }, { day: 'Thu', checked: false },
        { day: 'Fri', checked: false }, { day: 'Sat', checked: false },
        { day: 'Sun', checked: false }, { day: 'Check All', checked: false }],
        assignees: [],
        comment: ''
    }

    async handleCancel(action) {
        this.props.finish(action);
    }

    checkAll = (value) => {
        const { defaultDays, defaultHolidays } = this.state;
        defaultDays.map(item => {
            item.checked = value;
        })
        this.setState({ defaultDays, defaultHolidays: [] });
    }

    handleCheck = (index, evt) => {
        const { defaultDays, defaultHolidays } = this.state;
        let isAllTrue = true;
        let holidays = defaultHolidays;

        if (evt === false && defaultDays[index].day != 'Check All') {
            holidays.push(index);
        }

        if (evt === true && defaultDays[index].day != 'Check All') {
            holidays = holidays.includes(index) ? holidays.filter(e => e != index) : holidays
        }

        if (defaultDays[index].day === 'Check All') {
            this.checkAll(evt);
        } else {
            defaultDays[index].checked = evt;
            for (let i in defaultDays) {
                if (defaultDays[i].day != 'Check All' && defaultDays[i].checked === false) {
                    isAllTrue = false;
                    break;
                }
            }
            defaultDays[7].checked = isAllTrue;
            this.setState({ defaultDays, defaultHolidays: holidays });
        }
    }

    handleChange = (name, data) => {
        this.setState({ [name]: data });
        if (name == 'end_date') {
            this.setState({
                end_date: moment(this.state.start_date).add(6, 'days').format('YYYY-MM-DD')
            })
        }
    }
    async handleSave() {
        const { t, commonStore } = this.props;
        let payload = {
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            assignees: this.state.assignees.includes(',') ? this.state.assignees.split(',') : [`${this.state.assignees}`],
            comment: this.state.comment,
        }
        await agent.Schedule.sendNotify(payload).then(() => {
            commonStore.addNotification(t('Saved'), null, 'success')
            this.handleCancel('cancel')
        }).catch((err) => {
            commonStore.addNotification(err.message || t('Error'), null, 'error');
        });
    }


    render() {
        const { t, commonStore } = this.props
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';

        return (
            <Modal dialogClassName="lg bulk-shift-modal" className="lg" size="lg" show onHide={() => this.handleCancel('cancel')}>
                <Modal.Header closeButton className="set_ModalHeader" >
                    <Modal.Title style={{ fontFamily: 'Roboto', fontSize: '18px', fontWeight: 'normal' }}>{this.props.t('Create / edit availability')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="col-md-6">
                                <div className="form-group field field-string">
                                    <label className="control-label">{t('From date')} *</label>
                                    <br />
                                    <DatePicker
                                        selected={new Date(this.state.start_date)}
                                        onChange={(date) => this.handleChange('start_date', moment(date).format('YYYY-MM-DD'))}
                                        dateFormat={longDateFormat(dateTimeRules)}
                                        filterDate={date => getDay(date) === 1}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group field field-string">
                                    <label className="control-label">{t('To date')} *</label>
                                    <br />
                                    <DatePicker
                                        selected={new Date(this.state.end_date)}
                                        onChange={(date) => this.handleChange('end_date', moment(date).format('YYYY-MM-DD'))}
                                        dateFormat={longDateFormat(dateTimeRules)}
                                        filterDate={date => getDay(date) === 0}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group field field-string">
                                    <label className="control-label">{t('Select User To check Availability')} *</label>
                                    <MembersWidgetMultipleSelect
                                        className="form-control"
                                        onChange={(value) => { this.setState({ assignees: value }) }}
                                        value={'all'}
                                        defaultIfOne
                                    />
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <label className="control-label fs15">{t('Comment')}</label>
                                <Textarea
                                    value={this.state.comment}
                                    style={{ width: '100%', height: '50%' }}
                                    onChange={(data) => this.handleChange('comment', data.target.value)}
                                    placeholder={t('Enter comments here')}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Button fill wd icon type="submit"
                                style={{ marginLeft: 30, marginRight: 30, marginTop: 10 }}
                                onClick={values => this.handleSave('save', values)}>
                                {' '}
                                {t('Send Availability Request')}
                            </Button>
                            <Button wd icon style={{ marginTop: '10px' }} type="submit"
                                onClick={() => this.handleCancel('cancel')}>
                                {' '}
                                {t('Cancel')}
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default CheckAvailabilityForm