import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import SubscriptionsList from './SubscriptionsList';
import SubscriptionsForm from './SubscriptionsForm'

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

@inject('authStore', 'subscriptionStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class Subscriptions extends Component {

    onAdd() {
        const { history } = this.props;
        history.push('/admin/subscriptions/add');
    }

    onTableAction(id, type) {
        const { history, commonStore, t } = this.props;
        if (type == 'remove')
            return this.props.handleDelete(id, id => {
                return this.props.subscriptionStore.remove(id).then(res => {
                commonStore.addNotification(t('Deleted'), null, 'error');
                history.push('/admin/subscriptions/');
            });
        });
        history.push(`/admin/subscriptions/${id}`);
    }

    editingId = () => {
        let id = null;
        if (this.props.location.pathname !== this.props.match.path) {
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }
        return { editing: !!id, add: id === 'add', id };
    };

    render() {
        const mode = this.editingId();

        return (
            <div className="main-content">
                {!mode.editing && (
                    <SubscriptionsList
                        onTableAction={(id, type) => this.onTableAction(id, type)}
                        onAdd={() => this.onAdd()}
                    />
                )}
                {mode.editing && <SubscriptionsForm id={mode.id} add={mode.add} />}
            </div>
        );
    }
}
export default Subscriptions;
