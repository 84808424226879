import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import MonitoringList from './MonitoringList';
import MonitoringForm from './MonitoringForm';
import MonitoringGrid from './MonitoringGrid';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import MonitoringHistory from './MonitoringHistory';
import style from './style.css'

@inject('authStore', 'userStore')
@withRouter
@withRemoveDialog
@observer
class Monitoring extends Component {
    //added state to maintain the genericList and genericForm states in parent component//
    state={
        mode: 'view',
        editId: 0,
        page:0,
        historyId: 0,
        updateId: 0,
        isPageAddedOrEdited:false,

    }

    //removed URL pushing//
    onAdd() {
        const { history } = this.props;
        history.push('/admin/monitor/add');
        this.setState({mode:'add',isPageAddedOrEdited:true});        
    }

    onFormSave() {
        const { history } = this.props;
        history.push('/admin/monitor/addGrid');
        this.setState({mode:'save',isPageAddedOrEdited:true})
    }
    onFormCancel () {        
        const { history } = this.props;
       
        history.push('/admin/monitor');
        this.setState({mode:'view' ,isPageAddedOrEdited:true})
    }

    onTableAction(id, type) {
        const { history } = this.props;
        if (type == 'history') {
            history.push(`/admin/monitor/${id}`);
            this.setState({historyId: id, mode:'history',isPageAddedOrEdited:true});
            return;
        }
        if (type == 'save') {
            history.push(`/admin/monitor/editGrid/${id}`);
            this.setState({mode:'save',isPageAddedOrEdited:true});
            return;
        }
       
        if (type == 'gridEdit') {
            history.push(`/admin/monitor/${id}`);
            this.setState({ updateId: id, mode:'gridEdit',isPageAddedOrEdited:true});
            return;
        }
        if (type == 'edit') {
            history.push(`/admin/monitor/${id}`);
            this.setState({mode:'edit', editId: id,isPageAddedOrEdited:true});
            return;
        }
    }
    
    changeMode = (mode) =>{ 
        this.setState({mode});
    }
    //this method is replacing the URL pushing part while add/edit//
    goBack = () =>{        
        // const mode = {id:0,add:false};
        this.setState({mode:"view"});
    }

    //method to maintain react-table page in parent component//
    handlePageChange = (page,isEdit) => {
        this.setState({page:page,isPageAddedOrEdited:isEdit});        
    }

    render() {
        const {mode, editId, page, isPageAddedOrEdited}= this.state;
        
        return (
            <div className="main-content monitor-content">
                {(mode == 'view')  && 
                    <MonitoringList
                        onTableAction={(id, type) => this.onTableAction(id, type)}
                        onAdd={() => this.onAdd()}
                        handlePageChange={(page, isEdit) => this.handlePageChange(page, isEdit)}
                        page={page}
                        isPageAddedOrEdited={isPageAddedOrEdited}
                    />
                }
                {(mode == 'add') &&
                    <MonitoringForm
                        onFormSave={() => this.onFormSave()}
                        goBack={() => this.goBack()} 
                        onFormCancel={() => {this.onFormCancel()}}
                        
                        />
                }
                {(mode == 'edit') &&
                    <MonitoringForm
                        onFormSave={() => this.onFormSave()}
                        id={this.state.editId}
                        onFormCancel={() =>{ this.onFormCancel()}}
                    />
                }
                {(mode == 'save' || mode == 'gridEdit') &&
                    <MonitoringGrid
                        changeMode={this.changeMode}
                        id={this.state.updateId}
                        onFormCancel={() =>{ this.onFormCancel()}} />
                }
                {(mode == 'history') &&
                <>
                    <MonitoringHistory
                        id={this.state.historyId} />
                </>
                }

                
            </div>
        );
    }
}
export default Monitoring;
