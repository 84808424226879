// Core
import React, { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Popup, Marker } from 'react-leaflet';

// Leaflet
import L from 'leaflet';

export default class MapMarker extends PureComponent {
    getIcon = () => L.divIcon({
        className: 'custom-marker',
        html: ReactDOMServer.renderToString(
            <i className={`custom-marker__icon ${this.props.isActive ? 'custom-marker__icon_active' : ''}`} />
        ),
        iconSize: [50, 50],
        iconAnchor: [25, 50],
    });

    render() {
        const {
            title, position, onClick, id
        } = this.props;
        return (
            <Marker icon={this.getIcon()} id={id} position={position || [50, 50]} onClick={onClick}>
                <Popup>{title}</Popup>
            </Marker>
        );
    }
}
