import { observable, action, computed } from 'mobx';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';

// import getBase64 from '~/utils/getBase64';
import agent from '../agent';
import * as scheduleUtils from '../../utils/scheduleUtils';

class ScheduleStore {
    @observable currentList = {};

    @observable currentAvailabilityList = {};

    @observable resources = null;

    @observable currentEntity = {};

    @observable availabiltyData = {};

    @observable isAvailabilityData = false;

    @observable updatingErrors = null;

    @observable updating = false;

    @observable loading = false;

    @observable requestParams = null;

    @observable deleteSuccess = false;

    @observable userData = null;
    @observable dataOnHover = null;

    @observable lastListLoadTime = null;

    @observable pendingCountsInAvailability = null;

    @action resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    @action setLoading(value) {
        this.loading = value;
    }

    @observable filters = {
        status: '',
        name: '',
    };

    @observable appliedFilters = {
        status: '',
        name: '',
    };

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
        // requestParams.filters = this.filters;
        // this.loadList(requestParams);
    }

    @action loadAvailabilityList(params) {
        return agent.Schedule.availabilityList(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    @action async loadByUser(params = {}) {
        this.loading = true;
        const list = await agent.Schedule.list(params);
        this.requestParams = params;
        this.userData = list;
        return list;
    }

    @action async loadResources(params) {
        if (!this.resources) {
            return agent.Schedule.loadResources().then(
                action((list) => {
                    let r;
                    if (params && params.userType == 'member') {
                        const filtered = _.filter(list, (r) => {
                            return r.id === 0 || r.id === params.userId;
                        });
                        r = filtered;
                    } else {
                        r = list;
                    }
                    r.forEach((res) => {
                        if (res.id === 0 && params.t) res.name = params.t(res.name);
                    });
                    this.resources = r;
                })
            );
        }
        return this.resources;
    }
// datas
    @action async loadEverything(params = {}, dateTimeRules) {
        await this.loadResources(params);
        const list = await agent.Schedule.list(params);
        // const listHover = await agent.Schedule.listHover(params)
        // this.dataOnHover = scheduleUtils.transformToSchedulerAvailabilityList(list, dateTimeRules);
        this.currentAvailabilityList = scheduleUtils.transformToSchedulerAvailabilityList(list, dateTimeRules);
        this.requestParams = params;
        this.pendingCountsInAvailability = list.pendingCount
        this.currentList = scheduleUtils.transformToSchedulerEventList(list, dateTimeRules);
        this.loading = false;
    }

    @action loadList(params, dateTimeRules) {
        return agent.Schedule.list(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    this.currentList = scheduleUtils.transformToSchedulerEventList(list, dateTimeRules);
                    this.loading = false;
                    return this.currentList;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }
    @action loadAvailabilityDataList(params, dateTimeRules) {
        return agent.Schedule.list(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    this.currentAvailabilityList = scheduleUtils.transformToSchedulerAvailabilityList(list, dateTimeRules);
                    this.loading = false;
                    return this.currentAvailabilityList;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action returnDefaultNew(params) {
        if (params) {
            this.currentEntity = Object.assign({}, params);
        }
        if (this.currentEntity && !this.currentEntity.data) this.currentEntity.data = {};
        this.loading = false;
    }

    @action changeEntity(name, value, kind) {
        const entity = Object.assign({}, this.currentEntity);
        const nameArray = name.split('.');
        if (nameArray.length === 2) {
            const [firstProp, secondProp] = nameArray;
            entity[firstProp] = entity[firstProp] || {};
            entity[firstProp][secondProp] = value;
        } else {
            entity[name] = value;
        }
        this.currentEntity = entity;
    }

    @action load(id) {
        this.loading = true
        return agent.Schedule.load(id)
            .then(
                action((response) => {
                    this.currentEntity = response.shift;
                    this.loading = false;
                    return response.shift;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action saveAvailability(values) {
        this.updating = true;
        return agent.Schedule.saveAvailability(values)
            .then(
                action((user) => {
                    this.currentEntity = user.shift;
                    return this.currentEntity;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }
    
    @action async removeAvailability(id) {
        const res = await agent.Schedule.removeAvailability(id);
        this.deleteSuccess = true;
        // return 1;
        return res;
    }

    @action updateStatus(id, data) {
        this.updating = true;
        return agent.Schedule.updateStatus(id, data)
            .then(
                action((user) => {
                    this.currentEntity = user.shift;
                    return this.currentEntity;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action getAvailability(values){
        this.isAvailabilityData = true;
        values.start_date = moment(values.start_date).format('YYYY-MM-DD');
        values.end_date = moment(values.end_date).format('YYYY-MM-DD');
        let multiUser = false;
        if(values && values.userIDs){
            multiUser = true;
        }
        return agent.Schedule.getAvailability(values)
            .then(
                action((res) =>{
                    // console.log("res : ",res);
                    if(!multiUser)
                    {
                        this.availabiltyData = res.availability[0];
                    }
                    else{
                        this.availabiltyData = res.availability;
                    }
                    this.isAvailabilityData = false;
                    return this.availabiltyData;
                })
            ) 
            .catch(
                action((err)=>{
                    this.isAvailabilityData = false;
                    throw err;
                })
            )
    }

    @action save(values, isAdd) {
        this.updating = true;
        values.for_date = moment(values.for_date).format('YYYY-MM-DD');
        return agent.Schedule.save(values, isAdd)
            .then(
                action((user) => {
                    this.currentEntity = user.shift;
                    this.currentEntity.do_notify = values.do_notify
                    this.clearCurrentEntity()
                    return this.currentEntity;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action addBulkShift(values) {
        this.updating = true;
        values.start_date = moment(values.start_date).format('YYYY-MM-DD');
        values.end_date = moment(values.end_date).format('YYYY-MM-DD');
        return agent.Schedule.addBulkShift(values)
            .then(
                action((user) => {
                    this.updating = false;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
    }
    @action updateAvailabilityStatus(id, values) {
        this.updating = true;
        return agent.Schedule.updateAvailabilityStatus(id, values)
            .then(
                action((res) => {
                    this.updating = false;
                    return res;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
    }

    @action async memberAction(action, currentUser, extras) {
        this.updating = true;
        if (!extras) extras = {};
        extras.proposed = this.currentEntity.data.proposed || null;
        const changes = await agent.Schedule.memberAction(this.currentEntity.id, action, {
            action,
            comment: this.currentEntity.comment,
            extras,
        });
        this.updating = false;
        return changes;
    }

    @action async move(data) {
        this.updating = true;
        const changes = await agent.Schedule.move(data);
        this.updating = false;
        return changes;
    }

    @action async copy(data) {
        this.updating = true;
        const changes = await agent.Schedule.copy(data);
        this.updating = false;
        return changes;
    }

    @action async copyToNextWeek(params) {
        this.updating = true;
        const changes = await agent.Schedule.copyToNextWeek(params);
        this.updating = false;
        return changes;
    }

    @action async remove(id) {
        await agent.Schedule.remove(id);
        this.deleteSuccess = true;
        return 1;
    }
    @action async copyMonth(data) {
        this.updating = true;
        const changes = await agent.Schedule.copyMonth(data);
        this.updating = false;
        return changes;
    }
    @action clearCurrentEntity() {
        this.currentEntity.id = '';
    }
}

export default new ScheduleStore();
