import {
    observable, action, reaction, runInAction, computed
} from 'mobx';

import agent from '../agent';


const currentContractInit = {
    //default values to initiate currentDocument//
    id: 0,
   name:'',
   country:'',
   module:''
}

class ContractStore {
    
    @observable currentContractList = [];

    @observable currentEntity = currentContractInit;

    @observable contractTypesList = [];

    @observable currentContractDetails = null;

    @observable contractEditorDetails = null
    
    @observable lastListLoadTime = null;

    @observable isEditMode = false;

    @observable archive_record_content = null;

    @observable filters = {
        country: '',
    };

    @observable appliedFilters = {
        country: '',
    };

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    @action setEditMode(){
        this.isEditMode = true;
    }

    @action resetList(){
        this.currentContractDetails = null;
        this.contractEditorDetails = null;
    }

    @action resetLastListLoadTime() {        
        this.lastListLoadTime = new Date();
    }

    @action resetArchiveRecordContent(){
        this.archive_record_content = null;
    }

    @action getContractTypes(params) {
        return agent.contracts
            .getContractTypes(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                  //  this.currentContractList = list;
                  
                  this.contractTypesList = list.rows;
                    return list;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    @action getPDFData(id) {
        return agent.contracts
            .getPDFData(id)
            .then(
                action((res) => {
                    return res;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

  
    @action addContractType(data) {
        return agent.contracts.addContractType(data).then(
            action((response) => {
                return response;
            })
        )
            .catch(
                action((error) => {
                    throw error;
                })
            )
    }

    @action saveContractArchives(data){
        return agent.contracts.saveContractArchives(data).then(
            action((response) => {
                this.archive_record_content = response.contract_archive.body;
                return response;
            })).catch(
                action((error) => {
                    throw error;
                })
            )
    }

    @action getContractTypesById(id) {
        return agent.contracts.getContractTypesById(id)
            .then(
                action((response) => {
                    if (!response.contract_type) response.contract_type = {};
                    return response;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            )
    }


    @action  UpdateContractTypeById(contract_type) {
         return agent.contracts.UpdateContractType(contract_type).then(
            action((response) => {
                return response;
            })
        ).catch(
                action((error) => {
                    throw error;
                })
         )
    
    }

   
    @action async deleteContractTypeById(contract_type,id) {
        await agent.contracts.deleteContractById(contract_type.id).then(
            action((response) => {
                return response;
            })
        ).catch(
                action((error) => {
                    throw error;
                })
            )
    }
  

    @action getContracts(params) {
        return agent.contractslist
            .getContractsData(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentContractList = list;
                    return list;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    @action getContractsById(id) {
      //  this.loading = true;
        return agent.contractslist.getContractsById(id)
            .then(
                action(response => {
                    if (!response.contracts) response.contracts = {};
                  //  this.currentContractList = response;
                 //   this.loading = false;
                this.currentContractDetails = response.contract_record;
                this.contractEditorDetails = response.contract_document_record;
                    return response;
                })
            )
            .catch(
                action(err => {
                //    this.loading = false;
                    throw err;
                })
            );
    }

    @action loadContractById = async (id) => {
        //sets editable document values from list to currentDocument//
        //takes 1 argument, type - integer, id//
        if (id && id > 0) {
            await this.getContractsById(id).then((data) => {
                this.currentEntity.id = data.contracts.id;
                this.currentEntity.name = data.contracts.name;
                this.currentEntity.country = data.contracts.country;
                this.currentEntity.module = data.contracts.module;

               
                // this.contractslist.created_by_id = data.document.created_by_id;
                // this.contractslist.ispdf = data.document.ispdf;
                // this.contractslist.pdf_attachment = data.document.pdf_attachment;
                return true;
            })
        }
    }

      
    @action addContractDataList(data) {
        return agent.contractslist.addContractData(data).then(
            action((response) => {
                this.currentContractDetails = response.contract_details;
                return response;
            })
        )
            .catch(
                action((error) => {
                    throw error;
                })
            )
    }


    @action async deleteContracts(id) {
        await agent.contractslist.deleteContractsData(id).then(
            action((response) => {
                return response;
            })
        ).catch(
                action((error) => {
                    throw error;
                })
            )
    }

    @action  UpdateContractsById(contracts) {
        return agent.contractslist.UpdateContracts(contracts).then(
           action((response) => {
               return response;
           })
       ).catch(
               action((error) => {
                   throw error;
               })
        )
   
   }

//    @action updateContractsData(values, isAdd) {
//     this.updating = true;
//     return agent.contractslist.UpdateContracts(values, isAdd)
//         .then(
//             action(user => {
//                 this.currentEntity = user;
//                 return user;
//             })
//         )
//         .catch(
//             action(err => {
//                 this.updating = false;
//                 this.updatingErrors = err.response && err.response.body && err.response.body.errors;
//                 throw err;
//             })
//         );
// }
@action loadDefaultContract() {
    //initiates currentDocument for new document//
    this.currentEntity = currentContractInit;
}

//             })
//         );
// }
@action loadDefaultContract() {
    //initiates currentDocument for new document//
    this.currentEntity = currentContractInit;
}
@action loadEditorData(id){
    return agent.contracts.loadEditorDataById(id).then(
        action((response) => {
            return response;
        })
    )
        .catch(
            action((error) => {
                throw error;
            })
        )

}

@action loadEditorData(id){
    return agent.contracts.loadEditorDataById(id).then(
        action((response) => {
            return response;
        })
    )
        .catch(
            action((error) => {
                throw error;
            })
        )

}

@action archivesPDFDownload(id){
    return agent.contracts.archivesPDFDownload(id).then(
        action((response) => {
            return response;
        })
    )
        .catch(
            action((error) => {
                throw error;
            })
        )

}

@action saveEditorData(data){
    return agent.contracts.saveEditorData(data).then(
        action((response) => {
            return response;
        })
    )
        .catch(
            action((error) => {
                throw error;
            })
        )

}

}

export default new ContractStore();
