import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import GenericList from '~/components/GenericList/GenericList';

// Elements

@inject('userStore', 'commonStore','recipientStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class RecipientsList extends Component {
    state = {
        nowEditingId: null,
        currenttab: null,
        currentUserId : 0,
        userAttachmentIds:"",
        showRenewModal:false,
        attachmentList:[],
    };

    renderTableActionButtons = (id, user_type, attachments) => (
        <div className="actions-center">
                    
            <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                <i className="fa fa-edit" />
            </Button>
            <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                <i className="fa fa-times" />
            </Button>            
        </div>
    );

    renderHeader = () => {
        const {
             t, onAdd, 
        } = this.props;
              
        return (
            <div>
                <div className="table-list__header" style={{ flexWrap: 'nowrap' }}>
                    <Button fill wd icon onClick={() => onAdd()}>
                        {t('Add new')} <i className="fa fa-plus" />
                    </Button>
                </div>
                
            </div>
        );
    };

   
    render() {
        const { mode } = this.props;

        return (
            <Fragment>
                <GenericList
                    columns={[
                        {
                            Header: 'Name',
                            id: 'user_name',
                            accessor: 'user_name',
                        },
                        {
                            Header: 'Phone',
                            id: 'phone',
                            accessor: 'phone',
                        },
                        {
                            Header: 'Email',
                            id: 'email',
                            accessor: 'email',
                        },
                        // {
                        //     Header: 'Operation',
                        //     id: 'operation',
                        //     sortable: false,
                        //     accessor: ({ id, user_type, attachments }) => this.renderTableActionButtons(id, user_type, attachments),
                        // },
                    ]}
                    lastListLoadTime={this.props.recipientStore.lastListLoadTime}
                    requestData={params => this.props.recipientStore.loadList(params, mode)}
                    header={this.renderHeader()}
                />
               
            </Fragment>
        );
    }
}
export default RecipientsList;
