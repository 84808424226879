import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import customerFeature from '../../../utils/customerFeature';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { serverTimeFormat } from '~/library/core';
import { shortDateFormat } from '~/utils/dateFormat'
import config from '~/library/config';
import agent from '~/library/agent';
// Components
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Modal } from 'react-bootstrap';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import ChecklistTable from './ChecklistTable'

// import ElementList from './ElementList';
import ChecklistTemplateList from './CheckListTemplate/ChecklistTemplateList';
import ChecklistAssign from './ChecklistAssign';
import GenericList from '~/components/GenericList/GenericList';
import ChecklistCheck from './ChecklistCheck';

import '../../../styles/css/styles.css';
import ChecklistsFilter from './ChecklistsFilter';
import ChecklistAccept from './ChecklistAccept';
import SwitchWidget from '../ControlPanel/SwitchWidget';

@inject('checklistStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ChecklistTableAd extends Component {

    state = {
        add: false,
        currentChecklistId: 0,
        showChecklistTodo: false,
        showChecklistAccept: false,
        currentChecklist: {},
        sureModal: false,
        isGoBack : false
    };

    handleSwitch = (id, value, index) => {
        const { t, commonStore, checklistStore } = this.props;
        const { loading, currentChecklist: checklist } = this.props.checklistStore;

        let hydrated = {
            id: id,
            // user_id: checklist.user_id,
            // project_id: checklist.project_id,
            // checklist_templates_id: checklist.checklist_templates_id,
            //to_project: checklist.to_project,
            cornjob_type: checklist.cornjob_type,
            cornjob_duration: checklist.cornjob_duration,
        };
        checklistStore.scheduleToNone(hydrated).then((res) => {
                this.props.checklistStore.resetLastListLoadTime();
                commonStore.addNotification(t('Successfully turned off the schedule to None'), null, 'success');
            })
            .catch((err) => {
                commonStore.addNotification(err.message || t('Cant able to find id'), null, 'error');
            });
    }

    renderTableActionButtons = (id, full) => {
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const { t } = this.props;
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');
        const actionable = full.status != 'approved';

        let statusText = full.status;
        if (statusText != "Approved") statusText = 'active';
        let statusIcon = <i className={'pe-7s-hourglass'} />;
        if (full.status == 'Approved') {
            statusIcon = <i className={'pe-7s-check'} />;
        }

        return(
            <div >
                <SwitchWidget
                    value={full.job_type != 'None' ? true : false}
                    onChange={(value) => this.handleSwitch(id, value)}
                    disabled = {full.job_type != 'None' ? false : true }
                />
            </div>
        )
    };

    viewChecklists = () => {  
        this.setState({isGoBack: true}) 
    };
    viewChecklistAssign = () => {
        this.setState({ add: true, currentChecklistId: 0 });
        this.renderChecklistAssignModal();
    };

    renderChecklistAssignModal = () => {
        const { t } = this.props;
        this.setState({
            showChecklistAssign: true
        });
    };
    handleClosesChecklisAssignModal = () => {
        this.setState({ showChecklistAssign: false, add: false });
        this.props.checklistStore.returnDefaultNew();
    };

    applyForm = (params = {}) => {
        const { loading, currentChecklist: checklist } = this.props.checklistStore;
        const { t, commonStore, checklistStore } = this.props;

        if (checklist.project_id == null || checklist.project_id == "" ||
            checklist.checklist_templates_id == null || checklist.checklist_templates_id == "") {
            commonStore.addNotification(t('Please fill all required fields'), null, 'error');
            return;
        }

        if ((checklist.cornjob_type === "Gap Between Days" && (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === ""))
            || (checklist.cornjob_type === "Weekdays" && (checklist.cornjob_duration === "" || !isNaN(checklist.cornjob_duration))
            || (checklist.cornjob_type === "Monthly" && (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === ""))
            || (checklist.cornjob_type === "Half Yearly" && (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === ""))
            || (checklist.cornjob_type === "Annually" && (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === ""))
            )) {
            commonStore.addNotification(t('Please fill all required fields'), null, 'error');
            return;
        }
        let hydrated = {
            user_id: checklist.user_id,
            project_id: checklist.project_id,
            checklist_templates_id: checklist.checklist_templates_id,
            //to_project: checklist.to_project,
            isSendEmail: checklist.isSendEmail,
            cornjob_type: checklist.cornjob_type,
            cornjob_duration: checklist.cornjob_duration,
            isobligatory: checklist.isobligatory,
            allow_check_all: checklist.allow_check_all
        };
        if (checklist.id && checklist.id > 0) {
            hydrated['id'] = checklist.id;
        }
        //if (hydrated.checklist_templates_id && (hydrated.user_id || hydrated.to_project )) {
            // console.log("hydrated:",hydrated)
        checklistStore
            .assignChecklist(hydrated)
            .then((res) => {
                this.props.checklistStore.resetLastListLoadTime();
                commonStore.addNotification(t('Checklist assign sucessfully.'), null, 'success');
                this.handleClosesChecklisAssignModal();
            })
            .catch((err) => {
                commonStore.addNotification(err.message || t('Please fill all required fields'), null, 'error');
            });
    };

    setStatusColor = (status) => {
        let color = "";
        switch (status) {
            case 'Done':
                color = 'green';
                break;

            case 'Approved':
                color = 'green';
                break;            

            case 'Deviation':
                color = 'red';
                break;

            case 'Under work':
                color = '#ff8c00';
                break;

            default:
                color = 'blue';            
        }
        return color;
    }

    render() {
        const {  t, isUnionMode } = this.props;
        const { loading, filters, appliedFilters } = this.props.checklistStore;
        const {config} = this.props.commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        return (
            <>
            {this.state.isGoBack &&
                <ChecklistTable />
            }
                {!this.state.isGoBack &&
                    <div>
                        <div>
                            <div className="table-list">
                                <div className="table-list__header">
                                    {this.props.userStore.currentUser.user_type != 'member' &&
                                        <Button fill wd icon onClick={() => this.viewChecklists()}>
                                            {t('All')} {t('Checklists')}
                                        </Button>
                                    }
                                    <Button fill wd icon onClick={() => this.viewChecklistAssign()}>
                                        {t('Do Checklists')}
                                    </Button>
                                </div>
                                <GenericList
                                    columns={[
                                        {
                                            Header: 'Id',
                                            id: 'id',
                                            sortable: false,
                                            accessor: 'id',
                                        },
                                        {
                                            Header: 'Name',
                                            id: 'name',
                                            accessor: ({ id, name }) => (
                                                <a
                                                // className="list-main-column"
                                                // onClick={() => this.handleTableButtonAction(id, 'edit')}
                                                >
                                                    {`${name}`}
                                                </a>
                                            ),
                                        },
                                        {
                                            Header: 'User',
                                            id: 'user_name',
                                            // accessor: ({ first_name, last_name }) => {`${first_name}` + `${last_name}`},
                                            accessor: ({ first_name, last_name, to_project }) => (
                                                to_project == false ? <span>{`${first_name}` + ' ' + `${last_name}`}</span> : "To Project"
                                            ),
                                            sortable: false,
                                        },
                                        {
                                            Header: 'Project',
                                            id: 'project_name',
                                            sortable: false,
                                            accessor: 'project_name',
                                        },
                                        {
                                            Header: 'Assigned by',
                                            id: 'client_name',
                                            sortable: false,
                                            accessor: ({ created_by_first_name, created_by_last_name }) => (
                                                <span>{`${created_by_first_name}` + ' ' + `${created_by_last_name}`}</span>
                                            ),
                                        },
                                        {
                                            Header: 'Status',
                                            id: 'status',
                                            sortable: true,
                                            accessor: ({ status }) => <span
                                                style={{
                                                    color: this.setStatusColor(status)
                                                }}>{status}</span>,
                                        },
                                        {
                                            Header: 'Last Updated',
                                            id: 'updated_at',
                                            accessor: ({ updated_at }) => (
                                                <span>{updated_at && shortDateFormat(updated_at, dateTimeRules)}</span>
                                            ),
                                        },
                                        {
                                            Header: 'Next Schedule',
                                            id: 'next_schedule',
                                            accessor: ({ next_day }) => (
                                                <span>{next_day && shortDateFormat(next_day, dateTimeRules)}</span>
                                            ),
                                        },
                                        {
                                            Header: 'Actions',
                                            id: 'actions',
                                            sortable: false,
                                            accessor: (full) => this.renderTableActionButtons(full.id, full),
                                        },
                                    ]}
                                    forceReload={
                                        this.state.checklistChanged == true ||
                                        this.props.checklistStore.deleteSuccess ||
                                        this.props.checklistStore.addOrUpdated
                                    }
                                    filters={appliedFilters}
                                    lastListLoadTime={this.props.checklistStore.lastListLoadTime}
                                    header={
                                        <ChecklistsFilter
                                            filters={filters}
                                            setFilter={(name, value) => this.props.checklistStore.setFilter(name, value)}
                                            onFilter={() => this.props.checklistStore.onFilter()}
                                            loading={loading}
                                        />
                                    }
                                    requestData={(params) => this.props.checklistStore.getChecklistsFilter(params)}
                                />
                            </div>
                        </div>
                    </div>
                }
                <Modal
                    className="modal right fade"
                    show={this.state.showChecklistAssign}
                    onHide={this.handleClosesChecklisAssignModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Do Checklists')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ChecklistAssign
                            add={this.state.add}
                            id={this.state.currentChecklistId}
                            handleClosesChecklisAssignModal={this.handleClosesChecklisAssignModal}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.applyForm} className="btn-wd btn-fill">
                            {t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleClosesChecklisAssignModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
export default ChecklistTableAd;
