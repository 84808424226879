import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import CustomButton from '../CustomButton/CustomButton';

import { SummaryPos } from './index';

class Summary extends Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
        summary: PropTypes.object.isRequired,
        left: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        top: PropTypes.number.isRequired,
    };

    render() {
        const {
            summary, left, width, top, schedulerData, click
        } = this.props;
        const { config } = schedulerData;
        let color = config.summaryColor;
        if (summary.color != undefined) color = summary.color;
        let textAlign = 'center';
        if (config.summaryPos === SummaryPos.TopRight || config.summaryPos === SummaryPos.BottomRight) textAlign = 'right';
        else if (config.summaryPos === SummaryPos.TopLeft || config.summaryPos === SummaryPos.BottomLeft) textAlign = 'left';
        let style = { height: config.eventItemHeight };
        if (summary.fontSize != undefined) style = { ...style, fontSize: summary.fontSize };

        return (
            <span className="timeline-event header2-text add-more-button" style={{ left, width, top }}>
                <div style={style} onClick={() => click()}>
                    <CustomButton roundSmaller>
                        <i className="glyphicon glyphicon-plus" />
                    </CustomButton>
                </div>
            </span>
        );
    }
}

export default Summary;
