import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import LoadingSpinner from '../../../elements/LoadingSpinner';
import withLocalization from '~/hoc/withLocalization';
import { withRouter } from 'react-router-dom';
import { serverTimeFormat } from '~/library/core';
import { minutesToTimeInput } from '../../../utils/timeCalc'
import * as timeUtils from '../../../utils/dateFormat';
import moment from 'moment/min/moment-with-locales';
import { shortDateFormat } from '~/utils/dateFormat'



@inject('monitorStore', 'userStore', 'commonStore')
@withLocalization
@withRouter
@observer

export class MonitoringHistoryTable extends Component {
    render() {
        const { monitorStore, commonStore, t } = this.props;        
        let for_date = ''
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && 
                              commonStore.config.client.data.dateTimeRules ? 
                              commonStore.config.client.data.dateTimeRules : false;

        if (!monitorStore.tableListDataHistory.length) {
            return (<>
                <div className='no-data-found'> <p>{t('No Data Found!!!')}</p></div>
            </>)
        }

        
        if (monitorStore.loading) {
            return <LoadingSpinner />
        }

        
        return (
            <>
                {
                    monitorStore.tableListDataHistory.map((data, index) => {
                        return (
                            <div key={index}>
                                <div className="monitoring-table-container" >
                                    <table className="monitoring-table" style={{width: '70%', border: '1px solid #ddd' }}>
                                        <thead>
                                            <tr>
                                                <th style={{border: '1px solid #ddd', padding: 10}}>{t('Date')}</th>
                                                <th style={{border: '1px solid #ddd', padding: 10}}>{t('Name')}</th>
                                                <th style={{border: '1px solid #ddd', padding: 10}}>{t('Project')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{border: '1px solid #ddd', padding: 10}}>{shortDateFormat(data.for_date, dateTimeRules)}</td>
                                                <td style={{border: '1px solid #ddd', padding: 10}}>{data.name}</td>
                                                <td style={{border: '1px solid #ddd', padding: 10}}>{data.p_name}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot></tfoot>
                                    </table>
                                </div>
                                   {/* <div>
                                        {data.for_date}
                                    </div>
                                    <div>
                                        {data.name}
                                    </div>
                                    <div>{data.project}</div> */}
    
                                {
                                    data.tableListData.map((tabElement, tabIndex) => {
                                        return (
                                            <div className='mon-tab-list myDiv' style={{overflow: this.props.styling}} key={tabIndex}>
                                                <div className='m-table' id="m-table-end">
                                                    {
                                                        tabElement.meta.products.map((element, productIndex) => {
                                                            if (!productIndex) {
                                                                return (
                                                                    <div key={element} className='m-column list-column'>
                                                                        <div className='m-block' id="m-block-odd"><div className='itemList' data-toggle="tooltip" data-placement="top" title={element}>{element}</div></div>
                                                                        {
                                                                            tabElement.meta.items.map((element, index) => {
                                                                                return (
                                                                                    <div key={element} className='m-block' id='m-block-even'>
                                                                                        <div className='itemList' data-toggle="tooltip" data-placement="top" title={element}>{element}</div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div key={element} className='m-column' id="m-table-end">
                                                                        <div className='m-block' id="m-block-odd"><div className='itemList' data-toggle="tooltip" data-placement="top" title={element}>{element}</div></div>
                                                                        {

                                                                            tabElement[productIndex].map((element, itemIndex) => {
                                                                                return (
                                                                                    <div key={itemIndex} className='m-block' id="m-block-even">
                                                                                        <div>
                                                                                            {
                                                                                                !itemIndex ? <span>{element && typeof element == ('number' || 'string') ? timeUtils.timeFormat(element, dateTimeRules) : ''}</span> :
                                                                                                    element && element.value && (<><div className='text__align'>{element.value} {data.unit}</div>
                                                                                                           <div className="itemList m-grid-extra-style" data-toggle="tooltip" data-placement="top" style={{ height: 18, overflow: 'hidden', width: 75, whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} title={element.uname}>
                                                                                                    {element.uname}
                                                                                            </div></>)

                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div style={{ width: ((tabElement.meta.products.length * 100) + 101) + 'px' }}>
                                                    <div className='tab-comment'>
                                                        <div>
                                                            <div className='cmt-label'>{t('Comment')}</div>
                                                            <span style={{lineBreak:"anywhere"}}>{tabElement.comment}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })
                                }
                            </div>
                        )
                    })
                }

            </>
        )

    }
}

export default MonitoringHistoryTable