import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormControl } from 'react-bootstrap';
import { find } from 'lodash';
import moment from 'moment/min/moment-with-locales';

import Button from '../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '../../../hoc/withLocalization';
import statuses from '../../../utils/normalStatuses';

@inject('commonStore')
@withLocalization
@observer
class ScheduleAvailabilityFilter extends Component {
    setFilterDate(name, name1, value) {
        this.props.setFilter(name1, value);
        this.props.setFilter(name, moment(new Date(value)).format('YYYY-MM-DD'));
    }

    render() {
        const {
            filters, setFilter, onFilter, loading, t
        } = this.props;
        const { status, name } = filters;
        const projectStatuses = statuses;
        const options = projectStatuses.map(status => {
            return { value: `${status.code}`, label: `${t(status.name)}` };
        });
        options.unshift({ value: '', label: t('All') });
        const currentSelect = find(options, o => {
            return o.value === status;
        });
        return (
            <div className="list-filters">
                <div className="list-filters__item list-filters__item_grow">
                    <FormControl
                        placeholder={t('Search')}
                        type="text"
                        onChange={(e) => setFilter('name', e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onFilter();
                            }
                        }}
                        value={name}
                    />
                </div>
                <div className="list-filters__item list-filters__item_to-right">
                    <Button fill wd onClick={() => onFilter()} >
                        {t('Filter')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default ScheduleAvailabilityFilter;
