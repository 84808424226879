import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';

import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';

import withLocalization from '~/hoc/withLocalization';
import Button from '~/components/CustomButton/CustomButton.jsx';
import config from '~/library/config';
import agent from '~/library/agent';
import {longDateFormat} from '../../../utils/dateFormat';
// import customerFeature from '~/utils/customerFeature';

@inject('dashboardStore', 'userStore', 'commonStore')
@withLocalization
@withRouter
@observer
class DailyLogReport extends Component {
    state = {
        email: null,
        dateRange: { start: moment(new Date()).startOf('month'), end: moment(new Date()) },
        sending: false,
        approved_only: false,
        include_tasks: false,
        iframeURL: 'about:blank',
        users: [],
        project: { value: '', label: 'All' },
        all_members: false,
        include_userimage: false
    };

    getReportMode() {
        return 'dailylog';
    }

    loadData() {
        const { t, dashboardStore, userStore } = this.props;
        const { sending } = this.state;
        const userType = userStore.currentUser.user_type;
        // const userSelect = customerFeature('reports_not_for_me');
        const userSelect = userType != 'member';
        this.setState({
            dateRange: { start: moment(new Date()).startOf('month'), end: moment(new Date()) },
            weekStart: moment(new Date()).add(-7, 'days'),
        });
        if (!userSelect) {
            this.setState({ users: [userStore.currentUser.id] });
        }
        // this.setState({users:[`${userStore.currentUser.id}`]});
        /* if (userType === 'pm' && userSelect) {
      this.setState({users:userStore.currentUser.Manages.map(o=>`${o}`)});
    } */
    }

    locale() {
        // let lang = window.localStorage.getItem('locale') || 'en';
        let lang = window.sessionStorage.getItem('locale') || 'en';
        if (lang === 'no') {
            lang = 'nb';
        }
        return lang;
    }

    componentDidMount() {
        this.loadData();
    }

    onSelect = (date, field) => {
        const dr = this.state.dateRange;
        dr[field] = moment(date);
        this.setState({ dateRange: dr });
    };

    onProjectChange = (id, user) => {
        this.setState({ project: user });
    };

    onCheckboxChange = (name, value) => {
        this.setState({ [name]: value });
    };

    applyForm = (params = {}) => {
        const mode = this.getReportMode();
        const {
            t, dashboardStore, userStore, commonStore
        } = this.props;
        if (this.state.users.length <= 0 && !this.state.all_members) {
            commonStore.addNotification(t('Choose users'), null, 'error');
            return;
        }
        // this.setState({sending:true});
        const hydrated = {
            user_id: userStore.currentUser.id,
            mode,
            mail: !!params.email,
            pdf: params.pdf,
            all_members: this.state.all_members,
            project: this.state.project.value,
            projectname: this.state.project.label,
            approved_only: this.state.approved_only,
            include_tasks: this.state.include_tasks,
            users: this.state.users.join(','),
            start: this.state.dateRange.start.format('YYYY-MM-DD'),
            end: this.state.dateRange.end.format('YYYY-MM-DD'),
            include_userimage: this.state.include_userimage
        };
        hydrated.preview = !params.pdf;
        hydrated.authorization = commonStore.token;
        if (params.pdf) {
            if (!this.state.email) {
                window.open(`${config.API_ROOT}/dashboard/send_report${agent.convertToGetParams(hydrated)}`);
            }
            userStore
                .sendReport(hydrated)
                .then(res => {
                    this.setState({ sending: false });
                    commonStore.addNotification(t('Sent'), null, 'success');
                })
                .catch(err => {
                    this.setState({ sending: false });
                    commonStore.addNotification(err.message || t('Error'), null, 'error');
                });
        } else {
            this.setState({
                iframeURL: `${config.API_ROOT}/dashboard/send_report${agent.convertToGetParams(hydrated)}`,
            });
        }
    };

    onMemberChange = (id, user) => {
        //    const { users } = this.state;
        const users = user ? user.map(u => u.value) : [];
        // this.setState({users:[id]});
        this.setState({ users });
    };

    render() {
        const { t, dashboardStore, userStore } = this.props;
        const { sending } = this.state;
        const userType = userStore.currentUser.user_type;
        const mode = this.getReportMode();
        const userSelect = userType != 'member';
        const { config } = this.props.commonStore
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        
        return (
            <div>
                <div className="panel" style={{ margin: 20, padding: 20 }}>
                    {/* <h4>{t('Daily Log Report')}</h4> */}
                    <div className="row">
                        <div className="col-md-6">
                            {/* <h4>{t('From')}</h4> */}
                            <DatePicker
                                selected={this.state.dateRange.start.toDate()}
                                onChange={date => this.onSelect(date, 'start')}
                                dateFormat= {longDateFormat(dateTimeRules)}
                                // maxDate={new Date()}
                                label={t('From')}   
                            />
                        </div>
                        <div className="col-md-6">
                            {/* <h4>{t('To')}</h4> */}
                            <DatePicker
                                selected={this.state.dateRange.end.toDate()}
                                onChange={date => this.onSelect(date, 'end')}
                                dateFormat= {longDateFormat(dateTimeRules)}
                                // maxDate={new Date()}
                                label={t('To')}   
                            />
                        </div>
                    </div>
                    {/* <CustomCheckbox
                        label={t('Approved only')}
                        onChange={v => this.onCheckboxChange('approved_only', v)}
                        isChecked={this.state.approved_only}
                        inline
                    />
                    <CustomCheckbox
                        label={t('Include tasks')}
                        onChange={v => this.onCheckboxChange('include_tasks', v)}
                        isChecked={this.state.include_tasks}
                        inline
                    /> */}
                    <br />
                    <CustomCheckbox
                        label={t('Include User captured image')}
                        onChange={v => this.onCheckboxChange('include_userimage', v)}
                        isChecked={this.state.include_userimage}
                        inline
                    />
                    {userSelect && (
                        <div>
                        <CustomCheckbox
                            label={t('All members')}
                            onChange={v => this.onCheckboxChange('all_members', v)}
                            isChecked={this.state.all_members}
                            inline
                            style={{marginLeft: '0px'}}
                        />
                        </div>
                    )}
                    {userSelect && (
                        <MembersWidget
                            isMulti
                            value={this.state.users}
                            onChange={(id, user) => this.onMemberChange(id, user)}
                        />
                    )}
                    <div>
                        <h5>{t('Project')}</h5>
                        <ProjectsWidget
                            value={this.state.project.value}
                            onChange={(id, user) => this.onProjectChange(id, user)}
                            allowAll
                        />
                    </div>
                    <div className="did-not-work__buttons">
                        {sending && <div>Sending</div>}
                        {!sending && (
                            <Button variant="primary" onClick={() => this.applyForm()}>
                                {t('Apply')}
                            </Button>
                        )}
                        <Button variant="primary" onClick={() => this.applyForm({ pdf: true })}>
                            {t('Download as PDF')}
                        </Button>
                    </div>
                </div>
                <div className="panel" style={{ margin: 20, padding: 20 }}>
                    <iframe style={{ width: '100%', height: '90vh', border: 0 }} src={this.state.iframeURL} />
                </div>
            </div>
        );
    }
}

export default DailyLogReport;
