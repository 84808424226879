import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
// import clientStore from 'stores/clientStore';

import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import getSchema from '~/library/schemas/clientControlPanel';

const schema = getSchema();
const listUrl = '/admin/controlpanel';

const returnUiSchema = (state) => {
    const { formData } = state;
    const showTimetrackOptions = formData && formData.basicRules && formData.basicRules.worklogValidation && formData.basicRules.worklogValidation.validateTimetrackOption;
    const uiSchema = {
        'ui:field': 'tabs',
        'ui:tabs': [
            {
                title: 'Basic Rules',
                id: 'tab1',
                'ui:field': 'layout',
                'ui:layout:hideframe': true,
                classNames: 'no-border-around-lists',
                'ui:layout': [
                    {
                        basicRules: {
                            md: 12,
                        },
                    },
                ],
                basicRules: {
                    selfClarificationPeriod: { md: 12 },
                    absencePeriodForTwoKids: { md: 12 },
                    absencePeriodForMoreThanTwoKids: { md: 12 },                    
                    allowSingleParentCare: { md: 12, 'ui:widget': 'TrueFalse'},                    
                    allowQucikChecklist:{md:6,'ui:widget':'TrueFalse',classNames: 'col-md-6'},
                    quickCheckListText:{md:6,classNames: 'col-md-6'},
                    // gpsEnabledForCompany:{md:12,'ui:widget': 'TrueFalse'},
                    // gpsAllowedDistance: { md: 12 },
                    // gpsAllowedTime: { md: 12 },
                    // autoTimelogs: { md: 12 },
                    // autoLogout: { md: 12 },
                    defaultHolidays: { md: 12, 'ui:widget': 'DaysOfWeek' },
                    allowManualTimelogChange: { md: 12, 'ui:widget': 'TrueFalse' },
                    allow_overtime_limit: { md: 12, 'ui:widget': 'TrueFalse' },
                    displayCommentAfterFinish: { md: 12, 'ui:widget': 'TrueFalse' },
                    signatureModalAfterWorklog: { md: 12, 'ui:widget': 'TrueFalse' },
                    workinghourUserphoto: { md: 12, 'ui:widget': 'TrueFalse' },
                    workinghourGPSObligatory: { md: 12, 'ui:widget': 'TrueFalse' },
                    pmManagesOvertime: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4'},
                    maxAllowedTimePeriodToLogOutWithGPSObligatory:{md: 12,'ui:widget': 'range', classNames: 'col-md-8'},
                    
                    // validateTimetrackOption: { md: 12, 'ui:widget': 'TrueFalse' },
                    // criticalVarianceThreshold: {
                    //     // classNames: 'col-md-12',
                    //     md: 12,
                    //     'ui:placeholder': '00:30',
                    //     'ui:widget': 'InputTime',
                    // },
                    // overtimeThreshold: {
                    //     // classNames: 'col-md-12',
                    //     md: 12,
                    //     'ui:placeholder': '00:30',
                    //     'ui:widget': 'InputTime',
                    // },
                    // sendEmailNotificationForCriticalThresholdToAdmin: { md: 12, 'ui:widget': 'TrueFalse' },
                    // sendEmailNotificationForCriticalThresholdToPM: { md: 12, 'ui:widget': 'TrueFalse' },
                    // sendEmailNotificationForMediumThresholdToAdmin: { md: 12, 'ui:widget': 'TrueFalse' },
                    // sendEmailNotificationForMediumThresholdToPM: { md: 12, 'ui:widget': 'TrueFalse' },
                    // sendEmailNotificationForOvertimeThresholdToAdmin: { md: 12, 'ui:widget': 'TrueFalse' },
                    // sendEmailNotificationForOvertimeThresholdToPM: { md: 12, 'ui:widget': 'TrueFalse' },
                    // sendEmailNotificationAt: {
                    //     // classNames: 'col-md-12',
                    //     md: 12,
                    //     'ui:placeholder': '00:00',
                    //     'ui:widget': 'InputTime',
                    // },
                    overtimeAutomaticMode: {
                        "ui:widget": "Radio"
                    },
                    overtime_types: {
                        'ui:options': { orderable: false, removable: true, inline: true },
                        classNames: 'form-chooser',
                        items: {
                            'ui:options': { orderable: false, removable: true, inline: true },
                            classNames: 'form-chooser',
                            code: { classNames: 'col-md-4' },
                            multiplier: { classNames: 'col-md-4' },
                            name: { classNames: 'col-md-4' },
                        },
                    },
                    overtimeAutoCalcRules: {
                        // 'ui:options': { orderable: false, removable: true, inline: true },
                        classNames: 'form-chooser',
                        calc_period: { 
                            max_hours: { md: 4, classNames: 'col-md-4' },
                            calc_percent: { classNames: 'col-md-4' },
                            common: {
                                'ui:options': { orderable: false, removable: true, inline: true },
                                classNames: 'form-chooser',
                                items: {
                                    'ui:options': { orderable: false, removable: true, inline: true },
                                    classNames: 'form-chooser',
                                    hours: { classNames: 'col-md-5' },
                                    name: { classNames: 'col-md-5' },
                                },
                            },
                            // },
                            monday: {
                                'ui:options': { orderable: false, removable: true, inline: true },
                                classNames: 'form-chooser',
                                items: {
                                    'ui:options': { orderable: false, removable: true, inline: true },
                                    classNames: 'form-chooser',
                                    hours: { classNames: 'col-md-5' },
                                    name: { classNames: 'col-md-5' },
                                },
                            },
                            tuesday: {
                                'ui:options': { orderable: false, removable: true, inline: true },
                                classNames: 'form-chooser',
                                items: {
                                    'ui:options': { orderable: false, removable: true, inline: true },
                                    classNames: 'form-chooser',
                                    hours: { classNames: 'col-md-5' },
                                    name: { classNames: 'col-md-5' },
                                },
                            },
                            wednesday: {
                                'ui:options': { orderable: false, removable: true, inline: true },
                                classNames: 'form-chooser',
                                items: {
                                    'ui:options': { orderable: false, removable: true, inline: true },
                                    classNames: 'form-chooser',
                                    hours: { classNames: 'col-md-5' },
                                    name: { classNames: 'col-md-5' },
                                },
                            },
                            thursday: {
                                'ui:options': { orderable: false, removable: true, inline: true },
                                classNames: 'form-chooser',
                                items: {
                                    'ui:options': { orderable: false, removable: true, inline: true },
                                    classNames: 'form-chooser',
                                    hours: { classNames: 'col-md-5' },
                                    name: { classNames: 'col-md-5' },
                                },
                            },
                            friday: {
                                'ui:options': { orderable: false, removable: true, inline: true },
                                classNames: 'form-chooser',
                                items: {
                                    'ui:options': { orderable: false, removable: true, inline: true },
                                    classNames: 'form-chooser',
                                    hours: { classNames: 'col-md-5' },
                                    name: { classNames: 'col-md-5' },
                                },
                            },
                            saturday: {
                                'ui:options': { orderable: false, removable: true, inline: true },
                                classNames: 'form-chooser',
                                items: {
                                    'ui:options': { orderable: false, removable: true, inline: true },
                                    classNames: 'form-chooser',
                                    hours: { classNames: 'col-md-5' },
                                    name: { classNames: 'col-md-5' },
                                },
                            },
                            sunday: {
                                'ui:options': { orderable: false, removable: true, inline: true },
                                classNames: 'form-chooser',
                                items: {
                                    'ui:options': { orderable: false, removable: true, inline: true },
                                    classNames: 'form-chooser',
                                    hours: { classNames: 'col-md-5' },
                                    name: { classNames: 'col-md-5' },
                                },
                            },
                         },
                    },
                    overtimeCalcRules: {
                        'ui:options': { orderable: false, removable: true, inline: true },
                        classNames: 'form-chooser',
                        calc_period: { md: 4, classNames: 'col-md-4' },
                        max_hours: { md: 2, classNames: 'col-md-2' },
                        max_minutes: { md: 2, classNames: 'col-md-2' },
                        calc_percent: { classNames: 'col-md-4' },
                        weekend_percent: { classNames: 'col-md-6' },
                        weekend_days: { classNames: 'col-md-6', 'ui:widget': 'DaysOfWeek' },
                    },
                    startTimeRules: {
                        start: {
                            'ui:widget': 'InputTime',
                            classNames: 'col-md-4',
                        },
                        end: {
                            'ui:widget': 'InputTime',
                            classNames: 'col-md-4',
                        },
                        total: {
                            classNames: 'col-md-4',
                            'ui:placeholder': '08:00',
                            'ui:widget': 'InputTime',
                        },
                    },
                    worklogValidation: {
                        validateTimetrackOption: { md: 12, classNames: 'col-md-12', 'ui:widget': 'TrueFalse' },
                        criticalVarianceThreshold: {
                            // classNames: 'col-md-12',
                            md: 6,
                            classNames: 'col-md-6',
                            'ui:placeholder': '00:30',
                            'ui:widget': showTimetrackOptions ? 'InputTime' : 'hidden',
                        },
                        overtimeThreshold: {
                            // classNames: 'col-md-12',
                            md: 6,
                            classNames: 'col-md-6',
                            'ui:placeholder': '00:30',
                            'ui:widget': showTimetrackOptions ? 'InputTime' : 'hidden',
                        },
                        sendEmailNotificationForCriticalThresholdToPM: { md: 6, classNames: 'col-md-6', 'ui:widget': showTimetrackOptions ? 'TrueFalse' : 'hidden' },
                        sendEmailNotificationForCriticalThresholdToAdmin: { md: 6, classNames: 'col-md-6', 'ui:widget': showTimetrackOptions ? 'TrueFalse' : 'hidden' },
                        sendEmailNotificationForMediumThresholdToPM: { md: 6, classNames: 'col-md-6', 'ui:widget': showTimetrackOptions ? 'TrueFalse' : 'hidden' },
                        sendEmailNotificationForMediumThresholdToAdmin: { md: 6, classNames: 'col-md-6', 'ui:widget': showTimetrackOptions ? 'TrueFalse' : 'hidden' },
                        sendEmailNotificationForOvertimeThresholdToPM: { md: 6, classNames: 'col-md-6', 'ui:widget': showTimetrackOptions ? 'TrueFalse' : 'hidden' },
                        sendEmailNotificationForOvertimeThresholdToAdmin: { md: 6, classNames: 'col-md-6', 'ui:widget': showTimetrackOptions ? 'TrueFalse' : 'hidden' },
                        sendEmailNotificationAt: {
                            classNames: 'col-md-12',
                            md: 12,
                            'ui:placeholder': '18:00',
                            'ui:widget': showTimetrackOptions ? 'InputTime' : 'hidden',
                        },
                    }
                },
            },
            {
                title: 'Extended rules',
                'ui:layout:hideframe': true,
                id: 'tab2',
                'ui:field': 'layout',
                'ui:layout': [
                    {
                        extendedRules: {
                            md: 12,
                        },
                    },
                ],
                extendedRules: {
                    kilometersRules: {
                        classNames: 'form-chooser',
                        items: {
                            distance: {
                                classNames: 'col-md-6',
                                'ui:placeholder': '10km',
                            },
                            max_km: {
                                classNames: 'col-md-6',
                                'ui:placeholder': '100',
                            },
                        },
                        'ui:options': {
                            orderable: false,
                        },
                    },
                    vacation_types: {
                        classNames: 'form-chooser',
                        items: {
                            code: {
                                classNames: 'col-md-6',
                                'ui:placeholder': 'Standard',
                            },
                            name: {
                                classNames: 'col-md-6',
                                'ui:placeholder': '30 Days',
                            },
                        },
                        'ui:options': {
                            orderable: false,
                        },
                    },
                    absent_types: {
                        classNames: 'form-chooser',
                        items: {
                            code: {
                                classNames: 'col-md-6',
                                'ui:placeholder': 'Standard',
                            },
                            name: {
                                classNames: 'col-md-6',
                                'ui:placeholder': '30 Days',
                            },
                        },
                        'ui:options': {
                            orderable: false,
                        },
                    },
                    expense_types: {
                        classNames: 'form-chooser',
                        items: {
                            code: {
                                classNames: 'col-md-6',
                                'ui:placeholder': 'Standard',
                            },
                            name: {
                                classNames: 'col-md-6',
                                'ui:placeholder': '30 Days',
                            },
                        },
                        'ui:options': {
                            orderable: false,
                        },
                    },
                    extrawork_types: {
                        classNames: 'form-chooser',
                        items: {
                            code: {
                                classNames: 'col-md-6',
                                'ui:placeholder': 'Standard',
                            },
                            name: {
                                classNames: 'col-md-6',
                                'ui:placeholder': '30 Days',
                            },
                        },
                        'ui:options': {
                            orderable: false,
                        },
                    },
                },
            },
            /* {
          'title':'Business types',
          'id':'tab3',
          'ui:field': 'layout',
          'ui:layout:hideframe':true,
          'ui:layout': [
            {
              business_types: { md: 12 },
            }
          ],
          business_types: { "ui:options": { orderable: false, removable: true, inline: true } },
        },
        {
          'title':'User types',
          'id':'tab4',
          'ui:field': 'layout',
          'ui:layout:hideframe':true,
          'ui:layout': [
            {
              user_types: { md: 12 },
            }
          ],
          user_types: {
            "ui:options": { orderable: false, removable: true, inline: true },
            classNames: 'form-chooser',
            items: {
              "ui:options": { orderable: false, removable: true, inline: true },
              classNames: 'form-chooser',
              code: {classNames: 'col-md-6'},
              name: {classNames: 'col-md-6'}
            }
          }
        }, */
            {
                title: 'Project Rules',
                id: 'tab5',
                'ui:field': 'layout',
                'ui:layout:hideframe': true,
                'ui:layout': [
                    {
                        project_statuses: { md: 12 },
                    },
                ],
                project_statuses: {
                    'ui:options': { orderable: false, removable: true, inline: true },
                    classNames: 'form-chooser',
                    items: {
                        'ui:options': { orderable: false, removable: true, inline: true },
                        classNames: 'form-chooser',
                        code: { classNames: 'col-md-6' },
                        name: { classNames: 'col-md-6' },
                    },
                },
            },
            {
                title: 'Task Rules',
                id: 'tab6',
                'ui:field': 'layout',
                'ui:layout:hideframe': true,
                'ui:layout': [
                    {
                        task_statuses: { md: 12 },
                    },
                ],
                task_statuses: {
                    'ui:options': { orderable: false, removable: true, inline: true },
                    classNames: 'form-chooser',
                    items: {
                        'ui:options': { orderable: false, removable: true, inline: true },
                        classNames: 'form-chooser',
                        code: { classNames: 'col-md-6' },
                        name: { classNames: 'col-md-6' },
                    },
                },
            },
            {
                title: 'Deviations Rules',
                id: 'tab7',
                'ui:field': 'layout',
                'ui:layout:hideframe': true,
                'ui:layout': [
                    {
                        deviations: { md: 12 },
                    },
                ],
                deviations: {
                    classNames: 'form-chooser',
                    dueDateEmailToBeSentBeforeDays: { classNames: 'col-md-12' },
                    strings: {
                        classNames: 'col-md-12',
                        'ui:widget': 'DeviationConfigWidget',
                    },
                },
            },
            {
                title: 'Vacation Rules',
                id: 'tab8',
                'ui:field': 'layout',
                'ui:layout:hideframe': true,
                'ui:layout': [
                    {
                        Vacation_setup: { md: 12 },
                    },
                ],
                Vacation_setup: {
                    classNames: 'form-chooser',
                    dueDateEmailToBeSentBeforeDays: { classNames: 'col-md-12' },
                    strings: {
                        classNames: 'col-md-12',
                        'ui:widget': 'vacation_dayConfigWidget',
                    },
                },
            },
            {
                title: 'General Settings',
                id: 'tab9',
                'ui:field': 'layout',
                'ui:layout:hideframe': true,
                'ui:layout': [
                    {
                        general_settings: { md: 12 },
                    },
                ],
                general_settings: {

                    pMsAdmins: {
                        classNames: 'badge badge-light',
                        'ui:options': { orderable: false, removable: true, inline: true },
                        classNames: 'form-chooser',
                        pm_allow_editing_all: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        pm_no_shifts: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' }
                    },

                    pM: {
                        'ui:options': { orderable: false, removable: true, inline: true },
                        classNames: 'form-chooser',
                        pm_hide_tasks: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-3' },
                        pm_hide_vacations: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-3' },
                        pm_hide_absences: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-3' },
                        pm_no_register: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-3' }

                    },

                    AbsentsVacations: {
                        'ui:options': { orderable: false, removable: true, inline: true },
                        classNames: 'form-chooser',
                        allow_vacations: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        allow_absents: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        allow_accepting: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        allow_vacation_not_me: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        admin_hide_absences: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        disallow_absences_vacations_not_self : { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' }
                    },

                    deviations: {
                        'ui:options': { orderable: false, removable: true, inline: true },
                        classNames: 'form-chooser',
                        enable_deviations: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-6' }
                    },

                    projects: {
                        'ui:options': { orderable: false, removable: true, inline: true },
                        classNames: 'form-chooser',
                        member_allow_see_projects: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        show_times_on_project: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' }
                    },

                    timelog: {
                        'ui:options': { orderable: false, removable: true, inline: true },
                        classNames: 'form-chooser',
                        timelog_hide_kilometers: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        timelog_hide_other: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        timelog_hide_extra: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        timelog_hide_names: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        timelog_no_edit_for_approved: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        timelog_no_edit_for_done: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' }
                    },

                    reports: {
                        'ui:options': { orderable: false, removable: true, inline: true },
                        classNames: 'form-chooser',
                        reports_weekly: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        rest_report: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        hide_send_report: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        reports_not_for_me: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4'   }
                    },

                    mix: {
                        'ui:options': { orderable: false, removable: true, inline: true },
                        classNames: 'form-chooser',
                        presence_view: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        login_as_allowed: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        locked_mode: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },
                        union_group: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Union group', classNames: 'col-md-4' },
                        gps_enabled: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' }
                    }
                    
                    // classNames: 'form-chooser',
                    // dueDateEmailToBeSentBeforeDays: { classNames: 'col-md-12' },
                    // strings: {
                    //     classNames: 'col-md-12',
                    //     'ui:widget': 'settings_dayConfigWidget',
                    // },

                    
                    // allow_vacations: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-2' },
                    // allow_absents: { md: 12, 'ui:widget': 'TrueFalse',  classNames: 'col-md-2' },
                    // allow_accepting: { md: 12, 'ui:widget': 'TrueFalse',  classNames: 'col-md-4' },
                    // allow_vacation_not_me: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-4' },

                    // enable_deviations: { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-6'},
                    // disallow_absences_vacations_not_self : { md: 12, 'ui:widget': 'TrueFalse', classNames: 'col-md-6' },

                    // pm_hide_tasks: { md: 12, 'ui:widget': 'TrueFalse',  classNames: 'col-md-3' },
                    // pm_hide_vacations: { md: 12, 'ui:widget': 'TrueFalse',  classNames: 'col-md-3' },
                    // pm_hide_absences: { md: 12, 'ui:widget': 'TrueFalse',  classNames: 'col-md-3' },
                    // pm_no_register: { md: 12, 'ui:widget': 'TrueFalse',  classNames: 'col-md-3' },

                    // member_allow_see_projects: { md: 12, 'ui:widget': 'TrueFalse' },
                    // timelog_hide_kilometers: { md: 12, 'ui:widget': 'TrueFalse' },
                    // timelog_hide_other: { md: 12, 'ui:widget': 'TrueFalse' },
                    // timelog_hide_extra: { md: 12, 'ui:widget': 'TrueFalse' },
                    // show_times_on_project:  { md: 12, 'ui:widget': 'TrueFalse' },
                    // hide_send_report: { md: 12, 'ui:widget': 'TrueFalse' },
                    // presence_view: { md: 12, 'ui:widget': 'TrueFalse' },
                    // admin_hide_absences: { md: 12, 'ui:widget': 'TrueFalse' },
                    
                    // reports_not_for_me: { md: 12, 'ui:widget': 'TrueFalse' },
                    // reports_weekly: { md: 12, 'ui:widget': 'TrueFalse' },
                    // locked_mode: { md: 12, 'ui:widget': 'TrueFalse' },
                    // rest_report: { md: 12, 'ui:widget': 'TrueFalse' },
                    // pm_allow_editing_all: { md: 12, 'ui:widget': 'TrueFalse' },
                    // login_as_allowed: { md: 12, 'ui:widget': 'TrueFalse' },
                    
                    // timelog_hide_names: { md: 12, 'ui:widget': 'TrueFalse' },
                    // gps_enabled: { md: 12, 'ui:widget': 'TrueFalse' },
                    // timelog_no_edit_for_approved: { md: 12, 'ui:widget': 'TrueFalse' },
                    // timelog_no_edit_for_done: { md: 12, 'ui:widget': 'TrueFalse' },
                    // pm_no_shifts: { md: 12, 'ui:widget': 'TrueFalse' },
                    
                    // union_group: { type: 'boolean', 'ui:widget': 'TrueFalse', title: 'Union group' },
                },
            },
            {
                title: 'Login Rules',
                id: 'tab10',
                'ui:field': 'layout',
                'ui:layout:hideframe': true,
                classNames: 'no-border-around-lists',
                'ui:layout': [
                    {
                        loginRules: {
                            md: 12,
                        },
                    },
                ],
                loginRules: {
                    gpsAllowedDistance: { md: 12 },
                    gpsAllowedTime: { md: 12 },
                    autoTimelogs: { md: 12 },
                    autoLogout: { md: 12 },
                    
                },
            },
        ],
    };
    return uiSchema;
}

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class ControlPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: null,
        }
        this.formDataCallback = this.formDataCallback.bind(this);
    }

    formDataCallback(formData) {
        this.setState({ formData });
    }

    loadData() {
        const { clientStore, userStore } = this.props;
        clientStore.load(userStore.currentUser.client_id, false);
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
        this.props.clientStore.setLoading(true);
    }

    onSave(values) {
        const {
            clientStore, history, add, t, commonStore
        } = this.props;        
        const currentClient = Object.assign({}, clientStore.currentEntity.client);
        currentClient.data = values;
        return clientStore.save(currentClient, false).then(result => {
            if (!result.client || !result.client.id) {
                // some error                
                commonStore.addNotification(t('Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');
            // history.push(listUrl);
            return true;
        });
    }

    translateSchema() {
        const { t } = this.props;
        // schema.properties.basicRules.properties.autoTimelogs.anyOf.forEach(r => {
        //     r.title = t(r.title);
        // });
        schema.properties.basicRules.properties.approvalTimelogs.anyOf.forEach(r => {
            r.title = t(r.title);
        });
        schema.properties.basicRules.properties.approvalVacations.anyOf.forEach(r => {
            r.title = t(r.title);
        });
        schema.properties.basicRules.properties.approvalAbsents.anyOf.forEach(r => {
            r.title = t(r.title);
        });
        schema.properties.loginRules.properties.autoTimelogs.anyOf.forEach(r => {
            r.title = t(r.title);
        });
        schema.properties.loginRules.properties.autoLogout.anyOf.forEach(r => {
            r.title = t(r.title);
        });
        return schema;
    }

    render() {
        const { clientStore, add } = this.props;
        const { loading, currentEntity } = clientStore;
        if (loading) return <LoadingSpinner />;

        return (
            <div className="main-content">
                <GenericForm
                    entity={currentEntity.client.data}
                    uiSchema={returnUiSchema(this.state)}
                    schema={this.translateSchema()}
                    translationScope="forms.client"
                    onSave={values => this.onSave(values)}
                    listUrl={listUrl}
                    isAdding={add}
                    formDataCallback={this.formDataCallback}
                />
            </div>
        );
    }
}

export default ControlPanel;
