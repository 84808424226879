import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
// import { Col, Grid, Row, DropdownButton, MenuItem } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';

import withLocalization from '~/hoc/withLocalization';
import LoadingSpinner from '~/elements/LoadingSpinner';
import CopyIcon from '../../../assets/img/copy_icon.svg';
import Button from '~/components/CustomButton/CustomButton.jsx';
// import customerFeature from '~/utils/customerFeature';
import Scheduler, { SchedulerData, ViewTypes, DATE_FORMAT, DemoData } from '~/components/Scheduler';
import withDnDContext from '~/hoc/withDnDContext';

// include `react-big-scheduler/lib/css/style.css` for styles, link it in html or import it here
import '~/components/Scheduler/css/style.css';
import { confirmAlert } from 'react-confirm-alert';
import { getMinutesFromTimeInput } from '../../../utils/timeCalc';

import MoveOrCopyModal from './MoveOrCopyModal';
import ShiftForm from './ShiftForm';
import BulkShiftForm from './BulkShiftForm';
import CopyForm from './CopyForm';
import EventItemTemplateResolver from './EventItemTemplateResolver';
import EventItemPopoverTemplateResolver from './EventItemPopoverTemplateResolver';
import _ from 'lodash';
import MonthCalendar from './MonthCalendar';
import { Modal, Badge } from 'react-bootstrap';
import ScheduleAvailabilityList from './ScheduleAvailabilityList';
import CheckAvailabilityForm from './CheckAvailabilityForm';
import ScheduleAddAvailability from './ScheduleAddAvailability';

@inject('dashboardStore', 'userStore', 'commonStore', 'scheduleStore')
@withLocalization
@withRouter
@withDnDContext
@observer
export default class ScheduleMain extends Component {
    state = {
        width: 0,
        viewModel: null,
        moving: false,
        movingData: null,
        editing: false,
        copying: false,
        bulkEditing: false,
        stub: null,
        copyingUsers: [],
        copyingUsersAll: false,
        copyingStarted: false,
        toggle_todaybtn:false,
        showCalendar:false,
        loading:'',

        add: false,
        currentChecklistId: 0,
        showChecklistTodo: false,
        showChecklistAccept: false,
        currentChecklist: {},
        isSchedule: false,
        isCheckAvailability: false,
        isAddAvailability: false,
        responseData: []
    };

    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
    }

    componentDidMount() {
        this.setState({ width: this.wrapper.current.parentElement.offsetWidth - 60 ,loading:true}); // 360
        this.loadData();
    }

    // componentWillMount() {
    //     const { scheduleStore } = this.props;
    //     scheduleStore.loading = true;
    // }

    async loadData(params) {
        const { t, dashboardStore, userStore, scheduleStore, commonStore } = this.props;
        const { sending, viewModel } = this.state;
        const userType = userStore.currentUser.user_type;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        await scheduleStore.loadEverything({ userType, userId: userStore.currentUser.id }, dateTimeRules);
        this.setState({loading: false});
        if (!viewModel) this.initScheduler();
    }

    async reloadData(schedulerData) {
        
        const autoReload = !schedulerData;
        if (!schedulerData) schedulerData = this.state.viewModel;
        const { scheduleStore, commonStore } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        const params = {
            start: schedulerData.startDate,
            end: schedulerData.endDate,
        };
        const data = await scheduleStore.loadList(params, dateTimeRules);
        schedulerData.setEvents(data.list);
        const availabilityData = await scheduleStore.loadAvailabilityDataList(params, dateTimeRules)
        schedulerData.setAvailability(availabilityData.list);
        if (autoReload) {
            this.setState({
                viewModel: schedulerData,
            });
        }
        return schedulerData;
    }

    prevClick = async (schedulerData) => {
        schedulerData.prev();
        const newScheduler = await this.reloadData(schedulerData);
        this.setState({
            viewModel: newScheduler,
        });
    };

    nextClick = async (schedulerData) => {
        schedulerData.next();
        const newScheduler = await this.reloadData(schedulerData);
        this.setState({
            viewModel: newScheduler,
        });
    };

    onViewChange = async (schedulerData, view) => {
        
       if(view.viewType === '1'){

           schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
           
           const newScheduler = await this.reloadData(schedulerData);
           this.setState({
               viewModel: newScheduler,
               showCalendar:false
           },);
       } else if (view.viewType ==='2'){
            const newScheduler = await this.reloadData(schedulerData);
           this.setState({viewModel: newScheduler,showCalendar:true});
       }
    };

    onSelectDate = async (schedulerData, date) => {
        schedulerData.setDate(date);
        const newScheduler = await this.reloadData(schedulerData);
        this.setState({
            viewModel: newScheduler,
        });
    };

    eventClicked = (schedulerData, event) => {
        const { scheduleStore, userStore } = this.props;
        const { resources, currentList } = scheduleStore;
        const { currentUser } = userStore;
        const isMember = currentUser.user_type === 'member';
        if (event.source === 'vacation' || event.source === 'absence' || event.availability_type == 'available' || event.availability_type == 'not available') return;
        if (isMember && event.resourceId > 0 && event.resourceId !== currentUser.id) return;
        this.setState({ editing: event.id, stub: event });
        // alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}}`);
    };

    newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {

        const clientConfig = this.props.commonStore.config.client.data;
        let from = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.start);
        let to = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.end);

        //    console.log('ADD', schedulerData, slotId, start, new Date(start.replace(' 00','T00')));
        this.setState({
            editing: 0,
            stub: {
                for_date: new Date(start.replace(' ', 'T')),
                assignee_id: slotId,
                start_time: from,
                end_time: to,
                do_notify: true
            },
        });
    };
    
    newBulkEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        //    console.log('ADD', schedulerData, slotId, start, new Date(start.replace(' 00','T00')));
        const clientConfig = this.props.commonStore.config.client.data;
        let from = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.start);
        let to = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.end);

        this.setState({
            bulkEditing: 0,
            stub: {
                start_time: from,
                end_time: to,
            },
        });
    };

    closeEditingWindow = () => {
        this.setState({
            editing: false,
            stub: null,
        });
    };

    afterEditing = async (status) => {
        const { t, scheduleStore, commonStore, userStore } = this.props;
        const { currentUser } = userStore;
        if (status === 'cancel') {
            this.setState({
                editing: false,
                stub: null,
            });
            this.setState({ responseData: [] })
        }
        if (status === 'delete') {
            this.closeEditingWindow();
            confirmAlert({
                title: t('Confirm delete'),
                message: t('This will remove the schedule'),
                buttons: [
                    {
                        label: t('Yes'),
                        onClick: async () => {
                            await scheduleStore.remove(scheduleStore.currentEntity.id);
                            this.reloadData();
                        },
                    },
                    {
                        label: t('No'),
                        onClick: () => {
                            // this.closeEditingWindow();
                        },
                    },
                ],
            });
        }
        if (status === 'save') {
            try {
                const shiftDetails = await scheduleStore.save(scheduleStore.currentEntity, !scheduleStore.currentEntity.id);
                if (shiftDetails && shiftDetails.assignedMemberName) {
                    let responseData = this.state.responseData;
                    responseData.push(shiftDetails)
                    this.setState({ responseData: responseData })
                }
                // this.closeEditingWindow();
                this.reloadData();
            } catch (e) {
                // commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
        }
        if (status === 'member_accept' || status === 'member_reject') {
            try {
                if (status === 'member_reject' && !scheduleStore.currentEntity.comment) {
                    throw new Error('Comment is required');
                }
                await scheduleStore.memberAction(status, currentUser);
                this.closeEditingWindow();
                this.reloadData();
            } catch (e) {
                // commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
        }
    };

    afterBulkEditing = async (status, values) => {
        const { t, scheduleStore, commonStore, userStore } = this.props;
        const { currentUser } = userStore;
        if (status === 'cancel') {
            this.setState({
                bulkEditing: false,
                stub: null,
            });
        }
        // if (status === 'delete') {
        //     this.closeEditingWindow();
        //     confirmAlert({
        //         title: t('Confirm delete'),
        //         message: t('This will remove the schedule'),
        //         buttons: [
        //             {
        //                 label: t('Yes'),
        //                 onClick: async () => {
        //                     await scheduleStore.remove(scheduleStore.currentEntity.id);
        //                     this.reloadData();
        //                 },
        //             },
        //             {
        //                 label: t('No'),
        //                 onClick: () => {
        //                     // this.closeEditingWindow();
        //                 },
        //             },
        //         ],
        //     });
        // }
        if (status === 'save') {
            try {
                await scheduleStore.addBulkShift(values);
                this.setState({
                    bulkEditing: false,
                    stub: null,
                }, () => {
                    this.reloadData();
                });
            } catch (e) {
                commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
        }
        // if (status === 'member_accept' || status === 'member_reject') {
        //     try {
        //         if (status === 'member_reject' && !scheduleStore.currentEntity.comment) {
        //             throw new Error('Comment is required');
        //         }
        //         await scheduleStore.memberAction(status, currentUser);
        //         this.closeEditingWindow();
        //         this.reloadData();
        //     } catch (e) {
        //         // commonStore.addNotification(t(e.message || 'Error'), null, 'error');
        //     }
        // }
    };

    moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
       
        this.setState({
            moving: true,
            movingData: {
                id: event.id,
                fromDay: event.start,
                toDay: start,
                fromUser: event.resourceId,
                toUser: slotId,
            },
        });
    };

    moveToPending = (event,slotId,slotName,start,end) =>{
        this.setState({
            movingData:{
                do_notify:'false',
                id: event.id,
                fromDay: event.start,
                toDay: start,
                fromUser: event.resourceId,
                toUser: 0,
            }
        },()=>{
            this.shiftToPending();
            //console.log("moving data&&&&&",this.state.movingData);
        })
    }

    eventItemTemplateResolver = (
        schedulerData,
        event,
        bgColor,
        isStart,
        isEnd,
        mustAddCssClass,
        mustBeHeight,
        agendaMaxEventWidth
    ) => {
        const {toggle_todaybtn}= this.state;
        let getEndDate = new Date(event.end);        let currentDate = new Date();
        const { t } = this.props;
        // these if-else basically use here to get functionality of today button ,,,, only today shifts will be shown.
        if(toggle_todaybtn){ 
            if(getEndDate.getDate() === currentDate.getDate() ){
                return (
                    <EventItemTemplateResolver
                        params={{
                            schedulerData,
                            event,
                            bgColor,
                            isStart,
                            isEnd,
                            mustAddCssClass,
                            mustBeHeight,
                            agendaMaxEventWidth,
                            t,
                            toggle_todaybtn
                        }}
                    />
                );
            }
        }
        else{
            return (
                <EventItemTemplateResolver
                    params={{
                        schedulerData,
                        event,
                        bgColor,
                        isStart,
                        isEnd,
                        mustAddCssClass,
                        mustBeHeight,
                        agendaMaxEventWidth,
                        t,
                        toggle_todaybtn
                    }}
                />
            );
        }
    };

    eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {
        const { t, commonStore } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false
        return (
            <EventItemPopoverTemplateResolver
                params={{
                    schedulerData,
                    eventItem,
                    title,
                    start,
                    end,
                    statusColor,
                    t, 
                    dateTimeRules,
                }}
            />
        );
    };

    getSummary = (schedulerData, headerEvents, slotId, slotName, headerStart, headerEnd) => {};

    goToUser = (data, { slotId: userId }) => {
        if (userId) {
            this.props.history.push(`/admin/members/${userId}`);
        }
    };

    // onStartCopy() {
    //     this.setState({ copyingStarted: true });
    // }

    startCopyToNextMonth() {
        // if (this.state.copyingUsers.length <= 0) {
        //     this.props.commonStore.addNotification(this.props.t('Please select users'), null, 'error');
        //     return;
        // }
        this.setState({ copying: true });
    }

    startCopyToNextWeek() {
        const { scheduleStore, userStore, t } = this.props;
        const { viewModel } = this.state;
        const params = { start: viewModel.startDate, end: viewModel.endDate };
        let count = 0;
         viewModel.events.map(item =>{
             let getSource = item.source === 'absence' || item.source === 'vacation' || item.resourceId === 0 ;
            if(moment(item.start).format('YYYY-MM-DD')>=viewModel.startDate && moment(item.end).format('YYYY-MM-DD')<=viewModel.endDate && !getSource ){
                count++;
            }
            
        })

       

        confirmAlert({
            title: t('Confirm copy'),
            // message: t('This will override the schedule for next week'),
            message: count +' '+ t('shifts are ready for copy to next week.') +' '+ t('This will override the schedule for next week'),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: async () => {
                        await scheduleStore.copyToNextWeek(params);
                        this.reloadData();
                    },
                },
                {
                    label: t('No'),
                    onClick: () => {
                        // this.closeEditingWindow();
                    },
                },
            ],
        });
    }

    afterCopy() {
        this.setState({ copying: false, copyingStarted: true });
        this.reloadData();
    }

    filterTodayShifts(){
        const { viewModel } = this.state;
        // console.log('view model value',viewModel);
        // console.log("props value12345",this.props);
        this.setState({toggle_todaybtn:!this.state.toggle_todaybtn});
    }

    renderTools() {
       
        const { scheduleStore, userStore, t, commonStore } = this.props;
        const { viewModel } = this.state;
        const { currentUser } = userStore;
        const { config } = commonStore 
        const allowAvailability = config.client && config.client.data && config.client.data.basicRules 
        && config.client.data.basicRules.allowAvailability ? config.client.data.basicRules.allowAvailability : false
        const isMember = currentUser.user_type === 'member';
        const { pendingCountsInAvailability  } = scheduleStore
       

        // if (viewModel.viewType !== 1) return <div style={{ width: '200px' }} />;
         // this is non-week type
        return (
            <>
            <div  className="dropdown-menu-right" style={{display: 'flex'}}>
                {!isMember && (
                    <>
                        <Button className={this.state.toggle_todaybtn?'set_today':'unset_today'} style={{marginRight:5,height:'2.6em',border:'1px solid'}} onClick={()=>this.filterTodayShifts()}>{t('Today')}</Button>
                        {/* <Button id="schedule-bulk-edit" onClick={() => this.startCopyToNextMonth()}  style={{marginRight:'15px'}}><img src={CopyIcon} alt="Copy Icon" /><span style={{marginLeft:'7px'}}>{t('Copy Month')}</span></Button> */}
                        <Button id="schedule-bulk-edit" onClick={() => this.startCopyToNextWeek()}  style={{marginRight:5,height:'2.6em'}}><img src={CopyIcon} alt="Copy Icon" /><span style={{marginLeft:'7px'}}>{t('Copy')}{t('Week')}</span></Button>
                        <Button id="schedule-bulk-edit" onClick={() => this.newBulkEvent()} style={{marginRight:5,height:'2.6em'}} >{t('Bulk shift')}</Button>
                        {
                        allowAvailability && 
                        <>
                        <Button id="schedule-bulk-edit" onClick={() => this.viewAvailabilityAssign()} style={{marginRight:5, height:'2.6em'}} >{t('Availability')+ ' '}<Badge bg="secondary">{pendingCountsInAvailability}</Badge></Button>
                        {/* <Button id="schedule-bulk-edit" onClick={() => this.CheckAvailability()} style={{height:'2.6em'}} >{t('Check Availability')}</Button> */}
                        </>
                        }
                        {/* <DropdownButton title={t('Actions')} id="schedule-actions" pullRight>
                            <MenuItem eventKey="1" onClick={() => this.startCopyToNextWeek()}>
                                {t('Copy to next week')}
                            </MenuItem>
                            <MenuItem eventKey="2" onClick={() => this.onStartCopy()}>
                                {t('Copy months')}
                            </MenuItem>
                            <MenuItem divider />
                        </DropdownButton> */}
                    </>
                )}
                </div>
                {isMember && allowAvailability &&
                    <div className='nextbtn'>
                        <Button id="schedule-bulk-edit" onClick={() => this.viewAvailabilityAssign()} style={{marginRight:5 ,height: '2.6em' }} >{t('Availability')}</Button>
                    </div>
                }
                </>
        );
    }

    initScheduler() {
        const { scheduleStore, userStore, t } = this.props;
        const { resources, currentList, dataOnHover, currentAvailabilityList } = scheduleStore;
        const { currentUser } = userStore;
        const isMember = currentUser.user_type === 'member';
        if (moment.locale() === 'en') {
            moment.locale('en-GB'); // change week
        }

        const schedulerData = new SchedulerData(
            moment(new Date()).format('YYYY-MM-DD'),
            ViewTypes.Week,
            false,
            false,
            {
                displayWeekend: true,
                weekCellWidth: this.state.width > 800 ? '12%' : 80,
                weekResourceTableWidth: this.state.width > 800 ? '16%' : 120,
                schedulerMaxHeight: 1200,
                schedulerWidth: this.state.width,
                //eventItemLineHeight: 32,
                eventItemLineHeight: 35,
                //eventItemHeight: 30,
               // eventItemHeight: 55,
                eventItemHeight: 30,
                startResizable: false,
                endResizable: false,
                movable: !isMember,
                // creatable: !isMember,
                currentUserId: currentUser.id,
                nonWorkingTimeHeadColor: '#000',
                
              //  nonWorkingTimeHeadBgColor: 'rgb(200,30,45) ',
                nonWorkingTimeHeadBgColor: '#F2F1ED',
                 nonWorkingTimeBodyBgColor: '#F2F1ED',
                 nonWorkingTimeBodyBgColor: '#F2F1ED',
                views: [
                    {
                        viewName: t('Week'),
                        viewType: ViewTypes.Week,
                        showAgenda: false,
                        isEventPerspective: false,
                    },
                    {
                        viewName: t('Month'),
                        viewType: ViewTypes.Month,
                        showAgenda: false,
                        isEventPerspective: false,
                    },
                ],
                getSummaryFunc: this.getSummary,
            },
            { isNonWorkingTimeFunc: this.isNonWorkingTimeFunc },
            undefined,
            dataOnHover
        );
        schedulerData.setResources(resources);
        // console.log('CL', currentList.list);
        schedulerData.setEvents(currentList.list);
        schedulerData.setAvailability(currentAvailabilityList.list);
        // schedulerData.setLocaleMoment(moment);

        this.setState({
            viewModel: schedulerData,
        },()=>{
            

        });
    }

    async shiftToPending(){
        
        const { t, scheduleStore, commonStore } = this.props;
        try{
            await scheduleStore.move(this.state.movingData);
            this.reloadData();
        }
        catch(e){

        }
    }

    async afterMoveDecision(state, doNotify) {
        const { t, scheduleStore, commonStore } = this.props;
        const data = this.state.movingData;
        if (state != 'cancel') {
            let today = moment(new Date).format('YYYY-MM-DD')
            if (moment(this.state.movingData.toDay).format('YYYY-MM-DD') < moment(this.state.movingData.fromDay).format('YYYY-MM-DD') &&
                moment(this.state.movingData.toDay).format('YYYY-MM-DD') < moment(today).format('YYYY-MM-DD')) {
                commonStore.addNotification(t('Can not Move or Copy schedule for past date'), null, 'error');
                return;
            }
        }
        data.do_notify = doNotify;
        try {
            if (state == 'copy') {
                await scheduleStore.copy(data);
            }
            if (state == 'move') {
                await scheduleStore.move(data);
            }
            this.setState({ moving: false, movingData: null });
            this.reloadData();
        } catch (e) {
            // commonStore.addNotification(t(e.message || 'Error'), null, 'error');
        }
    }

    isNonWorkingTimeFunc(schedulerData, time) {
        const { localeMoment } = schedulerData;
        const dayOfWeek = localeMoment(time).weekday();
        if (dayOfWeek === 6 || dayOfWeek === 5) return true;
        return false;
    }
    onHandleResourceSelection(s) {
        const { copyingUsers, copyingUsersAll } = this.state;
        let { resources } = this.props.scheduleStore;
        resources = _.filter(resources, (r) => !!r.id);
        if (s === 'all') {
            if (copyingUsersAll) {
                this.setState({ copyingUsersAll: false, copyingUsers: [] });
            } else {
                const users = resources.map((r) => r.id);
                this.setState({ copyingUsersAll: true, copyingUsers: users });
            }
        } else {
            if (copyingUsers.indexOf(parseInt(s)) >= 0) {
                copyingUsers.splice(copyingUsers.indexOf(parseInt(s)), 1);
            } else {
                copyingUsers.push(parseInt(s));
            }
            this.setState({ copyingUsers });
        }
    }
    CheckAvailability = () =>{
        this.setState({
            isCheckAvailability: true
        })
    }
    afterAvailModal(status){
        if(status == 'cancel')
        this.setState({
            isCheckAvailability: false
        })
    }
    
    renderInner() {
        const { scheduleStore, userStore, t } = this.props;
        const { resources } = scheduleStore;
        const { currentUser } = userStore;
        const { viewModel, moving, editing, bulkEditing, stub, movingData, copying, copyingUsers, copyingStarted,toggle_todaybtn, isCheckAvailability } = this.state;
        
        const userType = currentUser.user_type;
        const goToUserFunc = userType == 'pm' || userType == 'admin' ? this.goToUser : null;

        return (
            <Fragment>
                <Scheduler
                    // copyingStarted={copyingStarted}
                   // onCopyingStart={() => this.startCopyToNextMonth()}
                    schedulerData={viewModel}
                    prevClick={this.prevClick}
                    nextClick={this.nextClick}
                    onSelectDate={this.onSelectDate}
                    onViewChange={this.onViewChange}
                    eventItemClick={this.eventClicked}
                    moveEvent={this.moveEvent}
                    eventItemTemplateResolver={this.eventItemTemplateResolver}
                    eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
                    getSummaryFunc={this.getSummary}
                    newEvent={this.newEvent}
                    rightCustomHeader={this.renderTools()}
                    isNonWorkingTimeFunc={this.isNonWorkingTimeFunc}
                    allowResourceSelection={userType == 'pm' || userType == 'admin'}
                    resourceSelection={this.state.copyingUsers}
                    onResourceSelectionChanged={(s) => this.onHandleResourceSelection(s)}
                    finish={(status) => this.afterEditing(status)}
                    moveToPending={(event,slotId,slotName,start,end)=>this.moveToPending(event,slotId,slotName,start,end)}
                    toggle_todaybtn={toggle_todaybtn}
                    t={t}
                />
                {/*slotClickedFunc={goToUserFunc}*/}
                {moving && (
                    <MoveOrCopyModal
                        movingData={movingData}
                        decide={(state, doNotify) => this.afterMoveDecision(state, doNotify)}
                    />
                )}
                {editing !== false && (
                    <ShiftForm
                        id={editing}     
                        add={editing === 0}
                        stub={stub}
                        finish={(status) => this.afterEditing(status)}
                        responseData={this.state.responseData}
                    />
                    
                )}
                {bulkEditing !== false && (
                    <BulkShiftForm
                        id={bulkEditing}
                        add={bulkEditing === 0}
                        stub={stub}
                        viewModel={viewModel}
                        finish={(status, obj) => this.afterBulkEditing(status, obj)}
                    />
                    
                )}
                {copying !== false && (
                    <CopyForm users={copyingUsers} data={viewModel} finish={(action) => this.afterCopy(action)} />
                )}
                {isCheckAvailability &&
                        <CheckAvailabilityForm 
                        id={editing}     
                        add={editing === 0}
                        stub={stub}
                        viewModel={viewModel}
                        finish={(status) => this.afterAvailModal(status)}/>
                }
              
            </Fragment>
        );
    }

    renderMonthCalender(){
        return(
            <Fragment>
                <MonthCalendar schedulerData={this.state.viewModel} toggle_todaybtn={this.state.toggle_todaybtn}   prevClick={this.prevClick}
                    nextClick={this.nextClick} rightCustomHeader={this.renderTools()} bulkEvent={this.newBulkEvent}  onViewChange={this.onViewChange} />
            </Fragment>
        )
  
    }

    
    viewAvailabilityAssign = () => {
        this.setState({ add: true, currentChecklistId: 0 });
        this.renderAvailabilityAssignModal();
    };

    renderAvailabilityAssignModal = () => {
        const { t } = this.props;
        this.setState({
            showAvailabilityList: true
        });
    };

    handleClosesAvailabilityListModal = () => {
        this.setState({ showAvailabilityList: false, add: false , isAddAvailability : false , selectedRowData : null});
        this.props.scheduleStore.returnDefaultNew();
    };

    submitAvailability = () => {
        let { availabilityData } = this.state;
        const { userStore } = this.props;
        availabilityData.availability.forEach(element => {
            element.for_date = moment(element.for_date).format("YYYY-MM-DD");
        });
        availabilityData.user_id = userStore.currentUser.id;
        availabilityData.client_id = userStore.currentUser.client_id;
        // delete availabilityData.from_date;
        // delete availabilityData.to_date;
        this.setState({availabilityData : availabilityData})
        this.props.scheduleStore.saveAvailability(availabilityData).then(res => {
            // console.log("RES : ", res);
            this.setState({isAddAvailability : false});
        });
        this.reloadData();
    }

    updateAvailabilityStatus = () => {
        this.reloadData();
    }

    editAvailabilityDetails = (data) => {
        this.setState({selectedRowData : data, isAddAvailability: true});
    }

    changeStatusOfRequest = (id, data) => {
        this.props.scheduleStore.updateStatus(id, data).then(res => {
            // console.log("res : ", res);
            this.setState({selectedRowData : null, isAddAvailability: false});
        });
    }

    editData = () => {
        this.props.scheduleStore.updateStatus(this.state.selectedRowData.id, this.state.selectedRowData).then(res => {
            // console.log("res : ", res);
            this.setState({selectedRowData : null, isAddAvailability: false});
        });
        this.reloadData();
    }

    render() {
        const { scheduleStore,t, userStore } = this.props;
        const { resources } = scheduleStore;
        const { viewModel,showCalendar,loading, isAddAvailability } = this.state;
        const loaded = !!resources && this.state.width > 0 && viewModel;
        if (loading) {
            return <LoadingSpinner />;
        }
       
        return (
            <div style={{ padding: 15 }}>
                <Modal
                    className="modal right fade"
                    show={this.state.showAvailabilityList}
                    onHide={this.handleClosesAvailabilityListModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Availability')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {isAddAvailability 
                        ? 
                        <>
                            <div className='text-right'>
                                <Button className="btn-fill btn-wd btn-icon btn btn-default" id="schedule-bulk-edit" onClick={() => this.setState({isAddAvailability : false, selectedRowData : null})} style={{height:'2.6em'}} >{t('Back')}</Button>
                            </div>
                            <ScheduleAddAvailability changeStatusOfRequest={(id, data) => this.changeStatusOfRequest(id, data)} editData={(data) => this.setState({selectedRowData : data})} selectedRowData={this.state.selectedRowData} setData={(data) => this.setState({availabilityData : data})} />
                        </>
                        :
                            <>
                                <div className='text-right'>
                                    {userStore.currentUser.user_type != 'member' && 
                                    <Button className="btn-fill btn-wd btn-icon btn btn-default" id="schedule-bulk-edit" onClick={this.CheckAvailability} style={{ height: '2.6em', marginBottom: '15px', marginRight: '4px' }} >{t('Request Availability')}</Button>
                                    }
                                    <Button className="btn-fill btn-wd btn-icon btn btn-default" id="schedule-bulk-edit" onClick={() => this.setState({ isAddAvailability: true })} style={{ height: '2.6em', marginBottom: '15px' }} >{'+ '}{t('Add Availability')}</Button>
                                </div>
                                <ScheduleAvailabilityList editRowData={(data) => this.editAvailabilityDetails(data)} updateAvailabilityStatus={this.updateAvailabilityStatus}/>
                            </>
                        }
                    </Modal.Body>
                    {isAddAvailability && 
                    <Modal.Footer>
                        <Button variant="secondary" 
                        onClick={() => !this.state.selectedRowData ? this.submitAvailability() : this.editData()} 
                        className="btn-wd btn-fill">
                            {t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleClosesAvailabilityListModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>}
                </Modal>
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        background: 'white',
                        padding: 15,
                        
                    }}
                    ref={this.wrapper}
                >
                    {!showCalendar && loaded && this.renderInner()}
                    {showCalendar && this.renderMonthCalender()}
                </div>
               
            </div>
            
        );
    }
}
