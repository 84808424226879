import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../../../hoc/withLocalization';
import SignatureModal from '../../../../../containers/Admin/SignDocs/Recipients/public/signatureModal';
import AgreementModal from '../../../../../containers/Admin/SignDocs/Recipients/public/agreementModal';
import { Modal, Button } from 'react-bootstrap';
import { Nav, NavDropdown, MenuItem, Navbar } from 'react-bootstrap';
//import Select from '../../../../../elements/Select';
import '../public/index.css';
import config from '~/library/config';
import Logo from '../../../../../assets/img/Logo.webp';
import { autorun } from 'mobx';
import { ReactComponent as ZoomInIcon } from '../../../../../assets/img/zoom-in.svg';
import { ReactComponent as ZoomOutIcon } from '../../../../../assets/img/zoom-out.svg';
import Print from '../../../../../assets/img/print-icon.png';
import Download from '../../../../../assets/img/download-icon.webp';
import LoadingSpinner from '../../../../../elements/LoadingSpinner';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SignWidget from '../../Widgets/SignWidget';
import TextboxWidget from '../../Widgets/TextboxWidget';
import InitialWidget from '../../Widgets/InitialWidget';
import DateWidget from '../../Widgets/DateWidget';
import { isThisHour } from 'date-fns';
import customerFeature from '~/utils/customerFeature';
import PrimaryLayout from '../../../../../layouts/PrimaryLayout';
import LabelWidget from '../../Widgets/LabelWidget';
import RecipientNameWidget from '../../Widgets/RecipientNameWidget';

import agent from '~/library/agent';

import SelectBoxWidget from '../../Widgets/SelectBoxWidget';
import CheckBoxWidget from '../../Widgets/CheckBoxWidget';
import RadioWidget from '../../Widgets/RadioWidget';
import NotificationSystem from 'react-notification-system';
import { style } from '~/variables/Variables';


@inject('commonStore', 'propertyStore', 'signatureStore', 'recipientStore')
@withLocalization
@observer
export class ReviewAndSignDoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docPages: null,
            recipient: null,
            signed_recipient: null,
            widgets: null,
            isAgreed: true,
            caseId: null,
            zoomScale: 1,
            accessCode: null,
            isVerified: false,
            getResponse: null,
        };
        
    }

    mobxHook = autorun(() => {
        const { commonStore } = this.props;
        if (commonStore.PendingNotifications.length > 0) {
            commonStore.PendingNotifications.forEach((pn) => {
                if (this.refs.notificationSystem) this.refs.notificationSystem.addNotification(pn);
            });
        }
    });

    componentDidMount() {
        const { signatureStore, propertyStore, history } = this.props;
        const search = this.props.location.search;
        const id = search.slice(4);
        const dateTimeRules = signatureStore.dateFormat;
        if (id) {
            signatureStore.getRecipientDoc(id).then((res) => {
                if(res && res.sign_document && res.sign_document.widgets.name === "Date"){
                    moment(widget.data).format(dateTimeRules.short_date_format)
                }
               
                if (res.status === "expired") {
                    history.push("/docexpire");
                } else if(res.status === "deleted") {
                    history.push("/docdeleted");
                }
                else {
                    propertyStore.addWidget(res.sign_document.widgets);
                    signatureStore.setCaseId(res.sign_document.id);
                    this.setState({
                       //  docPages: res.document_pages,
                        recipient: res.recipient_detail,
                      //   signed_recipients: res.signed_recipients,
                      //  widgets: res.sign_document.widgets,
                        getResponse: res,
                        isAgreed: res.isPolicyAgreed,
                        caseId: res.sign_document.id,
                        isVerified: res.isPolicyAgreed,
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps,prevState){

        if(prevState.widgets != this.state.widgets){
           
            if(screen.width < 500){
                document.querySelector(".signerPage").style.zoom = "50%"
            }

        }
    }

    handleLangChange = lang => {
        this.props.commonStore.setAppLang(lang);
        // Force Render
        this.setState({ state: this.state });
    };
    handleAccessCode(event) {
        const { signatureStore } = this.props;
        signatureStore.VerifyCode(event.target.value);
    }

    handleAgreement = () => {
        const { recipientStore } = this.props;
        recipientStore.recipientAgreement({ case_id: this.state.caseId, recipient_id: this.state.recipient.id });
        this.setState({ isAgreed: true, isVerified: true });
    };


    verifyCode() {
        const { signatureStore, history, recipientStore, commonStore } = this.props;
        const { getResponse } = this.state;
        const search = this.props.location.search;
        const url_id = search.slice(4);
        const data = {
            id: url_id,
            access_code: signatureStore.accessCode,
        };
        if ((signatureStore.accessCode).length > 6) {
            commonStore.addNotification(t('Access code should be of only 6 letter'), null, 'error');
        }

        signatureStore
            .verifyAccessCode(data)
            .then((res) => {
                if (res.status === 'Verified') {
                    this.setState({
                        isVerified: false,
                        docPages: getResponse.document_pages,
                        widgets: getResponse.sign_document.widgets
                    });
                }
            })
            .catch((err) => {
            });
    }

    handleContinue = () => {
        this.setState({ isAgreed: true });
    }
    getWidgetFromData(widget, pageId) {
        const { recipient, caseId } = this.state;
        if (widget.pageId === pageId) {
            if (widget.name === 'Signature') {
                if (widget.recipientId !== recipient.id && widget.data) {
                    return (
                        <SignWidget
                            key={widget.widgetId}
                            view={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                } else if (widget.recipientId !== recipient.id && !widget.data) {
                    return;
                } else if (widget.recipientId === recipient.id && widget.data && recipient.status === 'signed') {
                    return (
                        <SignWidget
                            key={widget.widgetId}
                            view={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                } else {
                    return (
                        <SignWidget
                            key={widget.widgetId}
                            edit={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                }

            } else if (widget.name === "Text") {
                if (widget.recipientId !== recipient.id) {
                    return <TextboxWidget key={widget.widgetId} info={widget} view={1} locked={true} />
                } else if (widget.recipientId !== recipient.id && !widget.data) {
                    return;
                } else if (widget.recipientId === recipient.id && widget.data && recipient.status === 'signed') {
                    return <TextboxWidget key={widget.widgetId} info={widget} view={1} locked={true} />;
                } else {
                    return <TextboxWidget key={widget.widgetId} info={widget} edit={1} locked={true} />;
                }
            } else if (widget.name === "Date") {
                if (widget.recipientId !== recipient.id) {
                    return <DateWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />
                } else if (widget.recipientId === recipient.id && recipient.status === "signed") {
                    return <DateWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />
                } else {
                    return <DateWidget key={widget.widgetId} info={widget} owner={true} locked={true} />;
                }
            } else if (widget.name === 'Initial') {
                if (widget.recipientId !== recipient.id && widget.data) {
                    return (
                        <InitialWidget
                            key={widget.widgetId}
                            view={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                } else if (widget.recipientId !== recipient.id && !widget.data) {
                    return;
                } else if (widget.recipientId === recipient.id && widget.data && recipient.status === 'signed') {
                    return (
                        <InitialWidget
                            key={widget.widgetId}
                            view={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                } else {
                    return (
                        <InitialWidget
                            key={widget.widgetId}
                            edit={1}
                            recipientDetail={recipient}
                            caseId={caseId}
                            info={widget}
                            locked={true}
                        />
                    );
                }
                // return <InitialWidget key={widget.widgetId} info={widget} locked={true} />
            } else if (widget.name === 'Label') {
                if (widget.recipientId !== recipient.id) {
                    return <LabelWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return <LabelWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else {
                    return <LabelWidget key={widget.widgetId} info={widget} owner={true} locked={true} />;
                }
            } else if (widget.name === "Recipient") {

                if (widget.recipientId !== recipient.id) {
                    return (
                        <RecipientNameWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />
                    );
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return (
                        <RecipientNameWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />
                    );
                } else {
                    return <RecipientNameWidget key={widget.widgetId} info={widget} owner={true} locked={true} />;
                }
            } else if (widget.name === "Select") {

                if (widget.recipientId !== recipient.id && widget.data) {
                    return <SelectBoxWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return <SelectBoxWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else if (widget.recipientId !== recipient.id && !widget.data) {
                    return;
                } else {
                    return <SelectBoxWidget key={widget.widgetId} info={widget} owner={true} edit={1} locked={true} />;
                }
            } else if (widget.name === "Checkbox") {

                if (widget.recipientId !== recipient.id) {
                    return <CheckBoxWidget key={widget.widgetId} disable={true} info={widget} view={1} locked={true} />;
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return <CheckBoxWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else {
                    return <CheckBoxWidget key={widget.widgetId} info={widget} owner={true} edit={1} locked={true} />;
                }
            } else if (widget.name === 'Radio') {
                if (widget.recipientId !== recipient.id) {
                    return <RadioWidget key={widget.widgetId} disable={true} info={widget} view={1} locked={true} />;
                } else if (widget.recipientId === recipient.id && recipient.status === 'signed') {
                    return <RadioWidget key={widget.widgetId} info={widget} owner={false} view={1} locked={true} />;
                } else {
                    return <RadioWidget key={widget.widgetId} info={widget} owner={true} edit={1} locked={true} />;
                }
            }
            else {
                return ''
            }
        } else {
            return '';
        }
    }

    zoomIn = () => {
        if (this.state.zoomScale >= 1.5) return;
        this.setState({ zoomScale: this.state.zoomScale + 0.1 });
    };
    zoomOut = () => {
        if (this.state.zoomScale <= 1) return;
        this.setState({ zoomScale: this.state.zoomScale - 0.1 });
    };
    handleFinish = () => {
        // const{case} = this.state;        
        const { signatureStore, propertyStore, commonStore, t, history,caseId } = this.props;
        let flag = 0;
        let widgetsData = {
            caseId: signatureStore.caseId,
            widgets: propertyStore.widgets
          }
        for (let i = 0; i < propertyStore.widgets.length; i++) {
            if (propertyStore.widgets[i].recipientId === this.state.recipient.id && propertyStore.widgets[i].required && !propertyStore.widgets[i].data) {
                flag = 1;
                break;
            }
        }
        if (flag) {
            commonStore.addNotification(t('Please add signature widget for all recipient'), null, 'error');
            return
        }
        const search = this.props.location.search;
        const id = search.slice(4);
        
        propertyStore.saveWidget(widgetsData)
            signatureStore.getByDocCaseId(id).then((res) => {
            history.push("/signsuccess");
        })


    }

    handleDownload = () => {
        const { signatureStore, propertyStore, commonStore ,caseId} = this.props;
        const search = this.props.location.search;
        const id = search.slice(4);
        let widgetsData = {
            caseId: signatureStore.caseId,
            widgets: propertyStore.widgets
          }
          propertyStore.saveWidget(widgetsData)
        window.open(`${config.API_ROOT}/sign_documents/download/${encodeURIComponent(id)}`);

    }
    langName = (lang) => {
        if (lang == 'no') return 'Norsk';
        if (lang == 'en') return 'English';
        return defaultLanguage();
    };

    render() {
        const { docPages, recipient, caseId } = this.state;
        const { signatureStore, t } = this.props;
        // if (!docPages) {
        //     return <LoadingSpinner />;
        // }
        return (
            <div className="signerPage">
                <NotificationSystem ref="notificationSystem" style={style} />
                <div className='nav-signer'>
                    {/* <Navbar className="navbar-default"> */}
                        <div className="header">
                            <div className="header-details-nav">

                                {/* <div style={{display: 'flex',justifyContent: 'space-between',margin: '15px'}}> */}
                                {
                                    docPages && (

                                        <div className="footernav">
                                            <h4>{t('Review Document and Sign It')}</h4>

                                            <NavDropdown
                                                title={(
                                                    <div className="lp-text-btn">
                                                        <i className="fa fa-globe" />
                                                        {this.langName(this.props.commonStore.locale)}
                                                    </div>
                                                )}
                                                noCaret
                                                id="basic-nav-dropdown-1"
                                                style={{
                                                    padding: 0,
                                                }}
                                            >
                                                <MenuItem onClick={() => this.handleLangChange('en')}>English</MenuItem>
                                                <MenuItem onClick={() => this.handleLangChange('no')}>Norsk</MenuItem>
                                            </NavDropdown>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    {/* </Navbar> */}
                </div>
                <div className="header">
                    {
                        docPages && (

                            <div className="header-details">
                                <h6>{recipient && recipient.user_name}</h6>
                                <p>{recipient && recipient.email}</p>
                            </div>
                        )
                    }
                </div>
                <div>
                    {
                        signatureStore.isDocFinished ?
                           <LoadingSpinner /> :
                            <div className='content'>
                                {
                                    docPages && docPages.map((page) => {
                                        return (
                                            <>
                                                <div className="sign-img">
                                                    <LazyLoadImage src={`${config.UPLOADS_ROOT}/${page.page_path}`} draggable="false" />
                                                    {
                                                        this.state.widgets && this.state.widgets.map((widget) => {
                                                            return (
                                                                this.getWidgetFromData(widget, page.id)
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="devider"></div>
                                            </>
                                        );
                                    })
                                }
                            </div>
                    }
                </div>
                {docPages && recipient && recipient.status !== 'signed' && (
                    <footer>

                        <div className="footer-items">
                            <img src={Logo} />


                            {/* <div className="icons-">
                                <i className="fa-thin fa-arrow-down-to-line" />
                                <ZoomOutIcon
                                    style={{ background: 'rgb(235, 243, 252)', padding: '7px' }}
                                    onClick={this.zoomOut}
                                />
                                <ZoomInIcon
                                    style={{ background: 'rgb(235, 243, 252)', padding: '7px' }}
                                    onClick={this.zoomIn}
                                />
                            </div> */}

                            {
                            signatureStore.isDocFinished ?
                                <img
                                src={Download}
                                style={{
                                    background: 'rgb(235, 243, 252)',
                                    width: '30px',
                                    height: '32px',
                                    padding: '5px',
                                    marginLeft: '35%',
                                    cursor: 'not-allowed',
                                    display : 'block'
                                }}
                            /> 
                            :
                                
                            <img
                                src={Download}
                                onClick={this.handleDownload}
                                style={{
                                    background: 'rgb(235, 243, 252)',
                                    width: '30px',
                                    height: '32px',
                                    padding: '5px',
                                    marginLeft: '35%',
                                    cursor: 'pointer',
                                    display : 'block'
                                }}
                                /> 
                             }   


                            {recipient.signing_type !== 'Send Copy' && (
                                <button className="btn btn-primary ml-auto finishBtn" disabled={signatureStore.isDocFinished} onClick={this.handleFinish} style={{ marginRight: "20px" }}>
                                    {t('Finish')}
                                </button>
                            )}

                        </div>

                    </footer>
                )}


                <AgreementModal
                    show={!this.state.isAgreed}
                    handleAgreement={this.handleAgreement}
                    user={recipient && recipient.user_name}
                    email={recipient && recipient.email}
                />

                <Modal className="VerificationModal" show={this.state.isVerified}>
                    <Modal.Header style={{ background: '#f0f4f7', borderRadius: '8px 8px 0px 0px'}}>
                        <Modal.Title style={{ fontWeight: 'bolder', textAlign: 'center' }}>{t('Access Verification')}</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>

                        <div className='verification-main'>
                            <div style={{ marginTop: '20px' }}>
                                <input className='contractNameField noscroll' type="text" placeholder={this.props.t("Enter Code")} onChange={(e) => this.handleAccessCode(e)} style={{ height: '35px', color: 'black', paddingLeft: '7px' }} />
                            </div>
                            <Button
                                className="btn btn-fill"
                                type="button"
                                style={{ marginTop: '15px' }}
                                onClick={() => {
                                    this.verifyCode();
                                }}
                            >
                                {t('Verify')}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default ReviewAndSignDoc;
