import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import agent from '~/library/agent';


//styles
import '../../../styles/css/styles.css';
import clientRegister from '../../../assets/img/client-register.png'
// import email from '../../../assets/img/email.png'


//components
// import { Button } from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Input } from '../../../elements/Input';
import withLocalization from '~/hoc/withLocalization';
import config from '../../../library/config';
import DatePicker from '../../../components/DatePicker/DatePicker';

//this component is used for adding new visitor details//

@inject('visitorStore', 'userStore', 'commonStore')
@withLocalization
@withRouter
@observer
class UserRegister extends Component {
    state = {
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        social_number: '',
        phone: 0,
        birthday: '',
        address: '',
        post_place: '',
        post_number : '',
        thankyou: false,
        errors: false,
        errorPage: true,
        emailValidation: false,
        phoneValidation: false,
        generalValidation: false,
        errorMessage: ''
    }
    handleChange(name, value) {
        this.setState({ [name]: value })
    }

    componentDidMount() {
        let params = this.props.location.pathname;
        let id = params.replace('/user-register/', '')

        return agent.Users.getInvitedClientId({ id: id })
            .then((res) => {
                let name = '';
                let imageId = '';
                if (res.data.name) {
                    name = res.data.name
                }
                if (res.data.image) {
                    imageId = res.data.image
                }
                this.setState({ name: name, imageId: imageId })
            })
            .catch((err) => {
                this.setState({ errorPage: false })
                console.log("err:", err)
                throw err;
            })
    }

    handleErrors = (name, value) => {
        if (name == 'username' && value == '') {
            this.setState({ errors: true })
        }
        if (name == 'last_name' && value == '') {
            this.setState({ errors: true })
        }
        if (name == 'email' && value == '') {
            this.setState({ errors: true })
        }
        if (name == 'social_number' && value == '') {
            this.setState({ errors: true })
        }
        if (name == 'first_name' && value == '') {
            this.setState({ errors: true })
        }
        if (name == 'phone' && value == '') {
            this.setState({ errors: true })
        }
    }
    handle_Errors = (value) => {
        if (value != '') {
            this.setState({ errors: false })
        }
    }

    handleButtonClick = async () => {
        let emailvalidate = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        let phoneValidate = /^[0-9\b]+$/
        if (this.state.username == '' || this.state.first_name == '' || this.state.last_name == '' || this.state.social_number == '') {
            this.setState({ errors: true })
            return true;
        }
        if(this.state.email == '' || !this.state.email.match(emailvalidate) ){
            this.setState({emailValidation: true})
            return true;
        }
        if(this.state.phone === '' || !phoneValidate.test(this.state.phone)){
            this.setState({phoneValidation: true})
            return true
        }
        const state = Object.assign({}, this.state);
        state.username = this.state.username
        state.first_name = this.state.first_name
        state.last_name = this.state.last_name
        state.email = this.state.email
        state.social_number = this.state.social_number
        state.phone = this.state.phone
        state.birthday = this.state.birthday
        state.address = this.state.address
        state.post_number = this.state.post_number
        state.post_place = this.state.post_place
        let params = this.props.location.pathname;
        params = params.replace('/user-register/', '')
        state.client_id = params;
        let data = JSON.stringify(state);

        return agent.Users.saveMember(JSON.parse(data)).then((res) => {
            if(res.isError){
                this.setState({errorMessage: res.error, generalValidation: true})
            } else {
                this.setState({ thankyou: true, phoneValidate: false, generalValidation: false, emailValidation: false })
            }
        })
        .catch((err) => {
            console.log("err:", err)
            this.setState({errorMessage: err.message, generalValidation: true})
            throw err;
        })
        // this.setState({ errorPage: false })
        // let response = await fetch('http://localhost:3000/api/admins/register-user', {
        //     method: 'POST',
        //     mode: 'cors',
        //     body: data,
        //     headers: {
        //         "Content-type": "application/json"
        //     }
        // })
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className='container '>
                    <div className="row" style={{ paddingTop: '50px' }}>
                        <div className='row'>
                            <div className='col-md-4'></div>
                            <div className='col-md-4' style={{ display: 'flex', justifyContent: 'center' }}>
                                {this.state.imageId &&
                                    <img style={{ maxHeight: 200, maxWidth: 200 }} src={`${config.UPLOADS_API_ENDPOINT}/${this.state.imageId}`} />
                                }
                            </div>
                            <div className='col-md-4'></div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'></div>
                            <div className='col-md-4' style={{ display: 'flex', justifyContent: 'center' }}>
                                <p style={{ fontSize: 20 }}>{this.state.name}</p>
                            </div>
                            <div className='col-md-4'></div>
                        </div>
                        <div className='col-md-2'></div>
                        <div className='col-md-8' style={{ backgroundColor: 'white', borderRadius: 4 }}>
                            <div className='' style={{ alignItems: 'center' }}>
                                {this.state.thankyou == false && this.state.errorPage == true &&
                                    <div className='col-sm-12 col-md-12'>
                                        <div className='row' style={{ paddingLeft: '23px', textAlign: 'center   '}}>
                                            <h3 style={{ fontWeight: '600' }}>{t('Member Register')}</h3>
                                        </div>
                                        <div className='row' style={{ paddingLeft: '23px' }}>
                                        </div>
                                        <div className='row'>
                                            <form>
                                                <div className='col-md-6'>
                                                    <div className="custom-form__row">
                                                        <Input
                                                            className="custom-form__col"
                                                            placeholder={t('Username')}
                                                            label={t('Username') + '*'}
                                                            type="text"
                                                            name="username"
                                                            value={this.state.username}
                                                            required
                                                            onChange={evt => {
                                                                this.handleChange('username', evt.target.value);
                                                            }}
                                                            onBlur={evt => this.handleErrors('username', evt.target.value)}
                                                            onFocus={evt => this.handle_Errors('username', evt.target.value)}
                                                        />
                                                    </div>
                                                    <div className="custom-form__row">
                                                        <Input
                                                            className="custom-form__col"
                                                            placeholder={t('First Name')}
                                                            type="text"
                                                            name="first_name"
                                                            label={t('First Name') + '*'}
                                                            value={this.state.first_name}
                                                            required
                                                            onChange={evt => {
                                                                this.handleChange('first_name', evt.target.value);
                                                            }}
                                                            onBlur={evt => this.handleErrors('first_name', evt.target.value)}
                                                            onFocus={evt => this.handle_Errors('first_name', evt.target.value)}
                                                        />
                                                    </div>
                                                    <div className="custom-form__row public_form_user_register" style={{padding: 10}}>
                                                        <div>                                                            
                                                        <DatePicker
                                                            selected={this.state.birthday}
                                                            onChange={date => this.handleChange('birthday',date)}
                                                            dateFormat={"yyyy-MM-dd"}
                                                            label={t('Date of Birth')}
                                                        />
                                                        </div>
                                                    </div>
                                                    <div className="custom-form__row">
                                                        <Input
                                                            className="custom-form__col"
                                                            placeholder={t('Phone no.')}
                                                            type="text"
                                                            name="phone"
                                                            label={t('Phone no.') + '*'} value={this.state.phone}
                                                            required
                                                            onChange={evt => {
                                                                this.handleChange('phone', evt.target.value);
                                                            }}
                                                            onBlur={evt => this.handleErrors('phone', evt.target.value)}
                                                            onFocus={evt => this.handle_Errors('phone', evt.target.value)}
                                                        />
                                                    </div>
                                                    <div className="custom-form__row">
                                                        <Input
                                                            className="custom-form__col"
                                                            placeholder={t('Zipcode')}
                                                            type="text"
                                                            name="post_number"
                                                            label={t('Zipcode')} value={this.state.post_number}
                                                            required
                                                            onChange={evt => {
                                                                this.handleChange('post_number', evt.target.value);
                                                            }}
                                                            onBlur={evt => this.handleErrors('post_number', evt.target.value)}
                                                            onFocus={evt => this.handle_Errors('post_number', evt.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                <div className="custom-form__row">
                                                        <Input
                                                            className="custom-form__col"
                                                            placeholder={t('Email')}
                                                            type="email"
                                                            name="email"
                                                            label={t('Email') + '*'}
                                                            value={this.state.email}
                                                            required
                                                            onChange={evt => {
                                                                this.handleChange('email', evt.target.value);
                                                            }}
                                                            onBlur={evt => this.handleErrors('email', evt.target.value)}
                                                            onFocus={evt => this.handle_Errors('email', evt.target.value)}
                                                        />
                                                    </div>
                                                    <div className="custom-form__row">
                                                        <Input
                                                            className="custom-form__col"
                                                            placeholder={t('Last Name')}
                                                            type="text"
                                                            label={t('Last Name') + '*'}
                                                            name="last_name"
                                                            value={this.state.last_name}
                                                            required
                                                            onChange={evt => {
                                                                this.handleChange('last_name', evt.target.value);
                                                            }}
                                                            onBlur={evt => this.handleErrors('last_name', evt.target.value)}
                                                            onFocus={evt => this.handle_Errors('last_name', evt.target.value)}
                                                        />
                                                    </div>
                                                    <div className="custom-form__row">
                                                        <Input
                                                            className="custom-form__col"
                                                            placeholder={t('SSN')}
                                                            type="text"
                                                            name="social_number"
                                                            label={t('SSN') + '*'}
                                                            value={this.state.social_number}
                                                            required
                                                            onChange={evt => {
                                                                this.handleChange('social_number', evt.target.value);
                                                            }}
                                                            onBlur={evt => this.handleErrors('social_number', evt.target.value)}
                                                            onFocus={evt => this.handle_Errors('social_number', evt.target.value)}
                                                        />
                                                    </div>
                                                    <div className="custom-form__row">
                                                        <Input
                                                            className="custom-form__col"
                                                            placeholder={t('Address')}
                                                            type="text"
                                                            label={t('Address')}
                                                            name="address"
                                                            value={this.state.address}
                                                            onChange={evt => {
                                                                this.handleChange('address', evt.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="custom-form__row">
                                                        <Input
                                                            className="custom-form__col"
                                                            placeholder={t('City')}
                                                            type="text"
                                                            name="post_place"
                                                            label={t('City')}
                                                            value={this.state.post_place}
                                                            onChange={evt => {
                                                                this.handleChange('post_place', evt.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-3'></div>
                                            <div className='col-md-6' style={{ textAlign: 'center' }}>
                                                {this.state.errors == true ? <span style={{ color: 'red' }}>Please Add All Required Fields</span> : null}
                                                {this.state.emailValidation == true ? <><br/><span style={{ color: 'red' }}>Enter Valid Email</span></> : null}
                                                {this.state.phoneValidation == true ? <><br/><span style={{ color: 'red' }}>Enter Valid Phone Number</span></> : null}
                                                {this.state.generalValidation == true ? <><br/><span style={{ color: 'red' }}>{this.state.errorMessage}</span></> : null}
                                                <div className="custom-form__row">
                                                    <Button fill onClick={() => this.handleButtonClick()} style={{ height: "5rem", margin: "5px 10px", fontSize: "18px!important", width: '100%' }}>
                                                        {t('Submit')}
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className='col-md-3'></div>
                                        </div>
                                    </div>
                                }
                                {this.state.thankyou == true &&
                                    <div className='col-sm-12 col-md-12'>
                                        <p style={{ textAlign: 'center', fontSize: 30 }}>{t('Thanks for the entering the information. You will notified when your access is activated.')}</p>
                                    </div>
                                }
                                {this.state.errorPage == false && this.state.thankyou == false &&
                                    <div className="container">
                                        <h2>404</h2>
                                        <h3>{t('Oops, nothing here...')}</h3>
                                        <p>{t('Please Check the URL')}</p>
                                        <p>{t('Otherwise,')} <a href="/">{t('Click here')}</a> {t('to redirect to homepage.')}</p>
                                    </div>
                                }
                                {/* <div className='col-md-4'>
                                    <img src={clientRegister} alt='register.svg' />
                                </div> */}
                            </div>
                        </div>
                        <div className='col-md-2'></div>
                    </div>
                </div>
            </>
        )
    }
}
export default UserRegister;