import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import _, { round } from 'lodash';
import { longDateFormat, currencyDecimalSize } from '../../../utils/dateFormat'

// HOC
import { Modal } from 'react-bootstrap';

import withLocalization from '../../../hoc/withLocalization';

// Components
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import { TasksWidget } from '../../../components/GenericForm/Widgets/TasksWidget';
import { AllUsersInCompanyWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import CustomRadio from '../../../components/CustomRadio/CustomRadio';
import AttachmentsWidget from '../../../components/GenericForm/Widgets/AttachmentsWidget';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { confirmAlert } from '../../../components/confirmAlert/confirmAlert';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { Accordion, AccordionItem } from '../../../components/Accordion/Accordion';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
// Elements
import { Input, Textarea } from '../../../elements/Input';
import LoadingSpinner from '../../../elements/LoadingSpinner';

import globalConfig from '~/library/config';

@inject('deviationStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class ClientsForm extends Component {
    state = {
        showErrors: false,
        reportOpen: false,
        reportEmail: '',
        reportSending: false,
        modalTransition: {
            comment: '',
            showError: false,
        },
    };

    loadData() {
        const { deviationStore, id, add } = this.props;
        if (add) {
            deviationStore.returnDefaultNew();
            this.handleChange('myself', true);
            this.handleChange('for_date', moment(new Date()).format('YYYY-MM-DD'));
            return;
        }
        deviationStore.load(id, add);
    }

    componentWillMount() {
        this.props.deviationStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    getTransition = (transitions, name) => {
        return transitions.filter(transition => transition.name === name)[0];
    };

    onDelete = () => {
        const { id } = this.props.deviationStore.currentEntity;
        this.props.onDelete(id);
    };

    onSave() {
        const {
            deviationStore, add, t, commonStore
        } = this.props;
        const { currentEntity: fullDeviation } = deviationStore;
        const preparedData = deviationStore.getPreparedData;
        const { Transitions, assigned_id, assigned_id_init } = fullDeviation;
        const isAssignChanged = assigned_id != assigned_id_init;

        this.setState({
            showErrors: true,
        });

        if (!preparedData.project_id || !preparedData.subject || !preparedData.comments) {
            // || !preparedData.due_date
            return commonStore.addNotification(t('Please fill all required fields'), null, 'error');
        }
        if (isAssignChanged) {
            confirmAlert({
                title: t('Confirm the action'),
                message: t('The assigned person was changed.'),
                buttons: [
                    {
                        label: t('Save without assignee changes'),
                        onClick: () => this.saveDeviation(preparedData, add),
                    },
                    {
                        label: t('Change assignee and save'),
                        onClick: async () => {
                            const assign = this.getTransition(Transitions, 'assign');
                            const reassign = this.getTransition(Transitions, 'reassign');
                            if (assign) {
                                this.transitionAction('assign', { assigned_id, ...preparedData }, true);
                            } else if (reassign) {
                                this.transitionAction('reassign', { assigned_id, ...preparedData }, true);
                            }
                        },
                    },
                    {
                        label: t('Cancel'),
                    },
                ],
            });
        } else {
            this.saveDeviation(preparedData, add);
        }
    }

    saveDeviation = (changes, add) => {
        const {
            deviationStore, history, t, commonStore
        } = this.props;
        deviationStore
            .save(changes, add)
            .then(({ id }) => {
                commonStore.addNotification(t('Saved'), null, 'success');
                if (add && !changes.myself) {
                    commonStore.addNotification(
                        t(
                            'Thank you for deviation registration, we have sent a message to manager and he will react asap'
                        ),
                        <i className="fa fa-mobile" />,
                        'info',
                        'bc',
                        10
                    );
                }
                if (add) {
                    history.push(`/admin/deviation/${id}`);
                }
            })
            .catch(err => {
                commonStore.addNotification(t(err), null, 'error');
            });
    };

    handleChange = (name, value) => {
        const {commonStore} = this.props
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        if(name == "spent_rate"){
            let t = value;
            value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), currencyDecimalSize(dateTimeRules))) : t;
        }
        this.props.deviationStore.handleChange(name, value);
    };

    handleChangeMyself = () => {
        const { deviationStore } = this.props;
        const { currentEntity: deviation } = deviationStore;

        this.handleChange('myself', !deviation.myself);
        this.handleChange('due_date', new Date());
    };

    transitionAction = (transitionName = '', transitionData = {}, withDeviationSave) => {
        if (!transitionName) {
            return Promise.reject();
        }

        // Get transition
        const {
            deviationStore: {
                currentEntity: { Transitions = [] },
            },
        } = this.props;
        const transition = this.getTransition(Transitions, transitionName);
        let { name: type, cbMessage = 'Saved', requireComment } = transition;

        // Transition Logic
        const { t, commonStore, deviationStore } = this.props;
        const {
            currentEntity: { id, assigned_id },
        } = deviationStore;
        const { modalTransition } = this.state;

        const assignTransitions = ['reassign', 'assign', 'unassign'];

        if (assignTransitions.indexOf(transitionName) !== -1) {
            transitionData.assigned_id = assigned_id;
        }

        // Transition with comment
        if (requireComment) {
            // If modal Transition doesn't exist, create it
            if (!modalTransition.name) {
                return this.setState({
                    modalTransition: {
                        ...transition,
                        comment: '',
                        showError: false,
                    },
                });
            }

            // If modal Transition comment is empty
            if (!modalTransition.comment) {
                commonStore.addNotification(t('Please fill all required fields'), null, 'error');
                return this.setState({
                    modalTransition: {
                        ...modalTransition,
                        showError: true,
                    },
                });
            }

            // Remove modal Transition if all is correct
            this.setState({
                modalTransition: {
                    comment: '',
                    showError: false,
                },
            });
        }

        // Transition request
        return deviationStore
            .transitionDeviation(id, type, transitionData)
            .then(({
                deviation: {
                    Transitions, assigned_id, data, status, ...deviation
                }
            }) => {
                let newDeviationData = {
                    Transitions,
                    status,
                    data,
                };

                if (withDeviationSave) {
                    cbMessage = 'Saved';
                }

                if (assignTransitions.indexOf(transitionName) !== -1) {
                    newDeviationData = {
                        ...newDeviationData,
                        assigned_id,
                        assigned_id_init: assigned_id,
                    };
                }
                if (withDeviationSave) {
                    newDeviationData = {
                        ...deviation,
                    };
                }

                this.props.deviationStore.handleChangeFull(newDeviationData);
                commonStore.addNotification(t(cbMessage), null, 'success');
                return true;
            })
            .catch(err => {
                commonStore.addNotification(t(err), null, 'error');
            });
    };

    getReport() {
        this.setState({ reportOpen: true });
    }

    reportAction(action) {
        const { t, deviationStore, commonStore } = this.props;
        const { currentEntity: deviation } = deviationStore;
        if (this.state.reportSending) return;
        if (action === 'preview') {
            window.open(
                `${globalConfig.API_ROOT}/deviations/${deviation.id}/pdf?authorization=${commonStore.token}&preview=true`
            );
        }
        if (action === 'email') {
            this.setState({ reportSending: true });
            deviationStore.sendEmail(deviation.id, this.state.reportEmail).then(() => {
                this.setState({ reportSending: false });
                commonStore.addNotification(t('Successfully sent email'), null, 'success');
            });
        }
        if (action === 'download') {
            window.open(
                `${globalConfig.API_ROOT}/deviations/${deviation.id}/pdf?authorization=${commonStore.token}&download=true`
            );
        }
    }

    renderTransitions() {
        const { deviationStore, t } = this.props;
        const { currentEntity: deviation } = deviationStore;
        const { Transitions = [] } = deviation;
        const skips = ['reassign', 'assign', 'unassign'];
        const availableTransitions = _.filter(Transitions, tr => skips.indexOf(tr.name) < 0);
        const preparedData = deviationStore.getPreparedData;

        return (
            <>
                {availableTransitions.map((tran, index) => (
                    <div
                        className={`custom-form__col custom-form__col_grow-0 custom-form__col_v-center ${
                            index === 0 ? 'custom-form__col_sep-right' : ''
                        }`}
                        key={tran.name}
                    >
                        <Button onClick={() => this.transitionAction(tran.name, preparedData)} fill wd>
                            {t(tran.title)}
                        </Button>
                    </div>
                ))}
            </>
        );
    }

    getAttachmentGroup = (userType, isAssignee, isReporter) => {
        if (isAssignee) {
            return 'assignee';
        }
        if (isReporter) {
            return 'reporter';
        }
        if (userType === 'admin' || userType === 'pm') {
            return userType;
        }
        return null;
    };

    handleChangeTransitionComment = value => {
        const modalTransition = { ...this.state.modalTransition };
        modalTransition.comment = value;
        this.setState({
            modalTransition,
        });
    };

    render() {
        const {
            deviationStore,
            config,
            t,
            userStore: { currentUser },
            isUnionMode, commonStore
        } = this.props;
        const { showErrors, modalTransition = { comment: '' } } = this.state;
        const { loading, currentEntity: deviation } = deviationStore;
        const { Transitions = [] } = deviation;
        const urgencies = config.urgency ? Object.values(config.urgency) : [];

        // Transitions
        const assign = this.getTransition(Transitions, 'assign');
        const unassign = this.getTransition(Transitions, 'unassign');
        const reassign = this.getTransition(Transitions, 'reassign');

        // Attachments Groups
        const attachmentsGroups = [
            {
                key: 'assignee',
                label: t('Assignee attachments'),
            },
            {
                key: 'reporter',
                label: t('Reporter attachments'),
            },
            {
                key: 'admin',
                label: t('Admin attachments'),
            },
            {
                key: 'pm',
                label: t('PM attachments'),
            },
        ];

        const isAssignChanged = deviation.assigned_id != deviation.assigned_id_init;

        const userType = currentUser.user_type;
        const isAssignee = deviation.assigned_id_init === currentUser.id;
        const isReporter = deviation.reported_by_id === currentUser.id;

        const canEditMainInfo = userType === 'admin' || userType === 'pm' || isReporter || !deviation.id;

        let reportedUsername = `${currentUser.first_name} ${currentUser.last_name}`;
        let assignedUsername = '';
        const projectName = _.get(deviation, 'Project.name', '');
        const taskName = _.get(deviation, 'Task.name', '');

        if (deviation.Reporter) {
            reportedUsername = `${deviation.Reporter.first_name} ${deviation.Reporter.last_name}`;
        }
        if (deviation.Assignee) {
            assignedUsername = `${deviation.Assignee.first_name} ${deviation.Assignee.last_name}`;
        }

        if (isUnionMode && !deviation.id) {
            // deviation.myself = true;
        }

        const history = _.get(deviation, 'data.history', []);

        if (loading) return <LoadingSpinner />;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        return (
            <>
                <div className={deviation.id > 0 ? 'page page_overflow' : 'page'}>
                    <div className="page__header">
                        <h1 className="page__title">
                            {t(deviation.id > 0 ? 'Register deviation' : 'Registered deviation')}
                        </h1>
                    </div>
                    <div className="page__content">
                        <form className="custom-form">
                            <Accordion
                                preExpanded={['details', 'other', 'manage', 'cost', 'history']}
                                allowZeroExpanded
                            >
                                <AccordionItem title={t('Deviation basic')} uuid="details">
                                    <div className="custom-form__row">
                                        <div className="custom-form__col">
                                            <div className="read-area">
                                                <span className="read-area__label">{t('Status')}</span>
                                                <div className="read-area__content">
                                                    {t(config.names[deviation.status])}
                                                </div>
                                            </div>
                                        </div>
                                        {!!assignedUsername && (
                                            <div className="custom-form__col">
                                                <div className="read-area">
                                                    <span className="read-area__label">{t('Responsible')}</span>
                                                    <div className="read-area__content">{assignedUsername}</div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="custom-form__col">
                                            <div className="read-area">
                                                <span className="read-area__label">{t('Reported by')}</span>
                                                <div className="read-area__content">{reportedUsername}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {canEditMainInfo ? (
                                        <>
                                            <div className="custom-form__row">
                                                <label className="custom-form__col">
                                                    <ProjectsWidget
                                                        label={`${t('Project')} *`}
                                                        value={deviation.project_id}
                                                        allowAll
                                                        onChange={value => {
                                                            this.handleChange('project_id', value);
                                                        }}
                                                        error={showErrors && !deviation.project_id}
                                                    />
                                                </label>
                                                <label className="custom-form__col">
                                                    <TasksWidget
                                                        emptyOption
                                                        emptyOptionLabel={t('Task not applicable')}
                                                        placeholder={t('Select Task')}
                                                        label={t('Task')}
                                                        value={deviation.task_id}
                                                        filterByProject={deviation.project_id}
                                                        disabled={!deviation.project_id}
                                                        onChange={value => {
                                                            this.handleChange('task_id', value);
                                                        }}
                                                    />
                                                </label>
                                            </div>
                                            <div className="custom-form__row">
                                                <Input
                                                    className="custom-form__col"
                                                    label={`${t('Deviation Subject')} *`}
                                                    placeholder={t('Deviation Subject')}
                                                    error={showErrors && !deviation.subject}
                                                    type="text"
                                                    value={deviation.subject}
                                                    onChange={({ target: { name, value } }) => this.handleChange(name, value)
                                                    }
                                                    name="subject"
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="custom-form__row">
                                                <div className="custom-form__col">
                                                    <div className="read-area">
                                                        <span className="read-area__label">{t('Project')}</span>
                                                        <div className="read-area__content">{projectName}</div>
                                                    </div>
                                                </div>
                                                <div className="custom-form__col">
                                                    <div className="read-area">
                                                        <span className="read-area__label">{t('Task')}</span>
                                                        <div className="read-area__content">
                                                            {taskName || t('Task not applicable')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="custom-form__row">
                                                <div className="custom-form__col">
                                                    <div className="read-area">
                                                        <span className="read-area__label">
                                                            {t('Deviation Subject')}
                                                        </span>
                                                        <div className="read-area__content">{deviation.subject}</div>
                                                    </div>
                                                </div>
                                                <div className="custom-form__col">
                                                    <div className="read-area">
                                                        <span className="read-area__label">{t('Due date')}</span>
                                                        <div className="read-area__content">
                                                            {moment(new Date(deviation.due_date)).format(
                                                                'dddd MMMM D, YYYY'
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className="custom-form__row">
                                        <Textarea
                                            className="custom-form__col"
                                            label={`${t('Deviation details')} *`}
                                            error={showErrors && !deviation.comments}
                                            placeholder={t('Deviation details')}
                                            value={deviation.comments}
                                            onChange={({ target: { name, value } }) => this.handleChange(name, value)}
                                            name="comments"
                                        />
                                    </div>
                                    <div className="custom-form__row">
                                        <div className="custom-form__col">
                                            <div className="input-area">
                                                <span className="input-area__label">{t('Urgency')}</span>
                                                <div className="input-area__content flex-box">
                                                    {urgencies.map(urgency => (
                                                        <CustomRadio
                                                            key={urgency.key}
                                                            withColor={urgency.color}
                                                            required
                                                            label={t(urgency.label)}
                                                            value={urgency.key}
                                                            checked={urgency.key === deviation.urgency}
                                                            onChange={({ target: { name, value } }) => {
                                                                this.handleChange(name, value);
                                                            }}
                                                            name="urgency"
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-form__row">
                                        <div className="custom-form__col">
                                            <div className="input-area">
                                                <span className="input-area__label">{t('Deviation attachments')}</span>
                                                <div className="input-area__content">
                                                    <AttachmentsWidget
                                                        value={deviation.attachments}
                                                        onChange={attachIds => this.handleChange('attachments', attachIds)
                                                        }
                                                        group={this.getAttachmentGroup(
                                                            userType,
                                                            isAssignee,
                                                            isReporter
                                                        )}
                                                        groups={attachmentsGroups}
                                                        options={{
                                                            checkDate: true,
                                                            imageContext: {
                                                                existingAttachment: deviation.Attachments,
                                                                fileType: 'docs',
                                                                id: 0,
                                                                model: 'Deviation',
                                                                data: {
                                                                    group: this.getAttachmentGroup(
                                                                        userType,
                                                                        isAssignee,
                                                                        isReporter
                                                                    ),
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionItem>
                                <AccordionItem title={t('Caught deviation details')} uuid="other">
                                    {canEditMainInfo && (
                                        <>
                                            <div className="custom-form__row">
                                                <Textarea
                                                    className="custom-form__col"
                                                    label={t('Estimated cause of deviation')}
                                                    placeholder={t('Estimated cause of deviation')}
                                                    type="text"
                                                    value={deviation.txt_cause}
                                                    onChange={({ target: { name, value } }) => this.handleChange(name, value)
                                                    }
                                                    name="txt_cause"
                                                />
                                                <Textarea
                                                    className="custom-form__col"
                                                    label={t('Describe consequence of the deviation')}
                                                    placeholder={t('Describe consequence of the deviation')}
                                                    type="text"
                                                    value={deviation.txt_consequence}
                                                    onChange={({ target: { name, value } }) => this.handleChange(name, value)
                                                    }
                                                    name="txt_consequence"
                                                />
                                            </div>
                                            <div className="custom-form__row">
                                                <Textarea
                                                    className="custom-form__col"
                                                    label={t('Describe, what can be done')}
                                                    placeholder={t('Describe, what can be done')}
                                                    type="text"
                                                    value={deviation.txt_tbd}
                                                    onChange={({ target: { name, value } }) => this.handleChange(name, value)
                                                    }
                                                    name="txt_tbd"
                                                />
                                                <Textarea
                                                    className="custom-form__col"
                                                    label={t('Describe, how to prevent it')}
                                                    placeholder={t('Describe, how to prevent it')}
                                                    type="text"
                                                    value={deviation.txt_prevent}
                                                    onChange={({ target: { name, value } }) => this.handleChange(name, value)
                                                    }
                                                    name="txt_prevent"
                                                />
                                            </div>
                                        </>
                                    )}
                                    {!canEditMainInfo && (
                                        <>
                                            <div className="custom-form__row">
                                                <div className="custom-form__col">
                                                    <div className="read-area">
                                                        <span className="read-area__label">
                                                            {t('Estimated cause of deviation')}
                                                        </span>
                                                        <div className="read-area__content" title={deviation.txt_cause}>
                                                            {deviation.txt_cause}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="custom-form__col">
                                                    <div className="read-area">
                                                        <span className="read-area__label">
                                                            {t('Describe consequence of the deviation')}
                                                        </span>
                                                        <div
                                                            className="read-area__content"
                                                            title={deviation.txt_consequence}
                                                        >
                                                            {deviation.txt_consequence}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="custom-form__row">
                                                <div className="custom-form__col">
                                                    <div className="read-area">
                                                        <span className="read-area__label">
                                                            {t('Describe, what can be done')}
                                                        </span>
                                                        <div className="read-area__content" title={deviation.txt_tbd}>
                                                            {deviation.txt_tbd}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="custom-form__col">
                                                    <div className="read-area">
                                                        <span className="read-area__label">
                                                            {t('Describe, how to prevent it')}
                                                        </span>
                                                        <div
                                                            className="read-area__content"
                                                            title={deviation.txt_prevent}
                                                        >
                                                            {deviation.txt_prevent}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </AccordionItem>
                                <AccordionItem title={t('Deviation Control')} uuid="manage">
                                    {!deviation.id && !isUnionMode && (
                                        <div className="custom-form__row">
                                            <div className="custom-form__col font-weight-bold">
                                                <CustomCheckbox
                                                    label={t('I am doing it myself')}
                                                    onChange={() => this.handleChangeMyself()}
                                                    isChecked={!!deviation.myself}
                                                    style={{ fontWeight: 'bold' }}
                                                    className="font-weight-bold"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {!!canEditMainInfo && !isUnionMode && (
                                        <div className="custom-form__col">
                                            <DatePicker
                                                label={t('Due Date *')}
                                                selected={deviation.due_date ? new Date(deviation.due_date) : false}
                                                onChange={date => this.handleChange('due_date', date)}
                                                dateFormat= {longDateFormat(dateTimeRules)}
                                                minDate = {new Date()}
                                                error={showErrors && !deviation.due_date}
                                            />
                                        </div>
                                    )}

                                    {(assign || unassign || reassign) && (userType === 'admin' || userType === 'pm') && (
                                        <div className="custom-form__row custom-form__row_v-center">
                                            <div className="custom-form__col">
                                                <AllUsersInCompanyWidget
                                                    label={t('Responsible')}
                                                    placeholder={t('Responsible')}
                                                    value={deviation.assigned_id}
                                                    onChange={value => {
                                                        this.handleChange('assigned_id', value);
                                                    }}
                                                />
                                            </div>
                                            {assign && (
                                                <div className="custom-form__col custom-form__col_grow-0">
                                                    <Button
                                                        onClick={() => this.transitionAction('assign')}
                                                        fill
                                                        wd
                                                        round
                                                    >
                                                        {t(assign.title)}
                                                    </Button>
                                                </div>
                                            )}
                                            {unassign && (
                                                <div className="custom-form__col custom-form__col_grow-0">
                                                    <Button
                                                        onClick={() => this.transitionAction('unassign')}
                                                        fill
                                                        wd
                                                        round
                                                    >
                                                        {t(unassign.title)}
                                                    </Button>
                                                </div>
                                            )}
                                            {reassign && isAssignChanged && (
                                                <div className="custom-form__col custom-form__col_grow-0">
                                                    <Button
                                                        onClick={() => this.transitionAction('reassign')}
                                                        fill
                                                        wd
                                                        round
                                                    >
                                                        {t(reassign.title)}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {(deviation.id > 0 || deviation.myself) && (
                                        <>
                                            <div className="custom-form__row">
                                                <Textarea
                                                    className="custom-form__col"
                                                    label={t('How to correct?')}
                                                    placeholder={t('Describe, how to correct')}
                                                    type="text"
                                                    value={deviation.txt_how_to_correct}
                                                    onChange={({ target: { name, value } }) => this.handleChange(name, value)
                                                    }
                                                    name="txt_how_to_correct"
                                                />
                                                <Textarea
                                                    className="custom-form__col"
                                                    label={t('How to stop it happen again?')}
                                                    placeholder={t('Describe, how to stop it happen again')}
                                                    type="text"
                                                    value={deviation.txt_how_to_stop}
                                                    onChange={({ target: { name, value } }) => this.handleChange(name, value)
                                                    }
                                                    name="txt_how_to_stop"
                                                />
                                                <Textarea
                                                    className="custom-form__col"
                                                    label={t('How did we fix this?')}
                                                    placeholder={t('How did we fix this?')}
                                                    type="text"
                                                    value={deviation.txt_fix}
                                                    onChange={({ target: { name, value } }) => this.handleChange(name, value)
                                                    }
                                                    name="txt_fix"
                                                />
                                            </div>
                                        </>
                                    )}
                                </AccordionItem>
                                {(deviation.id > 0 || deviation.myself) && (
                                    <AccordionItem title={t('Costs and hours')} uuid="cost">
                                        <div className="custom-form__row">
                                            <Input
                                                className="custom-form__col"
                                                label={t('Enter total hours')}
                                                placeholder={t('Enter total hours')}
                                                type="number"
                                                value={deviation.spent_hours}
                                                onChange={({ target: { name, value } }) => this.handleChange(name, value)
                                                }
                                                name="spent_hours"
                                            />
                                            <Input
                                                className="custom-form__col"
                                                label={t('Enter hourly rate')}
                                                placeholder={t('Enter hourly rate')}
                                                type="number"
                                                value={deviation.spent_rate}
                                                onChange={({ target: { name, value } }) => this.handleChange(name, value)
                                                }
                                                name="spent_rate"
                                            />
                                            <Input
                                                className="custom-form__col"
                                                label={t('Other costs')}
                                                placeholder={t('Other costs')}
                                                type="number"
                                                value={deviation.spent_other}
                                                onChange={({ target: { name, value } }) => this.handleChange(name, value)
                                                }
                                                name="spent_other"
                                            />
                                            <Input
                                                className="custom-form__col"
                                                label={t('Total')}
                                                placeholder={t('Total')}
                                                type="number"
                                                value={deviation.spent_total}
                                                onChange={({ target: { name, value } }) => this.handleChange(name, value)
                                                }
                                                name="spent_total"
                                            />
                                        </div>
                                    </AccordionItem>
                                )}
                                {!!history.length && (
                                    <AccordionItem title={t('Deviation History')} uuid="history">
                                        <div className="custom-form__row">
                                            <div className="custom-form__col">
                                                <div className="horizontal-scroll_wrap">
                                                    <div className="horizontal-scroll">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>{t('User')}</th>
                                                                    <th>{t('Date')}</th>
                                                                    <th>{t('Action')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {history.map((historyItem, index) => (
                                                                    <Fragment key={index}>
                                                                        <tr className="table-project-small__row">
                                                                            <td>{index + 1}</td>
                                                                            <td>{historyItem.user.name}</td>
                                                                            <td>
                                                                                {historyItem.timestamp
                                                                                    ? moment(
                                                                                        new Date(
                                                                                            historyItem.timestamp
                                                                                        )
                                                                                    ).format('D MMMM YYYY, HH:mm')
                                                                                    : ''}
                                                                            </td>
                                                                            <td>
                                                                                {historyItem.transition.title
                                                                                    ? t(historyItem.transition.title)
                                                                                    : t(
                                                                                        historyItem.transition
                                                                                              == 'done'
                                                                                            ? 'Done'
                                                                                            : 'Create'
                                                                                    )}
                                                                            </td>
                                                                        </tr>
                                                                        {historyItem.params
                                                                            && historyItem.params.comment && (
                                                                            <tr className="table-project-small__row">
                                                                                <td
                                                                                    style={{
                                                                                        borderColor:
                                                                                                'rgba(221,221,221,0.1)',
                                                                                    }}
                                                                                />
                                                                                <td
                                                                                    colSpan={3}
                                                                                    style={{
                                                                                        borderColor:
                                                                                                'rgba(221,221,221,0.1)',
                                                                                        fontSize: 12,
                                                                                        color: '#565656',
                                                                                    }}
                                                                                >
                                                                                    {historyItem.params.comment}
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </Fragment>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionItem>
                                )}
                            </Accordion>
                        </form>
                    </div>
                </div>
                {(canEditMainInfo || isAssignee) && (
                    <div className={deviation.id > 0 ? 'main-content__footer' : 'main-content__footer-normal'}>
                        <div className="custom-form__row">
                            {!isUnionMode && this.renderTransitions()}
                            <div className="custom-form__col custom-form__col_grow-0 " style={{ marginLeft: 'auto' }}>
                                <Button onClick={() => this.onSave()} fill wd>
                                    {t(deviation.id > 0 ? 'Save' : 'Create')}
                                </Button>
                            </div>
                            {deviation.id && (userType === 'admin' || userType === 'pm' || isUnionMode) && (
                                <div className="custom-form__col custom-form__col_grow-0 pull-right">
                                    <Button onClick={() => this.getReport()} fill wd>
                                        {t('Report')}
                                    </Button>
                                </div>
                            )}
                            {deviation.id && (userType === 'admin' || isUnionMode) && (
                                <div className="custom-form__col custom-form__col_grow-0 pull-right">
                                    <Button danger onClick={() => this.onDelete()} fill wd>
                                        {t('Delete')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <Modal
                    dialogClassName="sm"
                    show={!!modalTransition.name}
                    onHide={() => this.setState({ modalTransition: { comment: '' } })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t(modalTransition.commentLabel)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="custom-form">
                        <div className="custom-form__row">
                            <Textarea
                                error={modalTransition.showError && !modalTransition.comment}
                                className="custom-form__col"
                                placeholder={t(modalTransition.commentLabel)}
                                value={modalTransition.comment}
                                onChange={({ target: { name, value } }) => this.handleChangeTransitionComment(value)}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => this.transitionAction(modalTransition.name, { comment: modalTransition.comment })
                            }
                            fill
                            wd
                        >
                            {t(modalTransition.title)}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    dialogClassName="sm"
                    show={!!this.state.reportOpen}
                    onHide={() => this.setState({ reportOpen: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Send report')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="custom-form">
                        <div className="custom-form__row">
                            <Input
                                className="custom-form__col"
                                label={t('Send to email')}
                                placeholder={t('user@somewhere.com')}
                                type="text"
                                value={this.state.reportEmail}
                                onChange={({ target: { name, value } }) => this.setState({ reportEmail: value })}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.reportAction('download')} fill wd>
                            {t('Download')}
                        </Button>
                        <Button onClick={() => this.reportAction('preview')} fill wd>
                            {t('Print preview')}
                        </Button>
                        <Button
                            onClick={() => this.reportAction('email')}
                            fill
                            wd
                            disabled={!this.state.reportEmail || this.state.reportSending}
                        >
                            {t('Send by email')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default ClientsForm;
