import React, { Component } from 'react';
import Switch from 'react-switch';

class ActiveInactiveWidget extends Component {
    render() {
        const { value, onChange } = this.props;
        return (
            <div>
                <Switch
                    checked={value === 'active'}
                    onChange={checked => onChange(checked ? 'active' : 'inactive')}
                />
            </div>
        );
    }
}

export default ActiveInactiveWidget;
