import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class BodyView extends Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
    };

    render() {
        const { schedulerData,filteredRenderData,project_id } = this.props;
        const {
            renderData, headers, config, behaviors
        } = schedulerData;
        const cellWidth = schedulerData.getContentCellWidth();

        let displayRenderData = renderData.filter(o => o.render);
        if(filteredRenderData && project_id!=''){
            displayRenderData = filteredRenderData;
        }
        const tableRows = displayRenderData.map(item => {
            const rowCells = headers.map((header, index) => {
                const key = `${item.slotId}_${header.time}`;
                let style = index === headers.length - 1 ? {} : { width: cellWidth };
                if (header.nonWorkingTime) style = { ...style, backgroundColor: config.nonWorkingTimeBodyBgColor };
                if (item.groupOnly) style = { ...style, backgroundColor: config.groupOnlySlotColor };
                if (behaviors.getNonAgendaViewBodyCellBgColorFunc) {
                    const cellBgColor = behaviors.getNonAgendaViewBodyCellBgColorFunc(
                        schedulerData,
                        item.slotId,
                        header
                    );
                    if (cellBgColor) style = { ...style, backgroundColor: cellBgColor };
                }
                if (`${item.slotId}` == '0' && !header.nonWorkingTime) {
                   // style.backgroundColor = 'rgba(150,224,255,0.4)';
                   //style.backgroundColor = 'rgba(68,125,247,0.6)';
                   // style.backgroundColor = '#447df7';
                   // style.backgroundColor = '#96e0ff';
                   style.backgroundColor = '#F2F1ED';
                }
                if(`${item.slotId}` == '0' && header.nonWorkingTime){
                   // style.backgroundColor = 'rgba(68,125,247,0.4)';
                   //style.backgroundColor = '#447df7';
                  // style.backgroundColor = '#96e0ff';
                  style.backgroundColor = '#F2F1ED';
                   
                }
                return (
                    <td key={key} style={style}>
                        <div />
                    </td>
                );
            });

            let { rowHeight } = item;
           // if (item.slotId > 0 && rowHeight == 34) rowHeight = 35 ; // what the hell...
            //if(item.slotId === 0) rowHeight = rowHeight-20;
            return (
                <tr key={item.slotId}  className={`tr-${item.slotId}`} style={{ height: rowHeight + 1 }}>
                    {rowCells}
                </tr>
            );
        });

        return <tbody>{tableRows}</tbody>;
    }
}

export default BodyView;
