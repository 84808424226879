import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import Dock from 'react-dock';

import withLocalization from '../../hoc/withLocalization';
import Button from '../../components/CustomButton/CustomButton.jsx';

@inject('dashboardStore', 'userStore', 'commonStore')
@withLocalization
@withRouter
@observer
class NotificationsDock extends Component {
    renderRow(row) {
        if (!row.data || !row.data.context) return row.message;
        if (row.data.context.source === 'chat') {
            return (
                <Link
                    to={`/admin/chat/${row.data.context.room}`}
                    onClick={() => {
                        userStore.isNotificationDockOpen = false;
                    }}
                >
                    {row.message}
                </Link>
            );
        }
        if (row.data.context.source === 'privatechat') {
            return (
                <Link
                    to={`/admin/privatechat/${row.data.context.room}`}
                    onClick={() => {
                        userStore.isNotificationDockOpen = false;
                    }}
                >
                    {row.message}
                </Link>
            );
        }
        return (
            <Link
                to={`/admin/${row.data.context.source}/${row.data.context.id}`}
                onClick={() => {
                    userStore.isNotificationDockOpen = false;
                }}
            >
                {row.message}
            </Link>
        );
    }

    render() {
        const { userStore, t } = this.props;
        if (!userStore.dockNotifications) return null;
        return (
            <Dock position="right" isVisible={userStore.isNotificationDockOpen}>
                {/* you can pass a function as a child here */}
                <div className="notificationdock--wrapper">
                    <div className="notificationdock--close" onClick={() => userStore.hideNotificationDock()}>
                        X
                    </div>
                    <div className="flex-break" />
                    <div className="notifications--list">
                        <div className="card">
                            <div className="header" style={{ display: 'flex' }}>
                                <h4 className="title">{t('Notifications')}</h4>
                                <div className="clear-button">
                                    <Button fill wd onClick={() => userStore.clearNotifications()}>
                                        {this.props.t('Clear')} <i className="fa fa-trash" />
                                    </Button>
                                </div>
                            </div>
                            <div className="content">
                                <div className="table-full-width">
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            {userStore.dockNotifications.map(row => (
                                                <tr key={row.id}>
                                                    <td>{this.renderRow(row)}</td>
                                                    <td>{moment(new Date(row.created_at)).format('DD.MM HH:mm')}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="footer">
                                    <hr />
                                    {/* <div className="stats"><i className="fa fa-history"></i> Updated 3 minutes ago</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dock>
        );
    }
}

export default NotificationsDock;
