import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';

import Button from '../../../components/CustomButton/CustomButton.jsx';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


import MonitorListFilter from './MonitorListFilter';


import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import { statusMap } from '~/utils/normalStatuses';
import { shortDateFormat } from '~/utils/dateFormat'



// Elements

import customerFeature from '~/utils/customerFeature';
import { serverTimeFormat } from '~/library/core';

@inject('userStore','commonStore', 'monitorStore')
@withRouter
@withLocalization
@observer
class MonitoringList extends Component {
    state = {
        accepting: null,
        acceptanceChanged: false,
        selected: null,
        start:'',
        end:'',
        dateRange: { start: moment(new Date()).startOf('month'), end: moment(new Date()) },
        all_members:'',
        users:'',
        type:''
    };

    componentDidMount() {
        this.setState({
            start: this.state.dateRange.start.format('YYYY-MM-DD'),
            end: this.state.dateRange.end.format('YYYY-MM-DD')
        })
    }

    handleTableButtonAction = (id, type) => {
        this.props.onTableAction(id, type);
    };

    renderTableActionButtons = (id, full) => {
        const { t } = this.props;
        const { user_type, id: userId } = this.props.userStore.currentUser;
        return (
            <div className="actions-center">
                <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                    <i className="fa fa-edit" />
                </Button>
                <OverlayTrigger
                    key="GRID"
                    placement="bottom"
                    overlay={
                        <Tooltip id="tooltip-top">
                            {t("Grid")}
                        </Tooltip>
                    }
                >
                    <Button onClick={() => this.handleTableButtonAction(id, 'gridEdit')} bsStyle="warning" simple icon>
                        <i className="fa fa-table" />
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    key="HISTORY"
                    placement="bottom"
                    overlay={
                        <Tooltip id="tooltip-top">
                            {t("History")}
                        </Tooltip>
                    }
                >
                    <Button onClick={() => this.handleTableButtonAction(id, 'history')} bsStyle="warning" simple icon>
                        <i className="fa fa-history" />
                    </Button>
                </OverlayTrigger>
            </div>
        );
    };


    onSelectionChanged(ids) {
        this.setState({ selected: ids });
    }


    handleClosesDownloadReportModal = () => {
        this.setState({
            all_members: '',
            users: '',
            type: ''
        });
    };
    

    handleDownloadFormInputChange = (key, value) => {
        this.setState({[key] : value})
    }    

    //handles generic list page change//
    handlePageChange = (page,isEdit) => {
        this.props.handlePageChange(page,isEdit);
    }

    render() {
        const { loading, filters, appliedFilters } = this.props.monitorStore;
        const { user_type } = this.props.userStore.currentUser;
        const { t } = this.props;
        const {config} = this.props.commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;

        return (
            <Fragment>
                <div className="table-list">
                    <div className="table-list__header">
                        <Button fill wd icon onClick={() => this.props.onAdd()}>
                            {t('Add new')} <i className="fa fa-plus" />
                        </Button>
                    </div>
                    <GenericList
                        columns={[
                            {
                                Header: 'Date',
                                id: 'created_at',
                                sortable: true,
                                accessor: ({ created_at }) => (
                                    <span>{created_at && shortDateFormat(created_at, dateTimeRules)}</span>
                                ),
                            },
                            {
                                Header: 'Name',
                                id: 'name',
                                sortable: true,
                                accessor: 'name',
                            },
                            {
                                Header: 'Name of Person',
                                id: 'user_id',
                                sortable: true,
                                accessor: ({ first_name, last_name }) => (
                                    <span>
                                        {first_name} {last_name}
                                    </span>
                                )
                            },
                            {
                                Header: 'Project',
                                id: 'project_name',
                                sortable: false,
                                accessor: 'project_name',
                            },
                            {
                                Header: 'Created On',
                                id: 'created_at',
                                accessor: ({ created_at }) => (
                                    <span>{created_at && shortDateFormat(created_at, dateTimeRules)}</span>
                                ),
                            },
                            {
                                Header: 'Updated On',
                                id: 'updated_at',
                                accessor: ({ updated_at }) => updated_at && shortDateFormat(updated_at, dateTimeRules),
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                sortable: false,
                                accessor: full => this.renderTableActionButtons(full.id, full),
                            },
                        ]}
                        onSelectionChanged={selection => this.onSelectionChanged(selection)}

                        lastListLoadTime={this.props.monitorStore.lastListLoadTime}
                        header={(
                            <MonitorListFilter
                                filters={filters}
                                setFilter={(name, value) => this.props.monitorStore.setFilter(name, value)}
                                onFilter={() => this.props.monitorStore.onFilter()}
                                loading={loading}
                            />
                        )}
                        forceReload={this.props.monitorStore.deleteSuccess || this.state.acceptanceChanged}
                        filters={appliedFilters}
                        requestData={params => this.props.monitorStore.loadList(params)}
                        handlePageChange = {(page,isEdit)=>this.handlePageChange(page,isEdit)}
                        page = {this.props.page}//new page props to maintain page from parent//
                        isPageAddedOrEdited = {this.props.isPageAddedOrEdited}//to load same page after edit//
                    />
                </div>
            </Fragment>
        );
    }
}
export default MonitoringList;
