import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import TableWidget from '../TableWidget.js';

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class SubscriptionRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionRules  : [{ days: '7' }],
        }
        this.loadData = this.loadData.bind(this);  
        this.inputValidation = this.inputValidation.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.extendedRules && currentEntity.client.data.extendedRules.subscriptionRules){
            const extendedRules = Object.assign({}, currentEntity.client.data.extendedRules);
            this.setState({                
                subscriptionRules: extendedRules.subscriptionRules
            })
        }
    }

    inputValidation = () =>{
        const { subRules } = this.props;
        if(subRules < 1 && subRules > 365){
            alert("Enter data in range between 1 to 365 Days.");
        }
    }

    render() {
        const { subscriptionRules } = this.state;        
        return (
            <div>
                <TableWidget
                        headers={['Reminder Before Expiry-Date(Days)']}
                        metaData = {[{field:'days',type:'number',placeholder:'0', required: true , default : '7'}]}                    
                        data={subscriptionRules}
                        onChange = {data => this.props.handleUserDataChange('subscriptionRules',data) }
                        minRemoveIndex={1}
                        maxLength={3}
                    />
                    <div>You an add three reminders here e.g notification will  be sent to user after 7-15-30 days gap between.</div>
            </div >
        );
    }
}
export default SubscriptionRules;
