import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { Modal, Button } from 'react-bootstrap';
import { filter, find } from 'lodash';

import { AllUsersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';

import ModalEditor from './ChatModalEditor';

import withLocalization from '~/hoc/withLocalization';
import LoadingSpinner from '~/elements/LoadingSpinner';
import Messenger from '~/components/Chat/Messenger';
import config from '~/library/config';
import DefaultUserIcon from '~/assets/img/faces/face-0.jpg';

@inject('chatStore', 'userStore', 'commonStore')
@withLocalization
@withRouter
@observer
export default class ChatModalEditor extends Component {
    state = {
        addingUserId: null,
    };

    onChange(property) {
        this.props.onChange(property);
    }

    addUser() {
        const user = this.state.addingUserId;
        if (!user) return;
        const newId = parseInt(user.value);
        const { members } = this.props.entity;
        if (find(members, r => r.user_id === newId)) {
            this.setState({ addingUserId: null });
            return;
        }
        members.push({
            user_id: newId,
            fullname: user.label,
        });
        this.props.onChange({ members });
        this.setState({ addingUserId: null });
    }

    removeMember(member) {
        let { members } = this.props.entity;
        members = filter(members, r => {
            return r.user_id != member.user_id;
        });
        this.props.onChange({ members });
    }

    render() {
        const { t } = this.props;
        const { name, description } = this.props.entity.room;
        const { members } = this.props.entity;
        return (
            <div className="chat">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group field field-string">
                            <label className="control-label" htmlFor="root_email">
                                {t('Chat title')}
                                <span className="required">*</span>
                            </label>
                            <input
                                className="form-control"
                                id="root_email"
                                label="title"
                                type="text"
                                value={name}
                                onChange={e => this.onChange({ name: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group field field-string">
                            <label className="control-label" htmlFor="root_email">
                                {t('Description')}
                                <span className="required" />
                            </label>
                            <input
                                className="form-control"
                                id="root_email"
                                label="description"
                                type="text"
                                value={description}
                                onChange={e => this.onChange({ description: e.target.value })}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group field field-string">
                            <label className="control-label" htmlFor="root_email">
                                {t('Add user')}
                                <span className="required">*</span>
                            </label>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '100%' }}>
                                    <AllUsersWidget
                                        value=""
                                        onChange={(id, t) => {
                                            this.setState({ addingUserId: t });
                                        }}
                                    />
                                </div>
                                <Button onClick={() => this.addUser()}>{t('Add')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <table>
                            {members.map(member => (
                                <tr key={member.user_id}>
                                    <td>{member.fullname}</td>
                                    <td>
                                        <i className="fa fa-times" onClick={() => this.removeMember(member)} />{' '}
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
