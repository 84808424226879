import { observable, action, reaction } from 'mobx';
import moment from 'moment/min/moment-with-locales';
import agent from '../agent';
import _ from 'lodash';

class propertyStore {
    @observable isActive = false;

    @observable activeWidget = null;

    @observable widgets = [];

    @observable activeRecipient = null;

    @action setActiveRecipient(recipient) {
        this.activeRecipient = recipient;
    }

    @action closeActivePanel() {
        this.activeWidget = null;
    }

    @action setActiveWidget(widget) {
        this.activeWidget = widget;
    }

    @action addWidget(widgetInfo) {
        if (widgetInfo.length) {
            this.widgets.push(...widgetInfo);
        } else {
            this.widgets.push(widgetInfo);
        }
        this.activeWidget = _.cloneDeep(widgetInfo);
    }

    @action updateWidgetRnd(id, widgetInfo) {
        let index = this.widgets.findIndex((widget) => widget.widgetId === id);
        let widgetData = {
            height: widgetInfo.height,
            width: widgetInfo.width,
            widgetX: widgetInfo.x,
            widgetY: widgetInfo.y,
        };
        this.widgets[index] = { ...this.widgets[index], ...widgetData };
        this.activeWidget = this.widgets[index];
    }

    @action updateWidgetProperty(data) {
        const activeWidget = _.cloneDeep(this.activeWidget);

        for (let key in data) {
            if (activeWidget.hasOwnProperty(key)) {
                activeWidget[key] = data[key];
            }
        }

        const index = this.widgets.findIndex((widget) => widget.widgetId === this.activeWidget.widgetId);

        this.widgets[index] = activeWidget;
    }
    @action fetchAllWidget(data) {
        return agent.Signature.fetchAllDocs(data)
            .then(
                action((res) => {
                    if (res.sign_document.widgets) {
                        this.widgets = res.sign_document.widgets;
                    }
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }
    @action deleteWidget(id) {
        this.widgets = this.widgets.filter((widget) => widget.widgetId !== id);
    }

    @action updateWidgetData(wid, field, data) {
        const index = this.widgets.findIndex((widget) => widget.widgetId === wid.widgetId);
        this.widgets[index] = { ...this.widgets[index], [field]: data };
    }

    @action deleteWidgetByRecipientId(id) {

        this.widgets = this.widgets.filter((widget) => widget.recipientId !== id);
    }
    @action deleteWidgetByPageId(id) {
        this.widgets = this.widgets.filter((widget) => widget.pageId !== id);
    }

    @action saveWidget(data) {
        return agent.Signature.addWidgets(data)
            .then(
                action((widget) => {
                    // this.currentEntity = user.shift;
                    return widget;
                })
            )
            .catch(
                action((err) => {
                    // this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action resetProperties() {
        this.activeWidget = null;
        this.widgets = [];
        this.activeRecipient = null;
    }
}

export default new propertyStore();
