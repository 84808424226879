import React, { Component } from 'react';

export class FlexCard extends Component {
    render() {
        const {
            title, desc, children, footer, icon
        } = this.props;
        return (
            <div className="flex-card">
                <div className="flex-card__header-wrap">
                    <div className="flex-card__header">
                        {!!title && <h5 className="flex-card__title">{title}</h5>}
                        {!!desc && <span className="flex-card__desc">{desc}</span>}
                    </div>
                    {!!icon && <div className="flex-card__header-icon">{icon}</div>}
                </div>
                {!!children && <div className="flex-card__content">{children}</div>}
                {!!footer && <div className="flex-card__footer">{footer}</div>}
            </div>
        );
    }
}

export default FlexCard;
