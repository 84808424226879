import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import './Compose.css';
import getBase64 from '~/utils/getBase64';
import config from '~/library/config';

import withLocalization from '../../../hoc/withLocalization';
import sendIcon from '../../../assets/img/sendIcon.svg';
import paperClipIcon from '../../../assets/img/paperClip.svg';

const imageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp', 'image/gif', 'image/x-icon'];
@inject('userStore','commonStore')
@withLocalization
@observer
export default class Compose extends Component {
    state = {
        width: 0,
        text: '',
        image_id:0
    };

    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
    }

    componentDidMount() {
        this.setState({ width: this.wrapper.current.parentElement.offsetWidth }); // 360
    }

    send() {
        const data={image_id:this.state.image_id};
        this.props.sendMessage(this.state.text,data);
        this.setState({ text: '',image_id:0 });
    }

    handleKeyDown = e => {
        if (e.key === 'Enter') {
            this.send();
        }
    };

    getUrl = img => {        
        return `${config.UPLOADS_API_ENDPOINT}/${img}`;
    };

    onUpload = (e) => {        
        const {userStore,commonStore,t } = this.props;        
        const file = e.target.files[0];     

        if(file){
            if(!imageTypes.includes(file.type)){                  
                commonStore.addNotification(t('Image type alert'), null, 'error');
                return;
            }                
            const { name } = file;            
            getBase64(file)
                .then(imgData => {
                    userStore
                        .fileUpload({
                            to_model: 'User',
                            to_id: 0,
                            user_id: userStore.currentUser.id,
                            filename: name,
                            filetype: 'image',
                            content: imgData,
                        })
                        .then(newId => {
                            this.setState({image_id:newId})
                        });
                    this.fileInput.value = '';
                })
                .catch(error => {
                    console.log(error);
                });
        }else{
            this.setState({image_id:0})
        }
    };    

    render() {
        const { text, width, image_id } = this.state;
        const {  userStore } = this.props;
        const { user_type, id: userId } = userStore.currentUser;
        
        const { config } = this.props.commonStore
        const messageSet = config.client && config.client.data && config.client.data.basicRules && config.client.data.basicRules.messageSetting ? config.client.data.basicRules.messageSetting : 'read_write';

      
        const isAdminPM = user_type === "admin" || user_type === "pm";
        const isMemberAllow = user_type === "member" && messageSet === "read_write";
       
        return (
            <div ref={this.wrapper}>
            {(isAdminPM || isMemberAllow) &&
                <div className="compose" style={{ width }}>
                    <input
                        type="text"
                        className="compose-input"
                        placeholder={this.props.t('Type a message, @name')}
                        value={text}
                        onKeyDown={e => this.handleKeyDown(e)}
                        onChange={e => this.setState({ text: e.target.value })}
                    />
                    <button 
                    className="compose-button" 
                    type="button" 
                    onClick={() => this.fileInput.click()}
                    title={this.props.t('Add Image')}>
                        {image_id == 0 ?
                            <img src={paperClipIcon}
                            alt={'paperclipIcon'}/>
                        :   <img
                                src={this.getUrl(image_id)}
                                style={{maxWidth:'40px',maxHeight:'40px'}}
                                alt="Image"
                            />                            
                        }
                    </button>
                    {image_id !==0 &&
                        <button 
                            className="compose-button" 
                            type="button"
                            onClick={()=>this.setState({image_id:0})}
                            title={this.props.t('Remove') + " " + this.props.t('Image')}
                        >
                            <i className="fa fa-times"                             
                                style={{color:'#4b5155'}}>                                    
                            </i>
                        </button>
                    }
                    <input        
                        style={{ display: 'none' }}                
                        type="file"
                        accept="image/*"  
                        ref={fileInput => {
                            this.fileInput = fileInput;
                        }}  
                        onChange={this.onUpload}
                        multiple={false}
                    />
                    <button className="compose-button" onClick={() => this.send()}
                        title={this.props.t('Send')}
                    >
                        <img src={sendIcon}/>
                    </button>
                    {/* {
                        this.props.rightItems
                    } */}
                </div>
            }
            </div>
        );
    }
}
