import React, { Component } from 'react';
import GenericList from '~/components/GenericList/GenericList';
import TipListFilter from './TipListFilter';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import withLocalization from '../../../hoc/withLocalization';
import { Modal } from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import moment from 'moment';
import { Input } from '../../../elements/Input';
import withRemoveDialog from '~/hoc/withRemoveDialog';



@inject('authStore', 'timelogStore', 'commonStore', 'userStore')
@withRouter
@withRemoveDialog
@withLocalization
@observer
export class TipList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tip_amount: 0,
            tipAmount: 0,
            isTipEdit: false,
            edit_id: 0,
            acceptanceChanged: false,
            isTipEdit: false
        }
    }

    cols = [
        {
            Header: 'Created By',
            id: 'created_by',
            accessor: ({ created_by }) => (
                created_by
            ),
        },
        {
            Header: 'Tip Amount',
            id: 'tip_amount',
            accessor: ({ tip_amount }) => (
                tip_amount
            ),
        },
        {
            Header: 'Entery Date',
            id: 'tip_date',
            accessor: ({ tip_date }) => (
                moment(new Date(tip_date)).format("DD/MM/YYYY")
            ),
        },
        {
            Header: 'Actions',
            id: 'actions',
            sortable: false,
            minWidth: 150,
            accessor: (full) => this.renderTableActionButtons(full.id, full),
        },

    ];
    onDeleteAction(id) {
        return this.props.handleDelete(id, id => {
            return this.props.timelogStore.deleteTip(id).then(res => {
                this.props.timelogStore.resetLastListLoadTime();
                this.setState({ acceptanceChanged: true });
                
            });
        });
    }

    createNewTip(){
        const { userStore, timelogStore, commonStore, t } = this.props;
        const { user_type, id: user_id } = userStore.currentUser;
        const { id: client_id } = this.props.commonStore.config.client;
        timelogStore.addTip({amount:  this.state.tipAmount, user_id, user_type, client_id }).then(()=>{
                commonStore.addNotification(t('Saved successfully'), null, 'success');
                this.props.timelogStore.resetLastListLoadTime();
                this.setState({isTipModal: !this.state.isTipModal, tipAmount: 0})
        })

    }

    openEditModal(data) {
        this.setState({ isTipEdit: !this.state.isTipEdit, acceptanceChanged: false, tip_amount: data.tip_amount, edit_id: data.id });
    }
    saveEditData() {
        const { timelogStore } = this.props;
        timelogStore.updateTip(this.state.edit_id, { tip_amount: this.state.tip_amount }).then(() => {
            this.props.timelogStore.resetLastListLoadTime();
            this.setState({ isTipEdit: !this.state.isTipEdit, acceptanceChanged: true });
        })
    }

    renderTableActionButtons(id, data) {
        return (
            <div className="actions-center" style={{ justifyContent: "center" }}>
                <Button onClick={() => this.openEditModal(data)} className="btn-simple btn-icon" simple icon>
                    <i className="fa fa-edit" />
                </Button>
                <Button onClick={() => this.onDeleteAction(id)} className="btn-simple btn-icon" simple icon>
                    <i className="fa fa-times" />
                </Button>
            </div>

        )
    }
    render() {
        const { user_type } = this.props.userStore.currentUser;
        const { t } = this.props;
        const { config } = this.props.commonStore;
        const tipEditor = config.client.data.tipRules && config.client.data.tipRules.tipEditor;
        return (
            <>
                <div className="main-content">
                    <Modal centered show={this.state.isTipEdit} onHide={() => { this.setState({ isTipEdit: !this.state.isTipEdit }) }}>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.props.t('Enter Tip Amount')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Input
                                type="number"
                                value={this.state.tip_amount}
                                className="big-input"
                                onChange={(evt) => { this.setState({ tip_amount: evt.target.value }) }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-fill" onClick={() => { this.saveEditData() }}>{t('Save')}</button>
                        </Modal.Footer>
                    </Modal>
                    <div className="table-list__header">
                        {(user_type === "pm" && tipEditor === "pm") && (
                            <Button fill wd icon onClick={() => this.setState({isTipModal: !this.state.isTipModal})}>
                                {this.props.t('Add Tip')} <i className="fa fa-plus" />
                            </Button>
                        )}
                        <Modal centered show={this.state.isTipModal} onHide={()=>{ this.setState({ isTipModal: !this.state.isTipModal }) }}>
                            <Modal.Header closeButton>
                                <Modal.Title>{this.props.t('Enter Tip Amount')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Input
                                    type="number"
                                    value={this.state.tipAmount}
                                    className="big-input"
                                    onChange={(evt) => { this.setState({ tipAmount: evt.target.value })}}

                                />
                            </Modal.Body>
                            <Modal.Footer>
                                 <button className="btn btn-fill" onClick={()=>{ this.createNewTip()}}>Save</button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <GenericList
                        columns={this.cols}
                        forceReload={this.props.timelogStore.deleteSuccess || this.state.acceptanceChanged}
                        filters={this.props.timelogStore.appliedTipFilters}
                        lastListLoadTime={this.props.timelogStore.lastListLoadTime}
                        header={
                            <TipListFilter
                                onTipFilter={()=>this.props.timelogStore.onTipFilter()}
                            />
                        }
                        requestData={(params) => this.props.timelogStore.getTipList(params)}
                        pageSize={5}
                    />

                </div>
            </>
        )
    }
}

export default TipList