import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import StatusBox from '../../../elements/StatusBox';
import Button from '../../../components/CustomButton/CustomButton.jsx';

import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';

// Elements

@inject('biztypeStore')
@withRouter
@withLocalization
@observer
class BizTypesList extends Component {
    handleTableButtonAction = (id, type) => {
        this.props.onTableAction(id, type);
    };

    renderTableActionButtons = id => (
        <div className="actions-center" style={{justifyContent:"center"}}>            
            <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                <i className="fa fa-edit" />
            </Button>
            <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                <i className="fa fa-times" />
            </Button>            
        </div>
    );

    renderHeader = () => (
        <div className="header-meta">
            <Button fill wd icon onClick={() => this.props.onAdd()}>
                {this.props.t('Add new')} <i className="fa fa-plus" />
            </Button>
        </div>
    );

    render() {
        return (
            <GenericList
                columns={[
                    {
                        Header: 'Name',
                        id: 'name',
                        accessor: 'name',
                    },
                    {
                        Header: 'Operation',
                        id: 'operation',
                        sortable: false,
                        accessor: ({ id }) => this.renderTableActionButtons(id),
                    },
                ]}
                lastListLoadTime={this.props.biztypeStore.lastListLoadTime}
                forceReload={this.props.biztypeStore.deleteSuccess}
                header={this.renderHeader()}
                requestData={params => this.props.biztypeStore.loadList(params)}
            />
        );
    }
}
export default BizTypesList;
