import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Col, Grid, Row } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';

import './dashboard.css';
import ChartistGraph from 'react-chartist';

import Button from '../../components/CustomButton/CustomButton.jsx';
import DatePicker from '../../components/DatePicker/DatePicker';
import withLocalization from '../../hoc/withLocalization';

import { minutesToTimeInput } from '~/utils/timeCalc';
import config from '~/library/config';
import StatsCard from '~/components/Card/StatsCard.jsx';
import LoadingSpinner from '~/elements//LoadingSpinner';

@inject('dashboardStore', 'userStore', 'commonStore')
@withLocalization
@withRouter
@observer
class UnderConstruction extends Component {
    render() {
        return (
            <div className="main-content">
                <center>
                    <h3>{this.props.t('This feature is under construction')}</h3>
                    <img src="/images/under-construction.png" style={{ width: '30%' }} />
                </center>
            </div>
        );
    }
}

export default UnderConstruction;
