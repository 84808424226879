import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
//import { confirmAlert } from 'react-confirm-alert';
import MultiSelect from '@khanacademy/react-multi-select';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import {Badge} from "react-bootstrap";
// import {FormControl, FormGroup, ControlLabel} from 'react-bootstrap';

import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import LoadingSpinner from '../../../elements/LoadingSpinner';
import InputTime from '../../../components/GenericForm/Widgets/InputTime';
import { Input, Textarea } from '../../../elements/Input';
// import playIcon from '../../../assets/img/play.svg';
// import stopIcon from '../../../assets/img/stop.svg';
// import clockIcon from '../../../assets/img/clock.svg';
// import distanceIcon from '../../../assets/img/distance.svg';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import { longDateFormat, shortDateFormat } from '../../../utils/dateFormat';
import { getStatusText } from '../../../utils/scheduleUtils';
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import { TasksWidget } from '../../../components/GenericForm/Widgets/TasksWidget';
// import Select from '../../../elements/Select';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';
import "./Schedule.css";
import ShiftHistory from './ShiftHistory';
import TimePickerInput from '../../../components/TimePicker';
import v4 from 'uuid/v4';
import customerFeature from '~/utils/customerFeature';
import { timeFormat } from '~/utils/dateFormat'
@inject('userStore', 'commonStore', 'scheduleStore')
@withRouter
@withLocalization
@observer
class ShiftForm extends Component {
    state = {
        availability : '',
        data: {
            for_date : this.props.stub.for_date,
            assignee_id : this.props.stub.assignee_id
        }
    };

    componentWillMount() {
        const { scheduleStore } = this.props;
        scheduleStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    async handleRun(action) {
        const { scheduleStore, t, commonStore } = this.props;
        const entity = scheduleStore.currentEntity;
        let today = moment(new Date).format('YYYY-MM-DD')
        if (action == 'save') {   
            if (moment(entity.for_date).format('YYYY-MM-DD') < moment(today).format('YYYY-MM-DD')) {
                commonStore.addNotification(t('Can not create schedule for past date'), null, 'error');
                return;
            }
        }
        if (action != 'cancel' && action != 'delete' && (!entity.project_id || !entity.task_id)) {
            commonStore.addNotification(t('Please select project and task'), null, 'error');
            return;
        }
        await this.props.finish(action);
    }

    handleChange = (name, value) => {
        const { scheduleStore  , stub} = this.props;
        scheduleStore.changeEntity(name, value);
        const tempdata = {
            for_date : '',
            assignee_id : ''
        }
        if(name == 'for_date'){
            tempdata.for_date = value;
            this.setState((prevState) =>{
                    let data = Object.assign({},prevState.data);
                    data.for_date = tempdata.for_date;
                    return {data}
                },()=>{this.getAvailabilityData(this.state.data)})
        }
        if(name == 'assignee_id'){
            tempdata.assignee_id = value; 
            this.setState((prevState) =>{
                let data = Object.assign({},prevState.data);
                data.assignee_id = tempdata.assignee_id;
                return {data}
            },()=>{this.getAvailabilityData(this.state.data)})
        }
        // this.getAvailabilityData(this.state.data);
    };


    handleUserChange = (value, full) => {
        this.handleChange('assignee_id', value);
    };

    handleTaskChange = (value, full) => {
        this.handleChange('task_id', value);
    };

    async loadData() {
        const { scheduleStore, id, add, stub, userStore } = this.props;
        const pm_no_shifts = customerFeature('pm_no_shifts');
        // if (pm_no_shifts) stub.assignee_id = userStore.currentUser.id;
        if (add) {
            this.getAvailabilityData(stub);
            scheduleStore.returnDefaultNew(stub);
            return
        }
        await scheduleStore.load(id, false);
        const entity = scheduleStore.currentEntity
        this.getAvailabilityData(entity);
        return
    }

    
    async getAvailabilityData(stub){
        const {scheduleStore} = this.props;
        const avaData = {start_date :stub && stub.for_date, end_date :stub && stub.for_date, user_id :stub && stub.assignee_id};
        if(avaData){
            const data = await scheduleStore.getAvailability(avaData);
            if(data){
                const tempdata = {
                    for_date : data.for_date,
                    assignee_id : data.user_id
                }
                this.setState({availability : data});
                this.setState({data : tempdata});
            }
        }
    }

    getStatusText() {
        const { scheduleStore, commonStore, t, params } = this.props;
        const entity = scheduleStore.currentEntity;
        const statusText = getStatusText(entity);
        return <div>{t(statusText)}</div>;
    }

    acceptProposed(id) {
        this.handleChange('assignee_id', id);
    }

    waiting_badge_style ={
        backgroundColor: '#ffce6b',
        margin:'0 0 0 14px',
        padding:'9px 0px 0px',
        borderRadius: '50px',
        fontSize: '14px',
        width:'155px',
        height:'30px'  
    }

    render() {
        const { scheduleStore, commonStore, t, params, userStore } = this.props;
        const pm_no_shifts = customerFeature('pm_no_shifts');

        if (!scheduleStore.currentEntity || scheduleStore.loading) {
            return <LoadingSpinner />;
        }
        const entity = scheduleStore.currentEntity;
        let { resources } = scheduleStore;
        const {availability} = this.state;
        resources = _.filter(resources, (r) => !!r.id);
        if (entity.project_id) {
            resources = _.filter(resources, (r) => r.project_ids.indexOf(parseInt(entity.project_id, 10)) >= 0);
        }

        const isMember = userStore.currentUser.user_type === 'member';

        const userOptions = resources.map((r) => {
            return { value: r.id, label: r.name };
        });
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;        
        const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';  
        const { config } = commonStore 
        const allowAvailability = config.client && config.client.data && config.client.data.basicRules 
        && config.client.data.basicRules.allowAvailability ? config.client.data.basicRules.allowAvailability : false
        return (
            <Fragment>
                <Modal dialogClassName="lg" className="lg" size="lg" show onHide={() => this.handleRun('cancel')}>
                    <Modal.Header closeButton className="set_ModalHeader">
                        <Modal.Title style={{ fontFamily: 'Roboto',fontSize:'18px',fontWeight:'normal'}}>{this.props.t('Create / edit shift')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className='col-md-12 col-lg-12 mb-3 fontStyles' style={{marginBottom:'1em'}} >
                                <div className='fontStyles' style={{marginBottom:'10px',fontSize:'15px',fontFamily:'roboto'}}>
                                    {t('status')}  
                                    <Badge className="waiting_badge" style={this.waiting_badge_style} text="dark"> {this.getStatusText()}</Badge>
                                </div>
                            </div>
                            <div className={this.props.responseData.length ? "col-md-9 col-lg-9 " : "col-md-12 col-lg-12"} style={{width:''}}>
                                <div className="row">
                                   <div className="col-md-2 col-lg-2 " style={{paddingRight:'',width:'fit-content'}} >
                                        <div className="form-group field field-string ">
                                            <label className="control-label fs15">
                                                {t('From')}{' '}
                                            </label>
                                            {/* <InputTime
                                                value={minutesToTimeInput(entity.start_time, false)}
                                                onChange={(e) =>
                                                    this.handleChange('start_time', getMinutesFromTimeInput(e))
                                                }      
                                                disabled={isMember}
                                            /> */}
                                            <TimePickerInput        
                                                style={{width:150}}        
                                                value={minutesToTimeInput(entity.start_time, false)}
                                                format={format=="hh:mm"?"HH:mm":format}
                                                onChange={value => this.handleChange('start_time', getMinutesFromTimeInput(value))}
                                                className="worklog-input"
                                            /> 
                                        </div>
                                   </div>
                                   <div className="col-md-2 col-lg-2" style={{paddingLeft:'0px',width:'fit-content'}}>
                                        <div className="form-group field field-string">
                                            <label className="control-label fs15">
                                                {t('To')}{' '}
                                            </label>
                                            {/* <InputTime
                                                value={minutesToTimeInput(entity.end_time, false)}
                                                onChange={(e) =>
                                                    this.handleChange('end_time', getMinutesFromTimeInput(e))
                                                }
                                                
                                                disabled={isMember}
                                            /> */}
                                            <TimePickerInput        
                                                style={{width:150}}        
                                                value={minutesToTimeInput(entity.end_time, false)}
                                                format={format=="hh:mm"?"HH:mm":format}
                                                onChange={value => this.handleChange('end_time', getMinutesFromTimeInput(value))}
                                                className="worklog-input"
                                            />
                                        </div>
                                   </div>
                                   <div className="col-md-4 col-lg-4" style={{paddingLeft:'0px'}}>
                                        <div className="form-group field field-string">
                                            <label className="control-label fs15">{t('Select Date')}</label>
                                            <br />
                                            <DatePicker
                                                selected={new Date(entity.for_date)}
                                                onChange={(date) => this.handleChange('for_date', date)}
                                                dateFormat= {longDateFormat(dateTimeRules)}
                                                disabled={isMember}
                                                
                                            />
                                        </div>
                                   </div>
                                   <div className="col-md-4">
                                   <div className="form-group field field-string">
                                            <label className="control-label">{t('Assigned to')} *</label>
                                            {entity.assignee_id > 0 && !pm_no_shifts && (
                                                <MembersWidget
                                                    className="form-control"
                                                    onChange={(value, full) => this.handleUserChange(value, full)}
                                                    value={entity.assignee_id}
                                                    defaultIfOne
                                                    disabled={isMember}
                                                />
                                            )}

                                            {
                                                !entity.assignee_id && (
                                                    <MembersWidget
                                                    className="form-control"
                                                    onChange={(value, full) => this.handleUserChange(value, full)}
                                                    value={entity.assignee_id}
                                                    defaultIfOne
                                                    disabled={isMember}
                                                />
                                                )
                                            }
                                            {entity.assignee_id > 0 && pm_no_shifts && (
                                                <div>
                                                    {userStore.currentUser.first_name} {userStore.currentUser.last_name}
                                                </div>
                                            )}
                                            {entity.assignee_id === 0 && <div>{t('Pending')}</div>}
                                        </div>
                                   {/* <FormGroup controlId="formControlsSelect">
                                        <ControlLabel className="fs15">Assigned To</ControlLabel>
                                            <FormControl componentClass="select" placeholder="Client Member">
                                                <option value="select">Client Member</option>
                                                <option value="other">user</option>
                                            </FormControl>
                                        </FormGroup> */}
                                   </div>
                                </div>
                                {/* availability */}
                                {allowAvailability &&
                                    <div>
                                         {availability && availability!= null && 
                                    <div key={v4()} className='row'>
                                        {(entity.assignee_id == availability.user_id && ( moment(entity.for_date).format('DD-MM-YYYY') == moment(availability.for_date).format('DD-MM-YYYY') ) ) &&
                                            <div>
                                            {(availability.availability_type=='available' && (availability.from == 0 || availability.from == null) && (availability.to==0 | availability.to == null) ) &&
                                                <div>
                                                    <div className='col-md-3'>
                                                        <label className="control-label fs15 availability-status-title">{t('Availability Status')}</label>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="availablility-status green">{t('Available for Full Day.')}</label>
                                                    </div>
                                                </div>
                                            }
                                            {(availability.availability_type=='available' && availability.from>0 && availability.to>0) &&
                                                <div>
                                                    <div className='col-md-3'>
                                                        <label className="control-label fs15 availability-status-title">{t('Availability Status')}</label>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="availablility-status orange">{t("Available from")} {timeFormat(availability.from, dateTimeRules)} {t("to")} {timeFormat(availability.to, dateTimeRules)}.</label>
                                                    </div>
                                                </div>
                                            }   
                                            {(availability.availability_type=='not available' && (availability.from == 0 || availability.from == null) && (availability.to==0 | availability.to == null) ) &&
                                                <div>
                                                    <div className='col-md-3'>
                                                        <label className="control-label fs15 availability-status-title" style={{margin : '10px 0px 10px 0px'}}>{t('Availability Status')}</label>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="availablility-status red">{t("Unavailable for Full Day.")}</label>
                                                    </div>
                                                </div>
                                            }
                                            {(availability.availability_type=='not available' && availability.from>0 && availability.to>0) &&
                                                <div>
                                                    <div className='col-md-3'>
                                                        <label className="control-label fs15 availability-status-title" style={{margin : '10px 0px 10px 0px'}}>{t('Availability Status')}</label>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label className="availablility-status red">{t("Unavailable from")} {timeFormat(availability.from, dateTimeRules)} {t("to")} {timeFormat(availability.to, dateTimeRules)}.</label>
                                                    </div>
                                                </div>
                                            }
                                                </div>
                                        }
                                    </div>
                                }
                                    </div>
                                }

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group field field-string">
                                            <label className="fs15">
                                                <input type="checkbox" style={{marginRight:'5px'}}  checked={!!(_.get(scheduleStore, 'currentEntity.data.addBonus'))} onChange={()=>this.handleChange('data.addBonus', !(_.get(scheduleStore, 'currentEntity.data.addBonus')))} disabled={isMember} />
                                                {' ' + t('Add bonus')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group field field-string">
                                            <label className="control-label fs15">{t('Project')}</label>
                                            <br />
                                            <ProjectsWidget
                                                value={entity.project_id || null}
                                                onChange={(e, f) => {
                                                    this.handleChange('project_id', e);
                                                }}
                                                applyOnFirstLoad={entity.assignee_id > 0}
                                                disabled={isMember}
                                                filterByUser={entity.assignee_id}
                                                style={{ border: '1px solid' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group field field-string">
                                            <label className="control-label fs15">{t('Task')}</label>
                                            <br />
                                            {entity.assignee_id > 0 && (
                                                <TasksWidget
                                                    className="form-control"
                                                    onChange={(value, full) => this.handleTaskChange(value, full)}
                                                    value={entity.task_id}
                                                    defaultIfOne
                                                    defaultByGeneric
                                                    filterByUser={entity.assignee_id}
                                                    filterByProject={entity.project_id}
                                                    shouldFilterByUser
                                                    shouldFilterByProject
                                                    disabled={isMember}
                                                />
                                            )}
                                              {
                                                !entity.assignee_id && (
                                                    <TasksWidget
                                                    className="form-control"
                                                    onChange={(value, full) => this.handleTaskChange(value, full)}
                                                    value={entity.task_id}
                                                    defaultIfOne
                                                    defaultByGeneric
                                                    filterByUser={entity.assignee_id}
                                                    filterByProject={entity.project_id}
                                                    shouldFilterByUser
                                                    shouldFilterByProject
                                                    disabled={isMember}
                                                />
                                                )
                                            }
                                            {entity.assignee_id == 0 && (
                                                <TasksWidget
                                                    className="form-control"
                                                    onChange={(value, full) => this.handleTaskChange(value, full)}
                                                    value={entity.task_id}
                                                    defaultIfOne
                                                    defaultByGeneric
                                                    filterByProject={entity.project_id}
                                                    shouldFilterByProject
                                                    disabled={isMember}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                               
                                
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="form-group field field-string">
                                            {isMember && entity.assignee_id > 0 && (
                                                <div>
                                                    <label className="control-label">{t('Propose people')}</label>
                                                    <MultiSelect
                                                        options={userOptions}
                                                        selected={entity.data.proposed || []}
                                                        onSelectedChanged={(id) => {
                                                            this.handleChange('data.proposed', id);
                                                        }}
                                                        overrideStrings={{
                                                            selectSomeItems: t('Select people...'),
                                                            allItemsAreSelected: t('Everyone is proposed'),
                                                            selectAll: t('Select All'),
                                                            search: t('Search'),
                                                        }}
                                                    />
                                                </div>
                                            
                                            )}


                                            {entity.assignee_id == 0 && !isMember && (
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group field field-string">
                                                            <label className="control-label">{t('Invite people')}</label>
                                                            <MultiSelect
                                                                options={userOptions}
                                                                selected={entity.data.new_invites || []}
                                                                onSelectedChanged={(id) => {
                                                                    this.handleChange('data.new_invites', id);
                                                                }}
                                                                overrideStrings={{
                                                                    selectSomeItems: t('Select people...'),
                                                                    allItemsAreSelected: t('Everyone is invited'),
                                                                    selectAll: t('Select All'),
                                                                    search: t('Search'),
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}


                                            <label className="control-label fs15">{t('Comment')}</label>
                                            <Textarea
                                                label=""
                                                value={entity.comment || ''}
                                                style={{ width: '100%' ,height:'50%'}}
                                                onChange={(e) => this.handleChange('comment', e.target.value)}
                                                placeholder={t('Enter comments here')}
                                            />
                                        </div>
                                    </div>
                                </div>
{/*===========================Commented History Component because we Adding multiple shift ================= */}                                
                                {/* <div className='row mb-50'>
                                    <div className='col-md-12 col-lg-12'>
                                        <ShiftHistory
                                            entity={entity}
                                            resources={resources}
                                            t={t}
                                            onAcceptProposed={(id) => this.acceptProposed(id)}
                                        />
                                    </div>
                                </div> */}
{/*=============================Comment Ends Here ========================================================== */}                                
                            </div>
{/*=========================== Code For Adding Multiple Shift at a time (Starts)============================ */}
{/*=========================== For Showing Shift detail which PM/Admin are adding ========================== */}                            
                            {this.props.responseData && this.props.responseData.length &&
                                <div className="col-md-3 col-lg-3" style={{ height: '400px', overflow: 'auto' }}>
                                    <label className="control-label fs15">
                                        {this.props.t('shifts')}
                                    </label>
                                    {this.props.responseData && this.props.responseData.map(ele => (
                                        <div className="mb-2" style={{ border: '1px solid gray', borderRadius: '14px', marginBottom: '5px' }}>
                                            <div style={{ marginLeft: '10px' }}>
                                                <strong style={{ color: 'blue' }}>
                                                    {ele.assignedMemberName}
                                                </strong>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                <div>
                                                    {shortDateFormat(ele.for_date, dateTimeRules)}
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'end' }}>
                                                    {timeFormat(ele.start_time, dateTimeRules)} - {timeFormat(ele.end_time, dateTimeRules)}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            } 
{/*=========================== Code For Adding Multiple Shift at a time (ends)===================   ========= */}
                            {/* <div className='col-md-3 col-lg-3'>
                                <div>
                                    <div>Available Employees</div>
                                    <hr/>
                                    <ul>
                                        <li style={{listStyleType:'none'}}><div className='circle'>CM</div><span>Client Main</span></li>
                                        <li style={{listStyleType:'none',marginTop:'10px'}}><div className='circle'>U</div><span>User</span></li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="row">
                            <div className='col-md-12 col-lg-12'>
                                <div style={{ textAlign: 'center', display: 'flex' }}> */}
                                {/* {!isMember && entity.assignee_id != 0 && (
                                    <Checkbox
                                        isChecked={entity.do_notify}
                                        onChange={(checked) => this.handleChange('do_notify', checked)}
                                        label={this.props.t('Notify user')}
                                        style={{ marginRight: 30 }}
                                    />
                                )} */}
                                {/* {isMember && entity.assignee_id == 0 && (
                                    <Button
                                        fill
                                        wd
                                        icon
                                        type="submit"
                                        style={{ marginLeft: 30, marginRight: 30 }}
                                        onClick={() => this.handleRun('member_accept')}
                                    >
                                        {' '}
                                        <i className="fa fa-times" /> {t('Get this shift')}
                                    </Button>
                                )}
                                {isMember && entity.assignee_id > 0 && (
                                    <Button
                                        fill
                                        wd
                                        icon
                                        type="submit"
                                        style={{ marginLeft: 30, marginRight: 30 }}
                                        onClick={() => this.handleRun('member_reject')}
                                    >
                                        {' '}
                                        <i className="fa fa-times" /> {t('Reject this shift')}
                                    </Button>
                                )} */}
                                {/* {!isMember && (
                                    <Button
                                        fill
                                        wd
                                        icon
                                        type="submit"
                                        style={{ marginLeft: 30, marginRight: 30 }}
                                        onClick={() => this.handleRun('save')}
                                    >
                                        {' '}
                                        <i className="fa fa-save" /> {t('Save')}
                                    </Button>
                                )}

                                <Button className="btn-primary outline" wd icon type="submit" onClick={() => this.handleRun('cancel')}>
                                    {' '}
                                    <i className="fa fa-times" /> {t('Cancel')}
                                </Button>

                                {entity.id && !isMember && (
                                    <Button
                                        round
                                        wd
                                        icon
                                        type="submit"
                                        style={{ color: 'red', borderColor: 'red', marginLeft: 100 }}
                                        onClick={() => this.handleRun('delete')}
                                    >
                                        {' '}
                                        <i className="fa fa-times" /> {t('Delete')}
                                    </Button>
                                )}
                                <div className="form-group field field-string notify">
                                    <label>
                                        <input type="checkbox" style={{marginRight:'3px',height: '15px',width: '15px',verticalAlign:' bottom'}} />
                                            Notify Employee
                                        </label>
                                    </div> */}
                                {/* </div>
                            </div>
                        </div> */}
                    </Modal.Body>
                <Modal.Footer>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div>
                {!isMember && (
                                    <Button
                                        fill
                                        wd
                                        icon
                                        type="submit"
                                        style={{ marginLeft: 30, marginRight: 30,marginTop:10 }}
                                        onClick={() => this.handleRun('save')}
                                    >
                                        {' '}
                                         {t('Save')}
                                    </Button>
                                )}

                                {isMember && entity.assignee_id == 0 && (
                                    <Button
                                        fill
                                        wd
                                        icon
                                        type="submit"
                                        style={{ marginLeft: 30, marginRight: 30 }}
                                        onClick={() => this.handleRun('member_accept')}
                                    >
                                        {' '}
                                        <i className="fa fa-times" /> {t('Get this shift')}
                                    </Button>
                                )}
                                {isMember && entity.assignee_id > 0 && (
                                    <Button
                                        fill
                                        wd
                                        icon
                                        type="submit"
                                        style={{ marginLeft: 30, marginRight: 30 }}
                                        onClick={() => this.handleRun('member_reject')}
                                    >
                                        {' '}
                                        <i className="fa fa-times" /> {t('Reject this shift')}
                                    </Button>
                                )}

                                <Button className="btn-primary outline" wd icon style={{marginTop:'10px'}} type="submit" onClick={() => this.handleRun('cancel')}>
                                    {' '}
                                     {t('Cancel')}
                                </Button>

                                {entity.id && !isMember && (
                                    <Button
                                        //round
                                        wd
                                        icon
                                        type="submit"
                                        style={{ color: 'red', borderColor: 'red', marginLeft: 100,marginTop:10 }}
                                        onClick={() => this.handleRun('delete')}
                                    >
                                        {' '}
                                        {/* <i className="fa fa-times" /> {t('Delete')} */}
                                        {t('Delete')}
                                    </Button>
                                )}
                                 </div>
                                <div className="form-group field field-string notify" style={{paddingTop:'5px'}}>
                                    {/* <label>
                                        <input type="checkbox" style={{marginRight:'10px',height: '16px',width: '15px',verticalAlign:' bottom'}} />
                                            Notify Employee
                                        </label> */}
                                        {!isMember && entity.assignee_id != 0 && (
                                    <Checkbox
                                        isChecked={entity.do_notify}
                                        onChange={(checked) => this.handleChange('do_notify', checked)}
                                        label={this.props.t('Notify user')}
                                        style={{ marginRight: 30 }}
                                        
                                    />
                                )}
                                    </div>
                         </div>        
                </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default ShiftForm;

