import React, { Component } from 'react';
import { Table } from 'react-bootstrap'
import Button from '../../../components/CustomButton/CustomButton.jsx'
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';

@inject('commonStore', 'clientStore', 'commonStore')
@withLocalization
@withRemoveDialog
@observer
class TableWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: props.headers,
            data: props.data,
            metaData: props.metaData,
            newRow: {},
            editableId: -1
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDataChanges = this.handleDataChanges.bind(this);
        this.handleTableActionButtonAction = this.handleTableActionButtonAction.bind(this);
        this.addRowToData = this.addRowToData.bind(this);
        this.editRowInData = this.editRowInData.bind(this);
        this.setNewRowFields = this.setNewRowFields.bind(this);
        this.checkForRequiredFields = this.checkForRequiredFields.bind(this);
        this.updateRowInData = this.updateRowInData.bind(this);
        this.removeObjectFormData = this.removeObjectFormData.bind(this);
        this.validateDataWithServer = this.validateDataWithServer.bind(this);
    }

    componentDidMount() {
        this.setNewRowFields();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data != prevState.data) {
            return { data: nextProps.data };
        }
        else return null;
    }

    handleTableActionButtonAction = (type, index) => {
        if (type === 'add') {
            this.addRowToData();
        }
        else if (type === 'edit') {
            this.editRowInData(index);
        }
        else if (type === 'update') {
            this.updateRowInData(index);
        }
        else {
            this.removeObjectFormData(index);
        }
    }

    setNewRowFields = () => {
        const { newRow, metaData } = this.state;
        for (const i in metaData) {
            newRow[metaData[i].field] = metaData[i].type === 'text' ? '' : 0;
        }
        this.setState({ newRow });
    }

    checkForRequiredFields(row) {
        const { metaData } = this.state;
        const { commonStore, t } = this.props;
        let total_share = 0;
        for (const i in metaData) {
            if (!row[metaData[i].field] && metaData[i].required) {
                commonStore.addNotification(t('Please fill all required fields'), null, 'error');
                // console.log('Please fill field ', metaData[i].field)
                return false;
            } else if (this.props.page === "tip" && metaData[i].field === "percentage" && row[metaData[i].field] > 100) {
                commonStore.addNotification(t('Share percentage(%) must be less then 100'), null, 'error');
                return false
            }
            if (this.props.page === "tip" && metaData[i].field === "percentage") {
                total_share = total_share + row[metaData[i].field];
            }

        }

        if (this.props.page === "tip") {
            this.state.data.forEach((data, index) => {
                if (index !== this.state.editableId) {
                    total_share = total_share + data.percentage
                }
            });
            if (total_share > 100) {
                commonStore.addNotification(t('Total share percentage(%) of all employee type must be less then 100'), null, 'error');
                return false
            }
        }
        return true;
    }

    addRowToData = () => {
        const { newRow, metaData, data } = this.state;
        if (!this.checkForRequiredFields(newRow))
            return;
        let temp = {};
        for (const i in metaData) {
            temp[metaData[i].field] = newRow[metaData[i].field]
        }
        data.push(temp);
        this.setState({ data }, () => {
            this.props.onChange(this.state.data)
            this.setNewRowFields();
        });
        return;
    }

    editRowInData = (index) => {
        this.setState({ editableId: index })
    }

    updateRowInData = (index) => {
        const { data } = this.state
        if (!this.checkForRequiredFields(data[index]))
            return;
        this.setState({ editableId: -1 })
        return;
    }

    removeObjectFormData = (index) => {
        const { data } = this.state;
        const { handleDelete, t, commonStore, onChange , page } = this.props;
        return handleDelete(index, async () => {
            if (page == 'tip') {
                const isValide = await this.validateDataWithServer(data);
                if (!isValide) {
                    return false
                }
            }
            data.splice(index, 1);
            commonStore.addNotification(t('Deleted'), null, 'error');
            this.setState({ data }, onChange(data));
        });
    }


    validateDataWithServer = async (value) => {
        const { clientStore} = this.props;
        const currentClient = _.cloneDeep(clientStore.currentEntity.client);
        const tipRules = currentClient.data && currentClient.data.tipRules ? currentClient.data.tipRules : {};
        currentClient.data.tipRules = { ...tipRules, profiles: value }
        return clientStore.save(currentClient, false).then((result) => {
           return true;
        }).catch(()=>{
            return false;
        }); 
    }

    handleInputChange = (name, evt) => {
        const { newRow } = this.state;
        newRow[name] = evt.target.type === "number" ? parseInt(evt.target.value, 10) : evt.target.value;
        this.setState({ newRow });
    }

    handleDataChanges(name, evt, index) {
        const { data } = this.state;
        let temp = data[index];
        temp[name] = evt.target.type === "number" ? parseInt(evt.target.value, 10) : evt.target.value;
        this.setState({ data }, this.props.onChange(this.state.data));
    }

    render() {
        const { headers, data, metaData, editableId, newRow } = this.state;
        const { t, minRemoveIndex , maxLength } = this.props;
        const checkIndexToDelete = minRemoveIndex && minRemoveIndex > 0 ? true : false;
        const isMaxLengthReached = maxLength && maxLength > 0 && (data.length >= maxLength) ? true : false;
        return (
            <Table striped hover responsive>
                <thead style={{ backgroundColor: '#e1f1f7' }}>
                    <tr>
                        {headers.map((item, index) => (
                            <th key={index}><b>{t(item)}</b></th>
                        ))}
                        <th><b>{t('Actions')}</b></th>
                    </tr>
                </thead>
                <tbody>
                {isMaxLengthReached ? 
                        <></> :
                        <tr>
                            {metaData.map((item, index) => (
                                <td key={index}>
                                    <input type={item.type} name={item.field} placeholder={t(item.placeholder)}
                                        onChange={evt => this.handleInputChange(item.field, evt)}
                                        value={newRow[item.field] ? newRow[item.field] : item.type === 'number' ? '' : ''} />
                                    {item.required && <span className="text-danger"> <b>{t('*')}</b></span>}
                                </td>
                            ))}
                            <td>
                                <Button onClick={() => this.handleTableActionButtonAction('add')}
                                    bsStyle="primary" simple icon fill>
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                </Button>
                            </td>
                    </tr>}

                    {data.map((item, index) => (
                        <tr key={index}>
                            {metaData.map(label => (
                                <td key={label.field}>
                                    {index === editableId ?
                                        <>
                                            <input type={label.type} name={label.field} placeholder={t(label.placeholder)}
                                                onChange={evt => this.handleDataChanges(label.field, evt, index)}
                                                value={item[label.field] ? item[label.field] : ''} />
                                            {label.required && <span className="text-danger"> <b>{t('*')}</b></span>}
                                        </>
                                        :
                                        item[label.field]}
                                </td>
                            ))}
                            <td>
                                {index === editableId ?
                                    <Button onClick={() => this.handleTableActionButtonAction('update', index)}
                                        bsStyle="primary" simple icon>
                                        <i className="fa fa-save" aria-hidden="true"></i>
                                    </Button>
                                    :
                                    <Button onClick={() => this.handleTableActionButtonAction('edit', index)}
                                        bsStyle="primary" simple icon>
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </Button>
                                }
                                {checkIndexToDelete && (minRemoveIndex > index) ?
                                    <></>
                                    :
                                    <Button onClick={() => this.handleTableActionButtonAction('delete', index)}
                                        bsStyle="danger" simple icon>
                                        <i className="fa fa-trash-alt" aria-hidden="true"></i>
                                    </Button>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }
}

export default TableWidget;