import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import ReactQuill from 'react-quill';
import './style.css';
import { Button, Modal } from 'react-bootstrap';
import config from '~/library/config';
import LoadingSpinner from '../../../elements/LoadingSpinner';
import SignLoader from '../../../assets/img/SignLoader.gif';
import LoadingBarIcon from '../../../assets/img/LoadingBarIcon.gif';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/ck-build/ckeditor';
import agent from '~/library/agent';


@inject('authStore', 'contractStore', 'commonStore', 'signatureStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
export class EditContracts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorContent: '',
            keys: [],
            contractsData: '',
            theme: 'snow',
            modules: {
                toolbar: false
            },
            replaceModal: false,
            activInput: 0,
            initContent: '',
            initKeys: '',
            archive_id: '',
            isLoading: false,
            isDownload: false,
            archive_record_content: ""
        }
    }

    handleTextChange = () => { }

    handleKeyChange = (evt, index) => {
        const keys = [...this.state.keys];
        if (evt.target.value) {
            keys[index] = evt.target.value;
            this.setState({ keys })
        }
    }

    componentWillUnmount() {
        const { contractStore } = this.props;
        contractStore.resetArchiveRecordContent();
    }

    saveKeyedValue() {
        let editorContent = this.state.initContent;
        let t = 0;
        // match(/[{{]+[\s&nbsp;a-zA-Z0-9_$\s&nbsp;]+[}}]+/g);
        editorContent = editorContent.replace(/\[[^\]]*]/g, () => {
            t++;
            return this.state.keys[t - 1];
        });
        this.setState({ editorContent, replaceModal: false });
    }

    loadData() {
        const { contractStore, location } = this.props;
        const { archive_record_content } = contractStore;
        if (!(location.state && location.state.selected && location.state.selected.id)) {
            return;
        }
        contractStore.getContractsById(location.state.selected.id).then((res) => {
            const content = res.contract_document_record.body;
            const keys = res.contract_document_record.keys;
            this.setState({ editorContent: archive_record_content || content, initContent: content, keys, initKeys: keys, contractsData: res });
        });
    }


    navigateBack() {
        const { history, location } = this.props;
        // history.push('/admin/employeecontract');
        history.push({
            pathname: '/admin/employeecontract',
            state: { selected: location.state.selected, recId: location.state.recId }
        })
    }
    fillingKeys() {
        {
            this.setState({ replaceModal: true })
        }
    }
    readyForSign() {
        const { contractStore, signatureStore, history, location, commonStore, t } = this.props;
        const { contractsData, archive_id } = this.state;

        const contract_id = contractsData.contract_document_record.contract_id;
        const data = {
            contract_id,
            editorBody: this.state.editorContent,
            current_keys: this.state.keys,
            previous_keys: this.state.initKeys,
            id: archive_id,
            redirect: true,
            recipient: location.state.recId
        }
        this.setState({ isLoading: true });
        contractStore.saveContractArchives(data).then((res) => {
            if (res.sign_document && res.sign_document.id) {
                signatureStore.setCaseId(res.sign_document.id);
                history.push('/admin/signupload');
            }


        }).catch((err) => {
            commonStore.addNotification(t('something went wrong while redirecting'), null, 'error');
            this.setState({ isLoading: false });
        });
    }


    saveAndDownload() {
        const { contractStore, commonStore, t } = this.props;
        const { contractsData, archive_id } = this.state;
        const contract_id = contractsData.contract_document_record.contract_id;
        const data = {
            contract_id,
            editorBody: this.state.editorContent,
            current_keys: this.state.keys,
            previous_keys: this.state.initKeys,
            id: archive_id,
            redirect: false
        }
        this.setState({ isDownload: true });
        contractStore.saveContractArchives(data).then((res) => {
            this.setState({ archive_id: res.contract_archive.id, isDownload: false, editorContent: res.contract_archive.body });

            const { commonStore } = this.props;

            let hydrated = {
                authorization: commonStore.token
            }

            window.open(`${config.API_ROOT}/contract_archives/download/${res.contract_archive.id}${agent.convertToGetParams(hydrated)}`);
        }).catch((err) => {
            commonStore.addNotification(t('Something went wrong while downloading'), null, 'error');
            this.setState({ isDownload: false });
        });
    }
    render() {
        const { t, contractStore } = this.props;
        const { archive_record_content } = contractStore;
        if (this.state.isLoading) {
            return (
                // <div className='docSpinner'>
                //     <LoadingSpinner/>
                //     <div style={{display: 'flex' ,justifyContent: 'center', fontSize: '1.5em'}}><b>{t('Your document is getting ready please wait...')}</b></div>
                // </div>
                <div>
                    <Modal
                        className='LoadingModal'
                        show={this.state.isLoading}
                        style={{ overflow: 'hidden' }}
                    >
                        <Modal.Body style={{ paddingTop: '30px', padding: '20px' }}>
                            <img src={SignLoader} style={{ width: '100px', height: '100px', marginLeft: '141px' }} />
                            <h6 style={{ textAlign: 'center', fontWeight: 'bolder', fontSize: '15px' }}>{t('Please wait for minute...')}</h6>
                            <h5 style={{ textAlign: 'center', fontWeight: 'bolder', fontSize: '25px' }}>{t('We are moving to Signature panel for further process.')}</h5>
                            <img src={LoadingBarIcon} style={{ width: '150px', height: '80px', marginLeft: '110px' }} />
                        </Modal.Body>
                    </Modal>
                </div>
            );
        }
        if (this.state.isDownload) {
            return (
                <div className='docSpinner'>
                    <LoadingSpinner />
                    <div style={{ display: 'flex', justifyContent: 'center', fontSize: '1.5em' }}><b>{t('Your document is getting ready please wait...')}</b></div>
                </div>

            );
        }
        return (
            <div className='main-content'>
                <Modal className="ReplaceModal" size="md" show={this.state.replaceModal} onHide={() => this.setState({ replaceModal: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Replace Modal')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div style={{ fontWeight: '' }}>
                            <h6 style={{ display: 'inline-block' }}>{t('Note :')}</h6> <span style={{ fontWeight: 'lighter !important', color: 'grey' }}>{t('Please change and fill the contract')}</span>
                        </div>
                        <div className='replace-body'>
                            {<span style={{ textTransform: "capitalize" }}>{t('current key')}({`${this.state.activInput + 1} / ${this.state.keys.length}`})</span>}
                            {
                                this.state.keys.map((data, index) => {

                                    return (
                                        <div key={index}>
                                            {
                                                this.state.activInput === index &&
                                                <div>
                                                    {
                                                        <input type="text" onBlur={(evt) => this.handleKeyChange(evt, index)} autoFocus={true} defaultValue={data} />
                                                    }
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }

                            <div className='redirect-btns'>
                                {this.state.activInput !== 0 && <button className="btn" onClick={() => { this.setState({ activInput: this.state.activInput - 1 }) }}>{t('Back')}</button>}
                                {this.state.activInput !== (this.state.keys.length - 1) && <button className="btn btn-fill nxt" onClick={() => { this.setState({ activInput: this.state.activInput + 1 }) }}>{t('Next')}</button>}
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" className='btn-fill' onClick={() => this.saveKeyedValue()}>
                            {t('Save changes')}
                        </Button>
                        <Button variant="secondary" onClick={() => this.setState({ replaceModal: false })}>
                            {t('Close')}
                        </Button>

                    </Modal.Footer>
                </Modal>
                <div className='contract-header'>
                    <div className='backbtn'>
                        <span onClick={() => { this.navigateBack() }}><i className="fa fa-angle-left" aria-hidden="true"></i> {t('Go Back to Contracts')} </span>
                    </div>
                    <div className='actions'>
                        <div>
                            <button className='btn btn-fill' 
                            disabled={this.state.keys.length === 0}
                            onClick={() => { this.fillingKeys() }}
                            >
                                {t('Start Filling')}
                            </button>
                        </div>
                        <div className='download'><button disabled={this.props.isDownload} className='btn' onClick={() => { this.saveAndDownload() }}>{t('Download as PDF')}</button></div>
                    </div>
                </div>
                <div className='contract-container'>
                    <div className='head-txt'><h5>{t('Employee Contract')}</h5></div>
                    <div className='quill-container ck-contracts'>
                        <div className='ready-btn'><button disabled={this.props.isLoading} onClick={() => { this.readyForSign() }} className='btn btn-fill'>{t('Ready For Sign')}</button></div>
                        {/* <ReactQuill
                            theme={this.state.theme}
                            onChange={value => { this.handleTextChange(value) }}
                            modules={this.state.modules}
                            formats={this.state.formats}
                            readOnly={true}
                            placeholder={this.state.placeholder}
                            style={{ border: '0px', width: '210mm', minHeight: '200px', padding: '10px', color: 'black' }}
                        /> */}
                        <CKEditor
                            editor={Editor}
                            data={this.state.editorContent}

                            onReady={editor => {
                                const editableElement = editor.ui.view.editable.element;
                                const toolbarElement = editor.ui.view.toolbar.element;
                                editor.on('change:isReadOnly', (evt, propertyName, isReadOnly) => {
                                    if (isReadOnly) {
                                        editableElement.style.borderColor = 'transparent';
                                        toolbarElement.style.display = 'none';
                                    } else {
                                        toolbarElement.style.display = 'flex';
                                    }
                                });



                                this.loadData();
                                editor.enableReadOnlyMode('my-feature-id');

                            }}

                        />
                        <div className='ready-btn'><button disabled={this.props.isLoading} onClick={() => { this.readyForSign() }} className='btn btn-fill'>{t('Ready For Sign')}</button></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditContracts