import React, { Component } from 'react';
import {
    Grid, Row, Col, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';

// Components
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import PrimaryLayout from '../../layouts/PrimaryLayout';
import ListErrors from '../../components/ListErrors';

import withLocalization from '~/hoc/withLocalization';
import Button from '~/components/CustomButton/CustomButton.jsx';
import Card from '~/components/Card/Card.jsx';

@inject('authStore')
@withRouter
@withLocalization
@observer
class Login extends Component {
    componentWillUnmount() {
        const { authStore } = this.props;
        authStore.reset();
    }

    handleEmailChange = e => this.props.authStore.setEmail(e.target.value);

    handlePasswordChange = e => this.props.authStore.setPassword(e.target.value);

    handleSubmitForm = e => {
        const { authStore, history } = this.props;
        e.preventDefault();
        authStore
            .login()
            .then(() => {
                // const lastPage = localStorage.getItem('lastPage');
                const lastPage = sessionStorage.getItem('lastPage');
                if (lastPage) {
                    // localStorage.removeItem('lastPage');
                    sessionStorage.removeItem('lastPage');
                    history.replace(lastPage);
                } else {
                    history.replace('/admin');
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    constructor(props) {
        super(props);
        this.state = {
            cardHidden: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ cardHidden: false });
        }, 700);
    }

    render() {
        const { authStore, t } = this.props;
        const { values, errors, inProgress } = authStore;
        return (
            <PrimaryLayout>
                <div className="login-page">
                    <Grid>
                        <Row>
                            <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                                <form onSubmit={this.handleSubmitForm}>
                                    <Card
                                        hidden={this.state.cardHidden}
                                        textCenter
                                        title={t('Login')}
                                        content={(
                                            <div>
                                                <ListErrors errors={t(errors)} />
                                                <FormGroup>
                                                    <ControlLabel>{t('Email address')}</ControlLabel>
                                                    <FormControl
                                                        placeholder="Enter email"
                                                        value={values.email}
                                                        type="email"
                                                        onChange={this.handleEmailChange}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <ControlLabel>{t('Password')}</ControlLabel>
                                                    <FormControl
                                                        placeholder="Password"
                                                        type="password"
                                                        autoComplete="off"
                                                        value={values.password}
                                                        onChange={this.handlePasswordChange}
                                                        onKeyPress={event => {
                                                            if (event.key === 'Enter') {
                                                                this.handleSubmitForm(event);
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </div>
                                        )}
                                        legend={(
                                            <div>
                                                <Button
                                                    bsStyle="info"
                                                    fill
                                                    wd
                                                    disabled={inProgress}
                                                    onClick={this.handleSubmitForm}
                                                >
                                                    {t('Login')}
                                                </Button>
                                                <br />
                                                <br />
                                                <Link to="/forgot">{t('Forgot password')}</Link>
                                            </div>
                                        )}
                                        ftTextCenter
                                    />
                                </form>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </PrimaryLayout>
        );
    }
}

export default Login;
