/*eslint-disable*/
import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import AdminNavbarLinks from '~/components/Navbars/AdminNavbarLinks.jsx';

// image for avatar in Sidebar
import defaultAvatar from '~/assets/img/faces/face-0.jpg';
// logo for sidebar
import logo from '../../logo.png';
import logoTextWhite from '../../assets/img/logo_text_white.svg';
import { inject, observer } from 'mobx-react';
import { sortBy } from 'lodash';
import withLocalization from '~/hoc/withLocalization';
import customerFeature from '~/utils/customerFeature';
import sidebarBackground from '../../assets/img/sidebar.jpg';
import config from '../../library/config';
var ps;

@inject('userStore', 'commonStore', 'chatStore', 'clientStore')
@withLocalization
@observer
class Sidebar extends Component {
    constructor(props) {
        super(props);
        const { routes } = props;
        this.state = {
            ...this.getCollapseStates(routes),
            isUserOpen: false,
            isDocumentsOpen: false,
            isWindows: navigator.platform.indexOf('Win') > -1 ? true : false,
            width: window.innerWidth,
        };
    }

    hideMenu() {
        try {
            if (document.documentElement.classList.value === 'nav-open')
                document.documentElement.classList.toggle('nav-open');
        } catch (e) {}
    }

    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = (routes) => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.collapseId]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState,
                };
            }
            return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }
    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = (_routes) => {
        const { chatStore, clientStore } = this.props;
        const routes = sortBy(_routes, (r) => {
            return r.order || 1;
        });
        const isTipAllowed  = (clientStore.currentEntity && clientStore.currentEntity.client && clientStore.currentEntity.client.allow_tip) || false;
        return routes.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.allowed && !prop.allowed(this.props.userStore.currentUser, isTipAllowed)) return null;
            if (prop.hide) return null;
            const showBadge = !!(prop.name === 'Chat' && (chatStore.totalUnreads || chatStore.totalPrivateUnreads));
            if (prop.collapse) {
                const isGroupAllowed = prop.views.reduce((result, menuItem) => {
                    if (!menuItem.allowed) {
                        return true;
                    }
                    if (menuItem.allowed && menuItem.allowed(this.props.userStore.currentUser, isTipAllowed)) {
                        return true;
                    }
                    return result;
                }, false);
                if (!isGroupAllowed) {
                    return null;
                }
                var st = {};
                st[prop['collapseId']] = !this.state[prop.collapseId];
                return (
                    <li className={this.getCollapseInitialState(prop.views) ? 'active' : ''} key={key}>
                        <a
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState(st);
                            }}
                        >
                            <i className={prop.icon} />
                            <p>
                                {this.props.t(prop.name)}
                                <b className={this.state[prop.collapseId] ? 'caret rotate-180' : 'caret'} />
                            </p>
                        </a>
                        <Collapse in={this.state[prop.collapseId]}>
                            <ul className="nav">{this.createLinks(prop.views)}</ul>
                        </Collapse>
                    </li>
                );
            }
            if (prop.url) {
                let url = prop.url;
                if (url === 'RULES') {
                    if (
                        !this.props.commonStore.config ||
                        !this.props.commonStore.config.client ||
                        !this.props.commonStore.config.client.data ||
                        !this.props.commonStore.config.client.data.basicRules
                    ) {
                        return null;
                    }
                    const { data: clientData } = this.props.commonStore.config.client;
                    url = clientData.basicRules.rulesLink;
                }
                return (
                    <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
                        <a target="_blank" href={url} className="nav-link" onClick={() => this.hideMenu()}>
                            {prop.icon ? (
                                <span>
                                    <i className={prop.icon} />
                                    <p>{this.props.t(prop.name)}</p>
                                </span>
                            ) : (
                                <span>
                                    <span className="sidebar-mini">{prop.mini}&nbsp;</span>
                                    <span className="sidebar-normal">{this.props.t(prop.name)}</span>
                                </span>
                            )}
                        </a>
                    </li>
                );
            }
            return (
                <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
                    <NavLink
                        to={prop.layout + prop.path}
                        className="nav-link"
                        activeClassName="active"
                        onClick={() => this.hideMenu()}
                    >
                        {prop.icon ? (
                            <span>
                                <i className={prop.icon} />
                                <div>
                                    {this.props.t(prop.name)}
                                    {showBadge && <div className="chat-badge">{chatStore.totalUnreads + chatStore.totalPrivateUnreads}</div>}
                                </div>
                            </span>
                        ) : (
                            <span>
                                <span className="sidebar-mini">{prop.mini}&nbsp;</span>
                                <span className="sidebar-normal">{this.props.t(prop.name)}</span>
                            </span>
                        )}
                    </NavLink>
                </li>
            );
        });
    };
    // verifies if routeName is the one active (in browser input)
    activeRoute = (routeName) => {
        return this.props.location.pathname === routeName ? 'active' : '';
    };
    // if the windows width changes CSS has to make some changes
    // this functions tell react what width is the window
    updateDimensions() {
        this.setState({ width: window.innerWidth });
    }
    componentDidUpdate() {  
        
        const { userStore, clientStore } = this.props;
        if(userStore.currentUser && userStore.currentUser.client_id && !this.props.clientStore.currentEntity.client){
            clientStore.load(userStore.currentUser.client_id);
        } 
        if (navigator.platform.indexOf('Win') > -1) {
            setTimeout(() => {
                if (ps) ps.update();
            }, 350);
        }
    }
    componentDidMount() {
        const { userStore, clientStore } = this.props;
        if(userStore.currentUser && userStore.currentUser.client_id){
            clientStore.load(userStore.currentUser.client_id);
        } 
        this.updateDimensions();
        // add event listener for windows resize
        window.addEventListener('resize', this.updateDimensions.bind(this));
        // if you are using a Windows Machine, the scrollbars will have a Mac look
        /*if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }*/
    }
    componentWillUnmount() {
        // we need to destroy the false scrollbar when we navigate
        // to a page that doesn't have this component rendered
        /*if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }*/
    }
    render() {
        if (this.props.userStore && this.props.userStore.currentUser && this.props.userStore.currentUser) {
            const user = this.props.userStore.currentUser;
            if (user.is_now_locked) return null;
        }
        const {
            routes,
            t,
            userStore: { currentUser },
        } = this.props; // image,
        const { isUserOpen, isDocumentsOpen } = this.state;
        const color = 'black';
        const image = sidebarBackground;
        let avatar = defaultAvatar;
        if (this.props.userStore.currentUser && this.props.userStore.currentUser.image) {
            avatar = `${config.UPLOADS_API_ENDPOINT}/${this.props.userStore.currentUser.image}`;
        }
        // console.log('this.props.userStore.currentUser', this.props.userStore.currentUser);
        return (
            <div
                className="sidebar"
                data-color={color ? color : 'purple'}
                data-image={image ? image : 'none'}
                style={{ overflow: 'hidden' }}
            >
                {this.props.hasImage ? (
                    <div className="sidebar-background" style={{ backgroundImage: 'url(' + image + ')' }} />
                ) : (
                    ''
                )}
                <div className="logo">
                    <NavLink to="/" className="simple-text logo-mini" onClick={() => this.hideMenu()}>
                        <div className="logo-img">
                            <img src={logo} alt="react-logo" />
                        </div>
                    </NavLink>
                    <NavLink to="/" className="simple-text logo-normal">
                        <img src={logoTextWhite} alt="react-logo" />
                    </NavLink>
                </div>
                <div className="sidebar-wrapper" ref="sidebarWrapper">
                    <ul className="nav">
                        <li className="user sidebar-user">
                            <a
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ isUserOpen: !isUserOpen });
                                }}
                            >
                                <img src={avatar} className="photo" alt="Name" />
                                <p>
                                    {`${currentUser.first_name} ${currentUser.last_name}`}
                                    <b className={isUserOpen ? 'caret rotate-180' : 'caret'} />
                                </p>
                            </a>
                            <Collapse in={isUserOpen}>
                                <ul className="nav">
                                    <li>
                                        <NavLink
                                            to="/admin/profile"
                                            className="nav-link"
                                            onClick={() => this.hideMenu()}
                                        >
                                            <span className="sidebar-mini">U</span>
                                            <span className="sidebar-normal">{t('User profile')}</span>
                                        </NavLink>
                                    </li>
                                    {this.props.userStore.currentUser.user_type == 'admin' && (
                                        <li>
                                            <NavLink
                                                to="/admin/companyprofile"
                                                className="nav-link"
                                                onClick={() => this.hideMenu()}
                                            >
                                                <span className="sidebar-mini">C</span>
                                                <span className="sidebar-normal">{t('Company profile')}</span>
                                            </NavLink>
                                        </li>
                                    )}
                                    {/* {this.props.userStore.currentUser.user_type == 'admin' && (
                                        <li>
                                            <NavLink
                                                to="/admin/control_panel"
                                                className="nav-link"
                                                onClick={() => this.hideMenu()}
                                            >
                                                <span className="sidebar-mini">P</span>
                                                <span className="sidebar-normal">{t('Control Panel')}</span>
                                            </NavLink>
                                        </li>
                                    )} */}
                                    {this.props.userStore.currentUser.user_type == 'admin' && (
                                        <li>
                                            <NavLink
                                                to="/admin/control_panel"
                                                className="nav-link"
                                                onClick={() => this.hideMenu()}
                                            >
                                                <span className="sidebar-mini">P</span>
                                                <span className="sidebar-normal">{t('Control Panel')}</span>
                                            </NavLink>
                                        </li>
                                    )}
                                    {this.props.userStore.currentUser.user_type !== 'super-admin' && <li className="nav-link">
                                        <NavLink
                                            to="/admin/internaldocs"
                                            className="nav-link"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState((state) => ({isDocumentsOpen: !state.isDocumentsOpen}));
                                            }}
                                        >
                                            <span className="sidebar-mini">D</span>
                                            <span className="sidebar-normal"><b>{t('Documents')}</b></span>
                                        </NavLink>
                                    </li>}
                                    <Collapse in={isDocumentsOpen}>
                                        <ul className="nav">
                                            <li>
                                                <NavLink
                                                    to="/admin/files"
                                                    className="nav-link"
                                                    onClick={() => this.hideMenu()}
                                                >
                                                    <span className="sidebar-mini"></span>
                                                    <span className="sidebar-normal">{t('My Files')}</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/admin/internaldocs"
                                                    className="nav-link"
                                                    onClick={() => this.hideMenu()}
                                                >
                                                    <span className="sidebar-mini"></span>
                                                    <span className="sidebar-normal">{t('Internal Documents')}</span>
                                                </NavLink>
                                            </li>
                                            {this.props.userStore.currentUser.user_type !== 'super-admin' && !customerFeature('union_group') && <li>
                                                <NavLink
                                                    to="/admin/contracts"
                                                    className="nav-link"
                                                    onClick={() => this.hideMenu()}
                                                >
                                                    <span className="sidebar-mini"></span>
                                                    <span className="sidebar-normal">{t('Contracts')}</span>
                                                </NavLink>
                                            </li>}
                                        </ul>
                                    </Collapse>
                                </ul>
                            </Collapse>
                        </li>
                    </ul>
                    <ul className="nav">
                        {/*
              If we are on responsive, we want both links from navbar and sidebar
              to appear in sidebar, so we render here HeaderLinks
            */}
                        {this.state.width <= 992 ? <AdminNavbarLinks /> : null}
                        {/*
              here we render the links in the sidebar if the link is simple,
              we make a simple link, if not, we have to create a collapsible group,
              with the speciffic parent button and with it's children which are the links
            */}
                        {this.createLinks(routes)}
                    </ul>
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        );
    }
}

export default Sidebar;
