import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';

import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';

@inject('timelogStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class ModalMoveOrCopy extends Component {
    state = {
        notify: false,
    };

    async handleRun(action) {
        this.props.decide(action, this.state.notify);
    }

    render() {
        const {
            timelogStore, commonStore, t, params, movingData
        } = this.props;
        return (
            <Fragment>
                <Modal dialogClassName="sm" className="sm" size="sm" show onHide={() => this.handleRun('cancel')}>
                    <Modal.Body>
                        <div className="">
                            <h3>{t('With selected shift')}</h3>
                            {movingData.toUser > 0 && (
                                <Checkbox
                                    isChecked={this.state.notify}
                                    onChange={checked => this.setState({ notify: checked })}
                                    label={this.props.t('Notify user')}
                                />
                            )}

                            <div style={{ textAlign: 'center' }}>
                                <Button
                                    fill
                                    wd
                                    icon
                                    type="submit"
                                    style={{ backgroundColor: 'green', marginRight: 30 }}
                                    onClick={() => this.handleRun('move')}
                                >
                                    {' '}
                                    <i className="fa fa-check" /> {t('Move')}
                                </Button>
                                <Button
                                    fill
                                    wd
                                    icon
                                    type="submit"
                                    style={{ backgroundColor: 'red', marginRight: 30 }}
                                    onClick={() => this.handleRun('copy')}
                                >
                                    {' '}
                                    <i className="fa fa-times" /> {t('Copy')}
                                </Button>
                                <Button round wd icon type="submit" onClick={() => this.handleRun('cancel')}>
                                    {' '}
                                    <i className="fa fa-times" /> {t('Cancel')}
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default ModalMoveOrCopy;
