import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import {Badge} from "react-bootstrap";
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Input, Textarea } from '../../../elements/Input';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import { getStatusText } from '../../../utils/scheduleUtils';
import { MembersWidget, MembersWidgetMultiple, MembersWidgetMultipleSelect } from '../../../components/GenericForm/Widgets/UsersWidget';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import { TasksWidget } from '../../../components/GenericForm/Widgets/TasksWidget';
import { longDateFormat } from '../../../utils/dateFormat';
import TimePickerInput from '../../../components/TimePicker';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';
import customerFeature from '~/utils/customerFeature';
import './Schedule.css';
import ShowAvailabiliy from './ShowAvailability';
import moment from 'moment/min/moment-with-locales';


@inject('userStore', 'commonStore', 'scheduleStore')
@withRouter
@withLocalization
@observer
class BulkShiftForm extends Component {
    state = {        
        start_date: (new Date(this.props.start_date?this.props.start_date:this.props.viewModel.startDate)).toString(),
        end_date: (new Date(this.props.end_date?this.props.end_date:this.props.viewModel.endDate)).toString(),
        assignees: null,
        assigneesBonus: [],
        checkAllAssigneeBonusesInTable: false,
        showAllAssigneeBonusesInTable: false,
        project_id: null,
        task_id: null,
        start_time: 9 * 60,
        end_time: 18 * 60,
        comment: '',
        do_notify: false,
        defaultHolidays:[0,1,2,3,4,5,6],
        defaultDays: [{ day: 'Mon', checked:false }, { day: 'Tue', checked:false },
        { day: 'Wed', checked:false }, { day: 'Thu', checked:false },
        { day: 'Fri', checked:false }, { day: 'Sat', checked:false },
        { day: 'Sun', checked:false },{ day: 'Check All', checked: false }],
        isShowAvailability : false
    };

    constructor(props) {
        super(props);
        this.handleAssigneesChange = this.handleAssigneesChange.bind(this);
        this.handleAssigneeBonusChange = this.handleAssigneeBonusChange.bind(this);
        this.handleCheckAllChange = this.handleCheckAllChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
    }

    componentWillMount() {        
        const clientConfig = this.props.commonStore.config.client.data;
        let from = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.start);
        let to = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.end);
        this.setState({
            start_time: from,
            end_time: to,
        });
    }

    componentDidMount() {
        this.loadData();
    }

    endOfWeek(date){
        var lastday = date.getDate() - (date.getDay() - 1) + 6;
        return new Date(date.setDate(lastday));
    }

    handleAssigneesChange(str, id, full) {      
        const { assigneesBonus, checkAllAssigneeBonusesInTable } = this.state;
        let newAssigneesBonus = JSON.parse(JSON.stringify(assigneesBonus));
        // delete deleted values
        const ids = full.map(fullItem => fullItem.value);
        newAssigneesBonus = newAssigneesBonus.filter(assigneeBonus => ids.indexOf(assigneeBonus.value) !== -1);
        // add added values
        full.forEach((assigneeData) => {
            const foundCurrent = newAssigneesBonus.filter(assigneeBonus => assigneeBonus.value === assigneeData.value);
            if (foundCurrent && foundCurrent.length) {
                return;
            } else {
                const newAssigneeData = { ...JSON.parse(JSON.stringify(assigneeData)), addBonus: false };
                newAssigneesBonus.push(newAssigneeData);
            }
        });
        const newAssigneesBonusWithBonus = newAssigneesBonus.filter(newAssigneeBonus => newAssigneeBonus.addBonus);
        const newAssigneesBonusWithoutBonus = newAssigneesBonus.filter(newAssigneeBonus => !(newAssigneeBonus.addBonus));
        const assigneesBonusWithBonusExists = newAssigneesBonusWithBonus && newAssigneesBonusWithBonus.length;
        const assigneesBonusWithoutBonusExists = newAssigneesBonusWithoutBonus && newAssigneesBonusWithoutBonus.length;
        const assigneesBonusWithBonusDoNotExist = !assigneesBonusWithBonusExists;
        const assigneesBonusWithoutBonusDoNotExist = !assigneesBonusWithoutBonusExists;
        const newAssigneesBonusExist = newAssigneesBonus && newAssigneesBonus.length;
        const newAssigneesBonusEmpty = !newAssigneesBonusExist;
        let newCheckAllAssigneeBonusesInTable = checkAllAssigneeBonusesInTable;
        if (assigneesBonusWithBonusDoNotExist || newAssigneesBonusEmpty) {
            newCheckAllAssigneeBonusesInTable = false;
        } else if (assigneesBonusWithoutBonusDoNotExist) {
            newCheckAllAssigneeBonusesInTable = true;
        }
        this.setState({
            assignees: str,
            assigneesBonus: JSON.parse(JSON.stringify(newAssigneesBonus)),
            checkAllAssigneeBonusesInTable: newCheckAllAssigneeBonusesInTable,
        });
    }

    handleAssigneeBonusChange(value, checked) {
        const { assigneesBonus, checkAllAssigneeBonusesInTable } = this.state;
        let newAssigneesBonus = JSON.parse(JSON.stringify(assigneesBonus));
        newAssigneesBonus = newAssigneesBonus.map(newAssigneeBonus => {
            if (newAssigneeBonus.value === value) {
                return { ...JSON.parse(JSON.stringify(newAssigneeBonus)), addBonus: checked }
            } else {
                return newAssigneeBonus;
            }
        });
        const newAssigneesBonusWithBonus = newAssigneesBonus.filter(newAssigneeBonus => newAssigneeBonus.addBonus);
        const newAssigneesBonusWithoutBonus = newAssigneesBonus.filter(newAssigneeBonus => !(newAssigneeBonus.addBonus));
        const assigneesBonusWithBonusExists = newAssigneesBonusWithBonus && newAssigneesBonusWithBonus.length;
        const assigneesBonusWithoutBonusExists = newAssigneesBonusWithoutBonus && newAssigneesBonusWithoutBonus.length;
        const assigneesBonusWithBonusDoNotExist = !assigneesBonusWithBonusExists;
        const assigneesBonusWithoutBonusDoNotExist = !assigneesBonusWithoutBonusExists;
        const newAssigneesBonusExist = newAssigneesBonus && newAssigneesBonus.length;
        const newAssigneesBonusEmpty = !newAssigneesBonusExist;
        let newCheckAllAssigneeBonusesInTable = checkAllAssigneeBonusesInTable;
        if (assigneesBonusWithBonusDoNotExist || newAssigneesBonusEmpty) {
            newCheckAllAssigneeBonusesInTable = false;
        } else if (assigneesBonusWithoutBonusDoNotExist) {
            newCheckAllAssigneeBonusesInTable = true;
        }
        this.setState({
            assigneesBonus: JSON.parse(JSON.stringify(newAssigneesBonus)),
            checkAllAssigneeBonusesInTable: newCheckAllAssigneeBonusesInTable,
        });
    }

    handleCheckAllChange(checked) {
        const { assigneesBonus, checkAllAssigneeBonusesInTable } = this.state;
        let newAssigneesBonus = JSON.parse(JSON.stringify(assigneesBonus));
        newAssigneesBonus.forEach(newAssigneeBonus => {
            newAssigneeBonus.addBonus = checked
        });
        this.setState({
            checkAllAssigneeBonusesInTable: checked,
            assigneesBonus: JSON.parse(JSON.stringify(newAssigneesBonus)),
        });
    }

    async handleRun(action, values) {
        const { scheduleStore, t, commonStore } = this.props;
        // const entity = scheduleStore.currentEntity;
        const { start_date, end_date, assignees, project_id, task_id, start_time, end_time, comment } = this.state;

        if (action != 'cancel' && action != 'delete' && (!project_id || !task_id)) {
            commonStore.addNotification(t('Please select project and task'), null, 'error');
            return;
        }
        const startDateWithTime = new Date(start_date);
        const endDateWithTime = new Date(end_date);
        const startDate = new Date(Date.UTC(startDateWithTime.getUTCFullYear(), startDateWithTime.getUTCMonth(), startDateWithTime.getUTCDate() ));
        const endDate = new Date(Date.UTC(endDateWithTime.getUTCFullYear(), endDateWithTime.getUTCMonth(), endDateWithTime.getUTCDate() ));
        if (action != 'cancel' && action != 'delete' && startDate.getTime() > endDate.getTime()) {
            commonStore.addNotification(t('From date must be not bigger, than To date'), null, 'error');
            return;
        }
        this.props.finish(action, values);
    }

    handleChange = (name, value) => {
        const { scheduleStore } = this.props;
        this.setState({[name]:value});
        scheduleStore.changeEntity(name, value);
    };

    handleUserChange = (value, full) => {
        this.handleChange('assignee_id', value);
    };

    handleTaskChange = (value, full) => {
        this.handleChange('task_id', value);
    };

    async loadData() {
        const { scheduleStore, id, add, stub, userStore } = this.props;        
        if (add) {
            return scheduleStore.returnDefaultNew(stub);
        }
        return scheduleStore.load(id, false);
    }

    getStatusText() {
        const { scheduleStore, commonStore, t, params } = this.props;
        const entity = scheduleStore.currentEntity;
        const statusText = getStatusText(entity);
        return <div>{t(statusText)}</div>;
    }

    acceptProposed(id) {
        this.handleChange('assignee_id', id);
    }

    waiting_badge_style ={
        backgroundColor: '#ffce6b',
        margin:'0 0 0 14px',
        padding:'9px 0px 0px',
        borderRadius: '50px',
        fontSize: '14px',
        width:'155px',
        height:'30px'  
    }

    checkAll = (value) => {
        const { defaultDays,defaultHolidays } = this.state;
        defaultDays.map(item => {
            item.checked = value;
        })
        this.setState({ defaultDays, defaultHolidays:[]});
    }

    handleCheck = (index, evt) => {
        const { defaultDays,defaultHolidays } = this.state;
        let isAllTrue = true;
        let holidays = defaultHolidays;

        if(evt === false && defaultDays[index].day != 'Check All'){
            holidays.push(index);
        }

        if(evt === true && defaultDays[index].day != 'Check All'){
            holidays = holidays.includes(index)?holidays.filter(e=>e!=index):holidays
        }

        if (defaultDays[index].day === 'Check All') {
            this.checkAll(evt);
        } else {
            defaultDays[index].checked = evt;
            for(let i in defaultDays){
                if(defaultDays[i].day != 'Check All' && defaultDays[i].checked === false){
                    isAllTrue=false;                    
                    break;
                }                
            }
            defaultDays[7].checked = isAllTrue;
            this.setState({ defaultDays,defaultHolidays:holidays });
        }    
    }

    ShowAvailability=()=>{
        this.setState({isShowAvailability : !this.state.isShowAvailability })
    } 
    

    render() {
        const { scheduleStore, commonStore, t, params, userStore } = this.props;
        const { start_date, end_date, assignees, project_id, task_id, start_time, 
            end_time, comment, do_notify, assigneesBonus, checkAllAssigneeBonusesInTable, 
            showAllAssigneeBonusesInTable,defaultHolidays , isShowAvailability } = this.state;        
        
        let { resources } = scheduleStore;
        resources = _.filter(resources, (r) => !!r.id);        

        const isMember = userStore.currentUser.user_type === 'member';
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';  
        const { config } = commonStore 
        const allowAvailability = config.client && config.client.data && config.client.data.basicRules 
        && config.client.data.basicRules.allowAvailability ? config.client.data.basicRules.allowAvailability : false
        return (
            <Fragment>
                <Modal dialogClassName="lg bulk-shift-modal" className="lg" size="lg" show onHide={() => this.handleRun('cancel')}>
                    <Modal.Header closeButton className="set_ModalHeader" >
                    {!isShowAvailability ?
                        <Modal.Title style={{ fontFamily: 'Roboto',fontSize:'18px',fontWeight:'normal'}}>{this.props.t('Create / edit shift')}</Modal.Title>
                        :
                        <Modal.Title style={{ fontFamily: 'Roboto',fontSize:'18px',fontWeight:'normal'}}>{this.props.t('Member Availability')}</Modal.Title>
                    }
                    </Modal.Header>
                    <Modal.Body>
                    {!isShowAvailability ?
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                <div className='col-md-12 col-lg-12 mb-3 fontStyles' style={{marginBottom:'1em'}} >
                                <div className='fontStyles' style={{marginBottom:'10px',fontSize:'15px',fontFamily:'roboto'}}>
                                    {t('Status')}  
                                    <Badge className="waiting_badge" style={this.waiting_badge_style} text="dark">{t('Waiting for invites')}</Badge>
                                </div>
                            </div>                                    
                                    <div className="col-md-6">
                                        <div className="form-group field field-string">
                                            <label className="control-label">{t('From date')} *</label>
                                            <br />
                                            <DatePicker
                                                selected={new Date(start_date)}
                                                onChange={(date) => /* this.handleChange('for_date', date) */ this.setState({ start_date: date.toString() })}
                                                dateFormat= {longDateFormat(dateTimeRules)}
                                                disabled={isMember}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group field field-string">
                                            <label className="control-label">{t('To date')} *</label>
                                            <br />
                                            <DatePicker
                                                selected={new Date(end_date)}
                                                onChange={(date) => /* this.handleChange('for_date', date) */ this.setState({ end_date: date.toString() })}
                                                dateFormat= {longDateFormat(dateTimeRules)}
                                                disabled={isMember}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 row-flex" style={{ overflowX: 'auto',margin:'10px 0px' }}>
                                    {this.state.defaultDays.map((item, index) => (
                                <Checkbox
                                    inline
                                    key={`${index}`}
                                    isChecked={item.checked}
                                    onChange={evt => this.handleCheck(index, evt)}
                                    label={t(item.day)}
                                />
                            ))}
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group field field-string">
                                            <label className="control-label">{t('Assigned to')} *</label>                                            
                                            <MembersWidgetMultipleSelect
                                                className="form-control"
                                                onChange={this.handleAssigneesChange }
                                                value={assignees}
                                                defaultIfOne
                                                disabled={isMember}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                    <div className="form-group field field-string ">
                                            <label className="control-label fs15">
                                                {t('From')}{' '}
                                            </label>                                            
                                            <TimePickerInput        
                                                style={{width:150}}        
                                                value={minutesToTimeInput(start_time, false)}
                                                format={format=="hh:mm"?"HH:mm":format}
                                                onChange={value => this.handleChange('start_time', getMinutesFromTimeInput(value))}
                                                className="worklog-input"
                                                disabled={isMember}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                    <div className="form-group field field-string">
                                            <label className="control-label fs15">
                                                {t('To')}{' '}
                                            </label>                                            
                                            <TimePickerInput        
                                                style={{width:150}}        
                                                value={minutesToTimeInput(end_time, false)}
                                                format={format=="hh:mm"?"HH:mm":format}
                                                onChange={value => this.handleChange('end_time', getMinutesFromTimeInput(value))}
                                                className="worklog-input"
                                                disabled={isMember}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3" style = {{paddingTop: '25px'}}>
                                    <div className="form-group field field-string">
                                            <label className="fs15">
                                                <input type="checkbox" style={{marginRight:'5px'}}  checked={showAllAssigneeBonusesInTable} onChange={() => this.setState(state => ({ showAllAssigneeBonusesInTable: !(state.showAllAssigneeBonusesInTable) }))} />
                                                {' ' + t('Add bonus')}
                                            </label>
                                        </div>                                        
                                    </div>
                                    {showAllAssigneeBonusesInTable && <div className="col-md-12">
                                        {/* <div className="form-group field field-string"> */}
                                            <table className="table table-hover table-striped project-members-form">
                                                <tbody>
                                                    <tr>
                                                        <th>{t('Employee name')}</th>
                                                        {/* <th>{t('Add bonus')}</th> */}
                                                        <th><label>{t('Add bonus')}
                                                            <Checkbox
                                                                isChecked={checkAllAssigneeBonusesInTable}
                                                                onChange={(checked) =>  this.handleCheckAllChange(checked)}
                                                                label
                                                                style={{ marginRight: 30 }}
                                                                disabled={false}
                                                                boldLabel
                                                                inline
                                                                inlineBlock
                                                            />
                                                        </label></th>
                                                    </tr>
                                                    {assigneesBonus.map((assigneeBonus, rowIndex) => (
                                                        <tr key={`${assigneeBonus.value}`}>
                                                            <td>{assigneeBonus.label ? assigneeBonus.label : '' }</td>
                                                            <td>                                                                
                                                                <label>
                                                                    <Checkbox
                                                                        isChecked={assigneeBonus.addBonus}
                                                                        onChange={(checked) =>  this.handleAssigneeBonusChange(assigneeBonus.value, checked)}
                                                                        label={''}
                                                                        style={{ marginRight: 30 }}
                                                                        disabled={false}
                                                                    /></label>
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        {/* </div>  */}
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group field field-string">
                                            <label className="control-label">{t('Project')}</label>
                                            <br />
                                            <ProjectsWidget
                                                value={project_id}
                                                onChange={(e, f) => {
                                                    // this.handleChange('project_id', e);
                                                    this.setState({ project_id: e });
                                                }}
                                                applyOnFirstLoad={/* entity.assignee_id > 0 */ false}
                                                disabled={isMember}
                                                filterByUser={/* entity.assignee_id */ false}
                                                style={{ border: '1px solid' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group field field-string">
                                            <label className="control-label">{t('Task')}</label>
                                            <br />                                            
                                            <TasksWidget
                                                className="form-control"
                                                onChange={/* (value, full) => this.handleTaskChange(value, full) */ (value) => { this.setState({ task_id: value }) }}
                                                value={task_id}
                                                defaultIfOne
                                                defaultByGeneric
                                                filterByProject={project_id}
                                                shouldFilterByProject
                                                disabled={isMember}
                                            />
                                        </div>
                                    </div>
                                </div>                                
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group field field-string">                                            
                                            <label className="control-label">{t('Comment')}</label>
                                            <Textarea
                                                label=""
                                                value={comment || ''}
                                                style={{ width: '100%' }}
                                                onChange={(e) => /* this.handleChange('comment', e.target.value) */ this.setState({ comment: e.target.value })}
                                                placeholder={t('Enter comments here')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>     
                        </>
                    :
                        <ShowAvailabiliy
                            start_date = {moment(start_date).format('YYYY-MM-DD')}
                            end_date = {moment(end_date).format('YYYY-MM-DD')}
                            userIDs = {assignees}
                        />         
                    }                   
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div>
                                 {allowAvailability ? 
                                    !isShowAvailability ?
                                    <>
                                    {!assignees ? 
                                        <Button disabled wd icon style={{marginTop:'10px'}} type="submit" onClick={this.ShowAvailability}>
                                            {' '}
                                            {t('Show Availability')}
                                        </Button>
                                    :
                                        <Button fill wd icon style={{marginTop:'10px'}} type="submit" onClick={this.ShowAvailability}>
                                            {' '}
                                            {t('Show Availability')}
                                        </Button>
                                    }
                                    </>
                                :
                                    <Button fill wd icon style={{marginTop:'10px'}} type="submit" onClick={this.ShowAvailability}>
                                        {' '}
                                        {t('Back')}
                                    </Button>   
                                : 
                                null
                                }               
                            {(!isMember && !isShowAvailability) && (
                                <Button
                                    fill
                                    wd
                                    icon
                                    type="submit"
                                    style={{ marginLeft: 30, marginRight: 30,marginTop:10 }}
                                    onClick={() => this.handleRun('save', {start_date, end_date, assignees, assigneesBonus, project_id, task_id, start_time, end_time, comment, do_notify,defaultHolidays})}
                                >
                                    {' '}
                                     {t('Save')}
                                </Button>
                            )}

                            {!isShowAvailability &&
                                <Button  wd icon style={{marginTop:'10px'}} type="submit" onClick={() => this.handleRun('cancel')}>
                                    {' '}
                                    {t('Cancel')}
                                </Button>
                            }

                            </div>
                            {!isShowAvailability && 
                                <div style={{paddingTop:'5px'}}>
                                <Checkbox
                                        isChecked={do_notify}
                                        onChange={(checked) => /* this.handleChange('do_notify', checked */ this.setState({ do_notify: checked })}
                                        label={this.props.t('Notify user')}
                                        style={{ marginRight: 30 }}
                                        disabled={isMember}
                                    />
                                </div>
                            }
                        </div>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default BulkShiftForm;
