import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import addBtn from '../../../assets/img/plus.png'
import withLocalization from '~/hoc/withLocalization';
import withPermissions from '~/hoc/withPermissions';
import ConversationSearch from '../ConversationSearch';
import ConversationListItem from '../ConversationListItem';
import { MembersWidgetMultipleSelectforAll } from '../../../components/GenericForm/Widgets/UsersWidget';
import Button from '../../../components/CustomButton/CustomButton.jsx';

import './ConversationList.css';

@inject('chatStore', 'userStore', 'commonStore')
@withLocalization
@withPermissions
@withRouter
@observer
export default class ConversationList extends Component {
    constructor(props) {
        super(props);
        this.createRoom = this.createRoom.bind(this);
    }
    state = {
        rooms: [],
        flag: 1,
        searchText: '',
        name: '',
        roomMembers: null,
        privateRooms: [],
        findChatUsers: []
    }

    componentDidMount() {
        const { chatStore, userStore } = this.props
        const userOptions = this.props.userStore.currentMembersLookup.both || [];
        const currentUser = userStore.currentUser;

        const findChatUsers = userOptions.filter(ele => ele.id != currentUser.id);

        this.setState({
            rooms: this.props.rooms ? this.props.rooms : [], privateRooms: this.props.privaterooms ? this.props.privaterooms : [],

        });

    }

    componentDidUpdate(prevstate, prevprops) {
        // if (prevstate.flag != this.state.flag) {
        //     if (this.state.flag == 2) {
        //         console.log("1state if gets true:",this.state);
        //         // this.props.chatStore.loadPrivateRooms().then(data => {
        //         //    this.setState({privateRooms:data});
        //         // })
        // }

        //     }


    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { privateRooms } = prevState
        // console.log("nextProps::", nextProps)
        // console.log("prevState::", prevState);  
            if (prevState.privateRooms != nextProps.privaterooms) {
                // this.setState({privateRooms:nextProps.privateRooms});
                let getUpdatedPrivateRooms = nextProps.privaterooms || [];
                return {
                    privateRooms: getUpdatedPrivateRooms
                }
            }
        
        return {privateRooms}
    }

    onSearch(text) {
        const rooms = this.props.rooms ? this.props.rooms : [];
        const filteredRooms = rooms.filter(obj => obj.name.toLowerCase().indexOf(text.toLowerCase()) >= 0);
        this.setState({ rooms: filteredRooms });
    }
    Opentab = (tabid) => {
        const { chatStore, history } = this.props;
        this.setState({ flag: tabid }, () => {
            chatStore.setCurrentTab(tabid)
            if (tabid === 2) {
                this.props.chatStore.setCurrentPrivateRoom(null);
                this.props.chatStore.setCurrentPrivateRoomId(null);
                history.push('/admin/privatechat')
            }

            if(tabid === 1){
                history.push('/admin/chat') 

            }
        })
        // this.setState({ flag: tabid },()=>{

        //     chatStore.setCurrentTab(tabid);
        //     if(tabid == 2){
        //     this.props.chatStore.setCurrentPrivateRoom();
        //     this.props.chatStore.setCurrentPrivateRoomId();
        //     history.push('/admin/privatechat') }
        //     else if(tabid == 1){
        //         history.push('/admin/chat') 
        //     }
        // });
    };


    handleChange = (value) => {
        this.setState({ roomMembers: value });
    };

    createRoom() {
        const { roomMembers} = this.state;
        const { chatStore, userStore, commonStore,t } = this.props;
        if(roomMembers && roomMembers.split(",").length >5) {
            return commonStore.addNotification(t('You can select only up to 5 members!'), null, 'error');
        }
        let payload = {
            roomMembers: roomMembers,
            user_id: userStore.currentUser.id,
            client_id: userStore.currentUser.client_id
        }
        chatStore.createPrivateRoom(payload).then(data => {
            if (data) {
                this.props.createPrivateRoom(data);
                this.setState({ roomMembers: null });
            }
        }
        ).catch((error) => {
            commonStore.addNotification('No members selected', null, 'error');
        })

    }

    render() {
        const {
            t, allowModification, currentRoomId, unreads, userStore, chatStore, privateUnReads
        } = this.props;
        //  const {privateUnreads} = this.props.chatStore;
        const { chatCurrentTab, currentPrivateRoomId, currentPrivateRoom } = chatStore;
        const { flag, name } = this.state;

        return (
            <div className="conversation-list">
                <div className="conversation-tab" >
                    <p className={(chatCurrentTab == 1 ? "conversation-list-title-project-active" : "conversation-list-title-project")} onClick={() => this.Opentab(1)}>{t('Projects')}</p>
                    <p className={(chatCurrentTab == 2 ? "conversation-list-title-user-active" : "conversation-list-title-user")} onClick={() => this.Opentab(2)}>{t('Members')}</p>
                </div>
                {(chatCurrentTab == 1) ?
                    <div>
                        <div><ConversationSearch
                            search={(text) => this.onSearch(text)}
                            rooms={this.props.rooms ? this.props.rooms : []}
                            t={t} /></div>
                        {this.state.rooms.map(room => (
                            <ConversationListItem
                                currentRoomId={currentRoomId}
                                unreads={unreads}
                                key={room.id}
                                data={room}
                                changeRoom={id => this.props.changeRoom(id)}
                                editRoom={id => this.props.editRoom(id)}
                                allowModification={allowModification}
                                t={this.props.t}
                            />
                        ))}
                    </div>
                    :
                    <div>
                        <div className="users-chat" style={{ marginTop: '20px' }}>

                            <MembersWidgetMultipleSelectforAll
                                value={name}
                                onChange={value => {
                                    this.handleChange(value);
                                }}
                            />

                            <button
                                onClick={this.createRoom}
                                disabled={this.state.roomMembers == null || this.state.roomMembers == ''}
                                className='createroombutton'
                                style={{ visibility: 'hidden' }}>
                                <img src={addBtn} style={{ visibility: 'visible' }} />
                            </button>
                        </div>


                        {this.state.privateRooms.map(room => (
                            <ConversationListItem
                                currentRoomId={currentPrivateRoomId}
                                // privateunreads={privateUnReads}
                                unreads={privateUnReads}
                                key={room.id}
                                data={room}
                                changeRoom={id => this.props.changePrivateRoom(id)}
                                editRoom={id => this.props.editRoom(id)}
                                allowModification={allowModification}
                                t={this.props.t}
                            />

                        ))}


                    </div>
                }
            </div>
        );
    }
}
