import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import { Modal } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';
import MemberAvailability from './MemberAvailability';
import v4 from 'uuid/v4';

@inject('scheduleStore','commonStore')
@withLocalization
@withRouter
@observer
class ShowAvailabiliy extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        availability : [],
        tempAvailability : []
    };

    componentDidMount() {
        this.getAvailabilityData();
    }

    async getAvailabilityData(){
        const {scheduleStore, start_date , end_date ,userIDs} = this.props;
        const avaData = {start_date :start_date,end_date :end_date,userIDs :userIDs};
        if(avaData){
            const data = await scheduleStore.getAvailability(avaData);
            if(data){
                this.setState({availability : data},() => {
                    this.filterObj()
                })
            }
        }
    }

  
    filterObj(){
        const {availability} = this.state;
        const result = availability.reduce(function (r, a) {
            r[a.for_date] = r[a.for_date] || [];
            r[a.for_date].push(a);
            return r;
        }, Object.create(null));
        this.setState({tempAvailability : result},()=>{
        });
       
    }



    render() {
        const {t} = this.props;
        return (
         <>
         {Object.keys(this.state.tempAvailability).length <= 0 ?
            <span>{t("No Availability")}</span>
            :
            <div>
                {Object.keys(this.state.tempAvailability).length > 0 && Object.keys(this.state.tempAvailability).map((ava, index) =>
                    <div key={index}>
                        <MemberAvailability 
                            obj={this.state.tempAvailability[ava]} 
                            index={index}
                        />
                  </div> 
                )}
            </div>
         }
            
         </>   
        )
    }
}
export default ShowAvailabiliy