import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormControl } from 'react-bootstrap';
import { find } from 'lodash';

import Button from '../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '../../../hoc/withLocalization';
import Select from '../../../elements/Select';
import { Input } from '../../../elements/Input';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import { AllUsersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';

@withLocalization
@observer
class DeviationFilter extends Component {
    render() {
        const {
            filters, setFilter, onFilter, loading, t, statuses
        } = this.props;
        const { name } = filters;

        return (
            <div className="list-filters">
                <Input
                    className="list-filters__item list-filters__item_grow"
                    label={t('Search')}
                    placeholder={t('Search')}
                    type="text"
                    value={name}
                    onChange={e => setFilter('name', e.target.value)}
                    name="name"
                    style={{ paddingTop: '15px' }}
                />
                <div className="list-filters__item list-filters__item_to-right">
                    <Button fill wd onClick={() => onFilter()} disabled={loading}>
                        {t('Filter')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default DeviationFilter;
