import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import MultiSelect from '@khanacademy/react-multi-select';

import withLocalization from '../../../hoc/withLocalization';

@inject('documentStore', 'userStore')
@withLocalization
@observer
class DocumentTemplateWidget extends Component {
    state = {
        template_ids:null,
        selected: [],//for selected document template
        options: [],//for document template list
    };
   
    async loadData() {
        const {documentStore, user_id} = this.props;        
        let optionArray = []
        let selectedArray = []
        await documentStore.getDocumentTemplateAssignList(user_id).then((data) => {           
           data.document_templates.map(item=>{
            optionArray.push({
                label: item.template_name,
                value: item.id
            })
           })
           data.assigned_documents.map(item=>{
            selectedArray.push(item.document_id)
           })           
           this.props.handleChnage(selectedArray);
           this.setState({options:optionArray,selected:selectedArray})
        });
    }

    componentDidMount() {
        this.loadData();    
    }

    handleSelectedChanged = selected => {                    
        this.props.handleChnage(selected);
        this.setState({ selected });
    };

    render() {
        const { t } = this.props;
        return (
            <div className="input-block" style={{margin:'2rem 0rem'}}>
                <label className="control-label">{t('Documents')}</label>
                <MultiSelect
                    options={this.state.options}
                    selected={this.state.selected}
                    onSelectedChanged={this.handleSelectedChanged}
                    overrideStrings={{
                        selectSomeItems: t("Please Select Documents"),
                        allItemsAreSelected: t("All"),
                        selectAll: t("Select All"),
                        search: t("Search Document By Name")
                      }}                    
                />                
            </div>
        );
    }
}

export default DocumentTemplateWidget;