import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react'

//components
import withRemoveDialog from '~/hoc/withRemoveDialog';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '~/hoc/withLocalization';
import DocumentTable from './DocumentTemplate/DocumentsTable';
import DocumentAddEdit from './DocumentTemplate/DocumentAddEdit';
import DocumentTemplateTable from './DocumentTemplate/DocumentTemplateTable';
import DocumentTemplateAddEdit from './DocumentTemplate/DocumentTemplateAddEdit';

//styles
import '../../../styles/css/styles.css';

//this is the root component for documents//

@inject('documentStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class Document extends Component {
    state = {
        document_Data: [],
        currentTab: 1,
        id: 0,
        add: true,
        isEmployee: false,
    }

    componentDidMount() {
        const { user_type } = this.props.userStore.currentUser;
        if (user_type == "admin" || user_type == "super-admin") {
            this.setState({ isEmployee: false })
        }
        if (this.props.location.pathname.includes("templates")) {
            this.templateList();
        }

    }

    onAdd() {
        //redirects path to add document//
        const { history } = this.props;
        history.push('/admin/documents/add');
    }

    onAddTemplate() {
        //redirects path to add document_template//
        const { history } = this.props;
        history.push('/admin/documents/templates/add');
    }

    templateList() {
        //redirects path to document_templates list//
        const { history } = this.props;
        history.push('/admin/documents/templates');
    }

    documentList() {
        //redirects path to document list//
        const { history } = this.props;
        history.push('/admin/documents');
    }

    editingId = () => {
        //sets mode on the basis of path//

        let id = null;
        let type = "document";

        if (this.props.location.pathname !== this.props.match.path) {
            //if any id is passed in URL, it assigns the id to id var//
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }

        if (this.props.location.pathname.includes("templates")) {
            //checks if URL is for templates and accordingly assigns type and id//
            type = "template";
            if (id != null) {
                id = id.replace('templates/', '');
            }
        }

        //if no id is passed in URL sets add to true//
        if (id == 'templates') id = null;
        if (id == 'null') id = 'add';

        return { editing: !!id, add: id === 'add', id, type: type };
    };

    Opentab = (tabid) => {
        this.setState({ currentTab: tabid });
        if (tabid == 1) {
            this.templateList()
        }
        else {
            this.documentList()
        }
    };


    render() {
        //renders document and document_template list components//
        const { t } = this.props;
        const mode = this.editingId();
        const { user_type } = this.props.userStore.currentUser;
        return (
            <div className={`main-content`}>
                {user_type != "member" &&
                    <div className={'row-flex'}>
                        <button className={(this.state.currentTab == 1 ? "tablink-twotab-active" : "tablink-twotab")} onClick={() => this.Opentab(1)}>
                            {t('Document Book')}
                        </button>

                        <button className={(this.state.currentTab == 2 ? "tablink-twotab-active" : "tablink-twotab")} onClick={() => this.Opentab(2)}>
                            {t('Document List')}
                        </button>

                    </div>
                }
                <div className="table-list">
                    {!mode.editing && mode.type == "document" && (
                        <div>
                            <div className="table-list__header">
                                {user_type != "member" &&
                                    <Button fill wd icon onClick={() => this.onAdd()}>
                                        {t('Create Document')}
                                    </Button>}
                            </div>
                            <DocumentTable />
                        </div>
                    )}
                </div>
                {mode.editing && mode.type == "document" && <DocumentAddEdit id={mode.id} add={mode.add} />}
                <div className="table-list">
                    {!mode.editing && mode.type == "template" && (
                        <div>
                            {user_type == "super-admin" ?
                                <div className="input-area__content">
                                    <p className="text-danger">
                                        {t("Document Book")} {t("Is only accesible by client. Please Use")} {t('Document List')}
                                    </p>
                                </div>
                                : <>
                                    <div className="table-list__header">
                                        {user_type != "member" &&
                                            <Button fill wd icon onClick={() => this.onAddTemplate()}>
                                                {t('Create Document Book')}
                                            </Button>
                                        }
                                    </div>
                                    <DocumentTemplateTable />
                                </>}
                        </div>
                    )}
                </div>
                {mode.editing && mode.type == "template" && <DocumentTemplateAddEdit id={mode.id} add={mode.add} />}
                {/* {user_type == "super-admin" &&
                    <div>
                        Document Book is accesible by client only. Please Use Document List.
                    </div>
                } */}
            </div>
        )
    }
}

export default Document;