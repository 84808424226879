import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';

import { Input } from '../../../elements/Input';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import RadioWidget from '../../../components/GenericForm/Widgets/RadioWidget';
import { validateNorwegianIdNumber } from '../../../utils/validateNorwayNo';

import withLocalization from '~/hoc/withLocalization';

const userTypes = {
    enumOptions: [
        { value: 'member', label: 'Member' },
        { value: 'pm', label: 'PM' },
        { value: 'admin', label: 'Admin' },
    ],
};

@inject('userStore', 'commonStore')
@withRouter
@withLocalization
@observer
class UserValidateBeforeAdd extends Component {
    state = {
        data: {
            user_type: 'member',
            phone: '',
            social_number: '',
        },
        error: '',
    };

    async loadData() {
        const {
            userStore, id, add, mode
        } = this.props;
        const { loading, currentEntity } = userStore;
        // userStore.validate
    }

    componentDidMount() {
        this.loadData();
    }

    handleChange = (name, value) => {
        const data = Object.assign({}, this.state.data);
        data[name] = value;
        this.setState({ data });
    };

    proceed = async () => {
        const {
            userStore, history, add, t, commonStore
        } = this.props;
        const { data } = this.state;
        if (data.user_type === 'member' && (!data.phone || !data.social_number)) {
            this.setState({ error: t('Please fill phone and social number') });
            return;
        }
        if (data.social_number && !validateNorwegianIdNumber(data.social_number)) {
            this.setState({ error: t('Incorrect social number') });
            return;
        }
        if (data.phone && !data.phone.match(/^((\+47)?\d{8})|((\+48)?\d{9})$/)) {
            this.setState({ error: t('Incorrect phone') });
            return;
        }
        const response = await userStore.validateNewUser(data);
        if (response.error) {
            this.setState({ error: response.error });
            return;
        }
        if (response.user) {
            commonStore.addNotification(t('User is connected to your project'), null, 'success');
            history.push(`/admin/members/${response.user}`);
        } else {
            commonStore.addNotification(t('User is not found, please proceed with adding'), null, 'success');
            history.push(
                `/admin/members/add?user_type=${data.user_type}&phone=${data.phone.replaceAll('+', '%2B')}&social_number=${data.social_number}`
            );
        }
    };

    render() {
        const {
            userStore, add, mode, t
        } = this.props;
        const { error } = this.state;

        return (
            <div className="add-user-form">
                <h2>{t('Add user')}</h2>
                {userStore.currentUser.user_type === 'admin' && (
                    <div className="row">
                        <div className="form-group field field-string">
                            <RadioWidget
                                vertical
                                options={userTypes}
                                value={this.state.data.user_type}
                                onChange={value => this.handleChange('user_type', value)}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="form-group field field-string">
                        <label className="control-label" htmlFor="r_phone">
                            {t('Phone')}
                        </label>
                        <input
                            className="form-control"
                            id="r_phone"
                            required
                            placeholder={t('Phone')}
                            type="text"
                            value={this.state.data.phone}
                            onChange={e => this.handleChange('phone', e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group field field-string">
                        <label className="control-label" htmlFor="r_phone">
                            {t('Social number')}
                        </label>
                        <input
                            className="form-control"
                            id="r_social"
                            required
                            placeholder={t('Social number')}
                            type="text"
                            value={this.state.data.social_number}
                            onChange={e => this.handleChange('social_number', e.target.value)}
                        />
                    </div>
                </div>
                {!!error && <div className="row error">{error}</div>}
                <Button fill wd onClick={() => this.proceed()}>
                    {t('Proceed')}
                </Button>
            </div>
        );
    }
}

export default UserValidateBeforeAdd;
