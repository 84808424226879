import React from 'react';

function DeviationUrgencyMarker({ config, urgency, t }) {
    const foundUrgency = config.urgency[urgency] || config.urgency.normal;
    return (
        <span>
            <i className="fa fa-circle" style={{ color: foundUrgency.color }} alt={t(foundUrgency.name)} />
        </span>
    );
}
export default DeviationUrgencyMarker;
