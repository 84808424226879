import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormControl } from 'react-bootstrap';
import { find } from 'lodash';

import Button from '../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '../../../hoc/withLocalization';
import Select from '../../../elements/Select';
import { Input } from '../../../elements/Input';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import { AllUsersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';

@withLocalization
@observer
class DeviationFilter extends Component {
    render() {
        const {
            filters, setFilter, onFilter, loading, t, statuses, isUnionMode
        } = this.props;
        const {
            subject, assigned_id, project, status, reported_by_id
        } = filters;
        const statusesKeys = Object.keys(statuses);
        const options = statusesKeys.map(key => ({ label: t(statuses[key]), value: key }));
        options.unshift({ value: '', label: t('All') });
        const currentSelect = find(options, o => {
            return o.value === status;
        });

        return (
            <div className="list-filters">
                {!isUnionMode && (
                    <div className="list-filters__item">
                        <Select
                            inputView
                            label="Status"
                            options={options || []}
                            round
                            value={currentSelect}
                            onChange={id => setFilter('status', id.value)}
                            style={{ width: '150px' }}
                        />
                    </div>
                )}
                <Input
                    className="list-filters__item list-filters__item_grow"
                    label={t('Search')}
                    placeholder={t('Search')}
                    type="text"
                    value={subject}
                    onChange={e => setFilter('subject', e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            onFilter();
                        }
                    }}
                    name="subject"
                    style={{ paddingTop: '15px' }}
                />
                <div className="list-filters__item list-filters__item_grow">
                    <ProjectsWidget
                        allowAll
                        label={`${t('Project')}`}
                        value={project}
                        onChange={value => setFilter('project', value)}
                    />
                </div>
                {!isUnionMode && (
                    <div className="list-filters__item list-filters__item_grow">
                        <AllUsersWidget
                            emptyOption
                            emptyOptionLabel={t('All')}
                            placeholder={t('Responsible')}
                            label={t('Responsible')}
                            value={assigned_id}
                            onChange={value => setFilter('assigned_id', value)}
                        />
                    </div>
                )}
                {!isUnionMode && (
                    <div className="list-filters__item list-filters__item_grow">
                        <AllUsersWidget
                            emptyOption
                            emptyOptionLabel={t('All')}
                            label={t('Reported by')}
                            placeholder={t('Reported by')}
                            value={reported_by_id}
                            onChange={value => setFilter('reported_by_id', value)}
                        />
                    </div>
                )}
                <div className="list-filters__item list-filters__item_to-right">
                    <Button fill wd onClick={() => onFilter()} disabled={loading}>
                        {t('Filter')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default DeviationFilter;
