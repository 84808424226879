import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../hoc/withLocalization';
import { ReactComponent as SuccessIcon } from '../../../assets/img/success.svg';
import { Button } from 'react-bootstrap';
@inject('signatureStore')
@withRouter
@withLocalization
@observer
export class DocSuccess extends Component {
    componentDidMount() {
        const { signatureStore } = this.props;
        signatureStore.openFullScreenMode();
    }
    backToHome(){
        const { history, signatureStore } = this.props;
        signatureStore.closeFullScreenMode()
        signatureStore.resetDocList();
        history.push("/admin/cases");
    }
    render() {
        const {t} = this.props;
        return (
            <div className="successPage">
                <SuccessIcon />
                <h1>{t('Your Document Is On Its Way.')}</h1>
                <p>{t('Its your turn to sign.')}</p>
                <Button className="btnSignNow" onClick={()=>{ this.backToHome() }} variant="primary" size="lg">
                    {t('Back To Home')}
                </Button>
            </div>
        );
    }
}

export default DocSuccess;
