/*
*     status: DataTypes.STRING,
    user_id: DataTypes.INTEGER,
    from: DataTypes.DATE,
    to: DataTypes.DATE,
    vacation_type: DataTypes.INTEGER,
    total_days: DataTypes.INTEGER,
    attachments: DataTypes.STRING,
    comments: DataTypes.STRING(2000),
*/
export const schema = {
    title: 'Absence',
    type: 'object',
    required: ['absence_type', 'user_id', 'from', 'to', 'total_days','leave_type'],
    properties: {
        id: { type: 'integer' },
        user_id: { type: ['integer'], title: 'User', readOnly: true },
        client_id: { type: ['integer'], title: 'Client' },
        child: { type: ['string', 'null'], title: 'Child' },
        status: { type: ['string', 'null'], title: 'Status', default: 'active' },
        absence_type: { type: ['string'], title: 'Type'},        
        total_days: { type: ['integer'], title: 'Total days', readOnly: true ,default:10},
        absence_payment_per_day: { type: ['integer'], title: 'Enter Payment'},
        absence_total_payment:{type: ['number'], title: 'Total Payment', readOnly: true},
        from: { type: ['string'], format: 'date', title: 'From date' },
        to: { type: ['string'], format: 'date', title: 'To date' },
        attachments: { type: ['string', 'null'], title: 'Attachments' },        
        leave_type: { type: ['string'], title: 'Leave Type',default:'paid' },
        employement_grade: { type: 'integer', title: 'Employee grade in %', minimum: 0, maximum: 100,default:0,multipleOf:5 },
        hours: { type: 'number', title: 'Hours', readOnly: true,default:0 },
        total_hours: { type: 'number', title: 'Total Hours', readOnly: true,default:0 },
    },
};

export const getSchema = () => schema;

export default getSchema;
