import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Col, Grid, Row } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';

import './dashboard.css';
import ChartistGraph from 'react-chartist';
import { Map, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import Button from '../../components/CustomButton/CustomButton.jsx';
import DatePicker from '../../components/DatePicker/DatePicker';
import withLocalization from '../../hoc/withLocalization';
import MapMarker from '../../elements/MapMarker';
import ChartPie from '../../elements/ChartPie';

import ScheduleMember from './Schedule/ScheduleMember';
import TimelogDashboardWidget from './Timelogs/TimelogDashboardWidget';
import DeviationDashboardWidget from './Deviation/DeviationDashboardWidget';
import { longDateFormat } from '../../utils/dateFormat'
import { totalTimeFormat, get_currency, timeFormat } from '~/utils/dateFormat'

// Leaflet

import { leafletStringToLocation, locationToString, googleCoordsToLeaflet } from '~/utils/geo';
import { minutesToTimeInput } from '~/utils/timeCalc';
import config from '~/library/config';
import * as core from '~/library/core';
import CompanyCard from '~/components/Card/CompanyCard.jsx';
import StatsCard from '~/components/Card/StatsCard.jsx';
import LoadingSpinner from '~/elements//LoadingSpinner';
import { style } from '~/variables/Variables';

const DEFAULT_LOCATION = [59.911442, 10.75905];

@inject('dashboardStore', 'userStore', 'commonStore')
@withLocalization
@withRouter
@observer
class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            setPage: 0,
            moreShifts : false
        }
        this.handleSeeMore = this.handleSeeMore.bind(this);
        this.handleSeeLess = this.handleSeeLess.bind(this);
    }
    state = {
        from: moment(new Date()).startOf('month').toDate(),
        to: moment(new Date()).toDate(),
        activeProject: null,
        showSchedules: 6,

    };


    componentDidMount() {
        this.loadData();
    }

    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };

    loadData() {
        const { currentUser } = this.props.userStore;
        const { dashboardStore, history ,commonStore} = this.props;
        const { from, to } = this.state;
        dashboardStore.load({ from: moment(from).format('YYYY-MM-DD'), to: moment(to).format('YYYY-MM-DD'), scheduleParams: { page: 0, pagesize: 6 } });
        if (currentUser && currentUser.is_now_locked) {
            history.push('/admin/locked');
        }
         if(commonStore.config.client.data.isFirstTimeLogin==true){
                history.push('/admin/control_panel')
             }

    }

    renderSuperAdmin() {
        const { t, dashboardStore, userStore } = this.props;
        const { loading, currentData } = dashboardStore;
        if (!currentData.clients) {
            return null;
        }
        const clientChartData = [
            {
                value: currentData.clients[0].active,
                view: 'blue',
                label: `${t('Active')} [${currentData.clients[0].active}]`,
            },
            {
                value: currentData.clients[0].inactive,
                view: 'red',
                label: `${t('Inactive')} [${currentData.clients[0].inactive}]`,
            },
        ];
        const userChartData = [
            {
                value: currentData.users[0].active,
                view: 'blue',
                label: `${t('Active')} [${currentData.users[0].active}]`,
            },
            {
                value: currentData.users[0].inactive,
                view: 'red',
                label: `${t('Inactive')} [${currentData.users[0].inactive}]`,
            },
        ];
        const userCounterChartData = [
            {
                value: currentData.usersCounters[0].working_now,
                view: 'blue',
                label: `${t('Working now')} [${currentData.usersCounters[0].working_now}]`,
            },
            {
                value: currentData.usersCounters[0].gone_home,
                view: 'orange',
                label: `${t('Gone home')} [${currentData.usersCounters[0].gone_home}]`,
            },
            {
                value: currentData.usersCounters[0].all_users - currentData.usersCounters[0].gone_home - currentData.usersCounters[0].working_now,
                view: 'red',
                label: `${t('Not at work')} [${currentData.usersCounters[0].all_users - currentData.usersCounters[0].gone_home - currentData.usersCounters[0].working_now
                    }]`,
            },
        ];
        return (
            <div className="main-content">
                <Grid fluid>
                    {/* {this.renderDatepicker()}
                    <Row>
                        <Col lg={3} sm={6}>
                            <StatsCard
                                bigIcon={<i className="pe-7s-wallet text-warning" />}
                                statsText={t('Clients')}
                                statsValue={currentData.clients[0].count}
                                statsIcon={<i className="fa fa-refresh" />}
                                statsIconText=""
                            />
                        </Col>
                        <Col lg={3} sm={6}>
                            <StatsCard
                                bigIcon={<i className="pe-7s-server text-warning" />}
                                statsText={t('Users')}
                                statsValue={currentData.users[0].count}
                                statsIcon={<i className="fa fa-refresh" />}
                                statsIconText=""
                            />
                        </Col>
                    </Row> */}
                    <Row>
                        <Col md={4}>
                            <div className="card">
                                <div className="card-header card-header-with-button">
                                    <h4 className="card-title">
                                        <NavLink to="/admin/clients">{t('Clients')}</NavLink>
                                    </h4>
                                </div>
                                <div className="card-body " style={{ minHeight: '320px' }}>
                                    <ChartPie data={clientChartData} />
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="card">
                                <div className="card-header card-header-with-button">
                                    <h4 className="card-title">
                                        <NavLink to="/admin/clients">{t('Users')}</NavLink>
                                    </h4>
                                </div>
                                <div className="card-body " style={{ minHeight: '320px' }}>
                                    <ChartPie data={userChartData} />
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="card">
                                <div className="card-header card-header-with-button">
                                    <h4 className="card-title">
                                        <NavLink to="/admin/clients">{t('Members')}</NavLink>
                                    </h4>
                                </div>
                                <div className="card-body " style={{ minHeight: '320px' }}>
                                    <ChartPie data={userCounterChartData} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

    renderDatepicker() {
        const { t, dashboardStore, userStore, commonStore } = this.props;
        const { loading, currentData } = dashboardStore;
        const dashboard = currentData;
        const { from, to } = this.state;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;

        return (
            <Row style={{ marginBottom: '20px' }}>
                <Col lg={12}>
                    <h4 className="dashboard-picker-title">{t('From')}</h4>

                    <DatePicker
                        selected={from}
                        onChange={(date) => this.handleChange('from', date)}
                        dateFormat= {longDateFormat(dateTimeRules)}
                    />
                    <h4 className="dashboard-picker-title">{t('To')}</h4>

                    <DatePicker
                        selected={to}
                        onChange={(date) => this.handleChange('to', date)}
                        dateFormat= {longDateFormat(dateTimeRules)}
                    />
                    <Button fill round wd onClick={() => this.loadData()} style={{ margin: '15px' }}>
                        {t('Submit')}
                    </Button>
                </Col>
            </Row>
        );
    }

    renderChart() {
        const { t, dashboardStore, userStore, commonStore } = this.props;
        const { loading, currentData } = dashboardStore;
        const dashboard = currentData;
        const { from, to } = this.state;

        if (!dashboard.timeList) return null;

        const hoursChart = {
            type: 'Line',
            options: {
                showPoint: true,
                height: '260px',
                lineSmooth: true,
                axisX: {
                    showGrid: false,
                    showLabel: false,
                },
                axisY: {
                    offset: 40,
                },
                chartPadding: {
                    right: -18,
                },
            },
        };

        return (
            <div className="card card-block">
                <div className="content">
                    <ChartistGraph data={dashboard.timeList} type={hoursChart.type} options={hoursChart.options} />
                </div>
            </div>
        );
    }

    renderProjects() {
        const { t, dashboardStore, userStore, commonStore } = this.props;
        const { loading, currentData } = dashboardStore;
        const dashboard = currentData;
        const { from, to } = this.state;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;

        return (
            <div className="card card-block">
                <div className="content">
                    <h5>{t('Projects')}</h5>
                    {dashboard.projects.map((project, index) => (
                        <div>
                            <h5>{project.name}</h5>
                            <div className="totals project">
                                <div className="label">{t('Planned hours')}</div>
                                <div className="value">
                                    {' '}
                                    {project.hours} {t('monthly')}
                                </div>
                            </div>
                            <div className="totals hours">
                                <div className="label">{t('Total Hours')}</div>
                                <div className="value">{totalTimeFormat(project.total_normal || 0, dateTimeRules)}</div>
                            </div>
                            <div className="totals hours">
                                <div className="label">{t('Overtime')}</div>
                                <div className="value">{totalTimeFormat(project.total_overtime || 0, dateTimeRules)}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    renderMember() {
        const { t, dashboardStore, userStore, commonStore } = this.props;
        const { loading, currentData } = dashboardStore;
        const dashboard = currentData;
        const { from, to } = this.state;

        const { data: biztypeData } = commonStore.config.biztype;

        let rate = userStore.currentUser.hourly_rate;
        let earned = 0;

        if (rate && dashboard.grandtotals
            && (userStore.currentUser.payment_mode !== 'fixed')) {
            earned = parseInt(
                (rate * (dashboard.grandtotals.total_normal + dashboard.grandtotals.total_overtime)) / 60
            );
            earned = `${earned} ${core.moneySymbol()}`;
            rate = `${rate} / ${core.hourSymbol()}`;
        }
        else if (userStore.currentUser.payment_mode === 'fixed') {
            earned = `${rate} ${core.moneySymbol()}`;
            rate = `${rate} / fixed`;
        }
        else {
            earned = '?';
            rate = t('Rate not set');
        }
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;

        return (
            <div className="main-content">
                <Grid fluid>
                    <Row key="main" className="dashboard-stats">
                        <TimelogDashboardWidget />
                        <Col lg={3} sm={6}>
                            <CompanyCard
                                bigIcon={
                                    commonStore.config.client.image ? (
                                        <img
                                            src={`${config.UPLOADS_API_ENDPOINT}/${commonStore.config.client.image}`}
                                        />
                                    ) : (
                                        <i className="pe-7s-users text-success" />
                                    )
                                }
                                statsText={commonStore.config.client.name}
                                statsValue=""
                                statsIcon={<i className="fa fa-refresh" />}
                                statsIconText={
                                    biztypeData.hide_send_report ? null : (
                                        <a href="#" onClick={() => this.props.history.push('/admin/send_report')}>
                                            {t('Contact manager')}
                                        </a>
                                    )
                                }
                            />
                        </Col>
                        {dashboard.grandtotals && (
                            <Col lg={3} sm={6}>
                                <StatsCard
                                    bigIcon={<i className="pe-7s-hourglass text-info" />}
                                    statsText={t('My total this month')}
                                    statsValue={
                                        <small>
                                            {`${totalTimeFormat(dashboard.grandtotals.total_normal || 0, dateTimeRules )}`}
                                        </small>
                                    }
                                    statsIcon={<i className="fa fa-refresh" />}
                                    statsIconText={`${totalTimeFormat(
                                        dashboard.grandtotals.total_overtime || 0,
                                        dateTimeRules
                                    )} ${t('Overtime')}`}
                                />
                            </Col>
                        )}
                        {dashboard.grandtotals && (
                            <Col lg={3} sm={6}>
                                <StatsCard
                                    bigIcon={<i className="pe-7s-piggy text-info" />}
                                    statsText={t('Earning this month')}
                                    statsValue={<small>{`${earned}`}</small>}
                                    statsIcon={<i className="fa fa-refresh" />}
                                    statsIconText={`${rate}`}
                                />
                            </Col>
                        )}
                    </Row>
                    {!!biztypeData.enable_deviations && !biztypeData.union_group && (
                        <Row key="deviations" className="deviation">
                            <DeviationDashboardWidget data={dashboard} />
                        </Row>
                    )}

                    <Row key="schedule">
                        <Col sm={12} lg={12} className="dashboard-schedule">
                            <div className="card card-block">
                                <div className="content">
                                    <h4>{t('Schedule for next 2 weeks')}</h4>
                                    <ScheduleMember />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} lg={6}>
                            {this.renderChart()}
                        </Col>
                        <Col sm={12} lg={6}>
                            {this.renderProjects()}
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

    handleSeeMore() {
        const { setPage,moreShifts } = this.state;
        const { dashboardStore: { currentData, todayAtWork},dashboardStore,commonStore,t } = this.props;
        // const { shifts } = todayAtWork;
        // this.setState(prevState => ({
        //     setPage: prevState.setPage + 1
        // }), () => {
        //     dashboardStore.getDashboardSchedules({ page: this.state.setPage, pagesize: 6 });
        // });
        let pageLength = Math.floor(todayAtWork.length / 6);

        dashboardStore.getDashboardSchedules({ page: pageLength, pagesize: 6 }).then(resp=>{
            if(resp && resp.length < 6){
                commonStore.addNotification(t('All schedules are fetched'), null, 'success');
                this.setState({moreShifts:true});
            }
            if(resp && resp.length === 0){
                commonStore.addNotification(t('All schedules are fetched'), null, 'success');
            }
        });

    }

    handleSeeLess() {
        const { dashboardStore } = this.props;
        dashboardStore.handleShowLess();
        this.setState({moreShifts:false})
    }

    renderScheduleData() {
        const {
            t,
            dashboardStore: { currentData, todayAtWork, nextShifts, tableLoading },
            commonStore,
            userStore,
        } = this.props;
        const { moreShifts } = this.state;
        if (tableLoading) {
            return <LoadingSpinner />;
        }
         if (todayAtWork.length === 0) 
         {
            return (
                <div style={{fontWeight:'600'}}>
                   {t('No schedule')} {t(' Today')}
                </div>
            )
         }
        // const { shifts } = todayAtWork;
        let disableBtn = Math.floor(todayAtWork.length / 6) ? false : true;
        return (
            <div className="horizontal-scroll_wrap">
                <div className="horizontal-scroll">
                    
                    <div className='scrollable' style={{overflowY:'auto',height:'324px',marginBottom:"15px"}}>
                    <table className="table table-dashboard-schedule" >
                        <thead>
                            <tr>
                                {/* <th className="text-center">ID</th> */}
                                <th>{t('Name')}</th>
                                <th>{t('Work Today')}</th>
                                <th>{t('Schedule')}</th>
                                <th>{t('status')}</th>
                            </tr>
                        </thead>
                        <tbody >
                            {todayAtWork.length > 0 && todayAtWork.map((shift) => (
                                <tr
                                    className="table-project-small__row"
                                    key={shift.assignee_id}
                                >
                                    {/* <td className="text-center">{shift['assignee_id']}</td> */}
                                    <td>{shift.username}</td>
                                    <td>{minutesToTimeInput(shift.sum, false)}</td>
                                    <td>
                                        {minutesToTimeInput(shift.min_start_time, true)} -{' '}
                                        {minutesToTimeInput(shift.max_end_time, true)}
                                    </td>
                                    <td>{t(`shift_status_${shift.status}`)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                    {todayAtWork.length > 0 && todayAtWork.length >=6 &&
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* <div style={? {display:'flex',justifyContent:'space-between'}:{display:'none'}}> */}
                            <Button disabled={(todayAtWork.length <= 6 ) ? true : false} onClick={this.handleSeeLess}>{t('See Less')}</Button>
                            <Button disabled={moreShifts || disableBtn} onClick={this.handleSeeMore}>{t('See More')}</Button>
                        </div>
                    }
                </div>
            </div>
        )
    }

    renderAdmin() {
        const {
            t,
            dashboardStore: { currentData, todayAtWork, nextShifts },
            commonStore,
            userStore,
        } = this.props;
        const activeProject = this.state.activeProject || currentData.projects[0];
        const usersCounters = currentData.usersCounters ? currentData.usersCounters[0] : {};
        if (!todayAtWork) return null;
        const { shifts } = todayAtWork;
        const totalNormal = currentData.overallWorkhours[0].total_normal;
        const totalOvertimes = currentData.overallWorkhours[0].total_overtime;
        let totalCosts = currentData.overallWorkhours[0].total_costs;
        let totalCostsOvertime = currentData.overallWorkhours[0].total_costs_overtime;
        let totalFixedCosts = currentData.overallWorkhours[0].total_fixed_costs;
        const { data: biztypeData } = commonStore.config.biztype;
        const userType = userStore.currentUser.user_type;

        if (!totalCosts) {
            totalCosts = !totalFixedCosts ? 0 : parseInt(totalFixedCosts, 10);
        } else {
            totalCosts = parseInt(totalCosts, 10) + (!totalFixedCosts ? 0 : parseInt(totalFixedCosts, 10));
        }
        if (!totalCostsOvertime) {
            totalCostsOvertime = 0;
        } else {
            totalCostsOvertime = parseInt(totalCostsOvertime, 10);
        }
        if (!currentData.projects) {
            return null;
        }
        const chartData = [
            {
                value: usersCounters.working_now,
                view: 'blue',
                label: `${t('Working now')} [${usersCounters.working_now}]`,
            },
            {
                value: usersCounters.gone_home,
                view: 'orange',
                label: `${t('Gone home')} [${usersCounters.gone_home}]`,
            },
            {
                value: usersCounters.all_users - usersCounters.gone_home - usersCounters.working_now,
                view: 'red',
                label: `${t('Not at work')} [${usersCounters.all_users - usersCounters.gone_home - usersCounters.working_now
                    }]`,
            },
        ];
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? 
                              commonStore.config.client.data.dateTimeRules : false;

        return (
            <div className="main-content">
                {/* <pre>{JSON.stringify(currentData, null, 4)}</pre> */}
                <Grid fluid>
                    <Row>
                        {userType === 'pm' && <TimelogDashboardWidget />}
                        <Col lg={3} sm={6}>
                            <CompanyCard
                                bigIcon={
                                    commonStore.config.client.image ? (
                                        <img
                                            src={`${config.UPLOADS_API_ENDPOINT}/${commonStore.config.client.image}`}
                                        />
                                    ) : (
                                        <i className="pe-7s-users text-success" />
                                    )
                                }
                                statsText={commonStore.config.client.name}
                                statsValue=""
                                statsIcon={<i className="fa fa-refresh" />}
                                statsIconText={t('Your company')}
                            />
                        </Col>
                        <Col lg={3} sm={6}>
                            <StatsCard
                                bigIcon={<i className="fa fa-clock-o text-warning" />}
                                statsText={t('Total Hours')}
                                statsValue={totalTimeFormat(totalNormal || 0, dateTimeRules )}
                                statsIcon={<i className="fa fa-refresh" />}
                                statsIconText={t('This month')}
                            />
                        </Col>
                        <Col lg={3} sm={6}>
                            <StatsCard
                                bigIcon={<i className="fa fa-black-tie text-danger" />}
                                statsText={t('Overtime')}
                                statsValue={totalTimeFormat(totalOvertimes || 0, dateTimeRules )}
                                statsIcon={<i className="fa fa-refresh" />}
                                statsIconText={t('This month')}
                            />
                        </Col>
                        {userType !== 'pm' && (
                            <Col lg={3} sm={6}>
                                <StatsCard
                                    bigIcon={<i className="pe-7s-wallet text-success" />}
                                    statsText={t('Total Costs')}
                                    statsValue={get_currency(dateTimeRules) == '$' ?
                                    `${get_currency(dateTimeRules)} ${totalCosts + totalCostsOvertime}` 
                                    : `${totalCosts + totalCostsOvertime} ${get_currency(dateTimeRules)}`}
                                    statsIcon={<i className="fa fa-refresh" />}
                                    statsIconText={`${t('Overtime')} ${get_currency(dateTimeRules) == '$' ?
                                    `${get_currency(dateTimeRules)} ${totalCostsOvertime}` 
                                    : `${totalCostsOvertime} ${get_currency(dateTimeRules)}`}`}
                                />
                            </Col>
                        )}
                    </Row>
                    {!!biztypeData.enable_deviations && !biztypeData.union_group && (
                        <Row key="Deviation1" className="deviation">
                            <DeviationDashboardWidget data={currentData} />
                        </Row>
                    )}
                    {/* <Row>
                        <Col md={12}>
                            <div className="card ">
                                <div className="card-header ">
                                    <h4 className="card-title">
                                        <NavLink to="/admin/projects">{t('My Projects')}</NavLink>
                                    </h4>
                                </div>
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="horizontal-scroll_wrap">
                                                <div className="horizontal-scroll">
                                                    <table className="table table-project-small">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">ID</th>
                                                                <th>{t('Project')}</th>
                                                                <th>{t('Members')}</th>
                                                                <th className="text-center">{t('Tasks')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {currentData.projects.map((project) => (
                                                                <tr
                                                                    key={project.id}
                                                                    className={`table-project-small__row ${
                                                                        project.id === activeProject.id
                                                                            ? 'table-project-small__row_active'
                                                                            : ''
                                                                    }`}
                                                                    onClick={() =>
                                                                        this.setState({ activeProject: project })
                                                                    }
                                                                >
                                                                    <td className="text-center">{project.id}</td>
                                                                    <td>{project.name}</td>
                                                                    <td>{project.members_count}</td>
                                                                    <td>
                                                                        <NavLink
                                                                            className="table-project-small__tasklink"
                                                                            to={`/admin/tasks?project=${project.id}`}
                                                                        >
                                                                            {project.tasks_count}
                                                                        </NavLink>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="project-map">
                                                {activeProject && (
                                                    <Map
                                                        center={
                                                            activeProject.gps_data
                                                                ? leafletStringToLocation(activeProject.gps_data)
                                                                : DEFAULT_LOCATION
                                                        }
                                                        className="markercluster-map"
                                                        zoom={12}
                                                        maxZoom={18}
                                                    >
                                                        <TileLayer
                                                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        />
                                                        <MarkerClusterGroup>
                                                            {currentData.projects.map((project) => (
                                                                <MapMarker
                                                                    key={project.id}
                                                                    id={project.id}
                                                                    isActive={activeProject.id === project.id}
                                                                    position={
                                                                        activeProject.gps_data
                                                                            ? leafletStringToLocation(project.gps_data)
                                                                            : DEFAULT_LOCATION
                                                                    }
                                                                    title={project.name}
                                                                    riseOnHover
                                                                />
                                                            ))}
                                                        </MarkerClusterGroup>
                                                    </Map>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col md={4}>
                            <div className="card">
                                <div className="card-header card-header-with-button">
                                    <h4 className="card-title">
                                        <NavLink to="/admin/timelogs?status=draft">{t('Members')}</NavLink>
                                    </h4>
                                </div>
                                <div className="card-body " style={{ minHeight: '320px' }}>
                                    <ChartPie data={chartData} />
                                </div>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="card">
                                <div className="card-header card-header-with-button">
                                    <h4 className="card-title">
                                        <NavLink to="/admin/schedule">{t('Users Schedules')}</NavLink>
                                    </h4>
                                </div>
                                <div className="card-body " style={{ minHeight: '320px' }}>
                                   {
                                    this.renderScheduleData()
                                   }
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>{this.renderChart()}</Col>
                    </Row>
                </Grid>
            </div>
        );
    }

    render() {
        const {dashboardStore, userStore, commonStore } = this.props;
        const { loading, currentData } = dashboardStore;
        if (loading) {
            return <LoadingSpinner />;
        }
        const userType = userStore.currentUser.user_type;
        if (userType == 'super-admin') {
            return this.renderSuperAdmin();
        }
        if (!commonStore.config.biztype || !currentData.projects) {
            window.location.reload(true);
            return null;
        }
        if (userType == 'pm' || userType == 'admin') {
            
            return this.renderAdmin();
        }

        return this.renderMember();
    }
}

export default Dashboard;
