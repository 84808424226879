import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Col, FormControl } from 'react-bootstrap';
import { find } from 'lodash';
import moment from 'moment/min/moment-with-locales';

import Button from '../../../../components/CustomButton/CustomButton.jsx';
import Select from '../../../../elements/Select';
import withLocalization from '../../../../hoc/withLocalization';
import statuses from '../../../../utils/normalStatuses';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import { longDateFormat } from '../../../../utils/dateFormat'
import { ProjectsWidget } from '../../../../components/GenericForm/Widgets/ProjectsWidget';

@inject('commonStore')
@withLocalization
@observer
class TimelogListFilter extends Component {
    setFilterDate(name, name1, value) {
        this.props.setFilter(name1, value);
        this.props.setFilter(name, moment(new Date(value)).format('YYYY-MM-DD'));
    }

    render() {
        const { filters, setFilter, onFilter, loading, t, showStatus, onChangeStatus } = this.props;
        const { config } = this.props.commonStore;
        const { status, name, project, fromDate, toDate, fromDate1, toDate1 } = filters;
        // console.log('F', filters);
        const projectStatuses = statuses;
        const options = projectStatuses.map((status) => {
            return { value: `${status.code}`, label: t(status.name) };
        });
        options.unshift({ value: '', label: t('All') });
        const currentSelect = find(options, (o) => {
            return o.value === status;
        });
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        return (
            <div className="list-filters">
                <div className="list-filters__item">
                    <Select
                        options={options}
                        round
                        value={currentSelect}
                        onChange={(id) => setFilter('status', id.value)}
                        style={{ width: '150px' }}
                        placeholder={t('Select...')}
                    />
                </div>
                <div className="list-filters__item">
                    <ProjectsWidget
                        allowAll
                        allLabel={t('All projects')}
                        value={project || ''}
                        onChange={(id) => setFilter('project', id)}
                    />
                </div>
                <div className="list-filters__item list-filters__item_grow">
                    <DatePicker
                        selected={fromDate1}
                        onChange={(date) => this.setFilterDate('fromDate', 'fromDate1', date)}
                        dateFormat= {longDateFormat(dateTimeRules)}
                        placeholder={t('From')}
                    />
                    
                </div>
                <div className="list-filters__item list-filters__item_grow">
                    <DatePicker
                        selected={toDate1}
                        onChange={(date) => this.setFilterDate('toDate', 'toDate1', date)}
                        dateFormat= {longDateFormat(dateTimeRules)}
                        placeholder={t('To')}
                    />
                </div>
                <div className="list-filters__item list-filters__item_grow">
                    <FormControl
                        placeholder={t('Search')}
                        type="text"
                        onChange={(e) => setFilter('name', e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onFilter();
                            }
                        }}
                        value={name}
                        style={{margin: 0}}
                    />
                </div>
                <div className="list-filters__item">
                    <Button fill wd onClick={() => onFilter()} disabled={loading}>
                        {t('Filter')}
                    </Button>
                    {showStatus && (
                        <Button wd round onClick={() => onChangeStatus()} disabled={loading}>
                            {t('Change status')}
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

export default TimelogListFilter;
