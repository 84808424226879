import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { find } from 'lodash';
import { Button, Modal } from 'react-bootstrap';

import ModalEditor from './ChatModalEditor';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import withPermissions from '~/hoc/withPermissions';
import LoadingSpinner from '~/elements//LoadingSpinner';
import Messenger from '~/components/Chat/Messenger';
import config from '~/library/config';
import DefaultUserIcon from '~/assets/img/faces/face-0.jpg';

@inject('chatStore', 'userStore', 'commonStore')
@withLocalization
@withPermissions
@withRouter
@observer
export default class Chat extends Component {
    state = {
        nowEditingId: null,
        nowEditingRoom: {},
        searchText: ''
    };

    loadData(_id) {
        const id = !_id ? null : parseInt(_id);
        // const privateRoomId = !_privateId ? null : parseInt(_privateId);
        const {
            chatStore, add, location, match
        } = this.props;
        if (!chatStore.allRooms) {
            chatStore.loadRooms();
        } else {
            this.props.chatStore.loading.rooms = false;
        }
        if (id) {
            chatStore.setCurrentTab(1); // to set projects as current tab
            chatStore.setCurrentRoomId(id);
            chatStore.loadRoom(id);
            // chatStore.loadMessages(id);
        } else {
            chatStore.setCurrentRoomId(null);
        }
    }

    loadPrivateData(_id) {
        const id = !_id ? null : parseInt(_id);
        const { chatStore } = this.props
        if (!chatStore.currentPrivateAllRooms) {
            chatStore.loadPrivateRooms();
        } else {
            this.props.chatStore.loading.rooms = false;
        }
        if (id) {
            chatStore.setCurrentTab(2); // set current tab as members
            chatStore.setCurrentPrivateRoomId(id);
            chatStore.loadPrivateRoom(id);
            // chatStore.loadMessages(id);
        } else {
            chatStore.setCurrentPrivateRoomId(null);
        }
        

    }

    componentWillMount() {
        // this.props.chatStore.loading.messages = true;

        // this.props.chatStore.loading.rooms = true;
        // this.props.chatStore.loading.room = true;
    }

    componentDidMount() {

        const { location,chatStore } = this.props;
        let checkURLPath = location.pathname.split("/")[2]

        if(checkURLPath === "privatechat")
            chatStore.setCurrentTab(2);
        if(checkURLPath === "chat")            
            chatStore.setCurrentTab(1);
        let id = location.pathname.replace('/admin/chat', '');
        let privateRoomId = location.pathname.replace('/admin/privatechat', '');
        
        if (id.startsWith('/')) id = id.replace('/', '');
        if (privateRoomId.startsWith('/')) privateRoomId = privateRoomId.replace('/', '');
        if (!id) id = null;
        if (!privateRoomId) privateRoomId = null;
        if (privateRoomId && parseInt(privateRoomId) > 0) {
            this.loadPrivateData(privateRoomId)
        } else {

            this.loadData(id); // tbd: last loaded ID
        }
    }

    handleChangeRoom = id => {
        this.props.chatStore.setCurrentRoomId(parseInt(id));
        this.props.history.push(`/admin/chat/${id}`);
        this.loadData(id);
    };

    handleChangePrivateRoom = id => {
        this.props.chatStore.setCurrentPrivateRoomId(parseInt(id));
        this.props.history.push(`/admin/privatechat/${id}`);
        this.loadPrivateData(id);
    };

    createPrivateRoom = (data) => {
        const { chatStore } = this.props;
        chatStore.setCurrentPrivateRoom(data)
        this.props.history.push(`/admin/privatechat/${data.id}`);
        this.loadPrivateData(data.id);
    }


    createPrivateRoomsearch = (room_name) => {
        const { chatStore } = this.props;
        const { currentPrivateAllRooms } = chatStore;
        const filterRoom = currentPrivateAllRooms.filter(data => data.name === room_name);
        chatStore.setCurrentPrivateRoomId(filterRoom[0].id)
        this.props.history.push(`/admin/privatechat/${filterRoom[0].id}`);
        this.loadPrivateData(filterRoom[0].id);
    }

    sendMessage = (text, data) => {
        try {
            this.props.chatStore.sendMessage({
                author: this.props.userStore.currentUser.id,
                room: this.props.chatStore.currentRoomId,
                message: text,
                data: data
            });
        } catch (e) {
            this.props.commonStore.addNotification(e.toString(), null, 'error');
        }
    };

    sendPrivateMessage = (text, data) => {
        try {
            this.props.chatStore.sendPrivateMessage({
                author: this.props.userStore.currentUser.id,
                room: this.props.chatStore.currentPrivateRoomId,
                message: text,
                data: data
            });
        } catch (e) {
            this.props.commonStore.addNotification(e.toString(), null, 'error');
        }
    }

    resolveUser = message => {
        const room = this.props.chatStore.currentRooms[this.props.chatStore.currentRoomId];
        const member = find(room.members, r => r.user_id == message.author_id);
        if (!member) return { fullname: '?', image: DefaultUserIcon };
        return {
            fullname: member.fullname,
            image: member.image ? `${config.UPLOADS_API_ENDPOINT}/${member.image}` : DefaultUserIcon,
        };
    };

    resolvePrivateUser = message => {
        const room = this.props.chatStore.currentPrivateRooms[this.props.chatStore.currentPrivateRoomId];
        const member = find(room.members, r => r.user_id == message.author);

        if (!member) return { fullname: '?', image: DefaultUserIcon };
        return {
            fullname: member.fullname,
            image: member.image ? `${config.UPLOADS_API_ENDPOINT}/${member.image}` : DefaultUserIcon,
        };
    };

    editRoom = async id => {
        if (!id) {
            this.setState({ nowEditingRoom: { room: {}, members: [] } });
            this.setState({ nowEditingId: -1 });
            return;
        }
        await this.props.chatStore.loadRoom(id);
        this.setState({ nowEditingRoom: this.props.chatStore.currentEntity });
        this.setState({ nowEditingId: id });
    };

    onEditorChange = property => {
        const s = this.state.nowEditingRoom;
        if (property.members) {
            Object.keys(property).forEach(key => {
                s[key] = property[key];
            });
        } else {
            Object.keys(property).forEach(key => {
                s.room[key] = property[key];
            });
        }
        this.setState({ nowEditingRoom: s });
    };

    saveChat = async () => {
        await this.props.chatStore.save(this.state.nowEditingRoom, this.state.nowEditingId === -1);
        this.setState({ nowEditingId: null });
        this.props.chatStore.loading.rooms = true;
        this.props.chatStore.allRooms = null;
        this.loadData(0);
    };

    onSearch(text) {
        const { chatStore } = this.props;
        const rooms = this.props.chatStore.currentPrivateAllRooms ? this.props.chatStore.currentPrivateAllRooms : [];
        return rooms;
    }

    render() {
        const {
            currentRooms, allRooms, currentRoomId, loading, unreads, currentPrivateRoomId, currentPrivateRooms, totalPrivateUnreads,
            currentPrivateAllRooms
        } = this.props.chatStore;
        const { chatStore } = this.props;
        const { privateUnreads } = chatStore;
        const { t, userStore } = this.props;
        const { nowEditingId, nowEditingRoom } = this.state;
        const { aclAllowed } = this.props;

        const allowMod = aclAllowed('chat-configure');


        if (
            loading.rooms
            || (currentRoomId && loading.room && currentPrivateRoomId) || (loading.privaterooms) // 
        ) {
            return <LoadingSpinner />;
        }
        const currentRoom = currentRoomId ? currentRooms[currentRoomId] : null;
        const currentPrivateRoom = currentPrivateRoomId ? currentPrivateRooms[currentPrivateRoomId] : null;
        return (
            <div className="main-content">
                <Modal size="md" show={!!nowEditingId} onHide={() => this.setState({ nowEditingId: null })}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Chat properties')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <ModalEditor entity={nowEditingRoom} onChange={property => this.onEditorChange(property)} />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ nowEditingId: null })}>
                            {t('Close')}
                        </Button>
                        <Button variant="primary" onClick={() => this.saveChat()}>
                            {t('Save changes')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div>
                    <Row>
                        <Col sm={4} md={4} lg={4}>
                        </Col>
                        <Col sm={8} md={8} lg={8}>
                            {(chatStore.chatCurrentTab == 2) &&
                                <div className="conversation-search search-members">
                                    <input type="text"
                                        className="conversation-search-input"
                                        placeholder={`${this.props.t('Search')} ${this.props.t('Members')}`}
                                        onChange={(e) => this.createPrivateRoomsearch(e.target.value)}

                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                { (text) => this.onSearch(text) }
                                            }
                                        }}
                                        list={'userlist'}
                                    />
                                    <datalist id={'userlist'}>
                                        {currentPrivateAllRooms && currentPrivateAllRooms.map(room => (
                                            <option key={room.id}>{room.name}</option>
                                        ))}
                                    </datalist>
                                    <i className='fa fa-search conversation-search-icon'
                                    />
                                </div>
                            }
                        </Col>
                    </Row>
                </div>
                <Messenger
                    rooms={allRooms}
                    privaterooms={currentPrivateAllRooms}
                    changeRoom={id => this.handleChangeRoom(id)}
                    changePrivateRoom={id => this.handleChangePrivateRoom(id)}
                    currentRoomId={currentRoomId}
                    currentRoom={currentRoom}
                    currentPrivateRoom={currentPrivateRoom}
                    currentPrivateRoomId={currentPrivateRoomId}
                    myId={userStore.currentUser.id}
                    unreads={unreads}
                    privateUnReads={privateUnreads}
                    sendMessage={(message, data) => this.sendMessage(message, data)}
                    sendPrivateMessage={(message, data) => this.sendPrivateMessage(message, data)}
                    t={t}
                    resolveUser={message => this.resolveUser(message)}
                    resolvePrivateUser={message => this.resolvePrivateUser(message)}
                    editRoom={id => this.editRoom(id)}
                    allowModification={false}
                    createPrivateRoom={data => this.createPrivateRoom(data)}
                />
            </div>
        );
    }
}
