import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../../hoc/withLocalization';
import { Rnd } from 'react-rnd';

import Select from '../../../../elements/Select';

@inject('propertyStore', 'signatureStore')
@withLocalization
@observer
export class SelectBoxWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            x: this.props.info.widgetX || 0,
            y: this.props.info.widgetY || 0,
            width: this.props.info.width || '190px',
            height: this.props.info.height || '40px',
            selected:this.props.info.data ? { value : this.props.info.data, label : this.props.info.data }  : ''        
        }
    }
    setActiveWidget(evt, widget) {
        const { propertyStore } = this.props;
        propertyStore.closeActivePanel();
        propertyStore.setActiveWidget(widget)

    }
    onDragStop = (evt, position, at) => {
        const { propertyStore, info } = this.props;
        const DEFAULT_WIDTH = 200;
        const DEFAULT_HEIGHT = 50;
        const updatedWidth = (propertyStore.activeWidget && propertyStore.activeWidget.width) ? propertyStore.activeWidget.width : ( this.state.width ? this.state.width : DEFAULT_WIDTH);
        const updatedHeight = (propertyStore.activeWidget && propertyStore.activeWidget.height) ? propertyStore.activeWidget.height : ( this.state.height ? this.state.height : DEFAULT_HEIGHT);
        const widInfo = { x: position.x, y: position.y,width:updatedWidth, height:updatedHeight  };
        propertyStore.updateWidgetRnd(info.widgetId, widInfo)
    }

    onResizeStop = (e, direction, ref, delta, position) => {
        const widInfo = { x: position.x, y: position.y, width: ref.style.width, height: ref.style.height }

        const { propertyStore, info } = this.props;
        propertyStore.updateWidgetRnd(info.widgetId, widInfo)
    }
    onSelectChange = (wid,data) =>{
        if(this.props.owner){
            const { propertyStore, signatureStore } = this.props;
            propertyStore.updateWidgetData(wid,'data',data.label);
            const selectData = { caseId: signatureStore.caseId, widgets: propertyStore.widgets };
           // propertyStore.saveWidget(selectData)
            this.setState({ selected: data })
        }  else {
            this.setState({ selected: data })
        }
       
    }
    hexToRgbA(hex) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',.4)';
        }
        throw new Error('Bad Hex');
    }
    prepareOptions(options) {
        const opts = options.map((opt) => {
            return { value: opt, label: opt }
        })
        return opts
    }
    render() {
        const { x, y, width, height } = this.state;
        const data = this.props.info.data;
        const options = this.prepareOptions(this.props.info.options);
        
        const widgetColor = this.hexToRgbA(this.props.info ? this.props.info.colorCode : "#ffd65b");
        return (
            <>
                <Rnd
                    minHeight={40}
                    minWidth={190}
                    default={{
                        x,
                        y,
                        width,
                        height
                    }}
                    onDragStop={this.onDragStop}
                    onResizeStop={this.onResizeStop}
                    bounds="parent"
                    disableDragging={this.props.locked}
                    enableResizing={!this.props.locked}

                >
                    <div className="SelectboxCon" onClick={(evt) => { this.setActiveWidget(evt, this.props.info) }}>
                        {this.props.edit &&
                            <Select
                                options={options}
                                value={this.state.selected}
                                placeholder={this.props.t("Select Options")}
                                style={this.props.owner ? {} : { backgroundColor: widgetColor }}
                                onChange={(data) => this.onSelectChange(this.props.info,data)}
                            >
                            </Select> 
                        }
                        {this.props.view && this.props.info.data &&
                           <input
                           className="input-sel"
                           value={data}
                           disabled={true} 
                           style={{border: 'none', overflow: 'hidden', background: 'transparent'}}                      />
                        }
                    </div>
                </Rnd>
            </>
        )

    }
}

export default SelectBoxWidget