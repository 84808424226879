// copy of userprofile, but without almost everything expcept file widget
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import queryString from 'query-string';
import { cloneDeep } from 'lodash';
import getSchema from '~/library/schemas/user';
import customerFeature from '~/utils/customerFeature';

const schema = null;

const isFilledDisease = (fieldName) => (f) => {
  return true;
}

const defaultUiSchema = {
  'ui:field': 'tabs',
  'ui:tabs': [{
    'ui:title':'Personal Info',
    'id':'tab1',
  'ui:field': 'layout',
  'ui:layout:hideframe': true,
  'ui:layout': [
    {
      image: { md: 4 },
      first_name: { md: 3 },
      last_name: { md: 3 },
      status: { md: 2 }
    }, {
      email: { md: 3 },
      phone: { md: 3 },
      social_number: { md: 3 },
      birthday: { md: 3 },
    },
    {
      'ui:subtitle': 'Assecces',
      'ui:className': 'profile-edit-form__group',
      internal_number: { md: 4 },
      username: { md: 4 },
      password: { md: 4 },
    },
    {
      'ui:subtitle': 'System settings',
      'ui:className': 'profile-edit-form__group',
      hourly_rate: { md: 4 },
    }, {
      'ui:subtitle': 'Location settings',
      'ui:className': 'profile-edit-form__group',
      address: { md: 4 },
      post_number: { md: 4 },
      post_place: { md: 4 }
    },
    {
      'ui:className': 'profile-edit-form__group',
      attachments: { md: 12 },
    }
  ],
  attachments: {
    'ui:widget': 'AttachmentsWidget',
    'ui:imageContext': {
      model: 'User',
      fileType: 'docs',
      id: 0
    }
  },
  status: {
    'ui:widget': 'ActiveInactive'
  },
  image: {
    'ui:widget': 'ImageUpload',
  },
  disable_autolog: {
    'ui:widget': 'TrueFalse'
  },
  data: {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:flex': true,
    'ui:layout': [{
      enable_pause_button: { className: 'col-md-4' },
      disable_manual_log: { className: 'col-md-4' },
    }],
    enable_pause_button: {
      classNames: 'no-fieldset-title',
      'ui:widget': 'CheckBoxWidget'
    },
    disable_manual_log: {
      classNames: 'no-fieldset-title',
      'ui:widget': 'CheckBoxWidget'
    }
  },
  ManagesUsers: {
    items: {
      id: {
        'ui:widget': 'MembersWidget'
      }
    }
  },
  MemberInProjects: {
    'ui:widget': 'MemberInProjectsWidget'
  }
  },
  {
    'ui:title':'Profile Settings',
    'ui:layout:hideframe': true,
    'id':'tab2',
    'ui:field': 'layout',
    'ui:layout': [
      {
        address: { md: 8 },
        post_number:{md:4},
        post_place:{md:4},
        gps_data: { md: 4 },
        home_payment_enabled: { md: 4 },
      },
      {
        employee_percent: {md:6},
        hourly_rate: {md:6},
      },
      {data: {md:12}}
    ],
    'home_payment_enabled': {
      classNames:'no-fieldset-title',
      'ui:widget': 'CheckBoxWidget',
    },
    'data': {
      classNames:'no-border-around-lists no-fieldset-title',
      'ui:layout:hideframe':true,
      "kids": {
        'ui:widget': 'hidden'
      },
      "nomines": {
        'ui:widget': 'hidden'
      },
      "holidays":{
        'ui:widget':'DaysOfWeek'
      },
      enable_pause_button: {
        classNames:'no-fieldset-title',
        'ui:widget': 'CheckBoxWidget'
      },
      disable_manual_log: {
        classNames:'no-fieldset-title',
        'ui:widget': 'CheckBoxWidget'
      }
    }
  },

  ],
  attachments: {
    'ui:widget': 'AttachmentsWidget',
    'ui:imageContext': {
      model: 'User',
      fileType: 'docs',
      id: 0
    }
  },
}

@inject('userStore', 'commonStore', 'taskStore')
@withRouter
@withLocalization
@observer
class Files extends Component {
    state = ({
        uiSchema: defaultUiSchema,
        schema: null,
        loading: true,
      })
      async loadData() {
        const {
          add, mode, history
        } = this.props;
        // const { loading, currentEntity, currentUser } = userStore
        await this.props.userStore.load(this.props.userStore.currentUser.id, add);
        await this.setState({
          schema: getSchema(
            {
              config: this.props.commonStore.config,
              isAdd: add,
              mode
            }
          )
        });
        const params = queryString.parse(history.location.search) || {};
        this.state.loading = false
        const { userStore } = this.props
        const { loading, currentEntity, currentUser } = userStore
        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, this.props.userStore.currentEntity.user, this.props.userStore.currentUser) });
        if (add) {
          if (mode !== 'superadmins') {
            await userStore.validateAddPossible();
          }
          return userStore.returnDefaultNew(mode, currentUser.client_id, params,currentUser);
        }
    }

  componentDidMount() {
    this.loadData();
  }

  componentWillMount(){
    this.props.userStore.loading = true;
  }


  componentDidUpdate(previousProps) {
    const { location } = this.props;
    if (location !== previousProps.location) {
      this.loadData();
    }
  }


  onSave(values) {
    const { userStore, history, add, t, commonStore } = this.props;
    return userStore.save(values, add).then(result => {
      if (!result.user || !result.user.id) {
        // some error
        commonStore.addNotification(t(result.message || 'Error'), null, 'error');
        return false  ;
        }
      commonStore.addNotification(t('Saved'), null, 'success');
      history.push(this.getListUrl());
      return true;
    })
  }

  prepareSchema(_schema, currentEntity, currentUser) {

    const mode = currentUser.user_type
    const clientConfig = this.props.commonStore.config.client.data;
    const autoTimelogsEnabled = clientConfig && clientConfig.loginRules ? 
                                clientConfig.loginRules.autoTimelogs == 'gps' : false;
    const schema = cloneDeep(_schema);
    // schema.properties.user_type.anyOf.push({
    //     title: 'Super Admin',
    //     enum: ['super-admin'],
    //   })
    schema['ui:tabs'][0].image = {
      'ui:widget': 'ImageUpload',
      'ui:imageContext':{
        'model':'User',
        'fileType':'logo',
        'id': currentEntity ? currentEntity.id : 0
      }
    }
    schema['ui:tabs'][0].attachments = {
      'ui:widget': 'AttachmentsWidget',
      'ui:imageContext': {
        'model': 'User',
        'fileType': 'docs',
        'id': currentEntity ? currentEntity.id : 0,
        'existingAttachment': (currentEntity && currentEntity.Attachments) ? currentEntity.Attachments : []
      }
    };
    if (mode == 'members') { //  && mode != 'profile'
      const accessObj = {
        'ui:subtitle': 'Assecces',
        'ui:className': 'profile-edit-form__group',
      };
      schema['ui:layout'][3].timelog_start_from = { md: 4 };
      schema['ui:layout'][3].employee_percent = { md: 4 };
      accessObj.username = { md: 4 };
      accessObj.user_type = { md: 4 };
      accessObj.internal_number = { md: 4 };
      accessObj.password = { md: 4 };

      if (currentUser.role !== 'member' && autoTimelogsEnabled) { //  && mode != 'profile'
        schema['ui:layout'][3].disable_autolog = { md: 4 };
      }
      if (customerFeature('locked_mode')) { //  && mode != 'profile'
        accessObj.generic_pin = { md: 4 };
      }
      if (currentUser.role !== 'member') { //  && mode != 'profile'
        schema['ui:layout'][3].MemberInProjects = { md: 4 };
      }
      schema['ui:layout'][2] = accessObj;
    }
    if (mode == 'managers') {
      schema['ui:layout'].push({
        ManagesUsers: { md: 12 }
      });
    }
    if (customerFeature('locked_mode')) { //  && mode != 'profile'
      schema['ui:tabs'][0]['ui:layout'][2].generic_pin = {className:'col-md-6'};
    }

    const hardcodedSchema = {
      'ui:field': 'tabs',
      'ui:tabs': [{
        'ui:title':'Personal Info',
        'id':'tab1',
      'ui:field': 'layout',
      'ui:layout:hideframe': true,
      'ui:layout': [
        {
          'ui:className': 'profile-edit-form__group',
          attachments: { md: 12 },
        }
      ],
      attachments: {
        'ui:widget': 'AttachmentsWidget',
        'ui:imageContext': {
          model: 'User',
          fileType: 'docs',
          id: 0
        }
      },
      }

      ],
      attachments: {
        'ui:widget': 'AttachmentsWidget',
        'ui:imageContext': {
          model: 'User',
          fileType: 'docs',
          id: 0
        }
      },
    }

    hardcodedSchema['ui:tabs'][0].attachments = {
      'ui:widget': 'AttachmentsWidget',
      'ui:imageContext': {
        'model': 'User',
        'fileType': 'docs',
        'id': currentEntity ? currentEntity.id : 0,
        'existingAttachment': (currentEntity && currentEntity.Attachments) ? currentEntity.Attachments : []
      }
    };

    return hardcodedSchema;
  }

  getListUrl() {
    return '/admin/files';
  }

  async recalcGps(e) {
    return this.props.taskStore.recalcGpsForTask(e, true);
  }


  render() {
    const { userStore, add } = this.props;
    const { loading, currentEntity } = userStore;
    if (this.state.loading) {
      return (
        <LoadingSpinner />
      );
    }
     const { uiSchema, schema } = this.state
    const uiSchemaReady = this.prepareSchema(defaultUiSchema, currentEntity.user, userStore.currentUser)
    return (
      <div className="primary-page profile-edit-form">
        <GenericForm
          entity={currentEntity.user}
          uiSchema={uiSchemaReady}
          schema={schema}
          translationScope="forms.user"
          onSave={values => this.onSave(values)}
          recomputeFields={['gps_data', 'data.addressCache']}
          forceRefresh={!!currentEntity.user.gps_data}
          onChange={async data => this.recalcGps(data)}
          isAdding={false}
        />
      </div>
    );
  }
}

export default Files;
