import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import RecipientsList from './RecipientsList';
import RecipientsForm from './RecipientsForm';

import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';

// import { DndProvider } from "react-dnd";
// import  HTML5Backend  from "react-dnd-html5-backend";
import { DragDropContext } from 'react-beautiful-dnd';

@inject('authStore', 'userStore', 'commonStore',)
@withRouter
@withLocalization
@withRemoveDialog
@observer
class Recipients extends Component {
    onAdd() {
        const { history } = this.props;
        history.push('/admin/recipients/add');
    }

    onTableAction(id, type) {
        const { history, authStore } = this.props;
        if (type === 'remove') {
            return this.props.handleDelete(id, id => {
                return this.props.userStore.remove(id).then(res => {
                    this.props.userStore.resetLastListLoadTime();
                    return res;
                });
            });
        }
        history.push(`/admin/users/${id}`);
    }

    editingId = () => {
        let id = null;
        if (this.props.location.pathname !== this.props.match.path) {
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }
        return {
            editing: !!id,
            add: id === 'add',
            importing: id === 'import',
            id,
        };
    };

    render() {
        const mode = this.editingId();
        const { userStore } = this.props;
        const userType = userStore.currentUser.user_type;
        return (
            <div className="main-content">
                {!mode.editing && (
                    <RecipientsList
                            onTableAction={(id, type) => this.onTableAction(id, type)}
                            mode="users"
                            onAdd={() => this.onAdd()}
                        />
                    
                )}
                {mode.editing && !mode.importing && <RecipientsForm id={mode.id} add={mode.add} mode="users" />}
            </div>
        );
    }
}
export default Recipients;
