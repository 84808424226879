import { observable, action } from 'mobx';

// import getBase64 from '~/utils/getBase64';
import agent from '../agent';

class DashboardStore {
    @observable currentData = [];

    @observable todayAtWork = []

    @observable nextShifts = [];

    @observable loading = true;

    @observable tableLoading = false;

    @action load(params) {
        this.loading = true;
        return agent.Dashboard.get(params)
            .then(
                action(list => {
                    // this.requestParams = params;
                    this.currentData = list;
                    this.todayAtWork = list && list.todayAtWork && list.todayAtWork.shifts || [];
                    this.loading = false;
                    return list;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }
    @action getDashboardSchedules(params) {
        this.tableLoading = true;
        return agent.Dashboard.getSchedules(params)
            .then(
                action(list => {
                    const shifts = list.shifts || [];
                    this.nextShifts = shifts;
                    this.todayAtWork.push(...shifts);
                    this.tableLoading = false;
                    return shifts;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    @action handleShowLess(){
        this.todayAtWork = this.todayAtWork.slice(0,6);
    } 
}

export default new DashboardStore();
