import React from 'react';
import { observer } from 'mobx-react';
import CSVReader from 'react-csv-reader';

// import ColumnsChooser from './ColumnsChooser';

@observer
export default class UploadCSV extends React.Component {
    state = {};

    parseData(data) {
        const cols = data[0];
        data.splice(0, 1);
        const rows = data;
        return { cols, rows };
    }

    handleUpload(data) {
        const { saveStatus } = this.props;
        saveStatus('csvData', this.parseData(data));
    }

    handleResetUpload() {
        const { saveStatus } = this.props;
        saveStatus('csvData', null);
    }

    render() {
        const { campaign, zips } = this.props;
        const { csvData } = campaign;
        return (
            <div>
                {!csvData && (
                    <div>
                        <h2>Upload CSV</h2>
                        <CSVReader
                            cssClass="react-csv-input"
                            label="Select CSV with providers"
                            onFileLoaded={data => this.handleUpload(data)}
                        />
                    </div>
                )}
                {csvData && (
                    <div>
                        File with {csvData.rows.length} rows found. <br />
                        <a className="btn" onClick={() => this.handleResetUpload()}>
                            Re-upload
                        </a>
                    </div>
                )}
            </div>
        );
    }
}
