import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Modal,OverlayTrigger, Tooltip } from 'react-bootstrap';

import Button from '../../../components/CustomButton/CustomButton.jsx';

import ModalEditor from './ProjectMembersForm';
import TaskModalEditor from './ProjectTaskCloneForm';
import ProjectListFilter from './ProjectListFilter/ProjectListFilter';
import { shortDateFormat, totalTimeFormat } from '~/utils/dateFormat'
import withLocalization from '~/hoc/withLocalization';
import withPermissions from '~/hoc/withPermissions';
import GenericList from '~/components/GenericList/GenericList';
import { serverTimeFormat } from '~/library/core';
// Elements

import { minutesToTimeInput } from '~/utils/timeCalc';

@inject('projectStore', 'userStore','commonStore')
@withRouter
@withPermissions
@withLocalization
@observer
class ProjectsList extends Component {
    state = {
        nowEditingId: null,
        modalName:"",
    };

    handleTableButtonAction = (id, type) => {
        if(type == 'clone')
        {
            return this.startEdit(id,'tasks')
        }
        this.props.onTableAction(id, type);
    };

    startEdit = (id,type) => {
        if (this.props.userStore.currentUser.user_type == 'member') return;
        // console.log('ID', id);
        this.setState({ nowEditingId: id, modalName:type });
    };

    renderTableActionButtons = (id, aclAllowed, t) => (        
        <div className="actions-center justify-content-center">            
            {aclAllowed('project-edit') && (
                <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                    <i className="fa fa-edit" />
                </Button>
            )}
            {aclAllowed('project-delete') && (
                <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                    <i className="fa fa-times" />
                </Button>
            )}
            <OverlayTrigger
                key="clone_task"
                placement="top"
                overlay={
                    <Tooltip id="tooltip-top">
                        {t("Clone Task")}
                    </Tooltip>
                }
            >
                <Button onClick={() => this.handleTableButtonAction(id, 'clone')} bsStyle="default" simple icon>
                    <i className="fa fa-clone" />
                </Button>
            </OverlayTrigger>
            <Button onClick={() => this.handleTableButtonAction(id, 'view')} bsStyle="default" simple icon>
                <i className="fa fa-align-left" />
            </Button>
        </div>
    );


    afterChangeMembers() {        
        this.props.projectStore.setFilter('reloadTime', new Date().toString());
        this.props.projectStore.onFilter();
        this.props.projectStore.resetLastListLoadTime();
        this.setState({ nowEditingId: null, modalName:'' });
    }

    render() {
        const { loading, filters, appliedFilters } = this.props.projectStore;
        const { aclAllowed, t, userStore } = this.props;
        const { nowEditingId, modalName } = this.state;
        const {config} = this.props.commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        return (
            <Fragment>
                <Modal
                    size="lg"
                    dialogClassName="custom-big-modal"
                    show={!!nowEditingId && modalName=='assignees'}
                    onHide={() => this.setState({ nowEditingId: null, modalName:'' })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Project members')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <ModalEditor id={nowEditingId} afterSave={() => this.afterChangeMembers()} />
                    </Modal.Body>
                </Modal>

                <Modal
                    size="sm"
                    show={!!nowEditingId && modalName=='tasks'}
                    onHide={() => this.setState({ nowEditingId: null, modalName:'' })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Clone task to project')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                            <TaskModalEditor id={nowEditingId} afterSave={() => this.setState({ nowEditingId: null, modalName:'' })}/>
                    </Modal.Body>
                </Modal>



                <div className="table-list">
                    <div className="table-list__header">
                        {aclAllowed('project-add') && (
                            <Button fill wd icon onClick={() => this.props.onAdd()}>
                                {this.props.t('Add new')} <i className="fa fa-plus" />
                            </Button>
                        )}
                    </div>
                    <GenericList
                        columns={[
                            {
                                Header: 'Employer / Project',
                                id: 'name',
                                accessor: ({ id, name }) => (
                                    <a
                                        className="list-main-column"
                                        onClick={() => this.handleTableButtonAction(id, 'edit')}
                                    >
                                        {name}
                                    </a>
                                ),
                            },
                            {
                                Header: 'Last Updated',
                                id: 'updated_at',
                                accessor: ({ updated_at }) => updated_at && shortDateFormat(updated_at, dateTimeRules),
                            },
                            {
                                Header: 'Location',
                                id: 'address',
                                accessor: ({ address, post_place, post_number }) =>
                                    `${address ? `${address} ` : ''}${post_place ? `${post_place} ` : ''}${
                                        post_number ? `${post_number} ` : ''
                                    }`,
                            },
                            {
                                Header: 'Total Members',
                                id: 'members_amount',
                                sortable: false,
                                accessor: ({ members_amount, id }) => (
                                    <span>
                                        <div className="badge badge-green">{members_amount}</div>
                                        {userStore.currentUser.user_type != 'member' && (
                                            <Button
                                                style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 10 }}
                                                onClick={() => this.startEdit(id,'assignees')}
                                            >
                                                {t('Assign')}
                                            </Button>
                                        )}
                                    </span>
                                ),
                            },
                            {
                                Header: 'Total Hours',
                                id: 'total_hours',
                                sortable: false,
                                accessor: ({ total_hours }) => `${totalTimeFormat(total_hours, dateTimeRules )}`,
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                sortable: false,
                                accessor: ({ id }) => this.renderTableActionButtons(id, aclAllowed, t),
                            },
                        ]}
                        forceReload={this.props.projectStore.deleteSuccess}
                        filters={appliedFilters}
                        lastListLoadTime={this.props.projectStore.lastListLoadTime}
                        header={
                            <ProjectListFilter
                                filters={filters}
                                setFilter={(name, value) => this.props.projectStore.setFilter(name, value)}
                                onFilter={() => this.props.projectStore.onFilter()}
                                loading={loading}
                            />
                        }
                        requestData={(params) => this.props.projectStore.loadList(params)}
                    />
                </div>
            </Fragment>
        );
    }
}
export default ProjectsList;
