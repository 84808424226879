import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { inject, observer } from 'mobx-react';
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import moment from 'moment/min/moment-with-locales';



@inject('commonStore', 'timelogStore')
@withLocalization
@observer
export class TipListFilter extends Component {


    setFilterDate(name, name1, value) {
        this.props.timelogStore.setTipFilter(name1, value);
        this.props.timelogStore.setTipFilter(name, moment(new Date(value)).format('YYYY-MM-DD'));
    }
    render() {
        

        const { tip_Filter, loading, onTipFilter } = this.props.timelogStore;
        const { name, fromDate1, toDate1 } = tip_Filter;
        const { t } = this.props

        return (

            <div className="list-filters">

                <div className="list-filters__item list-filters__item_grow">
                    <FormControl
                        placeholder={t('Search')}
                        type="text"
                        onChange={(e) => { this.props.timelogStore.setTipFilter('name', e.target.value)}}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onTipFilter();
                            }
                        }}
                        value={name}
                    />
                </div>
                <div className="list-filters__item">
                    <span className="smaller-wrapper">
                        <DatePicker
                            selected={fromDate1}
                            className="smaller"
                            onChange={(date) => this.setFilterDate('fromDate', 'fromDate1', date)}
                            dateFormat="MMMM dd"
                            placeholder={t('From')}
                        // style={{ width: '150px' }}
                        />
                    </span>
                </div>
                <div className="list-filters__item">
                    <DatePicker
                        selected={toDate1}
                        className="smaller"
                        onChange={(date) => this.setFilterDate('toDate', 'toDate1', date)}
                        dateFormat="MMMM dd"
                        placeholder={t('To')}
                    // style={{ width: '150px' }}
                    />
                </div>
                <div className="list-filters__item list-filters__item_to-right">
                    <Button fill wd onClick={() => this.props.onTipFilter()} disabled={loading}>
                        {t('Filter')}
                    </Button>
                </div>
            </div>
        )
    }
}

export default TipListFilter