import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../hoc/withLocalization';
import { ReactComponent as ExpiredIcon } from '../../../assets/img/Expired-icon.svg';
import { Button } from 'react-bootstrap';
@inject('signatureStore')
@withLocalization
@observer
export class DocExpire extends Component {
    componentDidMount() {
        const { signatureStore } = this.props;
        signatureStore.openFullScreenMode();
    }
    BacktoHome() {
        const { history } = this.props;
        history.push('/admin');
    }
    render() {
        const {t} = this.props;
        return (
            <div className="successPage">
                <ExpiredIcon />
                <h1>{t('Your document has been expired')}</h1>
                <p>{t('Please contact your administrator.')}</p>
                <Button className="btnSignNow" variant="primary" size="lg"  onClick={() => this.BacktoHome('')}>
                    {t('Back to home')}
                </Button>
            </div>
        );
    }
}

export default DocExpire;
