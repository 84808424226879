import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import '../../../styles/css/styles.css';

// Components
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import CustomCheckBox from '../../../components/CustomCheckbox/CustomCheckbox';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { NumberInput } from '../../../elements/Input';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import Radio from '../../../components/CustomRadio/CustomRadio.jsx';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import moment from 'moment/min/moment-with-locales';
import { ChecklistWidget } from '../../../components/GenericForm/Widgets/ChecklistWidget';
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import customerFeature from '~/utils/customerFeature';
import Select from '../../../elements/Select';
import TrueFalseWidget from '../../../components/GenericForm/Widgets/TrueFalseWidget';
import checklistStore from '../../../library/stores/checklistStore';

const cornjob_typeOptions = [{ label: 'Everyday', value: 'Everyday', title: 'Everyday' }, 
{ label: 'Weekdays', value: 'Weekdays', title: 'Weekdays' },
{ label: 'Gap Between Days', value: 'Gap Between Days', title: 'Gap Between Days' },
{ label: 'Monthly', value: 'Monthly', title: 'Monthly' }, 
{ label: 'Half Yearly', value: 'Half Yearly', title: 'Half Yearly' },
{ label: 'Annually', value: 'Annually', title: 'Annually' }, 
{ label: 'None', value: 'None', title: 'None' }]

const WeekDaysOptions = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

@inject('checklistStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ChecklistTemplateAssign extends Component {
    state = {
        checklist: {},
        showErrors: false,
        isProjectMembers: false,
        projectMembers: [],
        selectedMember: '',
        all:false,
        selected_date:null
    }

    loadData = async () => {
        const { checklistStore, id, add } = this.props;
        checklistStore.updateIsAllChecklistShow(false);
        const { userStore: { currentUser } } = this.props;
        let user_type = currentUser.user_type;
        let user_id = currentUser.id;

        if (add) {
            checklistStore.returnDefaultNew();
            if (user_type == 'member') {
                this.handleChange('user_id', user_id);
            }
            return;
        } else {
            checklistStore.updateIsAllChecklistShow(true);
            this.setState({all: true});
        }
        if (user_type == 'member') {
            this.handleChange('user_id', user_id);
        }

        await checklistStore.load(id, add).then(
            (response) => {
                this.loadProjectMembers(checklistStore.currentChecklist.project_id)
            }
        );
    }

    componentWillMount() {
        this.props.checklistStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    handleChange = (name, value) => {
        this.props.checklistStore.handleChange(name, value);
        if (name === "cornjob_type" && (value == "Monthly" || value == "Half Yearly" || value == "Annually")) {
            
            if (value == "Monthly") {
                value = 30;
            } else if (value == "Half Yearly") {
                value = 182;
            } else if (value == "Annually") {
                value = 365;
            }
            this.props.checklistStore.handleChange('cornjob_duration', value);
            const getDate = moment(new Date()).add(value,"days").toDate();
            this.setState({selected_date:getDate});
        }
        if(name === "cornjob_duration"){
            const getDate = moment(new Date()).add(value,"days").toDate();
            this.setState({selected_date:getDate});
        }
        //load members according to project in select input//
        if (name === "project_id") {
            this.loadProjectMembers(value)
        }
    };

    //handles change in select input//
    handleSelect = (member, type) => {
        this.setState({ selectedMember: member })
        if (type === 'change') {
            this.handleChange('user_id', member.value)
        }

    }

    //loads projectMemberOptions in select according to projectId//
    loadProjectMembers = async (id) => {
        const { userStore, add, checklistStore } = this.props;
        await userStore.loadByProjectId(id).then(
            (data) => {
                const result = data ? data.map(element => {
                    return { value: `${element.id}`, label: `${element.fullname}` }
                })
                    : [];
                this.setState({ projectMembers: result }, () => {
                    if (!add) {
                        let member = result.filter(item => item.value == checklistStore.currentChecklist.user_id)
                        this.handleSelect(member, 'load');
                    }
                    this.setState({ isProjectMembers: true })
                })
            }
        )
    }

    handleUserChange = (value, full) => {
        this.handleChange('user_id', value);
    };

    // parseCronJobDays = () => {
    //     const { currentChecklist } = this.props.checklistStore
    //     let idsArray = []
    //     if (currentChecklist.cornjob_duration != null && currentChecklist.cornjob_duration != "") {
    //         idsArray = currentChecklist.cornjob_duration.split(",").map(function (item) {
    //             return parseInt(item, 10);
    //         });
    //     }
    //     return idsArray;
    // }

    handleCornjobDurationChange(day, value) {
        const { currentChecklist } = this.props.checklistStore;
        let weekDayIds = [];
        if(currentChecklist.cornjob_duration != "" && isNaN(currentChecklist.cornjob_duration))
            weekDayIds = currentChecklist.cornjob_duration.split(",");
        if (value) {
            if (!weekDayIds.includes(day))
                weekDayIds.push(day);
        }
        else {
            if (weekDayIds.includes(day))
                weekDayIds.splice(weekDayIds.indexOf(day), 1);
        }
        this.handleChange("cornjob_duration", weekDayIds.toString())
    }

    render() {
        const { config, t, isUnionMode, userStore: { currentUser }, userStore } = this.props;
        const { loading, currentChecklist: checklist, isAllChecklistShow } = this.props.checklistStore;
        const { showErrors, projectMembers, isProjectMembers, selectedMember } = this.state;
        // let reportedUsername = `${currentUser.first_name} ${currentUser.last_name}`;
        const user_type = currentUser.user_type;
        return (
            <div className="page" style={{ padding: 0, margin: 0 }}>
                <div className="page__content">
                    <form className="custom-form">
                        <div className="custom-form__row">
                            <div className="col-md-6">
                                <label className="custom-form__col">
                                    <ProjectsWidget
                                        label={`${t('Project')} *`}
                                        value={checklist.project_id}
                                        // _module={'checklist_templates'}
                                        allowAll={false}                                        
                                        onChange={value => {
                                            this.handleChange('project_id', value);
                                        }}
                                        error={showErrors && !checklist.project_id}
                                    />
                                </label>
                                <label className="custom-form__col" style={{paddingTop:'0px', paddingBottom: '0px'}}>
                                    {/* <TrueFalseWidget
                                        value={this.state.all}
                                        onChange={value => {
                                            this.setState({"all":value})
                                            console.log("value:", value)
                                            this.handleChange('all', value);
                                        }}
                                    /> */}
                                    <CustomCheckBox
                                        label={`${t('Show all available checklists')}`}
                                        disabled={!checklist.project_id}
                                        checked={this.state.all}
                                        onChange={value => {
                                            checklistStore.updateIsAllChecklistShow(value);
                                            this.setState({ "all": value })
                                            this.handleChange('all', isAllChecklistShow);
                                        }}
                                        />
                                    {/* <div style={{paddingLeft: '5px'}}>Select from all checklist</div> */}
                                </label>
                                <div className="custom-form__col">
                                    <ChecklistWidget
                                        placeholder={t('Select Checklist')}
                                        label={`${t('Checklist')} *`}
                                        value={checklist.checklist_templates_id}
                                        filterByProject={checklist.project_id}
                                        defaultIfOne
                                        disabled={!checklist.project_id}
                                        onChange={value => {
                                            this.handleChange('checklist_templates_id', value);
                                        }}
                                        all={isAllChecklistShow}
                                    />
                                </div>
                                <div className="custom-form__row">
                                    {/* <div className="custom-form__col">
                                <div className="read-area">
                                    <span className="read-area__label">{t('Reported by')}</span>
                                    <div className="read-area__content">{reportedUsername}</div>
                                </div>
                            </div> */}
                                    {user_type != 'member' && (
                                        <div className="custom-form__col">
                                            <div className="form-group field field-string">
                                                <label className="control-label schedule-text" style={{color:'black'}} ><strong>{t('Select Member (If you want to assign the checklist for selected member)')} *</strong></label>
                                                {(
                                                    // <MembersWidget
                                                    //     className="form-control"
                                                    //     onChange={value => {
                                                    //         this.handleChange('user_id', value);
                                                    //     }}
                                                    //     value={checklist.user_id}
                                                    //     defaultIfOne
                                                    // />
                                                    <Select
                                                        options={projectMembers}
                                                        value={selectedMember}
                                                        //isDisabled={!isProjectMembers}
                                                        onChange={member => this.handleSelect(member, 'change')}
                                                        placeholder='Select...'
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="custom-form__row">
                                    <div className="custom-form__col">
                                        <CustomCheckBox
                                            label={`${t('Obligatory')}`}
                                            checked={checklist.isobligatory}
                                            onChange={evt => { this.handleChange('isobligatory', evt) }}
                                        />
                                    </div>
                                    <div className="custom-form__col">
                                        <CustomCheckBox
                                            label={`${t('Send Mail')}`}
                                            checked={checklist.isSendEmail}
                                            onChange={evt => { this.handleChange('isSendEmail', evt) }}
                                        />
                                    </div>
                                    <div className="custom-form__col">
                                        <CustomCheckBox
                                            label={`${t('Allow Check all')}`}
                                            checked={checklist.allow_check_all}
                                            onChange={evt => { this.handleChange('allow_check_all', evt) }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 schedule">

                                <p className="schedule-header">{t('Schedule')}</p>
                                <p className="schedule-text">{t('By selecting below options your checklist will be created')}</p>

                                <div className="schedule-options">
                                    {cornjob_typeOptions.map(radio => (
                                        <div style={{ marginRight: '1rem' }} key={radio.value}>
                                            <Radio
                                                key={radio.value}
                                                name={radio.label}
                                                value={radio.value}
                                                label={t(radio.label)}
                                                checked={checklist.cornjob_type === radio.value}
                                                onChange={() => {
                                                    if (radio.value === "Everyday" || radio.value === "Weekdays") {
                                                        this.handleChange("cornjob_type", radio.value);
                                                        this.handleChange("cornjob_duration", "");
                                                    }
                                                    else
                                                        this.handleChange("cornjob_type", radio.value);
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                                {checklist.cornjob_type === "Weekdays" &&
                                    <div className="schedule-options">
                                        {WeekDaysOptions.map((day, index) => (
                                            <div style={{ marginRight: '1rem' }} key={index}>
                                                <CustomCheckBox
                                                    label={`${t(day)}`}
                                                    className="custom-form__col"
                                                    checked={checklist.cornjob_duration && checklist.cornjob_duration.split(',').some(item => item === day)}
                                                    onChange={evt => this.handleCornjobDurationChange(day, evt)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                }
                                {checklist.cornjob_type === "Gap Between Days" &&
                                    <>
                                    <div className="custom-form__row">
                                        <NumberInput
                                            className="custom-form__col"
                                            label={`${t('Gap Between Days')} *`}
                                            placeholder={t('Enter Gap')}
                                            name="cornjob_duration"
                                            value={checklist.cornjob_duration}
                                            onChange={evt => {
                                                if((evt.target.value>0 && evt.target.value<366 )||evt.target.value ==="")
                                                    this.handleChange("cornjob_duration", evt.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="custom-form__row">
                                        <div style={{padding:'10px',width:'70%'}}>
                                            <DatePicker 
                                            selected={this.state.selected_date} 
                                            style={{width:'10px'}}
                                            dateFormat="EEEE MMMM dd, yyyy"
                                            disabled />
                                        </div>
                                    </div>
                                    </>
                                }
                                {checklist.cornjob_type === "Monthly" &&
                                    <>
                                    <div className="custom-form__row">
                                        <NumberInput
                                            className="custom-form__col"
                                            label={`${t('Days')} *`}
                                            placeholder={t('Enter Days')}
                                            name="cornjob_duration"
                                            value={checklist.cornjob_duration}
                                            onChange={evt => {
                                                if((evt.target.value>0 && evt.target.value<32 )||evt.target.value ==="")
                                                    this.handleChange("cornjob_duration", evt.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="custom-form__row">
                                        <div style={{padding:'10px',width:'70%'}}>
                                            <DatePicker 
                                            selected={this.state.selected_date} 
                                            style={{width:'10px'}}
                                            dateFormat="EEEE MMMM dd, yyyy"
                                            disabled />
                                        </div>
                                    </div>
                                    </>
                                }
                                {checklist.cornjob_type === "Half Yearly" &&
                                    <>
                                    <div className="custom-form__row">
                                        <NumberInput
                                            className="custom-form__col"
                                            label={`${t('Days')} *`}
                                            placeholder={t('Enter Days')}
                                            name="cornjob_duration"
                                            value={checklist.cornjob_duration}
                                            onChange={evt => {
                                                if((evt.target.value>0 && evt.target.value<183 )||evt.target.value ==="")
                                                    this.handleChange("cornjob_duration", evt.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="custom-form__row">
                                        <div style={{padding:'10px',width:'70%'}}>
                                            <DatePicker 
                                            selected={this.state.selected_date} 
                                            style={{width:'10px'}}
                                            dateFormat="EEEE MMMM dd, yyyy"
                                            disabled />
                                        </div>
                                    </div>
                                    </>
                                }
                                {checklist.cornjob_type === "Annually" &&
                                    <>
                                    <div className="custom-form__row">
                                        <NumberInput
                                            className="custom-form__col"
                                            label={`${t('Days')} *`}
                                            placeholder={t('Enter Days')}
                                            name="cornjob_duration"
                                            value={checklist.cornjob_duration}
                                            onChange={evt => {
                                                if((evt.target.value>0 && evt.target.value<366 )||evt.target.value ==="")
                                                    this.handleChange("cornjob_duration", evt.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="custom-form__row">
                                        <div style={{padding:'10px',width:'70%'}}>
                                            <DatePicker 
                                            selected={this.state.selected_date} 
                                            style={{width:'10px'}}
                                            dateFormat="EEEE MMMM dd, yyyy"
                                            disabled />
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>

                        {/* <div className="custom-form__row center-block">                            
                            <div className="custom-form__row" style={{ margin: 10 }}>
                                <Radio
                                    label={t('Assign To Member')}
                                    checked={!checklist.to_project}
                                    //disabled={!this.props.add}
                                    onChange={() => this.handleChange('to_project', !checklist.to_project)} />
                            </div>
                            <div className="custom-form__row" style={{ margin: 10 }}>
                                <OverlayTrigger
                                    key="top"
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            {t("Checklist Will Be Availble For The Assigned Members Of The Project")}
                                        </Tooltip>
                                    }
                                >
                                    <Radio
                                        label={t('Assign To Project')}
                                        //disabled={!this.props.add}
                                        checked={checklist.to_project}
                                        onChange={() => this.handleChange('to_project', !checklist.to_project)} />
                                </OverlayTrigger>
                            </div>
                        </div> */}

                        {/* {checklist.to_project === false ? ( */}
                        {/* ) : null} */}

                        <div className="custom-form__row">
                            {user_type == 'member' && (
                                <div className="custom-form__col">
                                    <div className="read-area">
                                        <span className="read-area__label">{t('Member')}</span>
                                        <div className="read-area__content">{userStore.currentUser.first_name} {userStore.currentUser.last_name}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default ChecklistTemplateAssign;
