import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import Radio from '../../../../components/CustomRadio/CustomRadio.jsx';
import Checkbox from '../../../../components/CustomCheckbox/CustomCheckbox.jsx';
import Select from '../../../../elements/Select.js';
import Input, { NumberInput } from '../../../../elements/Input.js';
import TableWidget from '../TableWidget.js';
// title: 'Hours in excess of nine hours in the course of 24 hours and 40 hours in the course of seven days',
// enum: ['government'],

const calc_periodOption = [{ value: 'none', label: 'None', title: 'None' },
{ value: 'day', label: 'Day', title: 'Day' }, { value: 'week', label: 'Week', title: 'Week' },
{ value: 'month', label: 'Month', title: 'Month' },{
  value:'government',
  label:'Hours in excess of nine hours in the course of 24 hours and 40 hours in the course of seven days',
  title:'Hours in excess of nine hours in the course of 24 hours and 40 hours in the course of seven days'
}]

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class OverTimeCalculation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            overtimeAutomaticMode: false,
            overtimeCalcRules: {
                calc_percent: 0,
                calc_period: 'none',
                max_hours: 0,
                max_minutes: 0,
                weekend_days: "",
                weekend_percent: 0,
            },
            overtime_types: [
            ],
            defaultDays: [{ day: 'Mon', checked: false },
            { day: 'Tue', checked: false },
            { day: 'Wed', checked: false },
            { day: 'Thu', checked: false },
            { day: 'Fri', checked: false },
            { day: 'Sat', checked: false },
            { day: 'Sun', checked: false }],
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.loadData = this.loadData.bind(this);
        this.translateLables = this.translateLables.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            const basicRules = Object.assign({}, currentEntity.client.data.basicRules);
            if (basicRules.overtimeCalcRules) {
                this.setState({
                    overtimeAutomaticMode: basicRules.overtimeAutomaticMode,
                    overtimeCalcRules: basicRules.overtimeCalcRules,
                    overtime_types: basicRules.overtime_types
                },
                    () => { this.updatedWeekDays() })
            }
            else {
                this.setState({
                    overtime_types: basicRules.overtime_types
                },
                    () => { this.updatedWeekDays() })
            }
        }
        else {
            this.updatedWeekDays();
        }
    }

    translateLables() {
        const { t } = this.props;
        calc_periodOption.map(item => {
            item.label = t(item.title)
        })
    }

    handleChange = (name, value) => {
        const { overtimeCalcRules } = this.state;
        overtimeCalcRules[name] = value;
        this.props.handleUserDataChange('overtimeCalcRules', overtimeCalcRules);
        this.setState({ overtimeCalcRules });        
    }

    handleCheck = (index, value) => {
        const { overtimeCalcRules } = this.state;
        let idsArray = [];
        idsArray = this.parseWeekDays();
        if (value == true) {
            idsArray.push(index);
        } else {
            if (overtimeCalcRules.weekend_days.includes(index)) {
                idsArray = idsArray.filter(function (item) {
                    return parseInt(item) !== index
                })
            }
        }

        overtimeCalcRules.weekend_days = idsArray.toString();
        this.props.handleUserDataChange('overtimeCalcRules', overtimeCalcRules);
        this.setState({ overtimeCalcRules }, () => {
            this.updatedWeekDays();
        });
    }

    //maps and parse days from weekdays//
    parseWeekDays = () => {
        const { overtimeCalcRules } = this.state;
        let idsArray = []
        if (overtimeCalcRules.weekend_days != null && overtimeCalcRules.weekend_days != "") {
            idsArray = overtimeCalcRules.weekend_days.split(",").map(function (item) {
                return parseInt(item, 10);
            });
        }
        return idsArray;
    }

    updatedWeekDays = () => {
        let idsArray = this.parseWeekDays();
        let updatedDays = this.state.defaultDays;
        updatedDays = this.state.defaultDays.map((item, index) => {
            if (idsArray.includes(index)) {
                item.checked = true;
                return item;
            } else {
                item.checked = false;
                return item;
            }
        })
        this.setState({ defaultDays: updatedDays });
    }

    render() {
        const { overtimeCalcRules, overtimeAutomaticMode, defaultDays, overtime_types } = this.state;
        const { t } = this.props;
        this.translateLables();
        return (
            <div>
                <div className="custom-form__row center-block">
                    <div className="custom-form__row" style={{ margin: 10 }}>
                        <Radio
                            label={t('Manual')}
                            checked={!overtimeAutomaticMode}
                            onChange={() => this.setState(prevState => ({ overtimeAutomaticMode: !prevState.overtimeAutomaticMode }),
                                () => this.props.handleUserDataChange('overtimeAutomaticMode', this.state.overtimeAutomaticMode))} />
                    </div>
                    <div className="custom-form__row" style={{ margin: 10 }}>
                        <Radio
                            label={t('Automatic')}
                            checked={overtimeAutomaticMode}
                            onChange={() => this.setState(prevState => ({ overtimeAutomaticMode: !prevState.overtimeAutomaticMode }),
                                () => this.props.handleUserDataChange('overtimeAutomaticMode', this.state.overtimeAutomaticMode))} />
                    </div>
                </div>
                {overtimeAutomaticMode ?
                    <div>
                        <Row>
                            <Col sm={4}>
                                <label className="control-label">{t('Period')}</label>
                                <Select
                                    options={calc_periodOption}
                                    value={calc_periodOption.find(item => item.value === overtimeCalcRules.calc_period)}
                                    onChange={period => {
                                        this.handleChange('calc_period', period.value)
                                        if(period.value == "government"){
                                            this.handleChange('max_hours', 9);
                                            this.handleChange('max_minutes', 0);
                                        }
                                        }
                                    }
                                    style={{ width: '100%' }}
                                />
                            </Col>
                            <Col sm={4}>
                                <NumberInput
                                    label={`${t('Maximum hours')} (hh)`}
                                    name="max_hours"
                                    value={overtimeCalcRules.max_hours}
                                    onChange={evt => {
                                        this.handleChange('max_hours', parseInt(evt.target.value, 10));
                                    }}
                                    disabled={overtimeCalcRules.calc_period=="government"}
                                />
                            </Col>
                            <Col sm={4}>
                                <NumberInput
                                    label={`${t('Maximum Minutes')} (mm)`}
                                    name="max_minutes"
                                    value={overtimeCalcRules.max_minutes}
                                    onChange={evt => {
                                        this.handleChange('max_minutes', parseInt(evt.target.value, 10));
                                    }}
                                    disabled={overtimeCalcRules.calc_period=="government"}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <NumberInput
                                    label={`${t('Overtime')} (%)`}
                                    name="Overtime"
                                    value={overtimeCalcRules.calc_percent}
                                    onChange={evt => {
                                        this.handleChange('calc_percent', parseInt(evt.target.value, 10));
                                    }}
                                />
                            </Col>
                            <Col sm={4}>
                                <NumberInput
                                    label={`${t('Additional pay for weekend')}`}
                                    name="weekend_percent"
                                    value={overtimeCalcRules.weekend_percent}
                                    onChange={evt => {
                                        this.handleChange('weekend_percent', parseInt(evt.target.value, 10));
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col sm={12}>
                                <label className="control-label">{t('Default holidays')}</label>
                                <div className="row-flex" style={{ overflowX: 'auto' }}>
                                    {defaultDays.map((item, index) => (
                                        <Checkbox
                                            wrapperClassName="days-of-week__item"
                                            key={`${index}`}
                                            isChecked={item.checked}
                                            onChange={evt => this.handleCheck(index, evt)}
                                            label={t(item.day)}
                                        />
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    :
                    <div>
                        <TableWidget
                            headers={['Unique id', 'Amount', 'Label']}
                            metaData={[{ field: 'code', type: 'text', placeholder: '0', required: true }, 
                                { field: 'multiplier', type: 'number', placeholder: 0, required: true }, 
                                { field: 'name', type: 'text', placeholder: '%', required: true }]}
                            data={overtime_types}
                            onChange={data => this.props.handleUserDataChange('overtime_types', data)}
                        />
                    </div>
                }
            </div >
        );
    }
}
export default OverTimeCalculation;