import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormControl } from 'react-bootstrap';
//components
import Button from '../../../components/CustomButton/CustomButton.jsx';
import DatePicker from '../../../components/DatePicker/DatePicker';
import Select from '../../../elements/Select';
import withLocalization from '../../../hoc/withLocalization';
import config from '~/library/config';
import agent from '~/library/agent';

//this component handles the search and is visitor filter// 

@inject('commonStore', 'clientStore', 'visitorStore')
@withLocalization
@observer
class VisitorFilter extends Component {
    state = {
        options: [],
    }

    load_data = async () => {
        const { clientStore, t } = this.props;
        const clients = await clientStore.loadList({});
        const _options = clients.rows.map((obj) => {
            return { value: obj.id, label: obj.name };
        })
        _options.unshift({ value: '', label: t('All') })
        this.setState({ options: _options });
    }

    componentDidMount() {
        this.load_data();
    }

    printExcelReport = async () => {
        const { filters, t } = this.props;
        let hydratedPayload= {
            client_id:filters.client_id,
            full_name:filters.full_name,
            from_date:filters.from_date,
            to_date:filters.to_date,            
            authorization: this.props.commonStore.token
        }
        window.open(`${config.API_ROOT}/visitors/report/${agent.convertToGetParams(hydratedPayload)}`);        
    }

    render() {
        const { filters, setFilter, onFilter, loading, t } = this.props;

        const { full_name, from_date, to_date, client_id } = filters;
        return (
            <div className='list-filters'>
                <div className='list-filters__item list-filters__item_grow'>
                    <FormControl
                        placeholder={t('search by name')}
                        type="text"
                        onChange={(e) => setFilter('full_name', e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onFilter();
                            }
                        }}
                        value={full_name}
                    />
                    <div className="list-filters__item">
                        <DatePicker
                            selected={from_date}
                            className="smaller"
                            onChange={(date) => setFilter('from_date', date)}
                            dateFormat="MMMM dd"
                            placeholder={t('From')}
                            maxDate={new Date()}
                            style={{ width: '150px' }}
                        />
                    </div>
                    <div className="list-filters__item">
                        <DatePicker
                            selected={to_date}
                            className="smaller"
                            onChange={(date) => setFilter('to_date', date)}
                            dateFormat="MMMM dd"
                            placeholder={t('To')}
                            maxDate={new Date()}
                            style={{ width: '150px' }}
                        />
                    </div>
                    <div className="list-filters__item">
                        <Select
                            options={this.state.options}
                            round
                            value={client_id}
                            onChange={id => setFilter('client_id', id)}
                            style={{ width: 150 }}
                        />
                    </div>
                </div>
                <div className='list-filters__item list-filters__item_to-right'>
                    <Button fill onClick={() => onFilter()} disabled={loading} simple icon>
                        <i className="fa fa-search" />
                    </Button>
                    <Button fill onClick={() => this.printExcelReport()} disabled={loading} simple icon>
                        <i className="fa fa-print" />
                    </Button>
                </div>
            </div >
        )
    }
}

export default VisitorFilter;