import React, { Component } from 'react';
import v4 from 'uuid/v4';

class CustomCheckbox extends Component {
    render() {
        const {
            label, onChange, isChecked, inline, wrapperClassName = '', inlineBlock, boldLabel, ...rest
        } = this.props;
        const classes = inline !== undefined ? 'checkbox checkbox-inline' : 'checkbox';
        const key = v4();
        return (
            <div className={`${classes} ${wrapperClassName}`} style={{display : inlineBlock ? 'inline-block' : 'block'}}>
                <input
                    id={key}
                    type="checkbox"
                    onChange={e => onChange(e.target.checked)}
                    checked={isChecked}
                    {...rest}
                />
                {boldLabel ? <label htmlFor={key}><b>{label}</b></label> : <label htmlFor={key}>{label}</label>}
            </div>
        );
    }
}

export default CustomCheckbox;
