import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import _get from 'lodash/get';
import moment from 'moment';

import withLocalization from '../../../hoc/withLocalization';

import config from '~/library/config';
import getBase64 from '~/utils/getBase64';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import editIcon from '../../../assets/img/edit-icon.svg';

@inject('userStore', 'commonStore')
@withLocalization
@observer
class AttachmentsWidgetMobx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uiAttachments: [],
            docAttachments: false,
            withTable: false,
            isEditMode: null,
            filename: ''
        };
    }

    handleSave(Id) {
        const { userStore, commonStore, t } = this.props;
        if (this.state.filename.trim()) {
            userStore.updateAttachmentName({ id: Id, filename: this.state.filename }).then((res) => {                
                this.setState({ isEditMode: null }); 
            });
        } else {
            commonStore.addNotification(t(`Document name can't be blank`), null, 'error', 'tr', 3);
        }
    }

    onNameSave = (evt, Id) => {
        const { userStore, commonStore, t} = this.props;
        
        if (evt.key === 'Enter' || evt.keyCode === 13) {
            if (this.state.filename.trim()) {
                userStore.updateAttachmentName({ id: Id, filename: this.state.filename }).then((res) => {                    
                    this.setState({ isEditMode: null });                    
                });
            } else {
                commonStore.addNotification(t(`Document name can't be blank`), null, 'error', 'tr', 3);
            }

        }
    }

    componentDidMount() {
        this.pushAttachmentFromProps();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.options.imageContext) !== JSON.stringify(this.props.options.imageContext)) {
            this.pushAttachmentFromProps();
        }
    }

    openEditMode = (evt, filename, id) => {
        this.setState({ isEditMode: id, filename: filename })
    }

    onFileNameChange = (evt) => {
        const filename = evt.target.value;
        this.setState({ filename: filename })
    }
    getUrl = file => {
        return `${config.UPLOADS_API_ENDPOINT}/${file}`;
    };

    onUpload = e => {
        const {
            onChange, onUpload, options, userStore, commonStore, t
        } = this.props;



        const { files } = e.target;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const { name } = file;
            if (this.isImage(name) && options.checkDate) {
                // console.log("???", name, this.isImage(name), options.checkDate, file);
                // console.log(EXIF.getData(file));
                /* if (!file.exifData
          || !file.exifData.DateTime
          || moment(new Date()).diff(moment(new Date(file.exifData.DateTime)), 'hour') > 2) {
          commonStore.addNotification(t('Allowed to use files created no earlier than 2 hours ago'), null, 'error');
          return;
        } */
            }

            getBase64(file)
                .then(attachData => {
                    userStore
                        .fileUpload({
                            to_model: options.imageContext.model,
                            to_id: options.imageContext.id,
                            user_id: userStore.currentUser.id,
                            filename: name,
                            filetype: options.imageContext.fileType,
                            content: attachData,
                            data: options.imageContext.data || {},
                            // created_at: options.created_at,//new params
                            // current_date: userStore.dateInfo//new params
                        })
                        .then(newId => {
                            if (onUpload) onUpload(name);
                            const arrayIds = this.props.value ? this.props.value.split(',') : [];
                            arrayIds.push(newId);
                            onChange(`${arrayIds.join(',')}`);

                            const uiAttachment = {
                                id: newId,
                                filename: name,
                                filetype: options.imageContext.fileType,
                                // current_date: userStore.dateInfo//new parsms
                            };
                            this.addUiAttachment(uiAttachment);
                        });
                    this.fileInput.value = '';
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    addUiAttachment = attachment => {
        // Clone Array
        const uiAttachments = this.state.uiAttachments.slice(0);
        // console.log("some : ",attachment,uiAttachments)
        uiAttachments.push(attachment);
        this.setState({
            uiAttachments,
        });
    };

    removeUiAttachment = id => {
        // Clone Array
        const uiAttachments = this.state.uiAttachments.slice(0);

        uiAttachments.filter(attachment => String(attachment.id) !== String(id));
        this.setState({
            uiAttachments,
        });
    };


    removeAttachment = removedId => {
        const { onChange, value } = this.props;
        const arrayIds = value ? value.split(',') : [];
        const newArrayIds = arrayIds.filter(id => String(id) !== String(removedId));
        onChange(`${newArrayIds.join(',')}`);
        this.removeUiAttachment(removedId);
    };

    pushAttachmentFromProps = () => {
        let isWidthTable = false;
        const { existingAttachment } = this.props.options.imageContext;

        // if( existingAttachment && existingAttachment.imageContext && existingAttachment.imageContext.withTable){
        if (this.props.options.imageContext.withTable) {
            isWidthTable = true;
        }

        if (existingAttachment) {
            this.setState({
                uiAttachments: existingAttachment,
                withTable: isWidthTable
            });
        }
    };

    isImage = filename => {
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        return !!allowedExtensions.exec(filename);
    };

    renderItem = (attachments,dateTimeRules) => (

        (this.state.withTable) ?
            <div style={{ width: '100%' }}>
                <table className="table table-hover table-striped attachment-tbl" >
                    <thead style={{ background: "#447DF7" }}>
                        <tr>
                            <th>{this.props.t('Date')}</th>
                            <th>{this.props.t('Time')}</th>
                            <th>{this.props.t('Document Name')}</th>
                            <th>{this.props.t('Actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attachments.map((attachment) => (
                            <tr key={attachment.id}>
                                <td>{moment(attachment.updated_at).format(dateTimeRules.short_date_format)}</td>
                                <td>{moment(attachment.updated_at).format(dateTimeRules.time_format)}</td>
                                <td>
                                    {this.state.isEditMode !== attachment.id && <span>{attachment.filename}</span>}
                                    {this.state.isEditMode === attachment.id && <input type="text" className="fileNameInput" value={this.state.filename} onKeyUp={(evt) => { this.onNameSave(evt, attachment.id) }} onChange={this.onFileNameChange} />}
                                    {this.state.isEditMode === attachment.id && <span onClick={() => this.handleSave(attachment.id)}><i className='fa fa-save saveIcon' style={{ color: 'grey', marginLeft: '8px', fontSize: '20px',cursor:'pointer' }} title='save'></i></span>}
                                </td>
                                <td>
                                    {/* <Button className="contractButtons">
                                        <i className="fa fa-print" style={{ color: 'grey', marginLeft: '-10px' }} />
                                    </Button> */}
                                    <Button onClick={() => this.removeAttachment(attachment.id)} className="contractButtonsDelete">
                                        <i className="fa fa-times" style={{ color: 'red', marginLeft: '-10px' }} />
                                    </Button>
                                    <Button className="contractButtons">
                                        {(this.state.isEditMode !== attachment.id || this.state.isEditMode === attachment.id) && <i className="fa fa-edit" style={{ color: 'grey', marginLeft: '-10px' }} onClick={(evt) => { this.openEditMode(evt, attachment.filename, attachment.id) }} />}
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            :         
               
            <div className="uploads-item" key={attachments.id}>
                <div className="uploads-item__file">
                    {this.isImage(attachments.filename) ? (
                        <img
                            src={`${config.UPLOADS_API_ENDPOINT}/${attachments.id}`}
                            alt={attachments.filename}
                            className="uploads-item__img"
                            onClick={() => window.open(this.getUrl(attachments.id), '_blank')}
                        />
                    ) : (
                        <i
                            className="uploads-item__icon pe-7s-file"
                            onClick={() => window.open(this.getUrl(attachments.id), '_blank')}
                        />
                    )}
                    {!this.props.disabled && (
                        <i
                            className="uploads-item__delete pe-7s-close-circle"
                            onClick={() => this.removeAttachment(attachments.id)}
                        />
                    )}
                </div>
                <span className="uploads-item__title" title={attachments.filename}>
                    {attachments.filename}
                </span>
            </div>            
    );

    render() {
        const { value, groups = [], group: userGroup, t, disabled, commonStore } = this.props;
        const { uiAttachments, withTable } = this.state;
        const attachmentsIds = value ? value.split(',') : [];
        const attachments = uiAttachments.filter(item => attachmentsIds.indexOf(String(item.id)) !== -1);
        // console.log("withTable",withTable,uiAttachments,attachments)
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        if (!groups.length) {
            return (
                <>
                    <div className="uploads upload-attachments" style={{ justifyContent: withTable ? 'flex-end': 'center' }}>

                        {attachments && withTable && this.renderItem(attachments,dateTimeRules)}
                        {attachments && !withTable && attachments.map(attachment => this.renderItem(attachment))}

                        {(!disabled && !withTable) &&
                            <button className="uploads__add-button" type="button" onClick={() => this.fileInput.click()}>
                                <i className="pe-7s-cloud-upload" />
                            </button>
                        }
                        {(!disabled && withTable) &&
                            <div className='entity-attachment'>
                                    <button type='button' className='btn btn-fill btn-doc-upload' onClick={() => {
                                        this.fileInput.click()
                                    }}>{t('Upload New Document')}</button>
                            </div>
                        }

                        {!disabled && (
                            <input
                                style={{ display: 'none' }}
                                type="file"
                                ref={fileInput => {
                                    this.fileInput = fileInput;
                                }}
                                onChange={this.onUpload}
                                multiple
                            />
                        )}

                    </div>
                </>
            );
        }

        // GROPED ATTACHMENTS
        const allGroupKeys = groups.map(group => group.key);
        const ungroupedItems = attachments.filter(attach => {
            const group = _get(attach, 'data.group', 'ungrouped');
            if (!attach.user_id && allGroupKeys.indexOf(userGroup) !== -1) {
                return false;
            }
            return allGroupKeys.indexOf(group) === -1;
        });

        // console.log("Some2",ungroupedItems,uiAttachments)

        return (
            <div className="uploads uploads_with-groups">
                {groups.map(group => {
                    const groupedAttachments = attachments.filter(attach => {
                        if (!attach.user_id && userGroup === group.key) {
                            return true;
                        }
                        return attach.data && attach.data.group === group.key;
                    });
                    if (groupedAttachments.length) {
                        return (
                            <div className="input-area" key={group.key}>
                                <span className="input-area__label">{group.label}</span>
                                <div className="input-area__content">
                                    {groupedAttachments.map(attachment => this.renderItem(attachment,dateTimeRules))}
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
                {!!ungroupedItems.length && (
                    <div className="input-area">
                        <span className="input-area__label">{t('Other Attachments')}</span>
                        <div className="input-area__content">
                            {ungroupedItems.map(attachment => this.renderItem(attachment,dateTimeRules))}
                        </div>
                    </div>
                )}
                {(!disabled && !withTable) &&(
                    <button
                        className="uploads__add-button"
                        style={{ margin: '10px auto'}}
                        type="button"

                        onClick={() => this.fileInput.click()}
                    >
                        <i className="pe-7s-cloud-upload" />
                    </button>
                )}

                {(!disabled  && withTable ) &&
                    <div className='attachment-wid'>
                        <button className='btn btn-fill'
                            onClick={() => this.fileInput.click()}>{t('Upload New Document')}</button>
                    </div>
                }

                {!disabled && (
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        ref={fileInput => {
                            this.fileInput = fileInput;
                        }}
                        onChange={this.onUpload}
                        multiple
                    />
                )}
            </div>
        );
    }
}

@observer
class AttachmentsWidget extends Component {
    render() {
        return <AttachmentsWidgetMobx {...this.props} />;
    }
}

export default AttachmentsWidget;
