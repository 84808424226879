import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import Select from '../../../../elements/Select';

const breakMinOptions = [{ value: "0", label: '0 minute' }, { value: "30", label: '30 minutes' }, 
{ value: "60", label: '60 minutes' }, { value: "90", label: '90 minutes' }]
const hoursBeforeBreakOptions = [{ value: 0, label: '0 hour' }, { value: 1, label: '1 hour' }, 
{ value: 2, label: '2 hours' }, { value: 3, label: '3 hours' }]

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class RulesForBreakTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesForBreakTime: {
                minutes: "0",
                hoursBefore30MinBreak: 0,
                hoursBefore60MinBreak: 0,
            },
            maxHoursForAutoLogout:13
        }
        this.handleSelect = this.handleSelect.bind(this)
        this.handleInputRange = this.handleInputRange.bind(this)
        this.loadData = this.loadData.bind(this);
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const { rulesForBreakTime } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            const basicRules = Object.assign({}, currentEntity.client.data.basicRules);            
            rulesForBreakTime.minutes = basicRules.breakTimeRules.minutes;
            this.setState({ 
                rulesForBreakTime,
                maxHoursForAutoLogout : basicRules.maxHoursForAutoLogout ? basicRules.maxHoursForAutoLogout : 13 
            })
        }
    }

    componentDidMount() {
        this.loadData()
    }

    handleSelect = (name, value) => {
        const { rulesForBreakTime } = this.state;
        rulesForBreakTime[name] = value;
        this.props.handleUserDataChange('breakTimeRules', rulesForBreakTime);
        this.setState({ rulesForBreakTime });
    }

    handleInputRange = (e) => {      
        e.persist();        
        if(e.target.value < 12 || e.target.value > 23) return;
        let _maxHoursForAutoLogout = e.target.value;
        this.props.handleUserDataChange('maxHoursForAutoLogout', _maxHoursForAutoLogout);
        this.setState({ maxHoursForAutoLogout: _maxHoursForAutoLogout });
    }

    render() {
        const { rulesForBreakTime, maxHoursForAutoLogout } = this.state;
        const { t } = this.props;
        return (
            <div>
                <Row className='mt-20'>
                    <Col sm={4}>
                        <label className="control-label">{t('Set the time')}</label>
                        <Select
                            options={breakMinOptions}
                            value={breakMinOptions.find(item => item.value === rulesForBreakTime.minutes)}
                            onChange={time => this.handleSelect('minutes', time.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>                    
                    <Col sm={8} style={{ marginTop: '2rem' }}>
                        <label className="control-label">{t('Maximum allowed hours before auto finish working log')}</label>                        
                        <div className="slider-parent">
                            <input type="range" min="12" max="23" 
                                onChange={event => this.handleInputRange(event)} 
                                value={maxHoursForAutoLogout}
                            />
                            <div className="buble">
                                {maxHoursForAutoLogout} {t('Hours')}
                            </div>
                        </div>
                    </Col>
                </Row>                
                {/* <Row style={{ marginTop: '2rem' }}>
                    <Col sm={6}>
                        <label className="control-label">{t('After how many hours 30min break')}</label>
                        <Select
                            options={hoursBeforeBreakOptions}
                            value={hoursBeforeBreakOptions.find(item => item.value === rulesForBreakTime.hoursBefore30MinBreak)}
                            onChange={time => this.handleSelect('hoursBefore30MinBreak', time.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col sm={6}>
                        <label className="control-label">{t('After how many hours 60min break')}</label>
                        <Select
                            options={hoursBeforeBreakOptions}
                            value={hoursBeforeBreakOptions.find(item => item.value === rulesForBreakTime.hoursBefore60MinBreak)}
                            onChange={time => this.handleSelect('hoursBefore60MinBreak', time.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row > */}
            </div>
        );
    }
}
export default RulesForBreakTime;
