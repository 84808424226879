import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import AbsencesList from './AbsencesList';
import AbsencesForm from './AbsencesForm';

import withRemoveDialog from '~/hoc/withRemoveDialog';

@inject('authStore', 'absenceStore', 'userStore')
@withRouter
@withRemoveDialog
@observer
class AbsencesPage extends Component {
    //added state to maintain the genericList and genericForm states in parent component//
    state={
        mode:{
            id:0,
            add:false,            
        },
        page:0,
        isPageAddedOrEdited:false
    }

    //removed URL pushing//
    onAdd() {
        //const { history } = this.props;        
        // history.push('/admin/absences/add');
        const mode={id:0,add:true};
        this.setState({mode:mode,isPageAddedOrEdited:true});        
    }

    onTableAction(id, type) {
        //const { history } = this.props;
        if (type === 'remove') {
            return this.props.handleDelete(id, id => {
                return this.props.absenceStore.remove(id).then(res => {
                    this.props.absenceStore.resetLastListLoadTime();
                    return res;
                });
            });
        }
        const mode={id:id,add:false};
        this.setState({mode:mode,isPageAddedOrEdited:true});
        // history.push(`/admin/absences/${id}`);
    }

    // editingId = () => {
    //     let id = null;
    //     if (this.props.location.pathname !== this.props.match.path) {
    //         id = this.props.location.pathname.replace(this.props.match.path, '');
    //         id = id.replace('/', '');
    //     }
    //     return { editing: !!id, add: id === 'add', id };
    // };

    //this method is replacing the URL pushing part while add/edit//
    goBack = () =>{        
        const mode = {id:0,add:false};
        this.setState({mode:mode});
    }

    //method to maintain react-table page in parent component//
    handlePageChange = (page,isEdit) => {
        this.setState({page:page,isPageAddedOrEdited:isEdit});        
    }

    render() {
        //updated module to state based rendering//        
        // const mode = this.editingId();        
        // const { user_type } = this.props.userStore.currentUser;
        
        const {mode,page,isPageAddedOrEdited}= this.state;
        
        return (
            <div className="main-content">
                {(mode.id == 0 && mode.add == false) ? 
                    <AbsencesList
                        onTableAction={(id, type) => this.onTableAction(id, type)}
                        onAdd={() => this.onAdd()}
                        handlePageChange = {(page,isEdit)=>this.handlePageChange(page,isEdit)}
                        page = {page}
                        isPageAddedOrEdited = {isPageAddedOrEdited}
                    />
                :
                    <AbsencesForm 
                        id={mode.id} add={mode.add}
                        goBack={()=>this.goBack()}/>
                }
            </div>
        );
    }
}
export default AbsencesPage;
