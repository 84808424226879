import React from 'react';
import { withNamespaces } from 'react-i18next';
import { inject } from 'mobx-react';
import { find } from 'lodash';

import i18n from '../i18n';

// const debug = !!localStorage.getItem('tdebug');
// let tGather = localStorage.getItem('tgather') || '';
const debug = !!sessionStorage.getItem('tdebug');
let tGather = sessionStorage.getItem('tgather') || '';

const withLocalization = Component => {
    class localization extends React.Component {
        validator = translations => (...param) => {
            return this.props.t(param[0]);
            // const result = this.context.t(...param);

            // return !translations[param[0]] ? `**${param[0]}**` : result;
        };

        translationExists = text => {
            try {
                return i18n.exists(text);
            } catch (e) {
                return false;
            }
        };

        gatherDebug = t => {
            if (tGather.indexOf(`|${t}|`) < 0) {
                tGather += `|${t}|`;
                // localStorage.setItem('tgather', tGather);
                sessionStorage.setItem('tgather', tGather);
            }
        };

        tTypeSpecific = text => {
            const { commonStore, t } = this.props;
            if (
                !commonStore.config
                || !commonStore.config.biztype
                || !commonStore.config.biztype.data
                || !commonStore.config.biztype.data.name_substitutions
            ) return t(text);
            const replace = find(commonStore.config.biztype.data.name_substitutions, r => {
                return r.from === text;
            });
            const returned = replace ? replace.to : text;
            if (!returned || returned === 'undefined') return '';
            if (!this.translationExists(returned) && debug) {
                this.gatherDebug(returned);
                return `[NO]${returned}`;
            }
            return t(returned);
        };

        render() {
            return (
                <Component
                    {...this.props}
                    tTypeSpecific={this.tTypeSpecific}
                    t={this.tTypeSpecific}
                    tExists={this.translationExists}
                />
            );
        }
    }

    return inject('commonStore')(withNamespaces('translation')(localization));
};

export default withLocalization;
