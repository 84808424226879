import React, { Fragment } from 'react';
import Form from 'react-jsonschema-form';
import { withRouter } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';
import deepEqual from 'deep-equal';

import withLocalization from '../../hoc/withLocalization';

import LayoutField from './LayoutField';
import LayoutGridField from './LayoutGridField';
import TabsField from './TabsField';
import ImageUploadWidget from './Widgets/ImageUploadWidget';
import ActiveInactiveWidget from './Widgets/ActiveInactiveWidget';
import RadioWidget from './Widgets/RadioWidget';
import TrueFalseWidget from './Widgets/TrueFalseWidget';
import ProjectStatuses from './Widgets/ProjectStatuses';
import TaskStatuses from './Widgets/TaskStatuses';
import { AllUsersWidget, MembersWidget, ManagersWidget, MembersWidgetMultiple, MembersWidgetMultipleSelect } from './Widgets/UsersWidget';
import { ProjectsWidget } from './Widgets/ProjectsWidget';
import { TasksWidget } from './Widgets/TasksWidget';
import CheckBoxWidget from './Widgets/CheckBoxWidget';
import InputTime from './Widgets/InputTime';
import AttachmentsWidget from './Widgets/AttachmentsWidget';
import { KidsSelect } from './Widgets/SpecialSelect';
import VacationStatusesWidget from './Widgets/VacationStatusesWidget';
import AbsenceStatusesWidget, { AbsenceStatusesWidgetOnEdit } from './Widgets/AbsenceStatusesWidget';
import TimeSelectWidget from './Widgets/TimeSelectWidget';
import DaysOfWeekWidget from './Widgets/DaysOfWeekWidget';
import GpsCoordinatesWidget from './Widgets/GpsCoordinatesWidget';
import { MemberInProjectsWidget, MemberInProjectsWidgetMultiSelect } from './Widgets/MemberInProjectsWidget';
import BusinessTypesSelect from './Widgets/BusinessTypesSelect';
import DeviationConfigWidget from './Widgets/DeviationConfigWidget';
import ShiftsWidget from './Widgets/ShiftsWidget';
import { serverValidation } from '../../library/core';
import DocumentTemplateWidget from './Widgets/DocumentTemplateWidget';
import LeaveTypeWidget, { LeaveTypeWidgetOnEdit } from './Widgets/LeaveTypeWidget';
import UserContractList from '../../containers/Admin/Users/UserContractList';
import { observer, inject } from 'mobx-react';


const fields = {
    layout: LayoutField,
    layout_grid: LayoutGridField,
    tabs: TabsField,
    DeviationConfigWidget,
};

const widgets = {
    ImageUpload: ImageUploadWidget,
    ActiveInactive: ActiveInactiveWidget,
    Radio: RadioWidget,
    TrueFalse: TrueFalseWidget,
    ProjectStatuses,
    TaskStatuses,
    CheckBoxWidget,
    ManagersWidget,
    AllUsersWidget,
    MembersWidget,
    AttachmentsWidget,
    ProjectsWidget,
    TasksWidget,
    InputTime,
    VacationStatuses: VacationStatusesWidget,
    AbsenceStatuses: AbsenceStatusesWidget,
    AbsenceStatusesOnEdit: AbsenceStatusesWidgetOnEdit,
    TimeSelect: TimeSelectWidget,
    DaysOfWeek: DaysOfWeekWidget,
    KidsSelect,
    GpsCoordinatesWidget,
    MemberInProjectsWidget,
    MemberInProjectsWidgetMultiSelect,
    BusinessTypesSelect,
    MembersWidgetMultiple,
    MembersWidgetMultipleSelect,
    DeviationConfigWidget,
    ShiftsWidget,
    LeaveTypeWidget,
    LeaveTypeWidgetOnEdit
    //DocumentTemplate:DocumentTemplateWidget,
};

const customFormats = {
    'tax-number': serverValidation('tax'),
    'required-name': /^(?!\s*$).+/,
    'phone-no': serverValidation('phone'),
    postnumber: serverValidation('zip'),
    gps: /(^$|^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$)/, // allow empty strings
    'social-no': serverValidation('social'),
    email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
};

const getTypeScope = (typeName) => `types.${typeName}`;
const getPropScope = (scope, propName) => `${scope}.fields.${propName}`;

function intlSchema(schema, scope, i18n, name) {
    const newSchema = {
        ...schema,
        ..._.pickBy({
            title: i18n(schema.title),
            description: i18n(schema.description),
            help: i18n(schema.help),
        }),
    };

    if (schema.definitions) {
        newSchema.definitions = _.mapValues(schema.definitions, (typeSchema, typeName) =>
            intlSchema(typeSchema, getTypeScope(typeName), i18n, typeName)
        );
    }

    if (schema.type === 'object') {
        newSchema.properties = _.mapValues(schema.properties, (propSchema, propName) =>
            intlSchema(propSchema, getPropScope(scope, propName), i18n, propName)
        );
    }

    if (schema.enum && (!schema.enumNames || _.isEqual(schema.enum, schema.enumNames))) {
        newSchema.enumNames = schema.enum.map((option) => _.defaultTo(i18n(`${scope}.options.${option}`), option));
    }

    if (schema.items && schema.type === 'array') {
        if (schema.items.enum && !schema.items.enumNames) {
            newSchema.items = {
                ...newSchema.items,
                enumNames: schema.items.enum.map((option) => _.defaultTo(i18n(`${scope}.options.${option}`), option)),
            };
        } else if (schema.items.properties && schema.items.type === 'object') {
            newSchema.items = {
                ...newSchema.items,
                properties: _.mapValues(schema.items.properties, (propSchema, propName) =>
                    intlSchema(propSchema, getPropScope(scope, propName), i18n, propName)
                ),
            };
        }
    }

    return newSchema;
}

export const isFilled = (fieldName) => ({ formData }) => !!(formData[fieldName] && formData[fieldName].length);
export const isTrue = (fieldName) => ({ formData }) => formData[fieldName];

@withRouter
@withLocalization
@inject('commonStore', 'signatureStore','clientStore')
class GenericForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: props.entity,
            files: [],
            attachmentList:[],
            uploadInfo: null,
            currentClientId : 0,
            clientAttachmentIds:""
        };
        this.submitForm = this.submitForm.bind(this);
    }



    handleTableButtonAction = (id, type, data,expiry_date) => {
        if(type === 'upload'){       
            this.handleAttachmentUploads(id,data,expiry_date);
            return;
        }        
        this.props.onTableAction(id, type);
    };   
    
    
    static recomputeFields(props, state) {
        if (props.recomputeFields && props.recomputeFields.length) {
            let stateChanged = false;
            props.recomputeFields.forEach((fld) => {
                if (fld.indexOf('data.') === 0) {
                    fld = fld.replace('data.', '');
                    if (props.entity.data[fld] !== state.formData.data[fld]) {
                        stateChanged = true;
                        state.formData.data[fld] = props.entity.data[fld];
                    }
                } else if (props.entity[fld] !== state.formData[fld]) {
                    stateChanged = true;
                    state.formData[fld] = props.entity[fld];
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { signatureStore } = this.props;
        const { formDataCallback } = this.props;
        const caseId = signatureStore.caseId;
        const previousFormData = prevState.formData;
        const newFormData = this.state.formData;
        const formDataSame = deepEqual(previousFormData, newFormData, { strict: true });
        // if (!caseId) {
        //     signatureStore.createCase({ name: 'tid' })
        // }
        if (!formDataSame && typeof formDataCallback === 'function') {
            formDataCallback(this.state.formData);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.formData && props.entity && !state.formData.id && props.entity.id) {
            state.formData = props.entity;
        }
        if (state.formData && props.entity && !state.formData._reload && props.entity._reload) {
            state.formData = props.entity;
        }
        GenericForm.recomputeFields(props, state);
        return state;
    }

    onSubmit(e) {
        if(this.props.setRef){
            return this.props.onSave(e.formData, e, true);
        } else {
            return this.props.onSave(e.formData, e, false);
        }
        
    }

    onCancel() {
        this.setState({ formData: null });
        //checking if goback is passed//
        if (this.props.goBack) {
            //if present than calls the function to render same page//          
            this.props.goBack();
        }
        else {
            const { history, listUrl } = this.props;
            if (!listUrl) return;
            history.push(listUrl);
        }
    }

    submitForm() {
        this.submitButton.click();
    }

    componentDidMount() {
        const { formData } = this.state;
        const { formDataCallback } = this.props;
        if (typeof formDataCallback === 'function') {
            formDataCallback(formData);
        }
        // formData.__loadCounter = 1;
        setTimeout(() => {
            if (this.form) this.form.validate();
            // this.setState({formData});
        }, 100);
    }

    transformErrors(errors) {
        const { t } = this.props;
        return errors.map((error) => {
            error.message = t(error.message);
            return error;
        });
    }

    //custom form validator//
    validateForm(formData, errors) {
        const { customvalidate } = this.props;
        if (customvalidate !== undefined) return customvalidate(formData, errors);
        // console.log("FormObject : ",formData,errors);       
        // let today = moment(new Date()).format('YYYY-MM-DD');
        // if(formData.birthday && moment(formData.birthday).isSameOrAfter(today)){                                   
        //     errors.birthday.addError(t("Please enter valid birthdate"));
        // }         
        // // if(formData.username == "" || ( formData.username && formData.username.trim() === '')){
        // if(formData.username && formData.username.trim() === ''){
        //     errors.username.addError(t("is a required property"));
        // }
        return errors;
    }

    async onChange(formData, all) {
        const fd = _.cloneDeep(formData);
        // console.log("fd:",fd);
        const oldData = this.state.formData;
        // console.log("oldata:", oldData)
        if (fd && fd.basicRules && fd.basicRules.overtimeCalcRules && fd.basicRules.overtimeCalcRules.calc_period
            && fd.basicRules.overtimeCalcRules.calc_period == 'government'
            //  &&
            // oldData && oldData.basicRules && oldData.basicRules.overtimeCalcRules && oldData.basicRules.overtimeCalcRules.calc_period 
            // && oldData.basicRules.overtimeCalcRules.calc_period != 'government'
        ) {
            // console.log("************************");
            // console.log(fd.basicRules.overtimeCalcRules)
            let newpayload = fd.basicRules.overtimeCalcRules;
            newpayload.max_hours = 9;
            newpayload.max_minutes = 0;
            fd.basicRules.overtimeCalcRules = newpayload;
        }

        Object.keys(fd).forEach((key) => {
            // Fix for the issue when removing completely a string value.            
            if (fd && oldData && typeof oldData[key] === 'string' && oldData[key] && typeof fd[key] === 'undefined') {
                if (
                    !(all.schema.properties[key] && all.schema.properties[key].format === 'date') &&
                    !all.schema.properties[key].isRequired
                ) {
                    fd[key] = '';
                }
            }
            if (
                fd &&
                oldData &&
                (fd[key] === '' || typeof fd[key] === 'undefined') &&
                all.schema.properties[key] &&
                all.schema.properties[key].type.indexOf('null') >= 0 &&
                !all.schema.properties[key].isRequired
            ) {
                fd[key] = null;
            }

        });
        if (this.props.onChange) {
            const newData = await this.props.onChange(fd);
            Object.keys(newData).forEach((key) => {
                fd[key] = newData[key];
            });
        }
        this.setState({ formData: fd });
    }
   
    handleAttachmentModalCloseButton = () => {
        this.setState({ showAttachmentModal: false,
            currentClientId:0,
            clientAttachmentIds:"",
            attachmentList:[],
            expiry_date:new Date()});
    };




    handleAttachmentUploads = async (id,data,expiry_date) =>{        
        let attachment_ids = "";
        let attachmentList = [];
        if(data && data.attachment_id != ""){
            attachment_ids = data.attachment_id;
            await this.props.clientStore.getMultipleAttachments({ attachmentIds:attachment_ids}).then((response=>{
                attachmentList = response.attachments;
            }))
        }                            
        this.setState({ clientAttachmentIds: attachment_ids,
            showAttachmentModal: true,
            currentClientId: id,
            attachmentList: attachmentList,
            expiry_date: expiry_date ? new Date(expiry_date) : new Date()});  
    }





    saveClientAttachments = async () =>{
        const { clientStore, t, commonStore} = this.props;
        const { currentClientId,clientAttachmentIds,expiry_date} = this.state;    
        // if(clientAttachmentIds == "" || clientAttachmentIds == undefined){
        //     commonStore.addNotification(t('Please Add Attachments'), null, 'error');
        //     return;
        // }
        let data = {
            id: currentClientId,
            attachment_id: clientAttachmentIds,
            expiry_date : moment(expiry_date).format("YYYY-MM-DD")
        }    
        await clientStore.addAttachments(data).then(
            (response) => {
                if (response) {                    
                    commonStore.addNotification(t('Saved'), null, 'success');
                    this.handleAttachmentModalCloseButton();
                    this.props.clientStore.resetLastListLoadTime();
                    return;
                }
            })
            .catch(
                (error) => {
                    commonStore.addNotification(error.message || t('Error'), null, 'error');
            }
        )
    }

    render() {
        const { signatureStore } = this.props;
        const { entity, schema, uiSchema, translationScope, t, docAttachments, disabled,
            listUrl, formContext, disallowSave, isBizType, userContract, handleDocument, assignDocument, handleAssignDocuments } = this.props;
        const { formData } = this.state;
        return (
            <Fragment>
            
                <Form
                    ref={(form) => {
                        this.form = form;
                    }}
                    formData={formData}
                    schema={intlSchema(schema, translationScope, t)}
                    onChange={(all) => this.onChange(all.formData, all)}
                    uiSchema={uiSchema}
                    fields={fields}
                    showErrorList={false}
                    widgets={widgets}
                    customFormats={customFormats}
                    onSubmit={(e) => this.onSubmit(e)}
                    transformErrors={(errors) => this.transformErrors(errors)}
                    validate={(formData, errors) => this.validateForm(formData, errors)}
                    liveValidate
                    noHtml5Validate
                >
                    <button
                        ref={(btn) => {
                            this.submitButton = btn;
                            if(this.props.setRef){
                                this.props.setRef(btn);
                            }
                        }}
                        className="hidden"
                    />
                </Form>

                {assignDocument &&
                    <DocumentTemplateWidget
                        user_id={entity.id ? entity.id : 0}
                        handleChnage={(ids) => handleAssignDocuments(ids)} />
                }

                {!disabled && (
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            ref={fileInput => {
                                this.fileInput = fileInput;
                            }}
                            onChange={this.onUpload}
                            multiple
                        />
                    )}

                {isBizType == true && (
                    <div style={{ paddingBottom: '10px' }}>
                        <button className="btn btn-fill" onClick={() => handleDocument()}>
                            {t('Document Management')}
                        </button>
                    </div>
                )}
                {userContract == true && entity.user_type != 'super-admin' && (
                    <UserContractList user_id={entity.id ? entity.id : 0}  />
                )}
                {!disallowSave && (
                    <button className="btn btn-fill" onClick={() => this.submitForm()}>
                        {t('Save')}
                    </button>
                )}
                {listUrl && (
                    <button className="btn" style={{ marginLeft: '20px' }} onClick={() => this.onCancel()}>
                        {t('Cancel')}
                    </button>
                )}
            </Fragment>
        );
    }
}

export default GenericForm;
