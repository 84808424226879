import React, { Component } from 'react';
import { Rnd } from 'react-rnd';
import { observer, inject } from 'mobx-react';
import withLocalization from '../../../../hoc/withLocalization';
import propertyStore from '../../../../library/stores/propertyStore';
import '../signdoc.css';


let textWidgetTimeout;
@inject('propertyStore', 'signatureStore')
@withLocalization
@observer
export class TextboxWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            x: this.props.info.widgetX || 0,
            y: this.props.info.widgetY || 0,
            width: this.props.info.width || '140px',
            height: this.props.info.height || '45px',
            data: this.props.info.data || ""

        }
    }

    onTextChange(evt, id) {
        const { propertyStore, signatureStore } = this.props;
        const txtdata = evt.target.value;
        if(textWidgetTimeout){
            clearTimeout(textWidgetTimeout);
        }
        textWidgetTimeout = setTimeout(() => {
            const data = { caseId: signatureStore.caseId, widgets: propertyStore.widgets }
            propertyStore.updateWidgetData(id, 'data',  txtdata);
          //  propertyStore.saveWidget(data);
        }, 1000);
        this.setState({ data: evt.target.value });
    }

    setActiveWidget(evt, widget) {
        const { propertyStore } = this.props;
        propertyStore.closeActivePanel();
        propertyStore.setActiveWidget(widget)

    }
    onDragStop = (evt, position, at) => {
        const { propertyStore, info } = this.props;
        const DEFAULT_WIDTH = 150;
        const DEFAULT_HEIGHT = 50;
        const updatedWidth = (propertyStore.activeWidget && propertyStore.activeWidget.width) ? propertyStore.activeWidget.width : ( this.state.width ? this.state.width : DEFAULT_WIDTH);
        const updatedHeight = (propertyStore.activeWidget && propertyStore.activeWidget.height) ? propertyStore.activeWidget.height : ( this.state.height ? this.state.height : DEFAULT_HEIGHT);
        const widInfo = { x: position.x, y: position.y,width:updatedWidth, height:updatedHeight  };
        propertyStore.updateWidgetRnd(info.widgetId, widInfo)
    }

    onResizeStop = (e, direction, ref, delta, position) => {
        const widInfo = { x: position.x, y: position.y, width: ref.style.width, height: ref.style.height }

        const { propertyStore, info } = this.props;
        propertyStore.updateWidgetRnd(info.widgetId, widInfo)
    }

    hexToRgbA(hex) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',.4)';
        }
        throw new Error('Bad Hex');
    }

    render() {
        const { x, y, width, height } = this.state;
        const { propertyStore } = this.props;
        const widgetColor = this.hexToRgbA(this.props.info ? this.props.info.colorCode : "#ffd65b");
        return (
            <>
                <Rnd
                    minHeight={45}
                    minWidth={100}
                    default={{
                        x,
                        y,
                        width,
                        height
                    }}
                    onDragStop={this.onDragStop}
                    onResizeStop={this.onResizeStop}
                    bounds="parent"
                    disableDragging={this.props.locked}
                    enableResizing={!this.props.locked}

                >
                    <div className="TextboxCon-txt" onClick={(evt) => { this.setActiveWidget(evt, this.props.info) }}>
                        <textarea onChange={(evt) => { this.onTextChange(evt, this.props.info) }}
                            placeholder={this.props.info.placeholder}
                            value={this.state.data}
                            disabled={this.props.edit ? false : true}
                            style={this.props.edit ? { resize: 'none' } : this.props.view ? { resize: 'none', border: 'none', overflow: 'hidden', background: 'transparent' } : { backgroundColor: widgetColor, resize: 'none' }}
                        ></textarea>
                    </div>
                </Rnd>
            </>
        )

    }
}

export default TextboxWidget