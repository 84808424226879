import React from 'react';
import PropTypes from 'prop-types';

export default function WeekNumber({ date, onClickWeekNumber, weekNumber, checkedWeekNumbers, disabledWeekNumbers }) {
    const props = {
        className: 'react-calendar__tile',
        style: { flexGrow: 1 },
    };

    const children = <span>{weekNumber}</span>;
    // console.log('WN', checkedWeekNumbers);

    return onClickWeekNumber ? (
        <label>
            <input
                type="checkbox"
                disabled={disabledWeekNumbers && disabledWeekNumbers.indexOf(parseInt(weekNumber)) >= 0}
                checked={checkedWeekNumbers && checkedWeekNumbers.indexOf(parseInt(weekNumber)) >= 0}
                onChange={() => onClickWeekNumber(weekNumber, date)}
            />
            &nbsp;
            {children}
            {/*<button
                  {...props}
                  onClick={event => onClickWeekNumber(weekNumber, date, event)}
                  type="button"
              >
                  {children}
              </button>*/}
        </label>
    ) : (
        <div {...props}>{children}</div>
    );
}

WeekNumber.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    onClickWeekNumber: PropTypes.func,
    weekNumber: PropTypes.node.isRequired,
    checkedWeekNumbers: PropTypes.array,
};
