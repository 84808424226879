import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Fullscreen from 'react-full-screen';
import { Col, ControlLabel, FormControl, FormGroup, Grid, Row } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Portal } from 'react-portal';

import Clock from '../../../elements/Clock';
import { isMobile } from '../../../utils/deviceDetector';
import withLocalization from '../../../hoc/withLocalization';
import { minutesToTimeInput } from '../../../utils/timeCalc';
import config from '../../../library/config';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import ListErrors from '../../../components/ListErrors';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import logoBlue from '../../../assets/img/logo_blue.svg';
import FlexCard from '../../../components/Card/FlexCard';
import { serverTimeFormat } from '~/library/core';
import NumKeypad from './NumKeypad';
import StatusList from './StatusList';
import Input from './TabledInput';

let LOCKED_MODE_RELOAD_TIMEOUT = null;

@inject('userStore', 'presenceStore', 'lockedStore', 'commonStore')
@withRouter
@withLocalization
@observer
class LockedMode extends Component {
    state = {
        password: '',
        errors: null,
        mobile: '',
        pin: '',
        inProgress: false,
        fullscr: false,
        project: null,
        confirmingDone: false,
        confirmingClosePrevious: false,
        focusedInput: null,
    };

    componentDidMount() {
        const { userStore } = this.props;
        if (userStore.currentUser.data && userStore.currentUser.data.project) {
            if (userStore.currentUser.data.project.value) {
                this.setState({ project: userStore.currentUser.data.project });
            } else {
                this.setState({ project: { value: userStore.currentUser.data.project, label: '' } });
            }
        }

        LOCKED_MODE_RELOAD_TIMEOUT = setTimeout(() => {
            window.location.reload();
        }, 60 * 60 * 1000);
    }

    componentWillUnmount() {
        if (LOCKED_MODE_RELOAD_TIMEOUT) {
            clearTimeout(LOCKED_MODE_RELOAD_TIMEOUT);
        }
    }

    toFullScreen = () => {
        this.setState({ fullscr: true });
    };

    fullScreenHandler = (fullscr) => {
        this.setState({
            fullscr,
        });
    };

    handleEmailChange = (e) => this.setState({ mobile: e.target.value });

    handlePasswordChange = (e) => this.setState({ pin: e.target.value });

    handleAction = async (data) => {
        const { lockedStore, commonStore, t } = this.props;
        const { modes } = lockedStore;
        data.project = this.state.project;
        data.currentTimelog = lockedStore.currentTimelog;
        // console.log('DT', data);
        await lockedStore.userAction(data);
        if (data.action == 'done') {
            // otherwise the UI becomes incorrect :(
            lockedStore.switchMode(modes.LOCKED_MENU);
        }
    };

    handleLogin = async (e) => {
        const { lockedStore, commonStore, t } = this.props;
        const { project, mobile, pin } = this.state;
        this.setState({ errors: null });
        if (!project || !project.value) {
            this.setState({ errors: [t('Please choose project')] });
            return;
        }
        if (!mobile) {
            this.setState({ errors: [t('Please enter mobile')] });
            return;
        }
        if (!pin) {
            this.setState({ errors: [t('Please enter pin')] });
            return;
        }
        try {
            const { timelog, previous, today } = await lockedStore.loginWithPin({
                mobile,
                pin,
                project,
            });
            // console.log(timelog, previousTimelog);
            if (previous) {
                this.setState({ confirmingClosePrevious: true });
            } else if (today && today.length) {
                console.log('TODAY is already', today);
            } else {
                this.handleAction({ action: 'start' });
            }
            this.clear();
        } catch (e) {
            console.log(e);
            this.clear();
            this.setState({ errors: e.response && e.response.body ? e.response.body.errors : [t('Error connecting')] }); // commonStore.showNotification(t('Invalid'), 'error');
        }
    };

    handleProjectChange = (e, f) => {
        this.setState({ project: f });
    };

    handleSendSms = () => {
        const { lockedStore, t } = this.props;
        const { mobile } = this.state;
        if (!mobile) {
            this.setState({ errors: [t('Please enter mobile')] });
            return;
        }
        lockedStore
            .sendSms({ mobile })
            .then(() => {
                this.setState({ errors: [t('New PIN code is sent to your mobile')] });
            })
            .catch((e) => {
                this.setState({ errors: e.response.body.errors }); // commonStore.showNotification(t('Invalid'), 'error');
            });
    };

    clear() {
        this.setState({
            pin: '',
            mobile: '',
            password: '',
            errors: null,
        });
    }

    async startLockMode(isLocked) {
        const { lockedStore } = this.props;
        const { password, project } = this.state;
        if (isLocked) {
            try {
                await lockedStore.stopLockedMode(password);
                this.clear();
            } catch (e) {
                this.setState({ errors: e.response && e.response.body ? e.response.body.errors : [e.message] });
            }
        } else {
            try {
                await lockedStore.startLockedMode(password, project);
                this.clear();
            } catch (e) {
                this.setState({ errors: e.response && e.response.body ? e.response.body.errors : [e.message] });
            }
        }
    }

    onChangeValue(target, value) {
        if (target === 'mobile') {
            this.setState({ mobile: value });
        } else if (target === 'password') {
            this.setState({ password: value });
        } else {
            this.setState({ pin: value });
        }
    }

    handleChangeInputFocus = (name) => {
        this.setState({
            focusedInput: name,
        });
    };

    renderUserPage = () => {
        const { lockedStore, t } = this.props;
        const { modes, timeout, timer, currentTimelog, currentLoginData } = lockedStore;
        const { confirmingDone, confirmingClosePrevious, project } = this.state;
        const user = lockedStore.emulatingUser || { data: {} };
        const totalSpentTodayNumber = lockedStore.totalSpentToday || 0;
        const totalSpentToday = minutesToTimeInput(totalSpentTodayNumber, false);
        const totalBreakNow = minutesToTimeInput(lockedStore.totalBreakToday || 0, false);
        const lockData = {};
        let currentStartTime = '?';

        if (currentTimelog) {
            lockData.mode = 'started';
            currentStartTime = minutesToTimeInput(currentTimelog.from, true);
        }
        if (currentTimelog && currentTimelog.tracker_status == 'break') lockData.mode = 'break';
        let finishedToday = '...';
        if (!currentTimelog && currentLoginData && currentLoginData.today && currentLoginData.today.length) {
            lockData.mode = 'done';
            finishedToday = minutesToTimeInput(currentLoginData.today[0].to, true);
            currentStartTime = minutesToTimeInput(currentLoginData.today[0].from, true);
        }
        return (
            <>
                <div className="locked-mode-user">
                    <h2 className="locked-mode-user__title">
                        {t('Welcome')} {user.first_name} {user.last_name}
                    </h2>
                    {currentTimelog && (
                        <h3 className="locked-mode-user__sub-title">
                            {moment(new Date(currentTimelog.for_date)).format(serverTimeFormat())}
                        </h3>
                    )}

                    {confirmingDone && (
                        <div className="locked-mode-user__logout">
                            <h4>{t('Are you sure to log out?')}</h4>
                            {totalSpentTodayNumber >= 9 * 60 && (
                                <>
                                    <span className="locked-mode-user__logout-hint">
                                        {t(
                                            'You have registered more time than your standard working day, do you want to register overtime?'
                                        )}
                                        {totalSpentTodayNumber >= 24 * 60 && (
                                            <b>
                                                {t(
                                                    'The system shows that you forgot to logg out, please check this. You can not register more then 24 hours.'
                                                )}
                                            </b>
                                        )}
                                    </span>
                                    <div className="locked-mode-user__logout-buttons">
                                        <Button
                                            round
                                            tablet
                                            wd
                                            onClick={() => {
                                                this.setState({ confirmingDone: false });
                                            }}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                        <Button
                                            round
                                            tablet
                                            wd
                                            onClick={() => {
                                                this.setState({ confirmingDone: false });
                                                this.handleAction({ action: 'done' });
                                            }}
                                        >
                                            {t('Save with overtime')}
                                        </Button>
                                        <Button
                                            round
                                            tablet
                                            wd
                                            onClick={() => {
                                                this.setState({ confirmingDone: false });
                                                this.handleAction({ action: 'done', disableOvertime: true });
                                            }}
                                        >
                                            {t('Save without overtime')}
                                        </Button>
                                    </div>
                                </>
                            )}
                            {totalSpentTodayNumber < 9 * 60 && (
                                <div className="locked-mode-user__logout-buttons">
                                    <Button
                                        round
                                        tablet
                                        wd
                                        onClick={() => {
                                            this.setState({ confirmingDone: false });
                                        }}
                                        style={{ marginRight: '20px' }}
                                    >
                                        {t('No')}
                                    </Button>
                                    <Button
                                        round
                                        tablet
                                        wd
                                        onClick={() => {
                                            this.setState({ confirmingDone: false });
                                            this.handleAction({ action: 'done' });
                                        }}
                                        style={{ marginRight: '20px' }}
                                    >
                                        {t('Yes')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}

                    {confirmingClosePrevious && (
                        <>
                            <span className="locked-mode-user__logout-hint">{t('Previous task not closed')}</span>
                            <span className="locked-mode-user__logout-hint locked-mode-user__logout-hint_small">
                                {t('Do you want to close it now?')}
                            </span>
                            <div className="locked-mode-user__logout-buttons">
                                <Button
                                    round
                                    tablet
                                    wd
                                    onClick={() => {
                                        this.setState({ confirmingClosePrevious: false });
                                        lockedStore.switchMode(modes.LOCKED_MENU);
                                    }}
                                    style={{ marginRight: '20px' }}
                                >
                                    {t('No')}
                                </Button>
                                <Button
                                    round
                                    tablet
                                    wd
                                    onClick={() => {
                                        this.setState({ confirmingClosePrevious: false });
                                        this.handleAction({ action: 'close-previous' });
                                    }}
                                    style={{ marginRight: '20px' }}
                                >
                                    {t('Yes')}
                                </Button>
                            </div>
                        </>
                    )}

                    {lockData.mode === 'done' && !confirmingClosePrevious && (
                        <>
                            <span className="locked-mode-user__logout-hint">
                                {t('You have logged out at')}: {finishedToday}. {t('Good job')}
                            </span>
                            <div className="locked-mode-user__logout-buttons">
                                <Button
                                    round
                                    tablet
                                    wd
                                    fill
                                    onClick={() => this.handleAction({ action: 'start-over', project })}
                                    style={{ marginRight: '20px' }}
                                >
                                    {t('Re-start work')}
                                </Button>
                            </div>
                        </>
                    )}
                    {!confirmingDone && !confirmingClosePrevious && (
                        <Grid fluid>
                            {!confirmingDone && !confirmingClosePrevious && (
                                <Row>
                                    <Col lg={4} sm={6}>
                                        <FlexCard
                                            title={project.label}
                                            desc={`${t('Started at')}: ${currentStartTime}`}
                                            icon={<i className="fa fa-play" style={{ color: '#87cb16' }} />}
                                        />
                                    </Col>
                                    <Col lg={4} sm={6}>
                                        <FlexCard
                                            title={t('Total')}
                                            desc={`${t('Total time today')}: ${totalSpentToday}`}
                                            icon={<i className="pe-7s-stopwatch" style={{ color: '#447df7' }} />}
                                        />
                                    </Col>
                                    <Col lg={4} sm={6}>
                                        <FlexCard
                                            title={t('Breaktime')}
                                            desc={`${t('Break today:')} ${totalBreakNow}`}
                                            icon={<i className="pe-7s-coffee" style={{ color: '#ffa534' }} />}
                                        />
                                    </Col>
                                    {lockData.mode === 'break' && !confirmingClosePrevious && !confirmingDone && (
                                        <Col lg={4} sm={6}>
                                            <FlexCard
                                                title={t('Break start')}
                                                icon={<i className="pe-7s-stopwatch" style={{ color: '#ffa534' }} />}
                                                desc={`${t('Break started at')}: ${minutesToTimeInput(
                                                    currentTimelog.data.breakStarted,
                                                    true
                                                )}`}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            )}
                        </Grid>
                    )}
                </div>
                <div className="locked-page__footer">
                    <div className="locked-page__footer-nav">
                        <button
                            className="locked-page__footer-btn"
                            type="button"
                            onClick={() => lockedStore.switchMode(modes.LOCKED_MENU)}
                        >
                            <i className="fa fa-arrow-left" />
                        </button>
                        {!!timer && (
                            <div className="locked-page__footer-left-time">
                                <span>
                                    {t('Auto log off in')} {timeout < 0 ? 0 : timeout}.
                                    <button type="button" onClick={() => lockedStore.stopTimer()}>
                                        {t('Stop timer')}
                                    </button>
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="locked-page__footer-buttons">
                        {!confirmingClosePrevious && !confirmingDone && lockData.mode === 'started' && (
                            <>
                                <Button
                                    round
                                    tablet
                                    fill
                                    wd
                                    onClick={() => this.handleAction({ action: 'start-break' })}
                                >
                                    {t('Start break')}
                                </Button>
                                <Button
                                    round
                                    tablet
                                    wd
                                    fill
                                    onClick={() => this.setState({ confirmingDone: true })}
                                    style={{ marginLeft: 'auto' }}
                                >
                                    {t('Done today')}
                                </Button>
                            </>
                        )}
                        {lockData.mode === 'break' && !confirmingClosePrevious && !confirmingDone && (
                            <Button
                                round
                                tablet
                                fill
                                wd
                                onClick={() => this.handleAction({ action: 'stop-break' })}
                                style={{ marginRight: '20px' }}
                            >
                                {t('End break')}
                            </Button>
                        )}
                    </div>
                </div>
            </>
        );
    };

    renderLockedMenu = () => {
        const { lockedStore, t } = this.props;
        const { modes } = lockedStore;
        const { errors, project, pin, inProgress, mobile } = this.state;
        let _project = project;
        if (project && project.value) _project = project.value;
        setTimeout(() => {
            try {
                if (!isMobile()) {
                    document.forms[0].mob.readOnly = false;
                    document.forms[0].pin.readOnly = false;
                }
            } catch (e) {}
        }, 1000);

        return (
            <>
                <div className="locked-mode-login">
                    <div className="locked-mode-login__header">
                        <ListErrors errors={errors} />
                    </div>
                    <div className="locked-mode-login__content">
                        <div className="locked-mode-login__content-left">
                            <img src={logoBlue} className="locked-mode-login__logo" alt="Norsk Time" />
                            <Input
                                label={t('Mobile / Employee ID')}
                                inputMode="numeric"
                                placeholder="####"
                                name="mob"
                                autoFocus
                                autoComplete="disabled"
                                value={mobile}
                                type="phone"
                                onChange={this.handleEmailChange}
                                readOnly
                                onFocus={() => this.handleChangeInputFocus('mobile')}
                            />
                            <Input
                                label={t('PIN')}
                                placeholder="PIN"
                                name="pin"
                                inputMode="numeric"
                                autoComplete="disabled"
                                type="password"
                                value={pin}
                                onChange={this.handlePasswordChange}
                                readOnly
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        this.handleLogin();
                                    }
                                }}
                                onFocus={() => this.handleChangeInputFocus('pin')}
                            />
                            <div className="locked-mode-login__buttons">
                                <Button bsStyle="info" tablet fill wd disabled={inProgress} onClick={this.handleLogin}>
                                    {t('Login')}
                                </Button>
                                <Button
                                    bsStyle="info"
                                    tablet
                                    wd
                                    disabled={inProgress}
                                    onClick={this.handleSendSms}
                                    style={{ marginLeft: '20px' }}
                                >
                                    {t('Reset PIN code')}
                                </Button>
                            </div>
                        </div>
                        <div className="locked-mode-login__content-right">
                            <NumKeypad
                                t={t}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={this.handleChangeInputFocus}
                                mobile={mobile}
                                pin={pin}
                                onChangeValue={(t, v) => this.onChangeValue(t, v)}
                                onSubmit={this.handleLogin}
                            />
                        </div>
                    </div>
                </div>
                <div className="locked-page__footer">
                    <Button
                        round
                        tablet
                        wd
                        onClick={() => lockedStore.switchMode(modes.STATUS)}
                        style={{ margin: '5px 5px 5px 0', borderColor: 'green', color: 'green' }}
                    >
                        {t('Show status')}
                    </Button>
                    <Button
                        round
                        tablet
                        wd
                        onClick={() => lockedStore.switchMode(modes.EXITING)}
                        style={{ margin: '5px 0 5px auto' }}
                    >
                        {t('Quit stamping mode')}
                    </Button>
                </div>
            </>
        );
    };

    renderStatusList = () => {
        const { lockedStore, t } = this.props;
        const { modes } = lockedStore;
        const { project } = this.state;
        const mode = lockedStore.currentMode;

        return <StatusList project={project} />;
    };

    renderHeader(isLocked, mode, modes) {
        const { commonStore, userStore, lockedStore } = this.props;
        const { config: clientConfig } = commonStore;
        const { t } = this.props;
        const { currentUser } = userStore;
        return (
            <div className="locked-page__header">
                <div className="locked-page__header-col locked-page__header-col_no-grow">
                    {clientConfig.client.image ? (
                        <img
                            alt="logo"
                            src={`${config.UPLOADS_API_ENDPOINT}/${clientConfig.client.image}`}
                            style={{ height: '42px' }}
                        />
                    ) : (
                        <i className="pe-7s-wallet text-warning" />
                    )}
                </div>
                <div className="locked-page__header-col">
                    <h4>{clientConfig.client.name}</h4>
                    <small>
                        {t('Reg.No')} {clientConfig.client.tax_number}
                    </small>
                </div>
                <div className="locked-page__header-col">
                    <h4>{moment(new Date()).format('dddd L')}</h4>
                    <small>{currentUser.data.project ? currentUser.data.project.label : ''}</small>
                </div>
                <div className="locked-page__header-col locked-page__header-col_no-grow locked-page__header-col_left">
                    <Clock />
                </div>
            </div>
        );
    }

    renderLoginLogout(login) {
        const { t, lockedStore } = this.props;
        const { modes } = lockedStore;
        const { errors, password, project } = this.state;
        const isLocked = !login;

        let _project = project;
        if (project && project.value) _project = project.value;

        setTimeout(() => {
            try {
                if (!isMobile()) {
                    document.forms[0].pass.readOnly = false;
                }
            } catch (e) {}
        }, 1000);
        return (
            <>
                <div className="locked-init-screen">
                    <h3 className="locked-init-screen__title">
                        {t(!login ? 'Stop' : 'Start')} {t('stamping mode')}
                    </h3>
                    <ListErrors errors={errors} />
                    <div className="locked-init-screen__content">
                        <div className="locked-init-screen__content-fields">
                            <Input
                                label={t('Password')}
                                placeholder="*****"
                                name="pass"
                                autoComplete="off"
                                readOnly
                                type="password"
                                value={password}
                                onChange={(e) => this.setState({ password: e.target.value })}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        this.startLockMode(isLocked);
                                    }
                                }}
                            />
                            {login && (
                                <div className="locked-init-screen__project">
                                    <span>{t('Project')}</span>
                                    <ProjectsWidget
                                        applyOnFirstLoad
                                        value={_project || null}
                                        onChange={(e, f) => this.handleProjectChange(e, f)}
                                        style={{ border: '1px solid' }}
                                    />
                                </div>
                            )}
                            <Button
                                fill
                                round
                                tablet
                                wd
                                onClick={() => this.startLockMode(isLocked)}
                                style={{ margin: '0 auto' }}
                            >
                                {t(!login ? 'Stop' : 'Start')}
                            </Button>
                        </div>
                        <div className="locked-init-screen__content-keyboard">
                            <NumKeypad
                                t={t}
                                simple
                                password={password}
                                onChangeValue={(name, v) => this.onChangeValue('password', v)}
                                onSubmit={() => this.startLockMode(isLocked)}
                            />
                        </div>
                    </div>
                </div>
                {!login && (
                    <div className="locked-page__footer">
                        <div className="locked-page__footer-nav">
                            <button
                                className="locked-page__footer-btn"
                                type="button"
                                onClick={() => lockedStore.switchMode(modes.LOCKED_MENU)}
                            >
                                <i className="fa fa-arrow-left" />
                            </button>
                        </div>
                    </div>
                )}
            </>
        );
    }

    pageSwitcher = (isLocked, mode, modes) => {
        if (!isLocked) {
            return this.renderLoginLogout(true);
        }
        if (isLocked && mode === modes.EXITING) {
            return this.renderLoginLogout(false);
        }
        if (mode === modes.USERPAGE) {
            return this.renderUserPage();
        }
        if (mode === modes.LOCKED_MENU) {
            return this.renderLockedMenu();
        }
        if (mode === modes.STATUS) {
            return this.renderStatusList();
        }
        return null;
    };

    render() {
        const { userStore, lockedStore } = this.props;
        const { currentUser } = userStore;
        const { fullscr } = this.state;
        const isLocked = currentUser.is_now_locked;
        const { currentMode: mode, modes } = lockedStore;
        const Wrapper = isLocked ? Portal : Fragment;

        return (
            <Wrapper className="sss">
                <Fullscreen enabled={!!fullscr && !!isLocked} onChange={this.fullScreenHandler}>
                    <form autoComplete="disabled" onClick={this.toFullScreen}>
                        <input autoComplete="disabled" name="hidden" type="text" style={{ display: 'none' }} />
                        <div className={`full-screenable-node locked-page ${isLocked ? 'locked-page_block' : ''}`}>
                            <div className="locked-page__inner">
                                {this.renderHeader(isLocked, mode, modes)}
                                <div className={`locked-page__content mode__${mode}`}>
                                    {this.pageSwitcher(isLocked, mode, modes)}
                                </div>
                            </div>
                        </div>
                    </form>
                </Fullscreen>
            </Wrapper>
        );
    }
}

export default LockedMode;
