import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { confirmAlert } from 'react-confirm-alert';

import LoadingSpinner from '../../../elements/LoadingSpinner';
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Input, Textarea } from '../../../elements/Input';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';

@inject('timelogStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class AcceptFormBlock extends Component {
    state = {
        notes: '',
        status:''
    };
    
    componentDidMount(){
        if(this.props && this.props.params.data && this.props.params.data.absence && this.props.params.data.absence.status_note){

            this.setState({notes : this.props.params.data.absence.status_note,
                            status:this.props.params.data.absence.status});

        }
        if(this.props && this.props.params.data && this.props.params.data.vacation &&  this.props.params.data.vacation.status_note){

            this.setState({notes : this.props.params.data.vacation.status_note,
                            status:this.props.params.data.vacation.status});

        }
    }
    getaction(action){
        if(action == 'rejected'){
            return 'reject this';
        }
        else if(action == 'approved'){
            return 'approve this'
        }
        else if(action == 'reset'){
            return 'reset this'
        }
        else{
            return 'perform this Action';
        }
    }
    async handleChangeStatus(action) {
        // console.log("action",action)
        if (action == 'rejected' && !this.state.notes) {
            this.props.commonStore.addNotification(this.props.t('Comment is required'), null, 'error');
            return;
        }  
        const {
            timelogStore, commonStore, t, params, afterSave
        } = this.props;
        if(this.state.status == 'approved' || this.state.status == 'rejected' || action == 'reset'){
          
            const str = this.props.t(`Are you sure to `+this.getaction(action)+ `?`);
                           return confirmAlert({
                                // title: this.props.t(action),
                                message: str,
                                buttons: [
                                    {
                                        label: this.props.t('Yes'),
                                        onClick: async () => {
                                            await timelogStore.setStatus(params.id, params.entity, action, { notes: this.state.notes });
                                            if (afterSave) afterSave();
                                        },
                                    },
                                    {
                                        label: this.props.t('No'),
                                        onClick: () => {},
                                    },
                                ],
                            });
        }
            else{               
                await timelogStore.setStatus(params.id, params.entity, action, { notes: this.state.notes });
                if (afterSave) afterSave();
            }
      
    }

    render() {
        const {
            timelogStore, commonStore, t, params
        } = this.props;
        if (!params) return null;
        let { status } = params;
        if (!status || status === 'null') status = 'active';
        return (
            <div style={{ marginTop: '20px' }}>    
                <div className={`status-block status-${status}`} style={{ marginRight: '20px' }}>
                    {t(status)}
                </div>
                {status === "approved" ? <div>
                <Button
                    fill
                    wd
                    icon
                    type="submit"
                    style={{ backgroundColor: 'darkorange', border: 0 }}
                    onClick={() => this.handleChangeStatus('reset')}
                >
                    {' '}
                    <i className="pe-7s-refresh" /> {t('Reset approval')}
                </Button>
                    
                </div> : <div>
                    
                <Textarea
                    label={t('Comment')}
                    value={this.state.notes}
                    onChange={e => this.setState({ notes: e.target.value })}
                    placeholder={t('Enter comments here')}
                    required
                />

                <Button
                    fill
                    wd
                    icon
                    type="submit"
                    style={{ backgroundColor: 'green', marginRight: 30, border: 0 }}
                    onClick={() => this.handleChangeStatus('approved')}
                >
                    {' '}
                    <i className="fa fa-check" /> {t('Accept')}
                </Button>
                <Button
                    fill
                    wd
                    icon
                    type="submit"
                    style={{ backgroundColor: 'red', marginRight: 30, border: 0 }}
                    onClick={() => this.handleChangeStatus('rejected')}
                >
                    {' '}
                    <i className="fa fa-times" /> {t('Reject')}
                </Button>
                <Button
                    fill
                    wd
                    icon
                    type="submit"
                    style={{ backgroundColor: 'darkorange', border: 0 }}
                    onClick={() => this.handleChangeStatus('reset')}
                >
                    {' '}
                    <i className="pe-7s-refresh" /> {t('Reset approval')}
                </Button>
                    </div>}            
               
            </div>
        );
    }
}

export default AcceptFormBlock;
