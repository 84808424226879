import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import BizTypesList from './BizTypesList';
import BizTypesForm from './BizTypesForm';

import withRemoveDialog from '~/hoc/withRemoveDialog';

@inject('authStore', 'biztypeStore')
@withRouter
@withRemoveDialog
@observer
class BizTypesPage extends Component {
    onAdd() {
        const { history } = this.props;
        history.push('/admin/biztypes/add');
    }

    onTableAction(id, type) {        
        const { history,commonStore,t } = this.props;
        if (type === 'remove') 
            return this.props.handleDelete(id, id => this.props.biztypeStore.remove(id).then(res =>{
                commonStore.addNotification(t('Deleted'), null, 'error');
                history.push('/admin/biztypes/');
            }));            
              
        history.push(`/admin/biztypes/${id}`);
    }

    editingId = () => {
        let id = null;
        if (this.props.location.pathname !== this.props.match.path) {
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }
        return { editing: !!id, add: id === 'add', id };
    };

    render() {
        const mode = this.editingId();
        return (
            <div className="main-content">
                {!mode.editing && (
                    <BizTypesList
                        onTableAction={(id, type) => this.onTableAction(id, type)}
                        onAdd={() => this.onAdd()}
                    />
                )}
                {mode.editing && <BizTypesForm id={mode.id} add={mode.add} />}
            </div>
        );
    }
}
export default BizTypesPage;
