import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';

import withLocalization from '../../../hoc/withLocalization';

// Components
import Button from '../../../components/CustomButton/CustomButton.jsx';
import GenericList from '../../../components/GenericList/GenericList';

import ExtraDocsFilter from './ExtraDocsFilter';

@inject('clientStore', 'commonStore', 'extradocStore', 'userStore')
@withRouter
@withLocalization
@observer
class ExtraDocList extends Component {
    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        Object.keys(query).forEach(key => {
            if (key === 'name') {
                this.props.extradocStore.setFilter(key, query[key]);
            }
        });
        this.props.extradocStore.onFilter();
    }

    renderTableActionButtons = id => {
        const {
            userStore: { currentUser },
        } = this.props;

        const userType = currentUser.user_type;
        return (
            <div className="actions-right">
                <Button onClick={() => this.props.onDetails(id)} bsStyle="info" simple icon>
                    <i className="fa fa-edit" />
                </Button>
                {userType === 'admin' && (
                    <Button onClick={() => this.props.onDelete(id)} bsStyle="danger" simple icon>
                        <i className="fa fa-times" />
                    </Button>
                )}
            </div>
        );
    };

    render() {
        const {
            userStore: { currentUser },
        } = this.props;
        const {
            config, t, mode, isUnion
        } = this.props;
        const { filters, appliedFilters, loading } = this.props.extradocStore;
        const userType = currentUser.user_type;
        const allowAdd = userType !== 'member' || isUnion;
        return (
            <div className="table-list">
                <div className="table-list__header">
                    {allowAdd && (
                        <Button fill wd icon onClick={() => this.props.onAdd()}>
                            {t('Add new')}
                            <i className="fa fa-plus" />
                        </Button>
                    )}
                </div>
                <GenericList
                    columns={[
                        {
                            Header: 'ID',
                            id: 'id',
                            accessor: 'id',
                        },
                        {
                            Header: 'Name',
                            id: 'name',
                            accessor: ({ name, id, doc_type }) => (
                                <span>
                                    <NavLink
                                        to={`/admin/${doc_type == 'contracts' ? 'contracts' : 'internaldocs'}/${id}`}
                                    >
                                        {name}
                                    </NavLink>
                                </span>
                            ),
                        },
                        {
                            Header: 'Actions',
                            id: 'actions',
                            sortable: false,
                            accessor: ({ id }) => this.renderTableActionButtons(id),
                        },
                    ]}
                    filters={appliedFilters}
                    forceReload={this.props.extradocStore.deleteSuccess}
                    lastListLoadTime={this.props.extradocStore.lastListLoadTime}
                    header={(
                        <ExtraDocsFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.extradocStore.setFilter(name, value)}
                            onFilter={() => this.props.extradocStore.onFilter()}
                            loading={loading}
                        />
                    )}
                    requestData={params => this.props.extradocStore.loadList(params, mode)}
                />{' '}
                {/*
                 */}
            </div>
        );
    }
}
export default ExtraDocList;
