import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import TableWidget from '../TableWidget.js';

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class AbsentTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            absent_types  : [],
        }
        this.loadData = this.loadData.bind(this);        
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.extendedRules) {
            const extendedRules = Object.assign({}, currentEntity.client.data.extendedRules);
            this.setState({                
                absent_types: extendedRules.absent_types
            })
        }
    }

    render() {
        const { absent_types} = this.state;        
        return (
            <div>
                <TableWidget
                    headers={['code', 'Label']}
                    metaData = {[{field:'code',type:'text',placeholder:'code', required: true},
                        {field:'name',type:'text',placeholder:'Label', required: true}]}
                    data={absent_types}
                    onChange = {data => this.props.handleUserDataChange('absent_types', data)}
                />
            </div >
        );
    }
}
export default AbsentTypes;
