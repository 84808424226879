import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Row, Col, Tab } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import Input, { Textarea, NumberInput } from '../../../../elements/Input';

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class DeviationRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
            dueDateEmailToBeSentBeforeDays: "-1",
            deviationRules: {
                strings: ""
            },
            subTab: 'created',
            langIndex: 'en',
        }
        this.handleSubTabChange = this.handleSubTabChange.bind(this);
        this.getLangTranslation = this.getLangTranslation.bind(this);
        this.handleLangChange = this.handleLangChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.renderEmailAndNotification = this.renderEmailAndNotification.bind(this);
        this.handleClientDataChange = this.handleClientDataChange.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData()
    }

    componentWillMount() {
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const { deviationRules } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.deviations) {
            const deviation = Object.assign({}, currentEntity.client.data.deviations);
            deviationRules.strings = JSON.parse(deviation.strings);
            this.setState({
                dueDateEmailToBeSentBeforeDays: deviation.dueDateEmailToBeSentBeforeDays,
                deviationRules
            },
                () => this.setState({ isLoad: true }))
        }
    }

    handleClientDataChange = (name, value) => {
        // console.log(name, value);
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data.deviations) {
            currentEntity.client.data.deviations[name] = value;
        } else {
            //  cursor-pointer("No deviation rules found");
            // let basicRules = {} ;
            // basicRules[name]=value;
            // currentEntity.client.data.basicRules = basicRules;
        }
    }

    handleSubTabChange = (tabName) => {
        this.setState({ subTab: tabName })
    }

    handleLangChange = (index) => {
        this.setState({ langIndex: index })
    }

    handleTextChange = (name, newValue) => {
        const { deviationRules, langIndex } = this.state;
        let dataString = deviationRules.strings;
        let dataRow = dataString.find(item => item.key === name);
        let translations = dataRow.translations.find(item => item.lang === langIndex)
        if (!translations) {
            dataRow.translations.push({ 'lang': langIndex, 'value': newValue });
        } else {
            translations.value = newValue;
        }
        deviationRules.strings = dataString
        this.handleClientDataChange('strings', JSON.stringify(deviationRules.strings));
        this.setState({ deviationRules });
    }

    getLangTranslation = (element) => {
        const { langIndex } = this.state;
        let translation = element.translations.filter(item => item.lang === langIndex)
        if (translation.length > 0) {//if translations found for current lang
            return translation[0]
        }
        else {//if no translations found for current lang then return default
            return element.translations[0]
        }
    }

    renderEmailAndNotification = (subjectKey, bodyKey, notificationKey, type) => {
        const { t } = this.props;
        const { deviationRules } = this.state;

        let subject = deviationRules.strings.find(item => item.key === subjectKey)
        let body = deviationRules.strings.find(item => item.key === bodyKey)
        let notification = notificationKey;

        let subjectTranslation = this.getLangTranslation(subject);
        let bodyTranslation = this.getLangTranslation(body);
        let notificationTranslation = '';

        if (notificationKey) {
            notification = deviationRules.strings.find(item => item.key === notificationKey);
            notificationTranslation = this.getLangTranslation(notification);
        }

        return (
            <Row>
                <Col sm={10}>
                    <Input
                        label={`${t(type)} ${t('Email Subject')}`}
                        type="text"
                        name={subject.key}
                        value={subjectTranslation.value}
                        onChange={evt => {
                            this.handleTextChange(subject.key, evt.target.value);
                        }}
                    />
                </Col>
                <Col sm={10}>
                    <Textarea
                        label={`${t(type)} ${t('Email Body')}`}
                        name={body.key}
                        value={bodyTranslation.value}
                        onChange={evt => {
                            this.handleTextChange(body.key, evt.target.value);
                        }}
                    />
                </Col>
                {notification &&
                    <Col sm={10}>
                        <Input
                            label={`${t(type)} ${t('Notification')}`}
                            type="text"
                            name={notification.key}
                            value={notificationTranslation.value}
                            onChange={evt => {
                                this.handleTextChange(notification.key, evt.target.value);
                            }}
                        />
                    </Col>
                }
            </Row>
        )
    }

    render() {
        const { subTab, dueDateEmailToBeSentBeforeDays, langIndex, isLoad } = this.state;
        const { t } = this.props;
        return (
            <div>
                <Row>
                    <Col sm={6}>
                        <NumberInput
                            label={`${t('Due date email to be sent days, comma-separated')} *`}
                            name="dueDateEmailToBeSentBeforeDays"
                            value={dueDateEmailToBeSentBeforeDays}
                            onChange={evt => {
                                this.setState({ dueDateEmailToBeSentBeforeDays: evt.target.value })
                                this.handleClientDataChange('dueDateEmailToBeSentBeforeDays', evt.target.value)
                            }
                            }
                        />
                    </Col>
                    <Col sm={12}>
                        <p className="text-muted"><i>{t('(0 - on due date, -1 - day before, 1 - day after etc)')}</i></p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <ul className="list-group row-flex">
                            <li className={langIndex === 'en' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                onClick={() => this.handleLangChange('en')}>
                                EN
                            </li>
                            <li className={langIndex === 'no' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                onClick={() => this.handleLangChange('no')}>
                                NO
                            </li>
                            <li className={langIndex === 'pl' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                onClick={() => this.handleLangChange('pl')}>
                                PL
                            </li>
                            <li className={langIndex === 'lt' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                onClick={() => this.handleLangChange('lt')}>
                                LT
                            </li>
                            <li className={langIndex === 'ru' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                onClick={() => this.handleLangChange('ru')}>
                                RU
                            </li>
                        </ul>
                    </Col>
                </Row>
                {isLoad &&
                    <div className="custom-card">
                        <Tab.Container activeKey={subTab} id="DeviationTabContainer" onSelect={(key) => {}}>
                            <Row>
                                <Col sm={3}>
                                    <ul className="list-group" >
                                        <li className={subTab === 'created' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                            onClick={() => this.handleSubTabChange('created')}>{t('Created')}</li>
                                        <li className={subTab === 'assigned' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                            onClick={() => this.handleSubTabChange('assigned')}>{t('Assigned')}</li>
                                        <li className={subTab === 'takenToWork' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                            onClick={() => this.handleSubTabChange('takenToWork')}>{t('Taken To Work')}</li>
                                        <li className={subTab === 'rejectedByResponsible' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                            onClick={() => this.handleSubTabChange('rejectedByResponsible')}>{t('Rejected By Responsible')}</li>
                                        <li className={subTab === 'complete' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                            onClick={() => this.handleSubTabChange('complete')}>{t('Completed')}</li>
                                        <li className={subTab === 'approved' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                            onClick={() => this.handleSubTabChange('approved')}>{t('approved')}</li>
                                        <li className={subTab === 'rejected' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                            onClick={() => this.handleSubTabChange('rejected')}>{t('rejected')}</li>
                                        <li className={subTab === 'dueDate' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                            onClick={() => this.handleSubTabChange('dueDate')}>{t('Due Date')}</li>
                                    </ul>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content >
                                        <Tab.Pane eventKey="created">
                                            {this.renderEmailAndNotification("createdEmailSubject",
                                                "createdEmailBody", "createdNotification", 'Created')}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="assigned">
                                            {this.renderEmailAndNotification("assignedEmailSubject",
                                                "assignedEmailBody", "assignedNotification", 'Assigned')}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="takenToWork">
                                            {this.renderEmailAndNotification("takenToWorkSubject",
                                                "takenToWorkEmailBody", "takenToWorkNotification", 'Taken To Work')}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="rejectedByResponsible">
                                            {this.renderEmailAndNotification("rejectedByResponsibleEmailSubject",
                                                "rejectedByResponsibleEmailBody",
                                                "rejectedByResponsibleNotification",
                                                'Rejected By Responsible')}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="complete">
                                            {this.renderEmailAndNotification("completeEmailSubject",
                                                "completeEmailBody", "completeNotification", 'Complete')}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="approved">
                                            {this.renderEmailAndNotification("approvedEmailSubject",
                                                "approvedEmailBody", "approvedNotification", 'approved')}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="rejected">
                                            {this.renderEmailAndNotification("rejectedEmailSubject",
                                                "rejectedEmailBody", "rejectedNotification", 'rejected')}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="dueDate">
                                            {this.renderEmailAndNotification("dueDateApproachingSubject",
                                                "dueDateApproachingBody", false, 'Due Date')}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                    }
            </div>
        );
    }
}
export default DeviationRules;
