import React from 'react';

const Input = ({
    label, className = '', style, ...rest
}) => {
    return (
        <label className={`locked-page__input ${className}`} style={style}>
            {label && <span>{label}</span>}
            <input {...rest} />
        </label>
    );
};

export default Input;
