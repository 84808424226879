import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import customerFeature from '../../../utils/customerFeature';

import ExtraDocList from './ExtraDocsList';
import ExtraDocForm from './ExtraDocForm';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

@inject('authStore', 'extradocStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ExtraDocPage extends Component {
    editingId = () => {
        let id = null;
        if (this.props.location.pathname !== this.props.match.path) {
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }
        return { editing: !!id, add: id === 'add', id };
    };

    onAdd = () => {
        const { history, mode } = this.props;
        history.push(`/admin/${mode == 'contracts' ? 'contracts' : 'internaldocs'}/add`);
    };

    onDeleteListItem = id => {
        return this.props.handleDelete(id, id => {
            return this.props.extradocStore.remove(id).then(res => {
                this.props.extradocStore.resetLastListLoadTime();
                return res;
            });
        });
    };

    onDelete = id => {
        const { history, mode } = this.props;
        return this.props.handleDelete(id, id => {
            return this.props.extradocStore.remove(id).then(res => {
                history.push(`/admin/${mode == 'contracts' ? 'contracts' : 'internaldocs'}`);
                return res;
            });
        });
    };

    onDetails(id) {
        const { history, mode } = this.props;
        history.push(`/admin/${mode == 'contracts' ? 'contracts' : 'internaldocs'}/${id}`);
    }

    render() {
        const mode = this.editingId();
        const docMode = this.props.mode;
        const isUnionMode = customerFeature('union_group');
        return (
            <div className="main-content">
                {!mode.editing && (
                    <ExtraDocList
                        mode={docMode}
                        isUnion={isUnionMode}
                        onDetails={id => this.onDetails(id)}
                        onAdd={id => this.onAdd(id)}
                        onDelete={id => this.onDeleteListItem(id)}
                    />
                )}
                {mode.editing && (
                    <ExtraDocForm
                        isUnion={isUnionMode}
                        onDelete={this.onDelete}
                        mode={docMode}
                        id={mode.id}
                        add={mode.add}
                    />
                )}
            </div>
        );
    }
}

export const ExtraDocsContracts = props => <ExtraDocPage {...props} mode="contracts" />;
export const ExtraDocsInternal = props => <ExtraDocPage {...props} mode="internal" />;

export default ExtraDocPage;
