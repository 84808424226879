import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Modal, Col, Tabs, Tab, Button } from 'react-bootstrap';
import withLocalization from '../../../../../hoc/withLocalization';
import { components } from 'react-select';
import { withRouter, Link } from 'react-router-dom';
import Switch from 'react-switch';


@inject('commonStore', 'signatureStore')
@withLocalization
@withRouter
@observer
export class AgreementModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAgreementModal: false,
            isChecked: false,
            isOpen: true,
            checked: false
        };
    }

    componentWillUnmount() { }

    handleAgreementModal = () => {
        this.setState({ showAgreementModal: !this.state.showAgreementModal });
    };

    onCheckChange(evt) {
        this.setState({ isChecked: !this.state.isChecked });
    }

    handleMove = () => {
        const { history } = this.props;
        history.push('/admin');
    }

    handleSwitchChange = () => {
        this.setState({ checked: !this.state.checked }, () => {
            this.props.commonStore.setAppLang(this.state.checked ? 'en' : 'no');
        })
    }
    render() {
        const { t } = this.props;
        const { type, checked } = this.state;
        return (
            <>
                <Modal show={this.props.show} className="AgreementModal" id="agreement">
                    <Modal.Header style={{ background: '#f0f4f7', borderRadius: '8px 8px 0px 0px' }} >

                        <span className="close-button cursor-pointer" onClick={this.handleMove}>&times;</span>
                        <Modal.Title style={{ fontWeight: 'bolder' }}>{t('Review & Act on this document')}</Modal.Title>
                        <div className='language-container'>
                            <Switch
                                height={20} width={40}
                                checked={checked}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onColor={'#128eb8'}
                                onChange={() => this.handleSwitchChange()}

                            />
                            <p style={{ paddingLeft: '10px' }}>
                                <span>{checked ? "EN" : "NO"}</span>
                            </p>

                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='aggreement-main'>
                            <div className="agreement-modal-body">
                                <h4 style={{ fontWeight: 'bold' }}>{t('Sender')}</h4>
                                <h5 style={{ fontWeight: 'bold' }}>{this.props.user}</h5>
                                <p>{this.props.email}</p>
                            </div>

                            <hr></hr>

                            <p>

                                {t(`By selecting the 'I accept' button, you are signing this agreement electronically. You agree your electronic signature is the legal equivalent of your manual signature on this agreement. By selecting 'I accept' you consent to be legally bound by this agreement's terms and conditions.`)}
                            </p>
                            <p>{t('Please read the Electronic Record and Signature Disclosure')}</p>
                            <input
                                type="checkbox"
                                checked={this.state.isChecked}
                                onChange={(evt) => {
                                    this.onCheckChange(evt);
                                }}
                                className="check-box"
                            ></input>
                            <label style={{ paddingLeft: '10px' }}>{t('I agree to use electronic records and signature')}</label>
                            <p></p>

                            <Button
                                className="btn btn-fill"
                                disabled={!this.state.isChecked}
                                type="button"
                                onClick={() => {
                                    this.props.handleAgreement();
                                }}
                            >
                                {t('Continue')}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default AgreementModal;
