import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormControl } from 'react-bootstrap';
import { filter, find } from 'lodash';
import config from '~/library/config';
import agent from '~/library/agent';
import {optionStatus} from '../../../utils/constants';
import Select from '../../../elements/Select'; 
import Button from '../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '../../../hoc/withLocalization';
@inject('commonStore')
@withLocalization
@observer
class CaselistFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
      this.translateLabels = this.translateLabels.bind(this);
    }
     translateLabels = () => {
        const { t } = this.props;
        optionStatus.map(item => {
            item.label = t(item.title)
        })
    }
    render() {
        const {
            filters, setFilter, onFilter
        } = this.props;
        const { status } = filters;
        const currentSelect = find(optionStatus, o => {
            return o.value === status;
        });
        this.translateLabels();
        return (
            <div className="list-filters__item list-filters__item_grow" style={{ position:'absolute',marginTop:'-65px',marginLeft: '440px'}}>
                    <Select
                        options={optionStatus}
                         style={{ width: 250 }}   
                        onChange={e => {
                            setFilter('status', e.value);
                                    onFilter();}}
                        value={currentSelect}                    
                    />
                </div>
        );
    }
}
export default CaselistFilter;