import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Textarea } from '../../../elements/Input';
import '../../../styles/css/styles.css';

// Components
import Button from '../../../components/CustomButton/CustomButton.jsx';

import { Modal } from 'react-bootstrap';
import ChecklistElementCheck from './ChecklistElementCheck';
import config from '~/library/config';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import ChecklistDeviation from './ChecklistDeviation';
import ChecklistTreeElement from './ChecklistTreeElement';
import id from 'date-fns/locale/id/index.js';

@inject('checklistStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ChecklistTreeView extends Component {
    state = {
        open: false,
        data: {},
        elements: [],
        showaddelement: false,
        showAddElement: false,
        checkElement: false,
        checkElement2: false,
        isDeviation: false,
        elementData: {},
        checklist_name: null,
        checklist_id: 0,
        project_id: 0,
        deviationData: {},
        isAttachments: false,
        checklistAttachments: [],
        checklistName_: '',
        checklist_comment_by_user: '',
        imageFileExtentions: ['jpg', 'jpeg', 'png', 'svg', 'svgz', 'webp', 'bmp', 'gif', 'j2k', 'ico'],
        isNO_NA: null,
    };

    componentDidMount() {
        this.setState({
            data: this.props.data,
            paddingLeft: this.props.paddingLeft,
            siginig_required: this.props.siginig_required,
            photo_required: this.props.photo_required,
            due_date_required: this.props.due_date_required,
            checklist_name: this.props.checklist_name,
            checklist_id: this.props.checklist_id,
            project_id: this.props.project_id,
        });
        if (this.props.data.elements && this.props.data.elements.length > 0) {
            this.setState({ elements: this.props.data.elements });
        }
    }

    onviewclick = () => {
        let x = !this.state.open;
        this.setState({ open: x });
    };

    handleClosesCheckElementModal = () => {
        this.setState({ checkElement: false, checkElement2: false, isDeviation: false, elementData: {} });
    };

    handleClosesDeviationElementModal = () => {
        this.setState({ checkElement: false, checkElement2: false, isDeviation: false, elementData: {} });
    };

    handleClosesAttachmentsModal = () => {
        this.setState({ isAttachments: false, checklistAttachments: [], checklistName_: '' });
    };

    attachmentsModal = (data) => {
        let checklistName = data.name;
        let checklistAttachmentIds = data.attachments;
        if (data.attachments_by_user != undefined && data.attachments_by_user != '') {
            checklistAttachmentIds += ',' + data.attachments_by_user;
        }

        const { checklistStore } = this.props;
        let dataReq = {
            attachmentIds: checklistAttachmentIds,
        };
        checklistStore.getMultipleElements(dataReq).then((elementsResult) => {
            this.setState({
                checklistAttachments: elementsResult.attachments,
                isAttachments: true,
                checklistName_: checklistName,
                checklist_comment_by_user: data.comment_by_user,
            });
        });
    };

    renderAttachments = (attachment, index) => {
        const { imageFileExtentions } = this.state;
        let fileType = attachment.filename.split('.').pop();
        if (imageFileExtentions.includes(fileType.toLowerCase())) {
            return (
                <div className="" key={attachment.id}>
                    <img
                        className="checklist-element-attachment"
                        src={`${config.API_ROOT}/attachments/${attachment.id}`}
                    />
                </div>
            );
        } else {
            return (
                <div className="checklist-element-pdf-attachment" key={attachment.id}>
                    <a href={`${config.API_ROOT}/attachments/${attachment.id}`} target="_blank">
                        {attachment.filename}
                    </a>
                </div>
            );
        }
    };

    checkElement = (data, type) => {
        if (type === 'NO' || type === 'NA') this.handleStatus(data, type);
        else this.setState({ checkElement: true, elementData: data });
    };

    checkElement2 = (data, type) => {
        if (type === 'Done' || type === 'NA') this.handleStatus2(data, type);
        else this.setState({ checkElement2: true, elementData: data });
    };

    handleStatus2 = (data, type) => {
        // isNO_NA ? (isNO_NA === "NO" ? "NO" : "Not Applicable" ):
        const { t, commonStore, checklistStore, checklist_id } = this.props;
        const hydrated = {
            element_data: data ? data : this.state.elementData,
            id: checklist_id,
            status: type === 'Done' ? t('Done') : 'NO',
        };

        checklistStore
            .checkChecklistElement(hydrated)
            .then((res) => {
                this.props.checklistStore.resetLastListLoadTime();
                commonStore.addNotification(t('Updated successfully.'), null, 'success');
                this.handleClosesCheckElementModal();
                this.props.elementUpdate(checklist_id);
            })
            .catch((err) => {
                commonStore.addNotification(err.message || t('Something went wrong in status'), null, 'error');
            });
    };

    handleStatus = (data, type) => {
        // isNO_NA ? (isNO_NA === "NO" ? "NO" : "Not Applicable" ):
        const { t, commonStore, checklistStore, checklist_id } = this.props;
        const hydrated = {
            element_data: data ? data : this.state.elementData,
            id: checklist_id,
            status: type === 'NO' ? 'NO' : 'Not Applicable',
        };

        checklistStore
            .checkChecklistElement(hydrated)
            .then((res) => {
                this.props.checklistStore.resetLastListLoadTime();
                commonStore.addNotification(t('Updated successfully.'), null, 'success');
                this.handleClosesCheckElementModal();
                this.props.elementUpdate(checklist_id);
            })
            .catch((err) => {
                commonStore.addNotification(err.message || t('Something went wrong in status'), null, 'error');
            });
    };

    doDeviation = (data) => {
        if (data.status != 'Deviation') {
            this.setState({ isDeviation: true, elementData: data });
        }
    };

    handleSaveChecklistElement = (element) => {
        this.setState({ elementData: element });
    };

    handlDeviationData = (element) => {
        this.setState({ deviationData: element });
    };

    saveElement = (params = {}) => {
        const { t, commonStore, checklistStore, checklist_id } = this.props;

        if (
            this.state.elementData == {} ||
            (this.state.elementData.siginig_required &&
                (this.state.elementData.signature == undefined || this.state.elementData.signature == ''))
        ) {
            commonStore.addNotification(t('Please Do Signature.'), null, 'error');
            return;
        } else if (
            this.state.elementData.photo_required &&
            (this.state.elementData.attachments_by_user == undefined ||
                this.state.elementData.attachments_by_user == '')
        ) {
            commonStore.addNotification(t('Please Upload photo.'), null, 'error');
            return;
        }

        let hydrated = {
            element_data: this.state.elementData,
            id: checklist_id,
            status: 'Done',
        };
        checklistStore
            .checkChecklistElement(hydrated)
            .then((res) => {
                this.props.checklistStore.resetLastListLoadTime();
                commonStore.addNotification(t('Updated successfully.'), null, 'success');
                this.handleClosesCheckElementModal();
                this.props.elementUpdate(checklist_id);
            })
            .catch((err) => {
                commonStore.addNotification(err.message || t('Please Fill all fields.'), null, 'error');
            });
    };

    saveElem = (params = {}) => {
        const { t, commonStore, checklistStore, checklist_id } = this.props;

        let hydrated = {
            element_data: this.state.elementData,
            id: checklist_id,
            status: 'NO',
        };

        checklistStore
            .checkChecklistElement(hydrated)
            .then((res) => {
                this.props.checklistStore.resetLastListLoadTime();
                commonStore.addNotification(t('Saved'), null, 'success');
                this.handleClosesCheckElementModal();
                this.props.elementUpdate(checklist_id);
            })
            .catch((err) => {
                commonStore.addNotification(err.message || t('Please Fill all fields.'), null, 'error');
            });
    };

    saveDeviation = (params = {}) => {
        const { t, commonStore, checklistStore, checklist_id } = this.props;
        checklistStore
            .deviationChecklist(this.state.deviationData)
            .then((res) => {
                this.props.checklistStore.resetLastListLoadTime();
                commonStore.addNotification(t('Saved'), null, 'success');
                this.handleClosesDeviationElementModal();
                this.props.elementUpdate(checklist_id);
            })
            .catch((err) => {
                commonStore.addNotification(err.message || t('Please Fill all fields.'), null, 'error');
            });
    };

    render() {
        const { t } = this.props;

        return (
            <div style={{ width: '100%' }}>
                {this.props.sub && this.props.sub == true ? (
                    <div className="flex-box checklist-subcategory">
                        <div style={{ width: '100%' }}>
                            <div className="checklist-subcategory-name">{this.state.data.name}</div>
                        </div>
                        {this.state.data.comment && <div className="checklist-comment">{this.state.data.comment}</div>}
                        {this.state.data.attachments && (
                            <div
                                className="checklist-attachment-link"
                                onClick={() => this.attachmentsModal(this.state.data)}
                            >
                                {t('Click To See All Files')}
                            </div>
                        )}
                        {this.state.elements.length > 0
                            ? this.state.elements.map((item, index) =>
                                  item.elements && item.elements.length > 0 ? (
                                      <ChecklistTreeView
                                          key={item.id}
                                          data={item}
                                          sub={true}
                                          paddingLeft={this.state.paddingLeft + 30}
                                          siginig_required={this.state.siginig_required}
                                          photo_required={this.state.photo_required}
                                          due_date_required={this.state.due_date_required}
                                          checklist_id={this.props.checklist_id}
                                          t={this.props.t}
                                          commonStore={this.props.commonStore}
                                          checklistStore={this.props.checklistStore}
                                          elementUpdate={this.props.elementUpdate}
                                          paddingTop={0}
                                          checklist_name={this.state.checklist_name}
                                          project_id={this.state.project_id}
                                      />
                                  ) : (
                                      <ChecklistTreeElement
                                          element={item}
                                          key={item.id}
                                          checkElement={this.checkElement}
                                          checkElement2={this.checkElement2}
                                          doDeviation={this.doDeviation}
                                          attachmentsModal={this.attachmentsModal}
                                          checklist_status= {this.props.checklist_status}
                                      />
                                  )
                              )
                            : null}
                    </div>
                ) : this.state.data.elements && this.state.data.elements.length > 0 ? (
                    <div>
                        <div>
                            <div className="checklist-category-name">{this.state.data.name}</div>
                        </div>
                        {this.state.data.comment && (
                            <div>
                                <div className="checklist-comment">{this.state.data.comment}</div>
                            </div>
                        )}
                        {this.state.data.attachments && (
                            <div
                                className="checklist-attachment-link"
                                onClick={() => this.attachmentsModal(this.state.data)}
                            >
                                {t('Click To See All Files')}
                            </div>
                        )}
                        {this.state.elements.length > 0
                            ? this.state.elements.map((item, index) =>
                                  item.elements && item.elements.length > 0 ? (
                                      <ChecklistTreeView
                                          key={item.id}
                                          data={item}
                                          sub={true}
                                          paddingLeft={this.state.paddingLeft + 30}
                                          siginig_required={this.state.siginig_required}
                                          photo_required={this.state.photo_required}
                                          due_date_required={this.state.due_date_required}
                                          checklist_id={this.props.checklist_id}
                                          t={this.props.t}
                                          commonStore={this.props.commonStore}
                                          checklistStore={this.props.checklistStore}
                                          elementUpdate={this.props.elementUpdate}
                                          paddingTop={0}
                                          checklist_name={this.state.checklist_name}
                                          project_id={this.state.project_id}
                                      />
                                  ) : (
                                      <div className="flex-box checklist-subcategory">
                                          <ChecklistTreeElement
                                              key={item.id}
                                              element={item}
                                              checkElement={this.checkElement}
                                              checkElement2={this.checkElement2}
                                              doDeviation={this.doDeviation}
                                              attachmentsModal={this.attachmentsModal}
                                              checklist_status= {this.props.checklist_status}
                                          />
                                      </div>
                                  )
                              )
                            : null}
                    </div>
                ) : (
                    <div className="flex-box checklist-subcategory">
                        <ChecklistTreeElement
                            key={this.state.data.id}
                            element={this.state.data}
                            checkElement={this.checkElement}
                            checkElement2={this.checkElement2}
                            doDeviation={this.doDeviation}
                            checklist_status= {this.props.checklist_status}
                        />
                    </div>
                )}

                <Modal size="lg" show={this.state.checkElement} onHide={this.handleClosesCheckElementModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Checklist')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                        <ChecklistElementCheck
                            data={this.state.elementData}
                            isOnlyText={false}
                            siginig_required={this.state.siginig_required}
                            photo_required={this.state.photo_required}
                            due_date_required={this.state.due_date_required}
                            handleSaveChecklistElement={this.handleSaveChecklistElement}
                            checklist_status= {this.props.checklist_status}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        {this.props.checklist_status != 'Approved' &&(
                            <Button variant="secondary" onClick={this.saveElement}>
                                {t('Complete')}
                            </Button>
                        )}
                        <Button variant="secondary" onClick={this.handleClosesCheckElementModal}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal size="lg" show={this.state.checkElement2} onHide={this.handleClosesCheckElementModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Checklist')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                        <ChecklistElementCheck
                            isOnlyText={true}
                            data={this.state.elementData}
                            handleSaveChecklistElement={this.handleSaveChecklistElement}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                    {this.props.checklist_status != 'Approved' &&(
                            <Button variant="secondary" onClick={this.saveElem}>
                                {t('Complete')}
                            </Button>
                    )}
                        <Button variant="secondary" onClick={this.handleClosesCheckElementModal}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal size="lg" show={this.state.isDeviation} onHide={this.handleClosesDeviationElementModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Deviation')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                        <ChecklistDeviation
                            data={this.state.elementData}
                            handlDeviationData={this.handlDeviationData}
                            checklist_name={this.state.checklist_name}
                            checklist_id={this.state.checklist_id}
                            project_id={this.state.project_id}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.saveDeviation}>
                            {t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleClosesDeviationElementModal}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal size="lg" show={this.state.isAttachments} onHide={this.handleClosesAttachmentsModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Attachments')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                        <h4 className="custom-form__col">{this.state.checklistName_}</h4>
                        <div className="custom-form__col">
                            {this.state.checklistAttachments && this.state.checklistAttachments.length &&
                                this.state.checklistAttachments.map((item, index) =>
                                    this.renderAttachments(item, index)
                                )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClosesAttachmentsModal}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ChecklistTreeView;
