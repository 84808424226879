import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import Select from '../../../../elements/Select';


const Options = [{ value: "read", label: 'members can only read',title:'members can only read' }, { value: "read_write", label: 'members can read and write',title:'members can read and write' }]

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class MessageControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageRules: {
                messageSetting:'read_write'
            },
        }
    }
    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const { messageRules } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            const basicRules = Object.assign({}, currentEntity.client.data.basicRules);
            messageRules.messageSetting = basicRules.messageSetting ? basicRules.messageSetting : 'read_write';

        }
        this.handleSwitch = this.handleSwitch.bind(this);
        this.loadData = this.loadData.bind(this);
        this.translateLabels = this.translateLabels.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    translateLabels = () => {
        const { t } = this.props;
            Options.map(item => {
            item.label = t(item.title)
        })
        
    }

    handleSwitch = (name, evt) => {
        const { messageRules } = this.state;
        messageRules[name] = evt;
        this.props.handleUserDataChange(name, evt);
        this.setState({ messageRules })
    }

        render() {
            const { messageRules } = this.state;
            const { t } = this.props;
            this.translateLabels();
            return (
                <div>
                    <Row>
                        <Col sm={12}>
                            <label className="control-label">{t('Message')} {t('Chat properties')}</label>
                            <Select
                                 options={Options}
                                 value={Options.find(item => item.value === messageRules.messageSetting)}
                                onChange={evt => this.handleSwitch('messageSetting', evt.value)}
                                style={{ width: '50%' }}
                            />
                        </Col>
                    </Row>
                  
                </div>
            );
        }
}
    export default MessageControl;