import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GenericList from "../../../components/GenericList/GenericList";
import { inject, observer } from "mobx-react";
import withLocalization from "../../../hoc/withLocalization";
import withRemoveDialog from '~/hoc/withRemoveDialog';
import clockIcon from "../../../assets/img/clockIcon.svg";
import editIcon from "../../../assets/img/editIcon.svg";
import downloadIcon from "../../../assets/img/downloadIcon.svg";
import Search from "../../../assets/img/search-24.svg"
import RecipientsList from "./Recipients/recipientList";
import config from '~/library/config';
import moment from 'moment';
import CaselistFilter from "./CaselistFilter";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Widgets/CaseList.css"
import { style } from '~/variables/Variables';
import { signatureStore } from '~/library/stores/signatureStore';

let nameTimeOut = null;
@inject('signatureStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class CreateDoc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showrecipientList: false,
            caseId: null,
            currentStatus: 'All',
            RecipientsList: '',
            nameFilter: '',
            recipients_status: undefined
        }
    }

    navigateToHistory = (id, name, date) => {
        const { history } = this.props;
        history.push({
            pathname: '/admin/signdochistory',
            state: {
                caseId: id,
                caseName: name,
                caseDate: date,
                parent: 'CaseList'
            }
        });

    }

    handleDownload = (data, id) => {
        window.open(`${config.API_ROOT}/sign_documents/completed_signing_document/${encodeURIComponent(id)}`);
    }

    handleDoSignature = (data, id) => {
        const { signatureStore, propertyStore, commonStore, t } = this.props;
        signatureStore.generateSignatureLink(id).then((res) => {
            window.open(`${res.Link}`);
        }).catch((err) => {
            commonStore.addNotification(t(err.message), null, 'error');
        });
    }

    handleRemoveDoc = (case_id) => {
        const { commonStore, t } = this.props;
        // console.log(this.props.signatureStore)
        // console.log("id : ",case_id)
        return this.props.handleDelete(case_id, case_id => {
            return this.props.signatureStore.removeCase(case_id).then((res) => {
                this.props.signatureStore.resetLastListLoadTime();
                commonStore.addNotification(t('Deleted'), null, 'error');
            }).catch((err) => {
                commonStore.addNotification(t(err), null, 'error');
            })
        });
    }

    renderTableActionButtons = (id, data) => {
        const { t } = this.props;
        return (
            <div className="actions-center-signdoc" key={`sign-${id}`}>
                {data.status !== "draft" && (
                    // <img src={clockIcon}
                    //     onClick={() => this.navigateToHistory(id, data.data[0].og_name, data.send_date)}
                    //     className="img1"
                    // />
                    <OverlayTrigger
                        key="GRID"
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-top">
                                {t("History")}
                            </Tooltip>
                        }
                    >
                        <Button
                            onClick={() => this.navigateToHistory(id, data.data[0].og_name, data.send_date)}
                            bsStyle="danger" simple icon
                        >
                            <i className="fa fa-history" />
                        </Button>
                    </OverlayTrigger>

                )}
                {
                    data.status === "pending" && this.props.userStore.currentUser.id !== data.created_by && (

                        // <img src={editIcon}
                        //     onClick={() => this.handleDoSignature(data, id)}
                        //     className="img1"
                        // />
                        <OverlayTrigger
                            key="GRID"
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Signatue")}
                                </Tooltip>
                            }
                        >
                            <Button
                                onClick={() => this.handleDoSignature(data, id)}
                                bsStyle="danger" simple icon
                            >
                                <i className="fa fa-edit" />
                            </Button>
                        </OverlayTrigger>

                    )
                }
                {
                    data.status === 'completed' && (
                        <OverlayTrigger
                            key="GRID"
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Download")}
                                </Tooltip>
                            }
                        >
                            <Button
                                onClick={() => this.handleDownload(data, id)}
                                bsStyle="danger" simple icon
                            >
                                <i className="fa fa-download" />
                            </Button>
                        </OverlayTrigger>


                    )
                }

                <Button
                    onClick={() => this.handleRemoveDoc(id)}
                    bsStyle="danger" simple icon
                    
                >
                    <i className="fa fa-times" />
                </Button>
            </div>
        )
    }



    handlerecipientList = (recipients, id) => {

        if (recipients == 0) {
            return 0;
        }
        else {
            this.setState({ showrecipientList: !this.state.showrecipientList, caseId: id });
        }

    };

    onStatusChange = (value) => {
        const { signatureStore } = this.props;
        signatureStore.onFilter('status', value.value);
        this.setState({ currentStatus: value });
    }
    handleFilterChange = (e) => {
        const { signatureStore } = this.props;
        signatureStore.setFilter('name',e.target.value);
        this.setState({ nameFilter: e.target.value })
        // if (nameTimeOut) {
        //     clearTimeout(nameTimeOut);
        // }
        // nameTimeOut = setTimeout(() => {
        //     signatureStore.onFilter('name', this.state.nameFilter);
        // }, 1000);
    }
    sendToSignature(data) {
        const { signatureStore, history } = this.props;

        if (data.status === "draft") {
            signatureStore.setCaseId(data.id);
            sessionStorage.setItem("CaseId", data.id);
            history.push('/admin/signupload');
        }
    }

    navigateToSignupload = () => {
        const { history } = this.props;
        history.push('/admin/signupload');
    }

    // componentDidMount() {
    //     const { signatureStore } = this.props;       
    // }

    getColorCode(status) {
        if (status === 'pending') {
            return '#ffb300'
        } else if (status === 'draft') {
            return '#89CFF0'
        } else if (status === 'completed') {
            return '#33c85b'
        } else if (status === 'expired') {
            return '#ff0000'
        } else {
            return '#ffb300'
        }
    }

    render() {
        const { t } = this.props;
        const { commonStore } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        const { filters, appliedFilters,onFilter } = this.props.signatureStore;

        return (
            <div className="main-content">
                <div className="caseListContainer">
                    <div className="search-bar">
                        <div className="searchInput">
                            <input
                                type="text"
                                value={this.state.nameFilter}
                                onChange={this.handleFilterChange}
                                className="form-control"
                                placeholder={this.props.t('Search Document')}
                                onKeyPress = { (e) => {
                                if(e.key === 'Enter'){
                                    this.props.signatureStore.onFilter();
                                }
                        }}
                            />
                            <img src={Search} onClick={() => this.props.signatureStore.onFilter()} style={{cursor:"pointer"}} className="search-icon" alt="search" />
                        </div>
                        <div className="drop-down">

                        </div>
                        <div className="createBtn">
                            <button onClick={this.navigateToSignupload} className="btn btn-fill crt-btn">
                                {t('Create Document')}
                            </button>
                        </div>
                    </div>
                    {
                        <GenericList
                            columns={[
                                {
                                    Header: 'Name',
                                    id: 'name',
                                    sortable: true,
                                    accessor: (data) => (
                                        // <button          
                                        //     className={`btn btn-fill btn-sign_doc_name`}
                                        //     onClick={() => {
                                        //         this.sendToSignature(data);
                                        //     }}                                        
                                        //     title={data.name}
                                        //     disabled={data.status != 'draft'?true:false}
                                        // >
                                        //  {data.name}
                                        // </button>                                  
                                        // data.status === "draft"?
                                        // <button className="btn btn-fill btn-sign_doc_name" title={data.name}>
                                        //     {data.name}
                                        // </button> 
                                        // :
                                        // <span>{data.name}</span>

                                        <span onClick={() => { this.sendToSignature(data) }}
                                            className={data.status === 'draft' ? "draft_signdoc_name" : "not_draft_signdoc_name"}>
                                            {data.name}
                                        </span>
                                    ),
                                },
                                {
                                    Header: 'Created By',
                                    id: 'created_by_full_name',
                                    sortable: false,
                                    accessor: 'created_by_full_name',
                                },
                                {
                                    Header: 'Status',
                                    id: 'status',
                                    sortable: true,

                                    accessor: 'status',
                                    accessor: ({ status, recipients_status, recipients }) =>
                                        <div className={recipients_status == 0 && recipients == 0 ? "progress" : "progress"}
                                            style={{ height: "19px", color: "white", borderRadius: "10px", verticalAlign: "middle", lineHeight: "20px", userSelect: "none" }}>
                                            <div className={
                                                status == 'completed' ? "progress-bar"
                                                    : status == 'expired' ? "expired_color"
                                                        : status == 'pending' && recipients_status == 0 ? "pending"
                                                            : status == 'draft' ? "draft"
                                                                : recipients_status == (recipients >= 1 && recipients_status >= 1) || (recipients_status / recipients) ? "progress-bar-primary" : ""
                                            }
                                                role="progressbar"
                                                style={{
                                                    height: "20px", width: (status == 'completed') ? "100%"
                                                        : status == 'expired' ? "100%"
                                                            : status == 'pending' && recipients_status == 0 ? "100%"
                                                                : status == 'draft' ? "100%"
                                                                    : recipients_status == (recipients_status == recipients) || (recipients >= 1 && recipients_status >= 1) || (recipients_status / recipients) ? (recipients_status / recipients * 100).toFixed(0) + "%"
                                                                        : ""
                                                }}
                                            >
                                                {
                                                    status == 'completed' ? t("Completed")
                                                        : status == 'expired' ? t("Expired")
                                                            : status == 'pending' && recipients_status == 0 ? t("Pending")
                                                                : status == 'draft' ? t("Draft")
                                                                    : recipients_status == (recipients_status == recipients) || (recipients >= 1 && recipients_status >= 1) || (recipients_status / recipients) ? (recipients_status / recipients * 100).toFixed(0) + "%" : ""

                                                }
                                            </div>
                                        </div>
                                },
                                {
                                    Header: 'Recipients',
                                    id: 'recipients',
                                    accessor: ({ recipients, id }) => (
                                        <span
                                            className="chat-badge recipient_total"
                                            style={{ cursor: (recipients == 0) ? "auto" : "pointer" }}
                                            onClick={() => {
                                                this.handlerecipientList(recipients, id);
                                            }}
                                        >
                                            {recipients}
                                        </span>
                                    ),
                                    sortable: true,
                                },
                                {
                                    Header: 'Sent Date',
                                    id: 'send_date',
                                    sortable: true,
                                    accessor: ({ send_date }) => (
                                        <span>{send_date ? moment(send_date).format(dateTimeRules.short_date_format) : ''}</span>
                                    ),
                                },
                                {
                                    Header: 'Expiring On',
                                    id: 'expiry_date',
                                    sortable: true,
                                    accessor: ({ expiry_date }) => (
                                        <span>{expiry_date ? moment(expiry_date).format(dateTimeRules.short_date_format) : ''}</span>
                                    ),
                                },
                                {
                                    Header: 'Action',
                                    id: 'action',
                                    sortable: false,
                                    accessor: (data) => this.renderTableActionButtons(data.id, data),
                                },
                            ]}
                            filters={appliedFilters}
                            lastListLoadTime={this.props.signatureStore.lastListLoadTime}
                            header={(
                                <CaselistFilter
                                    filters={filters}
                                    setFilter={(name, value) => this.props.signatureStore.setFilter(name, value)}
                                    onFilter={() => {
                                        this.props.signatureStore.onFilter();

                                    }}
                                />
                            )}
                            requestData={(params) => this.props.signatureStore.getSignDocument(params)}
                        />
                    }

                    <RecipientsList
                        caseId={this.state.caseId}
                        show={this.state.showrecipientList}
                        handlerecipientList={this.handlerecipientList}
                    ></RecipientsList>
                </div>

            </div>
        );
    }

}

export default CreateDoc;
