import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Calendar, momentLocalizer, Views,dateFnsLocalizer  } from "react-big-calendar";
import Toolbar from 'react-big-calendar/lib/Toolbar';
// import Toolbar from 'react-big-calendar';
import _ from 'lodash';
import moment from "moment";
import { inject, observer } from 'mobx-react';
import "react-big-calendar/lib/css/react-big-calendar.css";

import ShiftForm from './ShiftForm';
import BulkShiftForm from './BulkShiftForm';
import CopyForm from './CopyForm';
import { getMinutesFromTimeInput } from '../../../utils/timeCalc';
import { confirmAlert } from 'react-confirm-alert';

import withLocalization from '~/hoc/withLocalization';
import withDnDContext from '~/hoc/withDnDContext';
import userStore from '../../../library/stores/userStore';
import MonthCustomHeader from './MonthCustomHeader';
import ExistingShiftsModal from './ExistingShiftsModal';
import MonthEventComponent from './MonthEventComponent';



import 'moment/locale/en-gb';
import 'moment/locale/nb';
import 'moment/locale/pl';
import 'moment/locale/lt';
import 'moment/locale/ru';
import 'moment/locale/es';

moment.locale('nb');
moment.locale('pl');
moment.locale('lt');
moment.locale('ru');
moment.locale('es');


// import enGb from 'date-fns/locale/en-GB'; // the locale you want
// import no from 'date-fns/locale/nb'; // the locale you want
// import pl from 'date-fns/locale/pl'; // the locale you want
// import lt from 'date-fns/locale/lt'; // the locale you want
// import ru from 'date-fns/locale/ru'; // the locale you want
// import es from 'date-fns/locale/es'; // the locale you want


// momentLocalizer('no', nb); // register it with the name you want
// momentLocalizer('en', enGb); // register it with the name you want
// momentLocalizer('pl', pl); // register it with the name you want
// momentLocalizer('lt', lt); // register it with the name you want
// momentLocalizer('ru', ru); // register it with the name you want
// momentLocalizer('es', es); // register it with the name you want


 

// const locales = {
//     'en-GB': enGB,
//     'nb':nb 
//   }
// const localizer = dateFnsLocalizer ({
//     format,
//     parse,
//     startOfWeek,
//     getDay,
//     locales,
//   })
 const localizer = momentLocalizer(moment);



@inject('userStore', 'commonStore', 'scheduleStore')
@withLocalization
@withRouter
@withDnDContext
@observer
class MonthCalendar extends Component {

    constructor(props){
        super(props);
        this.closeUserModal = this.closeUserModal.bind(this)
    }

    state = {
        editing: false,
        stub:null,
        bulkEditing: false,
        options:[
            {
                value:'1',
                label:this.props.t('Week')
            },
            {
                value:'2',
                label:this.props.t('Month')
            }
        ],
        schedulerData:this.props.schedulerData,
        copying: false,
        showUserModal:false,
        userWithFutureShifts:[],
        start_date:'',
        end_date:''
        // schedulerData: [{
        //     'title': 'All Day Event very long title',
        //     'start': new Date(2021, 11, 0, 11,0,0,0),
        //     'end': new Date(2021, 11, 1, 11, 13,10,0)
        // },
        // {
        //     'title': 'All nt very long title',
        //     'start': new Date(2021, 11, 0, 12, 0, 0,0),
        //     'end': new Date(2021, 11, 1, 15,0,0,0)
        // },
        // {
        //     'title': 'All Day Event',
        //     'start': new Date(2021, 11, 0),
        //     'end': new Date(2021, 11, 1)
        // },
        // {
        //     'title': 'Long Event',
        //     'start': new Date(2021, 11, 7),
        //     'end': new Date(2021, 11, 10)
        // },]
    }
    componentDidMount() {
        this.loadData();
    }

    async reloadData(scheduleData) {
        const autoReload = !scheduleData;
        if (!scheduleData) scheduleData = this.state.schedulerData;
        const { scheduleStore } = this.props;
        const params = {
            start: scheduleData.startDate,
            end: scheduleData.endDate,
        };
        // const data = await scheduleStore.loadList(params);
        // if (autoReload) {
        //     this.setState({
        //         schedulerData: scheduleData,
        //     });
        // }
        let data = await scheduleStore.loadList(params, dateTimeRules);
        data = JSON.parse(JSON.stringify(data));
        let getScheduleData = this.createPayload(data.list);
        this.setState({schedulerData: getScheduleData})
        return scheduleData;
    }

    async loadData() {
        const { scheduleStore, commonStore } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        const{schedulerData}=this.state;
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
        const params = {
            start: startOfMonth,
            end: endOfMonth,
        };
        let data = await scheduleStore.loadList(params,dateTimeRules);
        data = JSON.parse(JSON.stringify(data));
        let scheduleData = this.createPayload(data.list);
        this.setState({schedulerData: scheduleData,start_date:startOfMonth,end_date:endOfMonth});

    }

    closeEditingWindow = () => {
        this.setState({
            editing: false,
            stub: null,
        });
    };

    afterEditing = async (status) => {
        const { t, scheduleStore, commonStore, userStore } = this.props;
        const { currentUser } = userStore;
        if (status === 'cancel') {
            this.setState({
                editing: false,
                stub: null,
            });
        }
        if (status === 'delete') {
            this.closeEditingWindow();
            confirmAlert({
                title: t('Confirm delete'),
                message: t('This will remove the schedule'),
                buttons: [
                    {
                        label: t('Yes'),
                        onClick: async () => {
                            await scheduleStore.remove(scheduleStore.currentEntity.id);
                            this.reloadData();
                        },
                    },
                    {
                        label: t('No'),
                        onClick: () => {
                            // this.closeEditingWindow();
                        },
                    },
                ],
            });
        }
        if (status === 'save') {
            try {
                await scheduleStore.save(scheduleStore.currentEntity, !scheduleStore.currentEntity.id);
                this.closeEditingWindow();
                this.reloadData();
            } catch (e) {
                // commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
        }
        if (status === 'member_accept' || status === 'member_reject') {
            try {
                if (status === 'member_reject' && !scheduleStore.currentEntity.comment) {
                    throw new Error('Comment is required');
                }
                await scheduleStore.memberAction(status, currentUser);
                this.closeEditingWindow();
                this.reloadData();
            } catch (e) {
                // commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
        }
    };

    createPayload(data){
        const {
            mode, userStore, t, onAdd, commonStore,scheduleStore
        } = this.props;
        const { currentUser } = userStore;
        const isMember = currentUser.user_type === 'member';
    
        let{ resources } = scheduleStore;
        resources = _.filter(resources, (r) => !!r.id);

        const userOptions = resources.map((r) => {
            return { value: r.id, label: r.name };
        });



        let scheduleMonthData = [];

        data.forEach(element => {
            let tempPayload;
           let checkSource = element.title.concat(', \n',element.taskName);
            if(isMember){
                if(element.source === 'vacation'){
                    checkSource='Vacation'
                }
                if(element.source === 'absence'){
                    checkSource='Absent'
                }
                 tempPayload = {
                    'addBonus':element.addBonus,
                    'bgColor':element.bgColor,
                    'title': checkSource,
                    'id':element.id,
                    'resourceId':element.resourceId,
                    // 'allDay': false,
                    'start': element.start,
                    'end': element.end,
                    'workLogStatus':element.workLogStatus,
                    'source':element.source,
                }
            }
            else{
                let getName = userOptions.filter(function(n){
                    return element.resourceId===n.value;
                })
    
                checkSource = element.title.concat(', \n',getName[0]?getName[0].label:'Pending',', \n',element.taskName);
               // checkSource = element.title;
                if(element.source === 'vacation'){
                    checkSource='Vacation'.concat(', \n',getName[0]?getName[0].label:'');
                }
                if(element.source === 'absence'){
                    checkSource='Absent'.concat(', \n',getName[0]?getName[0].label:'');
                }
                
                 tempPayload = {
                    'addBonus':element.addBonus,
                    'bgColor':element.bgColor,
                    'title': checkSource,
                    'taskName':element.taskName,
                    'id':element.id,
                    'resourceId':element.resourceId,
                    // 'allDay': false,
                    'start': element.start,
                    'end': element.end,
                    'workLogStatus':element.workLogStatus,
                    'source':element.source,
                    'name':getName[0]?getName[0].label:'Pending'
                }
            }
            scheduleMonthData.push(tempPayload)
        });
        
        return scheduleMonthData;
    }

    handleSelectedEvent = (event)=>{
        const { currentUser } = userStore;
        if(typeof event.id === 'number'){

            this.setState({ editing: event.id, stub: event });
        }
       
    }

    handleSelectSlot = (event) =>{
        const clientConfig = this.props.commonStore.config.client.data;
        let from = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.start);
        let to = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.end);
        this.setState({
            editing: 0,
            stub: {
                // for_date: new Date(start.replace(' ', 'T')),
                for_date:event.start,
                // assignee_id: slotId,
                start_time: from,
                end_time: to,
                // start_time:event.start,
                // end_time:event.end
            },
         });
             
    }

    onViewChange = (e) => {
        const { onViewChange, schedulerData } = this.props;
        // const viewType = parseInt(e.target.value.charAt(0));
        // const showAgenda = e.target.value.charAt(1) === '1';
        // const isEventPerspective = e.target.value.charAt(2) === '1';
        const viewType = e.value;
        const showAgenda = e.value === 1;
        const isEventPerspective = e.value === 1;
              onViewChange(schedulerData, {
                viewType,
                showAgenda,
                isEventPerspective,
            });
        // if(viewType===1){
        //     onViewChange(schedulerData, {
        //         viewType,
        //         showAgenda,
        //         isEventPerspective,
        //     });
        // }
        // if(viewType===2){
        //     this.setState({showCalendar:true});
           
        // }
    };


   async handleNavigate(date,view){
        const { scheduleStore, commonStore } = this.props;
        const startOfMonth = moment(date).startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment(date).endOf('month').format('YYYY-MM-DD');
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        const params = {
            start: startOfMonth,
            end: endOfMonth,
        };
        let data = await scheduleStore.loadList(params, dateTimeRules);
        data = JSON.parse(JSON.stringify(data));
        let scheduleData = this.createPayload(data.list);
        this.setState({schedulerData: scheduleData})
    }

    newBulkEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        const clientConfig = this.props.commonStore.config.client.data;
        let from = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.start);
        let to = getMinutesFromTimeInput(clientConfig.basicRules.startTimeRules.end);

        this.setState({
            bulkEditing: 0,
            stub: {
                start_time: from,
                end_time: to,
            },
        });
    };

    afterBulkEditing = async (status, values) => {
        const { t, scheduleStore, commonStore, userStore } = this.props;
        const { currentUser } = userStore;
        if (status === 'cancel') {
            this.setState({
                bulkEditing: false,
                stub: null,
            });
        }
        // if (status === 'delete') {
        //     this.closeEditingWindow();
        //     confirmAlert({
        //         title: t('Confirm delete'),
        //         message: t('This will remove the schedule'),
        //         buttons: [
        //             {
        //                 label: t('Yes'),
        //                 onClick: async () => {
        //                     await scheduleStore.remove(scheduleStore.currentEntity.id);
        //                     this.reloadData();
        //                 },
        //             },
        //             {
        //                 label: t('No'),
        //                 onClick: () => {
        //                     // this.closeEditingWindow();
        //                 },
        //             },
        //         ],
        //     });
        // }
        if (status === 'save') {
            try {
                await scheduleStore.addBulkShift(values);
                this.setState({
                    bulkEditing: false,
                    stub: null,
                }, () => {
                    this.reloadData();
                });
            } catch (e) {
                commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
        }
        // if (status === 'member_accept' || status === 'member_reject') {
        //     try {
        //         if (status === 'member_reject' && !scheduleStore.currentEntity.comment) {
        //             throw new Error('Comment is required');
        //         }
        //         await scheduleStore.memberAction(status, currentUser);
        //         this.closeEditingWindow();
        //         this.reloadData();
        //     } catch (e) {
        //         // commonStore.addNotification(t(e.message || 'Error'), null, 'error');
        //     }
        // }
    };

    handleBulkEvent(props){
        const{bulkEvent}=props;
        bulkEvent();
    }

    // handleCopyMonth(props){
    //     const{onCopyingStart}=props;
      
    //     onCopyingStart();
    // }

    startCopyToNextMonth() {
        // if (this.state.copyingUsers.length <= 0) {
        //     this.props.commonStore.addNotification(this.props.t('Please select users'), null, 'error');
        //     return;
        // }
        this.setState({ copying: true });
    }

    afterCopy(action,storeResponse) {
        // this.setState({ copying: false, copyingStarted: false });
        this.setState({ copying: false},()=>{
            if(action === 'save' && storeResponse && storeResponse.length){
                this.setState({
                    showUserModal:true,
                    userWithFutureShifts:storeResponse
                })
            }
        });
        // this.reloadData();
    }

    closeUserModal(){
        this.setState({
            showUserModal:false,
        })
    }
    

    render() {
        const{editing,stub,options,bulkEditing,copying,userWithFutureShifts}=this.state;
        const{rightCustomHeader,schedulerData,toggle_todaybtn,scheduleStore,userStore,t,commonStore}=this.props;
        const { currentUser } = userStore;
        const isMember = currentUser.user_type === 'member'; 
        //let dateLabel = schedulerData.getDateLabel(2); 
        return (
            <>
    
            <div className="customize_calendar">
                <Calendar 
                localizer={localizer}                   
                    culture={
                        commonStore && commonStore.locale
                            ? commonStore.locale==='no'?'nb':commonStore.locale
                            : 'en'
                    }
                   // events={toggle_todaybtn?storeData:this.state.schedulerData}
                    events={this.state.schedulerData}
                    //events={this.state.events}
                    defaultView={Views.MONTH}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 600 }}
                    selectable
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    //onSelectEvent={event => this.handleSelectedEvent(event)}
                    onDoubleClickEvent={event => this.handleSelectedEvent(event)}
                    onSelectSlot={event => this.handleSelectSlot(event)}
                    popup //show popup
                    onNavigate={(date,view)=>this.handleNavigate(date,view)} 

                    components={{
                        toolbar:props =>(<MonthCustomHeader {...props} handleBulkEvent={this.newBulkEvent} t={this.props.t} onViewChange={(e)=>this.onViewChange(e)} isMember={isMember}  handleCopyMonth={(props)=>this.startCopyToNextMonth(this.props)}  />),
                        // month: {
                        //     event: MonthEventComponent
                        // },
                        event: (props) => <MonthEventComponent {...props} schedulerData={this.state.schedulerData} />
                    }}

                    eventPropGetter={
                        (event, start, end, isSelected) => {
                          let newStyle = {
                            backgroundColor: "#fff",
                            color: 'black',
                            borderRadius: "0px",
                            border: "none"
                          };

                          if(isSelected){
                            newStyle.backgroundColor = "white"
                          }
                    
                          return {
                            className: isSelected?'selected_event':'rbc-event-content',
                            style: newStyle
                          };
                        }
                      }

                   // components={{toolbar: MonthCustomHeader({handleCopyMonth})}}

                    // components={{
                    //     month: { header: MonthCustomHeader },
                    //   }}

                    //onSelectEvent={event => alert(event.title)}
                    // onClick={() => { alert('good') }}
                />
                  {editing !== false && (
                    <ShiftForm
                        id={editing}     
                        add={editing === 0}
                        stub={stub}
                        finish={(status) => this.afterEditing(status)}
                    />
                    
                )}
                   {bulkEditing !== false && (
                    <BulkShiftForm
                        id={bulkEditing}
                        add={bulkEditing === 0}
                        stub={stub}
                        viewModel={schedulerData}
                        start_date={this.state.start_date}
                        end_date={this.state.end_date}
                        finish={(status, obj) => this.afterBulkEditing(status, obj)}
                    />
                    
                )}
                   {copying !== false && (
                    // <CopyForm users={copyingUsers} data={viewModel} finish={(action) => this.afterCopy(action)} />
                    <CopyForm  finish={(action,storeResponse) => this.afterCopy(action,storeResponse)} />
                )}
                {
                 this.state.showUserModal &&(
                     <ExistingShiftsModal userWithFutureShifts={userWithFutureShifts} t={this.props.t} closeUserModal={this.closeUserModal} />
                 )   
                }
            </div>
            </>
        )
    }
}


export default MonthCalendar
