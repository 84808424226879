import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Modal, Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import MultiSelect from '@khanacademy/react-multi-select';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';

import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import LoadingSpinner from '../../../elements/LoadingSpinner';
import InputTime from '../../../components/GenericForm/Widgets/InputTime';
import { Input, Textarea } from '../../../elements/Input';
import playIcon from '../../../assets/img/play.svg';
import stopIcon from '../../../assets/img/stop.svg';
import clockIcon from '../../../assets/img/clock.svg';
import distanceIcon from '../../../assets/img/distance.svg';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import { getStatusText } from '../../../utils/scheduleUtils';
import { MembersWidget,MembersWidgetMultipleSelect } from '../../../components/GenericForm/Widgets/UsersWidget';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import { TasksWidget } from '../../../components/GenericForm/Widgets/TasksWidget';
import Select from '../../../elements/Select';
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';

import ShiftHistory from './ShiftHistory';

import customerFeature from '~/utils/customerFeature';
import Calendar from '../../../components/ReactCalendar';


@inject('userStore', 'commonStore', 'scheduleStore')
@withRouter
@withLocalization
@observer
class CopyForm extends Component {
    state = {
        all: false,
        users: [],
        fromWeeks: [],
        toWeeks: [],
        selected:[],
        optionUsers:[],
        activeStartDate: moment(new Date()).add(1, 'month').toDate(),
       
    };

    // componentWillMount() {
    //     const { scheduleStore } = this.props;
    // }

    componentDidMount() {
        
      //  this.loadData();

        // console.log('checking next month',moment(new Date()).add(1,'month').format('w'));
        // console.log('checking  month',moment(new Date()).format('w'));

        // console.log('first day',moment(new Date()).startOf('month'));
        // console.log('last day',moment(new Date()).endOf('month'));

        let firstDayOfStartMonth = moment(new Date()).startOf('month');
        let endDayOfStartMonth = moment(new Date()).endOf('month');

        let firstDayOfNextMonth = moment(new Date()).add(1,'month').startOf('month');
        let endDayOfNextMonth = moment(new Date()).add(1,'month').endOf('month');


        let monthRange = moment.range(firstDayOfStartMonth,endDayOfStartMonth);
        let monthRangeNext = moment.range(firstDayOfNextMonth,endDayOfNextMonth);


       // let demoweeks = [];

        const fromWeeks =[];
        let toWeeks =[];  

        for (let mday of monthRange.by('days')) {
            if (fromWeeks.indexOf(mday.week()) === -1) {
                fromWeeks.push(mday.week());
            }
          }


        for (let mday of monthRangeNext.by('days')) {
            if (toWeeks.indexOf(mday.week()) === -1) {
                toWeeks.push(mday.week());
            }
          }

         toWeeks = toWeeks.filter(val => !fromWeeks.includes(val));

        //console.log('test weeks',testWeeks);

        // const fromWeeks = [
        //     parseInt(moment(new Date()).add(-7, 'days').format('w')),
        //     parseInt(moment(new Date()).format('w')),
        //     parseInt(moment(new Date()).add(7, 'days').format('w')),
        // ];
        // const toWeeks = [
        //     parseInt(moment(new Date()).add(14, 'days').format('w')),
        //     parseInt(moment(new Date()).add(21, 'days').format('w')),
        //     parseInt(moment(new Date()).add(28, 'days').format('w')),
        // ];
        this.setState({ fromWeeks, toWeeks });
    }

    async loadData() {
        const { scheduleStore, commonStore } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        let data = await scheduleStore.loadList(params, dateTimeRules);
        
        
        // data.document_templates.map(item=>{
        //     optionArray.push({
        //         label: item.template_name,
        //         value: item.id
        //     })
        //    })
        //    data.assigned_documents.map(item=>{
        //     selectedArray.push(item.document_id)
        //    })           
        //    this.props.handleChnage(selectedArray);
        //    this.setState({options:optionArray,selected:selectedArray})

        await scheduleStore.resources.map(item=>{
            users.push({
                label: item.name,
                value: item.id
            })
            this.setState({optionUsers:users});
        })
    }


    selectAll() {
        const { scheduleStore, commonStore, t, params, userStore } = this.props;
        const { all } = this.state;

        let { resources } = scheduleStore;
        resources = _.filter(resources, (r) => !!r.id);

        const userOptions = resources.map((r) => {
            return { value: r.id, label: r.name };
        });
        let users = [];
        if (!all) {
            users = userOptions.map((r) => r.value);
        }
        this.setState({ all: !all, users });
    }

    async handleRun(action) {
        const { scheduleStore, t, commonStore } = this.props;
        const { fromWeeks, toWeeks,users } = this.state;
        let storeExistingShifts = [];
        if (action !== 'cancel') {
            if (!users.length) {
                commonStore.addNotification(t('Please select users'), null, 'error');
                return;
            }
            if (!fromWeeks.length) {
                commonStore.addNotification(t('Please select weeks to copy from'), null, 'error');
                return;
            }
            if (fromWeeks.length !== toWeeks.length) {
                commonStore.addNotification(t('Should copy equal weeks'), null, 'error');
                return;
            }
            if (_.union(fromWeeks, toWeeks).length !== fromWeeks.length * 2) {
                commonStore.addNotification(t('Weeks should be unique'), null, 'error');
                return;
            }
            if (_.min(fromWeeks) >= _.min(toWeeks)) {
                commonStore.addNotification(t('Sorry, cant copy between years'), null, 'error');
                return;
            }
            const _fromWeeks = _.orderBy(fromWeeks, (r) => r);
            const _toWeeks = _.orderBy(toWeeks, (r) => r);
            await scheduleStore.copyMonth({
                users,
                fromWeeks: _fromWeeks,
                toWeeks: _toWeeks,
                nowWeek: parseInt(moment(new Date()).format('w')),
            }).then(res=>{ 
                if(res.userWithShifts.length>0){
                    storeExistingShifts = res.userWithShifts;
                }
                
            });
            commonStore.addNotification(t('Schedule saved'), null, 'success');
          
        }
        
        this.props.finish(action,storeExistingShifts);
    }

    addUser(id) {
        const { users } = this.state;
        if (users.indexOf(id) >= 0) {
            users.splice(users.indexOf(id), 1);
        } else {
            users.push(id);
        }
        this.setState({ users },()=>{
        });
    }

    onHandleWeek(week, source) {
        const weeks = this.state[source];
        if (weeks.indexOf(week) >= 0) {
            weeks.splice(weeks.indexOf(week), 1);
        } else {
            weeks.push(week);
        }
        if (source === 'fromWeeks') {
            this.setState({ fromWeeks: weeks });
        } else {
            this.setState({ toWeeks: weeks });
        }
    }

   
     //handlesSelectedOptions
     handleSelectedOptions = (users) => {
            this.setState({ users });
        // this.setState({ selected }, () => {
        //     this.updateDocumentList()
        // })
    }



    render() {
        
        const { scheduleStore, commonStore, t, params, userStore } = this.props;
        const { users, fromWeeks, toWeeks, activeStartDate } = this.state;


        // console.log('fromWeeks',fromWeeks);
        // console.log('toWeeks',toWeeks);
        let fromStartDateWeek;
        let fromendDateWeek;

        let toStartDateWeek;
        let toendDateWeek;
        
        if(fromWeeks.length!==0){

            // fromStartDateWeek = moment().day("Monday").week(fromWeeks[0]).toDate().getDate();
            // fromendDateWeek = moment().day("Monday").week(fromWeeks[fromWeeks.length-1]).weekday(6).toDate().getDate();
            fromWeeks.sort();
            fromStartDateWeek = moment().day("Monday").week(fromWeeks[0]).toDate();
            fromendDateWeek=moment().day("Monday").week(fromWeeks[fromWeeks.length-1]).weekday(6).toDate();

        }


        if(toWeeks.length!==0){
            toWeeks.sort();
            toStartDateWeek = moment().day("Monday").week(toWeeks[0]).toDate();
            toendDateWeek=moment().day("Monday").week(toWeeks[toWeeks.length-1]).weekday(6).toDate();
        }



        let { resources } = scheduleStore;
        resources = _.filter(resources, (r) => !!r.id);
       
        let {currentList} = scheduleStore;



        const allShiftsData = currentList.list.map((r)=>{
            return { id: r.id, resourceId: r.resourceId,start: r.start, end:r.end };
        })

        // console.log('currentList',currentList);
        // console.log('allShiftsData',allShiftsData);


      //  console.log('fromStartDateWeek',fromStartDateWeek,'fromendDateWeek',fromendDateWeek);

        const userShifts = currentList.list.map((r)=>{
            if(fromStartDateWeek && fromendDateWeek && (new Date(r.end)>=fromStartDateWeek && new Date(r.end)<=fromendDateWeek) ){

                return { id: r.id, resourceId: r.resourceId,start: r.start, end:r.end };
            }
           //  return { id: r.id, resourceId: r.resourceId,start: r.start, end:r.end };
        })

       // console.log('userShifts',userShifts);

        const nextMonthUserShifts = currentList.list.map((r)=>{

            if(toStartDateWeek && toendDateWeek && (new Date(r.end)>=toStartDateWeek && new Date(r.end)<=toendDateWeek) ){

                return { id: r.id, resourceId: r.resourceId,start: r.start, end:r.end };
            }
        })


      // let demoShifts = userShifts.map(item=>item.resourceId).filter((value,index,self)=>self.indexOf(value)===index)

        const userOptions = resources.map((r) => {
           
            return { value: r.id,label:r.name};
        });


       // console.log('userOptions',userOptions);

        const getFilteredUsers = userOptions.filter( el => {
           
            return userShifts.some( f => {
            //    if(fromStartDateWeek && fromendDateWeek && (new Date(f.end).getDate()>= fromStartDateWeek && new Date(f.end).getDate()<=fromendDateWeek)){
                    
            //        return f.resourceId === el.value;
            //    }
            if(f){

                return f.resourceId === el.value;
            }
            });
          });

         // console.log('getFilteredUsers',getFilteredUsers);

        //to get comma seprate values from array of objects
        // var CSVOf_arr = getFilteredUsers.map((item) => { return item.value }).join(',')
       
        const getTileClass = (p, lookInFrom) => {
            const selectedWeeks = lookInFrom ? fromWeeks : toWeeks;
            if (selectedWeeks.indexOf(parseInt(moment(p.date).format('w'))) >= 0) {
                return lookInFrom ? 'day-week-selected-from' : 'day-week-selected-to';
            }
            return '';
        };
        // const disabledFromWeeks = [...fromWeeks];
        // console.log('disabledFromWeeks',disabledFromWeeks);
        // for (var i = 0; i < parseInt(moment(new Date()).add(-7, 'days').format('w')); i++) {
        //     disabledFromWeeks.push(i);
        // }

        return (
            <Fragment>
                <Modal dialogClassName="lg" className="lg" size="lg" show onHide={() => this.handleRun('cancel')}>
                    <Modal.Header closeButton className="set_ModalHeader">
                        <Modal.Title>{this.props.t('Copy')}{' '}{this.props.t('Schedule')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <div className="row">
                            <div className="col-md-12">
                                <div className="form-group field field-string">
                                    <label className="control-label">{t('Select people...')} *</label>{' '}
                                    <a href="#" onClick={() => this.selectAll()}>
                                        [{t('Select All')}]
                                    </a>
                                    <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                                        {getFilteredUsers.map((u) => (
                                            <label style={{ width: '33%' }}>
                                                <input
                                                    type="checkbox"
                                                    checked={users.indexOf(u.value) >= 0}
                                                    onChange={() => this.addUser(u.value)}
                                                />
                                                &nbsp;
                                                {u.label}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-12">
                            <label className="control-label">{t('Employees with available schedule')} *</label>{' '}
                            <MultiSelect
                    options={getFilteredUsers}
                   // selected={getFilteredUsers}
                    selected={this.state.users}
                    onSelectedChanged={selected => this.handleSelectedOptions(selected)}
                    overrideStrings={{
                        selectSomeItems: t('Select...'),
                        allItemsAreSelected: t('All Users Are Selected'),
                        selectAll: t('Select All'),
                        search: t('Find and Select users')
                      }} 
                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label style={{color:'#989898',fontSize:'14px',marginBottom:'12px'}}>
                                    {t('From weeks')}
                                    <span style={{ color: 'black',fontSize:'16px' }}>
                                        &nbsp;&nbsp;{_.orderBy(fromWeeks, (r) => r).join(', ')}
                                    </span>
                                </label>
                                <Calendar
                                    locale={moment.locale()}
                                    t={t}
                                    showFixedNumberOfWeeks
                                    showWeekNumbers
                                    checkedWeekNumbers={fromWeeks}
                                    disabledWeekNumbers={toWeeks}
                                    onClickWeekNumber={(week) => this.onHandleWeek(week, 'fromWeeks')}
                                    tileClassName={(params) => getTileClass(params, true)}
                                />
                                {/*                                    onActiveStartDateChange={(a, v) => this.setState({ fromWeeks: [] })}
                                 */}
                            </div>
                            <div
                                className="col-md-6"
                                style={{
                                    display: 'flex',
                                    //justifyContent: 'right',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <label style={{color:'#989898',fontSize:'14px',marginBottom:'12px'}}>
                                    {t('To weeks')}
                                    <span style={{ color: 'black',fontSize:'16px' }}>
                                        &nbsp;&nbsp;{_.orderBy(toWeeks, (r) => r).join(', ')}
                                    </span>
                                </label>
                                <Calendar
                                    locale={moment.locale()}
                                    t={t}
                                    activeStartDate={activeStartDate}
                                    onActiveStartDateChange={(a, v) => this.setState({ activeStartDate: v })}
                                    showFixedNumberOfWeeks
                                    showWeekNumbers
                                    checkedWeekNumbers={toWeeks}
                                    disabledWeekNumbers={fromWeeks}
                                    onClickWeekNumber={(week) => this.onHandleWeek(week, 'toWeeks')}
                                    tileClassName={(params) => getTileClass(params, false)}
                                />
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-md-12" style={{ textAlign: 'right', paddingTop: '20px' }}>
                                <Button round wd icon type="submit" onClick={() => this.handleRun('cancel')}>
                                    {' '}
                                    <i className="fa fa-times" /> {t('Cancel')}
                                </Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button  wd icon fill type="submit" onClick={() => this.handleRun('save')}>
                                    {' '}
                                  {t('Start copy')}
                                </Button>
                            </div>
                        </div> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{textAlign:'left'}}>
                                <Button  wd icon fill type="submit" onClick={() => this.handleRun('save')}>
                                    {' '}
                                  {t('Start copy')}
                                </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default CopyForm;
