import React, { Component, Fragment } from "react";
import { Button, Modal, Table } from 'react-bootstrap';
import { inject, observer } from "mobx-react";
import GenericList from "../../../../components/GenericList/GenericList";
import RecClose from "./../../../../assets/img/rec-close.svg"
import withLocalization from "../../../../hoc/withLocalization";
// import Table from 'react-bootstrap/Table';
// import withLocalization from '../../../../../hoc/withLocalization';
import "../Widgets/CaseList.css"
@inject('recipientStore', 'signatureStore')
@withLocalization
@observer


export class recipientList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showrecipientList: true,
            
        }
    }

    // componentDidUpdate() {
    //     const { signatureStore, recipientStore } = this.props
    //     //const caseId = signatureStore.caseId;
    //     const caseId = 'U2FsdGVkX18tMS5kuplHXjNr2vW85r5tKBDCROZH0To=';
    //     if (caseId) {
    //         // if (!(signatureStore.documentList && signatureStore.documentList.length)) {
    //         //     signatureStore.fetchAll(signatureStore.caseId);
    //         // }
    //         if (!(recipientStore.recipientsList && recipientStore.recipientsList.length)) {
    //             recipientStore.fetchAllRecipients(caseId);
    //         }
    //     }
    //     signatureStore.openFullScreenMode();
    // }

    render() {
        const {t} = this.props;
        // const { filters, appliedFilters } = this.props.signatureStore;
        return (
            <Fragment>
                
                    <Modal className="recipientListModal" show={this.props.show} onHide={this.state.handlerecipientList}>
                        <Modal.Header>
                            <Modal.Title style={{ fontWeight: "bold" }}>{t('Recipients')}</Modal.Title>
                            <img src={RecClose} alt="close" onClick={() => { this.props.handlerecipientList() }} />
                        </Modal.Header>
                        <Modal.Body>
                            {!this.props.caseId && <div>{t('No data found')}</div>}
                            {this.props.caseId && <GenericList
                                columns={[
                                    {

                                        Header: 'Name',
                                        id: 'user_name',
                                        sortable: true,
                                        accessor: 'user_name',


                                    },
                                    {

                                        Header: 'Email',
                                        id: 'email',
                                        sortable: true,
                                        accessor: 'email',


                                    },
                                    {

                                        Header: 'Status',
                                        id: 'status',
                                        sortable: true,
                                        accessor: 'status',
                                    },
                                    {

                                        Header: 'Type',
                                        id: 'signing_type',
                                        sortable: true,
                                        accessor: 'signing_type',


                                    },
                                ]}
                                
                                requestData={(params) => this.props.recipientStore.fetchAllRecipients(this.props.caseId)}
                            />
                            }
                        </Modal.Body>
                    </Modal>    
            </Fragment>

        )
    }
}

export default recipientList;