import React from 'react';
import PropTypes from 'prop-types';

import constants from '../constants';

const { STATUS_TEXTS } = constants;

const StatusBox = ({ status }) => <div className={`status-box status-box_${status}`}>{STATUS_TEXTS[status]}</div>;

StatusBox.propTypes = {
    status: PropTypes.string.isRequired,
};

export default StatusBox;
