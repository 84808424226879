import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormControl } from 'react-bootstrap';
import { filter, find } from 'lodash';
import config from '~/library/config';
import agent from '~/library/agent';
import {countryOptions} from '../../../utils/constants';
import Select from '../../../elements/Select'; 
import Button from '../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '../../../hoc/withLocalization';

@inject('commonStore')
@withLocalization
@observer
class ContractFilter extends Component {
    
    render() {
        const {
            filters, setFilter, onFilter
        } = this.props;
        const { country } = filters;
        const currentSelect = find(countryOptions, o => {
            return o.value === country;
        });

        return (
           
            <div className="list-filters__item" style={{ position:'absolute',marginTop:'-140px' }}>
                    <Select
                        options={countryOptions}
                        style={{ width: 450 }}   
                        onChange={e => {
                            setFilter('country', e.value);
                                    onFilter();}}
                        value={currentSelect}                    
                    />
                </div>        
        );
    }
}

export default ContractFilter;