import { withRouter, Link } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';

// Components
import {
    Grid, Row, Col, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';

import ListErrors from '../../components/ListErrors';
import PrimaryLayout from '../../layouts/PrimaryLayout';

import withLocalization from '~/hoc/withLocalization';
import Card from '~/components/Card/Card.jsx';
import Button from '~/components/CustomButton/CustomButton.jsx';

@inject('authStore')
@withRouter
@withLocalization
@observer
export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardHidden: true,
            email: '',
            errors: [],
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ cardHidden: false });
        }, 700);
    }

    componentWillUnmount() {
        const { authStore } = this.props;
        authStore.reset();
    }

    handleEmailChange = e => {
        this.setState({ email: e.target.value });
    };

    handleSubmitForm = e => {
        const { authStore, history } = this.props;
        e.preventDefault();
        authStore.resetPassword(this.state.email).then(result => {
            if (result) {
                history.replace('/login');
            } else {
                this.setState({ errors: ['Incorrect email'] });
            }
        });
    };

    render() {
        const { authStore, t } = this.props;
        const { inProgress } = authStore;
        const { errors } = this.state;
        const { email } = this.state;

        return (
            <PrimaryLayout>
                <div className="forgot-page">
                    <Grid>
                        <Row>
                            <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                                <form onSubmit={this.handleSubmitForm}>
                                    <Card
                                        hidden={this.state.cardHidden}
                                        textCenter
                                        title={t('Forgot password')}
                                        content={(
                                            <div>
                                                This will send you an email with resetted password. Please check in Spam
                                                folder for the email.
                                                <ListErrors errors={errors} />
                                                <FormGroup>
                                                    <ControlLabel>{t('Email address')}</ControlLabel>
                                                    <FormControl
                                                        placeholder="Enter email"
                                                        value={email}
                                                        type="email"
                                                        onChange={this.handleEmailChange}
                                                    />
                                                </FormGroup>
                                            </div>
                                        )}
                                        legend={(
                                            <div>
                                                <Button
                                                    bsStyle="info"
                                                    fill
                                                    wd
                                                    disabled={inProgress}
                                                    onClick={this.handleSubmitForm}
                                                >
                                                    {t('Submit')}
                                                </Button>
                                                <br />
                                                <br />
                                                <Link to="/login">{t('Back to login')}</Link>
                                                <br />
                                                <br />
                                            </div>
                                        )}
                                        ftTextCenter
                                    />
                                </form>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </PrimaryLayout>
        );
    }
}
