import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch, faExclamation, faBook } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import config from '~/library/config';
import Button from '../../components/CustomButton/CustomButton.jsx';
import { OvertimeDisplay } from '../../components/OvertimeDisplay/OvertimeDisplay';

import TimelogListFilter from './TimelogListFilter/TimelogListFilter';

import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';

// Elements

import customerFeature from '~/utils/customerFeature';
import { minutesToTimeInput } from '~/utils/timeCalc';
import { statusMap } from '~/utils/normalStatuses';
import { serverTimeFormat } from '~/library/core';

@inject('timelogStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@observer
class VisitorDashboard extends Component {
    state = {
        accepting: null,
        acceptanceChanged: false,
        selected: null,
        isUserUploadedImg: false,
        userImageAttachmentId: null
    };

    handleTableButtonAction = (id, type) => {
        this.props.onTableAction(id, type);
    };

    // localeIsEn = !!(this.props.commonStore && this.props.commonStore.locale === 'en');

    alreadyProcessedCols = false;

    cols = [
        {
            Header: 'Project',
            id: 'projects.name',
            accessor: ({ is_holiday, project_name, project_number }) => (
                <span>{is_holiday ? this.props.t('holiday') : `${project_number || ''} | ${project_name || ''}`}</span>
            ),
        },
        {
            Header: 'Task',
            id: 'tasks.name',
            accessor: ({ is_holiday, task_name, task_number }) => (
                <span>{is_holiday ? this.props.t('holiday') : `${task_number || ''} | ${task_name || ''}`}</span>
            ),
        },
        {
            Header: 'Date',
            id: 'for_date',
            accessor: ({ for_date }) => <span>{for_date && moment(for_date).format(serverTimeFormat())}</span>,
        },
        {
            Header: 'Time',
            id: 'from',
            accessor: ({ from, to, tracker_status, status }) => (
                <span>
                    {minutesToTimeInput(from, true)} -{' '}
                    {status == 'draft'
                        ? '...'
                        : minutesToTimeInput(to, true)}
                </span>
            ),
        },
        {
            Header: 'Total Hours',
            id: 'total_hours_normal',
            accessor: ({ total_hours_normal, total_hours_overtime }) => (
                <span>{minutesToTimeInput(total_hours_normal, false)}</span>
            ),
        },
        {
            Header: 'Overtime',
            id: 'total_hours_overtime',
            accessor: ({ data }) => <OvertimeDisplay data={data ? data.overtimes : null} />,
        },
        // {
        //     Header: 'Start variation',
        //     id: 'start_variation',
        //     accessor: ({ startVariation, validateTimetrackOption }) => {
        //         if (!validateTimetrackOption) {
        //             return null;
        //         }
        //         switch (startVariation){
        //             case 'overtime':
        //                 return <FontAwesomeIcon style={{color: 'blue'}} icon={faStopwatch} />
        //             case 'critical':
        //                 return <FontAwesomeIcon style={{color: 'red'}} icon={faExclamation} />
        //             case 'negative':
        //                 return <FontAwesomeIcon style={{color: 'red'}} icon={faStopwatch} />
        //             default:
        //                 return <FontAwesomeIcon style={{color: 'green'}} icon={faStopwatch} />
        //         }
        //     }
        // },
        // {
        //     Header: 'End variation',
        //     id: 'end_variation',
        //     accessor: ({ endVariation, validateTimetrackOption }) => {
        //         if (!validateTimetrackOption) {
        //             return null;
        //         }
        //         switch (endVariation){
        //             case 'overtime':
        //                 return <FontAwesomeIcon style={{color: 'blue'}} icon={faStopwatch} />
        //             case 'critical':
        //                 return <FontAwesomeIcon style={{color: 'red'}} icon={faExclamation} />
        //             case 'negative':
        //                 return <FontAwesomeIcon style={{color: 'red'}} icon={faStopwatch} />
        //             default:
        //                 return <FontAwesomeIcon style={{color: 'green'}} icon={faStopwatch} />
        //         }
        //     }
        // },
        {
            Header: 'Actions',
            id: 'actions',
            sortable: false,
            minWidth: 150,
            accessor: (full) => this.renderTableActionButtons(full.id, full),
        },
    ];

    componentDidMount() {
        const { commonStore, userStore } = this.props;
        const { data: clientData } = commonStore.config.client;
        const { data: biztypeData } = commonStore.config.biztype;


        if (!this.alreadyProcessedCols) {
            if (userStore.currentUser.user_type != 'member') {
                this.cols.splice(0, 0, {
                    Header: 'Member',
                    id: 'user_id',
                    accessor: ({ first_name, last_name }) => (
                        <span>
                            {first_name} {last_name}
                        </span>
                    ),
                });
            }
            this.alreadyProcessedCols = true;
        }
    }

    handleAccept(id, full) {
        if (full.status == 'approved' && !full.validateTimetrackOption) return;
        this.setState({
            accepting: {
                ids: [id],
                count: 1,
                entity: 'timelog',
                startedByWorklogButton: true,
            },
        });
    }

    onSelectionChanged(ids) {
        this.setState({ selected: ids });
    }

    onMassAccept() {
        const ids = [];
        if (!this.state.selected) {
            this.props.commonStore.addNotification(this.props.t('Choose items'), null, 'error');
            return;
        }
        Object.keys(this.state.selected).forEach((id) => {
            if (this.state.selected[id]) ids.push(id);
        });
        this.setState({
            acceptanceChanged: false,
            accepting: {
                ids,
                count: ids.length,
                entity: 'timelog',
            },
        });
    }

    afterMassAccept() {
        this.props.timelogStore.resetLastListLoadTime();
        this.setState({ accepting: null, acceptanceChanged: true });
    }

    renderTableActionButtons = (id, full) => {
        const { t } = this.props;
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const showStatusBlock = customerFeature('allow_accepting');
        const hideEditWhenAccepted = customerFeature('timelog_no_edit_for_approved');
        const hideEditWhenNotDraft = customerFeature('timelog_no_edit_for_done');
        let hideEdit = hideEditWhenAccepted && full.status == 'approved' && user_type === 'member';
        if (hideEditWhenNotDraft && full.status != 'draft' && user_type === 'member') {
            hideEdit = true;
        }
        const showStatus = (user_type === 'pm' || user_type == 'admin') && customerFeature('allow_accepting');
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');

        let statusText = full.status;
        if (!statusText) statusText = 'active';
        statusText = statusMap[statusText] || '';
        let statusIcon = <i className={'pe-7s-hourglass'} />;
        if (full.status == 'approved') {
            statusIcon = <i className={'pe-7s-check'} />;
        }
        if (full.status == 'rejected') {
            statusIcon = <i className={'pe-7s-close'} />;
        }
        if (full.validateTimetrackOption) {
            switch (full.unitedVariation) {
                case 'overtime':
                    statusIcon = <FontAwesomeIcon icon={faBook} style={{ paddingRight: '2px' }} />;
                    break;
                case 'critical':
                    statusIcon = <FontAwesomeIcon icon={faTimesCircle} style={{ paddingRight: '2px' }} />;
                    break;
                case 'negative':
                    statusIcon = <i><svg style={{ height: '16px', paddingRight: '2px' }} ariaHidden focusable={false} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa"><path fill="currentColor" d="M224 512a64 64 0 0 0 64-64H160a64 64 0 0 0 64 64zm215.39-149.71c-19.32-20.76-55.47-52-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32a32 32 0 1 0-64 0v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29A31.24 31.24 0 0 0 0 384c.11 16.4 13 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32a31.23 31.23 0 0 0-8.61-21.71zM224 352a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm25.4-110.4a16 16 0 0 1-15.9 14.4h-19a16 16 0 0 1-15.9-14.4l-12.8-96a16.06 16.06 0 0 1 15.9-17.6h44.6a16 16 0 0 1 15.9 17.6z"></path></svg></i>;
                    break;
                default:
                    statusIcon = <i><svg style={{ height: '16px', paddingRight: '2px' }} ariaHidden focusable={false} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa"><g className="fa-group"><path fill="currentColor" d="M528.53 171.5l-146.36-21.3-65.43-132.39c-11.71-23.59-45.68-23.89-57.48 0L193.83 150.2 47.47 171.5c-26.27 3.79-36.79 36.08-17.75 54.58l105.91 103-25 145.49c-4.52 26.3 23.22 46 46.48 33.69L288 439.56l130.93 68.69c23.26 12.21 51-7.39 46.48-33.69l-25-145.49 105.91-103c19-18.49 8.48-50.78-17.79-54.57zm-90.89 71l-66.05 64.23 15.63 90.86a12 12 0 0 1-17.4 12.66L288 367.27l-81.82 42.94a12 12 0 0 1-17.4-12.66l15.63-90.86-66-64.23A12 12 0 0 1 145 222l91.34-13.28 40.9-82.81a12 12 0 0 1 21.52 0l40.9 82.81L431 222a12 12 0 0 1 6.64 20.46z" className="fa-secondary" ></path><path fill="currentColor" d="M437.64 242.46l-66.05 64.23 15.63 90.86a12 12 0 0 1-17.4 12.66L288 367.27l-81.82 42.94a12 12 0 0 1-17.4-12.66l15.63-90.86-66-64.23A12 12 0 0 1 145 222l91.34-13.28 40.9-82.81a12 12 0 0 1 21.52 0l40.9 82.81L431 222a12 12 0 0 1 6.64 20.46z" class="fa-primary"></path></g></svg></i>;
            }
        }
        const actionable = showStatus && full.status != 'approved';
        let timetrackValidatePopupText = [];
        if (full.validateTimetrackOption) {
            // timetrackValidatePopupText += '\n';
            if (!full.startVariationNumber && !full.endVariationNumber) {
                timetrackValidatePopupText.push(t('Arrived and left in time'));
            } else {
                if (full.startVariationNumber) {
                    if (full.startVariationNumber < 0) {
                        timetrackValidatePopupText.push(`${t('Arrived')} ${minutesToTimeInput(-full.startVariationNumber)} ${t('later')}`);
                    } else {
                        timetrackValidatePopupText.push(`${t('Arrived')} ${minutesToTimeInput(full.startVariationNumber)} ${t('earlier')}`);
                    }
                }
                if (full.endVariationNumber) {
                    if (full.endVariationNumber < 0) {
                        timetrackValidatePopupText.push(`${t('Left')} ${minutesToTimeInput(-full.endVariationNumber)} ${t('earlier')}`);
                    } else {
                        timetrackValidatePopupText.push(`${t('Left')} ${minutesToTimeInput(full.endVariationNumber)} ${t('later')}`);
                    }
                }
            }
        }
        return (
            <div className="actions-center">
                {full.status !== 'draft' && showStatusBlock && (
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-top">
                                {/* t(statusText) + timetrackValidatePopupText */}
                                {[t(statusText), ...timetrackValidatePopupText].map(text => <>{text}<br /></>)}
                            </Tooltip>
                        }
                    >
                        <div
                            className={`status-block status-${full.status || 'active'}${actionable ? ' status-actionable' : ''
                                }${(full.status === 'approved' && full.validateTimetrackOption) ? ' status-approved-validate-timetrack' : ''}${user_type === 'member' ? ' status-no-click-for-member' : ''}`}
                            // title={t(statusText)}
                            onClick={() => (showStatus ? this.handleAccept(id, full) : null)}
                            style={{ marginTop: '-4px', lineHeight: '10px' }}
                        >
                            {statusIcon}
                            <small>
                                <small>
                                    {full.status_changed_by && moment(new Date(full.status_changed_on)).format(serverTimeFormat(true))}
                                </small>
                            </small>
                            <br />
                            <small>
                                <small>{this.props.commonStore.getUsername(full.status_changed_by)}</small>
                            </small>
                        </div>
                    </OverlayTrigger>

                )}
                {full.user_image_attachment_id && (
                    <OverlayTrigger
                        key="user-uploded-img"
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-top">
                                {t('User Uploaded Image')}
                            </Tooltip>}
                    >
                        <Button onClick={() => this.setState({ userImageAttachmentId: full.user_image_attachment_id, isUserUploadedImg: true })} simple icon>
                            <i className="fa fa-user" />
                        </Button>
                    </OverlayTrigger>
                )}
                {full.status === 'draft' && (
                    <div className={`status-block status-${full.status || 'active'}`}>{t(statusText)}</div>
                )}
                {!hideEdit && (
                    <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                        {(userId == full.user_id || allow) && <i className="fa fa-edit" />}
                        {userId != full.user_id && !allow && <i className="fa fa-eye" />}
                    </Button>
                )}
                {(userId == full.user_id || allow) && (
                    <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                        <i className="fa fa-times" />
                    </Button>
                )}                
                {/* <Button
        onClick={() => console.log('redirect to timelog View')}
        bsStyle="default"
        simple
        icon
      >
        <i className="fa fa-align-left" />
      </Button> */}
            </div>
        );
    };

    render() {
        const { loading, filters, appliedFilters } = this.props.timelogStore;
        const { user_type } = this.props.userStore.currentUser;
        const { accepting, acceptanceChanged, isUserUploadedImg, userImageAttachmentId } = this.state;
        this.localeIsEn = !!(this.props.commonStore && this.props.commonStore.locale === 'en');
        const showAddForm = user_type === 'member' || user_type === 'pm' || !customerFeature('pm_no_register');
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');
        const showStatus = (user_type === 'pm' || user_type === 'admin') && customerFeature('allow_accepting');
        return (
            <Fragment>
                <div className="table-list">
                    <div className="table-list__header">
                        {(showAddForm || allow) && (
                            <Button fill wd icon onClick={() => this.props.onAdd()}>
                                {this.props.t('Add new')} <i className="fa fa-plus" />
                            </Button>
                        )}
                    </div>
                    <GenericList
                        columns={this.cols}
                        onSelectionChanged={(selection) => this.onSelectionChanged(selection)}
                        allowCheckboxes={showStatus}
                        forceReload={this.props.timelogStore.deleteSuccess || acceptanceChanged}
                        filters={appliedFilters}
                        lastListLoadTime={this.props.timelogStore.lastListLoadTime}
                        header={
                            <TimelogListFilter
                                filters={filters}
                                setFilter={(name, value) => this.props.timelogStore.setFilter(name, value)}
                                onFilter={() => this.props.timelogStore.onFilter()}
                                loading={loading}
                                onChangeStatus={() => this.onMassAccept()}
                                showStatus={showStatus}
                            />
                        }
                        requestData={(params) => this.props.timelogStore.loadList(params)}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                style: {
                                    background:
                                        rowInfo.original.total_hours_normal + rowInfo.original.total_hours_overtime >=
                                            9 * 60
                                            ? '#f5bebe'
                                            : '',
                                },
                            };
                        }}
                        pageSize={100}
                    />
                </div>
            </Fragment>
        );
    }
}
export default VisitorDashboard;
