import React, { Component } from 'react';

import Checkbox from '../../CustomCheckbox/CustomCheckbox.jsx';

class CheckBoxWidget extends Component {
    render() {
        const { value, onChange, label } = this.props;
        return (
            <div className="check-box-widget">
                <Checkbox isChecked={value} onChange={onChange} label={label} />
            </div>
        );
    }
}

export default CheckBoxWidget;
