import React, { Component, createRef, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Icon } from 'leaflet';
import {
    Map, Marker, Popup, TileLayer, FeatureGroup
} from 'react-leaflet';
import { Modal } from 'react-bootstrap';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import LoadingSpinner from '../../../elements/LoadingSpinner';
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Input, Textarea } from '../../../elements/Input';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import { leafletStringToLocation, locationToString, googleCoordsToLeaflet } from '../../../utils/geo';
import timelogStatus from '../../../utils/timelogStatus';

// require("leaflet.markercluster");

const DEFAULT_COORDS = {
    latitude: 59.9141521,
    longitude: 10.7463433,
    latitudeDelta: 0.01,
    longitudeDelta: 0.01,
};

// import 'leaflet/dist/leaflet.css';
// import 'react-leaflet-markercluster/dist/styles.min.css';

@inject('timelogStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class GPSInfo extends Component {
    state = {
        zoom: 15,
        center: googleCoordsToLeaflet(DEFAULT_COORDS),
        loaded: false,
    };

    mapRef = createRef();

    groupRef = createRef();

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const { t, timelogStore, params } = this.props;
        // console.log(params);
        const _params = {
            date: moment(params.dateRange.start).format('YYYY-MM-DD'),
            user: params.users[0],
            project: params.project.value,
        };
        await timelogStore.getAllLocations(_params);
        this.afterOpenMap();
        this.setState({ loaded: true });
    }

    afterOpenMap() {
        const { t, timelogStore, params } = this.props;
        if (timelogStore.allLocations.length) {
            this.setState({ center: timelogStore.allLocations[0].l });
        }
        setTimeout(() => {
            const map = this.mapRef.current ? this.mapRef.current.leafletElement : null; // get native Map instance
            const group = this.groupRef.current ? this.groupRef.current.leafletElement : null; // get native featureGroup instance
            if (!!map && !!group) {
                console.log('group-zoom');
                map.fitBounds(group.getBounds().pad(100));
            }
        }, 1000);
    }

    render() {
        const {
            t, params, timelogStore, onClose
        } = this.props;
        const { center, loaded } = this.state;

        const { allLocations } = timelogStore;
        if (!loaded) return null;

        const myIcon = new Icon({
            iconUrl: '/images/map-icon.png',
            iconSize: [38, 50],
            iconAnchor: [22, 50],
            popupAnchor: [-3, -76],
            shadowUrl: '/images/map-icon-shadow.png',
            shadowSize: [68, 95],
            shadowAnchor: [22, 94],
        });

        return (
            <Fragment>
                <Modal size="lg" show onHide={() => onClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Locations')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ height: '500px' }}>
                        <div style={{ height: '400px', width: '100%' }}>
                            {!!allLocations.length && (
                                <Map
                                    center={center}
                                    length={4}
                                    zoom={this.state.zoom}
                                    zoomSnap={0.1}
                                    maxZoom={17}
                                    ref={this.mapRef}
                                >
                                    <TileLayer
                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <MarkerClusterGroup>
                                        {allLocations.map(row => (
                                            <Marker position={row.l} icon={myIcon} key={row.time}>
                                                <Popup>{minutesToTimeInput(row.time, true)}</Popup>
                                            </Marker>
                                        ))}
                                    </MarkerClusterGroup>
                                </Map>
                            )}
                            {!allLocations.length && <div>{t('No data')}</div>}
                            <div style={{ width: '100%', padding: '10px', display: 'flex' }}>
                                <button className="btn btn-fill" onClick={() => onClose()}>
                                    {t('Close')}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default GPSInfo;
