import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import { serverTimeFormat } from '~/library/core';
import { shortDateFormat } from '~/utils/dateFormat'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

//components
import DocumentFilter from './DocumentFilter';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import GenericList from '~/components/GenericList/GenericList';
import config from '~/library/config';
import agent from '~/library/agent';

//styles
import '../../../../styles/css/styles.css';

//this component for listing all document_templates and handles the fucntionality over them//

@inject('authStore', 'userStore', 'commonStore', 'documentStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class DocumentTemplateTable extends Component {

    handleTableActionButtonAction = (id, type) => {
        //handles action button clicks//
        if (type === 'edit') {
            //edit document_template           
            this.editDocumentTemplate(id)
        }
        else if (type === 'delete') {
            //delete document_template
            this.removeDocumentTemplate(id);
        }
        else if (type === 'report') {
            //generate document_template report
            this.printTemplateReport(id)
        }
        else if (type === 'status_report') {
            this.printDocumentBookEmployeeReadStatusReport(id);
        }
    }

    printDocumentBookEmployeeReadStatusReport = (id) => {
        //generates read status report for docuemnt_book of employees//
        const { commonStore } = this.props;

        let hydrated = {
            authorization: commonStore.token
        }

        console.log("hydrated::::",hydrated);

        window.open(`${config.API_ROOT}/document_read/report/${id}${agent.convertToGetParams(hydrated)}`);
    }

    printTemplateReport(id) {
        //generates template_documents report//
        const { commonStore } = this.props;

        let hydrated = {
            authorization: commonStore.token
        }

        window.open(`${config.API_ROOT}/document_templates/report/${id}${agent.convertToGetParams(hydrated)}`);
    }

//calls action from documentStore to delete document_template with id//
    removeDocumentTemplate(id) {
        const { commonStore, t } = this.props;

        return this.props.handleDelete(id, id => {
            return  this.props.documentStore.deleteDocumentTemplateById(id).then((res) => {
                if (res) {
                    commonStore.addNotification(t('Document Book Removed'), null, 'error');
                    this.props.documentStore.resetLastTemplateListLoadTime();
                }
                return res;
            })
        });

    }

    editDocumentTemplate(id) {
        //calls parentHandler to redirect to addEditComponent with given Id//
        const { history } = this.props;
        history.push(`/admin/documents/templates/${id}`);
    }


    renderTableActionButtons = (id, mailReport, status) => {
        //renders table edit delete buttons in generic list//        
        const { t } = this.props;
        const { user_type } = this.props.userStore.currentUser;
        return (
            <div>
                {user_type != "member" ?
                    <div className="actions-center">
                        <Button onClick={() => this.handleTableActionButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                            <i className="fa fa-edit" />
                        </Button>
                        <Button onClick={() => this.handleTableActionButtonAction(id, 'delete')} bsStyle="danger" simple icon>
                            <i className="fa fa-times" />
                        </Button>
                        <OverlayTrigger
                            key="PDF"
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Download As PDF")}
                                </Tooltip>
                            }
                        >
                            <Button onClick={() => this.handleTableActionButtonAction(id, 'report')} bsStyle="danger" simple icon>
                                <i className="fa fa-print" />
                            </Button>
                        </OverlayTrigger>

                        {mailReport &&
                            <OverlayTrigger
                                key="Report"
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        {t("Download Signed Report")}
                                    </Tooltip>
                                }
                            >
                                <Button onClick={() => this.handleTableActionButtonAction(id, 'status_report')} bsStyle="danger" simple icon>
                                    <i className="fa fa-file-alt" />
                                </Button>
                            </OverlayTrigger>
                        }
                    </div> :
                    <div>
                        <Button fill wd onClick={() => this.handleTableActionButtonAction(id, 'edit')} bsStyle={status?'success':'primary'}>
                            {t('View')}
                        </Button>
                    </div>
                }
            </div>

        )
    }

    render() {
        //renders document_template list using generic list along with documentFilter//

        const { filters, loading, appliedFilters } = this.props.documentStore;
        const {config} = this.props.commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        //        const { t } = this.props;
        return (
            <div className="table-list">
                <GenericList
                    columns={[
                        {
                            Header: 'Document Book',
                            id: 'template_name',
                            sortable: false,
                            accessor: ({ id, template_name }) => (
                                <a className='list-main-column'
                                    onClick={() => this.handleTableActionButtonAction(id, 'edit')}
                                >
                                    {`${template_name}`}
                                </a>
                            )
                        },
                        {
                            Header: 'Created By',
                            id: 'created_by',
                            sortable: false,
                            accessor: 'created_by'
                        },
                        {
                            Header: 'Updated On',
                            id: 'updated_at',
                            sortable: false,
                            accessor: ({ updated_at }) => (
                                <span>{updated_at && shortDateFormat(updated_at, dateTimeRules)}</span>
                            )
                        },
                        {
                            Header: 'Actions',
                            id: 'actions',
                            sortable: false,
                            accessor: (full) => this.renderTableActionButtons(full.id, full.mailReport, full.status)
                        },
                    ]}

                    filters={appliedFilters}
                    lastListLoadTime={this.props.documentStore.lastTemplateListLoadTime}
                    header={
                        <DocumentFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.documentStore.setFilter(name, value)}
                            onFilter={() => this.props.documentStore.onFilter()}
                            loading={loading}
                        />
                    }
                    requestData={(params) => this.props.documentStore.getDocumentTemplates(params)}
                />
            </div>
        )
    }
}

export default DocumentTemplateTable;