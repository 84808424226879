import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormControl } from 'react-bootstrap';
import { find } from 'lodash';

import Button from '../../../components/CustomButton/CustomButton.jsx';
import Select from '../../../elements/Select';
import withLocalization from '../../../hoc/withLocalization';
import statuses from '../../../utils/normalStatuses';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';

@inject('commonStore')
@withLocalization
@observer
class AbsencesFilter extends Component {
    render() {
        const {
            filters, setFilter, onFilter, loading, t, showStatus, onChangeStatus
        } = this.props;
        return (
            <div className="list-filter">
                <h5>{t('Project')}</h5>
                <div style={{ marginBottom: '20px', background: 'white' }}>
                    <ProjectsWidget
                        value={filters.project || null}
                        onChange={(e, f) => {
                            setFilter('project', f);
                        }}
                        style={{ border: '1px solid' }}
                    />
                </div>
            </div>
        );
    }
}

export default AbsencesFilter;
