import React, { Component } from 'react';
import shave from 'shave';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import { serverTimeFormat,serverIsAmPmTimeFormat } from '../../../library/core';
import { shortDateFormat } from '~/utils/dateFormat'

import './ConversationListItem.css';
@inject('commonStore','chatStore','userStore','documentStore')
@withRemoveDialog
@observer
export default class ConversationListItem extends Component {

    loadData(){
        const {chatStore,history} = this.props;
        this.props.chatStore.loadPrivateRooms();
        this.props.chatStore.setCurrentPrivateRoom();
        this.props.chatStore.setCurrentPrivateRoomId();
        history.push('/admin/privatechat')
}
    componentDidMount() {
        shave('.conversation-snippet', 25);
    }

    

    handleRemove(id){
        const {  commonStore, t,chatStore } = this.props;
             this.props.handleDelete(id, id => {
            return this.props.chatStore.removePrivateRoomData(id).then(res => {
                    commonStore.addNotification(t('Deleted'), null, 'error');
                    this.loadData();
                    this.props.chatStore.sendDeletedEvent();
               return res;
            })
        });
    }
    render() {
        const {
            photo, name, description, id,room_initiator
        } = this.props.data;
        // console.log("check room id:",id)
        const {
            changeRoom, editRoom, allowModification, currentRoomId, unreads, commonStore,chatStore,privateUnreads,userStore
        } = this.props;
        const unread = unreads[id];
        const {chatCurrentTab,currentPrivateRoom} = this.props.chatStore;
        const lastMessage = this.props.data.lastMessage?this.props.data.lastMessage:"";
        let dateTime = false;
        let dateTimeRules = commonStore.config.client && commonStore.config.client.data && 
                              commonStore.config.client.data.dateTimeRules ? 
                              {...commonStore.config.client.data.dateTimeRules}
                              : {short_date_format: "DD.MM.YYYY",  time_format: "hh:mm"};
        dateTimeRules.short_date_format = dateTimeRules.short_date_format ? dateTimeRules.short_date_format : "DD.MM.YYYY";
        dateTimeRules.time_format = dateTimeRules.time_format ? dateTimeRules.time_format : "hh:mm";
        if(lastMessage != ""){            
            if(moment(lastMessage.created_at).isBefore(new Date(),'day'))
                dateTime = moment(lastMessage.created_at).format(dateTimeRules.short_date_format);
            else
                dateTime = moment(lastMessage.created_at).format(dateTimeRules.time_format == "hh:mm"?'H:mm':'hh:mm a');
        }
        /* {allowModification && (
          <div className="conversation-action">
            <i className="fa fa-cog" onClick={()=>editRoom(id)}></i>
          </div>
        )} */
        const showUnread = !!(unread && unread !== '0') 
        // const {config} = this.props.commonStore;
        // const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        return (
            
            <div
                className={`conversation-list-item ${currentRoomId === id ? 'conversation-list-item-current' : ''}`}
                onClick={() => changeRoom(id)} style={{marginTop:'10px'}}
            >
                <div className='conversation-room-initials'>{name.replace('and','').split(" ").map(obj=> obj[0]).join('').substring(0,3)}</div>
                <div className="conversation-info">
                    <h1 className="conversation-title">{name}</h1>
                    <p className="conversation-snippet">
                        {lastMessage.message!=""?
                            lastMessage.message:
                            lastMessage.image > 0 ? this.props.t('Shared an image'):''
                        }
                    </p>
                </div>
                {showUnread ? <div className="conversation-badge">{unread}</div>
                :<div className="conversation-last-message-time">
                    {dateTime!=false && <p style={{fontSize:'12px',marginTop:'10px'}}>{dateTime}</p>}
                </div>                 
                }
               
            
             {chatCurrentTab === 2 && room_initiator === userStore.currentUser.id ?
                <i
                    className="fas fa-trash-alt cursor-pointer"
                    aria-hidden="true"
                    style={{ fontSize: '20px', color: 'grey',paddingLeft:'12px' }}
                    onClick={() => this.handleRemove(id)}
                    ></i> : ''}
                    </div>
        );
    }
}
