import { observable, action } from 'mobx';
import moment from 'moment/min/moment-with-locales';

import queryString from 'query-string';

import agent from '../agent';

import userStore from './userStore';


class MonitorStore {

    @observable currentList = [];

    @observable requestParams = null;

    @observable currentEntity = {};

    @observable loading = false;

    @observable updating = false;

    @observable deleteItemIndex = new Set();

    @observable unit = "°C";

    @action setDeletedItemIndex(i){
        this.deleteItemIndex.add(i);
        console.log('this item indse',this.deleteItemIndex);
    }

    @observable deleteProductIndex = new Set();

    @action setDeletedProductIndex(i){
        this.deleteProductIndex.add(i);
    }

    @action resetDeleteIndex(){
        this.deleteItemIndex = new Set();
        this.deleteProductIndex = new Set()
    }
 
    @observable currentGridDataId;

    @action resetCurrentGridDataId(){
        this.currentGridDataId = null;
    }

    @observable lastListLoadTime = null;

    @observable tableListDataHistory = [];

    @action resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    @observable filters = {
        status: '',
        name: '',
    };

    @observable appliedFilters = {
        status: '',
        name: '',
    };

    @observable tableMeta = {
        products: [],
        items: []
    };

    @observable tableListData = [];

    
    @action setTableListData(tableData) {
        this.tableListData = tableData;
    }

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
        // requestParams.filters = this.filters;
        // this.loadList(requestParams);
    }

    @action loadDataSync(res) {
        this.currentEntity = res;
        this.tableMeta = res.metadata
    }

    @action loadList(params) {
        return agent.Monitor.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }
    @action load(id) {
        this.loading = true;
        return agent.Monitor.load(id)
            .then(
                action(response => {
                    
                    this.currentEntity = response;
                    this.tableMeta = response.metadata
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }
    @action edit(id, data) {
        this.loading = true;
        return agent.Monitor.edit(id, data)
            .then(
                action(response => {
                   
                    this.currentEntity = response;
                    this.tableMeta = response.metadata
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }



    @action loadGrid(id) {
        this.loading = true;
        return agent.Monitor.loadGridData(id, this.appliedFilters)
            .then(
                action(response => {
                    const monitor_data = response.monitor_data;
                    let tableHistoryData = {}
                    let tableListDataHistory=[];
                    this.currentEntity = response.monitor;
                    let tableData = {};
                    let tableListData = [];
                    if (monitor_data.length) {
                        monitor_data.forEach(tabData => {
                            const dataKeys = Object.keys(tabData.data);
                            if (dataKeys.length) {
                               tableHistoryData.p_name = tabData.p_name;
                               tableHistoryData.name = tabData.name;
                               tableHistoryData.for_date = tabData.for_date;
                               tableHistoryData.unit = tabData.unit;
                               dataKeys.forEach((data) => {
                                    tableData = tabData.data[data]
                                    tableData.meta = tabData.metadata
                                    tableListData = [...tableListData, tableData]
                                })
                                tableHistoryData.tableListData = tableListData;
                                tableListDataHistory = [...tableListDataHistory, tableHistoryData]
                                tableListData = [];
                                tableHistoryData = {}
                            }
                        });
                        this.tableListDataHistory = tableListDataHistory;
                    }
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }
    @action loadGridpdf(id, payload) {
        this.loading = true;
        return agent.Monitor.getPDF(id, payload)
            .then(
                action(response => {
                    const monitor_data = response.monitor_data;
                    let tableHistoryData = {}
                    let tableListDataHistory=[];
                    this.currentEntity = response.monitor;
                    let tableData = {};
                    let tableListData = [];
                    if (monitor_data.length) {
                        monitor_data.forEach(tabData => {
                            const dataKeys = Object.keys(tabData.data);
                            if (dataKeys.length) {
                               tableHistoryData.p_name = tabData.p_name;
                               tableHistoryData.name = tabData.name;
                               tableHistoryData.client_name = tabData.client_name;
                               tableHistoryData.reg_no = tabData.reg_no;
                               tableHistoryData.for_date = tabData.for_date;
                               tableHistoryData.unit = tabData.unit;
                               dataKeys.forEach((data) => {
                                    tableData = tabData.data[data]
                                    tableData.meta = tabData.metadata
                                    tableListData = [...tableListData, tableData]
                                })
                                tableHistoryData.tableListData = tableListData;
                                tableListDataHistory = [...tableListDataHistory, tableHistoryData]
                                tableListData = [];
                                tableHistoryData = {}
                            }
                        });
                        this.tableListDataHistory = tableListDataHistory;
                    }
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action save(values, isAdd) {
        this.updating = true;
        return agent.Monitor.save(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action onGridSave(values, isAdd) {
       
        return agent.Monitor.saveGrid(values, isAdd)
            .then(
                action(user => {
                    
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action onGridUpdate(id) {
        this.updating = true;
        return agent.Monitor.getGridData(id)
            .then(
                action(response => {
                   
                    const monitor_data = response.monitor_data;
                    this.currentEntity = response.monitor;
                    this.tableMeta = response.monitor.metadata;
                    this.unit = response.monitor.unit
                    let tableData = {};
                    let tableListData = [];
                    if (monitor_data.length) {
                        monitor_data.forEach(tabData => {
                            this.currentGridDataId = tabData.id
                            if (tabData.data) {
                                Object.keys(tabData.data).forEach((data) => {
                                    tableData = tabData.data[data]
                                    tableData.meta = tabData.metadata
                                    tableListData = [...tableListData, tableData]
                                })
                            }
                        });
                       
                        this.tableListData = tableListData;
                        this.loading = false;
                    } 
                    this.updating = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action async generateMonitorReport(id) {
        //calls get method from document_template API//
        //takes 1 arguments, type - integer, id//
        //generates reports of documents in template//
        return agent.Monitor.report(id)
            .then(
                action((response) => {
                    return true;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }


}
export default new MonitorStore();