const times = {
    statuses: {
        DRAFT: 'draft',
        MANUAL: 'manual',
        AUTOMATIC: 'automatic',
        VERIFIED: 'verified',
        STAMPED: 'stamped',
        SYSTEM:'system',
    },
    getStatusByGps: timelog => {
        if (timelog.status == 'draft') return times.statuses.DRAFT;
        if (!timelog.gps_data) return times.statuses.MANUAL;
        const { start } = timelog.gps_data;
        const { end } = timelog.gps_data;
        if (!start || !end || start.manual || end.manual) return times.statuses.MANUAL;
        if (start && start.cron && end && end.cron) return times.statuses.AUTOMATIC;
        return times.statuses.VERIFIED;
    },
};
export default times;
