import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';

import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import getSchema from '~/library/schemas/user';
import customerFeature from '~/utils/customerFeature';

const schema = null;

const isFilledDisease = fieldName => f => {
    // console.log(f);
    return true;
};

const uiSchema = {
    'ui:field': 'tabs',
    'ui:tabs': [
        {
            'ui:title': 'Personal Info',
            'ui:layout:hideframe': true,
            id: 'tab1',
            'ui:field': 'layout',
            'ui:layout': [
                {
                    image: { md: 4 },
                    first_name: { md: 4 },
                    last_name: { md: 4 },
                },
                {
                    email: { md: 3 },
                    phone: { md: 3 },
                    social_number: { md: 3 },
                    birthday: { md: 3 },
                },
                {
                    'ui:subtitle': 'Assecces',
                    'ui:className': 'profile-edit-form__group',
                    username: { md: 6 },
                    password: { md: 6 },
                    internal_number: { md: 6 },
                },
                {
                    'ui:className': 'profile-edit-form__group',
                    attachments: { md: 12 },
                },
                {
                    'ui:className': 'profile-edit-form__group',
                    data: { md: 12 },
                },
            ],
            image: {
                'ui:widget': 'ImageUpload',
            },
            attachments: {
                'ui:widget': 'AttachmentsWidget',
                'ui:imageContext': {
                    model: 'User',
                    fileType: 'docs',
                    id: 0,
                },
            },
            data: {
                classNames: 'no-border-around-lists no-fieldset-title',
                'ui:layout:hideframe': true,   
                'ui:layout': [
                    {                        
                        is_single_parent: { className: 'col-md-4' },                        
                    },
                ],             
                holidays: {
                    'ui:widget': 'hidden',
                },
                enable_pause_button: {
                    'ui:widget': 'hidden',
                },
                disable_manual_log: {
                    'ui:widget': 'hidden',
                }, 
                is_single_parent: {
                    'ui:widget': 'Radio',
                },                               
                kids: {
                    classNames: 'form-chooser',
                    items: {
                        name: {
                            classNames: 'col-md-4',
                            'ui:placeholder': 'Name',
                        },
                        date: {
                            classNames: 'col-md-4',
                        },
                        // sex: {
                        //     classNames: 'col-md-3',
                        //     'ui:widget': 'Radio',
                        // },
                        chronic_disease: {
                            classNames: 'col-md-4',
                            'ui:widget': 'Radio',
                        },
                        // chronic_permission: {
                        //     classNames: 'col-md-3',
                        //     'ui:widget': 'Radio',
                        //     doShow: isFilledDisease('chronic_disease'),
                        // },
                    },
                    'ui:options': {
                        orderable: false,
                    },
                },                
                nomines: {
                    classNames: 'form-chooser',
                    items: {
                        name: {
                            classNames: 'col-md-3',
                            'ui:placeholder': 'Name',
                        },
                        // father: {
                        //     classNames: 'col-md-3',
                        //     'ui:placeholder': 'Father Name',
                        // },
                        // relation: {
                        //     classNames: 'col-md-3',
                        //     'ui:placeholder': 'Wife',
                        // },
                        contactNumber: {
                            classNames: 'col-md-3',
                            'ui:placeholder': '987-785-7865',
                        },
                    },
                    'ui:options': {
                        orderable: false,
                    },
                },
            },
        },
        {
            'ui:title': 'Profile Settings',
            'ui:layout:hideframe': true,
            id: 'tab2',
            'ui:field': 'layout',
            'ui:layout': [
                {
                    address: { md: 8 },
                    post_number: { md: 4 },
                    post_place: { md: 4 },
                    gps_data: { md: 4 },
                    home_payment_enabled: { md: 4 },
                },
                {
                    employee_percent: { md: 6 },
                    hourly_rate: { md: 6 },
                },
                { data: { md: 12 } },
            ],
            gps_data:{
                "ui:readonly":true
            },
            home_payment_enabled: {
                classNames: 'no-fieldset-title',
                'ui:widget': 'CheckBoxWidget',
            },
            data: {
                classNames: 'no-border-around-lists no-fieldset-title',
                'ui:layout:hideframe': true,
                is_single_parent: {
                    'ui:widget': 'hidden',
                },
                kids: {
                    'ui:widget': 'hidden',
                },
                nomines: {
                    'ui:widget': 'hidden',
                },                
                holidays: {
                    'ui:widget': 'DaysOfWeek',
                },
                enable_pause_button: {
                    classNames: 'no-fieldset-title',
                    'ui:widget': 'CheckBoxWidget',
                },
                disable_manual_log: {
                    classNames: 'no-fieldset-title',
                    'ui:widget': 'CheckBoxWidget',
                },                
            },
        },
    ],
};

@inject('userStore', 'commonStore', 'taskStore')
@withRouter
@withLocalization
@observer
class Profile extends Component {
    loadData() {
        const {
            userStore, id, add, mode
        } = this.props;
        userStore.load(userStore.currentUser.id, false);
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
        this.props.userStore.loading = true;
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    onSave(values) {
        const {
            userStore, history, add, t, commonStore
        } = this.props;
        return userStore.save(values, add).then(result => {
            if (!result.user || !result.user.id) {
                // some error
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            userStore.pullUser();
            commonStore.addNotification(t('Saved'), null, 'success');
            return true;
        });
    }

    validateUser(formData,errors){
        const {t} = this.props;
        //console.log("Checking form : ",formData,errors);
        if(formData.birthday && moment(formData.birthday).isSameOrAfter(moment())){                                   
            errors.birthday.addError(t("Please enter valid birthdate"));
        }         
        // if(formData.username == "" || ( formData.username && formData.username.trim() === '')){
        if(formData.username && formData.username.trim() === ''){
            errors.username.addError(t("is a required property"));
        }
        if(formData && formData.data && formData.data.kids && formData.data.kids.length){
            formData.data.kids.map((kid,index)=>{                
                if(kid.date && moment(kid.date).isSameOrAfter(moment())){
                    errors.data.kids[index].date.addError(t("Please enter valid birthdate"));                    
                }
            });       
        }
        return errors;
    }

    prepareSchema(schema, currentEntity) {
        schema['ui:tabs'][0].image = {
            'ui:widget': 'ImageUpload',
            'ui:imageContext': {
                model: 'User',
                fileType: 'logo',
                id: currentEntity ? currentEntity.id : 0,
            },
        };
        schema['ui:tabs'][0].attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'User',
                fileType: 'docs',
                id: currentEntity ? currentEntity.id : 0,
                test: '...',
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
            },
        };
        if (customerFeature('locked_mode')) {
            //  && mode != 'profile'
            schema['ui:tabs'][0]['ui:layout'][2].generic_pin = { className: 'col-md-6' };
        }

        return schema;
    }

    getBaseSchema() {
        const { userStore, add } = this.props;
        const { loading, currentEntity } = userStore;
        return getSchema({
            config: this.props.commonStore.config,
            isAdd: add,
            userType: add ? '' : currentEntity.user.user_type,
        });
    }

    getListUrl() {
        return '/admin/dashboard';
    }

    async recalcGps(e) {
        return this.props.taskStore.recalcGpsForTask(e, true);
    }

    render() {
        const { userStore, add } = this.props;
        const { loading, currentEntity } = userStore;
        if (loading) return <LoadingSpinner />;

        return (
            <div className="primary-page profile-edit-form">
                <GenericForm
                    entity={currentEntity.user}
                    uiSchema={this.prepareSchema(uiSchema, currentEntity.user)}
                    schema={this.getBaseSchema()}
                    translationScope="forms.user"
                    onSave={values => this.onSave(values)}
                    recomputeFields={['gps_data', 'data.addressCache']}
                    forceRefresh={!!currentEntity.user.gps_data}
                    onChange={async data => this.recalcGps(data)}
                    listUrl={this.getListUrl()}
                    isAdding={false}
                    customvalidate = {(formData,errors)=>this.validateUser(formData,errors)}
                />
            </div>
        );
    }
}

export default Profile;
