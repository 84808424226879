import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';

import withLocalization from '../../../hoc/withLocalization';
import { shortDateFormat } from '~/utils/dateFormat'

// Components
import Button from '../../../components/CustomButton/CustomButton.jsx';
import GenericList from '../../../components/GenericList/GenericList';
import moment from 'moment';
import DeviationFilter from './DeviationFilter';
import DeviationUrgencyMarker from './DeviationUrgencyMarker';
import { serverTimeFormat } from '../../../library/core';

@inject('clientStore', 'commonStore', 'deviationStore', 'userStore')
@withRouter
@withLocalization
@observer
class DeviationList extends Component {
    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        Object.keys(query).forEach((key) => {
            if (key === 'status' || key === 'reported_by_id' || key === 'assigned_id') {
                this.props.deviationStore.setFilter(key, query[key]);
            }
        });
        this.props.deviationStore.onFilter();
    }

    renderTableActionButtons = (id) => {
        const {
            userStore: { currentUser },
        } = this.props;

        const userType = currentUser.user_type;
        return (
            // <div className="actions-right">
            <div>
                <Button onClick={() => this.props.onDetails(id)} bsStyle="info" simple icon>
                    <i className="fa fa-edit" />
                </Button>
                {userType === 'admin' && (
                    <Button onClick={() => this.props.onDelete(id)} bsStyle="danger" simple icon>
                        <i className="fa fa-times" />
                    </Button>
                )}
            </div>
        );
    };

    renderUrgency(urgency) {
        const { config, t } = this.props;
        return <DeviationUrgencyMarker config={config} t={t} urgency={urgency} />;
    }

    render() {
        const { config, t, isUnionMode } = this.props;
        const { filters, appliedFilters, loading } = this.props.deviationStore;
        const {commonStore} = this.props;
        console.log('LOg:' , config)
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;
        return (
            <div className="table-list">
                <div className="table-list__header">
                    <Button fill wd icon onClick={() => this.props.onAdd()}>
                        {t('Add new')}
                        <i className="fa fa-plus" />
                    </Button>
                </div>
                <GenericList
                    columns={[
                        {
                            Header: 'ID',
                            id: 'id',
                            accessor: 'id',
                        },
                        {
                            Header: 'Project',
                            id: 'project_name',
                            accessor: 'project_name',
                        },
                        {
                            Header: 'Due date',
                            id: 'due_date',
                            accessor: ({ due_date }) => (
                                <span>{due_date ? shortDateFormat(due_date, dateTimeRules) : ''}</span>
                            ),
                            hidden: !!isUnionMode,
                        },
                        {
                            Header: 'Subject',
                            id: 'subject',
                            accessor: ({ urgency, subject, id }) => (
                                <span>
                                    {this.renderUrgency(urgency)}
                                    <NavLink to={`/admin/deviation/${id}`}>{subject}</NavLink>
                                </span>
                            ),
                        },
                        {
                            Header: 'Responsible',
                            id: 'assigned_name',
                            accessor: 'assigned_name',
                            hidden: !!isUnionMode,
                        },
                        {
                            Header: 'Reported by',
                            id: 'reporter_name',
                            accessor: 'reporter_name',
                            hidden: !!isUnionMode,
                        },
                        {
                            Header: 'Status',
                            id: 'status',
                            hidden: !!isUnionMode,
                            accessor: ({ status }) => <span>{config.names[status]}</span>,
                        },
                        {
                            Header: 'Actions',
                            id: 'actions',
                            sortable: false,
                            accessor: ({ id }) => this.renderTableActionButtons(id),
                        },
                    ]}
                    filters={appliedFilters}
                    forceReload={this.props.deviationStore.deleteSuccess}
                    lastListLoadTime={this.props.deviationStore.lastListLoadTime}
                    header={
                        <DeviationFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.deviationStore.setFilter(name, value)}
                            onFilter={() => this.props.deviationStore.onFilter()}
                            loading={loading}
                            isUnionMode={this.props.isUnionMode}
                            statuses={config.names}
                        />
                    }
                    requestData={(params) => this.props.deviationStore.loadList(params)}
                />
            </div>
        );
    }
}
export default DeviationList;
