import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

//components
import Button from '../../../components/CustomButton/CustomButton.jsx';
import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import getSchema from '~/library/schemas/biztype';
import BizTypeDocuments from './BizTypeDocuments';

import { Modal } from 'react-bootstrap';

const schema = getSchema();

let reports_weekly;
const uiSchema = {
    'ui:field': 'layout',
    'ui:layout': [
        {
            name: { md: 12 },
            data: { md: 12 },
        },
    ],
    data: {
        allow_vacations: {
            'ui:widget': 'TrueFalse',
        },
        allow_absents: { 'ui:widget': 'TrueFalse' },
        allow_project_users: { 'ui:widget': 'TrueFalse' },
        allow_accepting: { 'ui:widget': 'TrueFalse' },
        pm_hide_tasks: { 'ui:widget': 'TrueFalse' },
        pm_hide_vacations: { 'ui:widget': 'TrueFalse' },
        pm_hide_absences: { 'ui:widget': 'TrueFalse' },
        pm_no_register: { 'ui:widget': 'TrueFalse' },
        member_allow_see_projects: { 'ui:widget': 'TrueFalse' },
        timelog_hide_kilometers: { 'ui:widget': 'TrueFalse' },
        timelog_hide_other: { 'ui:widget': 'TrueFalse' },
        timelog_hide_extra: { 'ui:widget': 'TrueFalse' },
        show_times_on_project: { 'ui:widget': 'TrueFalse' },
        hide_send_report: { 'ui:widget': 'TrueFalse' },
        presence_view: { 'ui:widget': 'TrueFalse' },
        admin_hide_absences: { 'ui:widget': 'TrueFalse' },
        allow_vacation_not_me: { 'ui:widget': 'TrueFalse' },
        reports_not_for_me: { 'ui:widget': 'TrueFalse' },
        reports_weekly: { 'ui:widget': 'TrueFalse' },
        locked_mode: { 'ui:widget': 'TrueFalse' },
        rest_report: { 'ui:widget': 'TrueFalse' },
        pm_allow_editing_all: { 'ui:widget': 'TrueFalse' },
        login_as_allowed: { 'ui:widget': 'TrueFalse' },
        disallow_absences_vacations_not_self: { 'ui:widget': 'TrueFalse' },
        timelog_hide_names: { 'ui:widget': 'TrueFalse' },
        gps_enabled: { 'ui:widget': 'TrueFalse' },
        timelog_no_edit_for_approved: { 'ui:widget': 'TrueFalse' },
        timelog_no_edit_for_done: { 'ui:widget': 'TrueFalse' },
        pm_no_shifts: { 'ui:widget': 'TrueFalse' },
        enable_deviations: { 'ui:widget': 'TrueFalse' },
        union_group: { 'ui:widget': 'TrueFalse' },
        allow_system_document: { 'ui:widget': 'TrueFalse' },
    },
};

@inject('biztypeStore', 'commonStore')
@withRouter
@withLocalization
@observer
class BizTypesForm extends Component {

    state = {
        showDocumentModel: false,
        isLoad:false,
        bizId:null,
    }

    getListUrl() {
        return '/admin/biztypes';
    }

    loadData() {
        const {
            biztypeStore, id, add, mode
        } = this.props;
        if (add) return biztypeStore.returnDefaultNew();
        biztypeStore.load(id, add);
        //console.log("bizID : " + id);
        this.setState({bizId:id},()=>{
            this.setState({
                isLoad: true
            })
        })
    }

    componentWillMount() {
        this.props.biztypeStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    onSave(values) {                
        const {
            biztypeStore, history, add, t, commonStore
        } = this.props;
        if(!values.name || values.name.trim() === ""){
            commonStore.addNotification(t('Please Enter Business Name'), null, 'error');
            return;
        }
        return biztypeStore.save(values, add).then(result => {
            if (!result.biztype || !result.biztype.id) {
                // some error
                // console.log(result);
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');
            history.push(this.getListUrl());
            return true;
        });
    }

    handleDocument = () => {
        // console.log("handleDocument");
        this.setState({ showDocumentModel: true });
    }

    handleCloseDocumentModal = () => {
        this.setState({ showDocumentModel: false });
    };

    render() {
        const { biztypeStore, add, t } = this.props;
        const { loading, currentEntity } = biztypeStore;
        if (loading) return <LoadingSpinner />;

        return (
            <div className="primary-page">
                <GenericForm
                    entity={currentEntity.biztype}
                    uiSchema={uiSchema}
                    schema={schema}
                    translationScope="forms.biztype"
                    onSave={values => this.onSave(values)}
                    listUrl={this.getListUrl()}
                    isAdding={add}
                    isBizType={true && this.state.isLoad}//bizType documents can only be added after creating the business first//
                    handleDocument={this.handleDocument}                    
                />
                {/* <Button fill wd icon onClick={() => console.log("called")}>
                    {t('Select Docuemnts')}
                </Button> */}
                <Modal
                    className="modal fade"
                    show={this.state.showDocumentModel}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Document Management')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {this.state.isLoad &&
                        <BizTypeDocuments
                        biztypeId = {this.state.bizId}
                        />}
                        {/* TODO
                            Add compoent for document ids
                         */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseDocumentModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default BizTypesForm;
