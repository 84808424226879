import React from 'react';
import { observer } from 'mobx-react';

const MAX_ROWS_TO_SHOW = 10;

@observer
export default class ConfirmLaunch extends React.Component {
    render() {
        const {
            start, sent, filteredRows, resetCampaign, campaign
        } = this.props;

        const { csvData, mapping } = campaign;

        const filteredRowsTop10 = filteredRows.length <= MAX_ROWS_TO_SHOW ? filteredRows : filteredRows.slice(0, MAX_ROWS_TO_SHOW);

        return (
            <div>
                <h2>Moment of glory...</h2>

                {sent && <div style={{ textAlign: 'center' }}>Successfully started</div>}
                {!sent && (
                    <div>
                        {campaign.warnings.map(warning => (
                            <div style={{ color: 'red' }}>{warning}</div>
                        ))}

                        <h3>Approximate data</h3>
                        <table className="table">
                            <tbody>
                                <tr>
                                    {csvData.cols.map((row, index) => (
                                        <th key={`head_col_${index}`}>{mapping[index] || '(skip)'}</th>
                                    ))}
                                </tr>
                                {filteredRowsTop10.map((row, index) => (
                                    <tr key={`body_row${index}`}>
                                        {row.map((col, index) => (
                                            <td key={`body_col_${index}`}>{col}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div style={{ textAlign: 'center' }}>
                            <b>Rows to be added: {filteredRows.length}</b>
                            <br />
                            <a className="btn btn-primary flex-items-lg-center" onClick={() => start()}>
                                Launch
                            </a>
                        </div>
                    </div>
                )}
                <br />
                <br />
                <div style={{ textAlign: 'center' }}>
                    <a className="btn btn-danger flex-items-lg-center" onClick={() => resetCampaign()}>
                        Reset and start over
                    </a>
                </div>
            </div>
        );
    }
}
