import React from 'react';
import {
    Accordion as DefaultAccordion,
    AccordionItem as DefaultAccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

export const Accordion = ({ children, ...rest }) => {
    return (
        <DefaultAccordion {...rest} allowMultipleExpanded>
            {children}
        </DefaultAccordion>
    );
};

export const AccordionItem = ({ title, uuid, children }) => (
    <DefaultAccordionItem uuid={uuid}>
        <AccordionItemHeading>
            <AccordionItemButton>
                {title}
                <div className="accordion__icon">
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.50014 6.42857C6.31984 6.42857 6.14027 6.36366 6.00269 6.23348L0.634788 1.13955C0.360019 0.878493 0.360019 0.456165 0.634788 0.195792C0.909557 -0.0652641 1.3546 -0.0652641 1.6293 0.195792L6.50014 4.81766L11.371 0.195792C11.6457 -0.0652641 12.0908 -0.0652641 12.3655 0.195792C12.6403 0.456538 12.6403 0.878493 12.3655 1.13955L6.99727 6.23348C6.86008 6.36366 6.68011 6.42857 6.50014 6.42857Z"
                            fill="#373737"
                        />
                    </svg>
                </div>
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>{children}</AccordionItemPanel>
    </DefaultAccordionItem>
);
