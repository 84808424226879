import _ from 'lodash';

export const schema = {
    title: 'User',
    type: 'object',
    // required: ['username', 'email', 'first_name', 'last_name'],
    required: ['email', 'first_name', 'last_name'],
    properties: {
        id: { type: 'integer' },
        status: {
            type: 'string',
            title: 'Active',
            default: 'active',
        },

        image: {
            type: ['string', 'null'],
            title: 'Photo',
        },
        username: {
            title: 'Username',
            type: 'string',
            // isRequired: true,
        },
        password: {
            title: 'Password',
            type: 'string',
        },
        user_type: {
            type: 'string',
            title: 'User type',
        },
        allow_tip: {
            type: 'boolean',
            title: 'Allow Tips',
            default: false,
            anyOf: [
                {
                    title: 'Yes',
                    enum: [true],
                },
                {
                    title: 'No',
                    enum: [false],
                },
            ],
        },
        first_name: {
            title: 'First name',
            type: 'string',
        },
        last_name: {
            title: 'Last name',
            type: 'string',
        },
        email: {
            title: 'Email',
            type: 'string',
            format: 'email',
        },
        internal_number: { type: ['number', 'null'], title: 'Internal number' },
        generic_pin: { type: ['number', 'null'], title: 'PIN' },
        employee_percent: { type: ['string', 'null'], title: 'Employee percent', default: '100' },
        address: { type: ['string', 'null'], title: 'Address' },
        post_number: { type: ['string', 'null'], title: 'Post no', format: 'postnumber' },
        post_place: { type: ['string', 'null'], title: 'Post place' },
        // title: { type: ['string', 'null'], title: 'Title' },
        full_name: { type: ['string', 'null'], title: 'Full name' },
        phone: { type: ['string', 'null'], title: 'Phone', format: 'phone-no' },
        g_nr: { type: ['string', 'null'], title: 'G.Nr' },
        b_nr: { type: ['string', 'null'], title: 'B.Nr' },
        description: { type: ['string', 'null'], title: 'Description' },
        timelog_start_from: { type: ['string', 'null'], format: 'date', title: 'Start date' },
        timelog_last_filled: { type: ['string', 'null'], format: 'date', title: 'Work logs last filled' },
        birthday: { type: ['string', 'null'], format: 'date', title: 'Date of birth' },
        social_number: { type: ['string', 'null'], format: 'social-no', title: 'Date of birth no.' },
        attachments: { type: ['string', 'null'], title: 'Attachments' },
        vacation_days: { type: "number", title: "Vacation Days" },
        hourly_rate: { type: ['number', 'null'], title: 'Hourly/Fixed Rate' },
        payment_mode: { type: "string", title: "Select payment mode" },
        rate_monthly: { type: ['number', 'null'], title: 'Monthly rate' },
        disable_autolog: {
            type: ['boolean', 'null'],
            // title: 'Enable auto-log',
            title: 'Disable auto-log',
            default: true,
        },
        home_payment_enabled: {
            type: ['boolean', 'null'],
            title: 'I am paid to come to work place',
            default: false,
        },
        gps_data: { type: ['string', 'null'], title: 'GPS location', format: 'gps' },
        data: {
            type: 'object',
            properties: {
                holidays: {
                    type: 'string',
                    title: 'Default holidays',
                },
                kids: {
                    type: 'array',
                    title: 'Info About Kids',
                    default: [],
                    items: {
                        type: 'object',
                        required: ['name', 'date'],
                        properties: {
                            name: {
                                title: 'Name',
                                type: 'string',
                            },
                            date: {
                                title: 'Date of birth',
                                type: 'string',
                                format: 'date',
                            },
                            // sex: {
                            //     title: 'Sex',
                            //     type: ['string', 'null'],
                            //     anyOf: [
                            //         {
                            //             title: 'Boy',
                            //             enum: ['boy'],
                            //         },
                            //         {
                            //             title: 'Girl',
                            //             enum: ['girl'],
                            //         },
                            //     ],
                            //     default: 'boy',
                            // },
                            chronic_disease: {
                                type: 'string',
                                title: 'Has chronic deasease?',
                                anyOf: [
                                    {
                                        title: 'No',
                                        enum: ['no'],
                                    },
                                    {
                                        title: 'Yes',
                                        enum: ['yes'],
                                    },
                                ],
                                default: 'no',
                            },
                            // chronic_permission: {
                            //     type: 'string',
                            //     title: 'Permission for 20 days absence?',
                            //     anyOf: [
                            //         {
                            //             title: 'No',
                            //             enum: ['no'],
                            //         },
                            //         {
                            //             title: 'Yes',
                            //             enum: ['yes'],
                            //         },
                            //     ],
                            //     default: 'no',
                            // },
                        },
                    },
                },
                is_single_parent: {
                    type: 'boolean',
                    title: 'Are you a single parent',
                    default: false,
                },
                nomines: {
                    type: 'array',
                    title: 'Nomines',
                    default: [],
                    items: {
                        type: 'object',
                        // required: ['name', 'father', 'relation', 'contactNumber'],
                        required: ['name', 'contactNumber'],

                        properties: {
                            name: {
                                title: 'Name',
                                type: 'string',
                                format: 'required-name'
                            },
                            // father: {
                            //     title: 'Fathers Name',
                            //     type: 'string',
                            // },
                            // relation: {
                            //     title: 'Relation',
                            //     type: 'string',
                            // },
                            contactNumber: {
                                title: 'Contact Number',
                                type: 'string',
                                format: 'phone-no',
                            },
                        },
                    },
                },
                enable_pause_button: { type: 'boolean', title: 'Enable tracked pause', default: false },
                disable_manual_log: { type: 'boolean', title: 'Hide manual time tracking', default: false },
            },
        },
        MemberInProjects: {
            type: 'string',
            title: 'Member of projects',
        },
        // AssignedDocuments:{
        //     type: 'string',
        //     title: 'Assigned Documents',
        // },
        ManagesUsers: {
            type: 'array',
            title: 'Manages',
            default: [],
            items: {
                type: 'object',
                required: ['id'],
                properties: {
                    id: {
                        title: 'Member',
                        type: 'integer',
                    },
                },
            },
        },
    },
    dependencies: {
        allow_tip: {
            oneOf: [
                {
                    properties: {
                        allow_tip: {
                            enum: [
                                false
                            ]
                        },
                    },
                },
                {
                    required: ["employee_type"],
                    properties: {
                        allow_tip: {
                            enum: [
                                true
                            ]
                        },
                        employee_type: {
                            type: 'string',
                            title: 'Employee type',
                        }
                    }
                }

            ]
        },
    }
};

export const getSchema = props => {
    if (!props) return schema;
    const { config, mode, userType } = props;


    if (!config) return schema;
    const _schema = _.cloneDeep(schema);
    if (userType == 'admin') {
        _schema.properties.user_type = {
            type: 'string',
            title: 'User type',
            anyOf: [
                {
                    title: 'Admin',
                    enum: ['admin'],
                },
                {
                    title: 'PM',
                    enum: ['pm'],
                },
                {
                    title: 'Member',
                    enum: ['member'],
                },
            ],
        };
    }
    else{
        _schema.properties.user_type = {
            type: 'string',
            title: 'User type',
            anyOf: [
                {
                    title: 'PM',
                    enum: ['pm'],
                },
                {
                    title: 'Member',
                    enum: ['member'],
                },
            ],
        };
    }
    _schema.properties.payment_mode = {
        type: 'string',
        title: 'Select payment mode',
        default: 'hourly',
        anyOf: [
            {
                title: "Hourly",
                enum: ["hourly"]
            },
            {
                title: "Fixed",
                enum: ["fixed"]
            }
        ],
    };
    if (mode === 'superadmins') {
        _schema.properties.user_type.anyOf.unshift({ title: 'Super-admin', enum: ['super-admin'] });
    }
    if (userType == 'super-admin') {
        delete _schema.properties.user_type;
    }
    if (!props.isAdd) {
        //    _schema.properties.email.readOnly = true;
    } else {
        _schema.properties.email.readOnly = false;
    }
    if (config && config.client && config.client.data && config.client.data.user_types) {
        const ePercents = config.client.data.user_types.map(u => ({
            title: u.name,
            enum: [u.code],
        }));

        _schema.properties.employee_percent = {
            type: 'string',
            title: 'Employee percent',
            default: '100',
            anyOf: ePercents,
        };
    }
    if (userType === 'member') {
        delete _schema.properties.allow_tip;
    }
    if (config.client.data.tipRules && config.client.data.tipRules.profiles.length) {
        const profiles = config.client.data.tipRules.profiles.map((prof) => {
            return {
                title: prof.employee_type,
                enum: [prof.employee_type]
            }
        });

        _schema.dependencies.allow_tip.oneOf[1].properties.employee_type = {
            type: 'string',
            title: 'Employee type',
            anyOf: profiles

        }
    }

    return _schema;
};

export default getSchema;
