const statuses = [
    { code: 'active', name: 'Pending' },
    { code: 'auto', name: 'Auto' },
    { code: 'approved', name: 'approved' },
    { code: 'rejected', name: 'rejected' },
    { code: 'draft', name: 'At work' },
];

export const statusMap = {
    active: 'Pending',
    auto: 'Auto',
    approved: 'approved',
    rejected: 'rejected',
    draft: 'At work',
};

export default statuses;
