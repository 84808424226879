import VisitorDashboard from './containers/Visitor/Dashboard';
import VisitorAdd from './containers/Admin/Visitors/VisitorAdd';

const visitorRoutes = [

    // {
    //     path: '/',
    //     layout: '/admin',
    //     name: 'Visitors',
    //     icon: 'pe-7s-users',
    //     component: VisitorDashboard,
    //     order: 0.5,
    //     allowed: () => true,
    // },
    {
        path: '/',
        layout: '/admin',
        icon: 'pe-7s-users',
        name: 'Visitor Register',
        component: VisitorAdd,
        order: 0.5,
        allowed: user => {
            if (user.user_type === 'super-admin') return false;
            if (user.user_type === 'member') return false;
            // if (!customerFeature('enable_deviations')) return false;
            // if (user.user_type === 'admin' && customerFeature('union_group')) return false;
            return true;
        }
    }
];
export default visitorRoutes;
