import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import moment from 'moment/min/moment-with-locales';

import AcceptFormBlock from '../Timelogs/AcceptFormBlock';

import AbsencesTotals from './AbsencesTotals';

import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import getSchema from '~/library/schemas/absence';
import customerFeature from '~/utils/customerFeature';

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:layout': [
        {
            absence_type: { md: 4 },
            // 'status':{md:4},
            child: { md: 4, doShow: ({ formData }) => formData.absence_type === 'child' },
            leave_type: { md: 4 },            
            employement_grade: { md: 4},
        },
        {
            from: { md: 4 },
            to: { md: 4 },
            hours: { md: 2},
            total_hours: { md: 2},
        },
        {
            total_days: {
                md: 4,
            },
            absence_payment_per_day: { md: 4 },
            absence_total_payment:{md:4}
           
            // 'status':{md:4},
        },
        {
            comments: { md: 12 },
        },
        {
            attachments: { md: 12 },
        },
    ],
    absence_type: {
        'ui:widget': 'AbsenceStatuses',
    },
    leave_type: {
        'ui:widget': 'LeaveTypeWidget',
    },
    employement_grade:{
        'ui:widget': 'range',
    },
    child: {
        'ui:widget': 'KidsSelect',
    },
    attachments: {},
    user_id: {
        'ui:widget': 'AllUsersWidget',
    },
};

@inject('absenceStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class AbsencesForm extends Component {
    state = {
        schema: getSchema(),
        uiSchema: defaultUiSchema,
    };

    getListUrl() {
        return '/admin/absences';
    }

    async handleChangeStatus(action) {
        this.setState({defaultHolidays : this.props.commonStore.config.client.data.basicRules.defaultHolidays || "5,6"},()=>{
            this.loadData();
        })
    }

    async loadData() {     
        const {
            absenceStore, id, add, mode, location
        } = this.props;
        const { currentEntity } = absenceStore;

        this.setState({
            schema: getSchema({
                config: this.props.commonStore.config,
            }),
        });

        if (add) {
            this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, null) });
            return absenceStore.returnDefaultNew({ location });
        }
        const absence = await absenceStore.load(id, add);
        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, absence.absence) });
    }

    prepareSchema(_schema, currentEntity) {
        const schema = cloneDeep(_schema);
        const { userStore } = this.props;
        const { currentUser } = userStore;

        schema.attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'Absence',
                fileType: 'docs',
                id: currentEntity && currentEntity.id !== 'add' ? currentEntity.id : 0,
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
            },
        };
        const showUser = (currentUser.user_type == 'admin' || currentUser.user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');

        if (showUser) {
            schema['ui:layout'][0].user_id = { md: 4 };
        }

        if (currentUser.user_type === 'member') {
            if (schema.user_id) schema.user_id.classNames = 'hidden';
        }

        if(currentEntity && currentEntity.absence_type){  
            schema.absence_type['ui:widget'] = 'AbsenceStatusesOnEdit';
            schema.leave_type['ui:widget'] = 'LeaveTypeWidgetOnEdit';            
        }
        

        return schema;
    }

    componentWillMount() {
        this.props.absenceStore.loading = true;
        
        // console.log("load data from absent form",this.props.absenceStore.currentEntity)
        // alert("yes")
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    onSave(values) {
        const {
            absenceStore, history, add, t, commonStore
        } = this.props;
        const { userStore } = this.props;
        const { currentUser } = userStore;        

        if (!values.user_id) {
            values.user_id = currentUser.id;
        }
        const allow = (currentUser.user_type == 'admin' || currentUser.user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');

        if (currentUser.id != values.user_id && !allow) {
            commonStore.addNotification(t('Can not save on behalf of others'), null, 'error');
            return false;
        }

        // if (values.absence_type == 'self' && values.leave_type == 'paid' && moment(values.to).isAfter(moment().add(3,'days')) ){
        //     commonStore.addNotification(t('Cannot apply for future sick leave. Only up to 3 future days are valid.'), null, 'error');
        //     return false;
        // }

        // if (values.absence_type == 'self' && values.leave_type == 'paid' && moment(values.from).isBefore(moment().add(-1,'year')) ){
        //     commonStore.addNotification(t('Cannot apply for sick leave older than 1 year.'), null, 'error');
        //     return false;
        // }

        values.status = 'active';
        return absenceStore.save(values, add).then(result => {
            if (!result.absence || !result.absence.id) {
                // some error
                // console.log(result);
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');
            this.getBack();
            //history.push(this.getListUrl());
            return true;
        });
    }

    validateAbsence(formData,errors){
        const {t} = this.props;
        // console.log("Checking form : ",formData,errors);
        if(formData.to && formData.from && moment(formData.from).isAfter(moment(formData.to))){
            errors.from.addError(t("From date should be before to date"));            
        }
        if(formData.absence_type && formData.leave_type && formData.absence_type == 'self' && formData.leave_type == 'paid' && formData.total_days > 3){            
            errors.to.addError(t("Self-clarification is max 3 days"));            
        }
        if (formData.absence_type && formData.to && formData.leave_type && formData.absence_type == 'self' && formData.leave_type == 'paid' && moment(formData.to).isAfter(moment().add(3,'days')) ){
            errors.to.addError(t("Only up to 3 future days are valid."));            
        }
        if (formData.absence_type && formData.from && formData.leave_type && formData.absence_type == 'self' && formData.leave_type == 'paid' && moment(formData.from).isBefore(moment().add(-1,'year').add(2,'days')) ){
            errors.from.addError(t("Cannot apply for sick leave older than 1 year."));            
        }                        
        return errors;
    }

    getBack(){        
        this.props.goBack();
    }

    render() {
        const { absenceStore, add } = this.props;
        const { loading, currentEntity, recalc } = absenceStore;
        const { schema, uiSchema } = this.state;
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const showStatus = (user_type === 'pm' || user_type === 'admin') && customerFeature('allow_accepting') && !add;
        let allow = (user_type == 'admin' || user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');
        allow = !add && currentEntity && currentEntity.absence && currentEntity.absence.user_id != userId && !allow;

        if (currentEntity && currentEntity.absence && currentEntity.absence.status == 'approved') allow = true;
if(currentEntity && currentEntity.absence && currentEntity.absence.absence_payment_per_day > 0){
currentEntity.absence.absence_total_payment=currentEntity.absence.absence_payment_per_day * currentEntity.absence.total_days;
}
        if (loading) return <LoadingSpinner />;

        return (
            <div className="primary-page">
                <AbsencesTotals user={currentEntity.absence.user_id} />
              
                <GenericForm
                    entity={currentEntity.absence}
                    uiSchema={uiSchema}
                    schema={schema}
                    translationScope="forms.absence"
                    onChange={async data => absenceStore.recalc(data)}
                    onSave={values => this.onSave(values)}
                    listUrl={this.getListUrl()}
                    isAdding={add}
                    disallowSave={allow}
                    customvalidate = {(formData,errors)=>this.validateAbsence(formData,errors)}
                    goBack = {() => this.getBack()}
                />
                {showStatus && (
                    <AcceptFormBlock
                        params={{ id: this.props.id, status: currentEntity.absence.status,data:currentEntity, entity: 'absence' }}
                        afterSave={() => this.handleChangeStatus()}
                    />
                )}
            </div>
        );
    }
}

export default AbsencesForm;
