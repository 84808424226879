import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';

import LGImages from '../../assets/img/lg/index';
import PrimaryLayout from '../../layouts/PrimaryLayout';
import withLocalization from '../../hoc/withLocalization';

@inject('authStore', 'commonStore')
@withLocalization
@withRouter
@observer
class ExtraPage extends React.Component {
    refsForScroll = {};

    setScrollRef = (ref, name) => {
        this.refsForScroll[name] = ref;
    };

    render() {
        return (
            <PrimaryLayout {...this.props} refsForScroll={this.refsForScroll}>
                <div className="lp-main lp-main_black" ref={section => this.setScrollRef(section, 'home')}>
                    <div className="lp-main__wrapper">
                        <div className="lp-main__extra-content">{this.props.children}</div>
                        <div style={{ marginBottom: '200px', color: 'white' }} />
                    </div>
                </div>
                <div className="lp-footer">
                    <h3>{this.props.t('Get in touch!')}</h3>
                    <div className="lp-footer__main">
                        <div className="lp-footer__main_row">
                            <a href="https://goo.gl/maps/UEuVQFUnfdLzVA6n7" target="_blank">
                                <img src={LGImages.lg_icon_loc} alt="Location" /> Rabekkgata 9, 1523, Moss, Norway
                            </a>
                        </div>
                        <div className="lp-footer__main_row">
                            <a href="mailto:info@rainmakersinnovation.no">
                                <img src={LGImages.lg_icon_mail} alt="Email" />
                                support@tidogkontroll.no
                            </a>
                            <a href="skype:appsbusinesstore" style={{ paddingLeft: '15px' }}>
                                <img src={LGImages.lg_icon_phone} alt="Skype" />
                                appsbusinesstore
                            </a>
                        </div>
                    </div>

                    <div className="lp-footer__bottom" style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.17)' }}>
                        <div className="lp-footer__bottom-wrapper">
                            <a href="/" className="lp-footer__bottom-logo">
                                <img src={LGImages.lg_logo} alt="LOGO" />
                            </a>
                            <div className="lp-footer__bottom-texts">
                                <span>{this.props.t('A Product of Rainmakers Innovation as')}</span>
                                <span>{this.props.t('Copyrights 2019 - All Rights Reserved')}</span>
                            </div>
                            {/* <div className="lp-footer__bottom-social">
                <a href="#"><img src={LGImages['lg_icon_fb']} alt="Facebook"/></a>
                <a href="#"><img src={LGImages['lg_icon_tw']} alt="Twitter"/></a>
                <a href="#"><img src={LGImages['lg_icon_yt']} alt="YouTube"/></a>
              </div> */}
                        </div>
                    </div>

                    <div className="lp-footer__main" style={{ paddingBottom: '20px' }}>
                        <div style={{ color: 'white' }}>
                            <a href="/privacy" style={{ fontSize: '8px' }}>{this.props.t('Privacy Policy')}</a>
                            <span style={{ fontSize: '8px' }}> |</span>
                            <a href="/docs/databehandleravtale.pdf" style={{ fontSize: '8px' }}>{this.props.t('GDPR')}</a>
                            <span style={{ fontSize: '8px' }}> |</span>
                            <a href="/terms" style={{ fontSize: '8px' }}>{this.props.t('Terms of usage')}</a>
                        </div>
                    </div>

                </div>
            </PrimaryLayout>
        );
    }
}

export class GDPRPage extends React.Component {
    render() {
        return (
            <ExtraPage>
                <h1>GDPR</h1>
            </ExtraPage>
        );
    }
}

export class PrivacyPage extends React.Component {
    render() {
        return (
            <ExtraPage>
                <h1>Personvernerklæring</h1>
                <h2>Personvern, behandling av personopplysninger fra nett. </h2>
                <p>
                    Denne personvernerklæringen gjelder for Tid & kontroll as as her av norsktimeregister sine
                    nettsteder. Norsktimeregister (org. no. 920 566 650) er behandlingsansvarlig for personopplysninger
                    som samles inn på nettsidene: norsktimeregister.no. Vår app brukes av arbeidsgivere og deres
                    ansatte, fagforeninger og deres medlemmer.
                </p>
                <h2>Personvern, behandling av personopplysninger fra nett. </h2>
                <p>
                    Norsktimeregister.no samler inn personopplysninger som fødselsdato- og personnummer, eller
                    opplysninger om personlige betalingskort.
                </p>
                <ul>
                    <li>
                        <strong>Eksempler på informasjon som innhentes:</strong>
                    </li>
                    <li>Navn</li>
                    <li>Epost</li>
                    <li>Mobilnummer</li>
                    <li>Brukernavn</li>
                    <li>Fødselsnummer</li>
                    <li>Adresse</li>
                    <li>Postnummer</li>
                    <li>Poststed</li>
                    <li>Beskrivelse</li>
                    <li>Navn og telefonnummer på påførende</li>
                    <li>Navn til barn og fødselsdato</li>
                    <li>Timelister</li>
                    <li>Arbeidsoppgaver</li>
                    <li>Disponible feriedager</li>
                    <li>Fravær (egenmeldinger og sykt barn)</li>
                    <li>GPS koordinater (innhentes GPS posisjoner automatisk)</li>
                    <li>GPS løper også bakgrunnen til mobilapp</li>
                    <li>Medlem av appen kan laste opp sin kontrakt</li>
                    <li>Medlem av appen kan ta bilde av utført arbeid</li>
                </ul>
                <p>
                    Informasjon om din bruk av nettsiden, i form av blant annet hvilke undersider som besøkes,
                    nettleserinstillinger og IP-adresse
                </p>
                <h2>Hvordan informasjon innhentes?</h2>
                <p>
                    Norstimeregister samler inn opplysninger gjennom skjemaer på våre nettsider og vår mobilapp. Det er
                    frivillig å oppgi denne informasjonen. Hvis du velger å ikke oppgi personopplysningene, kan vi være
                    forhindret fra å gi deg tilgang til produktet eller tjenesten. Vi ber deg å kontakte din nærmeste
                    leder, er du medlem av fagforening, ber deg å kontakte fagforening eller nærmeste tillitsmann på
                    arbeidsplassen din.
                </p>
                <h2>Eksempel på skjema:</h2>
                <ul>
                    <li>Kontaktskjema</li>
                    <li>Når man ønsker å prøve et produkt eller tjeneste</li>
                    <li>Ved kjøp av produkter eller tjenester på våre nettsider</li>
                    <li>Ved påmelding til webinar, kurs og konferanser</li>
                    <li>Når man laster ned dokumenter som e-bøker fra våre nettsider</li>
                    <li>Ved påmelding til å motta vårt nyhetsbrev eller blogg på e-post</li>
                </ul>
                <h2>Formålet med informasjonen som innhentes</h2>
                <ul>
                    <li>For å kunne gi tilgang til våre produkter og tjenester</li>
                    <li>For å kunne sende relevant informasjon</li>
                    <li>For å kunne ta kontakt og tilby våre produkter og tjenester</li>
                </ul>
                <p>
                    Vi oppretter profiler på bakgrunnen av informasjonen som innhentes, for å kunne tilby deg de mest
                    relevante produktene og yte god kundeservice. Profilene baseres kun på informasjon som du frivillig
                    gir oss, vi henter ikke inn informasjon for dette formålet fra tredjeparter.
                </p>
                <h2>Hvordan lagres informasjonen?</h2>
                <p>
                    Informasjon innhentet gjennom norsktimeregister.no, mobilapp(både ios og android) og sendes pr epost
                    til oss og denne informasjonen går direkte inn i norsktimeregister applikasjonen, vår
                    markedsavdeling eller selger tar kontakt pr epost eller pr telefon for å tilby vårt produkt.
                </p>
                <h2>Hvordan slettes opplysningene?</h2>
                <p>Informasjon slettes på oppfordring ved å kontakte vårt kundesenter eller pr epost.</p>
                <h2>Utlevering av informasjon til tredjepart?</h2>
                <p>
                    Personopplysningene deles ikke med utenforstående tredjeparter, men norsktimeregister kan bruke
                    underleverandører til å levere hele eller deler av nettsiden. Slike underleverandører kan behandle
                    personopplysninger på vegne av norsktimeregister. Norsktimeregister kan bruke denne informasjonen
                    til andre produkter.{' '}
                </p>
                <h2>Rettigheter</h2>
                <p>
                    Som bruker av nettsidene våre har du rett til å kreve innsyn i personopplysningene som vi behandler
                    om deg og hvordan de behandles. Du kan også kreve retting, sletting og begrensninger i behandlingen
                    av personopplysninger i henhold til personopplysningsloven.
                </p>
                <p>
                    Hvor behandlingen av personopplysninger er basert på samtykke, kan du til enhver tid trekke
                    samtykket tilbake.
                </p>
                <p>
                    Dersom du mener at waitlist ikke har overholdt dine rettigheter i henhold til
                    personopplysningsloven, har du rett til å klage til den aktuelle tilsynsmyndigheten. Dette gjøres
                    ved å sende klage til Datatilsynet, kontaktinformasjon er tilgjengelig på{' '}
                    <a href="www.datatilsynet.no." target="_blank">
                        www.datatilsynet.no.
                    </a>
                </p>
                <h2>Kontaktinformasjon</h2>
                <ul>
                    <li>
                        Telefon:{' '}
                        <a
                            href="tel:94004888
"
                        >
                            94 00 48 88
                        </a>
                    </li>
                    <li>
                        <a href="mailto:admin@tidogkontroll.no">admin@tidogkontroll.no</a>
                    </li>
                </ul>
            </ExtraPage>
        );
    }
}
export class TermsPage extends React.Component {
    render() {
        return (
            <ExtraPage>
                <h1>Brukervilkår</h1>
                <br></br>
                <p>1 Definisjoner</p>
                <p>1.1. Tid og kontroll As, org.nr. 923 773 533, betegnes heretter som norsktimeregister.no(Kalt NTR).</p>
                <p>1.2. Fysisk eller juridisk person som tegner avtale med NTR betegnes heretter som Kunden.</p>
                <p>2 Inngåelse av avtale</p>
                <p>2.1. Avtalen anses inngått når Kunden har signert på kontrakt med NTR. Har ikke kunden signert på avtalen, avtalen bekrefter sin avtale tiltredelse ved å logge inn med mottatt brukernavn og passord på “websiden”. Kunden har 24 måneders binding ut og kan ikke sies opp før hele bindingstiden har gått ut.</p>
                <p>2.2. Leveransen er gjennomført og levert når Kunden har mottatt brukernavn og passord til tjenesten.</p>
                <p>2.3. Gratis support på bruk av løsningen/programvaren NTR inngår i avtalen. Support kan skje pr epost svartid innen 24 timer og skype(fra klokka 0900-1600) . En valgt person kan også ringe direkte på et tildelt mobilnummer.</p>
                <p>3 Kundens rettigheter og plikter</p>
                <p>3.1. Ved tegning av avtale får Kunden tilgang til produkter og tjenester som benyttes via internett og mobilapplikasjon både for iPhone og Android telefoner, prisplan styrer hvilke tjenester eventuelt vil være tilgjengelig. Kunden må selv administrere og nyttiggjøre seg av produktet.</p>
                <p>3.2. Kunden eier og kan fritt disponere data i systemet. Dersom abonnementet sies opp, kan kunden eksportere data til Excel via eksportfunksjon eller pdf. Det gjelder data som kun rapporter eller vaktplan rapporter. Ønsker kunden at dataene skal lagres på NTR sin server, faktureres det 3000kr eks. mva pr år.</p>
                <p>3.3. Alle skriftlige meddelelser fra NTR  til Kunden foretas via e-post. Dette inkluderer bl.a. informasjon om prisendringer eller produktendringer, fakturaer samt eventuelle purringer og inkassovarsler. Kunden har ansvar for at korrekt e-postadresse til enhver tid er gjort kjent for NTR.</p>
                <p>3.4. Ved bestilling av NTR gjelder ingen angrefrist for bedrifter.</p>
                <p>4 NTR plikter</p>
                <p>4.1. NTR forplikter seg til ikke å gi uvedkommende opplysninger om Kunden som NTR mottar i forbindelse med tegning av avtalen.</p>
                <p>4.2. NTR eventuelle erstatningsansvar overfor Kunden følger de til enhver tid gjeldende regler. NTR fraskriver seg imidlertid ethvert ansvar for direkte og indirekte tap (konsekvenstap; så som tap av inntekter m.v.) som følger av feil med programvaren eller servers nedetid. Det gjelder alle slags feil med programvaren både mobil app eller webapplikasjon. Nettsiden vil kunne være utilgjengelig i korte perioder grunnet oppgraderinger, teknisk vedlikehold og lignende.</p>
                <p>4.3. NTR forbeholder seg retten til å gjøre endringer som forbedrer produktene, uten å informere Kunden om dette.</p>
                <p>4.4. NTR forplikter seg til å meddele eventuelle prisendringer til Kunden senest en måned før endringene trer i kraft, med unntak av prisnedsettelse på eksisterende produkt.</p>
                <p>4.5. NTR skal sørge for at selskapets internettjenester betjenes enten gjennom egen Web-tjener eller gjennom samarbeidspartner.</p>
                <p>4.6. Force Majeure: NTR kan ikke stilles ansvarlig for endringer som er forårsaket av forhold som ligger utenfor NTRs’ kontroll, slik som streik, lockout, krig og andre omstendigheter, politiske og offentlige bestemmelser. Det gjelder også når tjenester leveres av underleverandører.</p>
                <p>5 Betalingsbetingelser</p>
                <p>5.1. Kunden betaler de til enhver tid gjeldende priser for de tjenester som NTR tilbyr. Forfall på fakturaer er 10 dager. NTR sender faktura for 12 måneder av gangen.</p>
                <p>5.2. Avtalen har 3 måneders oppsigelse gjeldende fra skriftlig oppsigelsesdato, inneværende måned + 3 måneder.</p>
                <p>5.3. Avtalen kan sies kun skriftlig og etter at underskrevet avtale dato har gått ut. Avtalen fornyes automatisk for nye 12 måneder etter avtaletiden har løpt ut. Ved oppsigelse kunden må betale for hele bindingstiden.</p>
                <p>5.4. Ved forsinket betaling til NTR beregnes morarenter og omkostninger i henhold til “Lov om forsinket betaling”. Dersom utestående beløp ikke er innbetalt innen 28 dager etter forfall, blir forholdet oversendt til inkasso hvor dertil hørende omkostninger blir avkrevd Kunden. Samtidig vil tilgang til konto bli stengt inntil faktura er betalt. Ved gjenåpningen av kontoen faktura på 3000kr eks. mva sendes til kunden. NTR har rett til å kreve forskuddsbetaling på årlig lisens før kontoen gjenåpnes.</p>
                <p>5.5 Betalt beløp til NTR produkter og tjenester betales ikke tilbake selv om kunden velger å si opp abonnement sitt før avtalt avtaleperiode har utløpt og kunden må betale for alle utestående måneder.</p>
                <p>5.6. NTR forbeholder seg retten til å transportere denne kontrakten.</p>
                <p>6 Tvister.</p>
                <p>6.1. Ved tvist mellom Kunden og NTR kan forhold regulert i denne avtale bringes inn for Oslo Tingrett.</p>
                <p>6.2. Et eventuelt erstatningsansvar er alltid begrenset oppad til kontraktsverdi for et år.</p>
                <p>6.3. En slik klage må være fremmet senest to måneder etter at forholdet ble kjent for Kunden eller NTR, eller senest en måned etter at man gjennom brevveksling/møter har påvist at det er en uenighet.</p>
            </ExtraPage>
        );
    }
}
