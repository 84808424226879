import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { find, cloneDeep, sortBy } from 'lodash';

import { Input } from '../../../elements/Input';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import RadioWidget from '../../../components/GenericForm/Widgets/RadioWidget';
import { ManagersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import MultiSelect from '@khanacademy/react-multi-select';

import withLocalization from '~/hoc/withLocalization';
import LoadingSpinner from '~/elements/LoadingSpinner';

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    classNames: 'no-border-around-lists',
    'ui:layout': [
        {
            members: {
                md: 12,
            },
        },
    ],
    members: {
        'ui:layout:hideframe': true,
        'ui:options': { orderable: false, removable: true, inline: true },
        className: 'form-chooser',
        items: {
            'ui:options': { orderable: false, removable: true, inline: true },
            className: 'form-chooser',
            user_id: {
                classNames: 'col-md-6',
                'ui:widget': 'MembersWidget',
            },
            additional_pay: { classNames: 'col-md-3', defaultValue: 0 },
            // vacation_days: { classNames: 'col-md-3', defaultValue: 25 },
            hours: { classNames: 'col-md-6' },
            timeframe: {
                classNames: 'col-md-6',
                'ui:widget': 'Radio',
            },
        },
    },
};
const timeframeOptions = {
    enumOptions: [
        { value: 'daily', label: 'daily' },
        { value: 'weekly', label: 'weekly' },
        { value: 'monthly', label: 'monthly' },
    ],
};

@inject('projectStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class ProjectsMembersForm extends Component {
    state = {
        users: null,
        members: null,
        editingRowId: null,
        usersToAdd: null,
    };

    async loadData() {
        const {
            projectStore, id, add, userStore
        } = this.props;
        if (!id) return;
        const project = await projectStore.load(id, add);
        const users = await userStore.loadLookup('members', '');

        this.setState({
            users,
            members: this.getSortedMembers(cloneDeep(project.project.members), users),
            usersToAdd: this.getUsersToAdd(project.project.members, users),
        });
    }

    getSortedMembers(members, users) {
        // console.log("Members for project : ",members);
        // console.log("Current users : ",users);
        members.forEach(member => {
            const user = find(users, r => r.id === member.user_id);
            member.fullname = user ? user.fullname : '???';
        });
        members = members.filter(obj=>obj.fullname!=="???");
        members = sortBy(members, r => r.fullname.toUpperCase());
        return members;
    }

    componentDidUpdate(previousProps) {
        const { id } = this.props;
        if (id !== previousProps.id) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    onSave(values) {
        const {
            projectStore, id, add, t, commonStore, afterSave
        } = this.props;
        return projectStore
            .save(
                {
                    id,
                    members: this.state.members,
                },
                add
            )
            .then(result => {
                if (!result.project || !result.project.id) {
                    // some error
                    //console.log(result);
                    commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                    return false;
                }
                commonStore.addNotification(t('Saved'), null, 'success');
                afterSave();
                return true;
            });
    }

    getUsersToAdd(members, users) {
        const toAdd = [];
        const memberIds = [];
        members.forEach(member => memberIds.push(member.user_id));
        users.forEach(user => {
            if (memberIds.indexOf(user.id) >= 0) return;
            toAdd.push(user);
        });        
        return toAdd;
    }

    getUserNameById(id) {
        const user = find(this.state.users, r => r.id === id);
        if (!user) return '???';
        return `${user.fullname}`;
    }

    handleChangeValue(rowIndex, name, value) {
        const members = cloneDeep(this.state.members);
        members[rowIndex][name] = value;
        this.setState({ members });
    }

    handleStartEdit(member) {
        this.setState({ editingRowId: member.user_id });
    }

    handleStopEdit(member) {
        this.setState({ editingRowId: null });
    }

    handleRemove(index) {
        const members = cloneDeep(this.state.members);
        members.splice(index, 1);
        this.setState({ members });
    }

    handleAdd(value) {
        const members = cloneDeep(this.state.members);
        members.unshift({
            user_id: parseInt(value),
            fullname: this.getUserNameById(parseInt(value)),
            additional_pay:0,
            vacation_days: 25,
            hours: 40,
            timeframe: 'weekly',
        });
        const newToAdd = this.getUsersToAdd(members, this.state.users);
        this.setState({ members, editingRowId: parseInt(value), usersToAdd: newToAdd });
    }

    handleAddAndDeleteByIdArrs(addId, deleteId) {
        const members = cloneDeep(this.state.members).filter(member => deleteId.indexOf(member.user_id) === -1);
        addId.forEach(id => {
            members.unshift({
                user_id: parseInt(id),
                fullname: this.getUserNameById(parseInt(id)),
                additional_pay:0,
                vacation_days: 25,
                hours: 40,
                timeframe: 'weekly',
            });
        })
        const newToAdd = this.getUsersToAdd(members, this.state.users);
        this.setState({ members, editingRowId: null, usersToAdd: newToAdd });
    }

    render() {
        const {
            users, members, editingRowId, usersToAdd
        } = this.state;
        const { id, t } = this.props;
        const multiSelectOptions = [ ...(members ? (members.map ? members.map(member => ({ label: member.fullname, value: member.user_id })) : []) : []), ...(usersToAdd ? (usersToAdd.map ? usersToAdd.map(user => ({ label: user.fullname, value: user.id })) : []) : []) ].sort((a, b) => a.label.localeCompare(b.label));
        const multiSelectSelected = members ? (members.map ? members.map(member => member.user_id) : []) : [];

        if (!users || !members || !id) {
            return <LoadingSpinner />;
        }

        return (
            <div style={{ height: '500px', overflow: 'auto' }}>
                <div style={{ display: 'flex', marginBottom: '15px' }}>
                    <span style={{ lineHeight: '30px', marginRight: '20px' }}>{t('Add user:')}</span>
                    <div style={{ minWidth: '300px' }}>
                        {/* <ManagersWidget
                            directList={usersToAdd}
                            value={null}
                            onChange={value => this.handleAdd(value)}
                        /> */}
                        <MultiSelect
                            options={multiSelectOptions || []}
                            selected={multiSelectSelected || []}
                            onSelectedChanged={newId => {
                                //console.log(newId);
                                const oldId = members ? (members.map ? members.map(member => member.user_id) : []) : [];
                                const deletedId = oldId.filter(id => newId.indexOf(id) === -1);
                                const addedId = newId.filter(id => oldId.indexOf(id) === -1);
                                this.handleAddAndDeleteByIdArrs(addedId, deletedId);
                            }}
                            overrideStrings={{
                                selectSomeItems: t('Select people...'),
                                allItemsAreSelected: t('All members are selected'),
                                selectAll: t('Select All'),
                                search: t('Search'),
                            }}
                        />
                    </div>
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        <Button onClick={() => this.onSave()}>
                            {t('Save')}
                        </Button>
                    </div>
                </div>
                <table className="table table-hover table-striped project-members-form">
                    <tbody>
                        <tr>
                            <th>{t('User')}</th>                            
                            <th>{t('Additional payment')}</th>
                            {/* <th>{t('Vacation')}</th> */}
                            <th>{t('Hours')}</th>
                            <th>{t('Timeframe')}</th>
                            <th>&nbsp;</th>
                        </tr>
                        {members.map((member, rowIndex) => (
                            <tr key={`${member.user_id}`}>
                                <td>{member.fullname}</td>
                                <td>
                                    {member.user_id === editingRowId && (
                                        <Input
                                            value={member.additional_pay}
                                            onChange={e => this.handleChangeValue(rowIndex, 'additional_pay', e.target.value)
                                            }
                                        />
                                    )}
                                    {member.user_id !== editingRowId && <span>{member.additional_pay}</span>}
                                </td>
                                {/* <td>
                                    {member.user_id === editingRowId && (
                                        <Input
                                            value={member.vacation_days}
                                            onChange={e => this.handleChangeValue(rowIndex, 'vacation_days', e.target.value)
                                            }
                                        />
                                    )}
                                    {member.user_id !== editingRowId && <span>{member.vacation_days}</span>}
                                </td> */}
                                <td>
                                    {member.user_id === editingRowId && (
                                        <Input
                                            value={member.hours}
                                            onChange={e => this.handleChangeValue(rowIndex, 'hours', e.target.value)}
                                        />
                                    )}
                                    {member.user_id !== editingRowId && <span>{member.hours}</span>}
                                </td>
                                <td width="200">
                                    {member.user_id === editingRowId && (
                                        <RadioWidget
                                            vertical
                                            options={timeframeOptions}
                                            value={member.timeframe}
                                            onChange={value => this.handleChangeValue(rowIndex, 'timeframe', value)}
                                        />
                                    )}
                                    {member.user_id !== editingRowId && <span>{member.timeframe}</span>}
                                </td>
                                <td>
                                    {member.user_id === editingRowId && (
                                        <Button
                                            onClick={() => this.handleStopEdit(rowIndex)}
                                            bsStyle="danger"
                                            simple
                                            icon
                                        >
                                            <i className="fa fa-check" />
                                        </Button>
                                    )}
                                    {member.user_id !== editingRowId && (
                                        <>
                                            <Button
                                                onClick={() => this.handleStartEdit(member)}
                                                bsStyle="warning"
                                                simple
                                                icon
                                            >
                                                <i className="fa fa-edit" />
                                            </Button>
                                            <Button
                                                onClick={() => this.handleRemove(rowIndex)}
                                                bsStyle="danger"
                                                simple
                                                icon
                                            >
                                                <i className="fa fa-times" />
                                            </Button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ProjectsMembersForm;
