import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import '../../../../styles/css/styles.css';

// Components
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import { Modal } from 'react-bootstrap';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

import AttachmentsWidget from '../../../../components/GenericForm/Widgets/AttachmentsWidget';
import { Input, Textarea } from '../../../../elements/Input';

@inject('checklistStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ElementTreeView extends Component {
    state = {
        open: false,
        data: {},
        child_elements: [],
        headingcolor: '#E0DBFB',
        showElemntModal: false,
        currentmodelelement: null,
        mode: '',
        name: '',
        comment: '',
        attachments: '',
        sub: false,
        showaddbtn: false,
    };

    componentDidMount() {
        this.setState({ data: this.props.data });
        if (this.props.data.child_elements && this.props.data.elements.length > 0) {
            this.setState({ child_elements: this.props.data.elements });
        }
        if (this.props && this.props.headingcolor) {
            this.setState({ headingcolor: this.props.headingcolor });
        }
    }

    onviewclick = () => {
        let x = !this.state.open;
        this.setState({ open: x });
    };

    handleClosesAddElementModal = () => {
        this.setState({ showAddElement: false, currentmodelelement: {} });
    };

    handleTableButtonAction = async (id, type, btn) => {
        if (type.toLowerCase().includes('edit')) {
            let reqData = {
                parent_id: id,
            };
            // await this.props.checklistStore.getElementById(id).then(async (element) => {
                if (id !== 0) {
                    reqData.isParent = true;
                }
                await this.props.checklistStore.getElementChildByParentId(reqData).then((element) => {
                    this.setState({
                        currentmodelelement: element.elements,
                        mode: type.toLowerCase().includes('editsub') ? 'Edit Checklist' : 'Edit Sub Catogary',
                    });
                    this.setState({
                        name: element.elements.name,
                        comment: element.elements.comment,
                        attachments: element.elements.attachments
                    }, () => {
                        this.renderAttachments();
                    });
                    this.renderElementModal();
                    this.setState({ showaddbtn: btn });
                });
            // });
        } else if (type == 'add') {
            this.setState({ currentmodelelement: null, mode: 'Add Checklist' });
            this.setState({ 
                name: '',
                 comment: '',
                 attachments: '' });
            this.renderElementModal();
        } else if (type === 'addsub') {
            let reqData = {
                parent_id: id,
            };
            // await this.props.checklistStore.getElementById(id).then(async (element) => {
                if (id !== 0) {
                    reqData.isParent = true;
                }
                await this.props.checklistStore.getElementChildByParentId(reqData).then((element) => {
                    this.setState({ currentmodelelement: element.elements, mode: 'Add Sub Checklist', sub: true });
                    this.setState({ 
                        name: '',
                        comment: '',
                        attachments: '' });
                    this.renderElementModal();
                    this.setState({ showaddbtn: btn });
                });
            // });
        } else if (type === 'delete') {
            return this.props.checklistStore.deleteElementById(id).then((res2) => {
                this.props.checklistStore.resetLastListLoadTime();
                this.props.commonStore.addNotification(this.props.t('Deleted'), null, 'success');
                this.props.closeparentmodel();
            
                return res2;
            });
        }
    };
   
    renderElementModal = () => {
        // const { t } = this.props;
        this.setState({
            showElemntModal: true,
        });
    };
    handleCloseElemntModal = () => {
        this.setState({
            showElemntModal: false,
        });
        this.props.closeparentmodel();
        // this.props.refresh();
    };

    handleChange = (name, value) => {
        if(name=="name"){
            this.setState({ name: value });
        } else if(name =="comment" ){
            this.setState({ comment: value });
        }
    };

    handleAddsubelement = () => {
        // const { history } = this.props;
        // this.setState({ mode: 'Add', sub: true });
        this.setState({
            currentmodelelement: this.state.currentmodelelement,
            mode: 'Add Checklist',
            sub: true,
            name: '',
            comment: '',
            attachments: ''
        });
    };
    
    renderAttachments = (e) => {
        const { checklistStore } = this.props;
        var dataReq = {
            attachmentIds: this.state.attachments
        }
        checklistStore.getMultipleElements(dataReq).then((elementsResult) => {
            this.setState({ attachmentsList: elementsResult.attachments })
        });
    };

    addelementclick = async () => {
        // if (this.state.mode == "Add Main Catogary" || this.state.mode == "Edit Main Catogary") {
        //     this.setState({ mode: 'Add Sub Category', isSubCategory: true });
        // }
        if (this.state.name == undefined || this.state.name == '') {
            this.props.commonStore.addNotification(this.props.t('Please fill Name.'), null, 'error');
            return;
        }

        if (this.state.mode.toLowerCase().includes('add')) {
            let data = {};
            data = {
                name: this.state.name,
                parent_id: 0,
                comment: this.state.comment,
                attachments: this.state.attachments
            };
            if (this.state.sub == true) {
                data.parent_id = this.state.currentmodelelement.id;
            }
            await this.props.checklistStore.addelement(data).then((data) => {
                this.props.commonStore.addNotification(this.props.t('Saved'), null, 'success');
                this.handleCloseElemntModal();
                // this.props.refresh();
            });
        } else {
            let data = this.state.currentmodelelement;
            data.name = this.state.name;
            data.comment = this.state.comment;
            data.attachments= this.state.attachments;
            await this.props.checklistStore.addelement(data).then((data) => {
                this.props.commonStore.addNotification(this.props.t('Updated'), null, 'success');
                this.handleCloseElemntModal();
                // this.props.refresh();
            });
        }
    };

    render() {
        const { config, t, isUnionMode, checklistStore } = this.props;

        return (
            <div>
                <div className="col-12">
                    <div className={this.props.sub && this.props.sub == true ? 'subpanelgroupc' : 'panel-group'}>
                        <div className="panel panel-default" style={{ borderBottom: '0' }}>
                            <div className="panel-heading">
                                {this.props.sub && this.props.sub == true ? (
                                    <div
                                        style={{
                                            backgroundColor: this.state.headingcolor,
                                            width: '100%',
                                        }}
                                    >
                                        <h5
                                            style={{
                                                // cursor: 'pointer',
                                                display: 'inline-flex',
                                            }}
                                        >
                                            <i
                                                className="fa fa-bars checklistelementiconc"
                                                style={{ cursor: 'pointer', margin: '0.3em 0.5em 0em 0.2em' }}
                                                aria-hidden="true"
                                            ></i>
                                            <p
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.handleTableButtonAction(this.state.data.id, 'edit')}
                                            >
                                                {this.state.data.name}
                                            </p>
                                            <i
                                                class="fa fa-plus-square"
                                                style={{ cursor: 'pointer', margin: '0.3em 0.5em 0em 0.2em' }}
                                                aria-hidden="true"
                                                title="Add sub element"
                                                onClick={() =>
                                                    this.handleTableButtonAction(this.state.data.id, 'addsub', true)
                                                }
                                            ></i>
                                            <i
                                                className="fa fa-trash"
                                                style={{
                                                    cursor: 'pointer',
                                                    margin: '0.4em 0.5em 0em 0.5em',
                                                    color: 'indianred',
                                                }}
                                                title="Delete"
                                                onClick={() =>
                                                    this.handleTableButtonAction(this.state.data.id, 'delete', true)
                                                }
                                            />
                                        </h5>
                                        <div className="elementdetails">
                                            {/* <ul>
                                                <li>hint 1</li>
                                                <li>hint 2</li>
                                            </ul> */}
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            backgroundColor: this.state.headingcolor,
                                            width: '100%',
                                        }}
                                    >
                                        <h4
                                            className="panel-title"
                                            style={{
                                                cursor: 'pointer',
                                                display: 'inline-flex',
                                            }}
                                        >
                                            <i
                                                className="fa fa-th-list checklistelementiconc"
                                                style={{ margin: '0.3em 0.5em 0em 0.2em' }}
                                                aria-hidden="true"
                                            ></i>
                                            <span
                                                onClick={() =>
                                                    this.handleTableButtonAction(this.state.data.id, 'edit', true)
                                                }
                                            >
                                                {' '}
                                                <p>{this.state.data.name}</p>
                                            </span>{' '}
                                            <i
                                                className="fa fa-plus-circle"
                                                style={{ cursor: 'pointer', margin: '0.3em 0.5em 0em 0.5em' }}
                                                aria-hidden="true"
                                                title="Add sub element"
                                                onClick={() =>
                                                    this.handleTableButtonAction(this.state.data.id, 'addsub', true)
                                                }
                                            ></i>
                                            <i
                                                className="fa fa-trash"
                                                style={{
                                                    cursor: 'pointer',
                                                    margin: '0.4em 0.5em 0em 0.5em',
                                                    color: 'indianred',
                                                }}
                                                title="Delete"
                                                onClick={() =>
                                                    this.handleTableButtonAction(this.state.data.id, 'delete', true)
                                                }
                                            />
                                        </h4>
                                        <div className="elementdetails">
                                            {/* <ul>
                                                <li>hint 1</li>
                                                <li>hint 2</li>
                                            </ul> */}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {this.state.data.elements && this.state.data.elements.length > 0 ? (
                                // <div className={this.state.open ? 'panel-collapse' : 'panel-collapse panel-close'}>
                                <ul className="list-group" style={{ marginTop: '20px' }}>
                                    {this.state.data.elements.map((item, index) => (
                                        <div>
                                            {item.elements && item.elements.length > 0 ? (
                                                <ElementTreeView
                                                    data={item}
                                                    sub={true}
                                                    headingcolor="#F6F6F6"
                                                    key={item.id}
                                                    checklistStore={checklistStore}
                                                    commonStore={this.props.commonStore}
                                                    closeparentmodel={this.props.closeparentmodel}
                                                    // refresh={this.props.refresh}
                                                />
                                            ) : (
                                                <div style={{ display: 'inline-flex', width: '100%' }}>
                                                    <i
                                                        className="fa fa-bars checklistelementiconc"
                                                        style={{ margin: '1em 0.2em 0em 0.5em' }}
                                                        aria-hidden="true"
                                                    ></i>

                                                    <li className="list-group-item">
                                                        {' '}
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() =>
                                                                this.handleTableButtonAction(item.id, 'editsub', false)
                                                            }
                                                        >
                                                            {item.name}
                                                        </span>
                                                    </li>
                                                    {/* <i
                                                        className="fa fa-plus"
                                                        style={{ cursor: 'pointer', margin: '1em 0.2em 0em 0.5em' }}
                                                        aria-hidden="true"
                                                        title="Add sub element"
                                                        onClick={() => this.handleTableButtonAction(item.id, 'addsub')}
                                                    ></i> */}
                                                    <i
                                                        className="fa fa-trash"
                                                        style={{
                                                            cursor: 'pointer',
                                                            margin: '1em 0.5em 0em 0.5em',
                                                            color: 'indianred',
                                                        }}
                                                        title="Delete"
                                                        onClick={() =>
                                                            this.handleTableButtonAction(item.id, 'delete', true)
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </ul>
                            ) : // </div>
                            null}
                        </div>
                    </div>
                </div>
                <Modal
                    className="modal"
                    show={this.state.showElemntModal}
                    onHide={this.handleCloseElemntModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                        {this.state.mode == "Add Main Catogary" ? t("Add main catagory") : ""}
                        {this.state.mode == "Edit Main Catogary" ? t("Edit main catagory") : ""}
                        {this.state.mode == "Add Sub Category" ? t("Add sub catagory") : ""}
                        {this.state.mode == "Edit Sub Catogary" ? t("Edit sub catagory") : ""}
                        {this.state.mode == "Add Checklist" ? t("Add Checklist") : ""}
                        {this.state.mode == "Edit Checklist" ? t("Edit Checklist") : ""}
                        {this.state.mode == "Add Sub Checklist" ? t("Add Checklist") : ""}
                        
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 130px)', minHeight: '80vh', overflowY: 'auto' }}>
                        <div className="form-group field field-string">
                            <Textarea
                                label={`${t('Name')} *`}
                                value={this.state.name}
                                style={{ width: '100%' }}
                                name="name"
                                // onChange={(e) => this.handleChange(e)}
                                onChange={(e) => this.handleChange('name', e.target.value)}
                                placeholder={t('Enter Name here')}
                            />
                        </div>
                        <div className="attachment-box" style={{ width: '100%' }}>
                            <span className="attachment-box__label">{t('Upload Files')}</span>
                            <AttachmentsWidget
                                value={this.state.attachments}
                                onChange={attachIds => {
                                    this.setState({
                                        attachments: attachIds
                                    })
                                }}
                                options={{
                                    imageContext: {
                                        existingAttachment: this.state.attachmentsList,
                                        fileType: 'docs',
                                        id: 0,
                                        model: 'Image',
                                    },
                                }}
                            />
                        </div>
                        <br />
                        <Textarea
                            label={t('Comment')}
                            value={this.state.comment}
                            style={{ width: '100%' }}
                            name="comment"
                            onChange={(e) => this.handleChange('comment', e.target.value)}
                            // onChange={(e) => this.handleChange('description', e.target.value)}
                            placeholder={t('Enter comments here')}
                        />
                        <br/>
                        {t('Add minimum one checklist under sub category to create checklist')}
                        <br/>
                        <div>
                            {this.state.mode.toLowerCase().includes('add') ? null : (
                                <div>
                                    {this.state.showaddbtn === true ? (
                                        <Button
                                            fill
                                            wd
                                            icon
                                            onClick={() => this.handleTableButtonAction(this.state.data.id, 'addsub')}
                                        >
                                            {t('Add Checklist')} <i className="fa fa-plus" />
                                        </Button>
                                    ) : null}
                                </div>
                            )}
                           
                            <div>
                                {this.state.mode.toLowerCase().includes('edit') &&
                                this.state.currentmodelelement &&
                                this.state.currentmodelelement.elements &&
                                this.state.currentmodelelement.elements.length > 0
                                    ? this.state.currentmodelelement.elements.map((item, index) => (
                                          <ElementTreeView data={item} key={item.id} />
                                      ))
                                    : null}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" onClick={this.addelementclick}>
                            {t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleCloseElemntModal}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ElementTreeView;
