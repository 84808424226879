import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import ClientsList from './ClientsList';
import ClientsForm from './ClientsForm';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

@inject('authStore', 'clientStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ClientsPage extends Component {
    onAdd(type) {
        const { history } = this.props;
        history.push(`/admin/clients/add?type=${type}`);
    }

    onTableAction(id, type) {
        const { history, authStore,commonStore,t } = this.props;        
        if (type === 'loginas') {
            authStore
                .loginAs(id)
                .then(() => {
                    window.location.href = `${window.location.href.split('/admin')[0]}/admin/?reload`;
                    // history.push(`/admin`);
                })
                .catch(e => {
                    this.props.commonStore.addNotification(e.message || this.props.t('Error'), null, 'error');
                });
            return;
        }
        if (type === 'remove') 
            return this.props.handleDelete(id, id => this.props.clientStore.remove(id).then(res =>{
                commonStore.addNotification(t('Deleted'), null, 'error');
                history.push('/admin/clients/');
            }));
        history.push(`/admin/clients/${id}`);
    }

    editingId = () => {
        let id = null;
        if (this.props.location.pathname !== this.props.match.path) {
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }
        return { editing: !!id, add: id === 'add', id };
    };

    render() {
        const mode = this.editingId();

        const { userStore } = this.props;
        const userType = userStore.currentUser.user_type;
        if (userType != 'super-admin') {
            return <div>Not Allowed</div>;
        }

        return (
            <div className="main-content">
                {!mode.editing && (
                    <ClientsList
                        onTableAction={(id, type) => this.onTableAction(id, type)}
                        onAdd={type => this.onAdd(type)}
                    />
                )}
                {mode.editing && <ClientsForm id={mode.id} add={mode.add} />}
            </div>
        );
    }
}
export default ClientsPage;
