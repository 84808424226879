import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';

import customerFeature from '../../../utils/customerFeature';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { serverTimeFormat } from '~/library/core';
import { shortDateFormat } from '~/utils/dateFormat'
import config from '~/library/config';
import agent from '~/library/agent';
// Components
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { Modal } from 'react-bootstrap';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

// import ElementList from './ElementList';
import ChecklistTemplateList from './CheckListTemplate/ChecklistTemplateList';
import ChecklistAssign from './ChecklistAssign';
import GenericList from '~/components/GenericList/GenericList';
import ChecklistCheck from './ChecklistCheck';

import '../../../styles/css/styles.css';
import ChecklistsFilter from './ChecklistsFilter';
import ChecklistAccept from './ChecklistAccept';
import commonStore from '../../../library/stores/commonStore';
import ChecklistTableAd from './ChecklistTableAd'

@inject('checklistStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class ChecklistTable extends Component {

    state = {
        add: false,
        currentChecklistId: 0,
        showChecklistTodo: false,
        showChecklistAccept: false,
        currentChecklist: {},
        isSchedule: false,
    };

    componentWillMount() {
        const params = new URLSearchParams(this.props.location.search);
        const filterByProject = params.get('project');
        this.props.checklistStore.setFilter('project', filterByProject || null);
        this.props.checklistStore.setFilter('name', '');
        this.props.checklistStore.onFilter();
    }

    renderTableActionButtons = (id, full) => {
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const { t } = this.props;
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');
        const actionable = full.status != 'approved';

        let statusText = full.status;
        if (statusText != "Approved") statusText = 'active';
        let statusIcon = <i className={'pe-7s-hourglass'} />;
        if (full.status == 'Approved') {
            statusIcon = <i className={'pe-7s-check'} />;
        }

        return (
            <div className="actions-center">
                {/* {user_type == 'pm' || user_type == 'admin' && (
                <div className="status-active status-block status-no-click-for-member"
                    style={{ marginTop: "-4px", lineHeight: "10px" }}>
                    <i className="pe-7s-hourglass" onClick={() => this.viewChecklistAccept(full)} />
                </div>
            )} */}

            <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip-top">
                            {t("Full Report")}
                        </Tooltip>
                    }
                >
                    <Button onClick={() => this.handleTableButtonAction(id, 'fullreport')} bsStyle="danger" simple icon>
                        <i className="fa fa-file-alt" />
                    </Button>
                </OverlayTrigger>

                <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                    <i className="fa fa-edit" />
                </Button>
                <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                    <i className="fa fa-trash" />
                </Button>
                {/* {(user_type == 'member' || (user_type == 'member' && full.assignee_id == userId) || allow) &&
                    !full.is_default_for_project && (
                        <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                            <i className="fa fa-times" />
                        </Button>
                    )}
                 */}
            </div>
        );
    };

    renderTableActionButtonsTwo = (id, full) => {
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const { t } = this.props;
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');
        const actionable = full.status != 'approved';

        let statusText = full.status;
        if (statusText != "Approved") statusText = 'active';
        let statusIcon = <i className={'pe-7s-hourglass'} />;
        if (full.status == 'Approved') {
            statusIcon = <i className={'pe-7s-check'} />;
        }

        return (
            <div className="actions-center">
                <div
                    className={`status-block status-${full.status == 'Approved' ? 'approved' : 'active'}${actionable ? ' status-actionable' : ''
                        }${user_type === 'member' ? ' status-no-click-for-member' : ''}`}
                    // title={t(statusText)}
                    onClick={() => ((user_type == 'pm' || user_type == 'admin' && (full.status == "Done" || full.status == "Approved")) ? this.viewChecklistAccept(full) : null)}
                    style={{ marginTop: '-4px', lineHeight: '10px', marginRight: '0px' }}
                >
                    {statusIcon}
                    {full.status == "Approved" && (
                        <>
                            <small>
                                <small>
                                    {full.status_changed_by && moment(new Date(full.status_changed_on)).format(serverTimeFormat(true))}
                                </small>
                            </small>
                            <br />
                            <small>
                                <small>{this.props.commonStore.getUsername(full.status_changed_by)}</small>
                            </small>
                        </>
                    )}
                </div>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip-top">
                            {t("Do Checklist")}
                        </Tooltip>
                    }
                >
                    <Button bsStyle="warning" onClick={() => this.handleTableButtonAction(id, 'todo')} simple icon>
                        <i className="fa fa-thumbs-up" />
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip-top">
                            {t("Quick Report")}
                        </Tooltip>
                    }
                >
                    <Button onClick={() => this.handleTableButtonAction(id, 'quickreport')} bsStyle="danger" simple icon>
                        <i className="far fa-file-alt" />
                    </Button>
                </OverlayTrigger>

               

            </div>
        );
    };

    viewChecklistAssign = () => {
        this.setState({ add: true, currentChecklistId: 0 });
        this.renderChecklistAssignModal();
    };

    viewChecklistAssignSchedule = () => {
        this.setState({ isSchedule: true})   
    };

    renderChecklistAssignModal = () => {
        const { t } = this.props;
        this.setState({
            showChecklistAssign: true
        });
    };

    handleClosesChecklisAssignModal = () => {
        this.setState({ showChecklistAssign: false, add: false });
        this.props.checklistStore.returnDefaultNew();
    };


    // Accept modal
    viewChecklistAccept = (checklist) => {
        this.setState({ currentChecklist: checklist });
        this.renderChecklistAcceptModal();
    };

    renderChecklistAcceptModal = () => {
        const { t } = this.props;
        this.setState({
            showChecklistAccept: true
        });
    };

    handleClosesChecklisAcceptModal = () => {
        this.setState({ showChecklistAccept: false });
    };

    updateCurrentChecklistComment = (data) => {
        this.setState({
            currentChecklist: {
                ...this.state.currentChecklist,
                "status_note": data
            }
        });
    }

    acceptChecklist = (data) => {
        const { t, commonStore, checklistStore } = this.props;
        let hydrated = {
            id: this.state.currentChecklist.id,
            status_note: this.state.currentChecklist.status_note
        };
        if (hydrated.id) {
            checklistStore
                .approveChecklist(hydrated)
                .then((res) => {
                    // this.props.checklistStore.resetLastListLoadTime();
                    commonStore.addNotification(t('Checklist Approve sucessfully.'), null, 'success');
                    this.handleClosesChecklisAcceptModal();
                })
                .catch((err) => {
                    commonStore.addNotification(err.message || t('Please Fill all fields.'), null, 'error');
                });
        } else {
            commonStore.addNotification(t('Error'), null, 'error');
        }
    }

    // handle action button
    handleTableButtonAction = (id, type) => {
        // this.props.onTableAction(id, type);
        if (type === 'edit') {
            const { history } = this.props;
            this.setState({ currentChecklistId: id, add: false });
            this.renderChecklistAssignModal();
        } else if (type === 'todo') {
            const { history } = this.props;
            this.setState({ currentChecklistId: id, add: false });
            this.renderChecklistTodoModal();
        } else if (type === 'remove') {
            const { history } = this.props;
            this.removeChecklist(id);
        } else if (type === 'quickreport') {
            const { history } = this.props;
            this.getChecklistReport(id, "quickreport");
        } else if (type === 'fullreport') {
            const { history } = this.props;
            this.getChecklistReport(id, "fullreport");
        }

    };

    removeChecklist(id) {
        return this.props.handleDelete(id, (id) => {
            return this.props.checklistStore.checklistDelete(id).then((res) => {
                this.props.checklistStore.resetLastListLoadTime();
                return res;
            });
        });
    }

    getChecklistReport(id, type) {
        let hydrated = {
            id: id,
            authorization: this.props.commonStore.token
        }
        window.open(`${config.API_ROOT}/userchecklists/report/${type}${agent.convertToGetParams(hydrated)}`);
    }

    //TODO
    renderChecklistTodoModal = () => {
        const { t } = this.props;
        this.setState({
            showChecklistTodo: true,
        });
    };

    handleClosesChecklisTodoModal = () => {
        this.setState({ showChecklistTodo: false });
    };

    applyForm = (params = {}) => {
        const { loading, currentChecklist: checklist } = this.props.checklistStore;
        const { t, commonStore, checklistStore } = this.props;

        if (checklist.project_id == null || checklist.project_id == "" ||
            checklist.checklist_templates_id == null || checklist.checklist_templates_id == "") {
            commonStore.addNotification(t('Please fill all required fields'), null, 'error');
            return;
        }

        if ((checklist.cornjob_type === "Gap Between Days" && (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === ""))
            || (checklist.cornjob_type === "Weekdays" && (checklist.cornjob_duration === "" || !isNaN(checklist.cornjob_duration))
            || (checklist.cornjob_type === "Monthly" && (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === ""))
            || (checklist.cornjob_type === "Half Yearly" && (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === ""))
            || (checklist.cornjob_type === "Annually" && (isNaN(checklist.cornjob_duration) || checklist.cornjob_duration === ""))
            )) {
            commonStore.addNotification(t('Please fill all required fields'), null, 'error');
            return;
        }
        let hydrated = {
            user_id: checklist.user_id,
            project_id: checklist.project_id,
            checklist_templates_id: checklist.checklist_templates_id,
            //to_project: checklist.to_project,
            isSendEmail: checklist.isSendEmail,
            cornjob_type: checklist.cornjob_type,
            cornjob_duration: checklist.cornjob_duration,
            isobligatory: checklist.isobligatory,
            allow_check_all: checklist.allow_check_all
        };
        if (checklist.id && checklist.id > 0) {
            hydrated['id'] = checklist.id;
        }
        //if (hydrated.checklist_templates_id && (hydrated.user_id || hydrated.to_project )) {
            // console.log("hydrated:",hydrated)
        checklistStore
            .assignChecklist(hydrated)
            .then((res) => {
                this.props.checklistStore.resetLastListLoadTime();
                commonStore.addNotification(t(res.isIdAvailable == true ? 'Checklist Updated Successfully.' : 'Checklist Assigned Successfully.'), null, 'success');
                this.handleClosesChecklisAssignModal();
            })
            .catch((err) => {
                commonStore.addNotification(err.message || t('Please fill all required fields'), null, 'error');
            });
    };

    reRenderForm = (id) => {

        this.handleClosesChecklisTodoModal();
        setTimeout(
            function () {
                this.handleTableButtonAction(id, 'todo');
            }
                .bind(this),
            300
        );
    }

    setStatusColor = (status) => {
        let color = "";
        switch (status) {
            case 'Done':
                color = 'green';
                break;

            case 'Approved':
                color = 'green';
                break;            

            case 'Deviation':
                color = 'red';
                break;

            case 'Under work':
                color = '#ff8c00';
                break;

            default:
                color = 'blue';            
        }
        return color;
    }

    render() {
        const {  t, isUnionMode } = this.props;
        const { loading, filters, appliedFilters, setFilter, onFilter } = this.props.checklistStore;
        const {config} = this.props.commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        return (
            <div>
                {this.state.isSchedule && 
                    <ChecklistTableAd />
                }
                {!this.state.isSchedule &&
                <div className="table-list">
                    <div className="table-list__header">
                            {this.props.userStore.currentUser.user_type != 'member' &&
                                <Button fill wd icon onClick={() => this.viewChecklistAssignSchedule()}>
                                    {t('Show only Scheduled')}
                                </Button>
                            }
                        <Button fill wd icon onClick={() => this.viewChecklistAssign()}>
                            {t('Do Checklists')}
                        </Button>
                        {/* <Button fill wd icon onClick={() => this.viewChecklistTemplates()}>
                            {t('Checklists Templates')}
                        </Button> */}
                    </div>
                    <GenericList
                        columns={[
                            {
                                Header: 'Id',
                                id: 'id',
                                sortable: false,
                                accessor: 'id',
                            },
                            {
                                Header: 'Name',
                                id: 'name',
                                accessor: ({ id, name }) => (
                                    <a
                                        className="list-main-column"
                                        onClick={() => this.handleTableButtonAction(id, 'edit')}
                                    >
                                        {`${name}`}
                                    </a>
                                ),
                            },
                            {
                                Header: 'User',
                                id: 'user_name',
                                // accessor: ({ first_name, last_name }) => {`${first_name}` + `${last_name}`},
                                accessor: ({ first_name, last_name, to_project }) => (
                                    to_project == false ? <span>{`${first_name}` + ' ' + `${last_name}`}</span> : "To Project"
                                ),
                                sortable: false,
                            },
                            {
                                Header: 'Project',
                                id: 'project_name',
                                sortable: false,
                                accessor: 'project_name',
                            },
                            {
                                Header: 'Assigned by',
                                id: 'client_name',
                                sortable: false,
                                accessor: ({ created_by_first_name, created_by_last_name }) => (
                                    <span>{`${created_by_first_name}` + ' ' + `${created_by_last_name}`}</span>
                                ),
                            },
                            {
                                Header: 'Status',
                                id: 'status',
                                sortable: true,
                                accessor: ({ status }) => <span
                                    style={{
                                        color:this.setStatusColor(status)                                            
                                    }}>{t(status)}</span>,
                            },
                            {
                                Header: 'Last Updated',
                                id: 'updated_at',
                                accessor: ({ updated_at }) => (
                                    <span>{updated_at && shortDateFormat(updated_at, dateTimeRules)}</span>
                                ),
                            },
                            {
                                Header: 'Actions',
                                id: 'approve',
                                sortable: false,
                                accessor: (full) => this.renderTableActionButtonsTwo(full.id, full),
                            },
                            {
                                Header: '',
                                id: 'actions',
                                sortable: false,
                                accessor: (full) => this.renderTableActionButtons(full.id, full),
                            },
                        ]}
                        forceReload={
                            this.state.checklistChanged == true ||
                            this.props.checklistStore.deleteSuccess ||
                            this.props.checklistStore.addOrUpdated
                        }
                        filters={appliedFilters}
                        lastListLoadTime={this.props.checklistStore.lastListLoadTime}
                        header={
                            <ChecklistsFilter
                                filters={filters}
                                setFilter={(name, value) => this.props.checklistStore.setFilter(name, value)}
                                onFilter={() => this.props.checklistStore.onFilter()}
                                loading={loading}
                            />
                        }
                        requestData={(params) => this.props.checklistStore.getChecklists(params)}
                    />
                </div>
                }
                <Modal
                    className="modal right fade"
                    show={this.state.showChecklistAssign}
                    onHide={this.handleClosesChecklisAssignModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Do Checklists')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ChecklistAssign
                            add={this.state.add}
                            id={this.state.currentChecklistId}
                            handleClosesChecklisAssignModal={this.handleClosesChecklisAssignModal}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.applyForm} className="btn-wd btn-fill">
                            {t('Save')}
                        </Button>
                        <Button variant="secondary" onClick={this.handleClosesChecklisAssignModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    className="modal right fade"
                    show={this.state.showChecklistTodo}
                    onHide={this.handleClosesChecklisTodoModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Do Checklists')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ minHeight: '500px', overflowY: 'auto' }}>
                        <ChecklistCheck id={this.state.currentChecklistId} reRenderForm={this.reRenderForm} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClosesChecklisTodoModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    className="modal fade"
                    show={this.state.showChecklistAccept}
                    onHide={this.handleClosesChecklisAcceptModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Approve')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ChecklistAccept
                            checklist={this.state.currentChecklist}
                            updateCurrentChecklistComment={this.updateCurrentChecklistComment}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                    {this.state.currentChecklist && this.state.currentChecklist.status != 'Approved' &&
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                fill
                                wd
                                icon
                                type="submit"
                                style={{ backgroundColor: 'green', marginRight: 30, border: 0 }}
                                onClick={() => this.acceptChecklist()}
                            >
                                {' '}
                                <i className="fa fa-check" /> {t('Accept')}
                            </Button>
                        </div>
                    }
                        <Button variant="secondary" onClick={this.handleClosesChecklisAcceptModal} className="btn-wd">
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default ChecklistTable;
