import React, { Component, Fragment, createRef } from 'react';
import { inject, observer } from 'mobx-react';
import { find, filter } from 'lodash';
import { Modal } from 'react-bootstrap';
import {
    Map, Marker, Popup, TileLayer
} from 'react-leaflet';
import { Icon } from 'leaflet';

import withLocalization from '../../../hoc/withLocalization';
import { leafletStringToLocation, locationToString } from '../../../utils/geo';

import LoadingSpinner from '~/elements/LoadingSpinner';

import 'leaflet/dist/leaflet.css';

const DEFAULT_COORDS = { lat: 59.9141521, lng: 10.7463433 };

@inject('taskStore', 'projectStore')
@withLocalization
@observer
class InternalGpsCoordinatesWidget extends Component {
    constructor(props) {
        super(props);
        // console.log('constructing', props.value);
        this.state = {
            mapOpen: false,
            intermediateCoords: props.value ? leafletStringToLocation(props.value) : null,
        };
    }

    mapRef = createRef();

    showMap() {
        this.setState({ mapOpen: true });
    }

    handleClick(e) {
        // console.log(e);
        this.setState({ intermediateCoords: e.latlng });
    }

    submitCoords() {
        this.setState({ mapOpen: false });
        this.props.onChange(locationToString(this.state.intermediateCoords));
    }

    handleLocationFound(e) {
        // console.log('lcf', e);
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.intermediateCoords && props.value) {
            state.intermediateCoords = leafletStringToLocation(props.value);
        }
        return state;
    }

    render() {
        const {
            value, onChange, className, disabled, t, taskStore, projectStore
        } = this.props;
        const { mapOpen, intermediateCoords } = this.state;
        let geoValue = null;
        try {
            geoValue = leafletStringToLocation(value);
        } catch (e) {}
        if (!geoValue || !geoValue.lat || Number.isNaN(geoValue.lat) || !geoValue.lng || Number.isNaN(geoValue.lng)) {
            geoValue = null;
        }
        const gpsNowLoading = taskStore.gpsNowLoading || projectStore.gpsNowLoading;
        const myIcon = new Icon({
            iconUrl: '/images/map-icon.png',
            iconSize: [38, 50],
            iconAnchor: [22, 50],
            popupAnchor: [-3, -76],
            shadowUrl: '/images/map-icon-shadow.png',
            shadowSize: [68, 95],
            shadowAnchor: [22, 94],
        });
        const marker = intermediateCoords ? (
            <Marker position={intermediateCoords} icon={myIcon}>
                <Popup>{t('Here')}</Popup>
            </Marker>
        ) : null;

        // console.log('MARKER', marker);

        return (
            <Fragment>
                <Modal size="lg" show={!!mapOpen} onHide={() => this.setState({ mapOpen: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Choose on map')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ height: '500px' }}>
                        <div style={{ height: '400px', width: '100%' }}>
                            <Map
                                center={geoValue || DEFAULT_COORDS}
                                length={4}
                                onClick={e => this.handleClick(e)}
                                onLocationfound={e => this.handleLocationFound(e)}
                                ref={this.mapRef}
                                zoom={13}
                            >
                                <TileLayer
                                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {marker}
                            </Map>
                            <div style={{ width: '100%', padding: '10px', display: 'flex' }}>
                                <div style={{ width: '200px', paddingTop: '7px', paddingRight: '10px' }}>
                                    {t('Please click on the map to set coordinates')}
                                </div>
                                <div style={{ width: '200px', paddingTop: '7px', paddingRight: '10px' }}>
                                    {intermediateCoords
                                        ? `${intermediateCoords.lat}, ${intermediateCoords.lng}`
                                        : t('Not set')}
                                </div>

                                <button className="btn btn-fill" onClick={() => this.submitCoords()}>
                                    {t('Set cooordinates')}
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <div style={{ display: 'flex', lineHeight: '40px' }}>
                    {/* Icon */}
                    {gpsNowLoading && <LoadingSpinner noMargin />}
                    {value && !gpsNowLoading && (
                        <i
                            className="pe-7s-check"
                            style={{ color: 'darkgreen', lineHeight: '40px', fontSize: '20px' }}
                        />
                    )}
                    <input
                        className={className || 'form-control time-input'}
                        type="text"
                        value={value || ''}
                        placeholder={t('GPS')}
                        onChange={e => onChange(e.target.value)}
                    />
                    <a onClick={() => this.showMap()}>{t('Map')}</a>
                </div>
            </Fragment>
        );
    }
}

function GpsCoordinatesWidget(props) {
    return (
        <Fragment>
            <InternalGpsCoordinatesWidget {...props} />
        </Fragment>
    );
}

export default GpsCoordinatesWidget;
