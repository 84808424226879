import React, { Component } from 'react';
import Switch from 'react-switch';

class SwitchWidget extends Component {
    render() {
        const { value, onChange , disabled} = this.props;
        return (
            <div>
                <Switch checked={value}  height={20} width={40} uncheckedIcon={false} checkedIcon={false} onColor={'#128eb8'} onChange={checked => onChange(checked)} disabled={disabled}/>                
            </div>
        );
    }
}

export default SwitchWidget;
