import React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

const MAX_ROWS_TO_SHOW = 10;

@observer
export default class ColumnsChooser extends React.Component {
    setMapping(e, index) {
        const { campaign, setMapping } = this.props;
        setMapping(e.target.value, index);
    }

    mapTo(col, index) {
        const { campaign, schema } = this.props;
        const { csvData, mapping } = campaign;
        return (
            <select onChange={e => this.setMapping(e, index)} value={mapping[index] ? mapping[index] : ''}>
                <option value="">(skip)</option>
                {schema.map(fld => (
                    <option value={fld.id}>{fld.field.title || fld.id}</option>
                ))}
            </select>
        );
    }

    render() {
        const { campaign, applyFilters, filteredRows } = this.props;
        const { csvData, filter, mapping } = campaign;

        const filteredRowsTop10 = filteredRows.length <= MAX_ROWS_TO_SHOW ? filteredRows : filteredRows.slice(0, MAX_ROWS_TO_SHOW);

        return (
            <div>
                <h2>Map fields</h2>
                <table className="table">
                    <tbody>
                        <tr>
                            <th>CSV field</th>
                            <th>Maps to</th>
                            <th>Sample data (1st)</th>
                        </tr>
                        {csvData.cols.map((row, index) => (
                            <tr key={`head_col_${index}`}>
                                <td>{row}</td>
                                <td>{this.mapTo(row, index)}</td>
                                <td>{filteredRows[0][index]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h2>
                    Found records <br />
                    <small>
                        (showing up to {MAX_ROWS_TO_SHOW} of {filteredRows.length})
                    </small>
                </h2>
                <div style={{ overflow: 'auto' }}>
                    <table className="table">
                        <tbody>
                            <tr>
                                {csvData.cols.map((row, index) => (
                                    <th key={`head_col_${index}`}>{row}</th>
                                ))}
                            </tr>
                            {filteredRowsTop10.map((row, index) => (
                                <tr key={`body_row${index}`}>
                                    {row.map((col, index) => (
                                        <td key={`body_col_${index}`}>{col}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
